import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  activateLoading,
  deactivateLoading,
} from 'features/global/loadingProgressSlice';
import { LoadingStatus, SystemRoleEnums } from 'helpers';
import {
  fetchBestFits,
  getBestFitStatus,
  resetBestFitState,
  selectBestFit,
} from 'features/BestFit/bestFitSlice';
import { resetDropDownState } from 'features/AdminSettingsDropDown/dropDownSlice';
import { BestFitReportTable } from 'components/BestFitReportTable';
import { TalentTabPanel } from './TalentMatchTab';
import { selectActiveUser } from 'features/account/accountSlice';
import { BestFitFilter } from 'components/BestFitReportTable/BestFitFilter';

const BestFitReport = () => {
  //#region Constants
  const dispatch = useDispatch();
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  const [itemBestFitPayload, setItemBestFitPayload] = useState({
    cost_center_id: null,
    job_id: null,
    job_role_id: null,
    skill_ids: null,
    office_id: null,
    percentage_match: null,
    command_id: null,
    depot_id: null,
    start_index: 0,
    stop_index: 10,
    other_start_index: 0,
    other_stop_index: 10,
  });
  const getReportData = async (payload) => {
    dispatch(activateLoading());
    await dispatch(
      fetchBestFits({
        ...payload,
        reset: payload?.reset ?? true,
        start_index: payload?.start_index ?? 0,
        stop_index: payload?.stop_index ?? 10,
      })
    );
    dispatch(deactivateLoading());
  };
  const [sortBy, setSortBy] = useState('-match');
  const [sortDirection, setSortDirection] = useState('DESC');
  //#endregion State

  //#region Selectors
  const itemCostCenterReportStatus = useSelector(getBestFitStatus);
  const bestFitData = useSelector(selectBestFit);
  const activeUser = useSelector(selectActiveUser);
  const isRoleAmc = activeUser.role === SystemRoleEnums.AMC;
  //#endregion Selectors

  //#region Refs
  const ref = useRef(false);
  //#endregion Refs

  //#region Effects
  useEffect(() => {
    if (ref.current === true && !isRoleAmc) {
      getReportData({ ...itemBestFitPayload });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemBestFitPayload]);

  useEffect(() => {
    return async () => {
      await dispatch(resetBestFitState());
      await dispatch(resetDropDownState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (itemCostCenterReportStatus === LoadingStatus.Idle && !isRoleAmc) {
      getReportData({
        ...itemBestFitPayload,
      });
    }
    ref.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemCostCenterReportStatus, dispatch]);
  //#endregion Effects

  //#region Methods
  const setSorting = (_sortBy, _sortDirection) => {
    setSortBy(_sortBy);
    setSortDirection(_sortDirection);
    setItemBestFitPayload({
      ...itemBestFitPayload,
      sort: `${_sortDirection === 'ASC' ? '' : '-'}${_sortBy}`,
    });
  };
  //#endregion Methods

  //#region Render time calcs
  //#endregion Render time calcs

  //#region Render
  return (
    <>
      <BestFitFilter
        itemBestFitPayload={itemBestFitPayload}
        setItemBestFitPayload={setItemBestFitPayload}
      />
      {isRoleAmc ? (
        <TalentTabPanel
          itemBestFitPayload={itemBestFitPayload}
          setItemBestFitPayload={setItemBestFitPayload}
          sortDirection={sortDirection}
          sortBy={sortBy}
          handleSort={setSorting}
        />
      ) : (
        <BestFitReportTable
          itemBestFitPayload={itemBestFitPayload}
          setItemBestFitPayload={setItemBestFitPayload}
          bestFitData={bestFitData}
          sortDirection={sortDirection}
          sortBy={sortBy}
          handleSort={setSorting}
        />
      )}
    </>
  );
  //#endregion Render
};
export { BestFitReport };
