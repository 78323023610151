import { useState, useEffect, useRef } from 'react';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

import { FormActions } from 'helpers/form-action';
import { makeFormStyles } from 'forms/style';
import {
  activateLoading,
  deactivateLoading,
} from 'features/global/loadingProgressSlice';
import {
  COST_CENTER_CACHE,
  EMPLOYEE_CACHE,
  OFFICE_CACHE,
  snackbarHandler,
} from 'helpers';
import { SnackbarDismiss } from 'shared/components/SnackbarDismiss';
import Loader from 'shared/components/Loader';
import { closePanel } from 'features/common/formSlice';
import {
  createCostCenter,
  fetchCostCenter,
  fetchCostCenterById,
  updateCostCenter,
} from 'features/CostCenter/costCenterSlice';
import { Button } from '@mui/material';
import {
  selectAdminSettingCache,
  updateAdminSettingCache,
} from 'features/global/visibilitySlice';
import { FormTextField } from 'shared/components/FormFields/TextField';
import { resetDropDownState } from 'features/AdminSettingsDropDown/dropDownSlice';
import AuditInfoCard from 'shared/components/_AuditInfoCard';

const ItemCostCenterForm = ({ formAction, itemTagId, auditHeader }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const adminCacheData = useSelector(selectAdminSettingCache);
  // eslint-disable-next-line no-unused-vars
  const [loaded, setLoaded] = useState(
    formAction.id === FormActions.Create.id ? true : false
  );
  const classes = makeFormStyles();
  // eslint-disable-next-line no-unused-vars
  const [initValues, setInitValues] = useState({
    cc_id: '',
    psntl: '',
    description: '',
  });
  const tagNameRef = useRef(null);
  const [auditInfo, setAuditInfo] = useState(null);

  useEffect(() => {
    if (formAction.id === FormActions.Edit.id) {
      const fetchData = async () => {
        var objectData = await dispatch(fetchCostCenterById(itemTagId));
        var fetchedObject = objectData.payload;
        setInitValues({
          cc_id: fetchedObject.cc_id,
          psntl: fetchedObject.psntl,
          description: fetchedObject?.description,
        });
        const { created_by, created_time, modified_time } = fetchedObject;
        setAuditInfo({
          author: created_by,
          creation: created_time,
          modified: modified_time,
        });
        setLoaded(true);
      };
      fetchData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = async (values, setSubmitting, resetForm) => {
    dispatch(activateLoading({ showProgress: true }));
    var resultAction = null;
    switch (formAction.id) {
      case FormActions.Edit.id:
        resultAction = await dispatch(
          updateCostCenter({
            cost_center_id: itemTagId,
            ...values,
          })
        );
        if (!resultAction.error) {
          setInitValues({
            ...initValues,
            cc_id: resultAction?.payload.cc_id,
            psntl: resultAction?.payload.psntl,
            description: resultAction?.payload?.description,
          });
          if (
            adminCacheData?.[EMPLOYEE_CACHE]?.cost_center_id?.value ===
              itemTagId &&
            adminCacheData?.[EMPLOYEE_CACHE]?.cost_center_id?.text !==
              resultAction?.payload.cc_id
          ) {
            dispatch(
              updateAdminSettingCache({
                key: EMPLOYEE_CACHE,
                payload: {
                  cost_center_id: {
                    value: itemTagId,
                    text: resultAction?.payload.cc_id,
                  },
                },
              })
            );
          }
          if (
            adminCacheData?.[OFFICE_CACHE]?.cost_center_id?.value ===
              itemTagId &&
            adminCacheData?.[OFFICE_CACHE]?.cost_center_id?.text !==
              resultAction?.payload.cc_id
          ) {
            dispatch(
              updateAdminSettingCache({
                key: OFFICE_CACHE,
                payload: {
                  cost_center_id: {
                    value: itemTagId,
                    text: resultAction?.payload.cc_id,
                  },
                },
              })
            );
          }

          if (
            adminCacheData?.[COST_CENTER_CACHE]?.cc_id &&
            adminCacheData?.[COST_CENTER_CACHE]?.cc_id !==
              resultAction?.payload.cc_id
          ) {
            dispatch(
              updateAdminSettingCache({
                key: COST_CENTER_CACHE,
                payload: {
                  cc_id: resultAction?.payload.cc_id,
                },
              })
            );
          }
          if (
            adminCacheData?.[COST_CENTER_CACHE]?.psntl &&
            adminCacheData?.[COST_CENTER_CACHE]?.psntl !==
              resultAction?.payload.psntl
          ) {
            dispatch(
              updateAdminSettingCache({
                key: COST_CENTER_CACHE,
                payload: {
                  psntl: resultAction?.payload.psntl,
                },
              })
            );
          }
          // if(cost_center_id){

          // }
          dispatch(closePanel({ formKey: `itemCostCenterForm` }));
        }
        break;
      case FormActions.Create.id:
        resultAction = await dispatch(createCostCenter({ ...values }));
        if (!resultAction.error) {
          await dispatch(fetchCostCenter());
          dispatch(closePanel({ formKey: `itemCostCenterForm` }));
          resetForm();
          if (tagNameRef.current) {
            tagNameRef.current.focus();
          }
        }
        break;
      default:
        break;
    }
    dispatch(resetDropDownState());
    dispatch(deactivateLoading());
    const { message, variant } = snackbarHandler(
      resultAction.meta.requestStatus,
      formAction.label
    );
    enqueueSnackbar(message, {
      action: (key) => <SnackbarDismiss key={key} />,
      variant: variant,
    });
    setSubmitting(false);
  };

  return !loaded ? (
    <Loader styleClassName={classes.loadingSvg} />
  ) : (
    <Formik
      enableReinitialize={true}
      initialValues={{ ...initValues }}
      validationSchema={Yup.object({
        cc_id: Yup.string().required('Required'),
      })}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        handleSubmit(values, setSubmitting, resetForm);
      }}
    >
      {({ isSubmitting }) => (
        <Form className={classes.form}>
          <FormTextField
            inputRef={tagNameRef}
            autoFocus
            label="CC ID"
            name="cc_id"
            placeholder="CC ID"
          />
          <FormTextField label="PSNTL" name="psntl" placeholder="PSNTL" />
          <FormTextField
            multiline="true"
            rows="3"
            label="Description"
            name="description"
            placeholder="Description"
          />
          <AuditInfoCard
            auditInfo={auditInfo}
            auditInfoHeader={auditHeader}
            formAction={formAction}
          />
          <Button
            size="large"
            variant="contained"
            color="primary"
            fullWidth
            type="submit"
            disabled={isSubmitting}
            sx={{ marginTop: '0.5rem' }}
          >
            {formAction.buttonLabel}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export { ItemCostCenterForm };
