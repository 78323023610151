import React, { useCallback, useEffect, useState } from 'react';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/styles';
import DynamicNestedTable from './DynamicNestedTable';
import { Box, IconButton } from '@mui/material';

import {
  activateLoading,
  deactivateLoading,
} from 'features/global/loadingProgressSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
  MdArrowDownward,
  MdArrowUpward,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
} from 'react-icons/md';
import { Each } from 'helpers/Each';

const DynamicTable = ({
  tableId,
  data,
  columns,
  nestedColumns,
  nestedKey,
  tableContainerClass,
  tableClass,
  bodyRowClass,
  bodyCellClass,
  fetchPayLoad,
  pageInfo,
  tablePayload,
  setTablePayload,
}) => {
  const dispatch = useDispatch();
  const [openStates, setOpenStates] = useState(data.map(() => false));
  const [sortBy, setSortBy] = useState(null);
  const [sortDirection, setSortDirection] = useState('ASC');
  const [loadingMore, setLoadingMore] = useState(false);
  const payloadPageInfo = useSelector(pageInfo);

  const [bestFitData, setBestFitData] = useState([...data]);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
      fontSize: '1rem',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: '1rem',
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
      fontSize: '1rem',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const handleAccordionClick = (rowIndex) => {
    const newOpenStates = [...openStates];
    newOpenStates[rowIndex] = !newOpenStates[rowIndex];
    setOpenStates(newOpenStates);
  };

  const handleSort = (columnId) => {
    dispatch(activateLoading());

    if (sortBy === columnId) {
      setSortDirection((prevDirection) =>
        prevDirection === 'ASC' ? 'DESC' : 'ASC'
      );
    } else {
      setSortBy(columnId);
      setSortDirection('ASC');
    }
  };

  useEffect(() => {
    const sortData = async () => {
      const sortedData = [...data].sort((a, b) => {
        const keyA = a[sortBy] === null ? '' : a[sortBy] + '';
        const keyB = b[sortBy] === null ? '' : b[sortBy] + '';

        return (
          (sortDirection === 'ASC' ? 1 : -1) *
          keyA.localeCompare(keyB, undefined, { sensitivity: 'base' })
        );
      });
      await setBestFitData(sortedData);
      dispatch(deactivateLoading());
    };
    sortData();
  }, [sortBy, sortDirection, data, dispatch]);

  const handleScroll = useCallback(
    async (e) => {
      const element = e.target;
      const condition = element.scrollHeight - element.scrollTop;
      if (
        condition === element.clientHeight &&
        !loadingMore &&
        tablePayload?.stop_index + 1 <= payloadPageInfo.TotalCount
      ) {
        setLoadingMore(false);
        let updatedTablePayload = {
          ...tablePayload,
          start_index: tablePayload?.stop_index + 1,
          stop_index: tablePayload?.stop_index + 10,
          other_start_index: tablePayload?.stop_index + 1,
          other_stop_index: tablePayload?.stop_index + 10,
          reset: false,
        };
        setTablePayload({ ...updatedTablePayload });
      } else {
        setLoadingMore(false);
      }
    },
    [loadingMore, payloadPageInfo.TotalCount, setTablePayload, tablePayload]
  );

  useEffect(() => {
    const tableContainer = document.getElementById(tableId);
    if (tableContainer) {
      tableContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (tableContainer) {
        tableContainer.removeEventListener('scroll', handleScroll);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, fetchPayLoad]);

  return (
    <TableContainer
      id={tableId}
      className={tableContainerClass}
      component={Paper}
      sx={{
        height: 'calc(100vh - 295px)',
        minWidth: '500px',
        overflow: 'auto',
      }}
    >
      <Table className={tableClass} aria-label="dynamic table">
        <TableHead sx={{ position: 'sticky', top: '0', zIndex: '2' }}>
          <StyledTableRow>
            {columns && (
              <Each
                of={columns}
                render={(column, index) => (
                  <StyledTableCell
                    style={{
                      width: column.width,
                      minWidth: column.minWidth,
                      textAlign: column.align,
                    }}
                  >
                    {typeof column?.handleSort === 'function' ? (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={() =>
                          handleSort(
                            column?.id,
                            column?.sortDirection === 'ASC' &&
                              column?.id === sortBy
                              ? 'DESC'
                              : 'ASC'
                          )
                        }
                      >
                        <>
                          <span
                            style={{
                              fontWeight:
                                column?.id === sortBy ? 'bold' : 'normal',
                            }}
                          >
                            {column.label}
                          </span>
                          {sortDirection === 'ASC' && column?.id === sortBy ? (
                            <MdArrowUpward
                              style={{
                                fontSize: '1rem',
                                marginLeft: '0.5rem',
                                fill:
                                  column?.id === sortBy ? '#60a6b3' : '#ffffff',
                              }}
                            />
                          ) : (
                            <MdArrowDownward
                              sx={{
                                fontSize: '1rem',
                                marginLeft: '0.5rem',
                                fill:
                                  column?.id === sortBy ? '#60a6b3' : '#ffffff',
                              }}
                            />
                          )}
                        </>
                      </Box>
                    ) : (
                      <span>{column.label}</span>
                    )}
                  </StyledTableCell>
                )}
              />
            )}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {bestFitData && bestFitData.length > 0 && (
            <Each
              of={bestFitData}
              render={(row, rowIndex) => (
                <>
                  <StyledTableRow className={bodyRowClass}>
                    {columns && columns?.length > 0 && (
                      <Each
                        of={columns}
                        render={(column, columnIndex) =>
                          column.accordion ? (
                            <StyledTableCell
                              key={columnIndex}
                              className={bodyCellClass}
                              style={{
                                textAlign: column?.align
                                  ? column.align
                                  : 'left',
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: column?.justifyContent
                                    ? column.justifyContent
                                    : 'center',
                                  alignItems: 'center',
                                }}
                              >
                                {column?.customRender(row)
                                  ? column.customRender(row)
                                  : row[column?.id]}
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                  onClick={() => handleAccordionClick(rowIndex)}
                                >
                                  {openStates[rowIndex] ? (
                                    <MdKeyboardArrowUp
                                      style={{
                                        fontSize: '1.5rem',
                                      }}
                                    />
                                  ) : (
                                    <MdKeyboardArrowDown
                                      style={{
                                        fontSize: '1.5rem',
                                      }}
                                    />
                                  )}
                                </IconButton>
                              </Box>
                            </StyledTableCell>
                          ) : (
                            <StyledTableCell
                              key={columnIndex}
                              className={bodyCellClass}
                              style={{
                                textAlign: column.align,
                              }}
                            >
                              {row && row[column?.id]}
                            </StyledTableCell>
                          )
                        }
                      />
                    )}
                  </StyledTableRow>
                  <TableRow>
                    {nestedColumns && nestedColumns?.length > 0 && (
                      <DynamicNestedTable
                        data={row}
                        nestedKey={nestedKey}
                        nestedColumns={nestedColumns}
                        accordionOpen={openStates[rowIndex]}
                        colSpanLength={columns?.length}
                      />
                    )}
                  </TableRow>
                </>
              )}
            />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DynamicTable;
