import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  activateLoading,
  deactivateLoading,
} from 'features/global/loadingProgressSlice';
import { resetDropDownState } from 'features/AdminSettingsDropDown/dropDownSlice';
import { LoadingStatus } from 'helpers';
import {
  fetchDepotReport,
  getDepotReportStatus,
  resetDepotReportState,
} from 'features/depotReports/depotReportSlice';
import { DepotTreeMap } from 'components/DepotTreeMap';

const DepotReports = () => {
  //#region Constants
  const dispatch = useDispatch();
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  const [itemDepotReportPayload, setItemDepotReportPayload] = useState({
    cost_center_id: null,
    office_id: null,
    job_id: null,
    job_role_id: null,
    custom_expiry_date: null,
    supervisor_employee_id: null,
    capability_id: null,
    report_expiry: null,
  });

  const getReportData = async (payload) => {
    dispatch(activateLoading());
    await dispatch(fetchDepotReport({ ...payload }));
    dispatch(deactivateLoading());
  };
  //#endregion State

  //#region Selectors
  const itemDepotReportStatus = useSelector(getDepotReportStatus);
  //#endregion Selectors

  //#region Refs
  const ref = useRef(false);
  //#endregion Refs

  //#region Effects
  useEffect(() => {
    if (ref.current === true) {
      getReportData({ ...itemDepotReportPayload });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemDepotReportPayload]);

  useEffect(() => {
    return async () => {
      await dispatch(resetDepotReportState());
      await dispatch(resetDropDownState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (itemDepotReportStatus === LoadingStatus.Idle) {
      getReportData({
        ...itemDepotReportPayload,
      });
    }
    ref.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemDepotReportStatus, dispatch]);
  //#endregion Effects

  //#region Methods
  //#endregion Methods

  //#region Render time calcs
  //#endregion Render time calcs

  //#region Render

  return (
    <DepotTreeMap
      itemDepotReportPayload={itemDepotReportPayload}
      setItemDepotReportPayload={setItemDepotReportPayload}
    />
  );
  //#endregion Render
};
export { DepotReports };
