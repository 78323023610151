import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  activateLoading,
  deactivateLoading,
} from 'features/global/loadingProgressSlice';
import { JOB_ROLE_CACHE, LoadingStatus } from 'helpers';

import { ItemJobRoleTable } from './ItemJobRoleTable';
import {
  fetchJobRoles,
  getJobRolePageInfo,
  getJobRoleStatus,
  resetJobRoleState,
  selectJobRole,
} from 'features/jobRole/jobRoleSlice';
import { closePanel } from 'features/common/formSlice';
import {
  selectAdminSettingCache,
  updateAdminSettingCache,
} from 'features/global/visibilitySlice';
import { mapValues } from 'helpers/mapValues';
import { resetDropDownState } from 'features/AdminSettingsDropDown/dropDownSlice';

const JobRolesManagementTab = ({ userJobRole }) => {
  //#region Constants
  const dispatch = useDispatch();
  const getCacheData = useSelector(selectAdminSettingCache);
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  const [itemJobRolePayload, setItemJobRolePayload] = useState({
    ...getCacheData?.[JOB_ROLE_CACHE],
    reset: true,
    startIndex: 0,
    stopIndex: 19,
  });
  const [sortBy, setSortBy] = useState(
    getCacheData?.[JOB_ROLE_CACHE]?.sort?.slice(0, 1) === '-'
      ? getCacheData?.[JOB_ROLE_CACHE]?.sort.slice(1)
      : getCacheData?.[JOB_ROLE_CACHE]?.sort || 'display_name'
  );

  const [sortDirection, setSortDirection] = useState(
    getCacheData?.[JOB_ROLE_CACHE]?.sort?.[0] !== '-' ? 'ASC' : 'DESC'
  );

  //#endregion State

  //#region Selectors
  const jobRoles = useSelector(selectJobRole);
  const itemJobRoleStatus = useSelector(getJobRoleStatus);
  const itemJobRolePageInfo = useSelector(getJobRolePageInfo);
  //#endregion Selectors

  //#region Refs
  const ref = useRef(false);
  //#endregion Refs

  //#region Effects
  const getJobRoleData = async (payload) => {
    dispatch(activateLoading());
    let updatedPayload = mapValues(payload);
    await dispatch(fetchJobRoles({ ...updatedPayload }));
    dispatch(updateAdminSettingCache({ key: JOB_ROLE_CACHE, payload }));
    dispatch(closePanel({ formKey: `itemJobRoleForm` }));
    dispatch(deactivateLoading());
  };

  useEffect(() => {
    if (ref.current === true) {
      getJobRoleData({ ...itemJobRolePayload });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemJobRolePayload]);

  useEffect(() => {
    if (itemJobRoleStatus === LoadingStatus.Idle) {
      getJobRoleData({
        ...itemJobRolePayload,
        startIndex: 0,
        stopIndex: 19,
      });
    }
    ref.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemJobRoleStatus, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetJobRoleState());
      dispatch(resetDropDownState());
    };
  }, []);

  //#endregion Effects

  //#region Methods
  const setSorting = (_sortBy, _sortDirection) => {
    setSortBy(_sortBy);
    setSortDirection(_sortDirection);
    setItemJobRolePayload({
      ...itemJobRolePayload,
      sort: `${_sortDirection === 'ASC' ? '' : '-'}${_sortBy}`,
    });
  };
  //#endregion Methods

  //#region Render time calcs
  //#endregion Render time calcs

  //#region Render

  return (
    <>
      <ItemJobRoleTable
        jobRoles={jobRoles}
        pageInfo={itemJobRolePageInfo}
        itemJobRolePayload={itemJobRolePayload}
        setItemJobRolePayload={setItemJobRolePayload}
        sortDirection={sortDirection}
        sortBy={sortBy}
        handleSort={(_sortBy, _sortDirection) =>
          setSorting(_sortBy, _sortDirection)
        }
        user={userJobRole}
      />
    </>
  );
  //#endregion Render
};
export { JobRolesManagementTab };
