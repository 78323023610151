import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { officeService } from 'features/services/office.service';
import { LoadingStatus, SavingStatus } from 'helpers';

const initialState = {
  status: LoadingStatus.Idle,
  error: null,
  offices: [],
  officeStatus: LoadingStatus.Idle,
  officePageInfo: {},
  displayOffice: {},
  officeUsage: [],
  loadedOfficeMap: {},
  officeImportInfo: [],
  officeImportInfoStatus: LoadingStatus.Idle,
};

export const fetchOffice = createAsyncThunk(
  'office/fetchOffice',
  async (params) =>
    await officeService.getOffices(
      params.startIndex,
      params.stopIndex,
      params.searchString,
      params.sort,
      params.cost_center_id,
      params.directorate,
      params.symbol
    )
);

export const fetchOfficeById = createAsyncThunk(
  'office/fetchOfficeById',
  async (officeId) => {
    const response = await officeService.getById(officeId);
    response.id = officeId;
    return response;
  }
);

export const fetchOfficeUsageById = createAsyncThunk(
  'office/fetchOfficeUsageById',
  async (officeId) => await officeService.getOfficeUsageById(officeId)
);

export const createOffice = createAsyncThunk(
  'office/createOffice',
  async (params) => await officeService.create(params)
);

export const updateOffice = createAsyncThunk(
  'office/updateOffice',
  async (params) => await officeService.update(params)
);

export const deleteOffice = createAsyncThunk(
  'office/deleteOffice',
  async (office_id) => {
    const response = await officeService.delete(office_id);
    return response;
  }
);

export const deleteOfficeArray = createAsyncThunk(
  'office/deleteOfficeArray',
  async (officeId) => {
    const response = await officeService.deleteArray(officeId);
    return response;
  }
);

export const getOfficeImportSampleCsv = createAsyncThunk(
  'office/getOfficeImportSampleCsv',
  async () => await officeService.getOfficeImportCsv()
);

export const uploadOfficeImportCsv = createAsyncThunk(
  'office/uploadOfficeImportCsv',
  async (params) => await officeService.uploadOfficeImportCsv(params)
);

export const exportOfficeCsv = createAsyncThunk(
  'office/exportOfficeCsv',
  async (params) =>
    await officeService.exportOfficeCsv(
      params.cost_center_id,
      params.directorate,
      params.symbol
    )
);

export const getImportOfficeHelp = createAsyncThunk(
  'office/getImportOfficeHelp',
  async (params) => await officeService.getOfficeImportHelp(params)
);

const officeSlice = createSlice({
  name: 'offices',
  initialState,
  reducers: {
    resetOfficeState: (state) => initialState,
    resetOfficeInfoState: (state) => {
      state.officeImportInfoStatus = LoadingStatus.Idle;
    },
  },
  extraReducers: {
    [fetchOffice.pending]: (state, action) => {
      state.officeStatus = LoadingStatus.Loading;
    },
    [fetchOffice.fulfilled]: (state, action) => {
      state.officePageInfo = action.payload.pageInfo;
      state.officeStatus = LoadingStatus.Loaded;

      if (action.meta.arg.reset) {
        state.loadedOfficeMap = {};
      }

      const startIndex = action.meta.arg.startIndex;
      const stopIndex = startIndex + action.payload?.offices.length - 1;

      for (var i = startIndex; i <= stopIndex; i++) {
        state.loadedOfficeMap[i] = LoadingStatus.Loaded;
      }

      // In case we didn't load as many as requested
      for (
        let j = stopIndex + 1;
        j <= action.payload?.pageInfo?.TotalCount;
        j++
      ) {
        delete state.loadedOfficeMap[j];
      }

      if (action.meta.arg.reset) {
        state.offices = action.payload.offices;
      } else {
        state.offices = state.offices.concat(action.payload.offices);
      }
    },
    [fetchOffice.rejected]: (state, action) => {
      state.officeStatus = LoadingStatus.Failed;
    },
    [createOffice.fulfilled]: (state, action) => {
      state.officeStatus = LoadingStatus.Idle;
    },
    [updateOffice.fulfilled]: (state, action) => {
      state.officeStatus = LoadingStatus.Idle;
    },
    [deleteOffice.fulfilled]: (state, action) => {
      state.officeStatus = LoadingStatus.Idle;
    },
    [deleteOfficeArray.fulfilled]: (state, action) => {
      state.officeStatus = LoadingStatus.Idle;
    },
    [deleteOfficeArray.rejected]: (state, action) => {
      state.status = SavingStatus.Failed;
      state.error = action.error.message;
    },
    [fetchOfficeById.fulfilled]: (state, action) => {
      state.displayCOffice = action.payload;
    },
    [fetchOfficeUsageById.fulfilled]: (state, action) => {
      state.officeUsage = action.payload;
    },
    [getOfficeImportSampleCsv.fulfilled]: (state, action) => {
      if (action?.payload) {
        const url = window.URL.createObjectURL(new Blob([action.payload]));
        const link = document.createElement('a');
        link.href = url;
        let downloadName =
          action.payload.headers?.filename ?? 'office_import.csv';
        link.setAttribute('download', downloadName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    },
    [getOfficeImportSampleCsv.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    [exportOfficeCsv.fulfilled]: (state, action) => {
      if (action?.payload) {
        const url = window.URL.createObjectURL(new Blob([action.payload]));
        const link = document.createElement('a');
        link.href = url;
        let downloadName =
          action.payload.headers?.filename ?? 'office_export.csv';
        link.setAttribute('download', downloadName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    },
    [exportOfficeCsv.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    [getImportOfficeHelp.pending]: (state, action) => {
      state.officeImportInfoStatus = LoadingStatus.Loading;
    },
    [getImportOfficeHelp.fulfilled]: (state, action) => {
      state.officeImportInfo = action.payload;
      state.officeImportInfoStatus = LoadingStatus.Loaded;
    },
    [getImportOfficeHelp.rejected]: (state, action) => {
      state.officeImportInfoStatus = LoadingStatus.Failed;
    },
  },
});

export const selectOffice = (state) => state.offices.offices;
export const getOfficeStatus = (state) => state.offices.officeStatus;
export const getOfficePageInfo = (state) => state.offices.officePageInfo;
export const getOfficeUsage = (state) => state.offices.officeUsage;
export const getOfficeInfoStatus = (state) =>
  state.offices.officeImportInfoStatus;

export const { resetOfficeState, resetOfficeInfoState } = officeSlice.actions;

export default officeSlice.reducer;
