import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { bestFitService } from 'features/services/bestFit.service';
import { LoadingStatus } from 'helpers';

const initialState = {
  status: LoadingStatus.Idle,
  error: null,
  bestFits: [],
  bestFitStatus: LoadingStatus.Idle,
  bestFitPageInfo: {},
  displayBestFit: {},
  bestFitUsage: [],
  loadedBestFitMap: {},

  otherBestFits: [],
  otherBestFitStatus: LoadingStatus.Idle,
  otherBestFitPageInfo: {},
  displayOtherBestFit: {},
  otherBestFitUsage: [],
  loadedOtherBestFitMap: {},
};

export const fetchBestFits = createAsyncThunk(
  'bestFit/fetchBestFits',
  async (params = {}, { getState }) => {
    try {
      const response = await bestFitService.getBestFit(
        params.job_id,
        params.job_role_id,
        params.cost_center,
        params.skillIds,
        params.office_id,
        params.percentage_match,
        params.command_id,
        params.depot_id,
        params.start_index,
        params.stop_index,
        params.sort
      );

      return response;
    } catch (error) {
      console.error('Error fetching Best Fits:', error);
      throw error;
    }
  }
);

export const fetchOtherBestFit = createAsyncThunk(
  'bestFit/fetchOtherBestFit',
  async (params = {}, { getState }) => {
    try {
      const response = await bestFitService.getOtherBestFit(
        params.job_id,
        params.job_role_id,
        params.cost_center,
        params.skillIds,
        params.office_id,
        params.percentage_match,
        params.command_id,
        params.depot_id,
        params.start_index,
        params.stop_index,
        params.sort
      );

      return response;
    } catch (error) {
      console.error('Error fetching Best Fits:', error);
      throw error;
    }
  }
);

const bestFitSlice = createSlice({
  name: 'bestFits',
  initialState,
  reducers: {
    resetBestFitState: (state) => initialState,
    resetOtherBestFitState: (state) => {
      state.otherBestFitStatus = LoadingStatus.Idle;
    },
    resetBestFitStatus: (state) => {
      state.bestFitStatus = LoadingStatus.Idle;
    },
  },
  extraReducers: {
    [fetchBestFits.pending]: (state, action) => {
      state.bestFitStatus = LoadingStatus.Loading;
    },
    [fetchBestFits.fulfilled]: (state, action) => {
      state.bestFitPageInfo = action.payload.pageInfo;
      state.bestFitStatus = LoadingStatus.Loaded;

      if (action.meta.arg.reset) {
        state.loadedBestFitMap = {};
      }

      const startIndex = action.meta.arg.startIndex;
      const stopIndex = startIndex + action.payload?.bestFits?.length - 1;

      for (var i = startIndex; i <= stopIndex; i++) {
        state.loadedBestFitMap[i] = LoadingStatus.Loaded;
      }

      for (
        let j = stopIndex + 1;
        j <= action.payload?.pageInfo?.TotalCount;
        j++
      ) {
        delete state.loadedBestFitMap[j];
      }
      if (action.meta.arg.reset) {
        state.bestFits = action.payload.bestFits;
      } else {
        state.bestFits = state.bestFits.concat(action.payload.bestFits);
      }
    },
    [fetchBestFits.rejected]: (state, action) => {
      state.bestFitStatus = LoadingStatus.Failed;
    },
    [fetchOtherBestFit.pending]: (state, action) => {
      state.otherBestFitStatus = LoadingStatus.Loading;
    },
    [fetchOtherBestFit.fulfilled]: (state, action) => {
      state.otherBestFitPageInfo = action.payload.pageInfo;
      state.otherBestFitStatus = LoadingStatus.Loaded;

      if (action.meta.arg.reset) {
        state.loadedOtherBestFitMap = {};
      }

      const startIndex = action.meta.arg.startIndex;
      const stopIndex = startIndex + action.payload?.otherBestFits?.length - 1;

      for (var i = startIndex; i <= stopIndex; i++) {
        state.loadedOtherBestFitMap[i] = LoadingStatus.Loaded;
      }

      for (
        let j = stopIndex + 1;
        j <= action.payload?.pageInfo?.TotalCount;
        j++
      ) {
        delete state.loadedOtherBestFitMap[j];
      }
      if (action.meta.arg.reset) {
        state.otherBestFits = action.payload.otherBestFits;
      } else {
        state.otherBestFits = state.otherBestFits.concat(
          action.payload.otherBestFits
        );
      }
    },
    [fetchOtherBestFit.rejected]: (state, action) => {
      state.otherBestFitStatus = LoadingStatus.Failed;
    },
  },
});

export const selectBestFit = (state) => state.bestFits.bestFits;
export const getBestFitStatus = (state) => state.bestFits.bestFitStatus;
export const getBestFitPageInfo = (state) => state.bestFits.bestFitPageInfo;
export const getBestFitUsage = (state) => state.bestFits.bestFitUsage;
export const getBestFitLoadedMap = (state) => state.bestFits.loadedBestFitMap;

export const selectOtherBestFit = (state) => state.bestFits.otherBestFits;
export const getOtherBestFitStatus = (state) =>
  state.bestFits.otherBestFitStatus;
export const getOtherBestFitPageInfo = (state) =>
  state.bestFits.otherBestFitPageInfo;
export const getOtherBestFitUsage = (state) => state.bestFits.otherBestFitUsage;
export const getOtherBestFitLoadedMap = (state) =>
  state.bestFits.loadedOtherBestFitMap;

export const { resetBestFitState, resetOtherBestFitState, resetBestFitStatus } =
  bestFitSlice.actions;

export default bestFitSlice.reducer;
