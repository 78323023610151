import { useCallback, useEffect, useRef, useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { makeFormStyles } from 'forms/style';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';

import { SelectionAutoComplete } from 'shared/components/_Autocomplete/SelectionAutocomplete';
import {
  activateLoading,
  deactivateLoading,
} from 'features/global/loadingProgressSlice';
import { CAPABILITY_ID, JOB_ID, JOB_ROLE_ID, LoadingStatus } from 'helpers';

import {
  fetchCapabilityDropDown,
  fetchJobRolesByJobId,
  fetchJobsDropDown,
  getCapabilityDropDownStatus,
  getJobDropDownStatus,
  getJobRolesByJobIdDropDownStatus,
  resetDropDown,
  selectCapabilitiesDropDown,
  selectJobDropDown,
  selectJobRoleByJobIdDropDown,
} from 'features/AdminSettingsDropDown/dropDownSlice';
import { Box, Button, Typography } from '@mui/material';
import { GroupTrainingSkillModal } from 'components/GroupTrainingSkillModal';
import {
  fetchGroupTraining,
  getGroupTrainingPageInfo,
  getGroupTrainingStatus,
  resetGroupTrainingState,
  selectCapSkill,
  selectGroupTraining,
} from 'features/groupTraining/groupTrainingSlice';
import { GroupAttendeesTable } from 'components/GroupTrainingSkillModal/GroupAttendeesTable';
import { useSearchParams } from 'react-router-dom';
import { selectActiveUser } from 'features/account/accountSlice';

const GroupTraining = () => {
  //#region Constants
  const classes = makeFormStyles();
  const dispatch = useDispatch();

  const getGroupTraining = useCallback(
    async (payload) => {
      dispatch(activateLoading());
      await dispatch(
        fetchGroupTraining({
          ...payload,
          reset: true,
          startIndex: 0,
          stopIndex: 19,
        })
      );
      dispatch(deactivateLoading());
    },
    [dispatch]
  );

  //#endregion Constants

  //#region Hooks
  const [searchParams] = useSearchParams();
  // const { setFieldValue } = useFormikContext();
  //#endregion Hooks

  //#region State

  const initValues = {
    job_id: searchParams ? searchParams.get('job_id') : null,
    job_role_id: searchParams ? searchParams.get('job_role_id') : null,
    capability_id: searchParams ? searchParams.get('capability_id') : null,
  };
  const [trainingDropDownData, setTrainingDropDownData] = useState({
    ...initValues,
  });
  const [isSkillModalOpen, setIsSkillModalOpen] = useState(false);
  const [isAttendTable, setIsAttendTable] = useState(false);
  const [groupTrainingPayload, setGroupTrainingPayload] = useState();
  const [sortBy, setSortBy] = useState('');
  const [sortDirection, setSortDirection] = useState('');
  //#endregion State

  //#region Selectors
  const getGroupTrainingData = useSelector(selectGroupTraining);
  const capSkillData = useSelector(selectCapSkill);
  const groupTrainingPageInfo = useSelector(getGroupTrainingPageInfo);
  const groupTrainingStatus = useSelector(getGroupTrainingStatus);
  const user = useSelector(selectActiveUser);
  //#endregion Selectors

  //#region Refs
  const ref = useRef(false);
  //#endregion Refs

  //#region Effects
  useEffect(() => {
    async function fetchMyAPI() {
      dispatch(activateLoading());
      await dispatch(
        fetchCapabilityDropDown({
          job_id: trainingDropDownData?.job_id,
          job_role_id: trainingDropDownData?.job_role_id,
        })
      );
      dispatch(deactivateLoading());
    }

    async function fetchJobRole() {
      dispatch(activateLoading());
      await dispatch(
        fetchJobRolesByJobId({
          job_id: trainingDropDownData?.job_id,
        })
      );
      await dispatch(
        fetchCapabilityDropDown({
          job_id: trainingDropDownData?.job_id,
        })
      );
      dispatch(deactivateLoading());
    }

    if (trainingDropDownData.job_id && trainingDropDownData.job_role_id) {
      fetchMyAPI();
    } else if (trainingDropDownData.job_id) {
      fetchJobRole();
    } else {
      fetchMyAPI();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trainingDropDownData]);

  useEffect(() => {
    if (ref.current === true && isAttendTable) {
      getGroupTraining({
        ...groupTrainingPayload,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupTrainingPayload]);

  useEffect(() => {
    if (isAttendTable && groupTrainingStatus === LoadingStatus.Idle) {
      getGroupTraining({
        ...groupTrainingPayload,
      });
    }
    ref.current = true;
  }, [
    groupTrainingStatus,
    dispatch,
    isAttendTable,
    getGroupTraining,
    groupTrainingPayload,
  ]);

  useEffect(() => {
    return () => {
      dispatch(resetDropDown());
      dispatch(resetGroupTrainingState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //#endregion Effects

  //#region Methods
  const setDependantIds = async (value, setFieldValue, key) => {
    if (key === JOB_ID && value) {
      setTrainingDropDownData((prevValue) => ({
        ...prevValue,
        job_id: value.value,
        job_role_id: null,
        capability_id: null,
      }));
      setFieldValue('job_id', value.value);
      setFieldValue('job_role_id', null);
      setFieldValue('capability_id', null);
    }
    if (key === JOB_ROLE_ID && value) {
      setTrainingDropDownData((prevValue) => ({
        ...prevValue,
        job_role_id: value.value,
        capability_id: null,
      }));
      setFieldValue('job_role_id', value.value);
      setFieldValue('capability_id', null);
    }
    if (key === CAPABILITY_ID && value) {
      setFieldValue('capability_id', value.value);
    }
  };

  const removeDependantIds = async (setFieldValue, key) => {
    dispatch(activateLoading());
    if (key === JOB_ID) {
      setFieldValue('job_id', null);
      setTrainingDropDownData((prevValue) => ({
        ...prevValue,
        job_id: null,
      }));
      if (trainingDropDownData?.job_role_id) {
        await dispatch(
          fetchCapabilityDropDown({
            job_role_id: trainingDropDownData?.job_role_id,
          })
        );
      }
      await dispatch(fetchJobRolesByJobId());
    }
    if (key === JOB_ROLE_ID) {
      setFieldValue('job_role_id', null);
      setTrainingDropDownData((prevValue) => ({
        ...prevValue,
        job_role_id: null,
      }));
      if (trainingDropDownData?.job_id) {
        await dispatch(
          fetchCapabilityDropDown({
            job_id: trainingDropDownData?.job_id,
          })
        );
      }
    }
    dispatch(deactivateLoading());
  };

  const handleSkillModal = async (capabilityId) => {
    if (capabilityId) {
      dispatch(activateLoading());
      setIsSkillModalOpen(true);
      dispatch(deactivateLoading());
    }
  };

  const setSorting = (_sortBy, _sortDirection) => {
    setSortBy(_sortBy);
    setSortDirection(_sortDirection);
    setGroupTrainingPayload({
      ...groupTrainingPayload,
      sort: `${_sortDirection === 'ASC' ? '' : '-'}${_sortBy}`,
    });
  };
  //#endregion Methods

  //#region Render time calcs
  //#endregion Render time calcs

  //#region Methods
  return (
    <>
      {!isAttendTable ? (
        <Box
          sx={{
            maxWidth: '600px',
            minHeight: '70vh',
            display: 'flex',
            margin: '0 auto',
          }}
        >
          <Formik
            enableReinitialize={true}
            initialValues={{ ...initValues }}
            validationSchema={Yup.object({
              capability_id: Yup.number().required('Required'),
            })}
          >
            {({ values, setFieldValue, errors }) => (
              <Form style={{ width: '100%' }} className={classes.form}>
                <Box sx={{ pb: '1rem', textAlign: 'center' }}>
                  <Typography variant="h4" mb={'0.5rem'}>
                    Group Training Tracker
                  </Typography>
                  <Typography variant="body1">
                    The Group Training tracker allows managers and training
                    facilitators to capture group training attendance. After
                    completing this process attendance records can be accessed
                    within SMARTS for reporting.
                  </Typography>
                </Box>
                <Field className={classes.input} name={JOB_ID}>
                  {({ field: { value }, form: { setFieldValue } }) => (
                    <SelectionAutoComplete
                      title={`Select ${user.label_job}`}
                      keyProperty="value"
                      nameProperty="text"
                      entityIds={value}
                      onCustomChange={(value) => {
                        setDependantIds(value, setFieldValue, JOB_ID);
                      }}
                      setEntityIds={setFieldValue}
                      entitySelector={selectJobDropDown}
                      entityStatusSelector={getJobDropDownStatus}
                      fetchEntityPage={fetchJobsDropDown}
                      formField={JOB_ID}
                      multiSelection={false}
                      selectStyle={{
                        backgroundColor: '#ffffff',
                      }}
                      showCloseItemEdit={true}
                      hideCheckIcon={true}
                      cancelItemTagEdit={() => {
                        removeDependantIds(setFieldValue, JOB_ID);
                      }}
                    />
                  )}
                </Field>
                <Field className={classes.input} name={JOB_ROLE_ID}>
                  {({ field: { value }, form: { setFieldValue } }) => (
                    <SelectionAutoComplete
                      title={`Select ${user.label_job_role}`}
                      keyProperty="value"
                      nameProperty="text"
                      entityIds={value}
                      setEntityIds={setFieldValue}
                      entitySelector={selectJobRoleByJobIdDropDown}
                      entityStatusSelector={getJobRolesByJobIdDropDownStatus}
                      onCustomChange={(value) => {
                        setDependantIds(value, setFieldValue, JOB_ROLE_ID);
                      }}
                      cancelItemTagEdit={() => {
                        removeDependantIds(setFieldValue, JOB_ROLE_ID);
                      }}
                      showCloseItemEdit={true}
                      hideCheckIcon={true}
                      fetchEntityPage={fetchJobRolesByJobId}
                      formField={JOB_ROLE_ID}
                      multiSelection={false}
                      selectStyle={{
                        backgroundColor: '#ffffff',
                      }}
                    />
                  )}
                </Field>
                <Field className={classes.input} name={CAPABILITY_ID}>
                  {({ field: { value }, form: { setFieldValue } }) => (
                    <SelectionAutoComplete
                      title={`Select ${user.label_capability}`}
                      keyProperty="value"
                      nameProperty="text"
                      entityIds={value}
                      setEntityIds={setFieldValue}
                      entitySelector={selectCapabilitiesDropDown}
                      entityStatusSelector={getCapabilityDropDownStatus}
                      onCustomChange={(value) => {
                        setDependantIds(value, setFieldValue, CAPABILITY_ID);
                      }}
                      fetchEntityPage={fetchCapabilityDropDown}
                      formField={CAPABILITY_ID}
                      multiSelection={false}
                      selectStyle={{
                        backgroundColor: '#ffffff',
                      }}
                    />
                  )}
                </Field>
                <div className={classes.formErrorDiv}>
                  {errors?.capability_id && (
                    <Typography
                      variant="subtitle2"
                      className={classes.inputErr}
                    >
                      {errors.capability_id}
                    </Typography>
                  )}
                </div>
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => handleSkillModal(values?.capability_id)}
                  type="submit"
                  sx={{ marginTop: '0.5rem' }}
                >
                  Start
                </Button>
                {isSkillModalOpen && (
                  <GroupTrainingSkillModal
                    setIsSkillModalOpen={setIsSkillModalOpen}
                    isSkillModalOpen={isSkillModalOpen}
                    trainingDropDownData={trainingDropDownData}
                    values={values}
                    setFieldValue={setFieldValue}
                    initValues={initValues}
                    setIsAttendTable={setIsAttendTable}
                    setGroupTrainingPayload={setGroupTrainingPayload}
                    groupTrainingPayload={groupTrainingPayload}
                  />
                )}
              </Form>
            )}
          </Formik>
        </Box>
      ) : (
        <GroupAttendeesTable
          groupTrainingData={getGroupTrainingData?.employees}
          pageInfo={groupTrainingPageInfo}
          groupTrainingPayload={groupTrainingPayload}
          setGroupTrainingPayload={setGroupTrainingPayload}
          sortDirection={sortDirection}
          sortBy={sortBy}
          setIsAttendTable={setIsAttendTable}
          handleSort={(_sortBy, _sortDirection) =>
            setSorting(_sortBy, _sortDirection)
          }
          user={user}
          capSkillData={capSkillData}
        />
      )}
    </>
  );
  //#endregion Render
};
export { GroupTraining };
