import { useCallback, useRef } from 'react';
import { Grid } from '@mui/material';
import { Formik, Form, Field } from 'formik';

import { SelectionAutoComplete } from 'shared/components/_Autocomplete/SelectionAutocomplete';
import {
  fetchCostCenterDropDown,
  fetchJobRolesByJobId,
  getCostCenterDropDownStatus,
  getJobRolesByJobIdDropDownStatus,
  selectCostCenterDropDown,
  selectJobRoleByJobIdDropDown,
} from 'features/AdminSettingsDropDown/dropDownSlice';
import { useSelector } from 'react-redux';
import { selectActiveUser } from 'features/account/accountSlice';
import { FilterAccordion } from 'shared/components/_Accordion';
import { clearFilter } from 'helpers/clearFilter';
import { useDebounce } from 'hooks/useDebounce';
import { FormTextField } from 'shared/components/FormFields/TextField';
import { sanitizeEnotationInput } from 'hooks/validateAndFormateColor';

const GroupTrainingFilter = ({
  filterTableData,
  setFilterTableData,
  setGroupTrainingPayload,
  groupTrainingPayload,
}) => {
  //#region Constants
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  const initValues = {
    employee_name: '',
    employee_dod_id: '',
    series_id: '',
    job_role_id: '',
    supervisor_name: '',
    cost_center: null,
    directorate: '',
  };
  //#endregion State

  //#region Selectors
  const user = useSelector(selectActiveUser);
  //#endregion Selectors

  //#region Refs
  const formRef = useRef();
  //#endregion Refs

  //#region Effects
  //#endregion Effects

  //#region Methods
  const handleSearch = useCallback(
    useDebounce(
      (
        employee_name,
        employee_dod_id,
        series_id,
        job_role_id,
        supervisor_name,
        cost_center,
        directorate
      ) => {
        setGroupTrainingPayload({
          ...groupTrainingPayload,
          employee_name,
          employee_dod_id,
          series_id,
          job_role_id: job_role_id?.value,
          supervisor_name,
          cost_center: cost_center?.value,
          directorate,
        });
        setFilterTableData({
          ...filterTableData,
          employee_name: { text: employee_name },
          employee_dod_id: { text: employee_dod_id },
          series_id: { text: series_id },
          job_role_id: { text: job_role_id?.text },
          supervisor_name: { text: supervisor_name },
          cost_center: { text: cost_center?.text },
          directorate: { text: directorate },
        });
      },
      1000
    ),
    [groupTrainingPayload, filterTableData]
  );

  const removeHandleSearch = useDebounce(
    (
      employee_name,
      employee_dod_id,
      series_id,
      job_role_id,
      supervisor_name,
      cost_center,
      directorate
    ) => {
      setGroupTrainingPayload({
        ...groupTrainingPayload,
        employee_name,
        employee_dod_id: employee_dod_id,
        series_id,
        job_role_id: job_role_id?.value,
        supervisor_name,
        cost_center: cost_center?.value,
        directorate,
      });
      setFilterTableData({
        ...filterTableData,
        employee_name: employee_name ? { text: employee_name } : null,
        employee_dod_id: employee_dod_id ? { text: employee_dod_id } : null,
        series_id: series_id ? { text: series_id } : null,
        job_role_id: job_role_id ? { text: job_role_id?.text } : null,
        supervisor_name: supervisor_name ? { text: supervisor_name } : null,
        cost_center: { text: cost_center?.text },
        directorate: directorate ? { text: directorate } : null,
      });
    },
    1000
  );
  const handleResetFilters = () => {
    const isFilterData = clearFilter(formRef);
    if (isFilterData) {
      formRef?.current?.resetForm();
      setGroupTrainingPayload({
        ...groupTrainingPayload,
        employee_name: '',
        employee_dod_id: '',
        series_id: '',
        job_role_id: '',
        supervisor_name: '',
        cost_center: null,
        directorate: '',
      });
      setFilterTableData({});
    }
  };
  //#endregion Methods

  //#region Render time calcs
  //#endregion Render time calcs

  //#region Render

  return (
    <>
      <FilterAccordion
        filteredTitles={filterTableData}
        expandedId="job-role"
        styles={{ margin: '1rem 0' }}
        onResetFilters={handleResetFilters}
      >
        <Formik
          enableReinitialize={true}
          initialValues={{ ...initValues }}
          innerRef={formRef}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Grid container spacing={'1rem'}>
                <Grid item xs={12} md={6} lg={3}>
                  <FormTextField
                    name="employee_name"
                    label="Employee Name"
                    placeholder="Employee Name"
                    fullWidth
                    onChange={(e) => {
                      const searchTerm = e.target.value;
                      setFieldValue('employee_name', searchTerm);
                      handleSearch(
                        searchTerm,
                        values?.employee_dod_id,
                        values?.series_id,
                        values?.job_role_id,
                        values?.supervisor_name,
                        values?.cost_center,
                        values?.directorate
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormTextField
                    name="employee_dod_id"
                    label="DoD Id"
                    placeholder="DoD Id"
                    fullWidth
                    onChange={(e) => {
                      const searchTerm = e.target.value;
                      const filterValue = sanitizeEnotationInput(searchTerm);
                      setFieldValue('employee_dod_id', filterValue);
                      handleSearch(
                        values?.employee_name,
                        filterValue,
                        values?.series_id,
                        values?.job_role_id,
                        values?.supervisor_name,
                        values?.cost_center,
                        values?.directorate
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormTextField
                    name="series_id"
                    label="Series Id"
                    placeholder="Series Id"
                    fullWidth
                    onChange={(e) => {
                      const searchTerm = e.target.value;
                      const filterValue = sanitizeEnotationInput(searchTerm);
                      setFieldValue('series_id', filterValue);
                      handleSearch(
                        values?.employee_name,
                        values?.employee_dod_id,
                        filterValue,
                        values?.job_role_id,
                        values?.supervisor_name,
                        values?.cost_center,
                        values?.directorate
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <Field name="job_role_id">
                    {({ field: { value }, form: { setFieldValue } }) => (
                      <SelectionAutoComplete
                        title={`${user.label_job_role}`}
                        keyProperty="value"
                        nameProperty="text"
                        entityIds={value?.value}
                        setEntityIds={setFieldValue}
                        entitySelector={selectJobRoleByJobIdDropDown}
                        entityStatusSelector={getJobRolesByJobIdDropDownStatus}
                        onCustomChange={(value) => {
                          setFieldValue('job_role_id', value);
                          handleSearch(
                            values?.employee_name,
                            values?.employee_dod_id,
                            values?.series_id,
                            value,
                            values?.supervisor_name,
                            values?.cost_center,
                            values?.directorate
                          );
                        }}
                        fetchEntityPage={fetchJobRolesByJobId}
                        cancelItemTagEdit={() => {
                          setFieldValue('job_role_id', null);
                          removeHandleSearch(
                            values?.employee_name,
                            values?.employee_dod_id,
                            values?.series_id,
                            null,
                            values?.supervisor_name,
                            values?.cost_center,
                            values?.directorate
                          );
                        }}
                        formField="job_role_id"
                        showCloseItemEdit={true}
                        multiSelection={false}
                        hideCheckIcon={true}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormTextField
                    name="supervisor_name"
                    label="Supervisor"
                    placeholder="Supervisor"
                    fullWidth
                    onChange={(e) => {
                      const searchTerm = e.target.value;
                      setFieldValue('supervisor_name', searchTerm);
                      handleSearch(
                        values?.employee_name,
                        values?.employee_dod_id,
                        values?.series_id,
                        values?.job_role_id,
                        searchTerm,
                        values?.cost_center,
                        values?.directorate
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <Field name="cost_center">
                    {({ field: { value }, form: { setFieldValue } }) => (
                      <SelectionAutoComplete
                        title={`Cost Center`}
                        keyProperty="value"
                        nameProperty="text"
                        entityIds={value?.value}
                        setEntityIds={setFieldValue}
                        entitySelector={selectCostCenterDropDown}
                        entityStatusSelector={getCostCenterDropDownStatus}
                        onCustomChange={(value) => {
                          setFieldValue('cost_center', value);
                          handleSearch(
                            values?.employee_name,
                            values?.employee_dod_id,
                            values?.series_id,
                            values?.job_role_id,
                            values?.supervisor_name,
                            value,
                            values?.directorate
                          );
                        }}
                        fetchEntityPage={fetchCostCenterDropDown}
                        cancelItemTagEdit={() => {
                          setFieldValue('cost_center', null);
                          removeHandleSearch(
                            values?.employee_name,
                            values?.employee_dod_id,
                            values?.series_id,
                            values?.job_role_id,
                            values?.supervisor_name,
                            null,
                            values?.directorate
                          );
                        }}
                        showCloseItemEdit={true}
                        formField="cost_center"
                        multiSelection={false}
                        hideCheckIcon={true}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormTextField
                    name="directorate"
                    label="Directorate"
                    placeholder="Directorate"
                    fullWidth
                    onChange={(e) => {
                      const searchTerm = e.target.value;
                      setFieldValue('directorate', searchTerm);
                      handleSearch(
                        values?.employee_name,
                        values?.employee_dod_id,
                        values?.series_id,
                        values?.job_role_id,
                        values?.supervisor_name,
                        values?.cost_center,
                        searchTerm
                      );
                    }}
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </FilterAccordion>
    </>
  );
  //#endregion Render
};
export { GroupTrainingFilter };
