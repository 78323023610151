import { config } from 'ectl-config';
import { FormDataUtils, getNetworkService } from 'helpers';
import { createProgressParams } from 'helpers/progressUtils';
import { buildFilters } from 'hooks/buildFilter';

const accountService = { config: config };
const networkService = getNetworkService({
  config: config,
  checkAuth: accountService.checkAuth,
});
const baseUrl = `${config.apiUrl}/Admin/JobRole`;
const importHelpUrl = `${config.apiUrl}/admin/capabilityjoin/help`;

const getJobRoles = async (
  startIndex = 0,
  stopIndex = 10,
  searchString = '',
  sort = 'display_name',
  displayName = '',
  jobId = null,
  jobSeriesId = null,
  capabilityIds = null
) => {
  const filters = [];
  if (searchString) {
    filters.push(`quick_search@=*${searchString}`);
  }
  if (displayName) {
    filters.push(`display_name@=*${displayName}`);
  }
  if (jobId) {
    filters.push(`job_id==${jobId}`);
  }
  if (jobSeriesId !== null && jobSeriesId !== undefined && jobSeriesId !== '') {
    filters.push(`job_series_id==${jobSeriesId}`);
  }
  if (capabilityIds?.length > 0) {
    filters.push(`capability_ids==${capabilityIds.join('|')}`);
  }

  const params = {
    filters: filters.join(','),
    sorts: sort,
    start_index: startIndex,
    stop_index: stopIndex,
  };
  const query = new URLSearchParams(params);
  const response = await networkService.get(`${baseUrl}?${query}`);
  const pageInfo = response.headers['x-pagination'];
  return {
    pageInfo: pageInfo,
    jobRoles: response?.data,
  };
};

const create = async (params) => {
  const response = await networkService.post(baseUrl, params);

  if (response.error) {
    return Promise.reject(response.error);
  }

  return response;
};

const update = async (params) => {
  const response = await networkService.put(
    `${baseUrl}/${params.job_role_id}`,
    params
  );

  if (response.error) {
    return Promise.reject(response.error);
  }

  return response.data;
};

const _delete = async (job_role_id) => {
  try {
    const response = await networkService.delete(`${baseUrl}/${job_role_id}`);
    return response;
  } catch (e) {
    return Promise.reject(e.error);
  }
};

const getById = async (id) => {
  const response = await networkService.get(`${baseUrl}/${id}`);
  return response.data;
};

const deleteJobRolesArray = async (ids) => {
  try {
    const deleteObject = {
      ids: ids,
    };
    const response = await networkService.deleteWithBody(baseUrl, deleteObject);
    return response.data;
  } catch (e) {
    return Promise.reject(e.error);
  }
};

const getJobRoleUsageById = async (tagId) => {
  const response = await networkService.get(`${baseUrl}/${tagId}`);
  return response.data;
};

const getJobRoleImportCsv = async () => {
  try {
    const response = await networkService.get(`${baseUrl}/import`);
    return response?.data;
  } catch (err) {
    return Promise.reject(err.error);
  }
};

const uploadJobRoleImportCsv = async (params) => {
  try {
    let formData = new FormData();
    FormDataUtils.safeAppend(formData, [['file', params.file]]);
    let progressParams = createProgressParams(params);
    const response = await networkService.postMultiFormData(
      `${baseUrl}/import`,
      formData,
      progressParams
    );
    return response.data;
  } catch (err) {
    return err;
  }
};

const exportJobRolesCsv = async (
  displayName = '',
  jobId = null,
  jobSeriesId = null,
  capabilityIds = null
) => {
  try {
    const filters = buildFilters(
      ['display_name', displayName],
      ['job_id', jobId],
      ['job_series_id', jobSeriesId],
      ['capability_ids', capabilityIds]
    );

    const params = {
      filters: filters,
    };
    const query = new URLSearchParams(params).toString();
    const response = await networkService.get(`${baseUrl}/export?${query}`);
    return response?.data;
  } catch (err) {
    return Promise.reject(err.error);
  }
};

const getJobRoleImportHelp = async (job_role_id = null) => {
  try {
    const filters = [];
    if (job_role_id) {
      filters.push(`job_role_id==${job_role_id}`);
    }
    const params = {
      filters: filters.join(','),
    };
    const query = new URLSearchParams(params);
    if (job_role_id) {
      const response = await networkService.get(`${baseUrl}/help/?${query}`);
      return response?.data;
    }
    const response = await networkService.get(`${baseUrl}/help`);
    return response?.data;
  } catch (err) {
    return Promise.reject(err.error);
  }
};

const getJobRoleCapSkillImportHelp = async (capability_id = null) => {
  try {
    const filters = [];
    if (capability_id) {
      filters.push(`capability_id==${capability_id}`);
    }
    const params = {
      filters: filters.join(','),
    };
    const query = new URLSearchParams(params);
    if (capability_id) {
      const response = await networkService.get(`${importHelpUrl}/?${query}`);
      return response?.data;
    }
    const response = await networkService.get(`${importHelpUrl}`);
    return response?.data;
  } catch (err) {
    return Promise.reject(err.error);
  }
};

const getAssociateCapability = async (job_role_id = null) => {
  try {
    if (job_role_id) {
      const response = await networkService.get(
        `${baseUrl}/capability/${job_role_id}`
      );
      return response?.data;
    }
  } catch (err) {
    return Promise.reject(err.error);
  }
};

const updateJobRoleCapability = async (params) => {
  try {
    const response = await networkService.put(
      `${baseUrl}/capability/${params.job_role_id}`,
      params?.jobRoleData
    );

    return response.data;
  } catch (err) {
    return Promise.reject(err.error);
  }
};

export const jobRoleService = {
  getJobRoles,
  create,
  update,
  delete: _delete,
  deleteJobRolesArray,
  getById,
  getJobRoleUsageById,
  getJobRoleImportCsv,
  uploadJobRoleImportCsv,
  exportJobRolesCsv,
  getJobRoleImportHelp,
  getJobRoleCapSkillImportHelp,
  getAssociateCapability,
  updateJobRoleCapability,
};
