import { config } from 'ectl-config';
import { getNetworkService } from 'helpers';
import { buildFilters } from 'hooks/buildFilter';

const baseUrl = `${config.apiUrl}/report`;
const networkService = getNetworkService({
  config: config,
});

const getDepotReports = async (
  command_id = null,
  depot_id = null,
  report_percent_id = null,
  supervisor_employee_id = null,
  cost_center_id = null,
  job_id = null,
  job_role_id = null,
  report_expiry = null,
  custom_expiry_date = null,
  capability_id = null,
  office_id = null
) => {
  try {
    const filters = buildFilters(
      ['command_id', command_id],
      ['depot_id', depot_id],
      ['report_percent_id', report_percent_id],
      ['supervisor_employee_id', supervisor_employee_id],
      ['cost_center_id', cost_center_id],
      ['job_id', job_id],
      ['job_role_id', job_role_id],
      ['report_expiry', report_expiry],
      ['custom_expiry_date', custom_expiry_date],
      ['capability_id', capability_id],
      ['office_id', office_id]
    );

    const params = {
      filters: filters,
    };

    const query = new URLSearchParams(params).toString();
    const response = await networkService.get(
      `${baseUrl}/amc/?${query}`,
      60000
    );
    return response?.data;
  } catch (e) {
    return Promise.reject(e.error);
  }
};

export const depotReportService = {
  getDepotReports,
};
