import { useCallback, useEffect, useMemo } from 'react';
import {
  Alert,
  Autocomplete,
  IconButton,
  Radio,
  TableCell,
  TableRow,
  Typography,
  TextField as MuiTextField,
} from '@mui/material';
import { TextField } from 'formik-mui';
import clsx from 'clsx';
import {
  ClearIcon,
  DatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { MdDelete, MdUndo } from 'react-icons/md';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Field } from 'formik';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import FieldValidationError from 'shared/components/FormFields/FieldValidationError';
import { selectActiveUser } from 'features/account/accountSlice';
import {
  fetchJobRolesByJobId,
  fetchJobsDropDown,
  selectJobDropDown,
} from 'features/AdminSettingsDropDown/dropDownSlice';
import { padNumberWithZeros } from 'hooks/PadNumberWithZeros';

const EmployeeJobTable = ({
  index,
  setFieldValue,
  initValues,
  setInitValues,
  classes,
  values,
  errors,
  rowClass,
  checkValidate,
  setWarnings,
  warnings,
  filteredJobRole,
  setFilteredJobRole,
  isReadOnly,
}) => {
  //#region Constants

  const dispatch = useDispatch();
  const user = useSelector(selectActiveUser);
  const jobs = useSelector(selectJobDropDown);

  const {
    jobIdKey,
    jobRoleIdKey,
    startDateKey,
    endDateKey,
    isActiveKey,
    jobHistoryValues,
  } = useMemo(
    () => ({
      jobIdKey: `job_history.[${index}].job_id`,
      jobRoleIdKey: `job_history.[${index}].job_role_id`,
      startDateKey: `job_history.[${index}].start_date`,
      endDateKey: `job_history.[${index}].end_date`,
      isActiveKey: `job_history.[${index}].is_active`,
      jobHistoryValues: values.job_history?.[index],
    }),
    [index, values.job_history]
  );

  const isRowHidden = jobHistoryValues?.is_active
    ? rowClass?.revertOpacity
    : rowClass?.setOpacity;
  const isWarning =
    warnings.missingCurrentJobRole[index] ||
    warnings.nonCurrentJobWithoutEndDate[index] ||
    warnings.noJobRoleFoundForEmployee[index];

  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  //#endregion State

  //#region Selectors
  //#endregion Selectors

  //#region Refs
  //#endregion Refs

  //#region Effects
  useEffect(() => {
    dispatch(fetchJobsDropDown());
  }, [dispatch]);

  useEffect(() => {
    checkValidate();
    let updatedWarning = {
      missingCurrentJobRole: [],
      nonCurrentJobWithoutEndDate: [],
      noJobRoleFoundForEmployee: [],
    };

    Object.values(values.job_history).forEach((value, index) => {
      if (value.is_employee_current_role === true) {
        updatedWarning.missingCurrentJobRole[index] = false;
      } else {
        updatedWarning.missingCurrentJobRole[index] = true;
      }
      if (
        value.is_employee_current_role === true &&
        value.is_active === false
      ) {
        updatedWarning.missingCurrentJobRole[index] = true;
      } else {
        updatedWarning.missingCurrentJobRole[index] = false;
      }
      if (value.is_employee_current_role === false && !value.end_date) {
        updatedWarning.nonCurrentJobWithoutEndDate[index] = true;
      } else {
        updatedWarning.nonCurrentJobWithoutEndDate[index] = false;
      }
      if (!value.job_role_id) {
        updatedWarning.noJobRoleFoundForEmployee[index] = true;
      } else {
        updatedWarning.noJobRoleFoundForEmployee[index] = false;
      }
    });
    setWarnings(updatedWarning);
  }, [initValues, checkValidate, values, setWarnings]);

  //#endregion Effects

  //#region Methods
  const handleIsActive = () => {
    setFieldValue(isActiveKey, true);
  };

  const handleCurrentChange = useCallback(() => {
    let allValues = [...values.job_history];
    Object.keys(allValues).forEach((key) => {
      allValues[key].is_employee_current_role = +index === +key || false;
    });
    setInitValues({ ...values, job_history: allValues });
  }, [index, setInitValues, values]);

  const setDependantIds = async (value, key) => {
    if (key === jobIdKey && value) {
      setFieldValue(key, value);
      setFieldValue(jobRoleIdKey, null);
      let resultAction = await dispatch(
        fetchJobRolesByJobId({ job_id: value?.value })
      );
      let jobRole = resultAction?.payload?.dropDowns.map((item) => {
        return { value: +item.value, text: item.text };
      });
      setFilteredJobRole((prev) => ({ ...prev, [index]: jobRole }));
    }

    if (key === jobRoleIdKey && value) {
      setFieldValue(key, value);
    }
  };

  const removeDependantIds = async (key) => {
    if (key === jobIdKey) {
      setFieldValue(jobIdKey, null);
      setFieldValue(jobRoleIdKey, null);
      let resultAction = await dispatch(fetchJobRolesByJobId());
      let jobRole = resultAction?.payload?.dropDowns.map((item) => {
        return { value: +item.value, text: item.text };
      });
      setFilteredJobRole((prev) => ({ ...prev, [index]: jobRole }));
    }
    if (key === jobRoleIdKey) {
      setFieldValue(key, null);
    }
  };

  const removeTableRow = (newAdded) => {
    if (newAdded) {
      const updatedValues = values.job_history;
      updatedValues.splice(index, 1);
      setInitValues({
        ...values,
        job_history: [...updatedValues],
      });
    } else {
      setFieldValue(isActiveKey, false);
    }
  };
  //#endregion Methods

  //#region Render time calcs
  //#endregion Render time calcs

  //#region Render
  return (
    <>
      <TableRow>
        {/* Current(Radio Button) */}
        <TableCell
          sx={{
            borderBottom: isWarning
              ? 'none'
              : '1px solid rgba(224, 224, 224, 1)',
          }}
          className={isRowHidden}
        >
          <Radio
            color="primary"
            checked={jobHistoryValues?.is_employee_current_role}
            onChange={handleCurrentChange}
            value={index}
            name="radio-group"
            disabled={!jobHistoryValues?.is_active || isReadOnly}
          />
        </TableCell>

        {/* Job */}
        <TableCell
          className={clsx(isRowHidden, classes.customClearIcon)}
          sx={{
            borderBottom: isWarning
              ? 'none'
              : '1px solid rgba(224, 224, 224, 1)',
            position: 'relative',
          }}
        >
          {!jobHistoryValues?.newAdded ? (
            <Typography variant="body1">
              {padNumberWithZeros(jobHistoryValues?.series_id)}
            </Typography>
          ) : (
            <Field name={jobIdKey}>
              {() => (
                <Autocomplete
                  sx={{
                    minWidth: '130px',
                    maxWidth: '100%',
                  }}
                  limitTags={2}
                  variant="outline"
                  id="job-id"
                  options={jobs}
                  disableClearable={true}
                  onChange={(_, newInputValue) => {
                    setDependantIds(newInputValue, jobIdKey);
                  }}
                  style={{ position: 'relative' }}
                  value={jobHistoryValues.job_id ?? ''}
                  getOptionLabel={(option) => String(option.text ?? '')}
                  renderOption={(props, option) => (
                    <li {...props} key={props.id}>
                      {option.text}
                    </li>
                  )}
                  readOnly={isReadOnly}
                  renderInput={(params) => (
                    <>
                      <MuiTextField
                        variant="outlined"
                        {...params}
                        label={`${user?.label_job ?? 'Job'}`}
                      />
                      <IconButton
                        onClick={(e) => {
                          removeDependantIds(jobIdKey);
                        }}
                        aria-label="close"
                        sx={{
                          position: 'absolute',
                          right: '30px',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          padding: '0',
                        }}
                        size="large"
                      >
                        <ClearIcon
                          style={{
                            fontSize: '18px',
                          }}
                        />
                      </IconButton>
                    </>
                  )}
                />
              )}
            </Field>
          )}
        </TableCell>

        {/* Job roles */}
        <TableCell
          className={clsx(isRowHidden, classes.customClearIcon)}
          sx={{
            borderBottom: isWarning
              ? 'none'
              : '1px solid rgba(224, 224, 224, 1)',
            position: 'relative',
          }}
        >
          {/* View mode */}
          {jobHistoryValues?.role_name ? (
            <Typography variant="body1">
              {jobHistoryValues?.role_name}
            </Typography>
          ) : (
            // Edit Mode
            <div>
              <Field className={isRowHidden} name={jobRoleIdKey}>
                {() => (
                  <Autocomplete
                    sx={{
                      minWidth: '130px',
                      maxWidth: '100%',
                    }}
                    limitTags={2}
                    variant="outline"
                    id="level-id"
                    options={filteredJobRole[index]}
                    onChange={(_, newInputValue) => {
                      setDependantIds(newInputValue, jobRoleIdKey);
                    }}
                    style={{ position: 'relative' }}
                    disableClearable={true}
                    value={jobHistoryValues.job_role_id ?? ''}
                    getOptionLabel={(option) => String(option.text ?? '')}
                    renderOption={(props, option) => (
                      <li {...props} key={props.id}>
                        {option.text}
                      </li>
                    )}
                    readOnly={isReadOnly}
                    renderInput={(params) => (
                      <>
                        <MuiTextField
                          variant="outlined"
                          {...params}
                          error={
                            !!errors[`job_history.[${[index]}].job_role_id`]
                          }
                          label={`Select ${user.label_job_role}`}
                        />
                        <IconButton
                          onClick={(e) => {
                            removeDependantIds(jobRoleIdKey);
                          }}
                          aria-label="close"
                          sx={{
                            position: 'absolute',
                            right: '30px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            padding: '0',
                          }}
                          size="large"
                        >
                          <ClearIcon
                            style={{
                              fontSize: '18px',
                            }}
                          />
                        </IconButton>
                      </>
                    )}
                  />
                )}
              </Field>
              {errors?.[jobRoleIdKey] && (
                <FieldValidationError validationError={errors[jobRoleIdKey]} />
              )}
            </div>
          )}
        </TableCell>

        {/* Start Date */}
        <TableCell
          className={isRowHidden}
          sx={{
            borderBottom: isWarning
              ? 'none'
              : '1px solid rgba(224, 224, 224, 1)',
            position: 'relative',
          }}
        >
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              value={
                jobHistoryValues?.start_date
                  ? moment(jobHistoryValues?.start_date) || 'Never'
                  : null
              }
              onChange={(date) => {
                setFieldValue(
                  startDateKey,
                  date ? date.format('YYYY-MM-DD') : date
                );
              }}
              slotProps={{
                actionBar: {
                  actions: ['clear', 'today'],
                },
              }}
              disabled={!jobHistoryValues?.is_active || isReadOnly}
              label="Start Date"
              format="YYYY-MM-DD"
              renderInput={(props) => (
                <TextField
                  {...props}
                  margin="dense"
                  variant="outlined"
                  label="Start Date"
                  placeholder={
                    jobHistoryValues?.start_date ? 'Never' : 'YYYY-MM-DD'
                  }
                  className={classes.materialInput}
                  type="text"
                  fullWidth
                />
              )}
            />
          </LocalizationProvider>
          {errors?.[startDateKey] && (
            <FieldValidationError validationError={errors[startDateKey]} />
          )}
        </TableCell>
        {/* End Date */}
        <TableCell
          className={isRowHidden}
          sx={{
            borderBottom: isWarning
              ? 'none'
              : '1px solid rgba(224, 224, 224, 1)',
            position: 'relative',
          }}
        >
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              value={
                jobHistoryValues?.end_date
                  ? moment(jobHistoryValues?.end_date)
                  : null
              }
              onChange={(date) => {
                setFieldValue(
                  endDateKey,
                  date ? date.format('YYYY-MM-DD') : date
                );
              }}
              slotProps={{
                actionBar: {
                  actions: ['clear', 'today'],
                },
              }}
              label="End Date"
              format="YYYY-MM-DD"
              disabled={!jobHistoryValues?.is_active || isReadOnly}
              renderInput={(props) => (
                <TextField
                  {...props}
                  margin="dense"
                  variant="outlined"
                  label="End Date"
                  placeholder={jobHistoryValues?.end_date ? '' : 'YYYY-MM-DD'}
                  className={classes.materialInput}
                  type="text"
                  fullWidth
                />
              )}
            />
          </LocalizationProvider>
          {errors?.[endDateKey] && (
            <FieldValidationError validationError={errors?.[endDateKey]} />
          )}
        </TableCell>

        {/* Delete Action */}
        {!isReadOnly ? (
          <TableCell
            sx={{
              borderBottom: isWarning
                ? 'none'
                : '1px solid rgba(224, 224, 224, 1)',
              position: 'relative',
            }}
          >
            {jobHistoryValues?.is_active === false ? (
              <IconButton onClick={handleIsActive}>
                <MdUndo />
              </IconButton>
            ) : (
              <IconButton
                onClick={() => {
                  removeTableRow(jobHistoryValues?.newAdded);
                }}
              >
                <MdDelete />
              </IconButton>
            )}
          </TableCell>
        ) : (
          <TableCell></TableCell>
        )}
      </TableRow>
      {(warnings.missingCurrentJobRole[index] ||
        warnings.nonCurrentJobWithoutEndDate[index] ||
        warnings.noJobRoleFoundForEmployee[index]) && (
        <TableRow>
          <TableCell sx={{ pt: '0' }} colSpan={6}>
            {(warnings.missingCurrentJobRole[index] ||
              warnings.nonCurrentJobWithoutEndDate[index]) && (
              <Alert severity="warning">
                {warnings.missingCurrentJobRole[index] &&
                  `This user currently does not have a ${user.label_job_role} set as active, this may cause the user not to show up in reports or dashboards`}
                {warnings.nonCurrentJobWithoutEndDate[index] &&
                  `This user has a non-current ${user.label_job_role} without an end date, this may affect reports generated on this user if not corrected.`}
              </Alert>
            )}
            {warnings.noJobRoleFoundForEmployee[index] && (
              <Alert severity="error">
                {warnings.noJobRoleFoundForEmployee[index] &&
                  `This User does not have a ${user.label_job_role} delete this row to continue.`}
              </Alert>
            )}
          </TableCell>
        </TableRow>
      )}
    </>
  );
  //#endregion Render
};
export { EmployeeJobTable };
