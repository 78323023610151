import { useState, useEffect, useRef } from 'react';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { Button } from '@mui/material';

import { FormActions } from 'helpers/form-action';
import { makeFormStyles } from 'forms/style';
import {
  activateLoading,
  deactivateLoading,
} from 'features/global/loadingProgressSlice';
import {
  CAPABILITY_CACHE,
  EMPLOYEE_CACHE,
  JOB_ROLE_CACHE,
  snackbarHandler,
} from 'helpers';
import { SnackbarDismiss } from 'shared/components/SnackbarDismiss';
import Loader from 'shared/components/Loader';

import { closePanel } from 'features/common/formSlice';
import {
  createJob,
  fetchJobs,
  fetchJobsById,
  updateJob,
} from 'features/job/jobSlice';
import { selectSearchCache, updateSearchCache } from 'features/Teams/teamSlice';
import {
  selectAdminSettingCache,
  updateAdminSettingCache,
} from 'features/global/visibilitySlice';
import { FormTextField } from 'shared/components/FormFields/TextField';
import { Each } from 'helpers/Each';
import { sanitizeEnotationInput } from 'hooks/validateAndFormateColor';
import AuditInfoCard from 'shared/components/_AuditInfoCard';

const ItemJobForm = ({ formAction, itemTagId, auditHeader }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const getCacheData = useSelector(selectSearchCache);
  const jobCacheData = useSelector(selectAdminSettingCache);
  // eslint-disable-next-line no-unused-vars
  const [loaded, setLoaded] = useState(
    formAction.id === FormActions.Create.id ? true : false
  );
  const classes = makeFormStyles();
  // eslint-disable-next-line no-unused-vars
  const [initValues, setInitValues] = useState({
    display_name: '',
    family_display_name: '',
    series_id: null,
    family_series_id: null,
    description: '',
  });
  const tagNameRef = useRef(null);
  const jobFormProps = [
    {
      label: 'Display Name',
      name: 'display_name',
      placeholder: 'Display Name',
    },
    {
      label: 'Series Id',
      name: 'series_id',
      placeholder: 'Series Id',
    },
    {
      multiline: true,
      rows: 3,
      label: 'Description',
      name: 'description',
      placeholder: 'Description',
    },

    {
      label: 'Family Name',
      name: 'family_display_name',
      placeholder: 'Family Name',
    },

    {
      label: 'Family Series ID',
      name: 'family_series_id',
      placeholder: 'Family Series ID',
    },
  ];
  const [auditInfo, setAuditInfo] = useState(null);
  useEffect(() => {
    if (formAction.id === FormActions.Edit.id) {
      const fetchData = async () => {
        var objectData = await dispatch(fetchJobsById(itemTagId));
        var fetchedObject = objectData.payload;
        setInitValues({
          display_name: fetchedObject?.display_name,
          description: fetchedObject?.description,
          series_id: fetchedObject?.series_id,
          family_display_name: fetchedObject?.family_display_name,
          family_series_id: fetchedObject?.family_series_id,
        });
        const { created_by, created_time, modified_time } = fetchedObject;
        setAuditInfo({
          author: created_by,
          creation: created_time,
          modified: modified_time,
        });
        setLoaded(true);
      };
      fetchData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = async (values, setSubmitting, resetForm) => {
    dispatch(activateLoading({ showProgress: true }));
    var resultAction = null;
    switch (formAction.id) {
      case FormActions.Edit.id:
        resultAction = await dispatch(
          updateJob({
            job_id: itemTagId,
            ...values,
          })
        );
        if (!resultAction.error) {
          setInitValues({
            ...initValues,
            display_name: resultAction?.payload.display_name,
            series_id: resultAction?.payload.series_id,
            family_series_id: resultAction?.payload.family_series_id,
            description: resultAction?.payload?.description,
            family_display_name: resultAction?.payload?.family_display_name,
          });
          dispatch(closePanel({ formKey: `itemJobForm` }));
          if (
            getCacheData?.job_id?.value === itemTagId &&
            getCacheData?.job_id?.text !== resultAction?.payload.display_name
          ) {
            dispatch(
              updateSearchCache({
                ...getCacheData,
                job_id: {
                  value: itemTagId,
                  text: resultAction?.payload.display_name,
                },
              })
            );
          }
          if (
            jobCacheData?.[JOB_ROLE_CACHE]?.job_id?.value === itemTagId &&
            jobCacheData?.[JOB_ROLE_CACHE]?.job_id?.text !==
              resultAction?.payload.display_name
          ) {
            dispatch(
              updateAdminSettingCache({
                key: JOB_ROLE_CACHE,
                payload: {
                  job_id: {
                    value: itemTagId,
                    text: resultAction?.payload.display_name,
                  },
                },
              })
            );
          }
          if (
            jobCacheData?.[CAPABILITY_CACHE]?.job_id?.value === itemTagId &&
            jobCacheData?.[CAPABILITY_CACHE]?.job_id?.text !==
              resultAction?.payload.display_name
          ) {
            dispatch(
              updateAdminSettingCache({
                key: CAPABILITY_CACHE,
                payload: {
                  job_id: {
                    value: itemTagId,
                    text: resultAction?.payload.display_name,
                  },
                },
              })
            );
          }
          if (
            jobCacheData?.[EMPLOYEE_CACHE]?.job_id?.value === itemTagId &&
            jobCacheData?.[EMPLOYEE_CACHE]?.job_id?.text !==
              resultAction?.payload.display_name
          ) {
            dispatch(
              updateAdminSettingCache({
                key: EMPLOYEE_CACHE,
                payload: {
                  job_id: {
                    value: itemTagId,
                    text: resultAction?.payload.display_name,
                  },
                },
              })
            );
          }
        }
        break;
      case FormActions.Create.id:
        resultAction = await dispatch(createJob({ ...values }));
        if (!resultAction.error) {
          await dispatch(fetchJobs());
          resetForm();
          dispatch(closePanel({ formKey: `itemJobForm` }));
          if (tagNameRef.current) {
            tagNameRef.current.focus();
          }
        }
        break;
      default:
        break;
    }

    dispatch(deactivateLoading());
    const { message, variant } = snackbarHandler(
      resultAction.meta.requestStatus,
      formAction.label
    );
    enqueueSnackbar(message, {
      action: (key) => <SnackbarDismiss key={key} />,
      variant: variant,
    });
    setSubmitting(false);
  };

  return !loaded ? (
    <Loader styleClassName={classes.loadingSvg} />
  ) : (
    <Formik
      enableReinitialize={true}
      initialValues={{ ...initValues }}
      validationSchema={Yup.object({
        display_name: Yup.string().required('Required'),
        series_id: Yup.number().min(0).required('Required'),
        family_series_id: Yup.number().min(0).required('Required'),
        description: Yup.string().required('Required'),
        family_display_name: Yup.string().required('Required'),
      })}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        handleSubmit(values, setSubmitting, resetForm);
      }}
    >
      {({ isSubmitting, setFieldValue }) => (
        <Form className={classes.form}>
          <Each
            of={jobFormProps}
            render={(field, index) => (
              <FormTextField
                key={index}
                fullWidth
                {...field}
                onChange={(event) => {
                  const { name, value } = event.target;
                  if (name === 'series_id' || name === 'family_series_id') {
                    const sanitizedValue = sanitizeEnotationInput(value);
                    setFieldValue(name, sanitizedValue);
                  } else {
                    setFieldValue(name, value);
                  }
                }}
              />
            )}
          />
          <AuditInfoCard
            auditInfo={auditInfo}
            auditInfoHeader={auditHeader}
            formAction={formAction}
          />
          <Button
            size="large"
            variant="contained"
            color="primary"
            fullWidth
            type="submit"
            disabled={isSubmitting}
            sx={{ marginTop: '1rem' }}
          >
            {formAction.buttonLabel}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export { ItemJobForm };
