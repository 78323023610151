import { config } from 'ectl-config';
import { FormDataUtils, getNetworkService } from 'helpers';

const baseUrl = `${config.apiUrl}/job`;
const networkService = getNetworkService({
  config: config,
});

const fetchJobById = async (id) => {
  const response = await networkService.get(`${baseUrl}/${id}`);
  return response.data;
};

const fetchJobs = async () => {
  const response = await networkService.get(`${baseUrl}`);
  return response?.data;
};

const fetchJobsDepot = async () => {
  const response = await networkService.get(`${baseUrl}/depot`);
  return response.data;
};

const createJob = async (params) => {
  let formData = new FormData();
  FormDataUtils.safeAppend(formData, [
    ['display_name', params.display_name],
    ['description', params.description],
    ['job_family', params.job_family],
    ['job_title', params?.job_title],
  ]);
  const response = await networkService.postMultiFormData(baseUrl, formData);
  return response.data;
};

const updateJob = async (params) => {
  let formData = new FormData();
  FormDataUtils.safeAppend(formData, [
    ['display_name', params.display_name],
    ['description', params.description],
    ['job_family', params.job_family],
    ['job_title', params?.job_title],
  ]);
  const response = await networkService.putMultiFormData(
    `${baseUrl}/${params.job_id}`,
    formData
  );
  return response.data;
};

const deleteJob = async (job_id) => {
  try {
    const response = await networkService.delete(`${baseUrl}/${job_id}`);
    return response;
  } catch (e) {
    return Promise.reject(e.error);
  }
};

const fetchJobRoleById = async (id) => {
  const response = await networkService.get(`${baseUrl}/role/${id}`);
  return response.data;
};

const fetchJobRoles = async () => {
  const response = await networkService.get(`${baseUrl}/role`);
  const pageInfo = response.headers['x-pagination'];
  return { pageInfo: pageInfo, job_roles: response?.data };
};

const createJobRole = async (params) => {
  let formData = new FormData();
  FormDataUtils.safeAppend(formData, [
    ['display_name', params.display_name],
    ['job_id', params.job_id],
    ['level_id', params.level_id],
  ]);
  FormDataUtils.safeArrayAppend(formData, [
    ['capability_ids', params.capability_ids],
  ]);
  const response = await networkService.postMultiFormData(
    `${baseUrl}/role`,
    formData
  );
  return response.data;
};

const updateJobRole = async (params) => {
  let formData = new FormData();
  FormDataUtils.safeAppend(formData, [
    ['display_name', params.display_name],
    ['job_id', params.job_id],
    ['level_id', params.level_id],
  ]);
  FormDataUtils.safeArrayAppend(formData, [
    ['capability_ids', params.capability_ids],
  ]);
  const response = await networkService.putMultiFormData(
    `${baseUrl}/role/${params.job_role_id}`,
    formData
  );
  return response.data;
};

const deleteJobRole = async (job_role_id) => {
  try {
    const response = await networkService.delete(
      `${baseUrl}/role/${job_role_id}`
    );
    return response;
  } catch (e) {
    return Promise.reject(e.error);
  }
};

const fetchLevelById = async (id) => {
  const response = await networkService.get(`${baseUrl}/level/${id}`);
  return response.data;
};

const fetchLevels = async () => {
  const response = await networkService.get(`${baseUrl}/level`);
  const pageInfo = response.headers['x-pagination'];
  return { pageInfo: pageInfo, levels: response?.data };
};

const createLevel = async (params) => {
  let formData = new FormData();
  FormDataUtils.safeAppend(formData, [['display_name', params.display_name]]);
  FormDataUtils.safeArrayAppend(formData, [
    ['capability_skills', params.capability_skills],
  ]);
  const response = await networkService.postMultiFormData(baseUrl, formData);
  return response.data;
};

const updateLevel = async (params) => {
  let formData = new FormData();
  FormDataUtils.safeAppend(formData, [['display_name', params.display_name]]);
  const response = await networkService.putMultiFormData(
    `${baseUrl}/level/${params.level_id}`,
    formData
  );
  return response.data;
};

const deleteLevel = async (id) => {
  try {
    const response = await networkService.delete(`${baseUrl}/level/${id}`);
    return response;
  } catch (e) {
    return Promise.reject(e.error);
  }
};

const fetchCapabilityById = async (id) => {
  const response = await networkService.get(`${baseUrl}/capability/${id}`);
  return response.data;
};

const fetchCapabilities = async () => {
  const response = await networkService.get(`${baseUrl}/capability`);
  const pageInfo = response.headers['x-pagination'];
  return { pageInfo: pageInfo, capabilities: response?.data };
};

const createCapability = async (params) => {
  let formData = new FormData();
  FormDataUtils.safeAppend(formData, [['display_name', params.display_name]]);
  FormDataUtils.safeArrayAppend(formData, [
    ['job_role_ids', params.job_role_ids],
  ]);
  const response = await networkService.postMultiFormData(
    `${baseUrl}/Capability`,
    formData
  );
  return response.data;
};

const updateCapability = async (params) => {
  let formData = new FormData();
  FormDataUtils.safeAppend(formData, [['display_name', params.display_name]]);
  FormDataUtils.safeArrayAppend(formData, [
    ['job_role_ids', params.job_role_ids],
  ]);
  const response = await networkService.putMultiFormData(
    `${baseUrl}/Capability/${params.capability_id}`,
    formData
  );
  return response.data;
};

const deleteCapability = async (id) => {
  try {
    const response = await networkService.delete(`${baseUrl}/Capability/${id}`);
    return response;
  } catch (e) {
    return Promise.reject(e.error);
  }
};

export const jobService = {
  fetchJobById,
  fetchJobs,
  createJob,
  updateJob,
  deleteJob,

  fetchJobRoleById,
  fetchJobRoles,
  createJobRole,
  updateJobRole,
  deleteJobRole,

  fetchLevelById,
  fetchLevels,
  createLevel,
  updateLevel,
  deleteLevel,

  fetchCapabilityById,
  fetchCapabilities,
  createCapability,
  updateCapability,
  deleteCapability,
  fetchJobsDepot,
};
