import { ActionIds } from './getActionIcon';

export const Action = {
  Delete: {
    iconId: ActionIds.Delete,
    label: 'delete',
    displayLabel: 'Delete',
  },
  Edit: {
    iconId: ActionIds.Edit,
    label: 'edit',
    displayLabel: 'Edit',
  },
  Publish: {
    iconId: ActionIds.Publish,
    label: 'publish',
    displayLabel: 'Publish',
  },
  PublishArray: {
    iconId: ActionIds.Publish,
    label: 'publishArray',
    displayLabel: 'Publish Array',
  },
  DeleteArray: {
    iconId: ActionIds.DeleteArray,
    label: 'deleteArray',
    displayLabel: 'Delete',
  },
  DeleteCurrentZone: {
    iconId: ActionIds.DeleteCurrentZone,
    label: 'deleteCurrentZone',
    displayLabel: 'delete selected zone',
  },
  CreateContentVersion: {
    iconId: ActionIds.CreateContentVersion,
    label: 'createContentVersion',
    displayLabel: 'Create Content Version',
  },
  Duplicate: {
    iconId: ActionIds.Duplicate,
    label: 'duplicate',
    displayLabel: 'Duplicate',
  },
  CopyToZone: {
    iconId: ActionIds.CopyToZone,
    label: 'copyToZone',
    displayLabel: 'Copy to zone',
  },
  Undelete: {
    iconId: ActionIds.Undelete,
    label: 'undelete',
    displayLabel: 'Undelete',
  },
  Product: {
    iconId: ActionIds.Product,
    label: 'Product',
    displayLabel: 'product',
  },
  Place: {
    iconId: ActionIds.Place,
    label: 'Place',
    displayLabel: 'place',
  },
  Import: {
    iconId: ActionIds.Import,
    label: 'import',
    displayLabel: 'Import',
  },
  Export: {
    iconId: ActionIds.Export,
    label: 'export',
    displayLabel: 'Export Data',
  },
  ExportAsset: {
    iconId: ActionIds.ExportAsset,
    label: 'exportAsset',
    displayLabel: 'Export Asset',
  },
  Procedure: {
    iconId: ActionIds.Procedure,
    label: 'Procedure',
    displayLabel: 'procedure',
  },
  Repeat: {
    iconId: ActionIds.Repeat,
    label: 'Repeat',
    displayLabel: 'repeat',
  },
  Playback: {
    iconId: ActionIds.Player,
    label: 'playback',
    displayLabel: 'Player',
  },
  Usage: {
    iconId: ActionIds.Edit,
    label: 'edit',
    displayLabel: 'Usage',
  },
  Deactivate: {
    iconId: ActionIds.Delete,
    label: 'deactivate',
    displayLabel: 'Deactivate',
  },
  Reactivate: {
    iconId: ActionIds.Undelete,
    label: 'reactivate',
    displayLabel: 'Reactivate',
  },
  View: {
    iconId: ActionIds.View,
    label: 'view',
    displayLabel: 'View',
  },
  AddJobRoles: {
    iconId: ActionIds.AddJobRoles,
    label: 'AddJobRoles',
    displayLabel: 'Quick Add Job Roles',
  },
  AddSkills: {
    iconId: ActionIds.AddSkill,
    label: 'AddSkills',
    displayLabel: 'Quick Add Skills',
  },
  AddCapability: {
    iconId: ActionIds.AddSkill,
    label: 'AddCapability',
    displayLabel: 'Quick Add Capabilities',
  },
  AddJobRoleCapability: {
    iconId: ActionIds.AddSkill,
    label: 'AddJobRoleCapability',
    displayLabel: 'Quick Add Capabilities',
  },
};
