import clsx from 'clsx';
import TableCell from '@mui/material/TableCell';
import InputBase from '@mui/material/InputBase';
import Checkbox from '@mui/material/Checkbox';
import { FiPlusCircle } from 'react-icons/fi';
import { BiComment } from 'react-icons/bi';
import {
  Chip,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  tooltipClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import {
  MdArrowDownward,
  MdArrowUpward,
  MdCheck,
  MdCheckBoxOutlineBlank,
  MdClose,
  MdEdit,
  MdSearch,
} from 'react-icons/md';

import { PopupActions } from 'components/_Misc/PopupAction';
import { openPanel, setEditObjectId } from 'features/common/formSlice';
import { validateAndFormatColor } from 'hooks/validateAndFormateColor';
import React from 'react';
import { Each } from 'helpers/Each';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 5,
  border: `1px solid ${'#3cbc9b'}`,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#808080',
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: '#3cbc9b',
  },
}));

const headerHeight = 40;
const rowHeight = 60;

function checkCell(props, column) {
  const { rowData } = props;
  const { classes, isSelected, handleClick, cellLevel } = column;
  const rowDataId = rowData[`${cellLevel}_id`];
  return (
    <TableCell
      component="div"
      padding={column.padding}
      className={clsx(classes.tableCell, classes.flexContainer)}
      style={{ height: rowHeight }}
    >
      <Checkbox
        checked={isSelected(rowDataId)}
        onClick={(event) => handleClick(event, rowDataId, rowData.display_name)}
      />
    </TableCell>
  );
}

function readOnlyCheckCell(props, column) {
  const { rowData } = props;
  const { classes, cellLevel } = column;
  const rowDataId = rowData[`${cellLevel}`];
  return (
    <TableCell
      component="div"
      padding={column.padding}
      className={clsx(classes.tableCell, classes.flexContainer)}
      style={{ height: rowHeight }}
    >
      <Box
        sx={{
          background: '#dddddd',
          borderRadius: '4px',
          width: '1.2rem',
          height: '1.2rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {!rowDataId ? (
          ''
        ) : rowDataId === true ? (
          <MdCheck sx={{ fontSize: '1rem' }} />
        ) : (
          <MdClose sx={{ fontSize: '1rem' }} />
        )}
      </Box>
    </TableCell>
  );
}

function conditionalCheckCell(props, column) {
  const { rowData } = props;
  const { classes, isSelected, handleClick, cellLevel } = column;
  const rowDataId = rowData[`${cellLevel}_id`];
  return (
    <TableCell
      component="div"
      padding={column.padding}
      className={clsx(classes.tableCell, classes.flexContainer)}
      style={{ height: rowHeight }}
    >
      {rowDataId !== null && (
        <Checkbox
          checked={isSelected(rowDataId)}
          onClick={(event) =>
            handleClick(event, rowDataId, rowData.display_name)
          }
        />
      )}
    </TableCell>
  );
}

function checkHeaderCell(props, column) {
  const {
    classes,
    numSelected,
    rowCount,
    handleSelectAllClick,
    hasHeaderSearchBar,
  } = column;

  return (
    <TableCell
      component="div"
      padding={column.padding}
      className={clsx(
        classes.tableCell,
        classes.flexContainer,
        classes.noClick,
        hasHeaderSearchBar && classes.searchBarWrapperAll
      )}
      variant="head"
      style={{ height: headerHeight, width: '100%' }}
    >
      <Checkbox
        indeterminate={numSelected > 0 && numSelected < rowCount}
        checked={rowCount > 0 && numSelected === rowCount}
        onChange={handleSelectAllClick}
      />
    </TableCell>
  );
}

function actionCell(props, column) {
  const { rowData } = props;
  const { classes, actionCellLevel, childComponents, targetPopup } = column;
  return (
    <TableCell
      padding={column.padding}
      className={clsx(
        classes.popupActions,
        classes.tableCell,
        classes.flexContainer
      )}
      component="div"
      style={{ height: rowHeight }}
    >
      <Each
        of={childComponents}
        render={(Component) => (
          <Component key={rowData[`${actionCellLevel}_id`]} rowData={rowData} />
        )}
      />
      <PopupActions
        object={rowData}
        level={actionCellLevel}
        targetPopup={targetPopup}
      />
    </TableCell>
  );
}

function actionEditCell(props, column) {
  const { classes, childComponents } = column;
  return (
    <TableCell
      padding={column.padding}
      className={clsx(
        classes.popupActions,
        classes.tableCell,
        classes.flexContainer
      )}
      component="div"
      style={{ height: rowHeight }}
    >
      <Each of={childComponents} render={(Component) => <p></p>} />
      <IconButton>
        <MdEdit sx={{ fontSize: '1rem' }} />
      </IconButton>
    </TableCell>
  );
}

function progressStatusCell(props, column) {
  const { cellData, rowData } = props;
  const { classes, onClick } = column;
  let data = cellData;
  if (column.transform !== undefined) {
    data = column.transform(cellData);
  }
  return (
    <TableCell
      component="div"
      padding={column.padding}
      className={clsx(classes.tableCell, classes.flexContainer)}
      variant="body"
      style={{ height: rowHeight }}
      align={column.numeric ? 'right' : 'left'}
      onClick={onClick ? () => onClick(rowData) : null}
    >
      <div
        style={{
          display: 'flex',
          position: 'relative',
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <BorderLinearProgress
            variant="determinate"
            value={rowData['progress_percentage'] ?? 0}
          />
        </Box>
        <div
          style={{
            position: 'absolute',
            top: 0,
            color: '#ffffff',
            fontWeight: 600,
          }}
        >
          {data}
        </div>
      </div>
    </TableCell>
  );
}

function activeCell(props, column) {
  const { cellData } = props;
  const { classes } = column;
  let data = cellData;
  if (column.transform !== undefined) {
    data = column.transform(cellData);
  }

  const active = data ? (
    <div className={clsx(classes.activeCellContainer, classes.activeCell)}>
      Active
    </div>
  ) : (
    <div className={clsx(classes.activeCellContainer, classes.inactiveCell)}>
      Inactive
    </div>
  );

  return (
    <TableCell
      component="div"
      padding={column.padding}
      className={clsx(classes.tableCell, classes.flexContainer)}
      variant="body"
      style={{ height: rowHeight }}
      align={column.numeric ? 'right' : 'left'}
    >
      {active}
    </TableCell>
  );
}

function labelCell(props, column) {
  const { cellData, rowData } = props;
  const { classes, onClick } = column;
  let data = cellData;
  if (column.transform !== undefined) {
    data = column.transform(cellData, rowData);
  }
  return (
    <TableCell
      component="div"
      padding={column.padding}
      className={clsx(classes.tableCell, classes.scrollingFlexContainer)}
      variant="body"
      style={{ height: rowHeight }}
      align={column.numeric ? 'right' : 'left'}
      onClick={onClick ? () => onClick(rowData) : null}
    >
      {data}
    </TableCell>
  );
}

function styledLabelCell(props, column) {
  const { cellData, rowData } = props;
  const { classes, onClick, setId, textColor } = column;
  let data = cellData;
  const rowDataId = rowData[`${setId}_id`];
  if (column.transform !== undefined) {
    data = column.transform(cellData, rowData);
  }
  return (
    <TableCell
      component="div"
      padding={column.padding}
      className={clsx(classes.tableCell, classes.scrollingFlexContainer)}
      variant="body"
      style={{
        height: rowHeight,
        color: textColor && rowDataId === null ? textColor : '',
      }}
      align={column.numeric ? 'right' : 'left'}
      onClick={onClick ? () => onClick(rowData) : null}
    >
      {data}
    </TableCell>
  );
}

function clipLabelTooltipCell(props, column) {
  const { cellData, rowData } = props;
  const { classes, onClick } = column;
  let data = cellData;
  if (column.transform !== undefined) {
    data = column.transform(cellData, rowData);
  }
  return (
    <TableCell
      component="div"
      padding={column.padding}
      className={clsx(classes.tableCell)}
      variant="body"
      style={{ overflow: 'hidden', height: rowHeight }}
      align={column.numeric ? 'right' : 'left'}
      onClick={onClick ? () => onClick(rowData) : null}
    >
      <Tooltip title={data}>
        <Typography
          sx={{
            overflow: 'hidden !important',
            textOverflow: 'ellipsis',
            margin: '0',
            WebkitLineClamp: '1',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            padding: '1.5px 0',
          }}
        >
          {data}
        </Typography>
      </Tooltip>
    </TableCell>
  );
}

function conditionalLabelCell(props, column) {
  const { cellData, rowData } = props;
  const { classes, onClick, conditionalFn } = column;
  let data = cellData;
  if (column.transform !== undefined) {
    data = column.transform(cellData);
  }
  return (
    <TableCell
      component="div"
      padding={column.padding}
      className={clsx(classes.tableCell, classes.flexContainer)}
      variant="body"
      style={{ height: rowHeight }}
      align={column.numeric ? 'right' : 'left'}
      onClick={onClick ? () => onClick(rowData) : null}
    >
      {typeof conditionalFn === 'function' ? conditionalFn(data) : data}
    </TableCell>
  );
}

function statusCell(props, column) {
  const { cellData, rowData } = props;
  const { classes, onClick } = column;
  let data = cellData;

  if (column.transform !== undefined) {
    data = column.transform(cellData);
  }

  let statusCellType;
  if (data === 'approved') {
    statusCellType = classes.approvedCell;
  } else if (data === 'pending for review') {
    statusCellType = classes.pendingCell;
  } else if (data === 'rejected') {
    statusCellType = classes.rejectedCell;
  }
  return (
    <TableCell
      component="div"
      padding={column.padding}
      className={clsx(classes.tableCell, classes.flexContainer)}
      variant="body"
      style={{ height: rowHeight }}
      align={column.numeric ? 'right' : 'left'}
      onClick={onClick ? () => onClick(rowData) : null}
    >
      <div className={clsx(classes.statusCell, statusCellType)}>{data}</div>
    </TableCell>
  );
}

function feedbackCell(props, column) {
  const { cellData, rowData } = props;
  const { classes, onClick } = column;
  let data = cellData;

  if (column.transform !== undefined) {
    data = column.transform(cellData);
  }
  return (
    <TableCell
      component="div"
      padding={column.padding}
      className={clsx(classes.tableCell, classes.flexContainer)}
      variant="body"
      style={{ height: rowHeight }}
      align={column.numeric ? 'right' : 'left'}
      onClick={onClick ? () => onClick(rowData) : null}
    >
      {data === 'yes' ? <BiComment size={25} color="#BDC3C7" /> : null}
    </TableCell>
  );
}

function labelHeader(props, column) {
  const { label } = props;
  const {
    classes,
    sortDirection,
    sortBy,
    handleSort,
    dataKey,
    allowHeaderSearchBar = false,
    onSearchChange,
    hasHeaderSearchBar = false,
  } = column;
  const isAscendingOrder = sortDirection === 'ASC' && dataKey === sortBy;
  const activeSort =
    sortBy === dataKey
      ? { fontSize: '16px', color: 'black' }
      : { fontSize: '16px' };
  return (
    <TableCell
      component="div"
      padding={column.padding}
      className={clsx(
        classes.tableCell,
        classes.flexContainer,
        classes.noClick,
        classes.tableCellHeader,
        hasHeaderSearchBar && classes.searchBarWrapperAll,
        allowHeaderSearchBar && classes.searchBarWrapper
      )}
      variant="head"
      style={{ height: headerHeight }}
      align={column.numeric ? 'right' : 'left'}
    >
      {typeof handleSort === 'function' ? (
        <div
          className={classes.sortIndicatorContainer}
          onClick={() => handleSort(dataKey, isAscendingOrder ? 'DESC' : 'ASC')}
        >
          <>
            <span
              style={{
                fontWeight: `${sortBy === dataKey ? 'bold' : 'normal'}`,
              }}
            >
              {label}
            </span>
            {isAscendingOrder ? (
              <MdArrowUpward style={activeSort} />
            ) : (
              <MdArrowDownward style={activeSort} />
            )}
          </>
        </div>
      ) : (
        <span>{label}</span>
      )}
      {allowHeaderSearchBar && (
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <MdSearch />
          </div>
          <InputBase
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            onChange={onSearchChange}
            inputProps={{ type: 'search' }}
          />
        </div>
      )}
    </TableCell>
  );
}

function labelHeaderWithSearch(props, column) {
  const { label } = props;
  const {
    classes,
    sortDirection,
    sortBy,
    handleSort,
    dataKey,
    allowHeaderSearchBar = false,
    onSearchChange,
    hasHeaderSearchBar = false,
    isOpenSearch = false,
    toggleOpenSearch = false,
  } = column;
  const isAscendingOrder = sortDirection === 'ASC' && dataKey === sortBy;
  const activeSort =
    sortBy === dataKey
      ? { fontSize: '16px', color: 'black' }
      : { fontSize: '16px' };
  return (
    <TableCell
      component="div"
      padding={column.padding}
      className={clsx(
        classes.tableCell,
        classes.flexContainer,
        classes.noClick,
        classes.tableCellHeader,
        hasHeaderSearchBar && classes.searchBarWrapperAll,
        allowHeaderSearchBar && classes.searchBarWrapper
      )}
      variant="head"
      style={{ height: headerHeight }}
      align={column.numeric ? 'right' : 'left'}
    >
      {typeof handleSort === 'function' ? (
        <div
          className={classes.sortIndicatorContainer}
          onClick={() => handleSort(dataKey, isAscendingOrder ? 'DESC' : 'ASC')}
          style={{ width: 'inherit' }}
        >
          <span>{label}</span>
          {isAscendingOrder ? (
            <MdArrowUpward style={activeSort} />
          ) : (
            <MdArrowDownward style={activeSort} />
          )}
        </div>
      ) : (
        <span>{label}&nbsp;</span>
      )}

      {allowHeaderSearchBar && (
        <Tooltip title="Click to Search">
          <IconButton
            onClick={toggleOpenSearch}
            aria-label="search"
            style={{ padding: 0 }}
          >
            <MdSearch style={activeSort} />
          </IconButton>
        </Tooltip>
      )}

      {allowHeaderSearchBar && isOpenSearch && (
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <MdSearch />
          </div>
          <InputBase
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            onChange={onSearchChange}
            inputProps={{ type: 'search' }}
          />
        </div>
      )}
    </TableCell>
  );
}

function tagTypeCell(props, column) {
  const { cellData, rowData } = props;
  const { classes, onClick } = column;
  let data = cellData;
  if (column.transform !== undefined) {
    data = column.transform(cellData, rowData);
  }
  return (
    <TableCell
      component="div"
      padding={column.padding}
      className={clsx(classes.tableCell, classes.scrollingFlexContainer)}
      variant="body"
      style={{ height: rowHeight }}
      align={column.numeric ? 'right' : 'left'}
      onClick={onClick ? () => onClick(rowData) : null}
    >
      <label className={classes.usageLabel}>{data}</label>
    </TableCell>
  );
}

function tagButtonTypeCell(props, column) {
  const { cellData } = props;
  const { classes } = column;
  let data = cellData;

  return (
    <TableCell
      component="div"
      padding={column.padding}
      className={clsx(classes.tableCell)}
      variant="body"
      style={{
        height: rowHeight,
        overflow: 'auto',
      }}
      sx={{
        marginRight: '1rem',
        '&::-webkit-scrollbar': {
          height: '6px',
          backgroundColor: '#F5F5F5',
        },
        '&::-webkit-scrollbar-track': {
          '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)',
          backgroundColor: '#F5F5F5',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#a4a4a4',
        },
      }}
      align={column.numeric ? 'right' : 'left'}
    >
      {data?.length > 0 && (
        <Each
          of={data}
          render={(at, index) => (
            <>
              <label
                className={classes.usageLabel}
                style={{
                  backgroundColor: at[column?.colorDataKey]
                    ? 'transparent'
                    : '#DCDCDC',
                  border: at[column?.colorDataKey]
                    ? `1px solid ${validateAndFormatColor(
                        at[column?.colorDataKey]
                      )}`
                    : '0',
                  whiteSpace: 'nowrap',
                }}
                key={column?.displayDataKey + index}
              >
                {at[column?.displayDataKey]}
              </label>
            </>
          )}
        />
      )}
    </TableCell>
  );
}

function createCell(props, column) {
  const { label } = props;
  const {
    classes,
    setViewPanel,
    dispatch,
    formKey,
    hasHeaderSearchBar,
    readOnly = false,
  } = column;
  const createLabel = `New ${label}`;

  const handleCreate = () => {
    dispatch(openPanel({ formKey: formKey, formAction: 'Create' }));
  };

  return (
    <TableCell
      component="div"
      padding={column.padding}
      className={clsx(
        classes.tableCell,
        classes.flexContainer,
        classes.noClick,
        hasHeaderSearchBar && classes.searchBarWrapperAll
      )}
      variant="head"
      style={{ height: headerHeight, whiteSpace: 'nowrap' }}
      align={column.numeric || false ? 'left' : 'right'}
    >
      {formKey === '' && (
        <div className={classes.newObject} onClick={() => setViewPanel(true)}>
          {createLabel}
          <FiPlusCircle style={{ marginLeft: '5px' }} />
        </div>
      )}
      {formKey !== '' && readOnly !== true && (
        <div className={classes.newObject} onClick={() => handleCreate()}>
          {createLabel}
          <FiPlusCircle style={{ marginLeft: '5px' }} />
        </div>
      )}
    </TableCell>
  );
}

function labelCaptionHeader(props, column) {
  const { label } = props;
  const {
    classes,
    allowHeaderSearchBar = false,
    hasHeaderSearchBar = false,
  } = column;

  return (
    <TableCell
      component="div"
      padding={column.padding}
      className={clsx(
        classes.tableCell,
        classes.flexContainer,
        classes.noClick,
        classes.tableCellHeader,
        hasHeaderSearchBar && classes.searchBarWrapperAll,
        allowHeaderSearchBar && classes.searchBarWrapper
      )}
      variant="head"
      style={{ height: headerHeight, width: 'auto' }}
      align={column.numeric ? 'right' : 'left'}
    >
      <Typography
        variant="caption"
        sx={{ wordBreak: 'break-word', textTransform: 'capitalize' }}
      >
        {label}
      </Typography>
    </TableCell>
  );
}

function labelColorBadge(props, column) {
  const { cellData, rowData } = props;
  const { classes, onClick } = column;
  let data = cellData;
  if (column.transform !== undefined) {
    data = column.transform(cellData, rowData);
  }
  return (
    <TableCell
      component="div"
      padding={column.padding}
      className={clsx(classes.tableCell, classes.scrollingFlexContainer)}
      variant="body"
      style={{ height: rowHeight }}
      align={column.numeric ? 'right' : 'left'}
      onClick={onClick ? () => onClick(rowData) : null}
    >
      {data && (
        <MdCheckBoxOutlineBlank
          style={{
            fill: validateAndFormatColor(data),
            backgroundColor: validateAndFormatColor(data),
            marginRight: '0rem',
            width: '2rem',
            height: '1.5rem',
          }}
        />
      )}
    </TableCell>
  );
}

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: '#ffffff',
    boxShadow: '5px 5px 17px rgb(0 0 0 / 47%)',
    maxWidth: 320,
    maxHeight: '300px',
    overflow: 'auto',
    padding: '1rem 0.8rem',
    borderRadius: '0.5rem',
  },
}));

function tagTypeCellWithToolTip(props, column) {
  const { cellData, rowData } = props;
  const { classes, toolTipKey, dispatch, formKey, formId } = column;
  let data = cellData;
  if (column.transform !== undefined) {
    data = column.transform(cellData, rowData);
  }
  return (
    <TableCell
      component="div"
      padding={column.padding}
      className={clsx(classes.tableCell, classes.scrollingFlexContainer)}
      variant="body"
      style={{ height: rowHeight, pointerEvents: 'auto' }}
      align={column.numeric ? 'right' : 'left'}
      onClick={(event) => {
        event.stopPropagation();
        dispatch(
          setEditObjectId({
            formKey: formKey,
            object_id: rowData[formId],
          })
        );
        dispatch(openPanel({ formKey: formKey, formAction: 'Create' }));
      }}
    >
      {rowData?.[toolTipKey]?.length > 0 ? (
        <HtmlTooltip
          title={
            <Stack direction="row" sx={{ flexWrap: 'wrap', gap: '0.5rem' }}>
              <Each
                of={rowData?.[toolTipKey]}
                render={(item) => <Chip label={item} />}
              />
            </Stack>
          }
        >
          <label className={classes.usageLabel}>{data}</label>
        </HtmlTooltip>
      ) : (
        <label className={classes.usageLabel}>{data}</label>
      )}
    </TableCell>
  );
}

export {
  activeCell,
  actionCell,
  checkCell,
  checkHeaderCell,
  createCell,
  labelHeader,
  labelCell,
  clipLabelTooltipCell,
  conditionalLabelCell,
  statusCell,
  feedbackCell,
  progressStatusCell,
  labelHeaderWithSearch,
  readOnlyCheckCell,
  actionEditCell,
  labelCaptionHeader,
  styledLabelCell,
  conditionalCheckCell,
  tagButtonTypeCell,
  tagTypeCell,
  labelColorBadge,
  tagTypeCellWithToolTip,
};
