import {
  MdDelete,
  MdEdit,
  MdPresentToAll,
  MdPublish,
  MdUploadFile,
  MdVisibility,
} from 'react-icons/md';

export const ActionIds = {
  Delete: 0,
  Edit: 1,
  Publish: 2,
  PublishArray: 3,
  DeleteArray: 4,
  Import: 15,
  Export: 16,
  View: 19,
  AddJobRoles: 20,
  AddSkill: 21,
  AddCapability: 22,
  AddJobRoleCapability: 23,
};

export default function getActionIcon(iconId, styles = null) {
  switch (iconId) {
    case ActionIds.Delete:
      return <MdDelete />;
    case ActionIds.Edit:
      return <MdEdit />;
    case ActionIds.Publish:
      return <MdPublish />;
    case ActionIds.PublishArray:
      return <MdPublish />;
    case ActionIds.DeleteArray:
      return <MdDelete />;
    case ActionIds.Import:
      return <MdUploadFile style={styles} />;
    case ActionIds.Export:
      return <MdPresentToAll style={styles} />;
    case ActionIds.View:
      return <MdVisibility style={styles} />;
    case ActionIds.AddJobRoles:
      return <MdEdit />;
    case ActionIds.AddSkill:
      return <MdEdit />;
    case ActionIds.AddCapability:
      return <MdEdit />;
    case ActionIds.AddJobRoleCapability:
      return <MdEdit />;
    default:
      return null;
  }
}
