import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

export function getNetworkService({ checkAuth, config }) {
  async function get(url, timeout = 10000) {
    const requestOptions = {
      headers: { 'Content-Type': 'application/json', ...authHeader(url) },
      timeout: timeout,
    };
    try {
      var response = await axios.get(url, requestOptions);
      return handleAxiosResponse(response);
    } catch (e) {
      return Promise.reject({
        code: e.code,
        error: e?.response?.data ? e?.response?.data : e.message,
      });
    }
  }

  async function post(url, data, timeout = 10000) {
    const requestOptions = {
      headers: { 'Content-Type': 'application/json', ...authHeader(url) },
      withCredentials: true,
      timeout: timeout,
    };
    try {
      var response = await axios.post(url, data, requestOptions);
      return handleAxiosResponse(response);
    } catch (e) {
      return Promise.reject({ code: e.code, error: e.message });
    }
  }

  async function postMultiFormData(
    url,
    formData,
    params,
    timeout = 0,
    transId = false
  ) {
    var uuid = uuidv4();
    if (!transId) {
      formData.append('transaction_id', uuid);
    }

    var timer = null;

    var config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        ...authHeader(url),
      },
      timeout: timeout,
    };

    var progressParams = null;

    if (params && params.onUploadProgress) {
      config['onUploadProgress'] = (progressEvent) => {
        progressParams = { progressEvent, url, params, timer, uuid };
        progressHandler(params, progressParams);
      };
    }

    try {
      var response = await axios.post(url, formData, config);

      if (progressParams && progressParams?.timer != null) {
        clearInterval(progressParams?.timer);
        progressParams.timer = null;
        if (params.onProcessProgress) {
          params.onProcessProgress(100);
        }
        if (params.onProcessFullProgress) {
          params.onProcessFullProgress({ percent_done: 100 });
        }
        await new Promise((r) => setTimeout(r, 1000));
      }
      return response;
    } catch (e) {
      clearInterval(progressParams?.timer);
      if (progressParams?.timer) {
        progressParams.timer = null;
      }
      return Promise.reject({
        code: e?.response?.status,
        error: e?.response?.data,
      });
    }
  }

  async function put(url, body, timeout = 0) {
    let requestOptions = {
      headers: { 'Content-Type': 'application/json', ...authHeader(url) },
      timeout: timeout,
    };

    try {
      var response = await axios.put(url, body, requestOptions);
      return handleAxiosResponse(response);
    } catch (e) {
      return Promise.reject({ code: e.code, error: e.message });
    }
  }

  async function putMultiFormData(url, formData, params, timeout = 0) {
    var uuid = uuidv4();
    formData.append('transaction_id', uuid);

    var timer = null;

    var config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        ...authHeader(url),
      },
      timeout: timeout,
    };

    var progressParams = null;

    if (params && params.onUploadProgress) {
      config['onUploadProgress'] = (progressEvent) => {
        progressParams = { progressEvent, url, params, timer, uuid };
        progressHandler(params, progressParams);
      };
    }

    try {
      var response = await axios.put(url, formData, config);
      if (progressParams && progressParams?.timer != null) {
        clearInterval(progressParams?.timer);
        progressParams.timer = null;
        if (params.onProcessProgress) {
          params.onProcessProgress(100);
        }
        if (params?.onProcessFullProgress) {
          params?.onProcessFullProgress({ percent_done: 100 });
        }
        await new Promise((r) => setTimeout(r, 1000));
      }

      return response;
    } catch (e) {
      clearInterval(progressParams?.timer);
      if (progressParams?.timer) {
        progressParams.timer = null;
      }
      return Promise.reject({
        code: e?.response?.status,
        error: e?.response?.data,
      });
    }
  }

  async function _delete(url) {
    const requestOptions = {
      headers: authHeader(url),
    };
    try {
      const response = await axios.delete(url, requestOptions);
      return handleAxiosResponse(response);
    } catch (e) {
      return Promise.reject({
        code: e?.response?.status,
        error: e?.response?.data,
      });
    }
  }

  async function _deleteWithBody(url, body) {
    try {
      const response = await axios.delete(url, {
        headers: {
          ...authHeader(url),
        },
        data: body,
      });
      return handleAxiosResponse(response);
    } catch (e) {
      return Promise.reject({
        code: e?.response?.status,
        error: e?.response?.data,
      });
    }
  }

  const progressHandler = (params, progressParams) => {
    alert('progressHandler not implmeneted');
    return;

    params.onUploadProgress(progressParams.progressEvent);
    if (
      progressParams.progressEvent.total === progressParams.progressEvent.loaded
    ) {
      if (params.onProcessProgress) {
        params.onProcessProgress(0);
      }
      if (params.onProcessFullProgress) {
        params.onProcessFullProgress({
          percent_done: 0,
          total_files: 0,
          files_transferred: 0,
        });
      }
      progressParams.timer = setInterval(async () => {
        if (params.onProcessProgress) {
          let response = await axios.get(
            `${config.apiUrl}/progress/${progressParams.uuid}`,
            {
              headers: {
                'Content-Type': 'application/json',
                ...authHeader(progressParams.url),
              },
            }
          );
          params.onProcessProgress(response.data.percent_done);
        }
        if (params.onProcessFullProgress) {
          let response = await axios.get(
            `${config.apiUrl}/progress/fullprogress/${progressParams.uuid}`,
            {
              headers: {
                'Content-Type': 'application/json',
                ...authHeader(progressParams.url),
              },
            }
          );
          params.onProcessFullProgress(response.data);
        }
      }, 2000);
    }
  };

  // helper functions
  function authHeader(url) {
    // return auth header with jwt if user is logged in and request is to the api url
    const user = JSON.parse(localStorage.getItem('user'));
    const isLoggedIn = user && user.jwt_token;
    const isApiUrl = url.startsWith(config.apiUrl);
    if (isLoggedIn && isApiUrl) {
      return { Authorization: `Bearer ${user.jwt_token}` };
    } else {
      return {};
    }
  }

  function handleAxiosResponse(response) {
    if (response.status < 200 || response.status > 299) {
      checkAuth(response.status);
      const error =
        (response.data && response.data.message) || response.statusText;
      return Promise.reject(error);
    }
    if (response.headers['x-pagination']) {
      response.headers['x-pagination'] = JSON.parse(
        response.headers['x-pagination']
      );
    }

    if (response.headers['download-filename']) {
      response.headers['filename'] = response.headers['download-filename'];
      delete response.headers['download-filename'];
    }

    return { headers: response.headers, data: response.data };
  }

  async function getWithAbortController(url, signal, timeout = 10000) {
    const requestOptions = {
      headers: { 'Content-Type': 'application/json', ...authHeader(url) },
      timeout: timeout,
      signal,
    };

    try {
      var response = await axios.get(url, requestOptions);
      return handleAxiosResponse(response);
    } catch (e) {
      return Promise.reject({
        code: e.code,
        error: e?.response?.data ? e?.response?.data : e.message,
      });
    }
  }

  return {
    get,
    post,
    postMultiFormData,
    delete: _delete,
    put,
    putMultiFormData,
    deleteWithBody: _deleteWithBody,
    getWithAbortController,
  };
}
