import { useState, useEffect, useRef, useCallback } from 'react';
import { Formik, Form, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

import { FormActions } from 'helpers/form-action';
import { makeFormStyles } from 'forms/style';
import {
  activateLoading,
  deactivateLoading,
} from 'features/global/loadingProgressSlice';
import {
  EMPLOYEE_CACHE,
  JOB_ID,
  SystemRoleEnums,
  snackbarHandler,
} from 'helpers';
import { SnackbarDismiss } from 'shared/components/SnackbarDismiss';
import Loader from 'shared/components/Loader';
import { SelectionAutoComplete } from 'shared/components/_Autocomplete/SelectionAutocomplete';
import { Box, Button, Grid, Typography } from '@mui/material';
import moment from 'moment';
import {
  fetchCostCenterDropDown,
  fetchJobRolesByJobId,
  fetchPayGradesDropDown,
  fetchPayPlansDropDown,
  fetchSystemRolesForEmployeeDropDown,
  getCostCenterDropDownStatus,
  getPayGradesDropDownStatus,
  getPayPlansDropDownStatus,
  getSystemRolesForEmployeeDropDownStatus,
  selectCostCenterDropDown,
  selectPayGradesDropdown,
  selectPayPlansDropdown,
  selectSystemRolesForEmployeeDropdown,
  selectOfficeByCostCenterIdDropDown,
  getOfficeByCostCenterIdDropDownStatus,
  fetchOfficeByCostCenterId,
  resetOfficeIdDropDown,
  resetDropDownState,
  fetchAllEmployeesDropDown,
  getAllEmployeeDropDownStatus,
  selectAllEmployeeDropDown,
} from 'features/AdminSettingsDropDown/dropDownSlice';
import { resetJobState } from 'features/job/jobSlice';
import {
  closePanel,
  getDirtyFlag,
  openPanel,
  setEditObjectId,
} from 'features/common/formSlice';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  createAdminEmployee,
  fetchAdminEmployeeById,
  resetEmployeeState,
  updateAdminEmployee,
  validateDoDId,
  validateEmail,
} from 'features/employee/employeeSlice';
import { EmployeeJobForm } from 'components/ItemTeamsTable/EmployeeModal';
import {
  resetEmployeeCapabilities,
  resetEmployeeSkill,
  resetTeamEmployeeState,
  selectSearchCache,
  updateSearchCache,
} from 'features/Teams/teamSlice';
import { FormTextField } from 'shared/components/FormFields/TextField';
import {
  selectAdminSettingCache,
  updateAdminSettingCache,
} from 'features/global/visibilitySlice';
import { IsFormDirty } from 'forms/_helpers/IsFormDirty';
import { useDebounce } from 'hooks/useDebounce';
import FieldValidationError from 'shared/components/FormFields/FieldValidationError';
import { EmployeeCapabilityForm } from './CapabilityForm';

const ItemEmployeeForm = ({
  formAction,
  itemTagId,
  isUpdatable,
  formInfo,
  isSameForm,
  user,
  isReadOnly,
}) => {
  //#region Constants
  const classes = makeFormStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const tagNameRef = useRef(null);
  const validateRef = useRef();
  const isFormEdit = formAction?.id === FormActions.Edit.id;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isHideMenu = searchParams ? searchParams.get('hide-menu') : null;
  const getCacheData = useSelector(selectSearchCache);
  const employeeCacheData = useSelector(selectAdminSettingCache);
  const isEmployeeFormDirty = useSelector(getDirtyFlag);
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  const [initValues, setInitValues] = useState({
    first_name: '',
    last_name: '',
    email: '',
    dod_id: null,
    cost_center_id: null,
    pay_plan_id: null,
    pay_grade_id: null,
    system_role_id: null,
    new_job_id: null,
    new_job_role_id: null,
    supervisor_employee_id: null,
    task_manager_employee_id: null,
    suffix: '',
    middle_initial: '',
    office_id: null,
    job_history: [],
    capabilities: [],
  });
  const [loaded, setLoaded] = useState(
    formAction?.id === FormActions.Create.id ? true : false
  );
  const [createdDateFilter, setCreatedDateFilter] = useState(
    moment(new Date())
  );
  const [newJob, setNewJob] = useState(false);
  const [newUserDropDown, setNewUserDropDown] = useState({
    ...initValues,
  });
  const [emailError, setEmailError] = useState(false);
  const [dodIdError, setDodIdError] = useState(false);
  const [newCapabilities, setNewCapabilities] = useState({
    capabilities: [],
  });
  //#endregion State

  //#region Selectors
  console.log(user);
  //#endregion Selectors

  //#region Refs
  //#endregion Refs
  //#region Effects
  useEffect(() => {
    if (formAction?.id === FormActions.Edit.id) {
      const fetchData = async () => {
        var objectData = await dispatch(fetchAdminEmployeeById(itemTagId));
        var fetchedObject = objectData.payload.employees;
        setInitValues({
          first_name: fetchedObject?.first_name,
          last_name: fetchedObject?.last_name,
          email: fetchedObject?.email,
          dod_id: fetchedObject?.dod_id,
          cost_center_id: fetchedObject?.cost_center_id,
          pay_plan_id: fetchedObject?.pay_plan_id,
          pay_grade_id: fetchedObject?.pay_grade_id,
          system_role_id: fetchedObject?.system_role_id,
          supervisor_employee_id: fetchedObject?.supervisor_employee_id,
          task_manager_employee_id: fetchedObject?.task_manager_employee_id,
          suffix: fetchedObject?.suffix,
          middle_initial: fetchedObject?.middle_initial,
          office_id: fetchedObject?.office_id,
          job_history: fetchedObject.job_history?.map((employee) => ({
            is_employee_current_role: employee.is_employee_current_role,
            series_id: employee?.series_id,
            role_name: employee?.role_name,
            employee_job_role_id: employee?.employee_job_role_id,
            job: employee?.job,
            start_date: employee?.start_date,
            end_date: employee?.end_date || null,
            is_active: employee?.is_active,
            job_id: employee?.job_id,
            job_role_id: employee?.job_role_id,
          })),
          capabilities: fetchedObject?.capabilities,
        });
        setNewCapabilities({
          capabilities: fetchedObject?.capabilities.map((cap) => ({
            ...cap,
            text: cap?.capability,
            value: cap?.capability_id,
          })),
        });
        dispatch(
          fetchSystemRolesForEmployeeDropDown({
            employee_id: itemTagId,
          })
        );
        dispatch(
          fetchOfficeByCostCenterId({
            cost_center_id: fetchedObject?.cost_center_id,
          })
        );
        setLoaded(true);
      };
      fetchData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    async function fetchJobRole() {
      dispatch(activateLoading());
      await dispatch(
        fetchJobRolesByJobId({
          job_id: newUserDropDown?.new_job_id,
        })
      );
      dispatch(deactivateLoading());
    }
    async function fetchOfficeId() {
      dispatch(activateLoading());
      await dispatch(
        fetchOfficeByCostCenterId({
          cost_center_id: newUserDropDown?.cost_center_id,
        })
      );
      dispatch(deactivateLoading());
    }

    if (newUserDropDown.new_job_id) {
      fetchJobRole();
    }

    if (newUserDropDown.cost_center_id) {
      fetchOfficeId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newUserDropDown]);

  useEffect(() => {
    return async () => {
      await dispatch(resetEmployeeState());
      await dispatch(resetOfficeIdDropDown());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //#endregion Effects

  //#region Methods
  const setDependantIds = async (value, setFieldValue, key) => {
    if (key === JOB_ID && value) {
      setNewUserDropDown((prevValue) => ({
        ...prevValue,
        new_job_id: value.value,
        new_job_role_id: null,
      }));
      setFieldValue('new_job_id', value.value);
      setFieldValue('new_job_role_id', null);
    }
    if (key === 'cost_center_id' && value) {
      setNewUserDropDown((prevValue) => ({
        ...prevValue,
        cost_center_id: value.value,
      }));
      setFieldValue('cost_center_id', value.value);
      setFieldValue('office_id', null);
    }
  };

  const removeDependantIds = async (setFieldValue, key) => {
    dispatch(activateLoading());
    if (key === JOB_ID) {
      setFieldValue('new_job_id', null);
      setFieldValue('new_job_role_id', null);
      setNewUserDropDown((prevValue) => ({
        ...prevValue,
        new_job_id: null,
        new_job_role_id: null,
      }));
      await dispatch(fetchJobRolesByJobId());
    }
    if (key === 'cost_center_id') {
      setFieldValue('cost_center_id', null);
      setNewUserDropDown((prevValue) => ({
        ...prevValue,
        cost_center_id: null,
      }));
      await dispatch(fetchOfficeByCostCenterId());
    }
    dispatch(deactivateLoading());
  };

  const debouncedEmailValidation = useDebounce(async (employeeId, email) => {
    try {
      if (validateRef.current) {
        validateRef.current.abort();
      }
      validateRef.current = new AbortController();
      const response = await dispatch(
        validateEmail({
          employee_id: employeeId ?? null,
          email: email,
          signal: validateRef.current.signal,
        })
      );
      if (response?.payload) {
        enqueueSnackbar(response?.payload, {
          action: (key) => <SnackbarDismiss className="abcc" key={key} />,
          variant: 'error',
          autoHideDuration: 3000,
          style: {
            maxWidth: '400px',
          },
        });
        setEmailError(true);
      } else {
        setEmailError(false);
      }
    } catch (error) {
      console.error('Error validating email:', error);
    }
  }, 500);

  const debouncedDodIdValidation = useDebounce(async (employeeId, dodId) => {
    try {
      if (validateRef.current) {
        validateRef.current.abort();
      }
      validateRef.current = new AbortController();
      const response = await dispatch(
        validateDoDId({
          employee_id: employeeId ?? null,
          dodId: dodId,
          signal: validateRef.current.signal,
        })
      );
      if (response?.payload) {
        enqueueSnackbar(response?.payload, {
          action: (key) => <SnackbarDismiss className="abcc" key={key} />,
          variant: 'error',
          autoHideDuration: 3000,
          style: {
            maxWidth: '400px',
          },
        });
        setDodIdError(true);
      } else {
        setDodIdError(false);
      }
    } catch (error) {
      console.error('Error validating email:', error);
    }
  }, 500);

  const handleEmailChange = async (employeeId, email) => {
    debouncedEmailValidation(employeeId, email);
  };

  const handleDodIdChange = async (employeeId, dodId) => {
    debouncedDodIdValidation(employeeId, dodId);
  };

  const handleSubmit = async (values, setSubmitting, setFieldValue) => {
    dispatch(activateLoading({ showProgress: true }));
    var resultAction = null;
    const getCompletionDate = createdDateFilter.toISOString();
    const filteredCapabilities = newCapabilities.capabilities?.map(
      (cap) => cap?.value
    );
    switch (formAction?.id) {
      case FormActions.Edit.id:
        resultAction = await dispatch(
          updateAdminEmployee({
            employee_id: itemTagId,
            new_start_date: getCompletionDate,
            ...values,
            capability_ids: filteredCapabilities,
          })
        );
        if (!resultAction.error) {
          setInitValues({
            ...initValues,
            first_name: resultAction?.payload.first_name,
            last_name: resultAction?.payload.last_name,
            email: resultAction?.payload.email,
            dod_id: resultAction?.payload.dod_id,
            cost_center_id: resultAction?.payload.cost_center_id,
            pay_plan_id: resultAction?.payload.pay_plan_id,
            pay_grade_id: resultAction?.payload.pay_grade_id,
            system_role_id: resultAction?.payload.system_role_id,
            supervisor_employee_id:
              resultAction?.payload.supervisor_employee_id,
            task_manager_employee_id:
              resultAction?.payload.task_manager_employee_id,
            suffix: resultAction?.payload?.suffix,
            middle_initial: resultAction?.payload?.middle_initial,
            office_id: resultAction?.payload?.office_id,
            capabilities: resultAction?.payload?.capabilities,
          });
          dispatch(closePanel({ formKey: `itemEmployeeForm` }));
          if (isUpdatable) {
            isHideMenu === true
              ? navigate(`/employee/${itemTagId}?hide-menu=${isHideMenu}`)
              : navigate(`/employee/${itemTagId}`);
            dispatch(resetEmployeeState());
          }
          let diffKey = [];
          if (
            getCacheData?.employee_id?.text !==
            resultAction?.payload?.employees?.full_name
          ) {
            diffKey.push('employee_id');
          }
          if (
            getCacheData?.supervisor_employee_id?.value === itemTagId &&
            getCacheData?.supervisor_employee_id?.text !==
              resultAction?.payload?.employees?.full_name
          ) {
            diffKey.push('supervisor_employee_id');
          }

          if (
            employeeCacheData?.[EMPLOYEE_CACHE]?.supervisor_employee_id
              ?.value === itemTagId &&
            employeeCacheData?.[EMPLOYEE_CACHE]?.supervisor_employee_id
              ?.text !== resultAction?.payload?.employees?.full_name
          ) {
            dispatch(
              updateAdminSettingCache({
                key: EMPLOYEE_CACHE,
                payload: {
                  supervisor_employee_id: {
                    value: itemTagId,
                    text: resultAction?.payload?.employees?.full_name,
                  },
                },
              })
            );
          }

          if (diffKey.length > 0 && isSameForm) {
            diffKey.forEach((key) => {
              dispatch(
                updateSearchCache({
                  onlyKeyChange: true,
                  data: {
                    [key]: {
                      value: itemTagId,
                      text: resultAction?.payload?.employees?.full_name,
                    },
                  },
                })
              );
            });
          }
          if (formInfo?.isOpen && isEmployeeFormDirty) {
            await dispatch(resetTeamEmployeeState());
            await dispatch(resetEmployeeCapabilities());
            await dispatch(resetEmployeeSkill());
          }
        }
        break;
      case FormActions.Create.id:
        resultAction = await dispatch(
          createAdminEmployee({
            new_start_date: getCompletionDate,
            ...values,
            capability_ids: filteredCapabilities,
          })
        );
        if (!resultAction.error) {
          const empId = resultAction.payload.data.employee_id;
          dispatch(fetchAdminEmployeeById(empId));
          if (tagNameRef.current) {
            tagNameRef.current.focus();
          }
          dispatch(closePanel({ formKey: `itemEmployeeForm` }));
          if (isUpdatable) {
            isHideMenu === true
              ? navigate(`/employee/${empId}?hide-menu=${isHideMenu}`)
              : navigate(`/employee/${empId}`);

            await dispatch(
              setEditObjectId({
                formKey: `itemEmployeeForm`,
                object_id: empId,
              })
            );
            await dispatch(
              openPanel({
                formKey: `itemEmployeeForm`,
                formAction: 'Edit',
              })
            );
          }
        }
        setFieldValue('new_job_id', null);
        setFieldValue('new_job_role_id', null);
        setCreatedDateFilter(moment(new Date()));
        break;
      default:
        break;
    }
    dispatch(resetDropDownState());
    dispatch(deactivateLoading());
    const { message, variant } = snackbarHandler(
      resultAction.meta.requestStatus,
      formAction?.label
    );
    enqueueSnackbar(message, {
      action: (key) => <SnackbarDismiss key={key} />,
      variant: variant,
    });
    dispatch(resetJobState());
    setSubmitting(false);
  };

  const handleFormValidate = useCallback(
    (values) => {
      let errors = {};
      if (emailError) {
        errors = {
          ...errors,
          Email: 'Other employees are already assigned this Email',
        };
      }
      if (dodIdError) {
        errors = {
          ...errors,
          dodIds: 'Other employees are already assigned this DoD ID',
        };
      }
      Object.values(values.job_history).forEach((item, index) => {
        if (!item.job_role_id && item.is_active) {
          errors[`job_history.[${index}].job_role_id`] = 'Required';
        }
        if (!item.start_date && item.is_active) {
          errors[`job_history.[${index}].start_date`] = 'Required';
        }
        if (
          item.start_date &&
          item.end_date &&
          item.end_date < item.start_date &&
          item.is_active
        ) {
          errors[`job_history.[${index}].end_date`] =
            'End date must be after start date';
        }
      });
      return errors;
    },
    [emailError, dodIdError]
  );

  return !loaded ? (
    <Loader styleClassName={classes.loadingSvg} />
  ) : (
    <Formik
      enableReinitialize={true}
      initialValues={{ ...initValues }}
      validationSchema={Yup.object({
        first_name: Yup.string().required('Required'),
        last_name: Yup.string().required('Required'),
        pay_grade_id: Yup.number().required('Required'),
        pay_plan_id: Yup.string().required('Required'),
        system_role_id: Yup.string().required('Required'),
        supervisor_employee_id:
          [
            SystemRoleEnums.Admin,
            SystemRoleEnums.Supervisor,
            SystemRoleEnums.Certifier,
            SystemRoleEnums.User,
          ].includes(user.role) && Yup.number().required('Required'),
        email: Yup.string().email().required('Required'),
        new_job_role_id: newJob && Yup.number().required('Required'),
        middle_initial: Yup.string()
          .max(1, 'Field should have a maximum of 1 character')
          .nullable(),
      })}
      validate={handleFormValidate}
      onSubmit={(values, { setSubmitting, setFieldValue }) => {
        let updatedValues = { ...values };
        updatedValues?.job_history.forEach((job) => {
          if (job.job_role_id && typeof job.job_role_id === 'object') {
            job.job_role_id = job.job_role_id.value;
          }
          if (job.job_id && typeof job.job_id === 'object') {
            job.job_id = job.job_id.value;
          }
        });
        handleSubmit(updatedValues, setSubmitting, setFieldValue);
      }}
      validateOnBlur={false}
    >
      {({ isSubmitting, errors, values, validateForm, setFieldValue }) => (
        <Form className={classes.form} style={{ padding: '0' }}>
          <IsFormDirty />
          {isFormEdit && (
            <Box
              sx={{
                paddingBottom: '1rem',
                textAlign: 'center',
              }}
            >
              <Typography>
                Use the fields below to create new user. All fields are
                required.
              </Typography>
            </Box>
          )}
          <Grid container>
            <Grid item={true} xs={12} lg={5} sx={{ padding: '0 1rem' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '0.5rem',
                }}
              >
                <FormTextField
                  label="First Name"
                  name="first_name"
                  placeholder="First Name"
                  validationError={errors?.first_name}
                  disabled={isReadOnly}
                />
                <FormTextField
                  label="M.I."
                  name="middle_initial"
                  placeholder="M.I."
                  validationError={errors?.middle_initial}
                  disabled={isReadOnly}
                />
                <FormTextField
                  label="Last Name"
                  name="last_name"
                  placeholder="Last Name"
                  validationError={errors?.last_name}
                  disabled={isReadOnly}
                />
                <FormTextField
                  label="Suffix"
                  name="suffix"
                  placeholder="Suffix"
                  disabled={isReadOnly}
                />
                <FormTextField
                  type="email"
                  label="Email"
                  name="email"
                  placeholder="Email"
                  validationError={errors?.email || emailError}
                  disabled={isReadOnly}
                  onChange={(e) => {
                    const emailValue = e.target.value;
                    setFieldValue('email', emailValue);
                    handleEmailChange(itemTagId, emailValue);
                  }}
                />
                {emailError && (
                  <FieldValidationError
                    validationError="Other employees are already assigned this Email"
                    IsRelative
                  />
                )}
                <FormTextField
                  label="DoD Id"
                  name="dod_id"
                  placeholder="DoD Id"
                  disabled={isReadOnly}
                  validationError={dodIdError}
                  onChange={(e) => {
                    const dodId = e.target.value;
                    setFieldValue('dod_id', dodId);
                    handleDodIdChange(itemTagId, dodId);
                  }}
                />
                {dodIdError && (
                  <FieldValidationError
                    validationError="Other employees are already assigned this DodId"
                    IsRelative
                  />
                )}
                <Field className={classes.input} name="cost_center_id">
                  {({ field: { value }, form: { setFieldValue } }) => (
                    <SelectionAutoComplete
                      title="Select Cost Center"
                      keyProperty="value"
                      nameProperty="text"
                      entityIds={value}
                      setEntityIds={setFieldValue}
                      entitySelector={selectCostCenterDropDown}
                      entityStatusSelector={getCostCenterDropDownStatus}
                      fetchEntityPage={fetchCostCenterDropDown}
                      onCustomChange={(value) => {
                        setDependantIds(value, setFieldValue, 'cost_center_id');
                      }}
                      cancelItemTagEdit={() => {
                        removeDependantIds(setFieldValue, 'cost_center_id');
                      }}
                      formField={'cost_center_id'}
                      multiSelection={false}
                      showCloseItemEdit={true}
                      hideCheckIcon={true}
                      readOnly={isReadOnly}
                    />
                  )}
                </Field>
                <Field className={classes.input} name="office_id">
                  {({ field: { value }, form: { setFieldValue } }) => (
                    <SelectionAutoComplete
                      title="Select Office"
                      keyProperty="value"
                      nameProperty="text"
                      entityIds={value}
                      setEntityIds={setFieldValue}
                      entitySelector={selectOfficeByCostCenterIdDropDown}
                      entityStatusSelector={
                        getOfficeByCostCenterIdDropDownStatus
                      }
                      fetchEntityPage={fetchOfficeByCostCenterId}
                      fetchParams={{
                        cost_center_id: values?.cost_center_id,
                      }}
                      formField={'office_id'}
                      multiSelection={false}
                      showCloseItemEdit={true}
                      hideCheckIcon={true}
                      cancelItemTagEdit={() => {
                        setFieldValue('office_id', null);
                      }}
                      readOnly={isReadOnly}
                    />
                  )}
                </Field>
                <Field className={classes.input} name="pay_plan_id">
                  {({ field: { value }, form: { setFieldValue } }) => (
                    <SelectionAutoComplete
                      title="Select Pay Plan"
                      keyProperty="value"
                      nameProperty="text"
                      entityIds={value}
                      setEntityIds={setFieldValue}
                      entitySelector={selectPayPlansDropdown}
                      entityStatusSelector={getPayPlansDropDownStatus}
                      fetchEntityPage={fetchPayPlansDropDown}
                      formField={'pay_plan_id'}
                      multiSelection={false}
                      readOnly={isReadOnly}
                    />
                  )}
                </Field>
                {errors?.pay_plan_id && (
                  <Typography variant="subtitle2" className={classes.inputErr}>
                    {errors.pay_plan_id}
                  </Typography>
                )}
                <Field className={classes.input} name="pay_grade_id">
                  {({ field: { value }, form: { setFieldValue } }) => (
                    <SelectionAutoComplete
                      title="Select Pay Grade"
                      keyProperty="value"
                      nameProperty="text"
                      entityIds={value}
                      setEntityIds={setFieldValue}
                      entitySelector={selectPayGradesDropdown}
                      entityStatusSelector={getPayGradesDropDownStatus}
                      fetchEntityPage={fetchPayGradesDropDown}
                      formField={'pay_grade_id'}
                      multiSelection={false}
                      readOnly={isReadOnly}
                    />
                  )}
                </Field>
                {errors?.pay_grade_id && (
                  <Typography variant="subtitle2" className={classes.inputErr}>
                    {errors.pay_grade_id}
                  </Typography>
                )}
                <Field className={classes.input} name="system_role_id">
                  {({ field: { value }, form: { setFieldValue } }) => (
                    <SelectionAutoComplete
                      title="Select System Role"
                      keyProperty="value"
                      nameProperty="text"
                      entityIds={value}
                      setEntityIds={setFieldValue}
                      entitySelector={selectSystemRolesForEmployeeDropdown}
                      entityStatusSelector={
                        getSystemRolesForEmployeeDropDownStatus
                      }
                      fetchEntityPage={fetchSystemRolesForEmployeeDropDown}
                      formField={'system_role_id'}
                      multiSelection={false}
                      readOnly={isReadOnly}
                    />
                  )}
                </Field>
                {errors?.system_role_id && (
                  <Typography variant="subtitle2" className={classes.inputErr}>
                    {errors.system_role_id}
                  </Typography>
                )}
                <Field className={classes.input} name="supervisor_employee_id">
                  {({ field: { value }, form: { setFieldValue } }) => (
                    <SelectionAutoComplete
                      title="Select Supervisor"
                      keyProperty="value"
                      nameProperty="text"
                      entityIds={value}
                      setEntityIds={setFieldValue}
                      entitySelector={selectAllEmployeeDropDown}
                      entityStatusSelector={getAllEmployeeDropDownStatus}
                      fetchEntityPage={fetchAllEmployeesDropDown}
                      fetchQueryParams={{
                        include_self: true,
                      }}
                      formField={'supervisor_employee_id'}
                      multiSelection={false}
                      showCloseItemEdit={true}
                      hideCheckIcon={true}
                      cancelItemTagEdit={() => {
                        setFieldValue('supervisor_employee_id', null);
                      }}
                      readOnly={isReadOnly}
                    />
                  )}
                </Field>
                {errors?.supervisor_employee_id && (
                  <Typography variant="subtitle2" className={classes.inputErr}>
                    {errors.supervisor_employee_id}
                  </Typography>
                )}
                <Field
                  className={classes.input}
                  name="task_manager_employee_id"
                >
                  {({ field: { value }, form: { setFieldValue } }) => (
                    <SelectionAutoComplete
                      title="Select Task Manager"
                      keyProperty="value"
                      nameProperty="text"
                      entityIds={value}
                      setEntityIds={setFieldValue}
                      entitySelector={selectAllEmployeeDropDown}
                      entityStatusSelector={getAllEmployeeDropDownStatus}
                      fetchEntityPage={fetchAllEmployeesDropDown}
                      fetchQueryParams={{
                        include_self: true,
                      }}
                      formField={'task_manager_employee_id'}
                      multiSelection={false}
                      showCloseItemEdit={true}
                      hideCheckIcon={true}
                      cancelItemTagEdit={() => {
                        setFieldValue('task_manager_employee_id', null);
                      }}
                      readOnly={isReadOnly}
                    />
                  )}
                </Field>
              </Box>
            </Grid>
            <Grid item={true} xs={12} lg={7} sx={{ padding: '0 1rem' }}>
              <EmployeeCapabilityForm
                formAction={formAction}
                setInitValues={setNewCapabilities}
                initValues={newCapabilities}
                errors={errors}
                isReadOnly={isReadOnly}
              />
              <EmployeeJobForm
                formInfo={formInfo}
                formAction={formAction}
                handleSubmit={handleSubmit}
                setInitValues={setInitValues}
                values={values}
                initValues={initValues}
                errors={errors}
                validateForm={validateForm}
                setFieldValue={setFieldValue}
                isReadOnly={isReadOnly}
              />
            </Grid>
            <Grid item={true} xs={12} lg={7} sx={{ padding: '0 1rem' }}></Grid>
            {!isReadOnly && (
              <Grid item={true} xs={12} lg={5} sx={{ padding: '0 1rem' }}>
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                  disabled={isSubmitting || isReadOnly}
                  sx={{ marginTop: '0.5rem' }}
                >
                  {formAction.buttonLabel}
                </Button>
              </Grid>
            )}
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export { ItemEmployeeForm };
