import React, { useState } from 'react';
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { FaCheckSquare } from 'react-icons/fa';
import { MdCheckBoxOutlineBlank, MdOutlineSquare } from 'react-icons/md';
import { ItemCapabilitySkillModal } from '../ItemCapabilitySkillModal';
import { useDispatch, useSelector } from 'react-redux';

import { fetchEmployeeCls, selectEmployeeCls } from 'features/Teams/teamSlice';
import { NoDataMessage } from 'shared/components/_Tables/NoDataMessage';
import { useStyles } from './styles';
import {
  activateLoading,
  deactivateLoading,
} from 'features/global/loadingProgressSlice';
import { ReactComponent as GroupTrainingSmallIcon } from '../../../assets/group_train_sm.svg';
import { EmployeeSkillModal } from '../ItemEmployeeSkillModal';
import { NO_MATCHING_CAPABILITIES, SystemRoleEnums } from 'helpers';
import { selectActiveUser } from 'features/account/accountSlice';
import { Each } from 'helpers/Each';
import { mdTheme } from 'theme';

function ItemEmployeeTable({ employee, levelData, empId }) {
  //#region Constants
  const classes = useStyles();
  const dispatch = useDispatch();
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  const [openCapabilitySkillModal, setOpenCapabilitySkillModal] =
    useState(false);
  const [isEmpSkillModalOpen, setIsEmpSkillModalOpen] = useState(false);
  const [capabilityId, setCapabilityId] = useState(null);
  const [employeeTrainingPayload, setEmployeeTrainingPayload] = useState({});
  //#endregion State

  //#region Selectors
  const employeeClsData = useSelector(selectEmployeeCls);
  const user = useSelector(selectActiveUser);
  //#endregion Selectors

  //#region Refs
  //#endregion Refs

  //#region Effects
  //#endregion Effects

  //#region Methods
  const handleCapSkillModal = async (employeeIndex, levelIndex) => {
    if (levelData[levelIndex] && employee[employeeIndex]?.capability_id) {
      const employeeId = empId;
      const capabilityId = employee[employeeIndex]?.capability_id;
      const levelId = levelData[levelIndex]?.level_id;
      dispatch(activateLoading());
      await dispatch(fetchEmployeeCls({ employeeId, capabilityId, levelId }));
      setOpenCapabilitySkillModal(true);
      dispatch(deactivateLoading());
    }
  };

  const handleEmployeeData = (e, id) => {
    e.stopPropagation();
    if (id) {
      setCapabilityId(id);
      setEmployeeTrainingPayload({
        employee_ids: [empId],
        capability_id: id,
      });
      setIsEmpSkillModalOpen(true);
    }
  };
  //#endregion Methods

  //#region Render time calcs
  //#endregion Render time calcs

  //#region Render

  return (
    <>
      <div className={classes.root}>
        {employee?.length > 0 ? (
          <Box
            sx={{
              maxWidth: '100%',
              lg: { width: '690px' },
              overflow: 'hidden',
            }}
          >
            <TableContainer
              sx={{
                borderRadius: '0',
                overflow: 'auto',
                maxHeight: '535px',
                height: '100%',
              }}
              component={Paper}
            >
              <Table aria-label="simple table">
                <TableHead
                  sx={{
                    position: 'sticky',
                    top: '0',
                    backgroundColor: mdTheme.palette.common.white,
                    zIndex: '1',
                  }}
                >
                  <TableRow>
                    {[
                      SystemRoleEnums.BlueGrassAdmin,
                      SystemRoleEnums.ProgramManager,
                      SystemRoleEnums.Certifier,
                      SystemRoleEnums.Supervisor,
                    ].includes(user?.role) && <TableCell></TableCell>}
                    {levelData?.length > 0 && (
                      <Each
                        of={levelData}
                        render={(level) => (
                          <TableCell
                            key={level.level_id}
                            width="180px"
                            align="center"
                          >
                            <Typography variant="body2" fontWeight={'bold'}>
                              {level.level_display_name}
                            </Typography>
                            <MdOutlineSquare
                              style={{
                                fill: level.color,
                                background: level.color,
                                overflow: 'hidden',
                                marginTop: '0.5rem',
                                fontSize: '1rem',
                              }}
                            />
                          </TableCell>
                        )}
                      />
                    )}
                    <TableCell colSpan={2}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <Each
                    of={employee}
                    render={(capability, employeeIndex) => (
                      <TableRow>
                        <React.Fragment key={capability.capability_id}>
                          {[
                            SystemRoleEnums.BlueGrassAdmin,
                            SystemRoleEnums.ProgramManager,
                            SystemRoleEnums.Certifier,
                            SystemRoleEnums.Supervisor,
                          ].includes(user?.role) && (
                            <TableCell>
                              <IconButton
                                onClick={(event) =>
                                  handleEmployeeData(
                                    event,
                                    capability.capability_id
                                  )
                                }
                                sx={{
                                  width: '2rem',
                                  height: '2rem',
                                  overflow: 'hidden',
                                  marginLeft: 'auto',
                                }}
                              >
                                <GroupTrainingSmallIcon />
                              </IconButton>
                            </TableCell>
                          )}
                          {levelData?.length > 0 && (
                            <Each
                              of={levelData}
                              render={(level, levelIndex) => (
                                <TableCell
                                  key={level.level_id}
                                  width="130px"
                                  align="center"
                                >
                                  {capability.level_ids?.includes(
                                    level.level_id
                                  ) && (
                                    <IconButton
                                      onClick={() =>
                                        handleCapSkillModal(
                                          employeeIndex,
                                          levelIndex
                                        )
                                      }
                                    >
                                      {capability.certified_capability_levels?.includes(
                                        level.level_id
                                      ) ? (
                                        <FaCheckSquare
                                          className={classes.checkBoxIcons}
                                        />
                                      ) : (
                                        <MdCheckBoxOutlineBlank
                                          className={classes.checkBoxIcons}
                                        />
                                      )}
                                    </IconButton>
                                  )}
                                </TableCell>
                              )}
                            />
                          )}
                          <TableCell
                            sx={{
                              minWidth: '250px',
                            }}
                          >
                            {capability.capability_display_name}
                          </TableCell>
                          <TableCell
                            sx={{
                              minWidth: '150px',
                            }}
                          >
                            {capability?.capability_expiration}
                          </TableCell>
                        </React.Fragment>
                      </TableRow>
                    )}
                  />
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ) : (
          <NoDataMessage setHeight="100px" message={NO_MATCHING_CAPABILITIES} />
        )}
      </div>
      <ItemCapabilitySkillModal
        isOpen={openCapabilitySkillModal}
        employeeClsData={employeeClsData}
        handleClose={setOpenCapabilitySkillModal}
      />
      {isEmpSkillModalOpen && (
        <EmployeeSkillModal
          setIsSkillModalOpen={setIsEmpSkillModalOpen}
          isSkillModalOpen={isEmpSkillModalOpen}
          capabilityId={capabilityId}
          setEmployeeTrainingPayload={setEmployeeTrainingPayload}
          employeeTrainingPayload={employeeTrainingPayload}
        />
      )}
    </>
  );
}

export { ItemEmployeeTable };
