import { useCallback, useEffect, useRef, useState } from 'react';
import { Grid } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { SelectionAutoComplete } from 'shared/components/_Autocomplete/SelectionAutocomplete';
import {
  fetchCostCenterDropDown,
  fetchEmployeeStatusDropDown,
  fetchJobRolesByJobId,
  fetchJobsDropDown,
  fetchOfficeByCostCenterId,
  fetchPayGradesDropDown,
  fetchPayPlansDropDown,
  fetchSuperVisorDropDown,
  fetchSystemRolesDropDown,
  getCostCenterDropDownStatus,
  getEmployeeStatusDropDownStatus,
  getJobDropDownStatus,
  getJobRolesByJobIdDropDownStatus,
  getOfficeByCostCenterIdDropDownStatus,
  getPayGradesDropDownStatus,
  getPayPlansDropDownStatus,
  getSuperVisorDropDownStatus,
  getSystemRolesDropDownStatus,
  resetJobRoleDropdown,
  resetOfficeIdDropDown,
  selectCostCenterDropDown,
  selectEmployeeStatusDropDown,
  selectJobDropDown,
  selectJobRoleByJobIdDropDown,
  selectOfficeByCostCenterIdDropDown,
  selectPayGradesDropdown,
  selectPayPlansDropdown,
  selectSuperVisorDropDown,
  selectSystemRolesDropdown,
} from 'features/AdminSettingsDropDown/dropDownSlice';
import { selectActiveUser } from 'features/account/accountSlice';
import { FilterAccordion } from 'shared/components/_Accordion';
import { clearFilter } from 'helpers/clearFilter';
import {
  selectAdminSettingCache,
  updateAdminSettingCache,
} from 'features/global/visibilitySlice';
import { useDebounce } from 'hooks/useDebounce';
import { EMPLOYEE_CACHE } from 'helpers';
import { FormTextField } from 'shared/components/FormFields/TextField';
import { sanitizeEnotationInput } from 'hooks/validateAndFormateColor';

const ItemEmployeeFilter = ({
  setItemEmployeePayload,
  itemEmployeePayload,
}) => {
  //#region Constants
  const dispatch = useDispatch();
  const getCacheData = useSelector(selectAdminSettingCache);
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  const [initValues, setInitValues] = useState({
    first_name: '',
    last_name: '',
    dod_id: '',
    supervisor_employee_id: null,
    pay_plan_id: null,
    pay_grade_id: null,
    system_role_id: null,
    job_id: null,
    job_role_id: null,
    employee_status: null,
    cost_center_id: null,
    office_id: null,
  });
  //#endregion State

  //#region Selectors
  const user = useSelector(selectActiveUser);
  //#endregion Selectors

  //#region Refs
  const formRef = useRef();
  //#endregion Refs

  //#region Effects
  useEffect(() => {
    setInitValues({ ...initValues, ...getCacheData?.[EMPLOYEE_CACHE] });
  }, [getCacheData, itemEmployeePayload]);
  //#endregion Effects

  //#region Methods
  const updateFields = useCallback(
    useDebounce(
      (
        firstName,
        lastName,
        dodId,
        supervisorEmployeeId,
        payPlanId,
        payGradeId,
        systemRoleId,
        jobId,
        jobRoleId,
        employeeStatus,
        costCenterId,
        officeId
      ) => {
        setItemEmployeePayload({
          ...itemEmployeePayload,
          first_name: firstName,
          last_name: lastName,
          dod_id: dodId,
          supervisor_employee_id: supervisorEmployeeId,
          pay_plan_id: payPlanId,
          pay_grade_id: payGradeId,
          system_role_id: systemRoleId,
          job_id: jobId,
          job_role_id: jobRoleId,
          employee_status: employeeStatus,
          cost_center_id: costCenterId,
          office_id: officeId,
        });
      },
      1000
    ),
    [itemEmployeePayload]
  );

  const handleResetFilters = () => {
    const isFilterData = clearFilter(formRef);
    if (isFilterData) {
      formRef?.current?.resetForm();
      setItemEmployeePayload({
        ...itemEmployeePayload,
        first_name: '',
        last_name: '',
        dod_id: '',
        supervisor_employee_id: null,
        pay_plan_id: null,
        pay_grade_id: null,
        system_role_id: null,
        job_id: null,
        job_role_id: null,
        employee_status: null,
        cost_center_id: null,
        office_id: null,
      });
      dispatch(fetchOfficeByCostCenterId());
      dispatch(fetchJobRolesByJobId());
      dispatch(updateAdminSettingCache({ key: EMPLOYEE_CACHE, ...{} }));
    }
  };
  //#endregion Methods

  //#region Render time calcs
  //#endregion Render time calcs

  //#region Render

  return (
    <FilterAccordion
      filteredTitles={itemEmployeePayload}
      expandedId="capability-filter"
      styles={{ marginBottom: '1.5rem' }}
      onResetFilters={handleResetFilters}
    >
      <Formik
        enableReinitialize={true}
        initialValues={{ ...initValues }}
        innerRef={formRef}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Grid container spacing={'1rem'}>
              <Grid item xs={12} md={4} lg={3}>
                <FormTextField
                  name="first_name"
                  label="First"
                  placeholder="First"
                  fullWidth
                  onChange={(e) => {
                    setFieldValue('first_name', e.target.value);
                    updateFields(
                      e.target.value,
                      values?.last_name,
                      values?.dod_id,
                      values?.supervisor_employee_id,
                      values?.pay_plan_id,
                      values?.pay_grade_id,
                      values?.system_role_id,
                      values?.job_id,
                      values?.job_role_id,
                      values?.employee_status,
                      values?.cost_center_id,
                      values?.office_id
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <FormTextField
                  name="last_name"
                  label="Last"
                  placeholder="Last"
                  fullWidth
                  onChange={(e) => {
                    const searchTerm = e.target.value;
                    setFieldValue('last_name', searchTerm);
                    updateFields(
                      values?.first_name,
                      searchTerm,
                      values?.dod_id,
                      values?.supervisor_employee_id,
                      values?.pay_plan_id,
                      values?.pay_grade_id,
                      values?.system_role_id,
                      values?.job_id,
                      values?.job_role_id,
                      values?.employee_status,
                      values?.cost_center_id,
                      values?.office_id
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <FormTextField
                  name="dod_id"
                  label="DoD Id"
                  placeholder="DoD Id"
                  fullWidth
                  onChange={(e) => {
                    const searchTerm = e.target.value;
                    const filterValue = sanitizeEnotationInput(searchTerm);
                    setFieldValue('dod_id', filterValue);
                    updateFields(
                      values?.first_name,
                      values?.last_name,
                      filterValue,
                      values?.supervisor_employee_id,
                      values?.pay_plan_id,
                      values?.pay_grade_id,
                      values?.system_role_id,
                      values?.job_id,
                      values?.job_role_id,
                      values?.employee_status,
                      values?.cost_center_id,
                      values?.office_id
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <Field name="supervisor_employee_id">
                  {({ field: { value }, form: { setFieldValue } }) => (
                    <SelectionAutoComplete
                      title={`Select Supervisor`}
                      keyProperty="value"
                      nameProperty="text"
                      entityIds={value?.value}
                      setEntityIds={setFieldValue}
                      entitySelector={selectSuperVisorDropDown}
                      entityStatusSelector={getSuperVisorDropDownStatus}
                      onCustomChange={(value) => {
                        setFieldValue('supervisor_employee_id', value);
                        updateFields(
                          values?.first_name,
                          values?.last_name,
                          values?.dod_id,
                          value,
                          values?.pay_plan_id,
                          values?.pay_grade_id,
                          values?.system_role_id,
                          values?.job_id,
                          values?.job_role_id,
                          values?.employee_status,
                          values?.cost_center_id,
                          values?.office_id
                        );
                      }}
                      fetchEntityPage={fetchSuperVisorDropDown}
                      fetchQueryParams={{
                        include_self: true,
                      }}
                      cancelItemTagEdit={() => {
                        setFieldValue('supervisor_employee_id', null);
                        updateFields(
                          values?.first_name,
                          values?.last_name,
                          values?.dod_id,
                          null,
                          values?.pay_plan_id,
                          values?.pay_grade_id,
                          values?.system_role_id,
                          values?.job_id,
                          values?.job_role_id,
                          values?.employee_status,
                          values?.cost_center_id,
                          values?.office_id
                        );
                      }}
                      formField="supervisor_employee_id"
                      showCloseItemEdit={true}
                      multiSelection={false}
                      hideCheckIcon={true}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <Field name="pay_plan_id">
                  {({ field: { value }, form: { setFieldValue } }) => (
                    <SelectionAutoComplete
                      title={`Select Pay Plan`}
                      keyProperty="value"
                      nameProperty="text"
                      entityIds={value?.value}
                      setEntityIds={setFieldValue}
                      entitySelector={selectPayPlansDropdown}
                      entityStatusSelector={getPayPlansDropDownStatus}
                      onCustomChange={(value) => {
                        setFieldValue('pay_plan_id', value);
                        updateFields(
                          values?.first_name,
                          values?.last_name,
                          values?.dod_id,
                          values?.supervisor_employee_id,
                          value,
                          values?.pay_grade_id,
                          values?.system_role_id,
                          values?.job_id,
                          values?.job_role_id,
                          values?.employee_status,
                          values?.cost_center_id,
                          values?.office_id
                        );
                      }}
                      fetchEntityPage={fetchPayPlansDropDown}
                      cancelItemTagEdit={() => {
                        setFieldValue('pay_plan_id', null);
                        updateFields(
                          values?.first_name,
                          values?.last_name,
                          values?.dod_id,
                          values?.supervisor_employee_id,
                          null,
                          values?.pay_grade_id,
                          values?.system_role_id,
                          values?.job_id,
                          values?.job_role_id,
                          values?.employee_status,
                          values?.cost_center_id,
                          values?.office_id
                        );
                      }}
                      formField="pay_plan_id"
                      showCloseItemEdit={true}
                      multiSelection={false}
                      hideCheckIcon={true}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <Field name="pay_grade_id">
                  {({ field: { value }, form: { setFieldValue } }) => (
                    <SelectionAutoComplete
                      title={`Select Pay Grade`}
                      keyProperty="value"
                      nameProperty="text"
                      entityIds={value?.value}
                      setEntityIds={setFieldValue}
                      entitySelector={selectPayGradesDropdown}
                      entityStatusSelector={getPayGradesDropDownStatus}
                      onCustomChange={(value) => {
                        setFieldValue('pay_grade_id', value);
                        updateFields(
                          values?.first_name,
                          values?.last_name,
                          values?.dod_id,
                          values?.supervisor_employee_id,
                          values?.pay_plan_id,
                          value,
                          values?.system_role_id,
                          values?.job_id,
                          values?.job_role_id,
                          values?.employee_status,
                          values?.cost_center_id,
                          values?.office_id
                        );
                      }}
                      fetchEntityPage={fetchPayGradesDropDown}
                      cancelItemTagEdit={() => {
                        setFieldValue('pay_grade_id', null);
                        updateFields(
                          values?.first_name,
                          values?.last_name,
                          values?.dod_id,
                          values?.supervisor_employee_id,
                          values?.pay_plan_id,
                          null,
                          values?.system_role_id,
                          values?.job_id,
                          values?.job_role_id,
                          values?.employee_status,
                          values?.cost_center_id,
                          values?.office_id
                        );
                      }}
                      formField="pay_grade_id"
                      showCloseItemEdit={true}
                      multiSelection={false}
                      hideCheckIcon={true}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <Field name="system_role_id">
                  {({ field: { value }, form: { setFieldValue } }) => (
                    <SelectionAutoComplete
                      title={`Select System Role`}
                      keyProperty="value"
                      nameProperty="text"
                      entityIds={value?.value}
                      setEntityIds={setFieldValue}
                      entitySelector={selectSystemRolesDropdown}
                      entityStatusSelector={getSystemRolesDropDownStatus}
                      onCustomChange={(value) => {
                        setFieldValue('system_role_id', value);
                        updateFields(
                          values?.first_name,
                          values?.last_name,
                          values?.dod_id,
                          values?.supervisor_employee_id,
                          values?.pay_plan_id,
                          values?.pay_grade_id,
                          value,
                          values?.job_id,
                          values?.job_role_id,
                          values?.employee_status,
                          values?.cost_center_id,
                          values?.office_id
                        );
                      }}
                      fetchEntityPage={fetchSystemRolesDropDown}
                      cancelItemTagEdit={() => {
                        setFieldValue('system_role_id', null);
                        updateFields(
                          values?.first_name,
                          values?.last_name,
                          values?.dod_id,
                          values?.supervisor_employee_id,
                          values?.pay_plan_id,
                          values?.pay_grade_id,
                          null,
                          values?.job_id,
                          values?.job_role_id,
                          values?.employee_status,
                          values?.cost_center_id,
                          values?.office_id
                        );
                      }}
                      formField="system_role_id"
                      showCloseItemEdit={true}
                      multiSelection={false}
                      hideCheckIcon={true}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <Field name="job_id">
                  {({ field: { value }, form: { setFieldValue } }) => (
                    <SelectionAutoComplete
                      title={`Select ${user.label_job}`}
                      keyProperty="value"
                      nameProperty="text"
                      entityIds={value?.value}
                      setEntityIds={setFieldValue}
                      entitySelector={selectJobDropDown}
                      entityStatusSelector={getJobDropDownStatus}
                      onCustomChange={(value) => {
                        setFieldValue('job_id', value);
                        setFieldValue('job_role_id', null);
                        updateFields(
                          values?.first_name,
                          values?.last_name,
                          values?.dod_id,
                          values?.supervisor_employee_id,
                          values?.pay_plan_id,
                          values?.pay_grade_id,
                          values?.system_role_id,
                          value,
                          null,
                          values?.employee_status
                        );
                        dispatch(resetJobRoleDropdown());
                      }}
                      fetchEntityPage={fetchJobsDropDown}
                      cancelItemTagEdit={() => {
                        setFieldValue('job_id', null);
                        updateFields(
                          values?.first_name,
                          values?.last_name,
                          values?.dod_id,
                          values?.supervisor_employee_id,
                          values?.pay_plan_id,
                          values?.pay_grade_id,
                          values?.system_role_id,
                          null,
                          values?.job_role_id,
                          values?.employee_status
                        );
                        dispatch(resetJobRoleDropdown());
                      }}
                      showCloseItemEdit={true}
                      formField="job_id"
                      multiSelection={false}
                      hideCheckIcon={true}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <Field name="job_role_id">
                  {({ field: { value }, form: { setFieldValue } }) => (
                    <SelectionAutoComplete
                      title={`Select ${user.label_job_role}`}
                      keyProperty="value"
                      nameProperty="text"
                      entityIds={value?.value}
                      setEntityIds={setFieldValue}
                      entitySelector={selectJobRoleByJobIdDropDown}
                      entityStatusSelector={getJobRolesByJobIdDropDownStatus}
                      onCustomChange={(value) => {
                        setFieldValue('job_role_id', value);
                        updateFields(
                          values?.first_name,
                          values?.last_name,
                          values?.dod_id,
                          values?.supervisor_employee_id,
                          values?.pay_plan_id,
                          values?.pay_grade_id,
                          values?.system_role_id,
                          values?.job_id,
                          value,
                          values?.employee_status,
                          values?.cost_center_id,
                          values?.office_id
                        );
                      }}
                      fetchEntityPage={fetchJobRolesByJobId}
                      cancelItemTagEdit={() => {
                        setFieldValue('job_role_id', null);
                        updateFields(
                          values?.first_name,
                          values?.last_name,
                          values?.dod_id,
                          values?.supervisor_employee_id,
                          values?.pay_plan_id,
                          values?.pay_grade_id,
                          values?.system_role_id,
                          values?.job_id,
                          null,
                          values?.employee_status,
                          values?.cost_center_id,
                          values?.office_id
                        );
                      }}
                      fetchParams={{
                        job_id: values?.job_id?.value,
                      }}
                      formField="job_role_id"
                      showCloseItemEdit={true}
                      multiSelection={false}
                      hideCheckIcon={true}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <Field name="employee_status">
                  {({ field: { value }, form: { setFieldValue } }) => (
                    <SelectionAutoComplete
                      title="Select Employee Status"
                      keyProperty="value"
                      nameProperty="text"
                      entityIds={value?.value}
                      setEntityIds={setFieldValue}
                      entitySelector={selectEmployeeStatusDropDown}
                      entityStatusSelector={getEmployeeStatusDropDownStatus}
                      onCustomChange={(value) => {
                        setFieldValue('employee_status', value);
                        updateFields(
                          values?.first_name,
                          values?.last_name,
                          values?.dod_id,
                          values?.supervisor_employee_id,
                          values?.pay_plan_id,
                          values?.pay_grade_id,
                          values?.system_role_id,
                          values?.job_id,
                          values?.job_role_id,
                          value,
                          values?.cost_center_id,
                          values?.office_id
                        );
                      }}
                      fetchEntityPage={fetchEmployeeStatusDropDown}
                      cancelItemTagEdit={() => {
                        setFieldValue('employee_status', null);
                        updateFields(
                          values?.first_name,
                          values?.last_name,
                          values?.dod_id,
                          values?.supervisor_employee_id,
                          values?.pay_plan_id,
                          values?.pay_grade_id,
                          values?.system_role_id,
                          values?.job_id,
                          values?.job_role_id,
                          null,
                          values?.cost_center_id,
                          values?.office_id
                        );
                      }}
                      formField="employee_status"
                      showCloseItemEdit={true}
                      multiSelection={false}
                      hideCheckIcon={true}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <Field name="cost_center_id">
                  {({ field: { value }, form: { setFieldValue } }) => (
                    <SelectionAutoComplete
                      title={`Select Cost Center`}
                      keyProperty="value"
                      nameProperty="text"
                      entityIds={value?.value}
                      setEntityIds={setFieldValue}
                      entitySelector={selectCostCenterDropDown}
                      entityStatusSelector={getCostCenterDropDownStatus}
                      onCustomChange={(value) => {
                        setFieldValue('cost_center_id', value);
                        setFieldValue('office_id', null);
                        updateFields(
                          values?.first_name,
                          values?.last_name,
                          values?.dod_id,
                          values?.supervisor_employee_id,
                          values?.pay_plan_id,
                          values?.pay_grade_id,
                          values?.system_role_id,
                          values?.job_id,
                          values?.job_role_id,
                          values?.employee_status,
                          value,
                          null
                        );
                        dispatch(resetOfficeIdDropDown());
                      }}
                      fetchEntityPage={fetchCostCenterDropDown}
                      cancelItemTagEdit={() => {
                        setFieldValue('cost_center_id', null);
                        updateFields(
                          values?.first_name,
                          values?.last_name,
                          values?.dod_id,
                          values?.supervisor_employee_id,
                          values?.pay_plan_id,
                          values?.pay_grade_id,
                          values?.system_role_id,
                          values?.job_id,
                          values?.job_role_id,
                          values?.employee_status,
                          null,
                          values?.office_id
                        );
                        dispatch(resetOfficeIdDropDown());
                      }}
                      showCloseItemEdit={true}
                      formField="cost_center_id"
                      multiSelection={false}
                      hideCheckIcon={true}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <Field name="office_id">
                  {({ field: { value }, form: { setFieldValue } }) => (
                    <SelectionAutoComplete
                      title={`Select Office`}
                      keyProperty="value"
                      nameProperty="text"
                      entityIds={value?.value}
                      setEntityIds={setFieldValue}
                      entitySelector={selectOfficeByCostCenterIdDropDown}
                      entityStatusSelector={
                        getOfficeByCostCenterIdDropDownStatus
                      }
                      onCustomChange={(value) => {
                        setFieldValue('office_id', value);
                        updateFields(
                          values?.first_name,
                          values?.last_name,
                          values?.dod_id,
                          values?.supervisor_employee_id,
                          values?.pay_plan_id,
                          values?.pay_grade_id,
                          values?.system_role_id,
                          values?.job_id,
                          values?.job_role_id,
                          values?.employee_status,
                          values?.cost_center_id,
                          value
                        );
                      }}
                      fetchEntityPage={fetchOfficeByCostCenterId}
                      cancelItemTagEdit={() => {
                        setFieldValue('office_id', null);
                        updateFields(
                          values?.first_name,
                          values?.last_name,
                          values?.dod_id,
                          values?.supervisor_employee_id,
                          values?.pay_plan_id,
                          values?.pay_grade_id,
                          values?.system_role_id,
                          values?.job_id,
                          values?.job_role_id,
                          values?.employee_status,
                          values?.cost_center_id,
                          null
                        );
                      }}
                      fetchParams={{
                        cost_center_id: values?.cost_center_id?.value,
                      }}
                      formField="office_id"
                      showCloseItemEdit={true}
                      multiSelection={false}
                      hideCheckIcon={true}
                    />
                  )}
                </Field>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </FilterAccordion>
  );
  //#endregion Render
};
export { ItemEmployeeFilter };
