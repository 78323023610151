import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { groupTrainingService } from 'features/services/groupTraining.service';
import { LoadingStatus } from 'helpers';

const initialState = {
  status: LoadingStatus.Idle,
  error: null,
  groupTraining: [],
  getGroupTrainingSkills: [],
  groupTrainingStatus: LoadingStatus.Idle,
  groupTrainingSkillStatus: LoadingStatus.Idle,
  groupTrainingPageInfo: {},
  groupTrainingSkillPageInfo: {},
  displayGroupTraining: {},
  groupTrainingUsage: [],
  loadedGroupTrainingMap: {},
  updateSkillCap: [],
};

export const fetchGroupTraining = createAsyncThunk(
  'groupTraining/fetchGroupTraining',
  async (params = {}) =>
    await groupTrainingService.getGroupTraining(
      params.capability_id,
      params.capability_skill_ids,
      params.job_id,
      params.job_role_id,
      params.employee_name,
      params.employee_dod_id,
      params.series_id,
      params.job_role,
      params.supervisor_name,
      params.cost_center,
      params.directorate,
      params.include_employee_ids,
      params.sort,
      params.startIndex,
      params.stopIndex
    )
);

export const fetchGroupTrainingSkill = createAsyncThunk(
  'groupTraining/fetchGroupTrainingSkill',
  async (params = {}) =>
    await groupTrainingService.getGroupTrainingSkills(
      params.capability_id,
      params.completion_date
    )
);

export const updateGroupTraining = createAsyncThunk(
  'groupTraining/updateGroupTraining',
  async (params) => await groupTrainingService.update(params)
);

const groupTrainingSlice = createSlice({
  name: 'groupTraining',
  initialState,
  reducers: {
    resetGroupTrainingState: (state) => initialState,
  },
  extraReducers: {
    [fetchGroupTraining.pending]: (state, action) => {
      state.groupTrainingStatus = LoadingStatus.Loading;
    },
    [fetchGroupTraining.fulfilled]: (state, action) => {
      state.groupTrainingPageInfo = action.payload.pageInfo;
      state.groupTrainingStatus = LoadingStatus.Loaded;
      state.updateSkillCap = action.payload.groupTraining;

      if (action.meta.arg.reset) {
        state.loadedGroupTrainingMap = {};
      }

      const startIndex = action.meta.arg.startIndex;
      const stopIndex =
        startIndex + action.payload.groupTraining.employees.length - 1;

      for (var i = startIndex; i <= stopIndex; i++) {
        state.loadedGroupTrainingMap[i] = LoadingStatus.Loaded;
      }

      // In case we didn't load as many as requested
      for (
        let j = stopIndex + 1;
        j <= action.payload?.pageInfo?.TotalCount;
        j++
      ) {
        delete state.loadedGroupTrainingMap[j];
      }

      if (action.meta.arg.reset) {
        state.groupTraining = action.payload.groupTraining;
        state.groupTraining.employees = action.payload.groupTraining.employees;
      } else {
        state.groupTraining.employees = state.groupTraining.employees.concat(
          action.payload.groupTraining.employees
        );
      }

      state.groupTrainingStatus = LoadingStatus.Loaded;
    },
    [fetchGroupTraining.rejected]: (state, action) => {
      state.groupTrainingStatus = LoadingStatus.Failed;
      state.error = action.error.message;
    },
    [fetchGroupTrainingSkill.pending]: (state, action) => {
      state.groupTrainingSkillStatus = LoadingStatus.Loading;
    },
    [fetchGroupTrainingSkill.fulfilled]: (state, action) => {
      state.groupTrainingSkillPageInfo = action.payload.pageInfo;
      state.getGroupTrainingSkills = action.payload;
      state.groupTrainingSkillStatus = LoadingStatus.Loaded;
    },
    [fetchGroupTrainingSkill.rejected]: (state, action) => {
      state.groupTrainingSkillStatus = LoadingStatus.Failed;
    },
    [updateGroupTraining.fulfilled]: (state, action) => {
      state.groupTrainingSkillStatus = LoadingStatus.Idle;
    },
  },
});

export const selectGroupTraining = (state) => state.groupTraining.groupTraining;
export const selectGroupTrainingSkill = (state) =>
  state.groupTraining.getGroupTrainingSkills;

export const getGroupTrainingStatus = (state) =>
  state.groupTraining.groupTrainingStatus;
export const getGroupTrainingSkillStatus = (state) =>
  state.groupTraining.groupTrainingSkillStatus;

export const getGroupTrainingPageInfo = (state) =>
  state.groupTraining.groupTrainingPageInfo;
export const getGroupTrainingUsage = (state) =>
  state.groupTraining.groupTrainingUsage;

export const selectCapSkill = (state) => state.groupTraining.updateSkillCap;

export const { resetGroupTrainingState } = groupTrainingSlice.actions;

export default groupTrainingSlice.reducer;
