import { makeStyles } from '@mui/styles';

const makeAuditInfoStyles = makeStyles((theme) => ({
  cardHeader: {
    backgroundColor: theme.palette.common.grey,
    display: 'flex',
    alignItems: 'center',
    padding: '1rem',
  },
  cardIcon: {
    width: '1.5rem',
    height: '1.5rem',
    marginRight: '0.5rem',
    flex: '0 0 auto',
  },
  cardWrapper: {
    margin: '1rem 0',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: theme.palette.common.grey,
    '& .MuiCollapse-hidden': {
      visibility: 'visible',
    },
  },
}));

export { makeAuditInfoStyles };
