import { makeStyles } from '@mui/styles';

export const accordionStyle = makeStyles((theme) => ({
  accordionSummary: {
    '& > div.MuiAccordionSummary-content': {
      overflow: 'hidden',
      position: 'relative',
      paddingRight: '1.8rem',
    },
    '& .clearFilter': {
      padding: '0',
      marginLeft: '0.5rem',
      fontSize: '1.2rem',
      position: 'absolute',
      right: '0',
      background: '#ECF0F10',
      top: '50%',
      transform: 'translateY(-50%)',
      margin: '0 0.5rem',
      zIndex: '1',
    },
  },
  accordionDetails: {
    paddingTop: '0 !important',
  },
}));
