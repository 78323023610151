import { config } from 'ectl-config';
import { FormDataUtils, getNetworkService } from 'helpers';
import { createProgressParams } from 'helpers/progressUtils';
import { buildFilters } from 'hooks/buildFilter';

const accountService = { config: config };
const networkService = getNetworkService({
  config: config,
  checkAuth: accountService.checkAuth,
});
const baseUrl = `${config.apiUrl}/Admin/Capability`;
const adminUrl = `${config.apiUrl}/Admin`;

const getCapabilities = async (
  startIndex = 0,
  stopIndex = 10,
  searchString = '',
  sort = 'display_name',
  displayName = '',
  jobRoleId = null,
  jobId = null,
  jobSeriesId = null,
  skillIds = null
) => {
  const filters = [];
  if (searchString) {
    filters.push(`quick_search@=*${searchString}`);
  }
  if (displayName) {
    filters.push(`display_name@=*${displayName}`);
  }
  if (jobRoleId) {
    filters.push(`job_role_id==${jobRoleId}`);
  }
  if (jobId) {
    filters.push(`job_id==${jobId}`);
  }
  if (jobSeriesId !== null && jobSeriesId !== undefined && jobSeriesId !== '') {
    filters.push(`job_series_id==${jobSeriesId}`);
  }
  if (skillIds?.length > 0) {
    filters.push(`skill_ids==${skillIds.join('|')}`);
  }

  const params = {
    filters: filters.join(','),
    sorts: sort,
    start_index: startIndex,
    stop_index: stopIndex,
  };
  const query = new URLSearchParams(params);
  const response = await networkService.get(`${baseUrl}?${query}`);
  const pageInfo = response.headers['x-pagination'];

  return {
    pageInfo: pageInfo,
    capabilities: response?.data,
  };
};

const create = async (params) => {
  const response = await networkService.post(baseUrl, params);

  if (response.error) {
    return Promise.reject(response.error);
  }

  return response;
};

const update = async (params) => {
  const response = await networkService.put(
    `${baseUrl}/${params.capability_id}`,
    params
  );

  if (response.error) {
    return Promise.reject(response.error);
  }

  return response.data;
};

const _delete = async (capability_id) => {
  try {
    const response = await networkService.delete(`${baseUrl}/${capability_id}`);
    return response;
  } catch (e) {
    return Promise.reject(e.error);
  }
};

const getById = async (id) => {
  const response = await networkService.get(`${baseUrl}/${id}`);
  return response.data;
};

const deleteCapabilitiesArray = async (ids) => {
  try {
    const deleteObject = {
      ids: ids,
    };
    const response = await networkService.deleteWithBody(baseUrl, deleteObject);
    return response.data;
  } catch (e) {
    return Promise.reject(e.error);
  }
};

const getCapabilityUsageById = async (tagId) => {
  const response = await networkService.get(`${baseUrl}/${tagId}`);
  return response.data;
};

const getCapabilityImportCsv = async () => {
  try {
    const response = await networkService.get(`${baseUrl}/import`);
    return response?.data;
  } catch (err) {
    return Promise.reject(err.error);
  }
};

const uploadCapabilityImportCsv = async (params) => {
  try {
    let formData = new FormData();
    FormDataUtils.safeAppend(formData, [['file', params.file]]);
    let progressParams = createProgressParams(params);
    const response = await networkService.postMultiFormData(
      `${baseUrl}/import`,
      formData,
      progressParams
    );
    return response.data;
  } catch (err) {
    return err;
  }
};

const exportCapabilityCsv = async (
  displayName = '',
  jobRoleId = null,
  jobId = null,
  jobSeriesId = null,
  skillIds = null
) => {
  try {
    const filters = buildFilters(
      ['display_name', displayName],
      ['job_role_id', jobRoleId],
      ['job_id', jobId],
      ['job_series_id', jobSeriesId],
      ['skill_ids', skillIds]
    );
    const params = {
      filters: filters,
    };
    const query = new URLSearchParams(params).toString();
    const response = await networkService.get(`${baseUrl}/export?${query}`);
    return response?.data;
  } catch (err) {
    return Promise.reject(err.error);
  }
};

const getCapabilitySkillImportCsv = async () => {
  try {
    const response = await networkService.get(
      `${adminUrl}/CapabilityJoin/import`
    );
    return response?.data;
  } catch (err) {
    return Promise.reject(err.error);
  }
};

const capabilitySkillImport = async (params) => {
  try {
    let formData = new FormData();
    FormDataUtils.safeAppend(formData, [['file', params.file]]);
    let progressParams = createProgressParams(params);
    const response = await networkService.postMultiFormData(
      `${adminUrl}/CapabilityJoin/import`,
      formData,
      progressParams
    );
    return response.data;
  } catch (err) {
    return err;
  }
};

const getCapabilityImportHelp = async (capability_id = null) => {
  try {
    const filters = [];
    if (capability_id) {
      filters.push(`capability_id==${capability_id}`);
    }
    const params = {
      filters: filters.join(','),
    };
    const query = new URLSearchParams(params);
    if (capability_id) {
      const response = await networkService.get(`${baseUrl}/help/?${query}`);
      return response?.data;
    }
    const response = await networkService.get(`${baseUrl}/help`);
    return response?.data;
  } catch (err) {
    return Promise.reject(err.error);
  }
};

const getAssociateCapability = async (capability_id = null) => {
  try {
    if (capability_id) {
      const response = await networkService.get(
        `${baseUrl}/skill/${capability_id}`
      );
      return response?.data;
    }
  } catch (err) {
    return Promise.reject(err.error);
  }
};

const updateCapabilitySkill = async (params) => {
  try {
    const response = await networkService.put(
      `${baseUrl}/skill/${params.capability_id}`,
      params?.capabilityData
    );

    return response.data;
  } catch (err) {
    return Promise.reject(err.error);
  }
};

const getAssociateJobRole = async (capability_id = null) => {
  try {
    if (capability_id) {
      const response = await networkService.get(
        `${baseUrl}/jobrole/${capability_id}`
      );
      return response?.data;
    }
  } catch (err) {
    return Promise.reject(err.error);
  }
};

const updateCapabilityJobRole = async (params) => {
  try {
    const response = await networkService.put(
      `${baseUrl}/jobrole/${params.capability_id}`,
      params?.jobRoleData
    );

    return response.data;
  } catch (err) {
    return Promise.reject(err.error);
  }
};

export const capabilityService = {
  getCapabilities,
  create,
  update,
  delete: _delete,
  deleteCapabilitiesArray,
  getById,
  getCapabilityUsageById,
  getCapabilityImportCsv,
  uploadCapabilityImportCsv,
  exportCapabilityCsv,
  getCapabilitySkillImportCsv,
  capabilitySkillImport,
  getCapabilityImportHelp,
  getAssociateCapability,
  updateCapabilitySkill,
  getAssociateJobRole,
  updateCapabilityJobRole,
};
