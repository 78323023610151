import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    position: 'relative',
  },
  tableWrapper: {
    border: '0',
    '& td, & th': {
      borderTop: '0',
      borderBottom: '0',
    },
  },
  borderX: {
    borderLeft: '1px solid #dddddd',
    borderRight: '1px solid #dddddd',
  },
  tableCaption: {
    textAlign: 'center',
    padding: '0.5rem 0',
  },
  verticallyCenter: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    padding: '0.5rem 1rem',
  },
  skillTableWrapper: {
    margin: '1rem 0',
    border: '2px solid #dddddd',
  },
  skillHeader: {
    backgroundColor: '#dddddd',
    padding: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tableMainContainer: {
    boxShadow: 'inherit !important',
    maxHeight: '42vh',
    overflow: 'auto',
  },
  inputErr: {
    color: '#E84C3C',
    margin: '4px 0',
    fontSize: '0.8rem',
    textAlign: 'left',
    fontWeight: 400,
    lineHeight: 1.66,
  },
}));
