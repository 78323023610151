import { config } from 'ectl-config';
import { getNetworkService } from 'helpers';

const accountService = { config: config };
const networkService = getNetworkService({
  config: config,
  checkAuth: accountService.checkAuth,
});
const baseUrl = `${config.apiUrl}/Admin/ApiClient`;

const getApiClient = async (
  startIndex = 0,
  stopIndex = 10,
  searchString = '',
  sort = 'display_name'
) => {
  const filters = [];
  if (searchString) {
    filters.push(`quick_search@=*${searchString}`);
  }

  const params = {
    filters: filters.join(','),
    sorts: sort || '',
    start_index: startIndex,
    stop_index: stopIndex,
  };
  const query = new URLSearchParams(params);
  const response = await networkService.get(`${baseUrl}?${query}`);
  const pageInfo = response.headers['x-pagination'];

  return {
    pageInfo: pageInfo,
    apiClients: response?.data,
  };
};

const create = async (params) => {
  const response = await networkService.post(baseUrl, params);

  if (response.error) {
    return Promise.reject(response.error);
  }

  return response;
};

const update = async (params) => {
  const response = await networkService.put(
    `${baseUrl}/${params.api_client_id}`,
    params
  );

  if (response.error) {
    return Promise.reject(response.error);
  }

  return response.data;
};

const _delete = async (api_client_id) => {
  try {
    const response = await networkService.delete(`${baseUrl}/${api_client_id}`);
    return response;
  } catch (e) {
    return Promise.reject(e.error);
  }
};

const getById = async (id) => {
  const response = await networkService.get(`${baseUrl}/${id}`);
  return response.data;
};

const deleteArray = async (ids) => {
  try {
    const deleteObject = {
      ids: ids,
    };
    const response = await networkService.deleteWithBody(baseUrl, deleteObject);
    return response.data;
  } catch (e) {
    return Promise.reject(e.error);
  }
};

const getApiClientsUsageById = async (tagId) => {
  const response = await networkService.get(`${baseUrl}/${tagId}`);
  return response.data;
};

export const apiClientService = {
  getApiClient,
  create,
  update,
  delete: _delete,
  deleteArray,
  getById,
  getApiClientsUsageById,
};
