import { useDispatch, useSelector } from 'react-redux';
import {
  Autocomplete,
  Checkbox,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import pluralize from 'pluralize';
import { MdAccountTree, MdAdd, MdDelete, MdInfo } from 'react-icons/md';

import { LoadingStatus } from 'helpers';
import {
  fetchSkillExpiryDropDown,
  fetchSkillsDropDown,
  getSkillExpiryDropDownStatus,
  getSkillsDropDownStatus,
  selectSkillExpiryDropDown,
  selectSkillsDropDown,
} from 'features/AdminSettingsDropDown/dropDownSlice';
import { useStyles } from './styles';
import { Each } from 'helpers/Each';
import { openPanel, setEditObjectId } from 'features/common/formSlice';
import { FormActions } from 'helpers/form-action';

const ItemCapabilitySkillTable = ({
  currentLevelId,
  setFormData,
  formData,
  levelObject,
  user,
  readOnly,
  itemTagId,
  formAction,
}) => {
  //#region Constants
  const dispatch = useDispatch();
  const classes = useStyles();
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  const [isSkillDropDown, setIsSkillDropDown] = useState(false);
  // const [rows, setRows] = useState(formData?.skills?.[getLevelId] || []);

  //#endregion State

  //#region Selectors
  const skillExpiryStatus = useSelector(getSkillExpiryDropDownStatus);
  const skillExpiryDropDown = useSelector(selectSkillExpiryDropDown);
  const skillDropDownStatus = useSelector(getSkillsDropDownStatus);
  const skillDropDown = useSelector(selectSkillsDropDown);
  //#endregion Selectors

  //#region Refs
  //#endregion Refs

  //#region Effects
  useEffect(() => {
    if (skillExpiryStatus === LoadingStatus.Idle) {
      dispatch(fetchSkillExpiryDropDown());
    }
    if (skillDropDownStatus === LoadingStatus.Idle) {
      dispatch(fetchSkillsDropDown());
    }
  }, [skillExpiryStatus, skillDropDownStatus]);

  let getFormData = { ...formData };

  const handleSkillChecked = (idx, key) => {
    getFormData.skills[currentLevelId][idx][key] =
      !getFormData.skills[currentLevelId][idx]?.[key];
    setFormData(getFormData);
  };

  const handleDeleteRow = (idx) => {
    let skillsData = [...getFormData.skills[currentLevelId]];
    skillsData.splice(idx, 1);
    getFormData.skills[currentLevelId] = skillsData;
    setFormData(getFormData);
  };

  const handleIsSkillDropDown = (event) => {
    event.preventDefault();
    setIsSkillDropDown(true);
  };

  const handleQuickAddSkill = (e) => {
    e.preventDefault();
    dispatch(
      setEditObjectId({
        formKey: `itemAssociateCapabilityForm`,
        object_id: itemTagId,
      })
    );
    dispatch(
      openPanel({
        formKey: 'itemAssociateCapabilityForm',
        formAction: 'Edit',
      })
    );
  };

  const handleSkillDropDown = (val) => {
    const isExist = formData?.skills[currentLevelId]?.find(
      (item) => item.skill_id === val.value
    );
    if (!isExist) {
      setFormData((prevValue) => ({
        ...prevValue,
        skills: {
          ...prevValue.skills,
          [currentLevelId]: prevValue.skills[currentLevelId]
            ? [
                ...prevValue.skills[currentLevelId],
                {
                  manager_can_certify: null,
                  skill_display_name: val.text,
                  skill_id: val.value,
                  skill_description: val.description,
                  years_to_expiry: null,
                },
              ]
            : [
                {
                  manager_can_certify: null,
                  skill_display_name: val.text,
                  skill_id: val.value,
                  skill_description: val.description,
                  years_to_expiry: null,
                },
              ],
        },
      }));
    }
    setIsSkillDropDown(false);
  };

  const handleSkillExpiry = (value, idx) => {
    getFormData.skills[currentLevelId][idx].years_to_expiry = parseInt(
      value.value
    );
    setFormData(getFormData);
  };
  //#endregion Methods

  //#region Render time calcs
  //#endregion Render time calcs

  //#region Render

  return (
    <>
      <Typography variant="body2" className={classes.tableCaption}>
        Check the {user?.label_skill} that are associated with your depots{' '}
        {user?.label_capability}
      </Typography>
      <Box className={classes.skillTableWrapper}>
        <Box className={classes.skillHeader}>
          <Typography>
            {levelObject?.level_display_name} -{' '}
            {pluralize(
              user?.label_skill,
              formData.skills[currentLevelId]?.length
            )}{' '}
            List
          </Typography>
          <Box className={classes.verticallyCenter}>
            {isSkillDropDown && skillDropDown.length > 0 && (
              <Autocomplete
                sx={{
                  minWidth: '340px',
                  maxWidth: '100%',
                }}
                limitTags={2}
                disableCloseOnSelect
                variant="outline"
                disabled={!currentLevelId}
                id="level-id"
                options={[...skillDropDown]}
                onChange={(event, newInputValue) => {
                  handleSkillDropDown(newInputValue);
                }}
                value={null}
                getOptionLabel={(option) => String(option.text)}
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={props.id}>
                    {option.text}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    label={user?.label_skill}
                    placeholder={user?.label_skill}
                  />
                )}
              />
            )}
            {!readOnly && !isSkillDropDown && (
              <IconButton
                onClick={(event) => handleIsSkillDropDown(event)}
                disableRipple
                sx={{
                  marginLeft: 'auto',
                  paddingLeft: '1rem',
                }}
                size="small"
              >
                <MdAdd /> Add {user?.label_skill}
              </IconButton>
            )}
            {!readOnly && formAction.id === FormActions.Edit.id && (
              <IconButton
                onClick={(event) => handleQuickAddSkill(event)}
                disableRipple
                sx={{
                  marginLeft: 'auto',
                  paddingLeft: '1rem',
                }}
                size="small"
              >
                <MdAccountTree />
              </IconButton>
            )}
          </Box>
        </Box>
        <TableContainer
          component={Paper}
          className={classes.tableMainContainer}
        >
          <Table
            sx={{ minWidth: 450 }}
            aria-label="simple table"
            className={classes.tableWrapper}
          >
            <TableHead>
              <TableRow>
                <TableCell colSpan={!readOnly ? '3' : '2'}>
                  <Typography variant="body1" fontWeight="bold">
                    Group Training
                  </Typography>
                </TableCell>
                <TableCell align="center" width="400">
                  <Typography variant="body1" fontWeight="bold">
                    {user?.label_skill}
                  </Typography>
                </TableCell>
                <TableCell align="center" width="250">
                  <Typography variant="body1" fontWeight="bold">
                    Expires
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <Each
                of={formData.skills[currentLevelId]}
                render={(row, index) => (
                  <TableRow
                    sx={{
                      '&:last-child td, &:last-child th': {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell>
                      <Checkbox
                        color="primary"
                        checked={
                          formData?.skills[currentLevelId][index]
                            ?.manager_can_certify
                        }
                        onChange={() =>
                          handleSkillChecked(index, 'manager_can_certify')
                        }
                        inputProps={row.skill_id}
                        disabled={readOnly}
                      />
                    </TableCell>
                    <TableCell>
                      <Tooltip title={row?.skill_description}>
                        <IconButton>
                          <MdInfo />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    {!readOnly && (
                      <TableCell>
                        <IconButton onClick={() => handleDeleteRow(index)}>
                          <MdDelete />
                        </IconButton>
                      </TableCell>
                    )}
                    <TableCell
                      width="180"
                      align="center"
                      className={classes.borderX}
                    >
                      {row.skill_display_name}
                    </TableCell>
                    <TableCell width="180">
                      {skillExpiryDropDown?.length > 0 && (
                        <Autocomplete
                          readOnly={readOnly}
                          sx={{
                            minWidth: '180px',
                            maxWidth: '100%',
                          }}
                          limitTags={2}
                          variant="outline"
                          id="level-id"
                          options={[...skillExpiryDropDown]}
                          onChange={(event, newInputValue) => {
                            handleSkillExpiry(newInputValue, index);
                          }}
                          value={skillExpiryDropDown.find((option) =>
                            option.value === null
                              ? option.value === row?.years_to_expiry
                              : parseInt(option.value) === row?.years_to_expiry
                          )}
                          getOptionLabel={(option) => String(option.text)}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>{option.text}</li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              size="small"
                              label="Year of Expiry"
                              placeholder="Year of Expiry"
                            />
                          )}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                )}
              />
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
  //#endregion Render
};
export { ItemCapabilitySkillTable };
