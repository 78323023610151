import { setProgress } from 'features/global/loadingProgressSlice';

const createProgressParams = (params) => {
  var onUploadProgress = params.onUploadProgress;
  var onProcessProgress = params.onProcessProgress;

  if (params.dispatch) {
    params.dispatch(
      setProgress({
        progress: 0,
        label: 'Step 1 of 2: Uploading',
        noAnimation: true,
      })
    );

    if (!onUploadProgress) {
      onUploadProgress = (progressEvent) => {
        var percentDone = (progressEvent.loaded / progressEvent.total) * 100;
        params.dispatch(
          setProgress({
            progress: percentDone,
            label: 'Step 1 of 2: Uploading',
            noAnimation: percentDone === 0,
          })
        );
      };
    }
    if (!onProcessProgress) {
      onProcessProgress = (processPercentDone) => {
        params.dispatch(
          setProgress({
            progress: processPercentDone,
            label: 'Step 2 of 2: Processing',
            noAnimation: processPercentDone === 0,
          })
        );
      };
    }
  }

  return {
    onUploadProgress: onUploadProgress
      ? (progressEvent) => onUploadProgress(progressEvent)
      : null,
    onProcessProgress: onProcessProgress
      ? (processPercentDone) => onProcessProgress(processPercentDone)
      : null,
  };
};

const createFullProgressParams = (params) => {
  var onUploadProgress = params.onUploadProgress;
  var onProcessFullProgress = params.onProcessFullProgress;

  params.dispatch(
    setProgress({
      progress: 0,
      label: 'Step 1 of 2: Uploading',
      noAnimation: true,
    })
  );

  if (!onUploadProgress) {
    onUploadProgress = (progressEvent) => {
      var percentDone = (progressEvent.loaded / progressEvent.total) * 100;
      params.dispatch(
        setProgress({
          progress: percentDone,
          label: 'Step 1 of 2: Uploading',
          noAnimation: percentDone === 0,
        })
      );
    };
  }
  if (!onProcessFullProgress) {
    onProcessFullProgress = (progress) => {
      var fileProgress = 0;
      if (progress.total_files) {
        fileProgress =
          (progress.files_transferred / progress.total_files) * 100;
      } else {
        fileProgress = progress;
      }
      params.dispatch(
        setProgress({
          progress: fileProgress,
          label: 'Step 2 of 2: Processing',
          noAnimation: fileProgress === 0,
        })
      );
    };
  }

  return {
    onUploadProgress: onUploadProgress
      ? (progressEvent) => onUploadProgress(progressEvent)
      : null,
    onProcessFullProgress: onProcessFullProgress
      ? (progress) => onProcessFullProgress(progress)
      : null,
  };
};

export { createProgressParams, createFullProgressParams };
