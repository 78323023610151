import React from 'react';
import { Field, useFormikContext } from 'formik';
import { SelectionAutoComplete } from 'shared/components/_Autocomplete/SelectionAutocomplete';
import FieldValidationError from '../FieldValidationError';

const FormFieldWithAutoComplete = ({
  title,
  entityIds,
  setEntityIds,
  entitySelector,
  entityStatusSelector,
  onCustomChange,
  fetchEntityPage,
  formField,
  cancelItemTagEdit,
  props,
  isProgramManager,
}) => {
  const { errors } = useFormikContext();
  return (
    <>
      <Field name={formField}>
        {({ field: { value }, form: { setFieldValue } }) => (
          <SelectionAutoComplete
            title={title}
            keyProperty="value"
            nameProperty="text"
            limitTags={5}
            entityIds={entityIds}
            setEntityIds={setEntityIds}
            entitySelector={entitySelector}
            entityStatusSelector={entityStatusSelector}
            onCustomChange={onCustomChange}
            fetchEntityPage={fetchEntityPage}
            formField={formField}
            multiSelection="true"
            showCloseItemEdit="true"
            hideCheckIcon="true"
            cancelItemTagEdit={() => {
              setFieldValue(formField, null);
              if (cancelItemTagEdit) {
                cancelItemTagEdit();
              }
            }}
            readOnly={isProgramManager}
            {...props}
          />
        )}
      </Field>
      {errors?.[formField] && (
        <FieldValidationError validationError={errors?.[formField]} />
      )}
    </>
  );
};

export { FormFieldWithAutoComplete };
