import { IconButton, TableCell, TableRow } from '@mui/material';
import { TextField } from 'formik-mui';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { MdDelete, MdUndo } from 'react-icons/md';

import FieldValidationError from 'shared/components/FormFields/FieldValidationError';

const SkillTableBody = ({
  index,
  setFieldValue,
  isReadOnly,
  initValues,
  classes,
  values,
  errors,
  rowClass,
  checkValidate,
}) => {
  //#region Constants
  const isDeletable = !!errors?.[`[${index}].expiry_date`];
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  //#endregion State

  //#region Selectors
  //#endregion Selectors

  //#region Refs
  //#endregion Refs

  //#region Effects
  //#endregion Effects
  //#region Methods
  const handleDeleteClick = async () => {
    const errors = await checkValidate();
    const updatedValue = { ...values[index] };
    updatedValue.delete = !errors?.[`[${index}].expiry_date`];
    setFieldValue(`[${index}]`, updatedValue);
  };

  const handleUndoClick = () => {
    const updatedValue = { ...values[index] };
    updatedValue.delete = false;
    setFieldValue(`[${index}]`, updatedValue);
  };
  //#endregion Methods

  //#region Render time calcs
  //#endregion Render time calcs

  //#region Render
  return (
    <TableRow>
      <TableCell
        className={
          !isDeletable && values[index]?.delete
            ? rowClass?.setOpacity
            : rowClass?.revertOpacity
        }
        sx={{ position: 'relative' }}
      >
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            value={moment(values[index]?.completion_date) || 'Never'}
            onChange={(date) => {
              let updatedValue = { ...values[index] };
              updatedValue = {
                ...updatedValue,
                completion_date: date ? date.format('YYYY-MM-DD') : null,
              };
              setFieldValue(`[${index}]`, updatedValue);
            }}
            slotProps={{
              actionBar: {
                actions: ['clear', 'today'],
              },
            }}
            disabled={isReadOnly || (!isDeletable && values[index]?.delete)}
            label="Completion Date"
            format="YYYY-MM-DD"
            renderInput={(props) => (
              <TextField
                {...props}
                margin="dense"
                variant="outlined"
                label="Completion Date"
                placeholder={
                  values[index]?.completion_date ? 'Never' : 'YYYY-MM-DD'
                }
                className={classes.materialInput}
                type="text"
                fullWidth
              />
            )}
          />
        </LocalizationProvider>
        {errors?.[`[${index}].completion_date`] && (
          <FieldValidationError
            validationError={errors?.[`[${index}].completion_date`]}
          />
        )}
      </TableCell>
      <TableCell
        className={
          !isDeletable && values[index]?.delete
            ? rowClass?.setOpacity
            : rowClass?.revertOpacity
        }
        sx={{ position: 'relative' }}
      >
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            value={
              values[index]?.expiry_date
                ? moment(values[index]?.expiry_date)
                : null
            }
            onChange={(date) => {
              let updatedValue = { ...values[index] };
              updatedValue = {
                ...updatedValue,
                expiry_date: date ? date.format('YYYY-MM-DD') : null,
              };
              setFieldValue(`[${index}]`, updatedValue);
            }}
            slotProps={{
              actionBar: {
                actions: ['clear', 'today'],
              },
            }}
            label="Expiry Date"
            format="YYYY-MM-DD"
            disabled={isReadOnly || (!isDeletable && values[index]?.delete)}
            renderInput={(props) => (
              <TextField
                {...props}
                margin="dense"
                variant="outlined"
                label="Expiry Date"
                placeholder={values[index]?.expiry_date ? '' : 'YYYY-MM-DD'}
                className={classes.materialInput}
                type="text"
                fullWidth
              />
            )}
          />
        </LocalizationProvider>
        {errors?.[`[${index}].expiry_date`] && (
          <FieldValidationError
            validationError={errors?.[`[${index}].expiry_date`]}
          />
        )}
      </TableCell>
      {!isReadOnly && (
        <TableCell sx={{ position: 'relative' }}>
          {values[index]?.delete ? (
            <IconButton onClick={handleUndoClick}>
              <MdUndo />
            </IconButton>
          ) : (
            <IconButton onClick={handleDeleteClick}>
              <MdDelete />
            </IconButton>
          )}
        </TableCell>
      )}
    </TableRow>
  );
  //#endregion Render
};
export { SkillTableBody };
