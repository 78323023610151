import { makeStyles } from '@mui/styles';

const teamTableStyle = makeStyles((theme) => ({
  form: {
    display: 'flex',
    padding: '0',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    minWidth: '140px',
  },
  selectLabel: {
    paddingRight: '10px',
    fontSize: '0.8rem',
  },
  mainLabel: {
    wordBreak: 'keep-all',
    whiteSpace: 'nowrap',
    fontSize: '0.8rem',
  },
  selectField: {
    minWidth: '120px',
  },
  mainTable: {
    boxShadow: 'none',
    borderRadius: '0',
    '& tr > td': {
      borderLeft: '1px solid',
      borderRight: '1px solid',
      borderColor: theme.palette.common.tableBorder,
      padding: '8px 10px',
    },
    '& tr > th': {
      border: '1px solid',
      borderColor: theme.palette.common.tableBorder,
    },
  },
  verticallyCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  levelList: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    overflow: 'auto',
    paddingBottom: '1rem',
    background: '#F7FBFB',
    padding: '1rem',
    marginBottom: '1rem',
  },
  stickyHeader: {
    position: 'sticky',
    top: '0',
    zIndex: '2',
  },
}));

export { teamTableStyle };
