import { config } from 'ectl-config';
import { FormDataUtils, getNetworkService } from 'helpers';
import { createProgressParams } from 'helpers/progressUtils';
import { buildFilters } from 'hooks/buildFilter';

const accountService = { config: config };
const networkService = getNetworkService({
  config: config,
  checkAuth: accountService.checkAuth,
});
const baseUrl = `${config.apiUrl}/Admin/Office`;

const getOffices = async (
  startIndex = 0,
  stopIndex = 10,
  searchString = '',
  sort = 'directorate',
  cost_center_id = '',
  directorate = '',
  symbol = ''
) => {
  const filters = buildFilters(
    ['quick_search', searchString],
    ['cost_center_id', cost_center_id],
    ['directorate', directorate],
    ['symbol', symbol]
  );
  const params = {
    filters: filters,
    sorts: sort || '',
    start_index: startIndex,
    stop_index: stopIndex,
  };
  const query = new URLSearchParams(params);
  const response = await networkService.get(`${baseUrl}/?${query}`);
  const pageInfo = response.headers['x-pagination'];

  return {
    pageInfo: pageInfo,
    offices: response?.data,
  };
};

const create = async (params) => {
  const response = await networkService.post(baseUrl, params);

  if (response.error) {
    return Promise.reject(response.error);
  }

  return response;
};

const update = async (params) => {
  const response = await networkService.put(
    `${baseUrl}/${params.office_id}`,
    params
  );

  if (response.error) {
    return Promise.reject(response.error);
  }

  return response.data;
};

const _delete = async (office_id) => {
  try {
    const response = await networkService.delete(`${baseUrl}/${office_id}`);
    return response;
  } catch (e) {
    return Promise.reject(e.error);
  }
};

const getById = async (id) => {
  const response = await networkService.get(`${baseUrl}/${id}`);
  return response.data;
};

const deleteArray = async (ids) => {
  try {
    const deleteObject = {
      ids: ids,
    };
    const response = await networkService.deleteWithBody(baseUrl, deleteObject);
    return response.data;
  } catch (e) {
    return Promise.reject(e.error);
  }
};

const getOfficeUsageById = async (tagId) => {
  const response = await networkService.get(`${baseUrl}/${tagId}`);
  return response.data;
};

const getOfficeImportCsv = async () => {
  try {
    const response = await networkService.get(`${baseUrl}/import`);
    return response?.data;
  } catch (err) {
    return Promise.reject(err.error);
  }
};

const uploadOfficeImportCsv = async (params) => {
  try {
    let formData = new FormData();
    FormDataUtils.safeAppend(formData, [['file', params.file]]);
    let progressParams = createProgressParams(params);
    const response = await networkService.postMultiFormData(
      `${baseUrl}/import`,
      formData,
      progressParams
    );
    return response.data;
  } catch (err) {
    return err;
  }
};

const exportOfficeCsv = async (
  cost_center_id = '',
  directorate = '',
  symbol = ''
) => {
  try {
    const filters = buildFilters(
      ['cost_center_id', cost_center_id],
      ['directorate', directorate],
      ['symbol', symbol]
    );
    const params = {
      filters: filters,
    };
    const query = new URLSearchParams(params).toString();
    const response = await networkService.get(`${baseUrl}/export?${query}`);
    return response?.data;
  } catch (err) {
    return Promise.reject(err.error);
  }
};

const getOfficeImportHelp = async (office_id = null) => {
  try {
    const filters = [];
    if (office_id) {
      filters.push(`office_id==${office_id}`);
    }
    const params = {
      filters: filters.join(','),
    };
    const query = new URLSearchParams(params);
    if (office_id) {
      const response = await networkService.get(`${baseUrl}/help/?${query}`);
      return response?.data;
    }
    const response = await networkService.get(`${baseUrl}/help`);
    return response?.data;
  } catch (err) {
    return Promise.reject(err.error);
  }
};

export const officeService = {
  getOffices,
  create,
  update,
  delete: _delete,
  deleteArray,
  getById,
  getOfficeUsageById,
  getOfficeImportCsv,
  uploadOfficeImportCsv,
  exportOfficeCsv,
  getOfficeImportHelp,
};
