import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { payPlanService } from 'features/services/payPlan.service';
import { LoadingStatus, SavingStatus } from 'helpers';

const initialState = {
  status: LoadingStatus.Idle,
  error: null,
  payPlans: [],
  payPlanStatus: LoadingStatus.Idle,
  payPlanPageInfo: {},
  displayPayPlan: {},
  payPlanUsage: [],
  loadedPayPlanMap: {},
  payPlanInfo: [],
  payPlanInfoStatus: LoadingStatus.Idle,
};

export const fetchPayPlans = createAsyncThunk(
  'payPlans/fetchPayPlans',
  async (params) =>
    await payPlanService.getPayPlans(
      params.startIndex,
      params.stopIndex,
      params.searchString,
      params.sort,
      params.display_name
    )
);

export const fetchPayPlanById = createAsyncThunk(
  'payPlans/fetchPayPlanById',
  async (payPlanId) => {
    const response = await payPlanService.getById(payPlanId);
    response.id = payPlanId;
    return response;
  }
);

export const fetchPayPlanUsageById = createAsyncThunk(
  'groups/fetchPayPlansUsageById',
  async (payPlanId) => await payPlanService.getPayPlanUsageById(payPlanId)
);

export const createPayPlan = createAsyncThunk(
  'payPlans/createPayPlan',
  async (params) => await payPlanService.create(params)
);

export const updatePayPlan = createAsyncThunk(
  'payPlans/updatePayPlan',
  async (params) => await payPlanService.update(params)
);

export const deletePayPlan = createAsyncThunk(
  'payPlans/deletePayPlan',
  async (pay_plan_id) => {
    const response = await payPlanService.delete(pay_plan_id);
    return response;
  }
);

export const deletePayPlanArray = createAsyncThunk(
  'assets/deletePayPlanArray',
  async (payPlanId) => {
    const response = await payPlanService.deleteArray(payPlanId);
    return response;
  }
);

export const getPayPlanImportSampleCsv = createAsyncThunk(
  'payPlan/getPayPlanImportSampleCsv',
  async () => await payPlanService.getPayPlanImportCsv()
);

export const uploadPayPlanImportCsv = createAsyncThunk(
  'payPlan/uploadPayPlanImportCsv',
  async (params) => await payPlanService.uploadPayPlanImportCsv(params)
);

export const exportPayPlanCsv = createAsyncThunk(
  'payPlan/exportPayPlanCsv',
  async (params) => await payPlanService.exportPayPlanCsv(params.display_name)
);

export const getPayPlanHelp = createAsyncThunk(
  'payPlans/getPayPlanHelp',
  async (params) => await payPlanService.getPayPlanImportHelp(params)
);

const payPlansSlice = createSlice({
  name: 'payPlans',
  initialState,
  reducers: {
    resetPayPlanState: (state) => initialState,
    resetPayPlanInfoState: (state) => {
      state.payPlanInfoStatus = LoadingStatus.Idle;
    },
  },
  extraReducers: {
    [fetchPayPlans.pending]: (state, action) => {
      state.payPlanStatus = LoadingStatus.Loading;
    },
    [fetchPayPlans.fulfilled]: (state, action) => {
      state.payPlanPageInfo = action.payload.pageInfo;
      state.payPlanStatus = LoadingStatus.Loaded;

      if (action.meta.arg.reset) {
        state.loadedPayPlanMap = {};
      }

      const startIndex = action.meta.arg.startIndex;
      const stopIndex = startIndex + action.payload.payPlans.length - 1;

      for (var i = startIndex; i <= stopIndex; i++) {
        state.loadedPayPlanMap[i] = LoadingStatus.Loaded;
      }

      // In case we didn't load as many as requested
      for (
        let j = stopIndex + 1;
        j <= action.payload?.pageInfo?.TotalCount;
        j++
      ) {
        delete state.loadedPayPlanMap[j];
      }

      if (action.meta.arg.reset) {
        state.payPlans = action.payload.payPlans;
      } else {
        state.payPlans = state.payPlans.concat(action.payload.payPlans);
      }
    },
    [fetchPayPlans.rejected]: (state, action) => {
      state.payPlanStatus = LoadingStatus.Failed;
    },
    [createPayPlan.fulfilled]: (state, action) => {
      state.payPlanStatus = LoadingStatus.Idle;
    },
    [updatePayPlan.fulfilled]: (state, action) => {
      state.payPlanStatus = LoadingStatus.Idle;
    },
    [deletePayPlan.fulfilled]: (state, action) => {
      state.payPlanStatus = LoadingStatus.Idle;
    },
    [deletePayPlanArray.fulfilled]: (state, action) => {
      state.payPlanStatus = LoadingStatus.Idle;
    },
    [deletePayPlanArray.rejected]: (state, action) => {
      state.status = SavingStatus.Failed;
      state.error = action.error.message;
    },
    [fetchPayPlanById.fulfilled]: (state, action) => {
      state.displayPayPlan = action.payload;
    },
    [fetchPayPlanUsageById.fulfilled]: (state, action) => {
      state.payPlanUsage = action.payload;
    },
    [getPayPlanImportSampleCsv.fulfilled]: (state, action) => {
      if (action?.payload) {
        const url = window.URL.createObjectURL(new Blob([action.payload]));
        const link = document.createElement('a');
        link.href = url;
        let downloadName =
          action.payload.headers?.filename ?? 'pay_plan_import.csv';
        link.setAttribute('download', downloadName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    },
    [getPayPlanImportSampleCsv.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    [exportPayPlanCsv.fulfilled]: (state, action) => {
      if (action?.payload) {
        const url = window.URL.createObjectURL(new Blob([action.payload]));
        const link = document.createElement('a');
        link.href = url;
        let downloadName =
          action.payload.headers?.filename ?? 'pay_plan_export.csv';
        link.setAttribute('download', downloadName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    },
    [exportPayPlanCsv.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    [getPayPlanHelp.pending]: (state, action) => {
      state.payPlanInfoStatus = LoadingStatus.Loading;
    },
    [getPayPlanHelp.fulfilled]: (state, action) => {
      state.payPlanInfo = action.payload;
      state.payPlanInfoStatus = LoadingStatus.Loaded;
    },
    [getPayPlanHelp.rejected]: (state, action) => {
      state.payPlanInfoStatus = LoadingStatus.Failed;
    },
  },
});

export const selectPayPlan = (state) => state.payPlans.payPlans;
export const getPayPlanStatus = (state) => state.payPlans.payPlanStatus;
export const getPayPlanPageInfo = (state) => state.payPlans.payPlanPageInfo;
export const getPayPlanUsage = (state) => state.payPlans.payPlanUsage;

export const getPayPlanInfoStatus = (state) => state.payPlans.payPlanInfoStatus;

export const { resetPayPlanState, resetPayPlanInfoState } =
  payPlansSlice.actions;

export default payPlansSlice.reducer;
