import Fade from '@mui/material/Fade';
import { Box } from '@mui/system';
import useStyles from './style';
import { ReactComponent as LoadingSvg } from 'assets/loading.svg';

const Loader = ({
  isTable = false,
  colSpan = null,
  enableBorder = false,
  boxSize = 0,
  styles,
}) => {
  const classes = useStyles();

  const Spinner = () => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        sx={styles}
        alignItems="center"
        justifyContent="center"
        p={boxSize}
        className={enableBorder && classes.boxRadius}
      >
        <Fade in={true} unmountOnExit>
          <LoadingSvg />
        </Fade>
      </Box>
    );
  };

  if (isTable) {
    return (
      <tr>
        <td colSpan={colSpan}>
          <Spinner />
        </td>
      </tr>
    );
  }

  return <Spinner />;
};

export default Loader;
