import { getNetworkService } from 'helpers';
import { config } from 'ectl-config';

const networkService = getNetworkService({
  config: config,
});

const baseUrl = `${config.apiUrl}/account`;

const mapUser = (user) => {
  return user;
};

const mapUsers = (users) => {
  return users.map((user) => {
    return mapUser(user);
  });
};

export const refreshToken = () => {
  const user = getUser();
  return networkService
    .post(`${baseUrl}/refresh-token`, {}, 15000)
    .then((response) => {
      const user = mapUser(response.data);
      if (user.themes_json) {
        user.themes = JSON.parse(user.themes_json);
        delete user.themes_json;
      }
      // publish user to subscribers and start timer to refresh token
      localStorage.setItem('user', JSON.stringify(user));
      startRefreshTokenTimer();
      return user;
    })
    .catch((e) => {
      networkService.post(`${baseUrl}/revoke-token`, {}).finally((response) => {
        stopRefreshTokenTimer();
        localStorage.clear();
        if (user) {
          window.location.href = './';
          return null;
        }
      });
      return Promise.reject(e);
    });
};

const stopRefreshTokenTimer = () => clearTimeout(refreshTokenTimeout);

// helper functions
let refreshTokenTimeout;

const getUser = () => {
  return JSON.parse(localStorage.getItem('user'));
};

const startRefreshTokenTimer = () => {
  const user = getUser();
  // parse json object from base64 encoded jwt token
  const jwtToken = JSON.parse(atob(user.jwt_token.split('.')[1]));
  // set a timeout to refresh the token a minute before it expires
  const expires = new Date(jwtToken.exp * 1000);
  const timeout = expires.getTime() - Date.now() - 60 * 1000;
  refreshTokenTimeout = setTimeout(refreshToken, timeout);
};

export const login = async (email) => {
  try {
    const response = await networkService.post(
      `${baseUrl}/auth`,
      { email },
      10000
    );

    const user = mapUser(response.data);
    localStorage.setItem('user', JSON.stringify(user));
    startRefreshTokenTimer();

    return user;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const logout = async () => {
  // revoke token, stop refresh timer, publish null to user subscribers and redirect to login page
  // Revoking the token logs out user from all browsers
  const user = getUser();
  await networkService
    .post(`${baseUrl}/revoke-token`, { token: user?.refresh_token })
    .then((response) => {
      localStorage.clear();
      stopRefreshTokenTimer();
    })
    .catch((e) => {
      localStorage.clear();
      stopRefreshTokenTimer();
    });
};

export const fetchApiVersion = async () => {
  const response = await networkService.get(`${config.apiUrl}/status`);
  return response?.data;
};
