import { makeStyles } from '@mui/styles';

const autoCompleteStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: '8px',
    marginBottom: '4px',
    '& .MuiAutocomplete-root': {
      position: 'relative',
    },
  },
  clearIndicator: {
    '& .MuiInputBase-root': {
      '& .MuiAutocomplete-endAdornment': {
        '& .MuiAutocomplete-clearIndicator': {
          right: '20px',
        },
      },
    },
  },
  heading: {
    fontSize: '16px',
    color: 'grey',
  },
  accordion: {
    border: '1px solid #c7c7c7',
    boxShadow: 'none',
    '&:hover': {
      border: '1px solid black',
    },
  },
  customAccordionSummary: {
    height: '40px',
    minHeight: '0px',
    '& div': {
      margin: '0 -12px 0 0',
    },
  },
  accordionSummary: {
    minHeight: '40px',
    height: '40px',
    '&.Mui-expanded': {
      minHeight: '40px',
      height: '40px',
    },
  },
  inputRoot: {
    color: 'inherit',
    border: '1px solid #BDC3C7',
    borderRadius: '5px',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  errorField: {
    '& .MuiAutocomplete-inputRoot .MuiOutlinedInput-notchedOutline': {
      borderColor: '#f44336',
    },
    '& .MuiAutocomplete-inputRoot:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#f44336',
    },
    '& .MuiAutocomplete-inputRoot.Mui-focused .MuiOutlinedInput-notchedOutline':
      {
        borderColor: '#f44336',
      },
    '& .MuiInputLabel-root, & .MuiInputLabel-root.Mui-focused ': {
      color: '#f44336',
    },
  },
  isCloseButton: {
    '& .MuiAutocomplete-hasPopupIcon.css-l3ln04-MuiAutocomplete-root .MuiOutlinedInput-root':
      {
        paddingRight: '50px',
      },
    '& .MuiAutocomplete-hasClearIcon.css-l3ln04-MuiAutocomplete-root .MuiOutlinedInput-root':
      {
        paddingRight: '50px',
      },
  },
}));

export { autoCompleteStyles };
