export const LoadingStatus = {
  Idle: 'Idle',
  Loading: 'Loading',
  Loaded: 'Loaded',
  Failed: 'Failed',
};

export const SavingStatus = {
  Idle: 'Idle',
  Saving: 'Saving',
  Saved: 'Saved',
  Failed: 'Failed',
};

export const SystemRoleEnums = {
  ProgramManager: 'Program Manager',
  Admin: 'Admin',
  Supervisor: 'Supervisor',
  Certifier: 'Certifier',
  User: 'User',
  AMC: 'AMC',
  BlueGrassAdmin: 'BlueGrassAdmin',
  DisableAdminRoles: ['Supervisor', 'User', 'Certifier'],
  NotUserAndAdmin: [
    'BlueGrassAdmin',
    'Program Manager',
    'Supervisor',
    'Certifier',
  ],
  EnableAdminRoles: [
    'BlueGrassAdmin',
    'Program Manager',
    'Supervisor',
    'Certifier',
    'Admin',
  ],
};

export const employeeRoleEnums = {
  EditSkills: 'EditSkills',
  ViewReport: 'ViewReport',
  EditUser: 'EditUser',
};

export const jobDepotLabels = ['Not Trained', 'Trained', 'Prof', 'Journeyman'];

export const JOB_ID = 'job_id';
export const JOB_ROLE_ID = 'job_role_id';
export const CAPABILITY_ID = 'capability_id';
export const SUPER_VISOR_ID = 'supervisor_employee_id';
export const EMPLOYEE_ID = 'employee_id';
export const COST_CENTER_ID = 'cost_center';

export const PAY_PLAN_CACHE = 'payPlanCache';
export const PAY_GRADE_CACHE = 'payGradeCache';
export const ADMIN_JOB_CACHE = 'adminJobCache';
export const JOB_ROLE_CACHE = 'jobRoleCache';
export const CAPABILITY_CACHE = 'capabilityCache';
export const SKILL_CACHE = 'skillCache';
export const LEVEL_CACHE = 'levelsCache';
export const EMPLOYEE_CACHE = 'employeesCache';
export const COST_CENTER_CACHE = 'costCenterCache';
export const OFFICE_CACHE = 'officeCache';
export const EXTERNAL_API_CACHE = 'externalApiCache';

export const adminTabsTitles = {
  depot: 'Depot',
  payPlan: 'Pay Plan',
  payGrade: 'Pay Grade',
  job: 'Jobs',
  jobRoles: 'Job Roles',
  capabilities: 'Capabilities',
  skill: 'Skills',
  level: 'Level',
  employee: 'Employee',
  costCenter: 'Cost Center',
  office: 'Office',
  externalApi: 'External API',
  ctls: 'CTLs',
};

export const NO_MATCHING_TEAMS_DATA = 'No Team Data Found.';
export const NO_MATCHING_REPORTS = 'No Matching Reports.';
export const NO_MATCHING_PAY_PLANS = 'No Matching Pay Plans.';
export const NO_MATCHING_PAY_GRADES = 'No Matching Pay Grades.';
export const NO_MATCHING_JOBS = 'No Matching Jobs.';
export const NO_MATCHING_JOB_ROLES = 'No Matching Job Roles.';
export const NO_MATCHING_CAPABILITIES = 'No Matching Capabilities.';
export const NO_MATCHING_SKILLS = 'No Matching Skills.';
export const NO_MATCHING_LEVELS = 'No Matching Levels.';
export const NO_MATCHING_EMPLOYEES = 'No Matching Employees.';
export const NO_MATCHING_COST_CENTERS = 'No Matching Cost Centers.';
export const NO_MATCHING_OFFICES = 'No Matching offices.';
export const NO_MATCHING_EXTERNAL_APIS = 'No Matching External API.';
export const NO_JOB_HISTORY_FOUND = 'No JobHistory found.';
export const NO_CAPABILITY_HISTORY_FOUND = 'No Capability History found.';

export const PAY_PLAN_TABLE_KEYS = {
  pay_plan_id: { id: 1 },
  display_name: { id: 2 },
  user_count: { id: 3 },
};

export const PAY_GRADE_TABLE_KEYS = {
  pay_grade_id: { id: 4 },
  display_name: { id: 5 },
  user_count: { id: 6 },
};

export const JOB_TABLE_KEYS = {
  job_id: { id: 7 },
  display_name: { id: 8 },
  series_id: { id: 9 },
  family_display_name: { id: 10 },
  family_series_id: { id: 11 },
  job_role_count: { id: 12 },
  description: { id: 49 },
};

export const JOB_ROLE_TABLE_KEYS = {
  job_role_id: { id: 13 },
  display_name: { id: 14 },
  job_display_name: { id: 15 },
  job_series_id: { id: 16 },
  capability_count: { id: 17 },
  description: { id: 50 },
};

export const CAPABILITY_TABLE_KEYS = {
  capability_id: { id: 18 },
  display_name: { id: 19 },
  levels: { id: 20 },
  skill_count: { id: 21 },
  description: { id: 51 },
};

export const SKILL_TABLE_KEYS = {
  skill_id: { id: 22 },
  display_name: { id: 23 },
  capability_count: { id: 24 },
  description: { id: 52 },
};

export const LEVEL_TABLE_KEYS = {
  level_id: { id: 25 },
  display_name: { id: 26 },
  color: { id: 27 },
  previous_level_display_name: { id: 28 },
  description: { id: 53 },
};

export const EMPLOYEE_TABLE_KEYS = {
  employee_id: { id: 29 },
  first_name: { id: 30 },
  last_name: { id: 31 },
  dod_id: { id: 32 },
  pay_plan_display_name: { id: 33 },
  pay_grade_display_name: { id: 34 },
  system_role_display_name: { id: 35 },
  supervisor_full_name: { id: 36 },
  job_display_name: { id: 37 },
  job_role_display_name: { id: 38 },
};

export const COST_CENTER_TABLE_KEYS = {
  cost_center_id: { id: 39 },
  cc_id: { id: 40 },
  psntl: { id: 41 },
  description: { id: 54 },
};

export const OFFICE_TABLE_KEYS = {
  office_id: { id: 42 },
  cost_center: { id: 43 },
  directorate: { id: 44 },
  symbol: { id: 45 },
  description: { id: 55 },
};

export const EXTERNAL_API_TABLE_KEYS = {
  api_client_id: { id: 46 },
  display_name: { id: 47 },
  api_key: { id: 48 },
  description: { id: 56 },
};
