import { useCallback, useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import pluralize from 'pluralize';
import { MdClose } from 'react-icons/md';

import {
  fetchGroupTrainingSkill,
  getGroupTrainingPageInfo,
  resetGroupTrainingState,
  selectGroupTrainingSkill,
} from 'features/groupTraining/groupTrainingSlice';
import {
  activateLoading,
  deactivateLoading,
} from 'features/global/loadingProgressSlice';
import { NO_MATCHING_SKILLS } from 'helpers';
import { NoDataMessage } from 'shared/components/_Tables/NoDataMessage';
import { selectActiveUser } from 'features/account/accountSlice';
import { EmployeeTrainingTable } from '../ItemEmployeeSkillTable';
import { useDebounce } from 'hooks/useDebounce';

const EmployeeSkillModal = ({
  setIsSkillModalOpen,
  isSkillModalOpen,
  capabilityId,
  setEmployeeTrainingPayload,
  employeeTrainingPayload,
}) => {
  //#region Constants
  const dispatch = useDispatch();
  // const isSelected = (id) => selected?.indexOf(id) !== -1;
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  //#endregion State
  const [sortBy, setSortBy] = useState('');
  const [sortDirection, setSortDirection] = useState('');
  const [createdDateFilter, setCreatedDateFilter] = useState(
    moment(new Date())
  );
  const [userFilter, setUserFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);
  const [hasIssueFilter, setHasIssueFilter] = useState('');
  const [primaryIdFilter, setPrimaryIdFilter] = useState('');
  const [secondaryIdFilter, setSecondaryIdFilter] = useState('');

  //#region Selectors
  const groupTrainingPageInfo = useSelector(getGroupTrainingPageInfo);
  const groupTrainingSkillData = useSelector(selectGroupTrainingSkill);
  const user = useSelector(selectActiveUser);
  //#endregion Selectors

  //#region Refs
  //#endregion Refs

  //#region Effects

  useEffect(() => {
    const fetchData = async () => {
      dispatch(activateLoading());
      try {
        await dispatch(
          fetchGroupTrainingSkill({
            capability_id: capabilityId,
          })
        );
      } finally {
        dispatch(deactivateLoading());
      }
    };
    if (isSkillModalOpen) {
      fetchData();
    }
  }, [isSkillModalOpen, dispatch, capabilityId]);

  useEffect(() => {
    return () => {
      dispatch(resetGroupTrainingState());
    };
  }, []);

  //#endregion Effects

  const setSorting = (_sortBy, _sortDirection) => {
    setSortBy(_sortBy);
    setSortDirection(_sortDirection);
    setEmployeeTrainingPayload({
      ...employeeTrainingPayload,
      sort: `${_sortDirection === 'ASC' ? '' : '-'}${_sortBy}`,
    });
  };

  const debouncedSearch = useCallback(
    useDebounce(
      (
        status,
        hasIssue,
        searchByPrimaryId,
        searchBySecondaryId,
        createdDate,
        user
      ) => {
        setEmployeeTrainingPayload({
          ...employeeTrainingPayload,
          reset: true,
          startIndex: 0,
          stopIndex: 19,
          status: status,
          hasIssue: hasIssue,
          searchByPrimaryId: searchByPrimaryId,
          searchBySecondaryId: searchBySecondaryId,
          createdDate:
            createdDate &&
            moment(createdDate).utc().startOf('day').format('YYYY-MM-DD'),
          createdById: user,
        });
      },
      1000
    ),
    [employeeTrainingPayload]
  );

  const clearFilters = () => {
    setStatusFilter([]);
    setHasIssueFilter('');
    setPrimaryIdFilter('');
    setSecondaryIdFilter('');
    setCreatedDateFilter(moment(new Date()));
    setUserFilter([]);
    setEmployeeTrainingPayload({
      ...employeeTrainingPayload,
      startIndex: 0,
      stopIndex: 19,
      status: [],
      hasIssue: '',
      searchByPrimaryId: '',
      searchBySecondaryId: '',
      createdDate: moment(new Date()),
      createdById: '',
    });
  };
  //#endregion Methods

  //#region Render time calcs
  //#endregion Render time calcs

  //#region Render

  return (
    <Dialog
      open={isSkillModalOpen}
      onClose={() => {}}
      maxWidth={'sm'}
      fullWidth
    >
      <DialogTitle>
        {pluralize(
          `Select ${user.label_skill}`,
          groupTrainingSkillData?.length
        )}
        <IconButton
          aria-label="close"
          onClick={() => {
            setIsSkillModalOpen(false);
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 6,
            color: '#000',
            fontSize: '1.4rem',
          }}
          size="large"
        >
          <MdClose />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {groupTrainingSkillData?.length > 0 ? (
          <>
            <DialogContentText marginTop={'1rem'} marginBottom={'5px'}>
              All {pluralize(user.label_skill, groupTrainingSkillData?.length)}{' '}
              shown in{' '}
              <Typography
                component="span"
                sx={{
                  color: 'red',
                  display: 'inline-block',
                }}
              >
                Red
              </Typography>{' '}
              require additional evidence to mark complete, so you will not be
              able to select them for completion on this screen. Please select
              all {pluralize(user.label_skill, groupTrainingSkillData?.length)}{' '}
              you'd like to start a group completion session for.
            </DialogContentText>
            <fieldset
              style={{
                display: 'flex',
                alignItems: 'center',
                border: '0',
                margin: '1rem 0 0',
              }}
            >
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  sx={{ width: '100%' }}
                  label="Date"
                  value={createdDateFilter}
                  format="YYYY-MM-DD"
                  disableFuture
                  onChange={(newValue) => {
                    if (
                      moment(newValue)
                        .utc()
                        .startOf('day')
                        .format('YYYY-MM-DD') !== 'Invalid date'
                    ) {
                      setCreatedDateFilter(newValue);
                      debouncedSearch(
                        statusFilter,
                        hasIssueFilter,
                        primaryIdFilter,
                        secondaryIdFilter,
                        newValue,
                        userFilter
                      );
                      dispatch(
                        fetchGroupTrainingSkill({
                          capability_id: capabilityId,
                          completion_date:
                            moment(newValue).format('YYYY-MM-DD'),
                        })
                      );
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      variant="outlined"
                      {...params}
                      sx={{
                        minWidth: '150px',
                        width: '100%',
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
              <IconButton
                aria-label="close"
                onClick={clearFilters}
                sx={{ padding: 0, paddingLeft: '0.50.rem' }}
                size="small"
              >
                <MdClose className="react-icon" />
              </IconButton>
            </fieldset>
            <EmployeeTrainingTable
              groupTrainingData={groupTrainingSkillData}
              pageInfo={groupTrainingPageInfo}
              employeeTrainingPayload={employeeTrainingPayload}
              sortDirection={sortDirection}
              sortBy={sortBy}
              handleSort={(_sortBy, _sortDirection) =>
                setSorting(_sortBy, _sortDirection)
              }
              user={user}
              setIsSkillModalOpen={setIsSkillModalOpen}
              createdDate={createdDateFilter}
            />
          </>
        ) : (
          <NoDataMessage message={NO_MATCHING_SKILLS} />
        )}
      </DialogContent>
    </Dialog>
  );
  //#endregion Render
};
export { EmployeeSkillModal };
