import React from 'react';
import clsx from 'clsx';
import { Typography, Toolbar, Tooltip } from '@mui/material';
import { useToolbarStyles } from 'components/styles';
import { Each } from 'helpers/Each';

const EnhancedTableToolbar = ({
  numSelected,
  toolTipTitle,
  totalCount,
  children,
}) => {
  const classes = useToolbarStyles();

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 && (
        <React.Fragment>
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} of {totalCount} selected
          </Typography>
          {children &&
            (Array.isArray(children) ? (
              <Each
                of={children}
                render={(item) => (
                  <Tooltip title={item?.props.toolTipTitle} key={item.item_id}>
                    <span>{item}</span>
                  </Tooltip>
                )}
              />
            ) : (
              <Tooltip title={toolTipTitle}>
                <span>{children}</span>
              </Tooltip>
            ))}
        </React.Fragment>
      )}
    </Toolbar>
  );
};
export { EnhancedTableToolbar };
