import { config } from 'ectl-config';
import { getNetworkService } from 'helpers';
import { buildFilters } from 'hooks/buildFilter';

const accountService = { config: config };
const networkService = getNetworkService({
  config: config,
  checkAuth: accountService.checkAuth,
});
const baseUrl = `${config.apiUrl}/Report/costcenter`;

const getCostCenterReports = async (
  depot_id = null,
  supervisor_employee_id = null,
  cost_center_id = null,
  job_id = null,
  job_role_id = null,
  report_expiry = null,
  custom_expiry_date = null,
  capability_id = null,
  office_id = null
) => {
  const filters = buildFilters(
    ['supervisor_employee_id', supervisor_employee_id],
    ['cost_center_id', cost_center_id],
    ['job_id', job_id],
    ['job_role_id', job_role_id],
    ['report_expiry', report_expiry],
    ['custom_expiry_date', custom_expiry_date],
    ['capability_id', capability_id],
    ['office_id', office_id]
  );

  const params = {
    filters: filters,
  };

  const query = new URLSearchParams(params).toString();
  if (depot_id) {
    const response = await networkService.get(
      `${baseUrl}/${depot_id}/?${query}`,
      60000
    );
    return response?.data;
  } else {
    const response = await networkService.get(`${baseUrl}/?${query}`, 60000);
    return response?.data;
  }
};

export const costCenterReportService = {
  getCostCenterReports,
};
