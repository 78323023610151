import { config } from 'ectl-config';
import { getNetworkService } from 'helpers';
import { buildFilters } from 'hooks/buildFilter';

const baseUrl = `${config.apiUrl}/dropdown`;
const networkService = getNetworkService({
  config: config,
});

const fetchJobDropDown = async () => {
  const response = await networkService.get(`${baseUrl}/Job`);
  return { dropDowns: response.data };
};

const fetchLevelDropDown = async (
  display_name = '',
  level_id = null,
  sort = 'level_id'
) => {
  const filters = [];

  if (level_id) {
    filters.push(`level_id==${level_id}`);
  }
  if (display_name) {
    filters.push(`display_name@==${display_name}`);
  }

  const params = {
    filters: filters.join(','),
    sorts: sort,
  };

  const query = new URLSearchParams(params).toString();

  const response = await networkService.get(`${baseUrl}/level/?${query}`);
  return { dropDowns: response.data };
};

const fetchJobRoleDropDown = async (
  display_name = '',
  job_id = null,
  job_display_name = '',
  job_series_id = null,
  job_family_series_id = null,
  level_id = null,
  level_display_name = '',
  sort = 'job_role_id'
) => {
  const filters = buildFilters(
    ['display_name', display_name],
    ['job_id', job_id],
    ['job_display_name', job_display_name],
    ['job_series_id', job_series_id],
    ['job_family_series_id', job_family_series_id],
    ['level_id', level_id],
    ['level_display_name', level_display_name]
  );

  const params = {
    filters: filters,
    sorts: sort,
  };

  const query = new URLSearchParams(params).toString();

  const response = await networkService.get(`${baseUrl}/JobRole/?${query}`);
  return { dropDowns: response.data };
};

const fetchDirectReportsDropDown = async (
  employee_id = null,
  first_name = '',
  last_name = '',
  full_name = '',
  pay_plan_id = null,
  pay_plan_display_name = '',
  pay_grade_id = null,
  pay_grade_display_name = '',
  job_id = null,
  job_display_name = '',
  job_series_id = null,
  job_family_series_id = null,
  job_family_display_name = '',
  job_role_id = null,
  job_role_display_name = '',
  supervisor_employee_id = null,
  supervisor_first_name = '',
  supervisor_last_name = '',
  supervisor_full_name = '',
  sort = 'employee_id'
) => {
  const filters = buildFilters(
    ['employee_id', employee_id],
    ['first_name', first_name],
    ['last_name', last_name],
    ['full_name', full_name],
    ['pay_plan_id', pay_plan_id],
    ['pay_plan_display_name', pay_plan_display_name],
    ['pay_grade_id', pay_grade_id],
    ['pay_grade_display_name', pay_grade_display_name],
    ['job_id', job_id],
    ['job_display_name', job_display_name],
    ['job_series_id', job_series_id],
    ['job_family_series_id', job_family_series_id],
    ['job_family_display_name', job_family_display_name],
    ['job_role_id', job_role_id],
    ['job_role_display_name', job_role_display_name],
    ['supervisor_employee_id', supervisor_employee_id],
    ['supervisor_first_name', supervisor_first_name],
    ['supervisor_last_name', supervisor_last_name],
    ['supervisor_full_name', supervisor_full_name]
  );

  const params = {
    filters: filters,
    sorts: sort,
  };

  const query = new URLSearchParams(params).toString();

  const response = await networkService.get(
    `${baseUrl}/employee/direct-report/?${query}`
  );
  return { dropDowns: response.data };
};

const fetchSkillsDropDown = async (
  display_name = '',
  skill_id = null,
  sort = 'skill_id'
) => {
  const filters = [];

  if (skill_id) {
    filters.push(`skill_id==${skill_id}`);
  }
  if (display_name) {
    filters.push(`display_name@==${display_name}`);
  }

  const params = {
    filters: filters.join(','),
    sorts: sort,
  };

  const query = new URLSearchParams(params).toString();

  const response = await networkService.get(`${baseUrl}/skill/?${query}`);
  return { dropDowns: response.data };
};

const fetchSkillExpiry = async () => {
  const response = await networkService.get(`${baseUrl}/skillExpiry`);
  return { dropDowns: response.data };
};

const fetchCapabilitiesDropDown = async (
  capability_id = null,
  display_name = '',
  job_id = null,
  job_role_id = null,
  sort = 'capability_id'
) => {
  const filters = buildFilters(
    ['capability_id', capability_id],
    ['display_name', display_name],
    ['job_id', job_id],
    ['job_role_id', job_role_id]
  );

  const params = {
    filters: filters,
    sorts: sort,
  };

  const query = new URLSearchParams(params).toString();

  const response = await networkService.get(`${baseUrl}/Capability?${query}`);
  return { dropDowns: response.data };
};

const fetchEmployeeDropDown = async (
  include_self = false,
  start_index = 0,
  stop_index = 19
) => {
  const filters = [`start_index==${start_index}`, `stop_index==${stop_index}`];

  if (include_self) {
    filters.push(`include_self=${include_self}`);
  }

  const params = {
    include_self: include_self,
    filters: filters.join(','),
  };

  const query = new URLSearchParams(params).toString();

  const response = await networkService.get(`${baseUrl}/employee?${query}`);
  return { dropDowns: response.data };
};

const fetchSuperVisorDropDown = async (include_self = false) => {
  try {
    const response = await networkService.get(
      `${baseUrl}/employee/supervisor?include_self=${include_self}`
    );
    return { dropDowns: response.data };
  } catch (e) {
    return Promise.reject(e.error);
  }
};

const fetchTaskManagerDropDown = async (include_self = false) => {
  try {
    const response = await networkService.get(
      `${baseUrl}/employee/taskmanager?include_self=${include_self}`
    );
    return { dropDowns: response.data };
  } catch (e) {
    return Promise.reject(e.error);
  }
};

const fetchPayPlansDropDown = async (
  pay_plan_id = null,
  display_name = '',
  start_index = 0,
  stop_index = 19,
  sort = 'pay_plan_id'
) => {
  const filters = [`start_index==${start_index}`, `stop_index==${stop_index}`];

  if (pay_plan_id) {
    filters.push(`pay_plan_id==${pay_plan_id}`);
  }
  if (display_name) {
    filters.push(`display_name@==${display_name}`);
  }

  const params = {
    filters: filters.join(','),
    sorts: sort,
  };

  const query = new URLSearchParams(params).toString();

  const response = await networkService.get(`${baseUrl}/payplan?${query}`);
  return { dropDowns: response.data };
};

const fetchPayGradesDropDown = async (
  pay_grade_id = null,
  display_name = '',
  start_index = 0,
  stop_index = 19,
  sort = 'pay_grade_id'
) => {
  const filters = [`start_index==${start_index}`, `stop_index==${stop_index}`];

  if (pay_grade_id) {
    filters.push(`pay_grade_id==${pay_grade_id}`);
  }
  if (display_name) {
    filters.push(`display_name@==${display_name}`);
  }

  const params = {
    filters: filters.join(','),
    sorts: sort,
  };

  const query = new URLSearchParams(params).toString();

  const response = await networkService.get(`${baseUrl}/paygrade?${query}`);
  return { dropDowns: response.data };
};

const fetchSystemRoles = async (employee_id = null) => {
  const response = await networkService.get(
    `${baseUrl}/system-role${!!employee_id ? `/${employee_id}` : ''}`
  );
  return { dropDowns: response.data };
};

const fetchReportsDropDown = async (
  employee_id = null,
  first_name = '',
  last_name = '',
  full_name = '',
  pay_plan_id = null,
  pay_plan_display_name = '',
  pay_grade_id = null,
  pay_grade_display_name = '',
  job_id = null,
  job_display_name = '',
  job_series_id = null,
  job_family_series_id = null,
  job_family_display_name = '',
  job_role_id = null,
  job_role_display_name = '',
  supervisor_employee_id = null,
  supervisor_first_name = '',
  supervisor_last_name = '',
  supervisor_full_name = '',
  sort = 'employee_id'
) => {
  const filters = buildFilters(
    ['employee_id', employee_id],
    ['first_name', first_name],
    ['last_name', last_name],
    ['full_name', full_name],
    ['pay_plan_id', pay_plan_id],
    ['pay_plan_display_name', pay_plan_display_name],
    ['pay_grade_id', pay_grade_id],
    ['pay_grade_display_name', pay_grade_display_name],
    ['job_id', job_id],
    ['job_display_name', job_display_name],
    ['job_series_id', job_series_id],
    ['job_family_series_id', job_family_series_id],
    ['job_family_display_name', job_family_display_name],
    ['job_role_id', job_role_id],
    ['job_role_display_name', job_role_display_name],
    ['supervisor_employee_id', supervisor_employee_id],
    ['supervisor_first_name', supervisor_first_name],
    ['supervisor_last_name', supervisor_last_name],
    ['supervisor_full_name', supervisor_full_name]
  );

  const params = {
    filters: filters,
    sorts: sort,
  };
  const query = new URLSearchParams(params).toString();

  const response = await networkService.get(
    `${baseUrl}/employee/reports/?${query}`
  );
  return { dropDowns: response.data };
};

const fetchCostCenter = async () => {
  const response = await networkService.get(`${baseUrl}/employee/costcenter`);
  return { dropDowns: response.data };
};

const fetchReportExpiration = async () => {
  const response = await networkService.get(`${baseUrl}/report/expiration`);
  return { dropDowns: response.data };
};

const fetchEmployeeStatus = async () => {
  const response = await networkService.get(`${baseUrl}/status`);
  return { dropDowns: response.data };
};

const fetchExternalApi = async () => {
  const response = await networkService.get(
    `${baseUrl}/external/restrictiontype`
  );
  return { dropDowns: response.data };
};

const fetchOfficeDropDown = async (cost_center_id = null) => {
  if (cost_center_id) {
    const filters = buildFilters(['cost_center_id', cost_center_id]);
    const params = {
      filters: filters,
    };

    const query = new URLSearchParams(params).toString();

    const response = await networkService.get(
      `${baseUrl}/employee/office/?${query}`
    );
    return {
      dropDowns: response?.data,
    };
  } else {
    const response = await networkService.get(`${baseUrl}/employee/office`);
    return {
      dropDowns: response?.data,
    };
  }
};

const fetchAllEmployees = async (include_self = false) => {
  try {
    const response = await networkService.get(
      `${baseUrl}/employee/all?include_self=${include_self}`
    );
    return { dropDowns: response.data };
  } catch (e) {
    return Promise.reject(e.error);
  }
};

const fetchCommandDropDown = async () => {
  try {
    const response = await networkService.get(`${baseUrl}/command`);
    return { dropDowns: response.data };
  } catch (e) {
    return Promise.reject(e.error);
  }
};

const fetchDepotDropDown = async (command_id = null) => {
  try {
    const filters = buildFilters(['command_id', command_id]);

    const params = {
      filters: filters,
    };

    const query = new URLSearchParams(params).toString();

    const response = await networkService.get(`${baseUrl}/depot/?${query}`);
    return { dropDowns: response.data };
  } catch (e) {
    return Promise.reject(e.error);
  }
};

const fetchRPDropDown = async () => {
  try {
    const response = await networkService.get(`${baseUrl}/report/percentage`);
    return { dropDowns: response.data };
  } catch (e) {
    return Promise.reject(e.error);
  }
};

export const dropDownService = {
  fetchJobDropDown,
  fetchLevelDropDown,
  fetchJobRoleDropDown,
  fetchDirectReportsDropDown,
  fetchSkillExpiry,
  fetchSkillsDropDown,
  fetchCapabilitiesDropDown,
  fetchSuperVisorDropDown,
  fetchTaskManagerDropDown,
  fetchPayPlansDropDown,
  fetchPayGradesDropDown,
  fetchSystemRoles,
  fetchReportsDropDown,
  fetchCostCenter,
  fetchReportExpiration,
  fetchEmployeeStatus,
  fetchExternalApi,
  fetchOfficeDropDown,
  fetchEmployeeDropDown,
  fetchAllEmployees,
  fetchCommandDropDown,
  fetchDepotDropDown,
  fetchRPDropDown,
};
