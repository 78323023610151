import { useCallback, useEffect, useRef, useState } from 'react';
import { Grid } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { map } from 'lodash';

import { SelectionAutoComplete } from 'shared/components/_Autocomplete/SelectionAutocomplete';
import {
  fetchCapabilityDropDown,
  fetchJobRolesByJobId,
  fetchJobsDropDown,
  getCapabilityDropDownStatus,
  getJobDropDownStatus,
  getJobRolesByJobIdDropDownStatus,
  resetJobRoleDropdown,
  selectCapabilitiesDropDown,
  selectJobDropDown,
  selectJobRoleByJobIdDropDown,
} from 'features/AdminSettingsDropDown/dropDownSlice';
import { selectActiveUser } from 'features/account/accountSlice';
import { FilterAccordion } from 'shared/components/_Accordion';
import { clearFilter } from 'helpers/clearFilter';
import {
  selectAdminSettingCache,
  updateAdminSettingCache,
} from 'features/global/visibilitySlice';
import { useDebounce } from 'hooks/useDebounce';
import { SKILL_CACHE } from 'helpers';
import { FormTextField } from 'shared/components/FormFields/TextField';

const ItemSkillFilter = ({ setItemSkillPayload, itemSkillPayload }) => {
  //#region Constants
  const dispatch = useDispatch();
  const getCacheData = useSelector(selectAdminSettingCache);
  const skillCacheData = getCacheData?.[SKILL_CACHE];
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  const [initValues, setInitValues] = useState({
    display_name: '',
    capability_id: null,
    job_id: null,
    job_role_id: null,
  });
  //#endregion State

  //#region Selectors
  const user = useSelector(selectActiveUser);
  //#endregion Selectors

  //#region Refs
  const formRef = useRef();
  //#endregion Refs

  //#region Effects
  useEffect(() => {
    setInitValues({ ...initValues, ...skillCacheData });
  }, [getCacheData, itemSkillPayload]);

  useEffect(() => {
    async function fetchJobRoleData() {
      const jobIds = itemSkillPayload?.job_id.map((item) => item?.value);
      dispatch(
        fetchJobRolesByJobId({
          job_id: jobIds,
        })
      );
    }
    if (itemSkillPayload?.job_id?.length > 0) {
      fetchJobRoleData();
    }
  }, [dispatch, itemSkillPayload]);

  //#endregion Effects

  //#region Methods
  const handleSearch = useCallback(
    useDebounce((displayName, capabilityId, jobIds, jobRoleIds) => {
      setItemSkillPayload({
        ...itemSkillPayload,
        display_name: displayName,
        capability_id: capabilityId,
        job_id: jobIds,
        job_role_id: jobRoleIds,
      });
    }, 1000),
    [itemSkillPayload]
  );

  const removeHandleSearch = useDebounce((capabilityId, jobIds, jobRoleIds) => {
    setItemSkillPayload({
      ...itemSkillPayload,
      capability_id: capabilityId,
      job_id: jobIds,
      job_role_id: jobRoleIds,
    });
  }, 1000);

  const handleResetFilters = () => {
    const isFilterData = clearFilter(formRef);
    if (isFilterData) {
      formRef?.current?.resetForm();
      setItemSkillPayload({
        ...itemSkillPayload,
        display_name: '',
        capability_id: null,
        job_id: null,
        job_role_id: null,
      });
      dispatch(fetchJobRolesByJobId());
      dispatch(updateAdminSettingCache({ key: SKILL_CACHE, ...{} }));
    }
  };
  //#endregion Methods

  //#region Render time calcs
  //#endregion Render time calcs

  //#region Render

  return (
    <FilterAccordion
      filteredTitles={itemSkillPayload}
      expandedId="skill-filter"
      styles={{ marginBottom: '1.5rem' }}
      onResetFilters={handleResetFilters}
    >
      <Formik
        enableReinitialize={true}
        initialValues={{ ...initValues }}
        innerRef={formRef}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Grid container columnSpacing={'1rem'}>
              <Grid item={true} xs={12} md={6} lg={3}>
                <FormTextField
                  name="display_name"
                  label="Display Name"
                  placeholder="Display Name"
                  fullWidth
                  onChange={(e) => {
                    const searchTerm = e.target.value;
                    setFieldValue('display_name', searchTerm);
                    handleSearch(
                      searchTerm,
                      values?.capability_id,
                      values?.job_id,
                      values?.job_role_id
                    );
                  }}
                />
              </Grid>
              <Grid item={true} xs={12} md={6} lg={3}>
                <Field name="capability_id">
                  {({ field: { value }, form: { setFieldValue } }) => (
                    <SelectionAutoComplete
                      title={`Select ${user.label_capability}`}
                      keyProperty="value"
                      nameProperty="text"
                      entityIds={value?.value}
                      setEntityIds={setFieldValue}
                      entitySelector={selectCapabilitiesDropDown}
                      entityStatusSelector={getCapabilityDropDownStatus}
                      onCustomChange={(value) => {
                        setFieldValue('capability_id', value);
                        handleSearch(
                          values?.display_name,
                          value,
                          values?.job_id,
                          values?.job_role_id
                        );
                      }}
                      fetchEntityPage={fetchCapabilityDropDown}
                      cancelItemTagEdit={() => {
                        setFieldValue('capability_id', null);
                        removeHandleSearch(
                          null,
                          values?.job_id,
                          values?.job_role_id
                        );
                      }}
                      formField="capability_id"
                      showCloseItemEdit={true}
                      multiSelection={false}
                      hideCheckIcon={true}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item={true} xs={12} md={6} lg={3}>
                <Field name="job_id">
                  {({ field: { value }, form: { setFieldValue } }) => (
                    <SelectionAutoComplete
                      title={`Select ${user.label_job}`}
                      keyProperty="value"
                      nameProperty="text"
                      entityIds={map(value, 'value')}
                      setEntityIds={setFieldValue}
                      entitySelector={selectJobDropDown}
                      entityStatusSelector={getJobDropDownStatus}
                      onCustomChange={(value) => {
                        setFieldValue('job_id', value);
                        handleSearch(
                          values?.display_name,
                          values?.capability_id,
                          value,
                          null
                        );
                      }}
                      fetchEntityPage={fetchJobsDropDown}
                      formField="job_id"
                      multiSelection={true}
                      showCloseItemEdit={true}
                      hideCheckIcon={true}
                      cancelItemTagEdit={() => {
                        setFieldValue('job_id', null);
                        removeHandleSearch(
                          values?.capability_id,
                          null,
                          values?.job_role_id
                        );
                        dispatch(resetJobRoleDropdown());
                      }}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item={true} xs={12} md={6} lg={3}>
                <Field name="job_role_id">
                  {({ field: { value }, form: { setFieldValue } }) => (
                    <SelectionAutoComplete
                      title={`Select ${user.label_job_role}`}
                      keyProperty="value"
                      nameProperty="text"
                      entityIds={map(value, 'value')}
                      setEntityIds={setFieldValue}
                      entitySelector={selectJobRoleByJobIdDropDown}
                      entityStatusSelector={getJobRolesByJobIdDropDownStatus}
                      onCustomChange={(value) => {
                        setFieldValue('job_role_id', value);
                        handleSearch(
                          values?.display_name,
                          values?.capability_id,
                          values?.job_id,
                          value
                        );
                      }}
                      fetchEntityPage={fetchJobRolesByJobId}
                      formField="job_role_id"
                      multiSelection={true}
                      showCloseItemEdit={true}
                      hideCheckIcon={true}
                      cancelItemTagEdit={() => {
                        setFieldValue('job_role_id', null);
                        removeHandleSearch(
                          values?.capability_id,
                          values?.job_id,
                          null
                        );
                      }}
                    />
                  )}
                </Field>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </FilterAccordion>
  );
  //#endregion Render
};
export { ItemSkillFilter };
