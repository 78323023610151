import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  boxRadius: {
    borderRadius: '4px',
    border: '1px solid #c4c4c4',
  },
}));

export default useStyles;
