import { useState, useEffect, useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { map } from 'lodash';
import { Box, Button, Grid, Typography } from '@mui/material';

import { FormActions } from 'helpers/form-action';
import { makeFormStyles } from 'forms/style';
import {
  activateLoading,
  deactivateLoading,
} from 'features/global/loadingProgressSlice';
import { CAPABILITY_CACHE, EMPLOYEE_CACHE, snackbarHandler } from 'helpers';
import { SnackbarDismiss } from 'shared/components/SnackbarDismiss';
import Loader from 'shared/components/Loader';

import { closePanel } from 'features/common/formSlice';
import {
  createJobRole,
  fetchJobRoles,
  fetchJobRolesById,
  getJobRoleById,
  resetJobRoleState,
  updateJobRole,
} from 'features/jobRole/jobRoleSlice';
import {
  fetchJobsDropDown,
  selectJobDropDown,
  getJobDropDownStatus,
} from 'features/AdminSettingsDropDown/dropDownSlice';
import { SelectionAutoComplete } from 'shared/components/_Autocomplete/SelectionAutocomplete';
import { selectActiveUser } from 'features/account/accountSlice';
import { ItemJobRoleCapabilityTable } from './ItemJobRoleCapabilityTable';
import { selectSearchCache, updateSearchCache } from 'features/Teams/teamSlice';
import {
  selectAdminSettingCache,
  updateAdminSettingCache,
} from 'features/global/visibilitySlice';
import { FormTextField } from 'shared/components/FormFields/TextField';

const ItemJobRoleForm = ({ formAction, itemTagId }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const getJobRoleData = useSelector(getJobRoleById);
  const getCacheData = useSelector(selectSearchCache);
  const adminCacheData = useSelector(selectAdminSettingCache);

  // eslint-disable-next-line no-unused-vars
  const [loaded, setLoaded] = useState(
    formAction.id === FormActions.Create.id ? true : false
  );
  const classes = makeFormStyles();
  const user = useSelector(selectActiveUser);
  // eslint-disable-next-line no-unused-vars
  const [formData, setFormData] = useState({
    capabilities: [],
  });
  const [initValues, setInitValues] = useState({
    display_name: '',
    job_id: null,
    description: '',
  });

  const tagNameRef = useRef(null);

  useEffect(() => {
    if (formAction.id === FormActions.Edit.id) {
      const fetchData = async () => {
        var objectData = await dispatch(fetchJobRolesById(itemTagId));
        var fetchedObject = objectData.payload;
        setInitValues({
          display_name: fetchedObject?.display_name,
          job_id: fetchedObject?.job_id,
          description: fetchedObject?.description,
        });
        setFormData({
          capabilities: fetchedObject?.capabilities,
        });
        setLoaded(true);
      };
      fetchData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      dispatch(resetJobRoleState());
    };
  }, []);

  const handleSubmit = async (values, setSubmitting, resetForm) => {
    dispatch(activateLoading({ showProgress: true }));
    var resultAction = null;
    if (formData) {
      formData.capabilities = map(formData.capabilities, 'capability_id');

      formData['capability_ids'] = formData['capabilities'];
      delete formData['capabilities'];
    }
    switch (formAction.id) {
      case FormActions.Edit.id:
        resultAction = await dispatch(
          updateJobRole({
            job_role_id: itemTagId,
            ...formData,
            ...values,
          })
        );
        if (!resultAction.error) {
          setInitValues({
            ...initValues,
            display_name: resultAction?.payload.display_name,
            job_id: resultAction?.payload?.job_id,
            description: resultAction?.payload?.description,
          });
          dispatch(closePanel({ formKey: `itemJobRoleForm` }));
          if (
            getCacheData?.job_role_id?.value === itemTagId &&
            getCacheData?.job_role_id?.text !==
              resultAction?.payload.display_name
          ) {
            dispatch(
              updateSearchCache({
                ...getCacheData,
                job_role_id: {
                  value: itemTagId,
                  text: resultAction?.payload.display_name,
                },
              })
            );
          }
          if (
            adminCacheData?.[CAPABILITY_CACHE]?.job_role_id?.value ===
              itemTagId &&
            adminCacheData?.[CAPABILITY_CACHE]?.job_role_id?.text !==
              resultAction?.payload.display_name
          ) {
            dispatch(
              updateAdminSettingCache({
                key: CAPABILITY_CACHE,
                payload: {
                  job_role_id: {
                    value: itemTagId,
                    text: resultAction?.payload.display_name,
                  },
                },
              })
            );
          }
          if (
            adminCacheData?.[EMPLOYEE_CACHE]?.job_role_id?.value ===
              itemTagId &&
            adminCacheData?.[EMPLOYEE_CACHE]?.job_role_id?.text !==
              resultAction?.payload.display_name
          ) {
            dispatch(
              updateAdminSettingCache({
                key: EMPLOYEE_CACHE,
                payload: {
                  job_role_id: {
                    value: itemTagId,
                    text: resultAction?.payload.display_name,
                  },
                },
              })
            );
          }
        }
        break;
      case FormActions.Create.id:
        resultAction = await dispatch(
          createJobRole({
            ...formData,
            ...values,
          })
        );
        if (!resultAction.error) {
          await dispatch(fetchJobRoles());
          resetForm();
          dispatch(closePanel({ formKey: `itemJobRoleForm` }));
          if (tagNameRef.current) {
            tagNameRef.current.focus();
          }
        }
        break;
      default:
        break;
    }

    dispatch(deactivateLoading());
    const { message, variant } = snackbarHandler(
      resultAction.meta.requestStatus,
      formAction.label
    );
    enqueueSnackbar(message, {
      action: (key) => <SnackbarDismiss key={key} />,
      variant: variant,
    });
    setSubmitting(false);
  };

  return !loaded ? (
    <Loader styleClassName={classes.loadingSvg} />
  ) : (
    <>
      <Grid container>
        <Grid item={true} xs={12} lg={6}>
          <Formik
            enableReinitialize={true}
            initialValues={{ ...initValues }}
            validationSchema={Yup.object({
              display_name: Yup.string().required('Required'),
              job_id: Yup.number().required('Required'),
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              handleSubmit(values, setSubmitting, resetForm);
            }}
          >
            {({ isSubmitting, errors }) => (
              <Form className={classes.form}>
                <FormTextField
                  label="Job Role"
                  name="display_name"
                  placeholder="Job Role"
                />
                <FormTextField
                  name="description"
                  label="Description"
                  placeholder="Description"
                  multiline
                  rows={3}
                />
                <Field className={classes.input} name="job_id">
                  {({ field: { value }, form: { setFieldValue } }) => (
                    <SelectionAutoComplete
                      title={`Select ${user?.label_job}`}
                      keyProperty="value"
                      nameProperty="text"
                      entityIds={value}
                      setEntityIds={setFieldValue}
                      entitySelector={selectJobDropDown}
                      entityStatusSelector={getJobDropDownStatus}
                      fetchEntityPage={fetchJobsDropDown}
                      formField={'job_id'}
                      multiSelection={false}
                    />
                  )}
                </Field>
                {errors?.job_id && (
                  <Typography variant="subtitle2" className={classes.inputErr}>
                    {errors.job_id}
                  </Typography>
                )}
                {getJobRoleData && formAction.id !== FormActions.Create.id && (
                  <Box
                    sx={{
                      margin: '1rem 0',
                      border: '2px solid #dddddd',
                    }}
                  >
                    <Typography
                      sx={{
                        backgroundColor: '#dddddd',
                        padding: '1rem',
                      }}
                    >
                      {`${user?.label_job_role} History`}
                    </Typography>
                    <Grid container sx={{ padding: '1rem' }}>
                      <Grid item={true} xs={12}>
                        <Typography>
                          Author: {getJobRoleData?.created_by}
                        </Typography>
                        Creation:{' '}
                        {moment(getJobRoleData?.created_time)
                          .local()
                          .format('YYYY-MM-DD')}
                        <Typography>
                          Last Modified:{' '}
                          {moment(getJobRoleData?.modified_time)
                            .local()
                            .format('YYYY-MM-DD')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                  disabled={isSubmitting}
                  sx={{ marginTop: '1rem' }}
                >
                  {formAction.buttonLabel}
                </Button>
              </Form>
            )}
          </Formik>
        </Grid>
        <Grid item={true} xs={12} lg={6} sx={{ padding: '0 1rem' }}>
          {getJobRoleData ? (
            <ItemJobRoleCapabilityTable
              setFormData={setFormData}
              formData={formData}
              levelObject={getJobRoleData}
              user={user}
              itemTagId={itemTagId}
              formAction={formAction}
            />
          ) : (
            <Typography textAlign={'center'}>No Skill data found</Typography>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export { ItemJobRoleForm };
