import { config } from 'ectl-config';
import { FormDataUtils, getNetworkService } from 'helpers';
import { createProgressParams } from 'helpers/progressUtils';
import { buildFilters } from 'hooks/buildFilter';

const accountService = { config: config };
const networkService = getNetworkService({
  config: config,
  checkAuth: accountService.checkAuth,
});
const baseUrl = `${config.apiUrl}/Admin/Level`;

const getLevels = async (
  level_id = null,
  display_name = '',
  description = '',
  color = '',
  order = null,
  previous_level_id = null,
  previous_level_display_name = '',
  startIndex = 0,
  stopIndex = 10,
  searchString = '',
  sort = 'display_name'
) => {
  const filters = buildFilters(
    ['level_id', level_id],
    ['quick_search', display_name || searchString],
    ['description', description],
    ['color', color],
    ['order', order],
    ['previous_level_id', previous_level_id],
    ['previous_level_display_name', previous_level_display_name]
  );

  const params = {
    filters: filters,
    sorts: sort,
    start_index: startIndex,
    stop_index: stopIndex,
  };

  const query = new URLSearchParams(params);
  const response = await networkService.get(`${baseUrl}/?${query}`);
  const pageInfo = response.headers['x-pagination'];

  return {
    pageInfo: pageInfo,
    levels: response?.data,
  };
};

const create = async (params) => {
  const response = await networkService.post(baseUrl, params);

  if (response.error) {
    return Promise.reject(response.error);
  }

  return response;
};

const update = async (params) => {
  const response = await networkService.put(
    `${baseUrl}/${params.level_id}`,
    params
  );

  if (response.error) {
    return Promise.reject(response.error);
  }

  return response.data;
};

const _delete = async (level_id) => {
  try {
    const response = await networkService.delete(`${baseUrl}/${level_id}`);
    return response;
  } catch (e) {
    return Promise.reject(e.error);
  }
};

const getById = async (id) => {
  const response = await networkService.get(`${baseUrl}/${id}`);
  return response.data;
};

const deleteLevelArray = async (ids) => {
  try {
    const deleteObject = {
      ids: ids,
    };
    const response = await networkService.deleteWithBody(baseUrl, deleteObject);
    return response.data;
  } catch (e) {
    return Promise.reject(e.error);
  }
};

const getLevelUsageById = async (tagId) => {
  const response = await networkService.get(`${baseUrl}/${tagId}`);
  return response.data;
};

const getLevelImportCsv = async () => {
  try {
    const response = await networkService.get(`${baseUrl}/import`);
    return response?.data;
  } catch (err) {
    return Promise.reject(err.error);
  }
};

const uploadLevelImportCsv = async (params) => {
  try {
    let formData = new FormData();
    FormDataUtils.safeAppend(formData, [['file', params.file]]);
    let progressParams = createProgressParams(params);
    const response = await networkService.postMultiFormData(
      `${baseUrl}/import`,
      formData,
      progressParams
    );
    return response.data;
  } catch (err) {
    return err;
  }
};

const exportLevelCsv = async (
  level_id = null,
  display_name = '',
  description = '',
  color = '',
  order = null,
  previous_level_id = null,
  previous_level_display_name = ''
) => {
  try {
    const filters = buildFilters(
      ['level_id', level_id],
      ['display_name', display_name],
      ['description', description],
      ['color', color],
      ['order', order],
      ['previous_level_id', previous_level_id],
      ['previous_level_display_name', previous_level_display_name]
    );

    const params = {
      filters: filters,
    };

    const query = new URLSearchParams(params);
    const response = await networkService.get(`${baseUrl}/export?${query}`);
    return response?.data;
  } catch (err) {
    return Promise.reject(err.error);
  }
};

const getLevelImportHelp = async (level_id = null) => {
  try {
    const filters = [];
    if (level_id) {
      filters.push(`level_id==${level_id}`);
    }
    const params = {
      filters: filters.join(','),
    };
    const query = new URLSearchParams(params);
    if (level_id) {
      const response = await networkService.get(`${baseUrl}/help/?${query}`);
      return response?.data;
    }
    const response = await networkService.get(`${baseUrl}/help`);
    return response?.data;
  } catch (err) {
    return Promise.reject(err.error);
  }
};

export const levelService = {
  getLevels,
  create,
  update,
  delete: _delete,
  deleteLevelArray,
  getById,
  getLevelUsageById,
  getLevelImportCsv,
  uploadLevelImportCsv,
  exportLevelCsv,
  getLevelImportHelp,
};
