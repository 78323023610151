import { createTheme } from '@mui/material/styles';

const colors = {
  primary: '#60A6B3',
  secondary: '#2f393b',
  background: {
    default: '#f4f6f6',
    paper: '#f4f6f6',
    drawer: '#60A6B3',
    lightBlue: '#D9E7ED',
  },
  common: {
    white: '#f4f6f6',
    tableBorder: '#868686',
    darkGray: '#eeeeee',
    grey: '#dddddd',
    headerMain: '#E1E7E8',
  },
  primarySectionForeground: '#FFFFFF',
  secondarySectionBackground: '#000000',
  textWhite: '#ffffff',
  text: '#000000',
  button: {
    primaryForeground: '#FFFFFF',
    secondaryForeground: '#4a4a4a',
  },
  error: '#d42f30',
  warning: '#d9ac27',
};

const layout = {
  drawerWidth: 242,
  appBarHeight: 64,
  appBarPaperHeading: 16,
};

export const mdTheme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
      contained: '#ffffff',
      outlined: '#60A6B3',
    },
    secondary: {
      main: colors.secondary,
    },
    background: colors.background,
    common: colors.common,
  },
  colors: colors,
  layout: layout,
  typography: {
    fontFamily: 'Avenir, sans-serif',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          color: '#ffffff',
          '&:hover': {
            backgroundColor: '#2f393b',
            color: '#ffffff',
          },
        },
        outlinedPrimary: {
          borderColor: '#60A6B3',
          color: '#60A6B3',
          '&:hover': {
            borderColor: '#2f393b',
            backgroundColor: '#2f393b',
            color: '#ffffff',
          },
        },
        root: {
          '&.MuiButton-sizeSmall': {
            fontSize: '0.75rem',
          },
          '&.MuiButton-sizeMedium': {
            fontSize: '0.935rem',
          },
          '&.MuiButton-sizeLarge': {
            fontSize: '1rem',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': {
            fill: '#868686',
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          '& button.MuiButton-root.MuiButton-text': {
            color: '#000000',
          },
          '& button.MuiButton-root.MuiButton-text:hover': {
            color: '#868686',
          },
        },
      },
    },
  },
  overrides: {
    MuiAutocomplete: {
      inputRoot: {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#868686',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: '#868686',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: '#868686',
        },
      },
    },
    MuiFormControl: {
      root: {
        '&.Mui-error .MuiOutlinedInput-notchedOutline': {
          borderColor: '#d32f2f',
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: '#868686',
        '&.Mui-error': {
          color: '#d32f2f',
        },
        '&.Mui-focused': {
          color: '#868686',
          '&.Mui-error': {
            color: '#d32f2f',
          },
        },
      },
    },
  },
});
