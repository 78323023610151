import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  activateLoading,
  deactivateLoading,
} from 'features/global/loadingProgressSlice';
import { LoadingStatus, PAY_GRADE_CACHE } from 'helpers';
import { ItemPayGradesTable } from './ItemPayGradesTable';
import {
  fetchPayGrade,
  getPayGradePageInfo,
  getPayGradeStatus,
  selectPayGrade,
} from 'features/payGrade/payGradeSlice';
import { closePanel } from 'features/common/formSlice';
import {
  selectAdminSettingCache,
  updateAdminSettingCache,
} from 'features/global/visibilitySlice';

const PayGradesManagementTab = () => {
  //#region Constants
  const dispatch = useDispatch();
  //#endregion Constants

  const getCacheData = useSelector(selectAdminSettingCache);

  //#region Hooks
  //#endregion Hooks

  //#region State
  const [itemPayGradePayload, setItemPayGradePayload] = useState({
    ...getCacheData?.[PAY_GRADE_CACHE],
    reset: true,
    startIndex: 0,
    stopIndex: 19,
  });
  const [sortBy, setSortBy] = useState(
    getCacheData?.[PAY_GRADE_CACHE]?.sort?.slice(0, 1) === '-'
      ? getCacheData?.[PAY_GRADE_CACHE]?.sort.slice(1)
      : getCacheData?.[PAY_GRADE_CACHE]?.sort || 'display_name'
  );

  const [sortDirection, setSortDirection] = useState(
    getCacheData?.[PAY_GRADE_CACHE]?.sort?.[0] !== '-' ? 'ASC' : 'DESC'
  );
  //#endregion State

  //#region Selectors
  const payGrades = useSelector(selectPayGrade);
  const itemPayGradeStatus = useSelector(getPayGradeStatus);
  const itemPayGradePageInfo = useSelector(getPayGradePageInfo);
  //#endregion Selectors

  //#region Refs
  const ref = useRef(false);
  //#endregion Refs

  //#region Effects
  useEffect(() => {
    if (ref.current === true) {
      getPayGradeData({ ...itemPayGradePayload });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemPayGradePayload]);

  useEffect(() => {
    if (itemPayGradeStatus === LoadingStatus.Idle) {
      getPayGradeData({
        ...itemPayGradePayload,
        startIndex: 0,
        stopIndex: 19,
      });
    }
    ref.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemPayGradeStatus, dispatch]);
  //#endregion Effects

  //#region Methods
  const getPayGradeData = async (payload) => {
    dispatch(activateLoading());
    await dispatch(fetchPayGrade({ ...payload }));
    dispatch(updateAdminSettingCache({ key: PAY_GRADE_CACHE, payload }));
    dispatch(closePanel({ formKey: `itemPayGradeForm` }));
    dispatch(deactivateLoading());
  };

  const setSorting = (_sortBy, _sortDirection) => {
    setSortBy(_sortBy);
    setSortDirection(_sortDirection);
    setItemPayGradePayload({
      ...itemPayGradePayload,
      sort: `${_sortDirection === 'ASC' ? '' : '-'}${_sortBy}`,
    });
  };
  //#endregion Methods

  //#region Render time calcs
  //#endregion Render time calcs

  //#region Render

  return (
    <>
      <ItemPayGradesTable
        payGrades={payGrades}
        pageInfo={itemPayGradePageInfo}
        itemPayGradePayload={itemPayGradePayload}
        setItemPayGradePayload={setItemPayGradePayload}
        sortDirection={sortDirection}
        sortBy={sortBy}
        handleSort={(_sortBy, _sortDirection) =>
          setSorting(_sortBy, _sortDirection)
        }
      />
    </>
  );
  //#endregion Render
};
export { PayGradesManagementTab };
