import React from 'react';
import { GenerateFilter } from './components';

const GenerateReports = () => {
  //#region Constants
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  //#endregion State

  //#region Selectors
  //#endregion Selectors

  //#region Refs
  //#endregion Refs

  //#region Effects
  //#endregion Effects

  //#region Methods
  //#endregion Methods

  //#region Render time calcs
  //#endregion Render time calcs

  //#region Render

  return <GenerateFilter />;
  //#endregion Render
};
export { GenerateReports };
