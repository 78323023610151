import document from '../assets/document.png';

export const ContentTypes = {
  Pdf: {
    id: 1,
    label: 'Pdf',
    extension: '.pdf',
    img: document,
  },
  Doc: {
    id: 2,
    label: 'Doc',
    extension: '.doc',
    img: document,
  },
  Txt: {
    id: 8,
    label: 'Txt',
    extension: '.txt',
    img: document,
  },
  Pages: {
    id: 9,
    label: 'Pages',
    extension: '.pages',
    img: document,
  },
  Csv: {
    id: 10,
    label: 'Csv',
    extension: '.csv',
    img: document,
  },
};

export const LinkTypes = {
  Document: {
    id: 1,
    label: 'Document',
    contentTypes: [
      ContentTypes.Csv,
      ContentTypes.Pdf,
      ContentTypes.Doc,
      ContentTypes.Pages,
      ContentTypes.Txt,
    ],
    fileSizeLimit: 31457280,
  },
};
