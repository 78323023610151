import React, { useState, useRef, useEffect, useCallback } from 'react';
import { AutoSizer } from 'react-virtualized';
import IconButton from '@mui/material/IconButton';
import { Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { enqueueSnackbar } from 'notistack';
import { MdAdd, MdOutlineRefresh, MdSummarize } from 'react-icons/md';

import SearchWithFilters from 'components/SearchWithFilters';
import {
  actionCell,
  checkCell,
  checkHeaderCell,
  clipLabelTooltipCell,
  labelCell,
  labelHeader,
} from 'shared/components/_Tables/TableCells';
import {
  activateLoading,
  deactivateLoading,
} from 'features/global/loadingProgressSlice';
import { makeButtonStyles } from 'components/styles';
import { VirtualTable } from 'shared/components/_Tables/VirtualTable';
import { EnhancedTableToolbar } from 'components/EnhancedTableToolbar';
import { tableStyles } from 'shared/components/_Tables/VirtualTable/styles';
import { Action } from 'helpers/action';
import { mdTheme } from 'theme';
import { PopupAction } from 'components/_Misc/PopupAction/components/PopupAction';
import {
  closePanel,
  openPanel,
  setEditObjectId,
} from 'features/common/formSlice';

import { NoDataMessage } from 'shared/components/_Tables/NoDataMessage';
import {
  EMPLOYEE_TABLE_KEYS,
  LoadingStatus,
  NO_MATCHING_EMPLOYEES,
} from 'helpers';
import {
  fetchEmployees,
  getEmployeeImportSampleCsv,
  getEmployeeStatus,
  getCertificationImportSampleCsv,
  resetEmployeeState,
  uploadEmployeeImportCsv,
  uploadCertificationImportCsv,
  exportEmployeeCsv,
  fetchEmployeesReport,
  getEmployeeInfoStatus,
  getImportEmployeeHelp,
  resetEmployeeInfoState,
  getImportCertificationHelp,
  resetCertificationInfoState,
  getCertificationInfoStatus,
} from 'features/employee/employeeSlice';
import { ItemEmployeeFilter } from '../ItemEmployeeFilter';
import { DropZoneImport } from 'shared/components/_DropZoneImport';
import { selectActiveUser } from 'features/account/accountSlice';
import { SnackbarDismiss } from 'shared/components/SnackbarDismiss';
import { snackbarHandler } from 'helpers';
import { mapValues } from 'helpers/mapValues';
import { useDebounce } from 'hooks/useDebounce';
import {
  fetchEmployeeDropDown,
  getEmployeeDropDownStatus,
  selectEmployeeDropDown,
} from 'features/AdminSettingsDropDown/dropDownSlice';
import { CustomDialog } from 'shared/components/_Modal';

function ItemEmployeeTable({
  employees,
  pageInfo,
  itemEmployeePayload,
  setItemEmployeePayload,
  sortDirection,
  sortBy,
  handleSort,
}) {
  const dispatch = useDispatch();
  const buttonStyles = makeButtonStyles(mdTheme);
  const tableClasses = tableStyles();
  const [selected, setSelected] = useState([]);
  const [selectedAll, setSelectedAll] = React.useState(false);
  const [dropZoneModalKey, setDropZoneModalKey] = useState(null);
  let updatedPayload = mapValues(itemEmployeePayload);
  const employeeStatus = useSelector(getEmployeeStatus);
  const loadedRowsMap = useSelector(
    (state) => state.employee.loadedEmployeesMap
  );
  const [openHelpDialog, setOpenHelpDialog] = useState(false);

  const getEmployeeImportStatus = useSelector(getEmployeeInfoStatus);
  const getCertificationImportStatus = useSelector(getCertificationInfoStatus);
  const user = useSelector(selectActiveUser);
  const hideEmployeeIdColumn = user?.hide_system_ids;
  const employeeColumnIds = user?.admin_field_ids;

  const headerHeight = 40;
  const rowHeight = 60;

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const prevSelectedAll = usePrevious(selectedAll);
  if (prevSelectedAll && selectedAll) {
    if (selected.length !== employees?.length) {
      setSelected(employees.map((row) => row.employee_id));
      setSelectedAll(true);
    }
  }

  const onRefreshIconClick = async () => {
    await dispatch(fetchEmployees({ ...updatedPayload }));
  };

  const handleClick = (event, employee_id) => {
    event.stopPropagation();
    const selectedIndex = selected.indexOf(employee_id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, employee_id);
    } else {
      newSelected = selected.filter((id) => id !== employee_id);
    }
    setSelected(newSelected);
    setSelectedAll(newSelected.length === employees?.length);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelectedIds = employees.map((tag) => tag.employee_id);
      setSelected(newSelectedIds);
      setSelectedAll(true);
      return;
    }
    setSelected([]);
    setSelectedAll(false);
  };

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const handleRowClick = async ({ rowData }) => {
    await dispatch(closePanel({ formKey: 'itemEmployeeForm' }));
    if (rowData?.employee_id) {
      await dispatch(
        setEditObjectId({
          formKey: `itemEmployeeForm`,
          object_id: rowData?.employee_id,
        })
      );
      dispatch(openPanel({ formKey: `itemEmployeeForm`, formAction: 'Edit' }));
    }
  };

  const handleReportClick = async () => {
    let payload = {};
    if (selectedAll) {
      payload = {
        ...updatedPayload,
      };
    } else {
      payload.employee_id = selected;
    }

    dispatch(activateLoading());
    const response = await dispatch(fetchEmployeesReport(payload));
    if (response?.payload) {
      const { message, variant } = snackbarHandler(
        response?.meta?.requestStatus,
        `${response.payload.headers?.filename ?? 'CSV'} Downloaded`
      );
      enqueueSnackbar(message, {
        action: (key) => <SnackbarDismiss key={key} />,
        variant: variant,
      });
    }
    dispatch(deactivateLoading());
    if (response?.error) {
      const { message, variant } = snackbarHandler(
        response?.error?.message,
        'Download CSV'
      );
      enqueueSnackbar(message, {
        action: (key) => <SnackbarDismiss key={key} />,
        variant: variant,
      });
    }
  };
  const init_columns = [
    {
      classes: tableClasses,
      minWidth: 50,
      maxWidth: 50,
      width: 50,
      label: '',
      dataKey: 'employee_id',
      padding: 'checkbox',
      cellLevel: 'employee',
      numSelected: selected?.length,
      rowCount: employees?.length,
      handleClick: handleClick,
      handleSelectAllClick: handleSelectAllClick,
      isSelected: isSelected,
      component: checkCell,
      headerComponent: checkHeaderCell,
    },
    {
      classes: tableClasses,
      minWidth: 50,
      maxWidth: 50,
      width: 50,
      label: '',
      dataKey: 'employee',
      padding: 'none',
      actionCellLevel: 'adminEmployee',
      targetPopup: 'adminEmployeeManagement',
      formKey: 'itemEmployeeForm',
      dispatch: dispatch,
      component: actionCell,
      headerComponent: labelHeader,
    },
  ];

  const handleColumnVisibility = (key, column) => {
    if (hideEmployeeIdColumn && key === 'employee_id') {
      return undefined;
    }

    const isVisible = employeeColumnIds?.includes(
      EMPLOYEE_TABLE_KEYS?.[key]?.id
    );
    if (isVisible) {
      return column;
    }
  };

  const columns = [
    ...init_columns,
    handleColumnVisibility('employee_id', {
      classes: tableClasses,
      flexGrow: 1,
      width: 120,
      minWidth: 120,
      label: 'Employee Id',
      dataKey: 'employee_id',
      padding: 'normal',
      component: labelCell,
      headerComponent: labelHeader,
      sortDirection: sortDirection,
      sortBy: sortBy,
      handleSort: handleSort,
    }),
    handleColumnVisibility('first_name', {
      classes: tableClasses,
      flexGrow: 1,
      width: 120,
      minWidth: 120,
      label: 'First',
      dataKey: 'first_name',
      padding: 'normal',
      component: labelCell,
      headerComponent: labelHeader,
      sortDirection: sortDirection,
      sortBy: sortBy,
      handleSort: handleSort,
    }),
    handleColumnVisibility('last_name', {
      classes: tableClasses,
      flexGrow: 1,
      width: 120,
      minWidth: 120,
      label: 'Last',
      dataKey: 'last_name',
      padding: 'normal',
      component: labelCell,
      headerComponent: labelHeader,
      sortDirection: sortDirection,
      sortBy: sortBy,
      handleSort: handleSort,
    }),
    handleColumnVisibility('dod_id', {
      classes: tableClasses,
      flexGrow: 1,
      width: 120,
      minWidth: 120,
      label: 'Dod Id',
      dataKey: 'dod_id',
      padding: 'normal',
      component: labelCell,
      headerComponent: labelHeader,
      sortDirection: sortDirection,
      sortBy: sortBy,
      handleSort: handleSort,
    }),
    handleColumnVisibility('pay_plan_display_name', {
      classes: tableClasses,
      flexGrow: 1,
      width: 120,
      minWidth: 120,
      label: 'Pay Plan',
      dataKey: 'pay_plan_display_name',
      padding: 'normal',
      component: labelCell,
      headerComponent: labelHeader,
      sortDirection: sortDirection,
      sortBy: sortBy,
      handleSort: handleSort,
    }),
    handleColumnVisibility('pay_grade_display_name', {
      classes: tableClasses,
      flexGrow: 1,
      width: 120,
      minWidth: 120,
      label: 'Pay Grade',
      dataKey: 'pay_grade_display_name',
      padding: 'normal',
      component: labelCell,
      headerComponent: labelHeader,
      sortDirection: sortDirection,
      sortBy: sortBy,
      handleSort: handleSort,
    }),
    handleColumnVisibility('system_role_display_name', {
      classes: tableClasses,
      flexGrow: 1,
      width: 120,
      minWidth: 120,
      label: 'System Role',
      dataKey: 'system_role_display_name',
      padding: 'normal',
      component: clipLabelTooltipCell,
      headerComponent: labelHeader,
      sortDirection: sortDirection,
      sortBy: sortBy,
      handleSort: handleSort,
    }),
    handleColumnVisibility('supervisor_full_name', {
      classes: tableClasses,
      flexGrow: 1,
      width: 120,
      minWidth: 120,
      label: 'Supervisor',
      dataKey: 'supervisor_full_name',
      padding: 'normal',
      component: clipLabelTooltipCell,
      headerComponent: labelHeader,
      sortDirection: sortDirection,
      sortBy: sortBy,
      handleSort: handleSort,
    }),
    handleColumnVisibility('job_display_name', {
      classes: tableClasses,
      flexGrow: 1,
      width: 120,
      minWidth: 120,
      label: 'Job',
      dataKey: 'job_display_name',
      padding: 'normal',
      component: clipLabelTooltipCell,
      headerComponent: labelHeader,
      sortDirection: sortDirection,
      sortBy: sortBy,
      handleSort: handleSort,
    }),
    handleColumnVisibility('job_role_display_name', {
      classes: tableClasses,
      flexGrow: 1,
      width: 120,
      minWidth: 120,
      label: 'Job Role',
      dataKey: 'job_role_display_name',
      padding: 'normal',
      component: clipLabelTooltipCell,
      headerComponent: labelHeader,
      sortDirection: sortDirection,
      sortBy: sortBy,
      handleSort: handleSort,
    }),
  ].filter(Boolean);

  // eslint-disable-next-line no-unused-vars
  async function loadMoreRows({ startIndex, stopIndex }) {
    if (employeeStatus !== LoadingStatus.Loading) {
      await dispatch(
        fetchEmployees({
          ...updatedPayload,
          reset: false,
          startIndex: startIndex,
          stopIndex: stopIndex,
        })
      );
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    useDebounce((searchString) => {
      setItemEmployeePayload({
        ...itemEmployeePayload,
        searchString: searchString,
      });
    }, 1000),
    [itemEmployeePayload]
  );

  const handleCreateNew = () => {
    dispatch(openPanel({ formKey: 'itemEmployeeForm', formAction: 'Create' }));
  };

  const importInfoModalProps = useCallback(() => {
    if (dropZoneModalKey === 'Employee') {
      return {
        getEntityHelp: getImportEmployeeHelp,
        fieldName: 'employee_id',
        selectDropdownEntity: selectEmployeeDropDown,
        getDropdownStatusEntity: getEmployeeDropDownStatus,
        fetchDropDownEntityPage: fetchEmployeeDropDown,
        openHelpDialog: openHelpDialog,
        setOpenHelpDialog: setOpenHelpDialog,
        getEntityHelpStatus: getEmployeeImportStatus,
        selectLabel: 'Select Employee',
        resetHelpEntity: resetEmployeeInfoState,
      };
    }
    if (dropZoneModalKey === 'Certification') {
      return {
        getEntityHelp: getImportCertificationHelp,
        fieldName: 'employee_id',
        selectDropdownEntity: selectEmployeeDropDown,
        getDropdownStatusEntity: getEmployeeDropDownStatus,
        fetchDropDownEntityPage: fetchEmployeeDropDown,
        openHelpDialog: openHelpDialog,
        setOpenHelpDialog: setOpenHelpDialog,
        getEntityHelpStatus: getCertificationImportStatus,
        selectLabel: 'Select Employee',
        resetHelpEntity: resetCertificationInfoState,
      };
    }
  }, [
    dropZoneModalKey,
    getCertificationImportStatus,
    getEmployeeImportStatus,
    openHelpDialog,
  ]);

  return (
    <div className={tableClasses.root}>
      <ItemEmployeeFilter
        setItemEmployeePayload={setItemEmployeePayload}
        itemEmployeePayload={itemEmployeePayload}
      />
      {selected.length > 0 && (
        <EnhancedTableToolbar
          numSelected={selected.length}
          toolTipTitle={'Delete'}
          totalCount={pageInfo?.TotalCount}
        >
          <IconButton onClick={() => handleReportClick()} aria-label="close">
            <Tooltip title={`Employee Reports`}>
              <MdSummarize />
            </Tooltip>
          </IconButton>
          <PopupAction
            action={Action.DeleteArray}
            object={{
              employee_id: selected,
              setEmployeeIds: setSelected,
            }}
            level={'adminEmployee'}
            showLabel={false}
          />
        </EnhancedTableToolbar>
      )}
      <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
        <Tooltip title="Add New Employee">
          <IconButton
            onClick={handleCreateNew}
            size="large"
            className={buttonStyles.contentAddButton}
          >
            <MdAdd />
          </IconButton>
        </Tooltip>
        <div style={{ flexGrow: 1 }}></div>
        <DropZoneImport
          key="certification-dzi"
          getEntityImportSample={getCertificationImportSampleCsv}
          uploadEntityState={uploadCertificationImportCsv}
          resetEntitySelector={resetEmployeeState}
          mainTitle="Certification"
          buttonText="Import Certifications"
          setOpenHelpDialog={setOpenHelpDialog}
          isInfoIcon
          setDropZoneModalKey={setDropZoneModalKey}
        />
        <CustomDialog {...importInfoModalProps()} />
        <DropZoneImport
          key="employee-dzi"
          getEntityImportSample={getEmployeeImportSampleCsv}
          uploadEntityState={uploadEmployeeImportCsv}
          resetEntitySelector={resetEmployeeState}
          mainTitle="Employee"
          exportEntityState={exportEmployeeCsv}
          exportFilterData={updatedPayload}
          setOpenHelpDialog={setOpenHelpDialog}
          isExport
          isInfoIcon
          setDropZoneModalKey={setDropZoneModalKey}
        />
        <IconButton
          onClick={onRefreshIconClick}
          aria-label="close"
          sx={{
            color: '#707070',
          }}
          size="large"
        >
          <MdOutlineRefresh style={{ fontSize: '18px', color: 'black' }} />
        </IconButton>
        <SearchWithFilters
          filter={false}
          onChange={(evt) => debouncedSearch(evt.target.value)}
          value={itemEmployeePayload?.searchString}
        />
      </div>
      {employees.length > 0 ? (
        <div
          style={{
            height: 'calc(100vh - 295px)',
            minWidth: '500px',
            paddingTop: '2rem',
          }}
        >
          <AutoSizer>
            {({ width, height }) => (
              <VirtualTable
                initialRows={employees}
                allRows={employees}
                totalRowCount={pageInfo?.TotalCount}
                loadedRowsMap={loadedRowsMap}
                loadMoreRows={loadMoreRows}
                columnDefinitions={columns}
                cellClassName={tableClasses.flexContainer}
                className={tableClasses.table}
                rowClassName={tableClasses.flexContainer}
                headerHeight={headerHeight}
                width={width}
                height={height}
                rowHeight={rowHeight}
                onRowClick={handleRowClick}
              />
            )}
          </AutoSizer>
        </div>
      ) : (
        <NoDataMessage message={NO_MATCHING_EMPLOYEES} />
      )}
    </div>
  );
}

export { ItemEmployeeTable };
