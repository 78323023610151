import { config } from 'ectl-config';
import { FormDataUtils, getNetworkService } from 'helpers';
import { createProgressParams } from 'helpers/progressUtils';

const accountService = { config: config };
const networkService = getNetworkService({
  config: config,
  checkAuth: accountService.checkAuth,
});
const baseUrl = `${config.apiUrl}/Admin/CostCenter`;

const getCostCenters = async (
  startIndex = 0,
  stopIndex = 10,
  searchString = '',
  sort = 'psntl',
  cc_id = '',
  psntl = '',
  cost_center_id = null,
  office_id = null
) => {
  const filters = [];
  if (searchString) {
    filters.push(`quick_search@=*${searchString}`);
  }
  if (cc_id) {
    filters.push(`cc_id@=*${cc_id}`);
  }
  if (psntl) {
    filters.push(`psntl@=*${psntl}`);
  }
  if (cost_center_id) {
    filters.push(`cost_center_id==${cost_center_id}`);
  }
  if (office_id) {
    filters.push(`office_id==${office_id}`);
  }

  const params = {
    filters: filters.join(','),
    sorts: sort || '',
    start_index: startIndex,
    stop_index: stopIndex,
  };
  const query = new URLSearchParams(params);
  const response = await networkService.get(`${baseUrl}/?${query}`);
  const pageInfo = response.headers['x-pagination'];

  return {
    pageInfo: pageInfo,
    costCenters: response?.data,
  };
};

const create = async (params) => {
  const response = await networkService.post(baseUrl, params);

  if (response.error) {
    return Promise.reject(response.error);
  }

  return response;
};

const update = async (params) => {
  const response = await networkService.put(
    `${baseUrl}/${params.cost_center_id}`,
    params
  );

  if (response.error) {
    return Promise.reject(response.error);
  }

  return response.data;
};

const _delete = async (cost_center_id) => {
  try {
    const response = await networkService.delete(
      `${baseUrl}/${cost_center_id}`
    );
    return response;
  } catch (e) {
    return Promise.reject(e.error);
  }
};

const getById = async (id) => {
  const response = await networkService.get(`${baseUrl}/${id}`);
  return response.data;
};

const deleteArray = async (ids) => {
  try {
    const deleteObject = {
      ids: ids,
    };
    const response = await networkService.deleteWithBody(baseUrl, deleteObject);
    return response.data;
  } catch (e) {
    return Promise.reject(e.error);
  }
};

const getCostCenterUsageById = async (tagId) => {
  const response = await networkService.get(`${baseUrl}/${tagId}`);
  return response.data;
};

const getCostCenterImportCsv = async () => {
  try {
    const response = await networkService.get(`${baseUrl}/import`);
    return response?.data;
  } catch (err) {
    return Promise.reject(err.error);
  }
};

const uploadCostCenterImportCsv = async (params) => {
  try {
    let formData = new FormData();
    FormDataUtils.safeAppend(formData, [['file', params.file]]);
    let progressParams = createProgressParams(params);
    const response = await networkService.postMultiFormData(
      `${baseUrl}/import`,
      formData,
      progressParams
    );
    return response.data;
  } catch (err) {
    return err;
  }
};

const exportCostCenterCsv = async (cc_id = '', psntl = '') => {
  try {
    const filters = [];

    if (cc_id) {
      filters.push(`cc_id@=*${cc_id}`);
    }
    if (psntl) {
      filters.push(`psntl@=*${psntl}`);
    }

    const params = {
      filters: filters.join(','),
    };
    const query = new URLSearchParams(params).toString();
    const response = await networkService.get(`${baseUrl}/export?${query}`);
    return response?.data;
  } catch (err) {
    return Promise.reject(err.error);
  }
};

const getCostCenterImportHelp = async (cost_center_id = null) => {
  try {
    const filters = [];
    if (cost_center_id) {
      filters.push(`cost_center_id==${cost_center_id}`);
    }
    const params = {
      filters: filters.join(','),
    };
    const query = new URLSearchParams(params);
    if (cost_center_id) {
      const response = await networkService.get(`${baseUrl}/help/?${query}`);
      return response?.data;
    }
    const response = await networkService.get(`${baseUrl}/help`);
    return response?.data;
  } catch (err) {
    return Promise.reject(err.error);
  }
};

export const costCenterService = {
  getCostCenters,
  create,
  update,
  delete: _delete,
  deleteArray,
  getById,
  getCostCenterUsageById,
  getCostCenterImportCsv,
  uploadCostCenterImportCsv,
  exportCostCenterCsv,
  getCostCenterImportHelp,
};
