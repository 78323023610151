import React, { useCallback, useState } from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    TextField,
    Typography,
} from '@mui/material';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { AutoSizer } from 'react-virtualized';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { enqueueSnackbar } from 'notistack';
import { MdClose } from 'react-icons/md';

import { NoDataMessage } from 'shared/components/_Tables/NoDataMessage';
import {
    checkHeaderCell,
    clipLabelTooltipCell,
    conditionalCheckCell,
    labelCell,
    labelHeader,
} from 'shared/components/_Tables/TableCells';
import { VirtualTable } from 'shared/components/_Tables/VirtualTable';
import { tableStyles } from 'shared/components/_Tables/VirtualTable/styles';
import {
    fetchGroupTraining,
    getGroupTrainingStatus,
    resetGroupTrainingState,
    updateGroupTraining,
} from 'features/groupTraining/groupTrainingSlice';
import {
    activateLoading,
    deactivateLoading,
} from 'features/global/loadingProgressSlice';
import { SnackbarDismiss } from 'shared/components/SnackbarDismiss';
import { LoadingStatus, NO_MATCHING_EMPLOYEES, snackbarHandler } from 'helpers';
import { selectActiveUser } from 'features/account/accountSlice';
import { GroupTrainingFilter } from '../GroupTrainingFilter';
import { padNumberWithZeros } from 'hooks/PadNumberWithZeros';
import pluralize from 'pluralize';
import { useDebounce } from 'hooks/useDebounce';
import { resetDropDown } from 'features/AdminSettingsDropDown/dropDownSlice';
import { EnhancedTableToolbar } from 'components/EnhancedTableToolbar';

const GroupAttendeesTable = ({
    groupTrainingData,
    pageInfo,
    setGroupTrainingPayload,
    groupTrainingPayload,
    setIsAttendTable,
    user,
    sortDirection,
    sortBy,
    handleSort,
    capSkillData,
}) => {
    //#region Constants
    const tableClasses = tableStyles();
    const dispatch = useDispatch();
    const headerHeight = 60;
    const rowHeight = 60;
    const isSelected = (id) => selected.indexOf(id) !== -1;
    //#endregion Constants

    //#region Hooks
    //#endregion Hooks

    //#region State
    const [selected, setSelected] = useState([]);
    const [selectedAll, setSelectedAll] = React.useState(false);
    const [isCompletionModal, setIsCompletionModal] = useState(false);
    const [createdDateFilter, setCreatedDateFilter] = useState(
        moment(new Date())
    );
    const [userFilter, setUserFilter] = useState([]);
    const [statusFilter, setStatusFilter] = useState([]);
    const [hasIssueFilter, setHasIssueFilter] = useState('');
    const [primaryIdFilter, setPrimaryIdFilter] = useState('');
    const [secondaryIdFilter, setSecondaryIdFilter] = useState('');
    const [filterTableData, setFilterTableData] = useState({
        employee_name: '',
        employee_dod_id: null,
        series_id: null,
        job_role: '',
        supervisor_name: '',
        cost_center: null,
        directorate: '',
        include_employee_ids: [],
    });
    //#endregion State

    //#region Selectors
    const loadedRowsMap = useSelector(
        (state) => state.groupTraining.loadedGroupTrainingMap
    );
    const activeUser = useSelector(selectActiveUser);
    const groupTrainingStatus = useSelector(getGroupTrainingStatus);
    //#endregion Selectors

    //#region Refs
    //#endregion Refs

    //#region Effects
    //#endregion Effects

    //#region Methods
    const debouncedSearch = useCallback(
        useDebounce(
            (
                status,
                hasIssue,
                searchByPrimaryId,
                searchBySecondaryId,
                createdDate,
                user
            ) => {
                setGroupTrainingPayload({
                    ...groupTrainingPayload,
                    reset: true,
                    startIndex: 0,
                    stopIndex: 19,
                    status: status,
                    hasIssue: hasIssue,
                    searchByPrimaryId: searchByPrimaryId,
                    searchBySecondaryId: searchBySecondaryId,
                    createdDate:
                        createdDate &&
                        moment(createdDate)
                            .utc()
                            .startOf('day')
                            .format('YYYY-MM-DD'),
                    createdById: user,
                });
            },
            1000
        ),
        [groupTrainingPayload]
    );
    const clearFilters = () => {
        setStatusFilter([]);
        setHasIssueFilter('');
        setPrimaryIdFilter('');
        setSecondaryIdFilter('');
        setCreatedDateFilter(moment(new Date()));
        setUserFilter([]);
        setGroupTrainingPayload({
            ...groupTrainingPayload,
            capability_skill_ids: groupTrainingPayload?.capability_skill_ids,
            capability_id: groupTrainingPayload?.capability_id,
            startIndex: 0,
            stopIndex: 19,
            status: [],
            hasIssue: '',
            searchByPrimaryId: '',
            searchBySecondaryId: '',
            createdDate: moment(new Date()),
            createdById: '',
        });
    };

    const handleClick = (event, employee_id) => {
        event.stopPropagation();
        const selectedIndex = selected.indexOf(employee_id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, employee_id);
        } else {
            newSelected = selected.filter((id) => id !== employee_id);
        }
        setSelected(newSelected);
        setSelectedAll(newSelected.length === groupTrainingData?.length);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelectedIds = groupTrainingData?.map(
                (employee) => employee.employee_id
            );
            setSelected(newSelectedIds);
            setSelectedAll(true);
            return;
        }
        setSelected([]);
        setSelectedAll(false);
    };

    const handleDateModal = () => {
        setIsCompletionModal(true);
    };

    const submitData = async () => {
        const getCompletionDate = createdDateFilter.toISOString();
        const getSelectedEmployeeIds = selected;
        const getPrevSkillIds = groupTrainingPayload?.capability_skill_ids;
        const getCapabilityId = groupTrainingPayload?.capability_id;
        const SubmitData = {
            completion_date: getCompletionDate ? getCompletionDate : '',
            capability_skill_ids: getPrevSkillIds ? getPrevSkillIds : [],
            employee_ids: getSelectedEmployeeIds ? getSelectedEmployeeIds : [],
            capability_id: getCapabilityId,
        };
        await dispatch(activateLoading());
        try {
            var altLabel = 'Group Training Update';
            let resultAction = await dispatch(updateGroupTraining(SubmitData));

            if (!resultAction.error) {
                let altMessage = 'Successfully';
                const { message, variant } = snackbarHandler(
                    resultAction.meta.requestStatus,
                    altLabel,
                    altMessage
                );
                enqueueSnackbar(message, {
                    action: (key) => <SnackbarDismiss snackItem={key} />,
                    variant: variant,
                });
                dispatch(resetGroupTrainingState());
                dispatch(resetDropDown());
                setIsCompletionModal(false);
                setIsAttendTable(false);
            } else {
                let altMessage = 'Failed';
                const { message, variant } = snackbarHandler(
                    resultAction.meta.requestStatus,
                    altLabel,
                    altMessage
                );
                enqueueSnackbar(message, {
                    action: (key) => <SnackbarDismiss snackItem={key} />,
                    variant: variant,
                });
            }
        } catch (err) {
            enqueueSnackbar(
                err?.message ? err.message : `Group Training Update failed`,
                {
                    action: (key) => <SnackbarDismiss snackItem={key} />,
                    variant: 'error',
                }
            );
        }
        dispatch(deactivateLoading());
    };

    // eslint-disable-next-line no-unused-vars
    async function loadMoreRows({ startIndex, stopIndex }) {
        if (groupTrainingStatus !== LoadingStatus.Loading) {
            await dispatch(
                fetchGroupTraining({
                    ...groupTrainingPayload,
                    reset: false,
                    startIndex: startIndex,
                    stopIndex: stopIndex,
                })
            );
        }
    }

    const columns = [
        {
            classes: tableClasses,
            minWidth: 200,
            width: 200,
            flexGrow: 1,
            label: 'Name',
            dataKey: 'employee_full_name',
            padding: 'none',
            cellLevel: 'employee_full_name',
            numSelected: selected.length,
            rowCount: groupTrainingData?.length,
            setId: 'employee',
            textColor: 'red',
            align: 'center',
            component: clipLabelTooltipCell,
            headerComponent: labelHeader,
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
        },
        {
            classes: tableClasses,
            minWidth: 200,
            width: 200,
            flexGrow: 1,
            label: 'Series',
            dataKey: 'job_series_id',
            padding: 'none',
            cellLevel: 'job_series_id',
            align: 'center',
            numSelected: selected.length,
            rowCount: groupTrainingData?.length,
            transform: (data) => padNumberWithZeros(data),
            component: labelCell,
            headerComponent: labelHeader,
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
        },
        {
            classes: tableClasses,
            minWidth: 200,
            width: 120,
            flexGrow: 1,
            label: activeUser?.label_job_role,
            dataKey: 'employee_job_role',
            padding: 'none',
            cellLevel: 'employee_job_role',
            align: 'center',
            numSelected: selected.length,
            rowCount: groupTrainingData?.length,
            component: clipLabelTooltipCell,
            headerComponent: labelHeader,
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
        },
        {
            classes: tableClasses,
            minWidth: 200,
            width: 200,
            flexGrow: 1,
            label: 'Cost Center',
            dataKey: 'cost_center_name',
            padding: 'none',
            cellLevel: 'cost_center_name',
            align: 'center',
            numSelected: selected.length,
            rowCount: groupTrainingData?.length,
            component: labelCell,
            headerComponent: labelHeader,
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
        },
        {
            classes: tableClasses,
            minWidth: 200,
            width: 200,
            flexGrow: 1,
            label: 'Directorate',
            dataKey: 'directorate',
            padding: 'none',
            cellLevel: 'directorate',
            align: 'center',
            numSelected: selected.length,
            rowCount: groupTrainingData?.length,
            component: labelCell,
            headerComponent: labelHeader,
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
        },
        {
            classes: tableClasses,
            maxWidth: 120,
            width: 120,
            label: 'Attended',
            dataKey: 'employee_id',
            padding: 'checkbox',
            cellLevel: 'employee',
            numSelected: selected.length,
            rowCount: groupTrainingData?.length,
            handleClick: handleClick,
            handleSelectAllClick: handleSelectAllClick,
            isSelected: isSelected,
            component: conditionalCheckCell,
            headerComponent: checkHeaderCell,
            sortDirection: sortDirection,
            sortBy: sortBy,
            handleSort: handleSort,
        },
    ];

    const setSkillName =
        capSkillData?.skills?.length > 0
            ? capSkillData.skills.join(', ')
            : capSkillData?.skills;
    //#endregion Methods

    //#region Render time calcs
    //#endregion Render time calcs

    //#region Render

    return (
        <div className={tableClasses.root}>
            <>
                <Grid container>
                    <Grid item={true} xs={12} lg={9} sx={{ padding: '1rem 0' }}>
                        <Typography variant="body1" marginBottom="1rem">
                            Below are "{capSkillData?.capability}"{' '}
                            {pluralize(user?.label_skill, capSkillData?.length)}{' '}
                            to be verified.
                        </Typography>
                        <Typography variant="body1">{setSkillName}</Typography>
                    </Grid>
                    <Grid
                        item={true}
                        xs={6}
                        lg={3}
                        sx={{ padding: '0 1rem', textAlign: 'center' }}
                    >
                        <Typography variant="h5" marginBottom="1rem">
                            {capSkillData?.capability}
                        </Typography>
                        <Button
                            size="large"
                            variant="contained"
                            color="primary"
                            fullWidth
                            disabled={selected.length > 0 ? false : true}
                            onClick={() => handleDateModal()}
                            sx={{ marginTop: '0.5rem' }}
                        >
                            Submit Certification
                        </Button>
                    </Grid>
                </Grid>
                <GroupTrainingFilter
                    groupTrainingPayload={groupTrainingPayload}
                    setGroupTrainingPayload={setGroupTrainingPayload}
                    filterTableData={filterTableData}
                    setFilterTableData={setFilterTableData}
                />
                {selected?.length > 0 && (
                    <EnhancedTableToolbar
                        numSelected={selected.length}
                        totalCount={pageInfo?.TotalCount}
                    />
                )}
                {groupTrainingData?.length > 0 ? (
                    <div
                        style={{
                            height: 'calc(100vh - 295px)',
                            overflow: 'auto hidden',
                        }}
                    >
                        <AutoSizer>
                            {({ width, height }) => (
                                <VirtualTable
                                    initialRows={groupTrainingData}
                                    allRows={groupTrainingData}
                                    totalRowCount={pageInfo?.TotalCount}
                                    loadedRowsMap={loadedRowsMap}
                                    loadMoreRows={loadMoreRows}
                                    columnDefinitions={columns}
                                    cellClassName={tableClasses.flexContainer}
                                    className={tableClasses.table}
                                    rowClassName={tableClasses.flexContainer}
                                    headerHeight={headerHeight}
                                    width={width}
                                    height={height}
                                    rowHeight={rowHeight}
                                />
                            )}
                        </AutoSizer>
                    </div>
                ) : (
                    <NoDataMessage message={NO_MATCHING_EMPLOYEES} />
                )}
            </>
            {isCompletionModal && (
                <Dialog
                    open={isCompletionModal}
                    onClose={() => {}}
                    maxWidth={'sm'}
                    fullWidth
                >
                    <DialogTitle>
                        {capSkillData?.capability}{' '}
                        {pluralize(user?.label_skill, capSkillData?.length)}{' '}
                        <IconButton
                            aria-label="close"
                            onClick={() => {
                                setIsCompletionModal(false);
                            }}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 6,
                                color: '#000',
                                fontSize: '1.4rem',
                            }}
                            size="large"
                        >
                            <MdClose />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText
                            marginTop={'0.5rem'}
                            marginBottom={'1rem'}
                        >
                            Please enter the data of completion
                        </DialogContentText>
                        <fieldset
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: '1rem',
                            }}
                        >
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    sx={{ width: '100%' }}
                                    label="Date"
                                    value={createdDateFilter}
                                    format="YYYY-MM-DD"
                                    disableFuture
                                    onChange={(newValue) => {
                                        if (
                                            moment(newValue)
                                                .utc()
                                                .startOf('day')
                                                .format('YYYY-MM-DD') !==
                                            'Invalid date'
                                        ) {
                                            setCreatedDateFilter(newValue);
                                            debouncedSearch(
                                                statusFilter,
                                                hasIssueFilter,
                                                primaryIdFilter,
                                                secondaryIdFilter,
                                                newValue,
                                                userFilter
                                            );
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            variant="outlined"
                                            {...params}
                                            sx={{
                                                minWidth: '150px',
                                                width: '100%',
                                            }}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                            <IconButton
                                aria-label="close"
                                onClick={clearFilters}
                                sx={{ padding: 0, paddingLeft: '0.50.rem' }}
                                size="small"
                            >
                                <MdClose className="react-icon" />
                            </IconButton>
                        </fieldset>
                        <Button
                            size="large"
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => submitData()}
                            sx={{ marginTop: '0.5rem' }}
                        >
                            Submit
                        </Button>
                    </DialogContent>
                </Dialog>
            )}
        </div>
    );
    //#endregion Render
};
export { GroupAttendeesTable };
