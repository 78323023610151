import { useCallback, useEffect, useRef, useState } from 'react';
import { Grid } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { SelectionAutoComplete } from 'shared/components/_Autocomplete/SelectionAutocomplete';
import {
  fetchCapabilityDropDown,
  fetchJobsDropDown,
  getCapabilityDropDownStatus,
  getJobDropDownStatus,
  selectCapabilitiesDropDown,
  selectJobDropDown,
} from 'features/AdminSettingsDropDown/dropDownSlice';
import { selectActiveUser } from 'features/account/accountSlice';
import { FilterAccordion } from 'shared/components/_Accordion';
import { clearFilter } from 'helpers/clearFilter';
import {
  selectAdminSettingCache,
  updateAdminSettingCache,
} from 'features/global/visibilitySlice';
import { useDebounce } from 'hooks/useDebounce';
import { JOB_ROLE_CACHE } from 'helpers';
import { FormTextField } from 'shared/components/FormFields/TextField';
import { sanitizeEnotationInput } from 'hooks/validateAndFormateColor';

const ItemJobRoleFilter = ({ setItemJobRolePayload, itemJobRolePayload }) => {
  //#region Constants
  const getCacheData = useSelector(selectAdminSettingCache);
  const jobRoleCacheData = getCacheData?.[JOB_ROLE_CACHE];
  const dispatch = useDispatch();
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  const [initValues, setInitValues] = useState({
    display_name: '',
    job_id: null,
    job_series_id: '',
    capability_ids: null,
  });
  //#endregion State

  //#region Selectors
  const user = useSelector(selectActiveUser);
  //#endregion Selectors

  //#region Refs
  const formRef = useRef();
  //#endregion Refs

  //#region Effects
  useEffect(() => {
    setInitValues({ ...initValues, ...jobRoleCacheData });
  }, [getCacheData, itemJobRolePayload]);

  //#endregion Effects

  //#region Methods
  const updateFileds = useCallback(
    useDebounce((displayName, jobId, jobSeriesId, capabilityIds) => {
      setItemJobRolePayload({
        ...itemJobRolePayload,
        display_name: displayName,
        job_id: jobId,
        job_series_id: jobSeriesId,
        capability_ids: capabilityIds,
      });
    }, 1000),
    [itemJobRolePayload]
  );

  const handleResetFilters = () => {
    const isFilterData = clearFilter(formRef);
    if (isFilterData) {
      formRef?.current?.resetForm();
      setItemJobRolePayload({
        ...itemJobRolePayload,
        display_name: '',
        job_id: null,
        job_series_id: '',
        capability_ids: null,
      });
      dispatch(updateAdminSettingCache({ key: JOB_ROLE_CACHE, ...{} }));
    }
  };
  //#endregion Methods

  //#region Render time calcs
  //#endregion Render time calcs

  //#region Render

  return (
    <>
      <FilterAccordion
        filteredTitles={itemJobRolePayload}
        expandedId="job-role"
        styles={{ marginBottom: '1.5rem' }}
        onResetFilters={handleResetFilters}
      >
        <Formik
          enableReinitialize={true}
          initialValues={{ ...initValues }}
          innerRef={formRef}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Grid container columnSpacing={'1rem'}>
                <Grid item xs={12} md={6} lg={3}>
                  <FormTextField
                    name="display_name"
                    label="Display Name"
                    placeholder="Display Name"
                    fullWidth
                    onChange={(e) => {
                      const searchTerm = e.target.value;
                      setFieldValue('display_name', searchTerm);
                      updateFileds(
                        searchTerm,
                        values?.job_id,
                        values?.job_series_id,
                        values?.capability_ids
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <Field name="job_id">
                    {({ field: { value }, form: { setFieldValue } }) => (
                      <SelectionAutoComplete
                        title={`Select ${user.label_job}`}
                        keyProperty="value"
                        nameProperty="text"
                        entityIds={value?.value}
                        setEntityIds={setFieldValue}
                        entitySelector={selectJobDropDown}
                        entityStatusSelector={getJobDropDownStatus}
                        onCustomChange={(value) => {
                          setFieldValue('job_id', value);
                          updateFileds(
                            values?.display_name,
                            value,
                            values?.job_series_id,
                            values?.capability_ids
                          );
                        }}
                        fetchEntityPage={fetchJobsDropDown}
                        cancelItemTagEdit={() => {
                          setFieldValue('job_id', null);
                          updateFileds(
                            values?.display_name,
                            null,
                            values?.job_series_id,
                            values?.capability_ids
                          );
                        }}
                        showCloseItemEdit={true}
                        formField="job_id"
                        multiSelection={false}
                        hideCheckIcon={true}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormTextField
                    name="job_series_id"
                    label="Job Series Id"
                    placeholder="Job Series Id shdsahdhjk"
                    fullWidth
                    onChange={(e) => {
                      const searchTerm = e.target.value;
                      const filterValue = sanitizeEnotationInput(searchTerm);
                      setFieldValue('job_series_id', filterValue);
                      updateFileds(
                        values?.display_name,
                        values?.job_id,
                        filterValue,
                        values?.capability_ids
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <Field name="capability_ids">
                    {({ field: { value }, form: { setFieldValue } }) => (
                      <SelectionAutoComplete
                        title={`Select ${user.label_capability}`}
                        keyProperty="value"
                        nameProperty="text"
                        entityIds={map(value, 'value')}
                        setEntityIds={setFieldValue}
                        entitySelector={selectCapabilitiesDropDown}
                        entityStatusSelector={getCapabilityDropDownStatus}
                        onCustomChange={(value) => {
                          setFieldValue('capability_ids', value);
                          updateFileds(
                            values?.display_name,
                            values?.job_id,
                            values?.job_series_id,
                            value
                          );
                        }}
                        fetchEntityPage={fetchCapabilityDropDown}
                        formField="capability_ids"
                        multiSelection={true}
                        showCloseItemEdit={true}
                        hideCheckIcon={true}
                        cancelItemTagEdit={() => {
                          setFieldValue('capability_ids', null);
                          updateFileds(
                            values?.display_name,
                            values?.job_id,
                            values?.job_series_id,
                            null
                          );
                        }}
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </FilterAccordion>
    </>
  );
  //#endregion Render
};
export { ItemJobRoleFilter };
