import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  activateLoading,
  deactivateLoading,
} from 'features/global/loadingProgressSlice';
import { EMPLOYEE_CACHE, LoadingStatus } from 'helpers';

import { closePanel } from 'features/common/formSlice';
import { ItemEmployeeTable } from './ItemEmployeeTable';
import {
  fetchEmployees,
  getEmployeePageInfo,
  getEmployeeStatus,
  resetEmployeeState,
  selectEmployee,
} from 'features/employee/employeeSlice';
import {
  selectAdminSettingCache,
  updateAdminSettingCache,
} from 'features/global/visibilitySlice';
import { mapValues } from 'helpers/mapValues';
import { resetDropDownState } from 'features/AdminSettingsDropDown/dropDownSlice';

const EmployeeManagementTab = () => {
  //#region Constants
  const dispatch = useDispatch();
  const getCacheData = useSelector(selectAdminSettingCache);

  const getEmployeesData = async (payload) => {
    dispatch(activateLoading());
    let updatedPayload = mapValues(payload);
    await dispatch(fetchEmployees({ ...updatedPayload }));
    dispatch(updateAdminSettingCache({ key: EMPLOYEE_CACHE, payload }));
    dispatch(closePanel({ formKey: `itemEmployeeForm` }));
    dispatch(deactivateLoading());
  };
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  const [itemEmployeePayload, setItemEmployeePayload] = useState({
    ...getCacheData?.[EMPLOYEE_CACHE],
    reset: true,
    startIndex: 0,
    stopIndex: 19,
  });
  const [sortBy, setSortBy] = useState(
    getCacheData?.[EMPLOYEE_CACHE]?.sort?.slice(0, 1) === '-'
      ? getCacheData?.[EMPLOYEE_CACHE]?.sort.slice(1)
      : getCacheData?.[EMPLOYEE_CACHE]?.sort || 'first_name'
  );

  const [sortDirection, setSortDirection] = useState(
    getCacheData?.[EMPLOYEE_CACHE]?.sort?.[0] !== '-' ? 'ASC' : 'DESC'
  );
  //#endregion State

  //#region Selectors
  const employees = useSelector(selectEmployee);
  const itemEmployeeStatus = useSelector(getEmployeeStatus);
  const itemEmployeePageInfo = useSelector(getEmployeePageInfo);
  //#endregion Selectors

  //#region Refs
  const ref = useRef(false);
  //#endregion Refs

  //#region Effects
  useEffect(() => {
    if (ref.current === true) {
      getEmployeesData({ ...itemEmployeePayload });
    }
    ref.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemEmployeePayload]);

  useEffect(() => {
    if (itemEmployeeStatus === LoadingStatus.Idle) {
      getEmployeesData({
        ...itemEmployeePayload,
        reset: true,
        startIndex: 0,
        stopIndex: 19,
      });
    }
    ref.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemEmployeeStatus, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetEmployeeState());
      dispatch(resetDropDownState());
    };
  }, []);

  //#endregion Effects

  //#region Methods
  const setSorting = (_sortBy, _sortDirection) => {
    setSortBy(_sortBy);
    setSortDirection(_sortDirection);
    setItemEmployeePayload({
      ...itemEmployeePayload,
      sort: `${_sortDirection === 'ASC' ? '' : '-'}${_sortBy}`,
    });
  };
  //#endregion Methods

  //#region Render time calcs
  //#endregion Render time calcs

  //#region Render

  return (
    <>
      <ItemEmployeeTable
        employees={employees}
        pageInfo={itemEmployeePageInfo}
        itemEmployeePayload={itemEmployeePayload}
        setItemEmployeePayload={setItemEmployeePayload}
        sortDirection={sortDirection}
        sortBy={sortBy}
        handleSort={(_sortBy, _sortDirection) =>
          setSorting(_sortBy, _sortDirection)
        }
      />
    </>
  );
  //#endregion Render
};
export { EmployeeManagementTab };
