import { Routes, Route } from 'react-router-dom';
import { AuthenticatedTemplate } from 'components/_Layout/AuthenticatedTemplate/AuthenticatedTemplate';
import { AnonymousTemplate } from 'components/_Layout/AnonymousTemplate/AnonymousTemplate';
import {
  setAppIsLoading,
  refreshUserToken,
  selectActiveUser,
} from 'features/account/accountSlice';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SceneDefinitions } from 'scenes/scene.definitions';
import { isFormOpen } from 'features/common/formSlice';
import {
  isAboutModalOpen,
  isDodModalOpen,
  selectActionModalInfo,
} from 'features/common/actionModalSlice';
import { FormDisplayer } from 'forms/FormDisplayer';
import { ActionModal } from 'components/Modals/ActionModal';
import { LoadingOverlay } from 'shared/components/LoadingOverlay';
import {
  getLoadingState,
  getProgress,
  getShowProgress,
} from 'features/global/loadingProgressSlice';
import { AboutModal } from 'components/Modals/AboutModal/Index';
import { DodModal } from 'components/Modals/DodModal/DodModal';

function App({ theme }) {
  const dispatch = useDispatch();
  const activeUser = useSelector(selectActiveUser);
  const scenes = SceneDefinitions({ activeUser: activeUser, theme: theme });
  const formOpen = useSelector(isFormOpen);
  const actionModalInfo = useSelector(selectActionModalInfo);
  const aboutModalInfo = useSelector(isAboutModalOpen);
  const dodModalOpen = useSelector(isDodModalOpen);

  const showLoading = useSelector(getLoadingState);
  const progress = useSelector(getProgress);
  const showProgress = useSelector(getShowProgress);

  useEffect(() => {
    async function refresh() {
      dispatch(setAppIsLoading(true));
      await dispatch(refreshUserToken());
    }
    refresh();
  }, [dispatch]);

  return (
    <>
      <Routes>
        {scenes
          .filter((x) => x.contentElement)
          .map((x) => {
            return (
              <Route
                key={x.path}
                path={x.path}
                element={
                  x.isAnon ? (
                    <AnonymousTemplate
                      title={x.title}
                      isPagination={x.isPagination}
                      backRoute={x.backRoute}
                      theme={theme}
                    >
                      {x.contentElement}
                    </AnonymousTemplate>
                  ) : (
                    <AuthenticatedTemplate
                      title={x.title}
                      isPagination={x.isPagination}
                      backRoute={x.backRoute}
                      theme={theme}
                      requiredRoles={x.requiredRoles}
                    >
                      {x.contentElement}
                    </AuthenticatedTemplate>
                  )
                }
              />
            );
          })}
      </Routes>
      {formOpen && <FormDisplayer />}
      {actionModalInfo.isOpen && <ActionModal {...actionModalInfo} />}
      {aboutModalInfo && <AboutModal />}
      {dodModalOpen && <DodModal />}
      <LoadingOverlay
        showBackdrop={showLoading}
        showProgress={showProgress}
        progress={progress.progress}
        label={progress.label}
        noAnimation={progress.noAnimation}
      />
    </>
  );
}

export default App;
