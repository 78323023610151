import { useState, useEffect, useRef } from 'react';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { Button } from '@mui/material';

import { FormActions } from 'helpers/form-action';
import { makeFormStyles } from 'forms/style';
import {
  createPayPlan,
  fetchPayPlanById,
  fetchPayPlans,
  updatePayPlan,
} from 'features/payPlan/payPlansSlice';
import {
  activateLoading,
  deactivateLoading,
} from 'features/global/loadingProgressSlice';
import { EMPLOYEE_CACHE, snackbarHandler } from 'helpers';
import { SnackbarDismiss } from 'shared/components/SnackbarDismiss';
import Loader from 'shared/components/Loader';
import { closePanel } from 'features/common/formSlice';
import {
  selectAdminSettingCache,
  updateAdminSettingCache,
} from 'features/global/visibilitySlice';
import { FormTextField } from 'shared/components/FormFields/TextField';
import AuditInfoCard from 'shared/components/_AuditInfoCard';

const ItemPayPlanForm = ({ formAction, itemTagId, auditHeader }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  // eslint-disable-next-line no-unused-vars
  const [loaded, setLoaded] = useState(
    formAction.id === FormActions.Create.id ? true : false
  );
  const classes = makeFormStyles();
  const adminCacheData = useSelector(selectAdminSettingCache);
  // eslint-disable-next-line no-unused-vars
  const [initValues, setInitValues] = useState({
    display_name: '',
  });
  const [auditInfo, setAuditInfo] = useState(null);
  const tagNameRef = useRef(null);

  useEffect(() => {
    if (formAction.id === FormActions.Edit.id) {
      const fetchData = async () => {
        var objectData = await dispatch(fetchPayPlanById(itemTagId));
        var fetchedObject = objectData.payload;
        setInitValues({
          display_name: fetchedObject.display_name,
        });
        const { created_by, created_time, modified_time } = fetchedObject;
        setAuditInfo({
          author: created_by,
          creation: created_time,
          modified: modified_time,
        });
        setLoaded(true);
      };
      fetchData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = async (values, setSubmitting, resetForm) => {
    dispatch(activateLoading({ showProgress: true }));
    var resultAction = null;
    switch (formAction.id) {
      case FormActions.Edit.id:
        resultAction = await dispatch(
          updatePayPlan({
            pay_plan_id: itemTagId,
            ...values,
          })
        );
        if (!resultAction.error) {
          setInitValues({
            ...initValues,
            display_name: resultAction?.payload.display_name,
          });
          if (
            adminCacheData?.[EMPLOYEE_CACHE]?.pay_plan_id?.value ===
              itemTagId &&
            adminCacheData?.[EMPLOYEE_CACHE]?.pay_plan_id?.text !==
              resultAction?.payload.display_name
          ) {
            dispatch(
              updateAdminSettingCache({
                key: EMPLOYEE_CACHE,
                payload: {
                  pay_plan_id: {
                    value: itemTagId,
                    text: resultAction?.payload.display_name,
                  },
                },
              })
            );
          }
          dispatch(closePanel({ formKey: `itemPayPlanForm` }));
        }
        break;
      case FormActions.Create.id:
        resultAction = await dispatch(createPayPlan({ ...values }));
        if (!resultAction.error) {
          await dispatch(fetchPayPlans());
          dispatch(closePanel({ formKey: `itemPayPlanForm` }));
          resetForm();
          if (tagNameRef.current) {
            tagNameRef.current.focus();
          }
        }
        break;
      default:
        break;
    }

    dispatch(deactivateLoading());
    const { message, variant } = snackbarHandler(
      resultAction.meta.requestStatus,
      formAction.label
    );
    enqueueSnackbar(message, {
      action: (key) => <SnackbarDismiss key={key} />,
      variant: variant,
    });
    setSubmitting(false);
  };

  return !loaded ? (
    <Loader styleClassName={classes.loadingSvg} />
  ) : (
    <Formik
      enableReinitialize={true}
      initialValues={{ ...initValues }}
      validationSchema={Yup.object({
        display_name: Yup.string().required('Required'),
      })}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        handleSubmit(values, setSubmitting, resetForm);
      }}
    >
      {({ isSubmitting }) => (
        <Form className={classes.form}>
          <FormTextField
            fullWidth
            label="Pay Plan Name"
            name="display_name"
            placeholder="Pay Plan Name"
          />
          <AuditInfoCard
            auditInfo={auditInfo}
            auditInfoHeader={auditHeader}
            formAction={formAction}
          />
          <Button
            size="large"
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            fullWidth
            type="submit"
            sx={{ marginTop: '1rem' }}
          >
            {formAction.buttonLabel}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export { ItemPayPlanForm };
