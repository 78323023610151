import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import {
    getTalentTabIndex,
    setTalentTabIndex,
} from 'features/global/visibilitySlice';
import { tabStyles } from 'components/_Layout/AdminTabPanel/style';
import { mdTheme } from 'theme';
import { TalentDepotTable } from './TalentDepotTable';
import { TalentOtherDepotTable } from './TalentOtherDepotTable';
import { selectActiveUser } from 'features/account/accountSlice';
import { SystemRoleEnums } from 'helpers';

function TalentTab(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            style={{ height: '100%' }}
            {...other}
        >
            {value === index && (
                <Box
                    p={3}
                    style={{
                        overflow: 'auto hidden',
                    }}
                >
                    {children}
                </Box>
            )}
        </div>
    );
}

TalentTab.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

function TalentTabPanel({
    itemBestFitPayload,
    setItemBestFitPayload,
    sortDirection,
    sortBy,
    handleSort,
}) {
    const dispatch = useDispatch();
    const classes = tabStyles(mdTheme);
    const { role } = useSelector(selectActiveUser);
    const displayTabIndex = useSelector((state) => getTalentTabIndex(state));

    const handleChange = (event, newValue) => {
        dispatch(setTalentTabIndex(newValue));
    };

    let tabBarIndex = 0;
    let tabPageIndex = 0;
    return (
        <div className={classes.tabRoot}>
            <AppBar position="static" color="default">
                <Tabs
                    value={displayTabIndex}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    className={classes.tabs}
                >
                    {role === SystemRoleEnums.AMC ? (
                        <Tab label={'All Depot'} {...a11yProps(tabBarIndex)} />
                    ) : (
                        <Tab
                            label={'This Depots'}
                            {...a11yProps(tabBarIndex)}
                        />
                    )}

                    {(role === SystemRoleEnums.BlueGrassAdmin ||
                        role === SystemRoleEnums.ProgramManager) && (
                        <Tab
                            label={'Other Depots'}
                            {...a11yProps(tabBarIndex)}
                        />
                    )}
                </Tabs>
            </AppBar>
            {role === SystemRoleEnums.AMC ? (
                <TalentTab value={displayTabIndex} index={tabPageIndex}>
                    <TalentOtherDepotTable
                        itemBestFitPayload={itemBestFitPayload}
                        setItemBestFitPayload={setItemBestFitPayload}
                        sortDirection={sortDirection}
                        sortBy={sortBy}
                        handleSort={handleSort}
                    />
                </TalentTab>
            ) : (
                <TalentTab value={displayTabIndex} index={tabPageIndex}>
                    <TalentDepotTable
                        itemBestFitPayload={itemBestFitPayload}
                        setItemBestFitPayload={setItemBestFitPayload}
                        sortDirection={sortDirection}
                        sortBy={sortBy}
                        handleSort={handleSort}
                    />
                </TalentTab>
            )}

            {(role === SystemRoleEnums.BlueGrassAdmin ||
                role === SystemRoleEnums.ProgramManager) && (
                <TalentTab value={displayTabIndex} index={tabPageIndex}>
                    <TalentOtherDepotTable
                        itemBestFitPayload={itemBestFitPayload}
                        setItemBestFitPayload={setItemBestFitPayload}
                        sortDirection={sortDirection}
                        sortBy={sortBy}
                        handleSort={handleSort}
                    />
                </TalentTab>
            )}
        </div>
    );
}

export { TalentTabPanel };
