import { createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const dodInfoStyles = makeStyles(() =>
  createStyles({
    dodWrapper: {
      width: '100%',
      margin: '0 auto',
      minHeight: 'calc(100vh - 244px)',
      display: 'flex',
      alignItems: 'center',
    },
    dodModalWrapper: {
      '& .MuiPaper-root': {
        overflow: 'visible',
      },
    },
    dodContent: {
      '& p': {
        fontWeight: 'bold',
      },
      '& ul': {
        pl: '1rem',
      },
      '& ul li': {
        marginBottom: '0.5rem',
        fontWeight: 'bold',
      },
    },
  })
);

export { dodInfoStyles };
