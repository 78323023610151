import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchApiVersion,
  login,
  logout,
  refreshToken,
} from 'features/services/account.service';

const initialState = {
  activeUser: null,
  loginError: null,
  appIsLoading: false,
  apiVersion: null,
  admin_field_ids: [],
};

export const loginUser = createAsyncThunk(
  'account/loginUser',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await login(params.email);
      return response;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getApiVersion = createAsyncThunk(
  'admin/getApiVersion',
  async () => {
    const response = await fetchApiVersion();
    return response;
  }
);

export const logoutUser = createAsyncThunk('accounts/logoutUser', async () => {
  await logout();
});

export const refreshUserToken = createAsyncThunk(
  'account/refreshUserToken',
  async () => {
    const response = await refreshToken();
    return response;
  }
);

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setActiveUser: (state, action) => {
      state.activeUser = action.payload;
    },
    setLoginError: (state, action) => {
      state.loginError = action.payload;
    },
    setAppIsLoading: (state, action) => {
      state.appIsLoading = action.payload;
    },
    setHideSystemIds: (state, action) => {
      state.activeUser.hide_system_ids = action.payload;
    },
    setAdminFieldIds: (state, action) => {
      state.activeUser.admin_field_ids = action.payload;
    },
  },
  extraReducers: {
    [loginUser.pending]: (state, action) => {
      state.appIsLoading = true;
    },
    [loginUser.fulfilled]: (state, action) => {
      console.log('accountSlice.er.loginUser.fulfilled', action.payload);
      state.activeUser = action.payload;
      state.loginError = null;
      state.appIsLoading = false;
    },
    [loginUser.rejected]: (state, action) => {
      console.log('accountSlice.er.loginUser.rejected', action);
      state.activeUser = null;
      state.appIsLoading = false;
      state.loginError = action.error.message;
    },
    [refreshUserToken.fulfilled]: (state, action) => {
      state.activeUser = action.payload;
      state.appIsLoading = false;
    },
    [refreshUserToken.rejected]: (state) => {
      state.activeUser = null;
      state.appIsLoading = false;
    },
    [logoutUser.fulfilled]: (state) => {
      state.activeUser = null;
      state.loginError = null;
      state.appIsLoading = false;
    },
    [getApiVersion.pending]: (state, action) => {
      state.appIsLoading = true;
    },
    [getApiVersion.fulfilled]: (state, action) => {
      state.apiVersion = action.payload;
      state.appIsLoading = false;
    },
    [getApiVersion.rejected]: (state, action) => {
      state.appIsLoading = false;
    },
  },
});

export const selectActiveUser = (state) => state.account.activeUser;
export const selectLoginError = (state) => state.account.loginError;
export const selectAppIsLoading = (state) => state.account.appIsLoading;
export const selectApiVersion = (state) => state.account.apiVersion;

export const {
  setActiveUser,
  setLoginError,
  setAppIsLoading,
  setHideSystemIds,
  setAdminFieldIds,
} = accountSlice.actions;

export default accountSlice.reducer;
