import { config } from 'ectl-config';
import { getNetworkService } from 'helpers';
import { buildFilters } from 'hooks/buildFilter';

const accountService = { config: config };
const networkService = getNetworkService({
  config: config,
  checkAuth: accountService.checkAuth,
});
const baseUrl = `${config.apiUrl}/Report/BestFit`;

const getBestFit = async (
  job_id = null,
  job_role_id = null,
  cost_center = null,
  skillIds = null,
  office_id = null,
  percentage_match = null,
  command_id = null,
  depot_id = null,
  start_index = 0,
  stop_index = 10,
  sort = '-match'
) => {
  const filters = buildFilters(
    ['job_id', job_id],
    ['cost_center', cost_center],
    ['skill_ids', skillIds],
    ['office_id', office_id],
    ['percentage_match', percentage_match],
    ['command_id', command_id],
    ['depot_id', depot_id]
  );

  const params = {
    filters: filters,
    sorts: sort,
    start_index: start_index,
    stop_index: stop_index,
  };
  const query = new URLSearchParams(params).toString();

  if (job_role_id) {
    const response = await networkService.get(
      `${baseUrl}/${job_role_id}?${query}`,
      60000
    );

    const pageInfo = response.headers['x-pagination'];
    return { bestFits: response.data, pageInfo: pageInfo };
  } else {
    return { error: 'Job Role Is Required' };
  }
};

const getOtherBestFit = async (
  job_id = null,
  job_role_id = null,
  cost_center = null,
  skillIds = null,
  office_id = null,
  percentage_match = null,
  command_id = null,
  depot_id = null,
  start_index = 0,
  stop_index = 10,
  sort = '-match'
) => {
  try {
    const filters = buildFilters(
      ['job_id', job_id],
      ['cost_center', cost_center],
      ['skill_ids', skillIds],
      ['office_id', office_id],
      ['percentage_match', percentage_match],
      ['command_id', command_id],
      ['depot_id', depot_id]
    );

    const params = {
      filters: filters,
      sorts: sort,
      start_index: start_index,
      stop_index: stop_index,
    };
    const query = new URLSearchParams(params).toString();
    if (job_role_id) {
      const response = await networkService.get(
        `${baseUrl}/other/${job_role_id}?${query}`,
        60000
      );

      const pageInfo = response.headers['x-pagination'];
      return { otherBestFits: response.data, pageInfo: pageInfo };
    } else {
      return { error: 'Job Role Is Required' };
    }
  } catch (e) {
    return Promise.reject(e.error);
  }
};

export const bestFitService = {
  getBestFit,
  getOtherBestFit,
};
