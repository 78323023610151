import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { makeFormStyles } from 'forms/style';

import { SelectionAutoComplete } from 'shared/components/_Autocomplete/SelectionAutocomplete';
import {
  getJobDropDownStatus,
  selectJobDropDown,
  fetchJobsDropDown,
  fetchSuperVisorDropDown,
  getSuperVisorDropDownStatus,
  selectSuperVisorDropDown,
  fetchJobRolesByJobId,
  selectJobRoleByJobIdDropDown,
  getJobRolesByJobIdDropDownStatus,
  fetchEmployeeDropDown,
  getEmployeeDropDownStatus,
  selectEmployeeDropDown,
  resetDropDownState,
} from 'features/AdminSettingsDropDown/dropDownSlice';
import { SUPER_VISOR_ID, JOB_ID, JOB_ROLE_ID, EMPLOYEE_ID } from 'helpers';
import {
  fetchTeamBench,
  fetchTeamExpiry,
  getTeamBenchPageInfo,
  getTeamExpiryPageInfo,
  selectSearchCache,
  selectTeamBench,
  selectTeamExpiry,
  updateSearchCache,
} from 'features/Teams/teamSlice';
import {
  activateLoading,
  deactivateLoading,
} from 'features/global/loadingProgressSlice';
import { Box } from '@mui/system';
import { selectActiveUser } from 'features/account/accountSlice';
import { FilterAccordion } from 'shared/components/_Accordion';
import { mdTheme } from 'theme';
import { clearFilter } from 'helpers/clearFilter';
import { TeamsTabPanel } from 'components/_Layout/TeamsTabPanel';

const TeamsFilter = () => {
  //#region Constants
  const classes = makeFormStyles();
  const dispatch = useDispatch();
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  const [initValues, setInitValues] = useState({
    job_id: null,
    job_role_id: null,
    supervisor_employee_id: null,
    employee_id: null,
  });
  const [mediaWidth, setMediaWidth] = useState(null);
  //#endregion State

  //#region Selectors
  const getTeamBenchData = useSelector(selectTeamBench);
  const getTeamExpiryData = useSelector(selectTeamExpiry);
  const user = useSelector(selectActiveUser);
  const getCacheData = useSelector(selectSearchCache);
  const [teamsTableData, setTeamsTableData] = useState(
    getCacheData ? getCacheData : []
  );
  const getExpiryTotal = useSelector(getTeamExpiryPageInfo);
  const getBenchTotal = useSelector(getTeamBenchPageInfo);

  const [currentPage, setCurrentPage] = useState({
    start_index: getCacheData?.start_index || 0,
    stop_index: getCacheData?.stop_index || 7,
  });

  //#endregion Selectors

  //#region Refs
  const formRef = useRef();
  //#endregion Refs

  //#region Effects
  useEffect(() => {
    (async () => {
      dispatch(activateLoading());
      await dispatch(
        fetchTeamBench({
          job_id: teamsTableData?.job_id?.value,
          job_role_id: teamsTableData?.job_role_id?.value,
          supervisor_employee_id: teamsTableData?.supervisor_employee_id?.value,
          employee_id: teamsTableData?.employee_id?.value,
          ...currentPage,
        })
      );
      await dispatch(
        fetchTeamExpiry({
          job_id: teamsTableData?.job_id?.value,
          job_role_id: teamsTableData?.job_role_id?.value,
          supervisor_employee_id: teamsTableData?.supervisor_employee_id?.value,
          employee_id: teamsTableData?.employee_id?.value,
          ...currentPage,
        })
      );

      if (teamsTableData?.job_id?.value) {
        dispatch(
          fetchJobRolesByJobId({
            job_id: teamsTableData?.job_id?.value,
          })
        );
      }
      dispatch(deactivateLoading());
    })();
    setInitValues({
      job_id: getCacheData?.job_id?.value,
      job_role_id: getCacheData?.job_role_id?.value,
      supervisor_employee_id: getCacheData?.supervisor_employee_id?.value,
      employee_id: getCacheData?.employee_id?.value,
    });
  }, [
    currentPage,
    dispatch,
    getCacheData?.employee_id?.value,
    getCacheData?.job_id?.value,
    getCacheData?.job_role_id?.value,
    getCacheData?.supervisor_employee_id?.value,
    teamsTableData?.employee_id?.value,
    teamsTableData?.job_id?.value,
    teamsTableData?.job_role_id?.value,
    teamsTableData?.supervisor_employee_id?.value,
  ]);

  useEffect(() => {
    dispatch(
      updateSearchCache({
        ...teamsTableData,
        ...currentPage,
      })
    );
  }, [teamsTableData, currentPage, dispatch]);

  useEffect(() => {
    if (window.innerWidth >= mdTheme.breakpoints.values.md) {
      setMediaWidth(7);
      setCurrentPage({
        start_index: getCacheData?.start_index ? getCacheData?.start_index : 0,
        stop_index: getCacheData?.stop_index ? getCacheData?.stop_index : 7,
      });
    } else if (window.innerWidth >= mdTheme.breakpoints.values.sm) {
      setMediaWidth(5);
      setCurrentPage({
        start_index: getCacheData?.start_index ? getCacheData?.start_index : 0,
        stop_index: getCacheData?.stop_index ? getCacheData?.stop_index : 5,
      });
    } else {
      setMediaWidth(4);
      setCurrentPage({
        start_index: getCacheData?.start_index ? getCacheData?.start_index : 0,
        stop_index: getCacheData?.stop_index ? getCacheData?.stop_index : 4,
      });
    }
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetDropDownState());
    };
  }, []);

  //#endregion Effects

  //#region Methods
  const setTeamTableDataID = async (value, setFieldValue, key) => {
    if (key === JOB_ID && value) {
      setTeamsTableData((prevValue) => ({
        ...prevValue,
        [JOB_ID]: value,
        [JOB_ROLE_ID]: null,
      }));
      setFieldValue(JOB_ID, value.value);
      setFieldValue(JOB_ROLE_ID, null);
    }
    if (key === JOB_ROLE_ID && value) {
      setTeamsTableData((prevValue) => ({
        ...prevValue,
        [JOB_ROLE_ID]: value,
      }));
      setFieldValue(JOB_ROLE_ID, value.value);
    }
    if (key === SUPER_VISOR_ID && value) {
      setTeamsTableData((prevValue) => ({
        ...prevValue,
        [SUPER_VISOR_ID]: value,
      }));
      setFieldValue(SUPER_VISOR_ID, value.value);
    }
    if (key === EMPLOYEE_ID && value) {
      setTeamsTableData((prevValue) => ({
        ...prevValue,
        [EMPLOYEE_ID]: value,
      }));
      setFieldValue(EMPLOYEE_ID, value.value);
    }
    setCurrentPage({
      start_index: 0,
      stop_index: 7,
    });
  };

  const removeTeamTableId = async (setFieldValue, key) => {
    if (key === JOB_ID) {
      setTeamsTableData((prevValue) => ({
        ...prevValue,
        [JOB_ID]: null,
      }));
      await dispatch(fetchJobRolesByJobId());
      setFieldValue(JOB_ID, null);
    }
    if (key === JOB_ROLE_ID) {
      setTeamsTableData((prevValue) => ({
        ...prevValue,
        [JOB_ROLE_ID]: null,
      }));
      setFieldValue(JOB_ROLE_ID, null);
    }
    if (key === SUPER_VISOR_ID) {
      setTeamsTableData((prevValue) => ({
        ...prevValue,
        [SUPER_VISOR_ID]: null,
      }));
      setFieldValue(SUPER_VISOR_ID, null);
    }
    if (key === EMPLOYEE_ID) {
      setTeamsTableData((prevValue) => ({
        ...prevValue,
        [EMPLOYEE_ID]: null,
      }));
      setFieldValue(EMPLOYEE_ID, null);
    }
    setCurrentPage({
      start_index: 0,
      stop_index: 7,
    });
  };

  const handleResetFilters = () => {
    const isFilterData = clearFilter(formRef);
    if (isFilterData) {
      setInitValues({
        job_id: null,
        job_role_id: null,
        SUPER_VISOR_ID: null,
        EMPLOYEE_ID: null,
      });
      setCurrentPage({
        start_index: 0,
        stop_index: 7,
      });
      setTeamsTableData([]);
      formRef?.current?.resetForm();
      dispatch(fetchJobRolesByJobId());
      updateSearchCache({});
    }
  };
  //#endregion Methods

  //#region Render time calcs
  //#endregion Render time calcs

  //#region Render

  return (
    <>
      <FilterAccordion
        filteredTitles={teamsTableData}
        expandedId="team-filter"
        styles={{ margin: '1rem 0' }}
        onResetFilters={handleResetFilters}
      >
        <Formik
          enableReinitialize={true}
          initialValues={{ ...initValues }}
          innerRef={formRef}
        >
          {({ isSubmitting, errors, values }) => (
            <Form className={classes.form} style={{ padding: '0' }}>
              <Grid container spacing={'1rem'}>
                <Grid item={true} xs={12} md={6} lg={4} xl={3}>
                  <Field className={classes.input} name={JOB_ID}>
                    {({ field: { value }, form: { setFieldValue } }) => (
                      <SelectionAutoComplete
                        title={`${user.label_job}`}
                        keyProperty="value"
                        nameProperty="text"
                        entityIds={value}
                        setEntityIds={setFieldValue}
                        entitySelector={selectJobDropDown}
                        onCustomChange={(value) => {
                          setTeamTableDataID(value, setFieldValue, JOB_ID);
                        }}
                        entityStatusSelector={getJobDropDownStatus}
                        fetchEntityPage={fetchJobsDropDown}
                        formField={JOB_ID}
                        multiSelection={false}
                        showCloseItemEdit={true}
                        hideCheckIcon={true}
                        cancelItemTagEdit={() => {
                          removeTeamTableId(setFieldValue, JOB_ID);
                        }}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item={true} xs={12} md={6} lg={4} xl={3}>
                  <Field className={classes.input} name={JOB_ROLE_ID}>
                    {({ field: { value }, form: { setFieldValue } }) => (
                      <SelectionAutoComplete
                        title={`${user.label_job_role}`}
                        keyProperty="value"
                        nameProperty="text"
                        entityIds={value}
                        setEntityIds={setFieldValue}
                        entitySelector={selectJobRoleByJobIdDropDown}
                        onCustomChange={(value) => {
                          setTeamTableDataID(value, setFieldValue, JOB_ROLE_ID);
                        }}
                        entityStatusSelector={getJobRolesByJobIdDropDownStatus}
                        fetchEntityPage={fetchJobRolesByJobId}
                        formField={JOB_ROLE_ID}
                        multiSelection={false}
                        showCloseItemEdit={true}
                        hideCheckIcon={true}
                        cancelItemTagEdit={() => {
                          removeTeamTableId(setFieldValue, JOB_ROLE_ID);
                        }}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item={true} xs={12} md={6} lg={4} xl={3}>
                  <Field className={classes.input} name={SUPER_VISOR_ID}>
                    {({ field: { value }, form: { setFieldValue } }) => (
                      <SelectionAutoComplete
                        title="Direct Supervisor"
                        keyProperty="value"
                        nameProperty="text"
                        entityIds={value}
                        setEntityIds={setFieldValue}
                        entitySelector={selectSuperVisorDropDown}
                        onCustomChange={(value) => {
                          setTeamTableDataID(
                            value,
                            setFieldValue,
                            SUPER_VISOR_ID
                          );
                        }}
                        entityStatusSelector={getSuperVisorDropDownStatus}
                        fetchEntityPage={fetchSuperVisorDropDown}
                        fetchQueryParams={{
                          include_self: true,
                        }}
                        formField={SUPER_VISOR_ID}
                        multiSelection={false}
                        showCloseItemEdit={true}
                        hideCheckIcon={true}
                        cancelItemTagEdit={() => {
                          removeTeamTableId(setFieldValue, SUPER_VISOR_ID);
                        }}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item={true} xs={12} md={6} lg={4} xl={3}>
                  <Field className={classes.input} name={EMPLOYEE_ID}>
                    {({ field: { value }, form: { setFieldValue } }) => (
                      <SelectionAutoComplete
                        title="Employee"
                        keyProperty="value"
                        nameProperty="text"
                        entityIds={value}
                        setEntityIds={setFieldValue}
                        entitySelector={selectEmployeeDropDown}
                        onCustomChange={(value) => {
                          setTeamTableDataID(value, setFieldValue, EMPLOYEE_ID);
                        }}
                        entityStatusSelector={getEmployeeDropDownStatus}
                        fetchEntityPage={fetchEmployeeDropDown}
                        fetchQueryParams={{
                          include_self: true,
                        }}
                        formField={EMPLOYEE_ID}
                        multiSelection={false}
                        showCloseItemEdit={true}
                        hideCheckIcon={true}
                        cancelItemTagEdit={() => {
                          removeTeamTableId(setFieldValue, EMPLOYEE_ID);
                        }}
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </FilterAccordion>
      <Box>
        <TeamsTabPanel
          columnData={getTeamBenchData}
          getTeamExpiryData={getTeamExpiryData}
          teamsTableData={teamsTableData}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          user={user}
          mediaWidth={mediaWidth + 1}
          expiryTotal={getExpiryTotal}
          benchTotal={getBenchTotal}
        />
      </Box>
    </>
  );
  //#endregion Render
};
export { TeamsFilter };
