import { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import pluralize from 'pluralize';
import { MdClose } from 'react-icons/md';

import {
  fetchGroupTrainingSkill,
  getGroupTrainingPageInfo,
  getGroupTrainingSkillStatus,
  resetGroupTrainingState,
  selectGroupTraining,
  selectGroupTrainingSkill,
} from 'features/groupTraining/groupTrainingSlice';
import { GroupTrainingTable } from './GroupTrainingTable';

import {
  activateLoading,
  deactivateLoading,
} from 'features/global/loadingProgressSlice';
import { LoadingStatus, NO_MATCHING_SKILLS } from 'helpers';
import { NoDataMessage } from 'shared/components/_Tables/NoDataMessage';
import { selectActiveUser } from 'features/account/accountSlice';

const GroupTrainingSkillModal = ({
  setIsSkillModalOpen,
  isSkillModalOpen,
  values,
  setIsAttendTable,
  setGroupTrainingPayload,
  groupTrainingPayload,
}) => {
  //#region Constants
  const dispatch = useDispatch();
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  //#endregion State
  const [sortBy, setSortBy] = useState('');
  const [sortDirection, setSortDirection] = useState('');

  //#region Selectors
  const getGroupTrainingData = useSelector(selectGroupTraining);
  const groupTrainingPageInfo = useSelector(getGroupTrainingPageInfo);
  const filteredTrainingData = getGroupTrainingData?.employees?.map(
    (item) => item?.employee_id
  );
  const groupTrainingSkillData = useSelector(selectGroupTrainingSkill);
  const groupTrainingSkillStatus = useSelector(getGroupTrainingSkillStatus);
  const user = useSelector(selectActiveUser);
  //#endregion Selectors

  //#region Refs
  //#endregion Refs

  //#region Effects
  const fetchData = async () => {
    if (isSkillModalOpen && groupTrainingSkillStatus === LoadingStatus.Idle) {
      dispatch(activateLoading());
      try {
        await dispatch(
          fetchGroupTrainingSkill({
            capability_id: values?.capability_id
              ? values?.capability_id
              : groupTrainingPayload?.capability_id,
          })
        );
      } finally {
        dispatch(deactivateLoading());
      }
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, isSkillModalOpen, groupTrainingSkillStatus]);

  //#endregion Effects

  const setSorting = (_sortBy, _sortDirection) => {
    setSortBy(_sortBy);
    setSortDirection(_sortDirection);
    setGroupTrainingPayload({
      ...groupTrainingPayload,
      sort: `${_sortDirection === 'ASC' ? '' : '-'}${_sortBy}`,
    });
  };
  //#endregion Methods

  //#region Render time calcs
  //#endregion Render time calcs

  //#region Render

  return (
    <Dialog
      open={isSkillModalOpen}
      onClose={() => {}}
      maxWidth={'sm'}
      fullWidth
    >
      <DialogTitle>
        {pluralize(
          `Select ${user.label_skill}`,
          groupTrainingSkillData?.length
        )}
        <IconButton
          aria-label="close"
          onClick={() => {
            setIsSkillModalOpen(false);
            dispatch(resetGroupTrainingState());
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 6,
            color: '#000',
            fontSize: '1.4rem',
          }}
          size="large"
        >
          <MdClose />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {groupTrainingSkillData?.length > 0 ? (
          <>
            <DialogContentText marginTop={'1rem'} marginBottom={'5px'}>
              All {pluralize(user.label_skill, groupTrainingSkillData?.length)}{' '}
              shown in{' '}
              <Typography
                component="span"
                sx={{
                  color: 'red',
                  display: 'inline-block',
                }}
              >
                Red
              </Typography>{' '}
              require additional evidence to mark complete, so you will not be
              able to select them for completion on this screen. Please select
              all {pluralize(user.label_skill, groupTrainingSkillData?.length)}{' '}
              you'd like to start a group completion session for.
            </DialogContentText>

            <GroupTrainingTable
              groupTrainingData={groupTrainingSkillData}
              pageInfo={groupTrainingPageInfo}
              groupTrainingPayload={groupTrainingPayload}
              setGroupTrainingPayload={setGroupTrainingPayload}
              employeeIds={filteredTrainingData}
              allFormData={values}
              sortDirection={sortDirection}
              sortBy={sortBy}
              setIsAttendTable={setIsAttendTable}
              handleSort={(_sortBy, _sortDirection) =>
                setSorting(_sortBy, _sortDirection)
              }
              user={user}
              setIsSkillModalOpen={setIsSkillModalOpen}
            />
          </>
        ) : (
          <NoDataMessage message={NO_MATCHING_SKILLS} />
        )}
      </DialogContent>
    </Dialog>
  );
  //#endregion Render
};
export { GroupTrainingSkillModal };
