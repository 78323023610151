import React from 'react';
import { TeamsFilter } from 'components/ItemTeamsTable/TeamsFilter';

const Teams = () => {
  //#region Constants
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  //#endregion State

  //#region Selectors
  //#endregion Selectors

  //#region Refs
  //#endregion Refs

  //#region Effects
  //#endregion Effects

  //#region Methods

  return <TeamsFilter />;
  //#endregion Render
};
export { Teams };
