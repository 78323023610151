import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { teamService } from 'features/services/teams.service';
import { LoadingStatus } from 'helpers';

const initialState = {
  status: LoadingStatus.Idle,
  error: null,
  teams: [],
  teamsStatus: LoadingStatus.Idle,
  teamsPageInfo: {},
  displayTeams: {},
  teamsUsage: [],
  loadedTeamsMap: {},
  employees: [],
  employeesStatus: LoadingStatus.Idle,
  employeesPageInfo: {},
  displayJobs: [],
  searchCache: {},
  employeeCls: [],
  employeeClsStatus: LoadingStatus.Idle,
  teamsBench: [],
  teamsBenchInfo: [],
  teamsBenchStatus: LoadingStatus.Idle,
  teamsExpiry: [],
  teamsExpiryInfo: [],
  teamsExpiryStatus: LoadingStatus.Idle,
  employeeSkill: [],
  employeeSkillStatus: LoadingStatus.Idle,
  employeeCapability: [],
  employeeCapabilityStatus: LoadingStatus.Idle,
};

export const fetchTeams = createAsyncThunk(
  'teams/fetchTeams',
  async (params = {}) =>
    await teamService.getTeams(params.jobRoleId, params.jobId)
);

export const fetchTeamBench = createAsyncThunk(
  'teams/fetchTeamBench',
  async (params = {}) =>
    await teamService.getTeamsBench(
      params.job_id,
      params.job_role_id,
      params.supervisor_employee_id,
      params.employee_id,
      params.start_index,
      params.stop_index
    )
);

export const fetchTeamExpiry = createAsyncThunk(
  'teams/fetchTeamExpiry',
  async (params = {}) =>
    await teamService.getTeamsExpiry(
      params.job_id,
      params.job_role_id,
      params.supervisor_employee_id,
      params.employee_id,
      params.start_index,
      params.stop_index
    )
);

export const fetchEmployee = createAsyncThunk(
  'employee/fetchEmployee',
  async (params) => await teamService.getEmployee(params)
);

export const fetchEmployeeById = createAsyncThunk(
  'jobs/fetchEmployeeById',
  async (employeeId) => {
    const response = await teamService.getEmployeeById(employeeId);
    response.id = employeeId;
    return response;
  }
);

export const fetchEmployeeReport = createAsyncThunk(
  'team/fetchEmployeeReport',
  async (employeeId) => {
    const response = await teamService.getEmployeeReport(employeeId);
    response.id = employeeId;
    return response;
  }
);

export const createEmployee = createAsyncThunk(
  'employee/createEmployee',
  async (params) => await teamService.createEmployees(params)
);

export const updateEmployee = createAsyncThunk(
  'employee/updateEmployee',
  async (params) => await teamService.updateEmployees(params)
);

export const fetchEmployeeCls = createAsyncThunk(
  'employee/fetchEmployeeCls',
  async (params = {}) =>
    await teamService.getEmployeeCls(
      params.employeeId,
      params.capabilityId,
      params.levelId
    )
);

export const fetchEmployeeSkill = createAsyncThunk(
  'employee/fetchEmployeeSkill',
  async (params) => await teamService.getEmployeeSkill(params)
);

export const fetchEmployeeCapability = createAsyncThunk(
  'employee/fetchEmployeeCapability',
  async (params) => await teamService.getEmployeeCapability(params)
);

const teamSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    resetTeamsState: (state) => initialState,
    resetTeamEmployeeState: (state) => {
      state.employeesStatus = LoadingStatus.Idle;
    },
    resetJobState: (state) => {
      state.displayJobs = [];
    },
    updateSearchCache: (state, action) => {
      if (action.payload?.onlyKeyChange) {
        state.searchCache = {
          ...state.searchCache,
          ...action.payload?.data,
        };
      } else {
        state.searchCache = action.payload;
      }
    },
    resetEmployeeCls: (state, action) => {
      state.employeeClsStatus = LoadingStatus.Idle;
    },
    resetEmployeeSkill: (state) => {
      state.employeeSkillStatus = LoadingStatus.Idle;
    },
    resetEmployeeCapabilities: (state) => {
      state.employeeCapabilityStatus = LoadingStatus.Idle;
    },
  },
  extraReducers: {
    [fetchTeams.pending]: (state, action) => {
      state.teamsStatus = LoadingStatus.Loading;
    },
    [fetchTeams.fulfilled]: (state, action) => {
      state.teamsPageInfo = action.payload.pageInfo;
      state.teams = action.payload.teams;
      state.teamsStatus = LoadingStatus.Loaded;
    },
    [fetchTeams.rejected]: (state, action) => {
      state.teamsStatus = LoadingStatus.Failed;
    },
    [fetchEmployee.pending]: (state, action) => {
      state.employeesStatus = LoadingStatus.Loading;
    },
    [fetchEmployee.fulfilled]: (state, action) => {
      state.employeesPageInfo = action.payload.pageInfo;
      state.employees = action.payload.employees;
      state.employeesStatus = LoadingStatus.Loaded;
    },
    [fetchEmployee.rejected]: (state, action) => {
      state.employeesStatus = LoadingStatus.Failed;
    },
    [fetchEmployeeById.fulfilled]: (state, action) => {
      state.displayJobs = action.payload;
    },
    [fetchEmployeeReport.fulfilled]: (state, action) => {
      if (action?.payload) {
        const url = window.URL.createObjectURL(new Blob([action.payload.data]));
        const link = document.createElement('a');
        link.href = url;
        let downloadName =
          action.payload.headers?.filename ?? 'User Report.csv';
        link.setAttribute('download', downloadName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    },
    [createEmployee.fulfilled]: (state, action) => {
      state.employeesStatus = LoadingStatus.Idle;
      state.displayJobs = action.payload;
    },
    [updateEmployee.fulfilled]: (state, action) => {
      state.employeesStatus = LoadingStatus.Idle;
    },
    [fetchEmployeeCls.pending]: (state, action) => {
      state.employeeClsStatus = LoadingStatus.Loading;
    },
    [fetchEmployeeCls.fulfilled]: (state, action) => {
      state.employeeCls = action.payload.employeeCls;
      state.employeeClsStatus = LoadingStatus.Loaded;
    },
    [fetchEmployeeCls.rejected]: (state, action) => {
      state.employeeClsStatus = LoadingStatus.Failed;
    },
    [fetchTeamBench.pending]: (state, action) => {
      state.teamsBenchStatus = LoadingStatus.Loading;
    },
    [fetchTeamBench.fulfilled]: (state, action) => {
      state.teamsBenchInfo = action.payload.pageInfo;
      state.teamsBench = action.payload.teamsBench;
      state.teamsBenchStatus = LoadingStatus.Loaded;
    },
    [fetchTeamBench.rejected]: (state, action) => {
      state.teamsBenchStatus = LoadingStatus.Failed;
    },
    [fetchTeamExpiry.pending]: (state, action) => {
      state.teamsExpiryStatus = LoadingStatus.Loading;
    },
    [fetchTeamExpiry.fulfilled]: (state, action) => {
      state.teamsExpiryInfo = action.payload.pageInfo;
      state.teamsExpiry = action.payload.teamsExpiry;
      state.teamsExpiryStatus = LoadingStatus.Loaded;
    },
    [fetchTeamExpiry.rejected]: (state, action) => {
      state.teamsExpiryStatus = LoadingStatus.Failed;
    },
    [fetchEmployeeSkill.pending]: (state, action) => {
      state.employeeSkillStatus = LoadingStatus.Loading;
    },
    [fetchEmployeeSkill.fulfilled]: (state, action) => {
      state.employeesPageInfo = action.payload.pageInfo;
      state.employeeSkill = action.payload.employeeSkill;
      state.employeeSkillStatus = LoadingStatus.Loaded;
    },
    [fetchEmployeeSkill.rejected]: (state, action) => {
      state.employeeSkillStatus = LoadingStatus.Failed;
    },
    [fetchEmployeeCapability.pending]: (state, action) => {
      state.employeeCapabilityStatus = LoadingStatus.Loading;
    },
    [fetchEmployeeCapability.fulfilled]: (state, action) => {
      state.employeesPageInfo = action.payload.pageInfo;
      state.employeeCapability = action.payload.employeeCapability;
      state.employeeCapabilityStatus = LoadingStatus.Loaded;
    },
    [fetchEmployeeCapability.rejected]: (state, action) => {
      state.employeeCapabilityStatus = LoadingStatus.Failed;
    },
  },
});

export const selectTeam = (state) => state.teams.teams;
export const getTeamStatus = (state) => state.teams.teamsStatus;
export const getTeamPageInfo = (state) => state.teams.teamsPageInfo;
export const getTeamUsage = (state) => state.teams.teamsUsage;

export const selectEmployee = (state) => state.teams.employees;
export const getEmployeeStatus = (state) => state.teams.employeesStatus;
export const getEmployeePageInfo = (state) => state.teams.employeesPageInfo;

export const selectJobs = (state) => state.teams.displayJobs;
export const selectSearchCache = (state) => state.teams.searchCache;

export const selectEmployeeCls = (state) => state.teams.employeeCls;
export const getEmployeeClsStatus = (state) => state.teams.employeeClsStatus;

export const selectTeamBench = (state) => state.teams.teamsBench;
export const getTeamBenchStatus = (state) => state.teams.teamsBenchStatus;
export const getTeamBenchPageInfo = (state) => state.teams.teamsBenchInfo;

export const selectTeamExpiry = (state) => state.teams.teamsExpiry;
export const getTeamExpiryStatus = (state) => state.teams.teamsExpiryStatus;
export const getTeamExpiryPageInfo = (state) => state.teams.teamsExpiryInfo;

export const selectEmployeeSkill = (state) => state?.teams?.employeeSkill;
export const getEmployeeSkillStatus = (state) =>
  state.teams.employeeSkillStatus;
export const selectEmployeeCapability = (state) =>
  state.teams.employeeCapability;
export const getEmployeeCapabilityStatus = (state) =>
  state.teams.employeeCapabilityStatus;

export const {
  resetTeamsState,
  resetTeamEmployeeState,
  resetJobState,
  updateSearchCache,
  resetEmployeeCls,
  resetEmployeeSkill,
  resetEmployeeCapabilities,
} = teamSlice.actions;

export default teamSlice.reducer;
