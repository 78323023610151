import { Grid } from '@mui/material';
import {
  selectAdminSettingCache,
  updateAdminSettingCache,
} from 'features/global/visibilitySlice';
import { Formik, Form } from 'formik';
import { PAY_PLAN_CACHE } from 'helpers';
import { useDebounce } from 'hooks/useDebounce';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormTextField } from 'shared/components/FormFields/TextField';

import { FilterAccordion } from 'shared/components/_Accordion';

const ItemPayPlanFilter = ({ setItemPayPlanPayload, itemPayPlanPayload }) => {
  //#region Constants;
  const dispatch = useDispatch();
  const getCacheData = useSelector(selectAdminSettingCache);
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  //#endregion State

  //#region Selectors
  //#endregion Selectors

  const [initValues, setInitValues] = useState({
    display_name: '',
  });
  //#region Refs
  const formRef = useRef();
  //#endregion Refs

  //#region Effects
  useEffect(() => {
    setInitValues({ ...initValues, ...getCacheData?.[PAY_PLAN_CACHE] });
  }, [getCacheData, itemPayPlanPayload]);
  //#endregion Effects

  //#region Methods

  const handleSearch = useDebounce((searchTerm) => {
    setItemPayPlanPayload({
      ...itemPayPlanPayload,
      display_name: searchTerm,
    });
  }, 1000); // Debounce time in milliseconds

  const handleResetFilters = () => {
    if (Object.keys(formRef?.current?.values).length > 0) {
      if (
        !!formRef?.current?.values[Object.keys(formRef?.current?.values)[0]]
      ) {
        setItemPayPlanPayload({
          ...itemPayPlanPayload,
          display_name: '',
        });
        dispatch(updateAdminSettingCache({ key: PAY_PLAN_CACHE, ...{} }));
        formRef?.current?.resetForm();
      }
    }
  };
  //#endregion Methods

  //#region Render time calcs
  //#endregion Render time calcs

  //#region Render
  return (
    <FilterAccordion
      filteredTitles={itemPayPlanPayload}
      expandedId="pay-plan"
      styles={{ marginBottom: '1.5rem' }}
      onResetFilters={handleResetFilters}
    >
      <Formik
        enableReinitialize={true}
        initialValues={{ ...initValues }}
        innerRef={formRef}
      >
        {({ setFieldValue }) => (
          <Form style={{ padding: '0' }}>
            <Grid container columnSpacing={'1rem'}>
              <Grid item xs={12} lg={3}>
                <FormTextField
                  fullWidth
                  placeholder="Display Name"
                  name="display_name"
                  label="Display Name"
                  onChange={(e) => {
                    const searchTerm = e.target.value;
                    setFieldValue('display_name', searchTerm);
                    handleSearch(searchTerm, 'display_name');
                  }}
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </FilterAccordion>
  );
  //#endregion Render
};
export { ItemPayPlanFilter };
