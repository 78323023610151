import hs_logo from 'assets/hs_thumb.png';
import { Box, Button, Dialog, DialogContent, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { MdClose } from 'react-icons/md';

import { isDodModalOpen, showDodModal } from 'features/common/actionModalSlice';
import { dodInfoStyles } from './style';
import { mdTheme } from 'theme';

const DodModal = ({ theme }) => {
  //#region Constants
  const dispatch = useDispatch();
  const classes = dodInfoStyles(mdTheme);
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  //#endregion State

  //#region Selectors
  const open = useSelector(isDodModalOpen);
  //#endregion Selectors

  //#region Refs
  //#endregion Refs

  //#region Effects
  //#endregion Effects

  //#region Methods
  const handleClose = () => {
    dispatch(showDodModal(false));
  };
  //#endregion Methods

  //#region Render time calcs
  //#endregion Render time calcs

  //#region

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        fullWidth={true}
        maxWidth="lg"
        className={classes.dodModalWrapper}
      >
        <button
          aria-label="close"
          onClick={handleClose}
          style={{
            position: 'absolute',
            fontSize: '1.3rem',
            right: 0,
            top: 0,
            color: (theme) => theme.palette.grey[500],
            backgroundColor: '#ffffff',
            transform: 'translate(35%, -35%)',
            boxShadow: '0 0 12px 0 rgba(0,0,0,0.5)',
            borderRadius: '50%',
            width: '35px',
            height: '35px',
            border: '0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
        >
          <MdClose />
        </button>
        <DialogContent sx={{ p: '2rem' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img src={hs_logo} alt="HS" width="60" />
            <div>
              <Typography
                component="h1"
                variant="h5"
                sx={{ marginLeft: '1rem' }}
              >
                HVTC SMARTS
              </Typography>
              <Typography
                sx={{
                  marginLeft: '1rem',
                  fontSize: '.8rem',
                }}
              >
                System for Mapping/Reporting/Tracking Skills
              </Typography>
            </div>
          </Box>
          <Box className={classes.dodContent} sx={{ mb: '2rem' }}>
            <Typography
              variant="h3"
              sx={{
                textAlign: 'center',
                textTransform: 'uppercase',
                margin: '1rem 0 1.5rem',
                fontWeight: 'bold',
              }}
            >
              Notice
            </Typography>
            <p>
              You are accessing a U.S. Government (USG) Information System (IS)
              that is provided for USG-authorized use only.
            </p>
            <p>
              By using this IS (which includes any device attached to this IS),
              you consent to the following conditions:
            </p>
            <ul>
              <li>
                The USG routinely intercepts and monitors communications on this
                IS for purposes including, but not limited to, penetration
                testing, COMSEC monitoring, network operations and defense,
                personnel misconduct (PM), law enforcement (LE), and
                counterintelligence (CI) investigations.
              </li>
              <ul>
                <li>
                  At any time, the USG may inspect and seize data stored on this
                  IS.
                </li>
                <li>
                  {' '}
                  Communications using, or data stored on, this IS are not
                  private, are subject to routine monitoring, interception, and
                  search, and may be disclosed or used for any USG-authorized
                  purpose.
                </li>
                <li>
                  This IS includes security measures (e.g., authentication and
                  access controls) to protect USG interests—not for your
                  personal benefit or privacy.
                </li>
                <li>
                  Notwithstanding the above, using this IS does not constitute
                  consent to PM, LE or CI investigative searching or monitoring
                  of the content of privileged communications, or work product,
                  related to personal representation or services by attorneys,
                  psychotherapists, or clergy, and their assistants. Such
                  communications and work product are private and confidential.
                  See User Agreement for details.
                </li>
              </ul>
            </ul>
          </Box>
          <Box sx={{ textAlign: 'center', mb: '1rem' }}>
            <Button
              variant="contained"
              sx={{
                minWidth: '250px',
                fontWeight: 'bold',
              }}
              onClick={handleClose}
            >
              Continue
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
  //#endregion Render
};
export { DodModal };
