import { config } from 'ectl-config';
import { FormDataUtils, getNetworkService } from 'helpers';
import { createProgressParams } from 'helpers/progressUtils';
import { buildFilters } from 'hooks/buildFilter';

const accountService = { config: config };
const networkService = getNetworkService({
  config: config,
  checkAuth: accountService.checkAuth,
});
const baseUrl = `${config.apiUrl}/Admin/PayGrade`;

const getPayGrades = async (
  startIndex = 1,
  stopIndex = 10,
  searchString = '',
  sort = 'display_name',
  displayName = ''
) => {
  const filters = [];

  if (searchString) {
    filters.push(`quick_search@=*${searchString}`);
  }
  if (displayName) {
    filters.push(`display_name@=*${displayName}`);
  }
  const params = {
    filters: filters.join(','),
    sorts: sort || '',
    start_index: startIndex,
    stop_index: stopIndex,
  };
  const query = new URLSearchParams(params);
  const response = await networkService.get(`${baseUrl}/?${query}`);
  const pageInfo = response.headers['x-pagination'];

  return {
    pageInfo: pageInfo,
    payGrades: response?.data,
  };
};

const create = async (params) => {
  const response = await networkService.post(baseUrl, params);

  if (response.error) {
    return Promise.reject(response.error);
  }

  return response;
};

const update = async (params) => {
  const response = await networkService.put(
    `${baseUrl}/${params.pay_grade_id}`,
    params
  );

  if (response.error) {
    return Promise.reject(response.error);
  }

  return response.data;
};

const _delete = async (pay_grade_id) => {
  try {
    const response = await networkService.delete(`${baseUrl}/${pay_grade_id}`);
    return response;
  } catch (e) {
    return Promise.reject(e.error);
  }
};

const getById = async (id) => {
  const response = await networkService.get(`${baseUrl}/${id}`);
  return response.data;
};

const deletePayGradeArray = async (ids) => {
  try {
    const deleteObject = {
      ids: ids,
    };
    const response = await networkService.deleteWithBody(baseUrl, deleteObject);
    return response.data;
  } catch (e) {
    return Promise.reject(e.error);
  }
};

const getPayGradeUsageById = async (tagId) => {
  const response = await networkService.get(`${baseUrl}/${tagId}`);
  return response.data;
};

const getPayGradeImportCsv = async () => {
  try {
    const response = await networkService.get(`${baseUrl}/import`);
    return response?.data;
  } catch (err) {
    return Promise.reject(err.error);
  }
};

const uploadPayGradeImportCsv = async (params) => {
  try {
    let formData = new FormData();
    FormDataUtils.safeAppend(formData, [['file', params.file]]);
    let progressParams = createProgressParams(params);
    const response = await networkService.postMultiFormData(
      `${baseUrl}/import`,
      formData,
      progressParams
    );
    return response.data;
  } catch (err) {
    return err;
  }
};

const exportPayGradeCsv = async (displayName = '') => {
  try {
    const filters = buildFilters(['display_name', displayName]);

    const params = {
      filters: filters,
    };
    const query = new URLSearchParams(params).toString();
    const response = await networkService.get(`${baseUrl}/export?${query}`);
    return response?.data;
  } catch (err) {
    return Promise.reject(err.error);
  }
};
const getPayGradeImportHelp = async (pay_grade_id = null) => {
  try {
    const filters = [];
    if (pay_grade_id) {
      filters.push(`pay_grade_id==${pay_grade_id}`);
    }
    const params = {
      filters: filters.join(','),
    };
    const query = new URLSearchParams(params);
    if (pay_grade_id) {
      const response = await networkService.get(`${baseUrl}/help/?${query}`);
      return response?.data;
    }
    const response = await networkService.get(`${baseUrl}/help`);
    return response?.data;
  } catch (err) {
    return Promise.reject(err.error);
  }
};

export const payGradeService = {
  getPayGrades,
  create,
  update,
  delete: _delete,
  deletePayGradeArray,
  getById,
  getPayGradeUsageById,
  getPayGradeImportCsv,
  uploadPayGradeImportCsv,
  exportPayGradeCsv,
  getPayGradeImportHelp,
};
