import React from 'react';
import { useSelector } from 'react-redux';
import { IconButton, TableCell, TableRow, Typography } from '@mui/material';

import { selectActiveUser } from 'features/account/accountSlice';
import { SystemRoleEnums } from 'helpers';
import { Each } from 'helpers/Each';
import { mdTheme } from 'theme';
import { ReactComponent as GroupTrainingSmallIcon } from '../../../assets/group_train_sm.svg';
import { ReactComponent as ViewIcon } from '../../../assets/view-icon.svg';

const ExpiryCapabilityData = ({
  columnData,
  handleEmployeeData,
  handleCapabilityForm,
}) => {
  //#region Constants
  const theme = mdTheme;
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  //#endregion State

  //#region Selectors
  const user = useSelector(selectActiveUser);
  //#endregion Selectors

  //#region Refs
  //#endregion Refs

  //#region Effects
  //#endregion Effects

  //#region Methods
  //#endregion Methods

  //#region Render time calcs
  //#endregion Render time calcs

  //#region Render

  return (
    <>
      {columnData?.capabilities?.length > 0 && (
        <Each
          of={columnData?.capabilities}
          render={(row, index) => (
            <React.Fragment key={row?.capability_id}>
              <TableRow
                sx={{
                  background:
                    index % 2 === 0
                      ? theme.colors.primarySectionForeground
                      : theme.palette.common.darkGray,
                }}
              >
                <TableCell
                  sx={{
                    background:
                      index % 2 === 0
                        ? theme.colors.primarySectionForeground
                        : theme.palette.common.darkGray,
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    {row?.display_name}
                    {SystemRoleEnums.NotUserAndAdmin.includes(user?.role) && (
                      <IconButton
                        onClick={(event) =>
                          handleEmployeeData(event, row.capability_id)
                        }
                        sx={{
                          width: '2rem',
                          height: '2rem',
                          overflow: 'hidden',
                          marginLeft: 'auto',
                        }}
                      >
                        <GroupTrainingSmallIcon />
                      </IconButton>
                    )}
                    {SystemRoleEnums.EnableAdminRoles.includes(user?.role) && (
                      <IconButton
                        onClick={() => handleCapabilityForm(row?.capability_id)}
                        sx={{
                          width: '2rem',
                          height: '2rem',
                          overflow: 'hidden',
                        }}
                      >
                        <ViewIcon />
                      </IconButton>
                    )}
                  </Typography>
                </TableCell>
                {columnData?.employees?.length > 0 ? (
                  <Each
                    of={columnData.employees}
                    render={(col, i) => (
                      <>
                        <TableCell
                          sx={{
                            background: columnData.colors.find(
                              (level) =>
                                level.color_id ===
                                col.capability_values[row.capability_id]
                                  ?.color_id
                            )?.color,
                            textAlign: 'center',
                          }}
                        >
                          <Typography
                            sx={{
                              color: '#ffffff',
                              textShadow: '0 0 5px #ffffff, 0 0 5px #000000',
                            }}
                          >
                            {col.capability_values[row.capability_id]?.text}
                          </Typography>
                        </TableCell>
                      </>
                    )}
                  />
                ) : (
                  <TableCell> N/A </TableCell>
                )}
                <TableCell
                  sx={{
                    background: theme.colors.primarySectionForeground,
                  }}
                ></TableCell>
              </TableRow>
            </React.Fragment>
          )}
        />
      )}
    </>
  );
  //#endregion Render
};
export { ExpiryCapabilityData };
