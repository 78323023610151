import { createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const tabStyles = makeStyles((theme) => {
  return createStyles({
    tabRoot: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      width: '100%',
      height: '100%',
      backgroundColor: 'inherit',
      '& header': {
        backgroundColor: 'inherit',
        boxShadow: 'unset',
        borderBottom: `1px solid ${theme.palette.common.grey}`,
      },
    },
    tabs: {
      '& button': {
        fontSize: '16px',
        color: theme.palette.common.tableBorder,
      },
      '& .Mui-selected': {
        color: `${theme.palette.secondary.main} !important`,
        fontWeight: '900',
      },
      '& .MuiTabs-indicator': {
        height: '5px',
        backgroundColor: theme.palette.background.drawer,
      },
    },
  });
});

export { tabStyles };
