import { useEffect, useRef, useState } from 'react';
import { Grid } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import {
  fetchCostCenterDropDown,
  fetchOfficeByCostCenterId,
  getCostCenterDropDownStatus,
  getOfficeByCostCenterIdDropDownStatus,
  resetOfficeIdDropDown,
  selectCostCenterDropDown,
  selectOfficeByCostCenterIdDropDown,
} from 'features/AdminSettingsDropDown/dropDownSlice';
import { clearFilter } from 'helpers/clearFilter';

import { FilterAccordion } from 'shared/components/_Accordion';
import { SelectionAutoComplete } from 'shared/components/_Autocomplete/SelectionAutocomplete';
import {
  selectAdminSettingCache,
  updateAdminSettingCache,
} from 'features/global/visibilitySlice';
import { useDebounce } from 'hooks/useDebounce';
import { COST_CENTER_CACHE } from 'helpers';
import { FormTextField } from 'shared/components/FormFields/TextField';

const ItemCostCenterFilter = ({
  setItemCostCenterPayload,
  itemCostCenterPayload,
}) => {
  //#region Constants
  const dispatch = useDispatch();
  const getCacheData = useSelector(selectAdminSettingCache);
  const costCenterCacheData = getCacheData?.[COST_CENTER_CACHE];
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  const [initValues, setInitValues] = useState({
    cc_id: '',
    psntl: '',
    cost_center_id: null,
    office_id: null,
  });
  //#endregion State

  //#region Selectors
  //#endregion Selectors

  //#region Refs
  const formRef = useRef();
  //#endregion Refs

  //#region Effects
  useEffect(() => {
    setInitValues({ ...initValues, ...costCenterCacheData });
  }, [getCacheData, itemCostCenterPayload]);
  //#endregion Effects

  //#region Methods
  const handleSearch = useDebounce((ccId, psntl, costCenterId, officeId) => {
    setItemCostCenterPayload({
      ...itemCostCenterPayload,
      cc_id: ccId,
      psntl: psntl,
      cost_center_id: costCenterId,
      office_id: officeId,
    });
  }, 1000);

  const removeHandleSearch = useDebounce((costCenterId, officeId) => {
    setItemCostCenterPayload({
      ...itemCostCenterPayload,
      cost_center_id: costCenterId,
      office_id: officeId,
    });
  }, 1000);

  const handleResetFilters = () => {
    const isFilterData = clearFilter(formRef);
    if (isFilterData) {
      formRef?.current?.resetForm();
      setItemCostCenterPayload({
        ...itemCostCenterPayload,
        cc_id: '',
        psntl: '',
        cost_center_id: null,
        office_id: null,
      });
      dispatch(fetchOfficeByCostCenterId());
      dispatch(updateAdminSettingCache({ key: COST_CENTER_CACHE, ...{} }));
    }
  };
  //#endregion Methods

  //#region Render time calcs
  //#endregion Render time calcs

  //#region Render

  return (
    <FilterAccordion
      filteredTitles={itemCostCenterPayload}
      expandedId="pay-plan"
      styles={{ marginBottom: '1.5rem' }}
      onResetFilters={handleResetFilters}
    >
      <Formik
        enableReinitialize={true}
        initialValues={{ ...initValues }}
        innerRef={formRef}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <Grid container columnSpacing={'1rem'}>
              <Grid item={true} xs={12} md={6} lg={3}>
                <FormTextField
                  name="cc_id"
                  label="CC ID"
                  placeholder="CC ID"
                  fullWidth
                  onChange={(e) => {
                    const searchTerm = e.target.value;
                    setFieldValue('cc_id', searchTerm);
                    handleSearch(
                      searchTerm,
                      values?.psntl,
                      values?.cost_center_id,
                      values?.office_id
                    );
                  }}
                />
              </Grid>
              <Grid item={true} xs={12} md={6} lg={3}>
                <FormTextField
                  name="psntl"
                  placeholder="psntl"
                  label="PSNTL"
                  fullWidth
                  onChange={(e) => {
                    const searchTerm = e.target.value;
                    setFieldValue('psntl', searchTerm);
                    handleSearch(
                      values?.cc_id,
                      searchTerm,
                      values?.cost_center_id,
                      values?.office_id
                    );
                  }}
                />
              </Grid>
              <Grid item={true} xs={12} md={6} lg={3}>
                <Field name="cost_center_id">
                  {({ field: { value }, form: { setFieldValue } }) => (
                    <SelectionAutoComplete
                      title={`Select Cost Center`}
                      keyProperty="value"
                      nameProperty="text"
                      entityIds={value?.value}
                      setEntityIds={setFieldValue}
                      entitySelector={selectCostCenterDropDown}
                      entityStatusSelector={getCostCenterDropDownStatus}
                      onCustomChange={(value) => {
                        setFieldValue('cost_center_id', value);
                        handleSearch(values?.cc_id, values?.psntl, value, null);
                        dispatch(resetOfficeIdDropDown());
                      }}
                      fetchEntityPage={fetchCostCenterDropDown}
                      cancelItemTagEdit={() => {
                        setFieldValue('cost_center_id', null);
                        removeHandleSearch(null, values?.office_id);
                        dispatch(resetOfficeIdDropDown());
                      }}
                      showCloseItemEdit={true}
                      formField="cost_center_id"
                      multiSelection={false}
                      hideCheckIcon={true}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item={true} xs={12} md={6} lg={3}>
                <Field name="office_id">
                  {({ field: { value }, form: { setFieldValue } }) => (
                    <SelectionAutoComplete
                      title={`Select Office`}
                      keyProperty="value"
                      nameProperty="text"
                      entityIds={value?.value}
                      setEntityIds={setFieldValue}
                      entitySelector={selectOfficeByCostCenterIdDropDown}
                      entityStatusSelector={
                        getOfficeByCostCenterIdDropDownStatus
                      }
                      onCustomChange={(value) => {
                        setFieldValue('office_id', value);
                        handleSearch(
                          values?.cc_id,
                          values?.psntl,
                          values?.cost_center_id,
                          value
                        );
                      }}
                      fetchEntityPage={fetchOfficeByCostCenterId}
                      cancelItemTagEdit={() => {
                        setFieldValue('office_id', null);
                        removeHandleSearch(values?.cost_center_id, null);
                      }}
                      fetchParams={{
                        cost_center_id: values?.cost_center_id?.value,
                      }}
                      formField="office_id"
                      showCloseItemEdit={true}
                      multiSelection={false}
                      hideCheckIcon={true}
                    />
                  )}
                </Field>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </FilterAccordion>
  );
  //#endregion Render
};
export { ItemCostCenterFilter };
