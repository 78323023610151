import { config } from 'ectl-config';
import { FormDataUtils, getNetworkService } from 'helpers';
import { createProgressParams } from 'helpers/progressUtils';

const accountService = { config: config };
const networkService = getNetworkService({
  config: config,
  checkAuth: accountService.checkAuth,
});
const baseUrl = `${config.apiUrl}/Admin/Job`;

const getJobs = async (
  startIndex = 1,
  stopIndex = 10,
  searchString = '',
  sort = 'display_name',
  displayName = '',
  seriesId = null,
  familyDisplayName = '',
  familySeriesId = null
) => {
  const filters = [];
  if (searchString) {
    filters.push(`quick_search@=*${searchString}`);
  }
  if (displayName) {
    filters.push(`display_name@=*${displayName}`);
  }
  if (seriesId !== null && seriesId !== undefined && seriesId !== '') {
    filters.push(`series_id==${seriesId}`);
  }
  if (familyDisplayName) {
    filters.push(`family_display_name@=*${familyDisplayName}`);
  }
  if (
    familySeriesId !== null &&
    familySeriesId !== undefined &&
    familySeriesId !== ''
  ) {
    filters.push(`family_series_id==${familySeriesId}`);
  }
  const params = {
    filters: filters.join(','),
    sorts: sort,
    start_index: startIndex,
    stop_index: stopIndex,
  };
  const query = new URLSearchParams(params);
  const response = await networkService.get(`${baseUrl}/?${query}`);
  const pageInfo = response.headers['x-pagination'];
  return {
    pageInfo: pageInfo,
    jobs: response?.data,
  };
};

const create = async (params) => {
  const response = await networkService.post(baseUrl, params);

  if (response.error) {
    return Promise.reject(response.error);
  }

  return response;
};

const update = async (params) => {
  const response = await networkService.put(
    `${baseUrl}/${params.job_id}`,
    params
  );

  if (response.error) {
    return Promise.reject(response.error);
  }

  return response.data;
};

const _delete = async (job_id) => {
  try {
    const response = await networkService.delete(`${baseUrl}/${job_id}`);
    return response;
  } catch (e) {
    return Promise.reject(e.error);
  }
};

const getById = async (id) => {
  const response = await networkService.get(`${baseUrl}/${id}`);
  return response.data;
};

const deleteJobsArray = async (ids) => {
  try {
    const deleteObject = {
      ids: ids,
    };
    const response = await networkService.deleteWithBody(baseUrl, deleteObject);
    return response.data;
  } catch (e) {
    return Promise.reject(e.error);
  }
};

const getJobUsageById = async (tagId) => {
  const response = await networkService.get(`${baseUrl}/${tagId}`);
  return response.data;
};

const getJobImportCsv = async () => {
  try {
    const response = await networkService.get(`${baseUrl}/import`);
    return response?.data;
  } catch (err) {
    return Promise.reject(err.error);
  }
};

const uploadJobImportCsv = async (params) => {
  try {
    let formData = new FormData();
    FormDataUtils.safeAppend(formData, [['file', params.file]]);
    let progressParams = createProgressParams(params);
    const response = await networkService.postMultiFormData(
      `${baseUrl}/import`,
      formData,
      progressParams
    );
    return response.data;
  } catch (err) {
    return err;
  }
};

const exportJobCsv = async (
  displayName = '',
  seriesId = null,
  familyDisplayName = '',
  familySeriesId = null
) => {
  try {
    const filters = [];

    if (displayName) {
      filters.push(`display_name@=*${displayName}`);
    }
    if (seriesId) {
      filters.push(`series_id==${seriesId}`);
    }
    if (familyDisplayName) {
      filters.push(`family_display_name@=*${familyDisplayName}`);
    }
    if (familySeriesId) {
      filters.push(`family_series_id==${familySeriesId}`);
    }

    const params = {
      filters: filters.join(','),
    };
    const query = new URLSearchParams(params).toString();
    const response = await networkService.get(`${baseUrl}/export?${query}`);
    return response?.data;
  } catch (err) {
    return Promise.reject(err.error);
  }
};

const getJobImportHelp = async (job_id = null) => {
  try {
    const filters = [];
    if (job_id) {
      filters.push(`job_id==${job_id}`);
    }
    const params = {
      filters: filters.join(','),
    };
    const query = new URLSearchParams(params);
    if (job_id) {
      const response = await networkService.get(`${baseUrl}/help/?${query}`);
      return response?.data;
    }
    const response = await networkService.get(`${baseUrl}/help`);
    return response?.data;
  } catch (err) {
    return Promise.reject(err.error);
  }
};

export const jobService = {
  getJobs,
  create,
  update,
  delete: _delete,
  deleteJobsArray,
  getById,
  getJobUsageById,
  getJobImportCsv,
  uploadJobImportCsv,
  exportJobCsv,
  getJobImportHelp,
};
