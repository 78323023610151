import { config } from 'ectl-config';
import { getNetworkService } from 'helpers';

const baseUrl = `${config.apiUrl}/Admin/Depot`;
const fieldIdsUrl = `${config.apiUrl}/dropdown/admin`;

const networkService = getNetworkService({
  config: config,
});

const fetchAdminDepotById = async (id) => {
  const response = await networkService.get(`${baseUrl}/${id}`);
  return response.data;
};

const fetchAdminDepot = async () => {
  const response = await networkService.get(`${baseUrl}`);
  return response?.data;
};

const updateAdminDepot = async (params) => {
  const response = await networkService.put(baseUrl, params);
  return response.data;
};

const runDepotReportCrunch = async (params) => {
  const response = await networkService.get(`${baseUrl}/report`, 300000);
  return response?.data;
};

const fetchPayPlanFields = async () => {
  try {
    const response = await networkService.get(`${fieldIdsUrl}/payplan/fields`);
    return response?.data;
  } catch (e) {
    return Promise.reject(e.error);
  }
};

const fetchPayGradeFields = async () => {
  try {
    const response = await networkService.get(`${fieldIdsUrl}/paygrade/fields`);
    return response?.data;
  } catch (e) {
    return Promise.reject(e.error);
  }
};

const fetchJobFields = async () => {
  try {
    const response = await networkService.get(`${fieldIdsUrl}/job/fields`);
    return response?.data;
  } catch (e) {
    return Promise.reject(e.error);
  }
};

const fetchJobRoleFields = async () => {
  try {
    const response = await networkService.get(`${fieldIdsUrl}/jobrole/fields`);
    return response?.data;
  } catch (e) {
    return Promise.reject(e.error);
  }
};

const fetchCapabilityFields = async () => {
  try {
    const response = await networkService.get(
      `${fieldIdsUrl}/capability/fields`
    );
    return response?.data;
  } catch (e) {
    return Promise.reject(e.error);
  }
};

const fetchSkillFields = async () => {
  try {
    const response = await networkService.get(`${fieldIdsUrl}/skill/fields`);
    return response?.data;
  } catch (e) {
    return Promise.reject(e.error);
  }
};

const fetchLevelFields = async () => {
  try {
    const response = await networkService.get(`${fieldIdsUrl}/level/fields`);
    return response?.data;
  } catch (e) {
    return Promise.reject(e.error);
  }
};

const fetchEmployeeFields = async () => {
  try {
    const response = await networkService.get(`${fieldIdsUrl}/employee/fields`);
    return response?.data;
  } catch (e) {
    return Promise.reject(e.error);
  }
};

const fetchCostCenterFields = async () => {
  try {
    const response = await networkService.get(
      `${fieldIdsUrl}/costcenter/fields`
    );
    return response?.data;
  } catch (e) {
    return Promise.reject(e.error);
  }
};

const fetchOfficeFields = async () => {
  try {
    const response = await networkService.get(`${fieldIdsUrl}/office/fields`);
    return response?.data;
  } catch (e) {
    return Promise.reject(e.error);
  }
};

const fetchExternalApiFields = async () => {
  try {
    const response = await networkService.get(
      `${fieldIdsUrl}/externalapi/fields`
    );
    return response?.data;
  } catch (e) {
    return Promise.reject(e.error);
  }
};

export const adminDepotService = {
  fetchAdminDepotById,
  fetchAdminDepot,
  updateAdminDepot,
  runDepotReportCrunch,

  fetchPayPlanFields,
  fetchPayGradeFields,
  fetchJobFields,
  fetchJobRoleFields,
  fetchCapabilityFields,
  fetchSkillFields,
  fetchLevelFields,
  fetchEmployeeFields,
  fetchCostCenterFields,
  fetchOfficeFields,
  fetchExternalApiFields,
};
