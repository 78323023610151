import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { appStyles } from 'app/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import DisplayModal from 'components/Modals/DisplayModal/DisplayModal';
import { Header } from 'components/_Layout/AuthenticatedTemplate/Header/Header';
import { Nav } from 'components/_Layout/AuthenticatedTemplate/Nav/Nav';
import { selectActiveUser } from 'features/account/accountSlice';
import { useSelector } from 'react-redux';
import {
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { IconButton } from '@mui/material';
import { SystemRoleEnums } from 'helpers';
import { MdOutlineArrowBack } from 'react-icons/md';

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © '}
      <Link color="inherit" href="/">
        SMARTS
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 250;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    overflowX: 'hidden',
    backgroundColor: theme.palette.background.drawer,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

function AuthenticatedTemplate({
  title,
  theme,
  children,
  requiredRoles,
  isPagination,
  backRoute,
}) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isHideMenu = searchParams ? searchParams.get('hide-menu') : null;
  const activeUser = useSelector(selectActiveUser);
  let location = useLocation();

  const [open, setOpen] = useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const classes = appStyles(theme);
  return activeUser ? (
    requiredRoles != undefined &&
    requiredRoles.length > 0 &&
    requiredRoles.filter((x) => activeUser.role === x).length === 0 ? (
      <Navigate to="/" state={{ from: location }} replace />
    ) : (
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Header
            theme={theme}
            open={open}
            toggleDrawer={toggleDrawer}
            isHideMenu={isHideMenu}
          />
        </AppBar>
        {isHideMenu !== 'true' && (
          <Drawer variant="permanent" sx={{ height: '100vh' }} open={open}>
            <Nav theme={theme} toggleDrawer={toggleDrawer} />
          </Drawer>
        )}
        <DisplayModal />
        <Box
          component="main"
          sx={{
            backgroundColor: '#f7fbfb',
            flexGrow: 1,
            height: '100vh',
            overflowX: 'hidden',
            overflowY: 'auto',
          }}
        >
          <Toolbar />
          <div className={classes.mainContentTitle}>
            <Typography
              variant="h6"
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {isPagination && activeUser?.role !== SystemRoleEnums.User && (
                <IconButton
                  sx={{ margin: '0 1rem 0' }}
                  onClick={() =>
                    isHideMenu === 'true'
                      ? navigate(`${backRoute}?hide-menu=${isHideMenu}`)
                      : navigate(backRoute)
                  }
                >
                  <MdOutlineArrowBack sx={{ color: '#000000' }} />
                </IconButton>
              )}
              {title}
            </Typography>
          </div>
          <Box
            sx={{
              padding: '1.5rem',
              margin: '0 -1.5rem',
              background:
                'linear-gradient(0deg, rgba(247,251,251,1) 0%,rgba(234,238,239,1) 50%)',
            }}
          ></Box>
          <Container
            maxWidth={children?.type?.name !== 'Teams' ? 'xl' : ''}
            sx={{
              mb: 4,
              minHeight: 'calc(100vh - 192px)',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {children}
            <Copyright sx={{ pt: 4, marginTop: 'auto' }} />
          </Container>
        </Box>
      </Box>
    )
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
}

export { AuthenticatedTemplate };
