import { makeStyles } from '@mui/styles';

const collapsedTableStyle = makeStyles((theme) => ({
  badgeWrapper: {
    marginLeft: '1rem',
    '& .MuiBadge-badge': {
      position: 'relative',
      padding: '0.2rem 0.8rem',
      borderRadius: '0.2rem',
      color: theme.colors.textWhite,
      fontWeight: 'bold',
      transform: 'inherit',
    },
  },
  error: {
    '& .MuiBadge-badge': {
      background: theme.colors.error,
    },
  },
  warning: {
    '& .MuiBadge-badge': {
      background: theme.colors.warning,
    },
  },
  default: {
    '& .MuiBadge-badge': {
      background: '#5a5a5a',
    },
  },
  tableContainer: {
    // maxHeight: 'calc(100vh - 300px)',
    overflow: 'auto',
  },
}));

export { collapsedTableStyle };
