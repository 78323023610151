import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import clsx from 'clsx';
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  DialogTitle,
  Paper,
} from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import {
  MdCheck,
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdClear,
  MdRefresh,
} from 'react-icons/md';

import { makeFormStyles } from 'forms/style';
import { autoCompleteStyles } from './style';
import { mdTheme } from 'theme';
import { LoadingStatus } from 'helpers';
import Loader from 'shared/components/Loader';
import { MultiSelectDoneButton } from 'shared/components/MultiSelectDoneButton';
import { deleteKey } from 'helpers/cacheStore';

const icon = <MdCheckBoxOutlineBlank />;
const checkedIcon = <MdCheckBox />;
const filter = createFilterOptions();

const SelectionAutoComplete = ({
  title,
  keyProperty,
  nameProperty,
  entityIds,
  setEntityIds,
  entitySelector,
  entityStatusSelector,
  fetchEntityPage,
  fetchParams,
  formField,
  placeholder,
  multiSelection = true,
  errorMessage,
  touched,
  enableCreatable = false,
  creatableDialogTitle = '',
  creatableDialogFieldLabel = '',
  creatableDispatch,
  creatableParams = null,
  enableCache = false,
  refreshCacheType = '',
  extraOptions = [],
  readOnly = false,
  handleItemTagEdit = () => {},
  showCloseItemEdit = false,
  cancelItemTagEdit,
  size = '',
  showInitialValuesAlways = false,
  freeSolo = false,
  onCustomChange = function () {},
  selectStyle = {},
  hideCheckIcon = false,
  fieldValidation = false,
  limitTags = 2,
  fetchQueryParams = null,
}) => {
  const DIALOG_INITIAL_STATE = {
    isDialogOpen: false,
    [nameProperty]: '',
  };

  const formClasses = makeFormStyles(mdTheme);
  const classes = autoCompleteStyles(mdTheme);
  const dispatch = useDispatch();
  let entities = useSelector(entitySelector) || [];
  entities = [...extraOptions, ...entities];
  const entityStatus = useSelector((state) => entityStatusSelector(state));
  const entitiesByIdMap = Object.create(null);
  const [isLoading, setIsLoading] = useState(false);
  const [manageDialog, setManageDialog] = React.useState(DIALOG_INITIAL_STATE);

  entities.forEach((row) => {
    if (!entitiesByIdMap[row[keyProperty]]) {
      entitiesByIdMap[row[keyProperty]] = row;
    }
  });

  const getDefaultValue = () => {
    if (multiSelection) {
      const selectedValue = [];
      entityIds?.forEach((item) => {
        if (entitiesByIdMap[item]) {
          selectedValue.push(entitiesByIdMap[item]);
        }
      });
      return selectedValue || [];
    } else {
      return entitiesByIdMap[entityIds] || null;
    }
  };

  const handleChange = (newValue) => {
    if (!multiSelection) {
      if (enableCreatable) {
        if (typeof newValue === 'string') {
          // timeout to avoid instant validation of the dialog's form.
          setTimeout(() => {
            setManageDialog({
              [nameProperty]: newValue,
              keyProperty: keyProperty,
              isDialogOpen: true,
            });
          });
        } else if (newValue && newValue.inputValue) {
          setManageDialog({
            [nameProperty]: newValue.inputValue,
            keyProperty: keyProperty,
            isDialogOpen: true,
          });
        }
      }
      setEntityIds(formField, newValue ? newValue[keyProperty] : '');
    } else {
      if (enableCreatable) {
        const newValueObj = newValue[newValue.length - 1];
        if (typeof newValueObj === 'string') {
          // timeout to avoid instant validation of the dialog's form.
          setTimeout(() => {
            setManageDialog({
              [nameProperty]: newValueObj,
              keyProperty: keyProperty,
              isDialogOpen: true,
            });
          });
        } else if (newValueObj && newValueObj.inputValue) {
          setManageDialog({
            [nameProperty]: newValueObj.inputValue,
            keyProperty: keyProperty,
            isDialogOpen: true,
          });
        }
      }
      setEntityIds(
        formField,
        newValue?.map((item) => (item ? item[keyProperty] : null))
      );
    }
  };

  const onRefreshIconClick = () => {
    deleteKey(refreshCacheType);
    dispatch(fetchEntityPage({ ...fetchParams }));
  };

  const filterOption = (options, params) => {
    const filtered = filter(options, params);
    if (enableCreatable && params?.inputValue !== '') {
      const isExisting = options.some(
        (option) => params?.inputValue === option[nameProperty]
      );
      if (params?.inputValue !== '' && !isExisting) {
        filtered.push({
          inputValue: params.inputValue,
          [nameProperty]: `Add "${params.inputValue}"`,
        });
      }
    }
    return filtered;
  };

  const handleSubmit = async () => {
    const result = await dispatch(
      creatableDispatch(
        creatableParams ? { ...manageDialog, ...creatableParams } : manageDialog
      )
    );
    if (!result.error) {
      const newId = result.payload[keyProperty];
      const updatedIds = multiSelection
        ? [...entityIds.filter((id) => id !== undefined), newId]
        : newId;
      setEntityIds(formField, updatedIds);
    }
    setManageDialog(DIALOG_INITIAL_STATE);
  };

  useEffect(() => {
    if (entityStatus === LoadingStatus.Idle) {
      setIsLoading(true);
      if (fetchQueryParams) {
        dispatch(fetchEntityPage({ ...fetchParams, ...fetchQueryParams }));
      } else {
        dispatch(fetchEntityPage({ ...fetchParams }));
      }
    }
    if (entityStatus === LoadingStatus.Loaded) {
      setIsLoading(false);
    }
  }, [entityStatus, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  return isLoading ? (
    <Loader
      styles={{
        width: '100%',
        marginTop: '8px',
        marginBottom: '4px',
        height: '56px',
      }}
      enableBorder
    />
  ) : (
    <>
      <div
        className={clsx([
          classes.root,
          showCloseItemEdit && classes.isCloseButton,
        ])}
      >
        <Autocomplete
          sx={selectStyle}
          size={size}
          multiple={multiSelection}
          readOnly={readOnly}
          limitTags={limitTags}
          variant="outline"
          id="checkboxes-tags-demo"
          name={formField}
          onChange={(event, newValue) => {
            if (showInitialValuesAlways === false) {
              handleChange(newValue);
              onCustomChange(newValue);
            }
          }}
          disableClearable={showCloseItemEdit}
          options={entities}
          disableCloseOnSelect={multiSelection}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === 'string') {
              return option.trim() ? option : '-';
            }
            if (option.inputValue) {
              return option.inputValue.trim() ? option.inputValue : '-';
            }
            // Regular option
            return option[nameProperty].trim() ? option[nameProperty] : '-';
          }}
          value={getDefaultValue()}
          renderOption={(props, option, { selected }) => {
            return (
              <li
                {...props}
                key={option[keyProperty] || props.id || Date.now()}
              >
                {multiSelection && (
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                )}
                {option[nameProperty].trim() ? option[nameProperty] : '-'}
              </li>
            );
          }}
          renderInput={(params) => (
            <>
              <TextField
                variant="outlined"
                error={fieldValidation}
                {...params}
                label={title}
                className={clsx([
                  errorMessage && touched ? classes.errorField : '',
                  enableCache && showCloseItemEdit === false
                    ? classes.clearIndicator
                    : '',
                ])}
                placeholder={entityIds?.length > 0 ? '' : placeholder}
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  ...params.inputProps,
                  onKeyDown: (e) => {
                    if (e.key === 'Enter') {
                      handleItemTagEdit();
                    }
                    if (e.key === 'Escape') {
                      cancelItemTagEdit();
                    }
                  },
                }}
              />
              {enableCache && (
                <IconButton
                  onClick={onRefreshIconClick}
                  aria-label="close"
                  sx={{
                    position: 'absolute',
                    top: '7px',
                    right: 0,
                    color: '#707070',
                  }}
                  size="large"
                >
                  <MdRefresh style={{ fontSize: '18px' }} />
                </IconButton>
              )}
              {showCloseItemEdit && getDefaultValue() && (
                <>
                  {!hideCheckIcon && (
                    <IconButton
                      onClick={(e) => handleItemTagEdit()}
                      aria-label="close"
                      sx={{
                        right: '20px',
                        position: 'absolute',
                        top: '50%',
                        transform: 'translateY(-50%)',
                      }}
                      size="large"
                    >
                      <MdCheck style={{ fontSize: '18px' }} />
                    </IconButton>
                  )}
                  {!readOnly && (
                    <IconButton
                      onClick={(e) => {
                        cancelItemTagEdit();
                      }}
                      aria-label="close"
                      sx={{
                        position: 'absolute',
                        right: freeSolo ? '0px' : '30px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        padding: '0',
                      }}
                      size="large"
                    >
                      <MdClear style={{ fontSize: '18px' }} />
                    </IconButton>
                  )}
                </>
              )}
            </>
          )}
          filterOptions={(options, params) => {
            return filterOption(options, params);
          }}
          freeSolo={freeSolo}
          PaperComponent={
            MultiSelectDoneButton && multiSelection
              ? MultiSelectDoneButton
              : Paper
          }
        />
      </div>

      <Dialog
        open={manageDialog?.isDialogOpen}
        onClose={() =>
          setManageDialog({ ...manageDialog, isDialogOpen: false })
        }
      >
        <DialogTitle>Add a new {creatableDialogTitle}</DialogTitle>
        <DialogContent sx={{ width: '400px' }}>
          <DialogContentText marginBottom={'5px'}>
            Add a new item to available {creatableDialogTitle} list.
          </DialogContentText>
          <TextField
            fullWidth
            autoFocus
            margin="dense"
            id="name"
            value={manageDialog[nameProperty]}
            onChange={(event) =>
              setManageDialog({
                ...manageDialog,
                [nameProperty]: event.target.value,
                keyProperty: keyProperty,
              })
            }
            label={creatableDialogFieldLabel}
            type="text"
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button
            className={formClasses.cancel}
            onClick={() =>
              setManageDialog({
                ...manageDialog,
                isDialogOpen: false,
              })
            }
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            className={formClasses.submit}
            type="button"
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export { SelectionAutoComplete };
