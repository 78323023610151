import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { employeeService } from 'features/services/employee.service';
import { LoadingStatus, SavingStatus } from 'helpers/enums';

const initialState = {
  employeeStatus: LoadingStatus.Idle,
  skillCertificateStatus: LoadingStatus.Idle,
  employee: [],
  skillCertifications: [],
  skillCertificationsPageInfo: {},
  employeePageInfo: {},
  displayEmployee: {},
  displayJobs: [],
  employeeUsage: [],
  loadedEmployeesMap: {},
  error: null,
  employeeTabStatus: LoadingStatus.Idle,
  employeeImportInfo: [],
  employeeImportInfoStatus: LoadingStatus.Idle,
  certificationImportInfo: [],
  certificationImportInfoStatus: LoadingStatus.Idle,
  dodIdError: '',
  emailError: '',
};

export const fetchEmployeeById = createAsyncThunk(
  'employee/fetchEmployeeById',
  async (employeeId) => {
    const response = await employeeService.fetchEmployeeById(employeeId);
    response.id = employeeId;
    return response;
  }
);

export const fetchEmployees = createAsyncThunk(
  'Employee/fetchEmployees',
  async (params = {}) =>
    await employeeService.getEmployee(
      params.searchString,
      params.employee_id,
      params.first_name,
      params.last_name,
      params.dod_id,
      params.supervisor_employee_id,
      params.pay_plan_id,
      params.pay_plan_display_name,
      params.pay_grade_id,
      params.pay_grade_display_name,
      params.system_role_id,
      params.system_role_display_name,
      params.job_id,
      params.job_display_name,
      params.job_role_id,
      params.job_role_display_name,
      params.employee_status,
      params.cost_center_id,
      params.office_id,
      params.startIndex,
      params.stopIndex,
      params.sort
    )
);

export const fetchAdminEmployeeById = createAsyncThunk(
  'Employee/fetchAdminEmployeeById',
  async (employeeId) => {
    const response = await employeeService.getAdminEmployeeById(employeeId);
    response.id = employeeId;
    return response;
  }
);

export const createAdminEmployee = createAsyncThunk(
  'employee/createEmployee',
  async (params) => await employeeService.createAdminEmployees(params)
);

export const updateAdminEmployee = createAsyncThunk(
  'employee/updateEmployee',
  async (params) => await employeeService.updateAdminEmployees(params)
);

export const fetchEmployeeUsageById = createAsyncThunk(
  'groups/fetchEmployeeUsageById',
  async (levelId) => await employeeService.getById(levelId)
);

export const deleteEmployee = createAsyncThunk(
  'Employee/deleteEmployee',
  async (employee_id) => {
    const response = await employeeService.delete(employee_id);
    return response;
  }
);

export const deleteEmployeeArray = createAsyncThunk(
  'Employee/deleteEmployeeArray',
  async (levelIds) => {
    const response = await employeeService.deleteEmployeesArray(levelIds);
    return response;
  }
);

export const getEmployeeImportSampleCsv = createAsyncThunk(
  'Employee/getEmployeeImportSampleCsv',
  async () => await employeeService.getEmployeeImportCsv()
);

export const uploadEmployeeImportCsv = createAsyncThunk(
  'Employee/uploadEmployeeImportCsv',
  async (params) => await employeeService.uploadEmployeeImportCsv(params)
);

export const getCertificationImportSampleCsv = createAsyncThunk(
  'Employee/getCertificationImportSampleCsv',
  async () => await employeeService.getCertificationImportCsv()
);

export const uploadCertificationImportCsv = createAsyncThunk(
  'Employee/uploadCertificationImportCsv',
  async (params) => await employeeService.uploadCertificationImportCsv(params)
);

export const fetchSkillCertification = createAsyncThunk(
  'SkillCertification/fetchSkillCertification',
  async (params = {}) =>
    await employeeService.getSkillCertifications(
      params.employee_id,
      params.skill_id
    )
);

export const updateSkillCertification = createAsyncThunk(
  'SkillCertification/updateSkillCertification',
  async (params) => await employeeService.updateSkillCertification(params)
);

export const exportEmployeeCsv = createAsyncThunk(
  'Employee/exportEmployeeCsv',
  async (params) =>
    await employeeService.exportEmployeesCsv(
      params.employee_id,
      params.first_name,
      params.last_name,
      params.dod_id,
      params.supervisor_employee_id,
      params.pay_plan_id,
      params.pay_plan_display_name,
      params.pay_grade_id,
      params.pay_grade_display_name,
      params.system_role_id,
      params.system_role_display_name,
      params.job_id,
      params.job_display_name,
      params.job_role_id,
      params.job_role_display_name,
      params.employee_status
    )
);

export const fetchEmployeesReport = createAsyncThunk(
  'Employee/fetchEmployeesReport',
  async (params) =>
    await employeeService.getEmployeesReport(
      params.employee_id,
      params.first_name,
      params.last_name,
      params.dod_id,
      params.supervisor_employee_id,
      params.pay_plan_id,
      params.pay_plan_display_name,
      params.pay_grade_id,
      params.pay_grade_display_name,
      params.system_role_id,
      params.system_role_display_name,
      params.job_id,
      params.job_display_name,
      params.job_role_id,
      params.job_role_display_name,
      params.employee_status
    )
);

export const getImportEmployeeHelp = createAsyncThunk(
  'Employee/getImportEmployeeHelp',
  async (params) => await employeeService.getEmployeeImportHelp(params)
);

export const getImportCertificationHelp = createAsyncThunk(
  'Certification/getImportCertificationHelp',
  async (params) => await employeeService.getCertificationImportHelp(params)
);

export const validateDoDId = createAsyncThunk(
  'EmployeeForm/validateDoDId',
  async (params) =>
    await employeeService.getDodIdValidate(
      params.employee_id,
      params.dodId,
      params.signal
    )
);

export const validateEmail = createAsyncThunk(
  'EmployeeForm/validateEmail',
  async (params) => {
    return await employeeService.getEmailValidate(
      params.employee_id,
      params.email,
      params.signal
    );
  }
);

const employeeSlice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    resetEmployeeState: (state) => initialState,
    resetSkillCertificationState: (state) => {
      state.skillCertificateStatus = LoadingStatus.Idle;
      state.employeeStatus = LoadingStatus.Idle;
    },
    resetEmployeeInfoState: (state) => {
      state.employeeImportInfoStatus = LoadingStatus.Idle;
    },
    resetCertificationInfoState: (state) => {
      state.certificationImportInfoStatus = LoadingStatus.Idle;
    },
  },
  extraReducers: {
    [fetchEmployeeById.pending]: (state, action) => {
      state.employeeStatus = LoadingStatus.Loading;
    },
    [fetchEmployeeById.fulfilled]: (state, action) => {
      state.employeeStatus = LoadingStatus.Loaded;
    },
    [fetchEmployeeById.rejected]: (state, action) => {
      state.employeeStatus = LoadingStatus.Failed;
    },
    [fetchEmployees.pending]: (state, action) => {
      state.employeeTabStatus = LoadingStatus.Loading;
    },
    [fetchEmployees.fulfilled]: (state, action) => {
      state.employeePageInfo = action.payload.pageInfo;
      state.employeeTabStatus = LoadingStatus.Loaded;

      if (action.meta.arg.reset) {
        state.loadedEmployeesMap = {};
      }

      const startIndex = action.meta.arg.startIndex;
      const stopIndex = startIndex + action.payload.employee.length - 1;

      for (var i = startIndex; i <= stopIndex; i++) {
        state.loadedEmployeesMap[i] = LoadingStatus.Loaded;
      }

      // In case we didn't load as many as requested
      for (
        let j = stopIndex + 1;
        j <= action.payload?.pageInfo?.TotalCount;
        j++
      ) {
        delete state.loadedEmployeesMap[j];
      }

      if (action.meta.arg.reset) {
        state.employee = action.payload.employee;
      } else {
        state.employee = state.employee.concat(action.payload.employee);
      }
    },
    [fetchEmployees.rejected]: (state, action) => {
      state.employeeTabStatus = LoadingStatus.Failed;
    },
    [fetchAdminEmployeeById.pending]: (state, action) => {
      state.employeeTabStatus = LoadingStatus.Loading;
    },
    [fetchAdminEmployeeById.fulfilled]: (state, action) => {
      state.employeeTabStatus = LoadingStatus.Loaded;
      state.displayJobs = action.payload;
    },
    [fetchAdminEmployeeById.rejected]: (state, action) => {
      state.employeeTabStatus = LoadingStatus.Failed;
    },
    [createAdminEmployee.fulfilled]: (state, action) => {
      state.employeeTabStatus = LoadingStatus.Idle;
      state.displayJobs = action.payload;
    },
    [updateAdminEmployee.fulfilled]: (state, action) => {
      state.employeeTabStatus = LoadingStatus.Idle;
    },
    [deleteEmployee.fulfilled]: (state, action) => {
      state.employeeTabStatus = LoadingStatus.Idle;
    },
    [deleteEmployeeArray.fulfilled]: (state, action) => {
      state.employeeTabStatus = LoadingStatus.Idle;
    },
    [deleteEmployeeArray.rejected]: (state, action) => {
      state.employeeTabStatus = SavingStatus.Failed;
      state.error = action.error.message;
    },
    [fetchEmployeeUsageById.fulfilled]: (state, action) => {
      state.employeeTabStatus = action.payload;
    },
    [getEmployeeImportSampleCsv.fulfilled]: (state, action) => {
      if (action?.payload) {
        const url = window.URL.createObjectURL(new Blob([action.payload]));
        const link = document.createElement('a');
        link.href = url;
        let downloadName =
          action.payload.headers?.filename ?? 'Employee_import.csv';
        link.setAttribute('download', downloadName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    },
    [getEmployeeImportSampleCsv.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    [getCertificationImportSampleCsv.fulfilled]: (state, action) => {
      if (action?.payload) {
        const url = window.URL.createObjectURL(new Blob([action.payload]));
        const link = document.createElement('a');
        link.href = url;
        let downloadName =
          action.payload.headers?.filename ?? 'completions.csv';
        link.setAttribute('download', downloadName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    },
    [getCertificationImportSampleCsv.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    [fetchSkillCertification.pending]: (state, action) => {
      state.skillCertificateStatus = LoadingStatus.Loading;
    },
    [fetchSkillCertification.fulfilled]: (state, action) => {
      state.skillCertificateStatus = LoadingStatus.Loaded;
      state.skillCertifications = action.payload;
    },
    [fetchSkillCertification.rejected]: (state, action) => {
      state.skillCertificateStatus = LoadingStatus.Failed;
    },
    [updateSkillCertification.fulfilled]: (state, action) => {
      state.skillCertificateStatus = LoadingStatus.Idle;
    },
    [exportEmployeeCsv.fulfilled]: (state, action) => {
      if (action?.payload) {
        const url = window.URL.createObjectURL(new Blob([action.payload]));
        const link = document.createElement('a');
        link.href = url;
        let downloadName =
          action.payload.headers?.filename ?? 'Employee_export.csv';
        link.setAttribute('download', downloadName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    },
    [exportEmployeeCsv.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    [fetchEmployeesReport.fulfilled]: (state, action) => {
      if (action?.payload) {
        const url = window.URL.createObjectURL(new Blob([action.payload.data]));
        const link = document.createElement('a');
        link.href = url;
        let downloadName =
          action.payload.headers?.filename ?? 'User Report.csv';
        link.setAttribute('download', downloadName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    },
    [fetchEmployeesReport.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    [getImportEmployeeHelp.pending]: (state, action) => {
      state.employeeImportInfoStatus = LoadingStatus.Loading;
    },
    [getImportEmployeeHelp.fulfilled]: (state, action) => {
      state.employeeImportInfo = action.payload;
      state.employeeImportInfoStatus = LoadingStatus.Loaded;
    },
    [getImportEmployeeHelp.rejected]: (state, action) => {
      state.employeeImportInfoStatus = LoadingStatus.Failed;
    },
    [getImportCertificationHelp.pending]: (state, action) => {
      state.certificationImportInfoStatus = LoadingStatus.Loading;
    },
    [getImportCertificationHelp.fulfilled]: (state, action) => {
      state.certificationImportInfo = action.payload;
      state.certificationImportInfoStatus = LoadingStatus.Loaded;
    },
    [getImportCertificationHelp.rejected]: (state, action) => {
      state.certificationImportInfoStatus = LoadingStatus.Failed;
    },
    [validateDoDId.fulfilled]: (state, action) => {
      state.dodIdError = action.payload;
    },
    [validateDoDId.rejected]: (state, action) => {
      state.dodIdError = '';
    },
    [validateEmail.fulfilled]: (state, action) => {
      state.emailError = action.payload;
    },
    [validateEmail.rejected]: (state, action) => {
      state.emailError = '';
    },
  },
});

export const selectEmployee = (state) => state.employee.employee;
export const getEmployeeStatus = (state) => state.employee.employeeTabStatus;
export const getEmployeePageInfo = (state) => state.employee.employeePageInfo;
export const getEmployeeUsage = (state) => state.employee.employeeUsage;

export const selectSkillCertifications = (state) =>
  state.employee.skillCertifications;
export const getSkillCertificationStatus = (state) =>
  state.employee.skillCertificateStatus;
export const getSkillCertificationsPageInfo = (state) =>
  state.employee.skillCertificationsPageInfo;

export const selectJobs = (state) => state.employee.displayJobs;
export const getEmployeeInfoStatus = (state) =>
  state.employee.employeeImportInfoStatus;
export const getCertificationInfoStatus = (state) =>
  state.employee.certificationImportInfoStatus;

export const {
  resetEmployeeState,
  resetSkillCertificationState,
  resetEmployeeInfoState,
  resetCertificationInfoState,
} = employeeSlice.actions;

export default employeeSlice.reducer;
