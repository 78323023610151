import React from 'react';
import { Box, IconButton, InputBase } from '@mui/material';
import { MdFilterList, MdSearch } from 'react-icons/md';

import { useStyles } from './style';

const SearchWithFilters = ({ onChange, filter = true, value }) => {
  const [toggleFilter, setToggleFilter] = React.useState(false);
  const classes = useStyles();

  const handleToggleFilter = () => {
    setToggleFilter(!toggleFilter);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
      <Box className={classes.search}>
        <Box className={classes.searchIcon}>
          <MdSearch />
        </Box>
        <InputBase
          placeholder="Search…"
          classes={{
            root: classes.inputRoot,
            input: filter
              ? classes.inputInput
              : classes.inputInputWithoutFilter,
          }}
          onChange={onChange}
          inputProps={{ type: 'search' }}
          defaultValue={value}
        />
      </Box>
      {filter && (
        <Box sx={{ marginLeft: '15px' }}>
          <IconButton
            aria-label="filter"
            color="secondary"
            onClick={handleToggleFilter}
          >
            <MdFilterList />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default SearchWithFilters;
