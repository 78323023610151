import React, { useState } from 'react';
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableBody,
  IconButton,
  Box,
  Typography,
  Tooltip,
} from '@mui/material';
import { MdAdd, MdDelete, MdInfo } from 'react-icons/md';
import { Field } from 'formik';
import { useSelector } from 'react-redux';

import { SelectionAutoComplete } from 'shared/components/_Autocomplete/SelectionAutocomplete';
import {
  fetchCapabilityDropDown,
  getCapabilityDropDownStatus,
  selectCapabilitiesDropDown,
} from 'features/AdminSettingsDropDown/dropDownSlice';
import { selectActiveUser } from 'features/account/accountSlice';
import { Each } from 'helpers/Each';
import { makeFormStyles } from 'forms/style';
import { formTableStyle } from './style';
import { NoDataMessage } from 'shared/components/_Tables/NoDataMessage';
import { NO_CAPABILITY_HISTORY_FOUND } from 'helpers';

const EmployeeCapabilityForm = ({
  setInitValues,
  initValues,
  isReadOnly,
  formAction,
}) => {
  // Constants
  const classes = makeFormStyles();
  const formTableClass = formTableStyle();

  // State
  const [isCapabilityDropDown, setIsCapabilityDropDown] = useState(false);

  // Selectors
  const activeUser = useSelector(selectActiveUser);

  // Methods
  const handleCapabilityDropDown = (val) => {
    const isExist = initValues?.capabilities?.find(
      (item) => item.value === val.value
    );
    if (!isExist) {
      setInitValues((prevValue) => ({
        ...prevValue,
        capabilities: [...(prevValue?.capabilities || []), val],
      }));
    }
    setIsCapabilityDropDown(false);
  };

  const handleDeleteRow = (idx) => {
    let capabilityData = [...initValues.capabilities];
    capabilityData.splice(idx, 1);
    setInitValues((prevValue) => ({
      ...prevValue,
      capabilities: capabilityData,
    }));
  };

  const handleIsCapabilityDropDown = (event) => {
    event.preventDefault();
    setIsCapabilityDropDown(true);
  };

  return (
    <Box className={formTableClass.cardWrapper}>
      <Box sx={{ maxHeight: '350px', overflow: 'auto' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            position: 'sticky',
            top: '0',
            zIndex: '1',
            padding: `${isCapabilityDropDown && '0.5rem 1rem'}`,
          }}
          className={formTableClass.cardHeader}
        >
          <Typography>
            Additional {activeUser?.label_capability ?? 'Capability'}
          </Typography>
          <Box sx={{ width: '100%', maxWidth: '400px' }}>
            {isCapabilityDropDown && (
              <Field name="capabilities">
                {({ field: { value }, form: { setFieldValue } }) => (
                  <SelectionAutoComplete
                    title={`Select ${activeUser.label_capability}`}
                    keyProperty="value"
                    nameProperty="text"
                    entityIds={value?.value}
                    setEntityIds={setFieldValue}
                    entitySelector={selectCapabilitiesDropDown}
                    entityStatusSelector={getCapabilityDropDownStatus}
                    onCustomChange={(value) => {
                      handleCapabilityDropDown(value);
                    }}
                    fetchEntityPage={fetchCapabilityDropDown}
                    cancelItemTagEdit={() => {
                      setFieldValue('capabilities', null);
                    }}
                    formField="capabilities"
                    showCloseItemEdit={true}
                    multiSelection={false}
                    hideCheckIcon={true}
                  />
                )}
              </Field>
            )}
          </Box>
          {!isReadOnly && !isCapabilityDropDown && (
            <IconButton
              onClick={(event) => handleIsCapabilityDropDown(event)}
              disableRipple
              sx={{ marginLeft: 'auto', paddingLeft: '1rem' }}
              size="small"
              className={formTableClass.CustomIconButton}
            >
              <MdAdd />
              <Typography variant="body2" sx={{ ml: '0.5rem' }}>
                Add {activeUser?.label_capability ?? 'Capability'}
              </Typography>
            </IconButton>
          )}
        </Box>
        {initValues?.capabilities?.length > 0 ? (
          <TableContainer
            component={Paper}
            className={classes.tableMainContainer}
          >
            <Table
              sx={{ minWidth: 450 }}
              aria-label="simple table"
              className={classes.tableWrapper}
            >
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell width={'500'}>
                    <Typography variant="body1" fontWeight="bold">
                      {activeUser?.label_capability}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <Each
                  of={initValues?.capabilities}
                  render={(row, index) => (
                    <>
                      <TableRow>
                        <TableCell>
                          {row?.description && (
                            <Tooltip title={row?.description}>
                              <IconButton>
                                <MdInfo />
                              </IconButton>
                            </Tooltip>
                          )}
                        </TableCell>
                        {!isReadOnly && (
                          <TableCell>
                            <IconButton onClick={() => handleDeleteRow(index)}>
                              <MdDelete />
                            </IconButton>
                          </TableCell>
                        )}
                        <TableCell className={classes.borderX}>
                          {row.text}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                />
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <NoDataMessage
            message={NO_CAPABILITY_HISTORY_FOUND}
            setHeight="200px"
          />
        )}
      </Box>
    </Box>
  );
};

export { EmployeeCapabilityForm };
