import { Backdrop, LinearProgress } from '@mui/material';
import clsx from 'clsx';
import { loadingStyles } from './styles';
import Loader from '../Loader';

function LoadingOverlay({
  showBackdrop,
  showProgress,
  progress,
  label,
  noAnimation,
}) {
  const classes = loadingStyles();

  const barClasses = noAnimation ? { bar: classes.progressBarNoAnimation } : {};

  return (
    <Backdrop className={clsx(classes.loadingSpinner)} open={showBackdrop}>
      <div>
        <Loader />
        {showProgress && (
          <div className={classes.progressArea}>
            {label}
            <LinearProgress
              className={classes.progressBar}
              classes={barClasses}
              variant="determinate"
              value={progress}
            />
          </div>
        )}
      </div>
    </Backdrop>
  );
}

export { LoadingOverlay };
