import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { depotReportService } from 'features/services/depotReport.service';
import { LoadingStatus } from 'helpers';

const initialState = {
  error: null,
  depotReports: [],
  depotReportStatus: LoadingStatus.Idle,
  depotReportPageInfo: {},
};

export const fetchDepotReport = createAsyncThunk(
  'costCenterReports/fetchDepotReport',
  async (params) =>
    await depotReportService.getDepotReports(
      params.command_id,
      params.depot_id,
      params.report_percent_id,
      params.supervisor_employee_id,
      params.cost_center_id,
      params.job_id,
      params.job_role_id,
      params.report_expiry,
      params.custom_expiry_date,
      params.capability_id,
      params.office_id
    )
);

const depotReportSlice = createSlice({
  name: 'depotReportDropDowns',
  initialState,
  reducers: {
    resetDepotState: (state) => initialState,
    resetDepotReportState: (state) => {
      state.depotReportStatus = LoadingStatus.Idle;
    },
    resetDepotDropDownState: (state) => {
      state.depotDrowpdownStatus = LoadingStatus.Idle;
    },
  },
  extraReducers: {
    [fetchDepotReport.pending]: (state, action) => {
      state.depotReportStatus = LoadingStatus.Loading;
    },
    [fetchDepotReport.fulfilled]: (state, action) => {
      state.depotReportStatus = LoadingStatus.Loaded;
      state.depotReports = action.payload;
    },
    [fetchDepotReport.rejected]: (state, action) => {
      state.depotReportStatus = LoadingStatus.Failed;
    },
  },
});

export const selectDepotReport = (state) => state.depotReports.depotReports;
export const getDepotReportStatus = (state) =>
  state.depotReports.depotReportStatus;
export const getDepotPageInfo = (state) =>
  state.depotReports.depotReportPageInfo;

export const { resetDepotState, resetDepotReportState } =
  depotReportSlice.actions;

export default depotReportSlice.reducer;
