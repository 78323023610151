import { config } from 'ectl-config';
import { FormDataUtils, getNetworkService } from 'helpers';
import { createProgressParams } from 'helpers/progressUtils';
import { buildFilters } from 'hooks/buildFilter';

const baseUrl = `${config.apiUrl}/employee`;
const employeeUrl = `${config.apiUrl}/admin/Employee`;
const completionUrl = `${config.apiUrl}/completion`;

const networkService = getNetworkService({
  config: config,
});

const fetchEmployeeById = async (id) => {
  const response = await networkService.get(`${baseUrl}/${id}`);
  return response.data;
};

const fetchEmployees = async (
  searchString = '',
  sort = '-full_name',
  activeOnly = true,
  payGradeIds,
  payPlanIds,
  jobIds,
  jobRoleIds
) => {
  const response = await networkService.get(`${baseUrl}`);
  const pageInfo = response.headers['x-pagination'];
  return { pageInfo: pageInfo, employees: response?.data };
};

const createEmployee = async (params) => {
  let formData = new FormData();
  FormDataUtils.safeAppend(formData, [
    ['first_name', params.first_name],
    ['last_name', params.last_name],
    ['suffix', params.suffix],
    ['email', params.email],
    ['system_role_id', params.system_role_id],
    ['pay_plan_id', params.pay_plan_id],
    ['pay_grade_id', params.pay_grade_id],
    ['job_role_id', params.job_role_id],
  ]);
  const response = await networkService.postMultiFormData(baseUrl, formData);
  return response.data;
};

const updateEmployee = async (params) => {
  let formData = new FormData();
  FormDataUtils.safeAppend(formData, [
    ['first_name', params.first_name],
    ['last_name', params.last_name],
    ['suffix', params.suffix],
    ['email', params.email],
    ['system_role_id', params.system_role_id],
    ['pay_plan_id', params.pay_plan_id],
    ['pay_grade_id', params.pay_grade_id],
    ['job_role_id', params.job_role_id],
  ]);
  const response = await networkService.putMultiFormData(
    `${baseUrl}/${params.employee_id}`,
    formData
  );
  return response.data;
};

const deleteEmployee = async (employee_id) => {
  try {
    const response = await networkService.delete(`${baseUrl}/${employee_id}`);
    return response;
  } catch (e) {
    return Promise.reject(e.error);
  }
};

const fetchDepotById = async (id) => {
  const response = await networkService.get(`${baseUrl}/depot/${id}`);
  return response.data;
};

const fetchDepots = async () => {
  const response = await networkService.get(`${baseUrl}/depot`);
  const pageInfo = response.headers['x-pagination'];
  return { pageInfo: pageInfo, depots: response?.data };
};

const updateDepot = async (params) => {
  let formData = new FormData();
  FormDataUtils.safeAppend(formData, [
    ['short_name', params.short_name],
    ['display_name', params.display_name],
    ['overwrite_label_job', params.overwrite_label_job],
    ['overwrite_label_job_role', params.overwrite_label_job_role],
    ['overwrite_label_capability', params.overwrite_label_capability],
    ['overwrite_label_skill', params.overwrite_label_skill],
  ]);
  const response = await networkService.putMultiFormData(
    `${baseUrl}/depot/${params.depot_id}`,
    formData
  );
  return response.data;
};

const fetchPayGradeById = async (id) => {
  const response = await networkService.get(`${baseUrl}/paygrade/${id}`);
  return response.data;
};

const fetchPayGrades = async () => {
  const response = await networkService.get(`${baseUrl}/paygrade`);
  const pageInfo = response.headers['x-pagination'];
  return { pageInfo: pageInfo, pay_grades: response?.data };
};

const fetchPayPlanById = async (id) => {
  const response = await networkService.get(`${baseUrl}/payplan/${id}`);
  return response.data;
};

const fetchPayPlans = async () => {
  const response = await networkService.get(`${baseUrl}/payplan`);
  const pageInfo = response.headers['x-pagination'];
  return { pageInfo: pageInfo, pay_plans: response?.data };
};

const getEmployee = async (
  searchString = '',
  employee_id = null,
  first_name = '',
  last_name = '',
  dod_id = null,
  supervisor_employee_id = null,
  pay_plan_id = null,
  pay_plan_display_name = '',
  pay_grade_id = null,
  pay_grade_display_name = '',
  system_role_id = null,
  system_role_display_name = '',
  job_id = null,
  job_display_name = '',
  job_role_id = null,
  job_role_display_name = '',
  employee_status = null,
  cost_center_id = null,
  office_id = null,
  startIndex = 0,
  stopIndex = 19,
  sort = '-first_name'
) => {
  const filters = [];
  if (searchString) {
    filters.push(`quick_search@=*${searchString}`);
  }
  if (first_name) {
    filters.push(`first_name@=*${first_name}`);
  }
  if (last_name) {
    filters.push(`last_name@=*${last_name}`);
  }
  if (employee_id) {
    filters.push(`employee_id==${employee_id}`);
  }
  if (dod_id !== null && dod_id !== undefined && dod_id !== '') {
    filters.push(`dod_id==${dod_id}`);
  }
  if (supervisor_employee_id) {
    filters.push(`supervisor_employee_id==${supervisor_employee_id}`);
  }
  if (pay_plan_id) {
    filters.push(`pay_plan_id==${pay_plan_id}`);
  }
  if (pay_plan_display_name) {
    filters.push(`pay_plan_display_name@=*${pay_plan_display_name}`);
  }
  if (pay_grade_display_name) {
    filters.push(`pay_grade_display_name@=*${pay_grade_display_name}`);
  }
  if (pay_grade_id) {
    filters.push(`pay_grade_id==${pay_grade_id}`);
  }
  if (system_role_id) {
    filters.push(`system_role_id==${system_role_id}`);
  }
  if (system_role_display_name) {
    filters.push(`system_role_display_name@=*${system_role_display_name}`);
  }
  if (job_id) {
    filters.push(`job_id==${job_id}`);
  }
  if (job_display_name) {
    filters.push(`job_display_name@==${job_display_name}`);
  }
  if (job_role_id) {
    filters.push(`job_role_id==${job_role_id}`);
  }
  if (job_role_display_name) {
    filters.push(`job_role_display_name@==${job_role_display_name}`);
  }
  if (employee_status) {
    filters.push(`employee_status==${employee_status}`);
  }
  if (cost_center_id) {
    filters.push(`cost_center_id==${cost_center_id}`);
  }
  if (office_id) {
    filters.push(`office_id==${office_id}`);
  }

  const params = {
    filters: filters.join(','),
    sorts: sort,
    start_index: startIndex,
    stop_index: stopIndex,
  };
  const query = new URLSearchParams(params);
  const response = await networkService.get(`${employeeUrl}/?${query}`);
  const pageInfo = response.headers['x-pagination'];
  return {
    pageInfo: pageInfo,
    employee: response?.data,
  };
};

const getAdminEmployeeById = async (id) => {
  const response = await networkService.get(`${baseUrl}/${id}`);
  return { employees: response.data };
};

const createAdminEmployees = async (params) => {
  const response = await networkService.post(baseUrl, params);

  if (response.error) {
    return Promise.reject(response.error);
  }

  return response;
};

const updateAdminEmployees = async (params) => {
  console.log('updateAdminEmployee(put)', params);
  if (params?.job_history?.length) {
    for (var i in params.job_history) {
      const jh = params.job_history[i];
      if (jh.job_role_id === null) {
        jh.job_role_id = -1;
      }
    }
  }

  const response = await networkService.put(
    `${baseUrl}/${params.employee_id}`,
    params
  );

  if (response.error) {
    return Promise.reject(response.error);
  }

  return { employees: response.data };
};

const _delete = async (employee_id) => {
  try {
    const response = await networkService.delete(
      `${employeeUrl}/${employee_id}`
    );
    return response;
  } catch (e) {
    return Promise.reject(e.error);
  }
};

const getById = async (id) => {
  const response = await networkService.get(`${employeeUrl}/${id}`);
  return response.data;
};

const deleteEmployeesArray = async (ids) => {
  try {
    const deleteObject = {
      ids: ids,
    };
    const response = await networkService.deleteWithBody(
      employeeUrl,
      deleteObject
    );
    return response.data;
  } catch (e) {
    return Promise.reject(e.error);
  }
};

const getEmployeeUsageById = async (tagId) => {
  const response = await networkService.get(`${employeeUrl}/${tagId}`);
  return response.data;
};

const getEmployeeImportCsv = async () => {
  try {
    const response = await networkService.get(`${employeeUrl}/import`);
    return response?.data;
  } catch (err) {
    return Promise.reject(err.error);
  }
};

const uploadEmployeeImportCsv = async (params) => {
  try {
    let formData = new FormData();
    FormDataUtils.safeAppend(formData, [['file', params.file]]);
    let progressParams = createProgressParams(params);
    const response = await networkService.postMultiFormData(
      `${employeeUrl}/import`,
      formData,
      progressParams
    );
    return response.data;
  } catch (err) {
    return err;
  }
};

const getCertificationImportCsv = async () => {
  try {
    const response = await networkService.get(`${completionUrl}/import`);
    return response?.data;
  } catch (err) {
    return Promise.reject(err.error);
  }
};

const uploadCertificationImportCsv = async (params) => {
  try {
    let formData = new FormData();
    FormDataUtils.safeAppend(formData, [['file', params.file]]);
    let progressParams = createProgressParams(params);
    const response = await networkService.postMultiFormData(
      `${completionUrl}/import`,
      formData,
      progressParams
    );
    return response.data;
  } catch (err) {
    return err;
  }
};

const exportEmployeesCsv = async (
  employee_id = null,
  first_name = '',
  last_name = '',
  dod_id = null,
  supervisor_employee_id = null,
  pay_plan_id = null,
  pay_plan_display_name = '',
  pay_grade_id = null,
  pay_grade_display_name = '',
  system_role_id = null,
  system_role_display_name = '',
  job_id = null,
  job_display_name = '',
  job_role_id = null,
  job_role_display_name = '',
  employee_status = null
) => {
  try {
    const filters = buildFilters(
      ['employee_id', employee_id],
      ['first_name', first_name],
      ['last_name', last_name],
      ['dod_id', dod_id],
      ['supervisor_employee_id', supervisor_employee_id],
      ['pay_plan_id', pay_plan_id],
      ['pay_plan_display_name', pay_plan_display_name],
      ['pay_grade_id', pay_grade_id],
      ['pay_grade_display_name', pay_grade_display_name],
      ['system_role_id', system_role_id],
      ['system_role_display_name', system_role_display_name],
      ['job_id', job_id],
      ['job_display_name', job_display_name],
      ['job_role_id', job_role_id],
      ['employee_status', employee_status],
      ['job_role_display_name', job_role_display_name]
    );

    const params = {
      filters: filters,
    };
    const query = new URLSearchParams(params).toString();
    const response = await networkService.get(`${employeeUrl}/export?${query}`);
    return response?.data;
  } catch (err) {
    return Promise.reject(err.error);
  }
};

const getEmployeesReport = async (
  employee_id = null,
  first_name = '',
  last_name = '',
  dod_id = null,
  supervisor_employee_id = null,
  pay_plan_id = null,
  pay_plan_display_name = '',
  pay_grade_id = null,
  pay_grade_display_name = '',
  system_role_id = null,
  system_role_display_name = '',
  job_id = null,
  job_display_name = '',
  job_role_id = null,
  job_role_display_name = '',
  employee_status = null
) => {
  try {
    const filters = buildFilters(
      ['employee_id', employee_id],
      ['first_name', first_name],
      ['last_name', last_name],
      ['dod_id', dod_id],
      ['supervisor_employee_id', supervisor_employee_id],
      ['pay_plan_id', pay_plan_id],
      ['pay_plan_display_name', pay_plan_display_name],
      ['pay_grade_id', pay_grade_id],
      ['pay_grade_display_name', pay_grade_display_name],
      ['system_role_id', system_role_id],
      ['system_role_display_name', system_role_display_name],
      ['job_id', job_id],
      ['job_display_name', job_display_name],
      ['job_role_id', job_role_id],
      ['employee_status', employee_status],
      ['job_role_display_name', job_role_display_name]
    );

    const params = {
      filters: filters,
    };
    const query = new URLSearchParams(params).toString();
    return await networkService.get(`${baseUrl}/Report?${query}`, 30000);
  } catch (err) {
    return Promise.reject(err.error);
  }
};

const getSkillCertifications = async (employee_id = null, skill_id = null) => {
  const response = await networkService.get(
    `${baseUrl}/CertificationHistory/${employee_id}/${skill_id}`
  );
  return response?.data;
};

const updateSkillCertification = async (params) => {
  const response = await networkService.put(`${baseUrl}/Certification`, params);

  if (response.error) {
    return Promise.reject(response.error);
  }

  return { skillCertifications: response.data };
};

const getEmployeeImportHelp = async (employee_id = null) => {
  try {
    const filters = [];
    if (employee_id) {
      filters.push(`employee_id==${employee_id}`);
    }
    const params = {
      filters: filters.join(','),
    };
    const query = new URLSearchParams(params);
    if (employee_id) {
      const response = await networkService.get(
        `${employeeUrl}/help/?${query}`
      );
      return response?.data;
    }
    const response = await networkService.get(`${employeeUrl}/help`);
    return response?.data;
  } catch (err) {
    return Promise.reject(err.error);
  }
};

const getCertificationImportHelp = async (employee_id = null) => {
  try {
    const filters = [];
    if (employee_id) {
      filters.push(`employee_id==${employee_id}`);
    }
    const params = {
      filters: filters.join(','),
    };
    const query = new URLSearchParams(params);
    if (employee_id) {
      const response = await networkService.get(
        `${completionUrl}/help/?${query}`
      );
      return response?.data;
    }
    const response = await networkService.get(`${completionUrl}/help`);
    return response?.data;
  } catch (err) {
    return Promise.reject(err.error);
  }
};

const getDodIdValidate = async (employee_id = null, dodId = '', signal) => {
  try {
    if (employee_id) {
      const response = await networkService.getWithAbortController(
        `${baseUrl}/validate/DoDId/${employee_id}?val=${dodId}`,
        signal
      );
      return response?.data;
    }
    const response = await networkService.getWithAbortController(
      `${baseUrl}/validate/DoDId?val=${dodId}`,
      signal
    );
    return response?.data;
  } catch (err) {
    return Promise.reject(err.error);
  }
};

const getEmailValidate = async (employee_id = null, email = '', signal) => {
  try {
    if (employee_id) {
      const response = await networkService.getWithAbortController(
        `${baseUrl}/validate/email/${employee_id}?val=${email}`,
        signal
      );
      return response?.data;
    }
    const response = await networkService.getWithAbortController(
      `${baseUrl}/validate/email?val=${email}`,
      signal
    );
    return response?.data;
  } catch (err) {
    return Promise.reject(err.error);
  }
};

export const employeeService = {
  fetchEmployeeById,
  fetchEmployees,
  createEmployee,
  updateEmployee,
  deleteEmployee,
  fetchDepotById,
  fetchDepots,
  updateDepot,
  fetchPayGradeById,
  fetchPayGrades,
  fetchPayPlanById,
  fetchPayPlans,
  getEmployee,
  delete: _delete,
  getById,
  deleteEmployeesArray,
  getEmployeeUsageById,
  getEmployeeImportCsv,
  uploadEmployeeImportCsv,
  getCertificationImportCsv,
  uploadCertificationImportCsv,
  getAdminEmployeeById,
  createAdminEmployees,
  updateAdminEmployees,
  exportEmployeesCsv,
  getEmployeesReport,
  getSkillCertifications,
  updateSkillCertification,
  getEmployeeImportHelp,
  getCertificationImportHelp,
  getDodIdValidate,
  getEmailValidate,
};
