import { createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const localStyles = makeStyles(() =>
  createStyles({
    loginForm: {
      width: '100%',
    },
    loginError: {
      border: '1px solid red',
      color: '#000000 !important',
    },
  })
);

export { localStyles };
