import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { tabStyles } from './style';
import {
  getAdminTabIndex,
  setAdminTabIndex,
} from 'features/global/visibilitySlice';
import { DepotManagementTab } from 'components/DepotManagementTab';
import { PayPlansManagementTab } from 'components/PayPlansManagementTab';
import { PayGradesManagementTab } from 'components/PayGradesManagementTab';
import { SkillManagementTab } from 'components/SkillManagementTab';
import { JobManagementTab } from 'components/JobManagementTab';
import { JobRolesManagementTab } from 'components/JobRolesManagementTab';
import { CapabilityManagementTab } from 'components/CapabilityManagementTab';
import { LevelManagementTab } from 'components/LevelsManagementTab';
import { selectActiveUser } from 'features/account/accountSlice';
import { EmployeeManagementTab } from 'components/EmployeeManagementTab';
import { CostCenterManagementTab } from 'components/CostCenterManagementTab';
import { ExternalApiManagementTab } from 'components/ExternalApiManagementTab';
import { mdTheme } from 'theme';
import { OfficeManagementTab } from 'components/OfficeManagementTab';
import { adminTabsTitles } from 'helpers';

function AdminTab(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      style={{ height: '100%' }}
      {...other}
    >
      {value === index && (
        <Box
          p={3}
          style={{
            overflow: 'auto hidden',
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

AdminTab.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

function AdminTabPanel() {
  const dispatch = useDispatch();
  const classes = tabStyles(mdTheme);

  const displayTabIndex = useSelector((state) => getAdminTabIndex(state));
  const user = useSelector(selectActiveUser);

  const handleChange = (event, newValue) => {
    dispatch(setAdminTabIndex(newValue));
  };

  let tabBarIndex = 0;
  let tabPageIndex = 0;
  return (
    <div className={classes.tabRoot}>
      <AppBar position="static" color="default">
        <Tabs
          value={displayTabIndex}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          className={classes.tabs}
        >
          <Tab label={adminTabsTitles.depot} {...a11yProps(tabBarIndex)} />
          <Tab label={adminTabsTitles.payPlan} {...a11yProps(++tabBarIndex)} />
          <Tab
            label={adminTabsTitles?.payGrade}
            {...a11yProps(++tabBarIndex)}
          />
          <Tab
            label={user?.label_job ?? adminTabsTitles?.job}
            {...a11yProps(++tabBarIndex)}
          />
          <Tab
            label={user?.label_job_role ?? adminTabsTitles?.jobRoles}
            {...a11yProps(++tabBarIndex)}
          />
          <Tab
            label={user?.label_capability ?? adminTabsTitles?.capabilities}
            {...a11yProps(++tabBarIndex)}
          />
          <Tab
            label={user?.label_skill ?? adminTabsTitles?.skill}
            {...a11yProps(++tabBarIndex)}
          />
          <Tab label={adminTabsTitles?.level} {...a11yProps(++tabBarIndex)} />
          <Tab
            label={adminTabsTitles?.employee}
            {...a11yProps(++tabBarIndex)}
          />
          <Tab
            label={adminTabsTitles?.costCenter}
            {...a11yProps(++tabBarIndex)}
          />
          <Tab label={adminTabsTitles?.office} {...a11yProps(++tabBarIndex)} />
          <Tab
            label={adminTabsTitles?.externalApi}
            {...a11yProps(++tabBarIndex)}
          />
        </Tabs>
      </AppBar>
      <AdminTab value={displayTabIndex} index={tabPageIndex}>
        <DepotManagementTab />
      </AdminTab>
      <AdminTab value={displayTabIndex} index={++tabPageIndex}>
        <PayPlansManagementTab />
      </AdminTab>
      <AdminTab value={displayTabIndex} index={++tabPageIndex}>
        <PayGradesManagementTab />
      </AdminTab>
      <AdminTab value={displayTabIndex} index={++tabPageIndex}>
        <JobManagementTab />
      </AdminTab>
      <AdminTab value={displayTabIndex} index={++tabPageIndex}>
        <JobRolesManagementTab userJobRole={user} />
      </AdminTab>
      <AdminTab value={displayTabIndex} index={++tabPageIndex}>
        <CapabilityManagementTab />
      </AdminTab>
      <AdminTab value={displayTabIndex} index={++tabPageIndex}>
        <SkillManagementTab />
      </AdminTab>
      <AdminTab value={displayTabIndex} index={++tabPageIndex}>
        <LevelManagementTab />
      </AdminTab>
      <AdminTab value={displayTabIndex} index={++tabPageIndex}>
        <EmployeeManagementTab />
      </AdminTab>
      <AdminTab value={displayTabIndex} index={++tabPageIndex}>
        <CostCenterManagementTab />
      </AdminTab>
      <AdminTab value={displayTabIndex} index={++tabPageIndex}>
        <OfficeManagementTab />
      </AdminTab>
      <AdminTab value={displayTabIndex} index={++tabPageIndex}>
        <ExternalApiManagementTab />
      </AdminTab>
    </div>
  );
}

export { AdminTabPanel };
