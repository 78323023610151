import React from 'react';
import { Action } from 'helpers';
import { PopupAction } from '../PopupAction';

function SkillManagementPopup({ object, level, onClose }) {
  return (
    <React.Fragment>
      {level === 'adminEmployee' && (
        <PopupAction
          action={Action.View}
          object={object}
          level={level}
          showLabel={true}
          onActionHandled={onClose}
        />
      )}
      <PopupAction
        action={Action.Edit}
        object={object}
        level={level}
        showLabel={true}
        onActionHandled={onClose}
      />
      <PopupAction
        action={Action.AddCapability}
        object={object}
        level={level}
        showLabel={true}
        onActionHandled={onClose}
      />
      <PopupAction
        action={Action.Delete}
        object={object}
        level={level}
        showLabel={true}
        onActionHandled={onClose}
      />
    </React.Fragment>
  );
}

export { SkillManagementPopup };
