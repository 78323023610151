import { navStyles } from './Nav.styles';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { SceneDefinitions } from 'scenes/scene.definitions';
import { userHasAccess } from 'helpers';
import { selectActiveUser } from 'features/account/accountSlice';
import { useDispatch, useSelector } from 'react-redux';
import { MdChevronLeft } from 'react-icons/md';

function Nav({ theme, toggleDrawer }) {
  //#region Constants
  //#endregion Constants

  //#region Hooks
  const classes = navStyles(theme);
  //#endregion Hooks

  //#region State
  //#endregion State

  //#region Selectors
  const activeUser = useSelector(selectActiveUser);
  //#endregion Selectors

  //#region Refs
  //#endregion Refs

  //#region Render time calcs
  const scenes = SceneDefinitions({ activeUser: activeUser });
  //#endregion Render time calcs

  //#region Effects
  //#endregion Effects

  //#region Methods
  //#endregion Methods

  return (
    <>
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: [1],
        }}
      >
        <IconButton onClick={toggleDrawer}>
          <MdChevronLeft />
        </IconButton>
      </Toolbar>
      <Divider />
      <List component="nav">
        {scenes
          .filter((x) => x.menuArea === 'top')
          .sort((x, y) => x.key.localeCompare(y.key))
          .map((x) =>
            NavItem({
              key: `${x.key}-ni-top`,
              menuItem: x,
              classes: classes,
              activeUser: activeUser,
            })
          )}
      </List>
      <List style={{ marginTop: 'auto' }}>
        {scenes
          .filter((x) => x.menuArea === 'bottom')
          .sort((x, y) => x.key.localeCompare(y.key))
          .map((x) =>
            NavItem({
              key: `${x.key}-ni-bottom`,
              menuItem: x,
              classes: classes,
              activeUser: activeUser,
            })
          )}
      </List>
    </>
  );
}

function NavItem({ key, menuItem, classes, activeUser }) {
  const IconImage = menuItem.iconElement;
  const calculatePath = (path) => (path?.startsWith('/') ? path : `/${path}`);
  const appRouteBase = process.env.REACT_APP_ROUTE_BASENAME || '';
  const pathname = window.location.pathname.endsWith(appRouteBase)
    ? window.location.pathname
    : `${window.location.pathname}${appRouteBase}`;
  const isActive =
    pathname.endsWith(calculatePath(menuItem.path)) ||
    menuItem?.isActiveOverride;

  const dispatch = useDispatch();
  return userHasAccess({
    activeUser,
    isAnon: menuItem.isAnon,
    requiredRoles: menuItem.requiredRoles,
  }) ? (
    menuItem?.menuText !== false && (
      <ListItemButton
        key={`${key}-button`}
        component={Link}
        to={menuItem.path}
        sx={{
          filter: isActive
            ? 'brightness(0) invert(0)'
            : 'brightness(0) invert(1) grayscale(1)',
        }}
        title={menuItem?.menuText}
        onClick={() => {
          if (menuItem?.clickDispatchEvent) {
            dispatch(
              menuItem.clickDispatchEvent(menuItem.clickDispatchArgs ?? {})
            );
          }
        }}
      >
        <ListItemIcon>
          {menuItem.iconSrc ? (
            <img
              key={`${key}-icon`}
              src={menuItem.iconSrc}
              alt={menuItem.menuText}
              className={classes.navIcon}
            />
          ) : menuItem.iconElement ? (
            <IconImage
              key={`${key}-img`}
              color="secondary"
              className={classes.navIcon}
              sx={{ width: 40, height: 40 }}
            />
          ) : (
            <>Unknown Menu Icon</>
          )}
        </ListItemIcon>
        <ListItemText primary={menuItem.menuText} />
      </ListItemButton>
    )
  ) : (
    <></>
  );
}

export { Nav };
