import { config } from 'ectl-config';
import { getNetworkService } from 'helpers';

const accountService = { config: config };
const networkService = getNetworkService({
  config: config,
  checkAuth: accountService.checkAuth,
});
const baseUrl = `${config.apiUrl}/Team`;
const employeeUrl = `${config.apiUrl}/Employee`;

const getTeams = async (jobId = null, jobRoleId = null) => {
  if (jobId && jobRoleId) {
    const response = await networkService.get(
      `${baseUrl}/${jobId}/${jobRoleId}`
    );
    return {
      teams: response?.data,
    };
  } else {
    const response = await networkService.get(baseUrl);
    return {
      teams: response?.data,
    };
  }
};

const getEmployee = async (employee_id) => {
  try {
    const response = await networkService.get(
      `${baseUrl}/employee/info/${employee_id}`
    );
    return { employees: response.data };
  } catch (e) {
    return Promise.reject(e.error);
  }
};

const getEmployeeById = async (id) => {
  const response = await networkService.get(`${employeeUrl}/${id}`);
  return { employees: response.data };
};

const getEmployeeReport = async (id) =>
  await networkService.get(`${employeeUrl}/Report/${id}`);

const createEmployees = async (params) => {
  const response = await networkService.post(employeeUrl, params);

  if (response.error) {
    return Promise.reject(response.error);
  }

  return response;
};

const updateEmployees = async (params) => {
  const response = await networkService.put(
    `${employeeUrl}/${params.employee_id}`,
    params
  );

  if (response.error) {
    return Promise.reject(response.error);
  }

  return { employees: response.data };
};

const getEmployeeCls = async (
  employeeId = null,
  capabilityId = null,
  levelId = null
) => {
  const endpoint = `skill/${employeeId}/${capabilityId}/${levelId}`;

  try {
    const response = await networkService.get(`${employeeUrl}/${endpoint}`);
    return { employeeCls: response.data };
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const getTeamsBench = async (
  jobId = null,
  jobRoleId = null,
  supEmployeeId,
  employeeId,
  startIndex = 0,
  stopIndex = 9
) => {
  const filters = [];

  if (jobId) {
    filters.push(`job_id==${jobId}`);
  }
  if (jobRoleId) {
    filters.push(`job_role_id==${jobRoleId}`);
  }
  if (supEmployeeId) {
    filters.push(`supervisor_employee_id==${supEmployeeId}`);
  }
  if (employeeId) {
    filters.push(`employee_id==${employeeId}`);
  }
  const params = {
    filters: filters.join(','),
    start_index: startIndex,
    stop_index: stopIndex,
  };

  const query = new URLSearchParams(params).toString();

  try {
    const response = await networkService.get(`${baseUrl}/bench/?${query}`);
    const pageInfo = response.headers['x-pagination'];
    return {
      pageInfo: pageInfo,
      teamsBench: response?.data,
    };
  } catch (e) {
    return Promise.reject(e.error);
  }
};

const getTeamsExpiry = async (
  jobId = null,
  jobRoleId = null,
  supEmployeeId,
  employeeId,
  startIndex = 0,
  stopIndex = 9
) => {
  const filters = [];

  if (jobId) {
    filters.push(`job_id==${jobId}`);
  }
  if (jobRoleId) {
    filters.push(`job_role_id==${jobRoleId}`);
  }
  if (supEmployeeId) {
    filters.push(`supervisor_employee_id==${supEmployeeId}`);
  }
  if (employeeId) {
    filters.push(`employee_id==${employeeId}`);
  }
  const params = {
    filters: filters.join(','),
    start_index: startIndex,
    stop_index: stopIndex,
  };

  const query = new URLSearchParams(params).toString();

  try {
    const response = await networkService.get(`${baseUrl}/expiry/?${query}`);
    const pageInfo = response.headers['x-pagination'];
    return {
      pageInfo: pageInfo,
      teamsExpiry: response?.data,
    };
  } catch (e) {
    return Promise.reject(e.error);
  }
};

const getEmployeeSkill = async (employee_id) => {
  try {
    const response = await networkService.get(
      `${baseUrl}/employee/skills/${employee_id}`,
      30000
    );
    return { employeeSkill: response.data };
  } catch (e) {
    return Promise.reject(e.error);
  }
};

const getEmployeeCapability = async (employee_id) => {
  try {
    const response = await networkService.get(
      `${baseUrl}/employee/capabilities/${employee_id}`,
      30000
    );
    return { employeeCapability: response.data };
  } catch (e) {
    return Promise.reject(e.error);
  }
};

export const teamService = {
  getTeams,
  getEmployee,
  getEmployeeById,
  getEmployeeReport,
  updateEmployees,
  createEmployees,
  getEmployeeCls,
  getTeamsBench,
  getTeamsExpiry,
  getEmployeeSkill,
  getEmployeeCapability,
};
