import { useCallback, useEffect, useRef, useState } from 'react';
import { Grid } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { SelectionAutoComplete } from 'shared/components/_Autocomplete/SelectionAutocomplete';
import {
  fetchJobRolesByJobId,
  fetchJobsDropDown,
  fetchSkillsDropDown,
  getJobDropDownStatus,
  getJobRolesByJobIdDropDownStatus,
  getSkillsDropDownStatus,
  resetJobRoleDropdown,
  selectJobDropDown,
  selectJobRoleByJobIdDropDown,
  selectSkillsDropDown,
} from 'features/AdminSettingsDropDown/dropDownSlice';
import { selectActiveUser } from 'features/account/accountSlice';
import { FilterAccordion } from 'shared/components/_Accordion';
import { clearFilter } from 'helpers/clearFilter';
import {
  selectAdminSettingCache,
  updateAdminSettingCache,
} from 'features/global/visibilitySlice';
import { useDebounce } from 'hooks/useDebounce';
import { CAPABILITY_CACHE } from 'helpers';
import { FormTextField } from 'shared/components/FormFields/TextField';
import { sanitizeEnotationInput } from 'hooks/validateAndFormateColor';

const ItemCapabilityFilter = ({
  setItemCapabilityPayload,
  itemCapabilityPayload,
}) => {
  //#region Constants
  const getCacheData = useSelector(selectAdminSettingCache);
  const capabilityCacheData = getCacheData?.[CAPABILITY_CACHE];
  const dispatch = useDispatch();
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  const [initValues, setInitValues] = useState({
    display_name: '',
    job_id: null,
    job_role_id: null,
    job_series_id: '',
    skill_ids: null,
  });
  //#endregion State

  //#region Selectors
  const user = useSelector(selectActiveUser);
  //#endregion Selectors

  //#region Refs
  const formRef = useRef();
  //#endregion Refs

  //#region Effects
  useEffect(() => {
    setInitValues({ ...initValues, ...capabilityCacheData });
  }, [getCacheData, itemCapabilityPayload]);

  //#endregion Effects

  //#region Methods
  const updateFields = useCallback(
    useDebounce((displayName, jobId, jobRoleId, jobSeriesId, skillIds) => {
      setItemCapabilityPayload({
        ...itemCapabilityPayload,
        display_name: displayName,
        job_id: jobId,
        job_role_id: jobRoleId,
        job_series_id: jobSeriesId,
        skill_ids: skillIds,
      });
    }, 1000),
    [itemCapabilityPayload]
  );

  const handleResetFilters = () => {
    const isFilterData = clearFilter(formRef);
    if (isFilterData) {
      formRef?.current?.resetForm();
      setItemCapabilityPayload({
        ...itemCapabilityPayload,
        display_name: '',
        job_role_id: null,
        job_id: null,
        job_series_id: '',
        skill_ids: null,
      });
      dispatch(updateAdminSettingCache({ key: CAPABILITY_CACHE, ...{} }));
    }
  };
  //#endregion Methods

  //#region Render time calcs
  //#endregion Render time calcs

  //#region Render

  return (
    <FilterAccordion
      filteredTitles={itemCapabilityPayload}
      expandedId="capability-filter"
      styles={{ marginBottom: '1.5rem' }}
      onResetFilters={handleResetFilters}
    >
      <Formik
        enableReinitialize={true}
        initialValues={{ ...initValues }}
        innerRef={formRef}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Grid container columnSpacing={'1rem'}>
              <Grid item xs={12} md={6} lg={3}>
                <FormTextField
                  name="display_name"
                  label="Display Name"
                  placeholder="Display Name"
                  fullWidth
                  onChange={(e) => {
                    const searchTerm = e.target.value;
                    setFieldValue('display_name', searchTerm);
                    updateFields(
                      searchTerm,
                      values?.job_id,
                      values?.job_role_id,
                      values?.job_series_id,
                      values?.skill_ids
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <Field name="job_id">
                  {({ field: { value }, form: { setFieldValue } }) => (
                    <SelectionAutoComplete
                      title={`Select ${user.label_job}`}
                      keyProperty="value"
                      nameProperty="text"
                      entityIds={value?.value}
                      setEntityIds={setFieldValue}
                      entitySelector={selectJobDropDown}
                      entityStatusSelector={getJobDropDownStatus}
                      onCustomChange={(value) => {
                        setFieldValue('job_id', value);
                        setFieldValue('job_role_id', null);
                        updateFields(
                          values?.display_name,
                          value,
                          null,
                          values?.job_series_id,
                          values?.skill_ids
                        );
                        dispatch(resetJobRoleDropdown());
                      }}
                      fetchEntityPage={fetchJobsDropDown}
                      cancelItemTagEdit={() => {
                        setFieldValue('job_id', null);
                        setFieldValue('job_role_id', null);
                        updateFields(
                          values?.display_name,
                          null,
                          null,
                          values?.job_series_id,
                          values?.skill_ids
                        );
                        dispatch(resetJobRoleDropdown());
                      }}
                      showCloseItemEdit={true}
                      formField="job_id"
                      multiSelection={false}
                      hideCheckIcon={true}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <Field name="job_role_id">
                  {({ field: { value }, form: { setFieldValue } }) => (
                    <SelectionAutoComplete
                      title={`Select ${user.label_job_role}`}
                      keyProperty="value"
                      nameProperty="text"
                      entityIds={value?.value}
                      setEntityIds={setFieldValue}
                      entitySelector={selectJobRoleByJobIdDropDown}
                      entityStatusSelector={getJobRolesByJobIdDropDownStatus}
                      onCustomChange={(value) => {
                        setFieldValue('job_role_id', value);
                        updateFields(
                          values?.display_name,
                          values?.job_id,
                          value,
                          values?.job_series_id,
                          values?.skill_ids
                        );
                      }}
                      fetchEntityPage={fetchJobRolesByJobId}
                      fetchParams={{
                        job_id: values?.job_id?.value,
                      }}
                      cancelItemTagEdit={() => {
                        setFieldValue('job_role_id', null);
                        updateFields(
                          values?.display_name,
                          values?.job_id,
                          null,
                          values?.job_series_id,
                          values?.skill_ids
                        );
                      }}
                      formField="job_role_id"
                      showCloseItemEdit={true}
                      multiSelection={false}
                      hideCheckIcon={true}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <FormTextField
                  name="job_series_id"
                  label="Job Series Id"
                  placeholder="Job Series Id"
                  fullWidth
                  onChange={(e) => {
                    const searchTerm = e.target.value;
                    const filterValue = sanitizeEnotationInput(searchTerm);
                    setFieldValue('job_series_id', filterValue);
                    updateFields(
                      values?.display_name,
                      values?.job_id,
                      values?.job_role_id,
                      filterValue,
                      values?.skill_ids
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3} pt={'0.5rem'}>
                <Field name="skill_ids">
                  {({ field: { value }, form: { setFieldValue } }) => (
                    <SelectionAutoComplete
                      title={`Select ${user.label_skill}`}
                      keyProperty="value"
                      nameProperty="text"
                      entityIds={map(value, 'value')}
                      setEntityIds={setFieldValue}
                      entitySelector={selectSkillsDropDown}
                      entityStatusSelector={getSkillsDropDownStatus}
                      onCustomChange={(value) => {
                        setFieldValue('skill_ids', value);
                        updateFields(
                          values?.display_name,
                          values?.job_id,
                          values?.job_role_id,
                          values?.job_series_id,
                          value
                        );
                      }}
                      fetchEntityPage={fetchSkillsDropDown}
                      formField="skill_ids"
                      multiSelection={true}
                      showCloseItemEdit={true}
                      hideCheckIcon={true}
                      cancelItemTagEdit={() => {
                        setFieldValue('skill_ids', null);
                        updateFields(
                          values?.display_name,
                          values?.job_id,
                          values?.job_role_id,
                          values?.job_series_id,
                          null
                        );
                      }}
                    />
                  )}
                </Field>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </FilterAccordion>
  );
  //#endregion Render
};
export { ItemCapabilityFilter };
