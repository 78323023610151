import { Box } from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import { tableCellClasses } from '@mui/material/TableCell';

import { mdTheme } from 'theme';
import { NoDataMessage } from 'shared/components/_Tables/NoDataMessage';
import { collapsedTableStyle } from 'scenes/GenerateReports/components/ReportsTable/style';
import DynamicTable from 'shared/components/_Tables/DynamicTable';
import { NO_MATCHING_REPORTS } from 'helpers';
import {
  fetchBestFits,
  getBestFitPageInfo,
} from 'features/BestFit/bestFitSlice';

const BestFitReportTable = ({
  itemBestFitPayload,
  setItemBestFitPayload,
  bestFitData,
  sortDirection,
  sortBy,
  handleSort,
}) => {
  //#region Constants
  const classes = collapsedTableStyle(mdTheme);
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  const [loadingMore, setLoadingMore] = useState(false);
  //#endregion State

  //#region Selectors
  //#endregion Selectors

  //#region Refs
  //#endregion Refs

  //#region Effects
  //#endregion Effects

  //#region Methods

  const getColorCode = (percent) => {
    if (percent >= 90) return '#77D93D';
    if (percent >= 75) return '#CC8C33';
    return '#C33429';
  };

  const columns = [
    {
      id: 'employee_full_name',
      label: 'Full Name',
      width: '250px',
      minWidth: '200px',
      sortDirection: sortDirection,
      sortBy: sortBy,
      handleSort: handleSort,
    },
    {
      id: 'cost_center',
      label: 'Cost Center',
      width: '200px',
      minWidth: '200px',
      sortDirection: sortDirection,
      sortBy: sortBy,
      handleSort: handleSort,
    },
    {
      id: 'job',
      label: 'Job',
      width: '300px',
      minWidth: '280px',
      sortDirection: sortDirection,
      sortBy: sortBy,
      handleSort: handleSort,
    },
    {
      id: 'job_role',
      label: 'Job Role',
      width: '300px',
      minWidth: '200px',
      sortDirection: sortDirection,
      sortBy: sortBy,
      handleSort: handleSort,
    },
    {
      id: 'match',
      label: 'Match Percent',
      width: '200px',
      minWidth: '200px',
      justifyContent: 'space-between',
      customRender: (item) => {
        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              bgcolor={getColorCode(item?.match * 100)}
              color="white"
              p={1}
              textAlign="center"
            >
              {`${(item?.match * 100).toFixed(0)}%`}
            </Box>
          </Box>
        );
      },
      accordion: true,
      sortDirection: sortDirection,
      sortBy: sortBy,
      handleSort: handleSort,
    },
  ];

  const nestedColumns = [
    { id: 'skill_id', label: 'Skill Id' },
    { id: 'skill', label: 'Skill' },
    {
      id: 'has_skill',
      label: 'Has Skill',
      customRender: (item) => {
        return item.has_skill ? 'Yes' : 'No';
      },
    },
    {
      id: 'skill_expiry',
      label: 'Skill Expiry',
      customRender: (item) => {
        return !item.skill_expiry
          ? '--'
          : item.skill_expiry === 'Never'
          ? 'Never'
          : moment(item.skill_expiry, moment.ISO_8601, true).isValid()
          ? moment(item.skill_expiry).format('YYYY-MM-DD')
          : '--';
      },
    },
  ];
  //#region Render time calcs
  //#endregion Render time calcs

  //#region Render
  return (
    <>
      {bestFitData && bestFitData?.length > 0 ? (
        <Box sx={{ marginTop: '1rem' }}>
          <DynamicTable
            tableId={'talent-match'}
            data={bestFitData}
            columns={columns}
            nestedKey={'skills'}
            nestedColumns={nestedColumns}
            tableContainerClass={classes.tableContainer}
            bodyCellClass={tableCellClasses.body}
            loadingMore={loadingMore}
            setLoadingMore={setLoadingMore}
            fetchPayLoad={fetchBestFits}
            tablePayload={itemBestFitPayload}
            setTablePayload={setItemBestFitPayload}
            pageInfo={getBestFitPageInfo}
          />
        </Box>
      ) : (
        <NoDataMessage message={NO_MATCHING_REPORTS} />
      )}
    </>
  );
  //#endregion Render
};
export { BestFitReportTable };
