export const buildFilters = (...filterArgs) => {
  const filters = filterArgs
    .filter(([key, value]) => value)
    .map(([key, value]) => {
      if (value && Array.isArray(value) && value.length > 0) {
        const keyValue = value.filter(Boolean);
        if (keyValue && keyValue.length > 0) {
          return `${key}==${keyValue.join('|')}`;
        } else {
          return;
        }
      } else if (typeof value === 'string') {
        return `${key}@=*${value}`;
      } else if (typeof value === 'number') {
        return `${key}==${value}`;
      }
    });
  return filters.join(',');
};
