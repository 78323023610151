import { Grid } from '@mui/material';
import {
  selectAdminSettingCache,
  updateAdminSettingCache,
} from 'features/global/visibilitySlice';
import { Formik, Form } from 'formik';
import { PAY_GRADE_CACHE } from 'helpers';
import { clearFilter } from 'helpers/clearFilter';
import { useDebounce } from 'hooks/useDebounce';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormTextField } from 'shared/components/FormFields/TextField';
import { FilterAccordion } from 'shared/components/_Accordion';

const ItemPayGradeFilter = ({
  setItemPayGradePayload,
  itemPayGradePayload,
}) => {
  //#region Constants
  const dispatch = useDispatch();
  const getCacheData = useSelector(selectAdminSettingCache);
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  const [initValues, setInitValues] = useState({
    display_name: '',
  });
  //#endregion State

  //#region Selectors
  //#endregion Selectors

  //#region Refs
  const formRef = useRef();
  //#endregion Refs

  //#region Effects
  useEffect(() => {
    setInitValues({ ...initValues, ...getCacheData?.[PAY_GRADE_CACHE] });
  }, [getCacheData, itemPayGradePayload]);
  //#endregion Effects

  //#region Methods

  const handleSearch = useDebounce((searchTerm) => {
    setItemPayGradePayload({
      ...itemPayGradePayload,
      display_name: searchTerm,
    });
  }, 1000);

  const handleResetFilters = () => {
    const isFilterData = clearFilter(formRef);
    if (isFilterData) {
      formRef?.current?.resetForm();
      setItemPayGradePayload({
        ...itemPayGradePayload,
        display_name: '',
      });
      dispatch(updateAdminSettingCache({ key: PAY_GRADE_CACHE, ...{} }));
    }
  };
  //#endregion Methods

  //#region Render time calcs
  //#endregion Render time calcs

  //#region Render

  return (
    <FilterAccordion
      filteredTitles={itemPayGradePayload}
      expandedId="pay-grade"
      styles={{ marginBottom: '1.5rem' }}
      onResetFilters={handleResetFilters}
    >
      <Formik
        enableReinitialize={true}
        initialValues={{ ...initValues }}
        innerRef={formRef}
      >
        {({ setFieldValue }) => (
          <Form style={{ padding: '0' }}>
            <Grid container columnSpacing={'1rem'}>
              <Grid item={true} xs={12} lg={3}>
                <FormTextField
                  name="display_name"
                  label="Display Name"
                  placeholder="Display Name"
                  fullWidth
                  onChange={(e) => {
                    const searchTerm = e.target.value;
                    setFieldValue('display_name', searchTerm);
                    handleSearch(searchTerm, 'display_name');
                  }}
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </FilterAccordion>
  );
  //#endregion Render
};
export { ItemPayGradeFilter };
