import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Formik, Form } from 'formik';
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableBody,
  Button,
} from '@mui/material';
import moment from 'moment';

import { FormActions } from 'helpers/form-action';
import { makeFormStyles } from 'forms/style';
import { snackbarHandler } from 'helpers';
import { SnackbarDismiss } from 'shared/components/SnackbarDismiss';
import Loader from 'shared/components/Loader';
import { closePanel } from 'features/common/formSlice';
import {
  fetchSkillCertification,
  resetSkillCertificationState,
  selectSkillCertifications,
  updateSkillCertification,
} from 'features/employee/employeeSlice';
import { SkillTableBody } from './SkillTableBody';
import { NoDataMessage } from 'shared/components/_Tables/NoDataMessage';
import {
  resetEmployeeCapabilities,
  resetEmployeeSkill,
  resetTeamEmployeeState,
} from 'features/Teams/teamSlice';
import { Each } from 'helpers/Each';

const ItemSkillCertificationForm = ({ formAction, formInfo }) => {
  //#region Constants
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const classes = makeFormStyles();
  const isReadOnly = !formInfo?.isUpdatable;
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  const [initValues, setInitValues] = useState({
    completion_date: '',
    expiry_date: null,
    delete: false,
  });
  const [loaded, setLoaded] = useState(
    formAction.id === FormActions.Create.id ? true : false
  );
  //#endregion State

  //#region Selectors
  const getSkillCertificationFormData = useSelector(selectSkillCertifications);
  //#endregion Selectors

  //#region Refs
  const tagNameRef = useRef(null);
  //#endregion Refs

  //#region Effects
  useEffect(() => {
    if (formAction.id === FormActions.Edit.id) {
      const fetchData = async () => {
        var objectData = await dispatch(
          fetchSkillCertification({
            employee_id: formInfo?.assetTypeID,
            skill_id: formInfo?.editObjectId,
          })
        );
        var fetchedObject = objectData.payload;
        const formattedCertifications =
          fetchedObject?.employee_certifications.map((certification) => ({
            ...certification,
            completion_date: moment(certification.completion_date).format(
              'YYYY-MM-DD'
            ),
            expiry_date: certification.expiry_date
              ? moment(certification.expiry_date).format('YYYY-MM-DD')
              : null,
            delete: false,
          }));
        setInitValues({
          ...formattedCertifications,
        });
        setLoaded(true);
      };
      fetchData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    return async () => {
      await dispatch(resetSkillCertificationState());
    };
  }, []);
  //#endregion Effects

  //#region Methods
  const handleSubmit = async (values, setSubmitting, resetForm) => {
    var resultAction = null;
    const updatedFields = {};

    Object.keys(initValues).forEach((fieldName) => {
      if (initValues[fieldName] !== values[fieldName]) {
        updatedFields[fieldName] = values[fieldName];
      }
    });
    const hasDeletions = Object.values(values).some((row) => row?.delete);

    if (Object.keys(updatedFields).length > 0 || hasDeletions) {
      switch (formAction.id) {
        case FormActions.Edit.id:
          resultAction = await dispatch(
            updateSkillCertification(Object.values(updatedFields))
          );

          if (!resultAction?.error) {
            setInitValues({
              ...initValues,
              completion_date: resultAction?.payload.completion_date,
              expiry_date: resultAction?.payload.expiry_date,
              delete: resultAction?.payload.delete,
            });
            dispatch(
              closePanel({
                formKey: `skillsCertificationForm`,
              })
            );
            await dispatch(resetTeamEmployeeState());
            await dispatch(resetEmployeeSkill());
            await dispatch(resetEmployeeCapabilities());
          }
          break;
        case FormActions.Create.id:
          if (!resultAction?.error) {
            await dispatch(fetchSkillCertification());
            dispatch(closePanel({ formKey: `skillsCertificationForm` }));
            resetForm();
            if (tagNameRef.current) {
              tagNameRef.current.focus();
            }
            await dispatch(resetTeamEmployeeState());
          }
          break;
        default:
          break;
      }
    } else {
      enqueueSnackbar('No changes to submit.', {
        variant: 'info',
      });
      setSubmitting(false);
    }
    const { message, variant } = snackbarHandler(
      resultAction.meta.requestStatus,
      formAction.label
    );
    enqueueSnackbar(message, {
      action: (key) => <SnackbarDismiss key={key} />,
      variant: variant,
    });
    setSubmitting(false);
  };
  //#endregion Methods

  //#region Render
  return !loaded ? (
    <Loader styleClassName={classes.loadingSvg} />
  ) : (
    <Formik
      enableReinitialize={true}
      initialValues={{ ...initValues }}
      validate={(values) => {
        let customError = {};

        Object.values(values).map((item, index) => {
          if (!item.completion_date && !item.delete) {
            customError[`[${index}].completion_date`] = 'Required';
          }
          if (item.expiry_date && item.expiry_date < item.completion_date) {
            customError[`[${index}].expiry_date`] =
              'cannot be earlier than completion date';
          }
          return null;
        });

        return customError;
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        handleSubmit(values, setSubmitting, resetForm);
      }}
    >
      {({ isSubmitting, setFieldValue, values, errors, validateForm }) => (
        <Form className={classes.form}>
          <TableContainer sx={{ boxShadow: 'none' }} component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width={220}>Completion Date</TableCell>
                  <TableCell width={220}>Expiry Date</TableCell>
                  {!isReadOnly && <TableCell width={90}>Delete</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.values(
                  getSkillCertificationFormData?.employee_certifications
                ).length > 0 ? (
                  <Each
                    of={Object.values(
                      getSkillCertificationFormData?.employee_certifications
                    )}
                    render={(item, index) => (
                      <React.Fragment key={index}>
                        <SkillTableBody
                          index={index}
                          setFieldValue={setFieldValue}
                          isReadOnly={isReadOnly}
                          initValues={item}
                          classes={classes}
                          values={values}
                          errors={errors}
                          rowClass={classes}
                          checkValidate={validateForm}
                        />
                      </React.Fragment>
                    )}
                  />
                ) : (
                  <TableRow>
                    <TableCell colSpan="5">
                      <NoDataMessage
                        message="No Skill Certifications found"
                        setHeight="300px"
                      />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {!isReadOnly &&
            Object.values(
              getSkillCertificationFormData?.employee_certifications
            ).length > 0 && (
              <Button
                size="large"
                variant="contained"
                fullWidth
                type="submit"
                disabled={isSubmitting}
              >
                {formAction.buttonLabel}
              </Button>
            )}
        </Form>
      )}
    </Formik>
  );
  //#endregion Render
};

export { ItemSkillCertificationForm };
