import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import {
  Autocomplete,
  Box,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField as MuiTextField,
  Typography,
  Button,
} from '@mui/material';
import { Formik, Form } from 'formik';
import moment from 'moment';
import _ from 'lodash';
import { MdAdd, MdDoNotDisturbOn } from 'react-icons/md';

import { FormActions } from 'helpers/form-action';
import { makeFormStyles } from 'forms/style';
import {
  activateLoading,
  deactivateLoading,
} from 'features/global/loadingProgressSlice';
import {
  JOB_ROLE_CACHE,
  LoadingStatus,
  SKILL_CACHE,
  SystemRoleEnums,
  snackbarHandler,
} from 'helpers';
import { SnackbarDismiss } from 'shared/components/SnackbarDismiss';
import Loader from 'shared/components/Loader';
import { closePanel } from 'features/common/formSlice';
import {
  createCapability,
  fetchCapabilities,
  fetchCapabilitiesById,
  getCapabilityById,
  resetCapabilityState,
  resetDisplayCapabilities,
  updateCapability,
} from 'features/capability/capabilitySlice';
import {
  fetchLevelsDropDown,
  getLevelDropDownStatus,
  selectLevelDropDown,
} from 'features/AdminSettingsDropDown/dropDownSlice';
import { ItemCapabilitySkillTable } from './ItemCapabilitySkillTable';
import { selectActiveUser } from 'features/account/accountSlice';
import { resetTeamsState } from 'features/Teams/teamSlice';
import { validateAndFormatColor } from 'hooks/validateAndFormateColor';
import {
  selectAdminSettingCache,
  updateAdminSettingCache,
} from 'features/global/visibilitySlice';
import { FormTextField } from 'shared/components/FormFields/TextField';
import { Each } from 'helpers/Each';

const ItemCapabilityForm = ({ formAction, itemTagId, user }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  // eslint-disable-next-line no-unused-vars
  const [loaded, setLoaded] = useState(
    formAction.id === FormActions.Create.id ? true : false
  );
  const [isLevelDropDown, setIsLevelDropDown] = useState(false);
  const getCapabilityLevelData = useSelector(getCapabilityById);
  const levelDropDown = useSelector(selectLevelDropDown);
  const activeUser = useSelector(selectActiveUser);
  const levelDropDownStatus = useSelector(getLevelDropDownStatus);
  const adminCacheData = useSelector(selectAdminSettingCache);
  const [formData, setFormData] = useState({
    capability_id: null,
    created_by_employee_id: null,
    created_by_employee_full_name: '',
    created_date: '',
    modified_date: '',
    levels: [],
    skills: {},
    display_name: '',
    description: '',
  });
  const [checkedLevelState, setCheckedLevelState] = useState(null);
  const [levelObject, setLevelObject] = useState({});

  const classes = makeFormStyles();
  // eslint-disable-next-line no-unused-vars
  const [initValues, setInitValues] = useState({
    display_name: '',
    description: '',
    level_id: undefined,
  });
  const tagNameRef = useRef(null);
  const readOnly = [
    SystemRoleEnums.Admin,
    SystemRoleEnums.User,
    SystemRoleEnums.Certifier,
  ].includes(activeUser?.role);

  useEffect(() => {
    if (formAction.id === FormActions.Edit.id) {
      const fetchData = async () => {
        var objectData = await dispatch(fetchCapabilitiesById(itemTagId));
        var fetchedObject = objectData.payload;
        const levelId = fetchedObject?.levels[0]?.level_id;
        setLevelObject(fetchedObject?.levels[0]);
        setCheckedLevelState(levelId);

        setInitValues({
          display_name: fetchedObject?.display_name,
          description: fetchedObject?.description,
        });
        setLoaded(true);
      };
      fetchData();
    } else {
      dispatch(resetDisplayCapabilities());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      dispatch(resetCapabilityState());
    };
  }, []);

  useEffect(() => {
    setFormData(JSON.parse(JSON.stringify(getCapabilityLevelData)));
  }, [getCapabilityLevelData]);

  useEffect(() => {
    if (levelDropDownStatus === LoadingStatus.Idle) {
      dispatch(fetchLevelsDropDown());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [levelDropDownStatus, isLevelDropDown]);

  const handleSubmit = async (values, setSubmitting, resetForm) => {
    dispatch(activateLoading({ showProgress: true }));
    var resultAction = null;
    switch (formAction.id) {
      case FormActions.Edit.id:
        resultAction = await dispatch(
          updateCapability({
            capability_id: itemTagId,
            ...formData,
            ...values,
          })
        );
        if (!resultAction.error) {
          setInitValues({
            ...initValues,
            display_name: resultAction?.payload.display_name,
            level_description: resultAction?.payload.level_description,
          });
          if (adminCacheData?.[JOB_ROLE_CACHE]?.capability_ids.length > 0) {
            let capabilityArray = [
              ...adminCacheData?.[JOB_ROLE_CACHE]?.capability_ids,
            ];
            capabilityArray.map((val, index) => {
              if (
                val?.text !== resultAction?.payload.display_name &&
                val?.value === itemTagId
              ) {
                capabilityArray[index] = {
                  value: itemTagId,
                  text: resultAction?.payload.display_name,
                  description: resultAction.payload?.description,
                };
              }
            });
            if (
              !_.isEqual(
                capabilityArray,
                adminCacheData?.[JOB_ROLE_CACHE]?.capability_ids
              )
            ) {
              dispatch(
                updateAdminSettingCache({
                  key: JOB_ROLE_CACHE,
                  payload: {
                    capability_ids: capabilityArray,
                  },
                })
              );
            }
          }
          if (
            adminCacheData?.[SKILL_CACHE]?.capability_id?.value === itemTagId &&
            adminCacheData?.[SKILL_CACHE]?.capability_id?.text !==
              resultAction?.payload.display_name
          ) {
            dispatch(
              updateAdminSettingCache({
                key: SKILL_CACHE,
                payload: {
                  capability_id: {
                    value: itemTagId,
                    text: resultAction?.payload.display_name,
                  },
                },
              })
            );
          }

          dispatch(closePanel({ formKey: `itemCapabilityForm` }));
        }
        break;
      case FormActions.Create.id:
        resultAction = await dispatch(
          createCapability({ ...formData, ...values })
        );
        if (!resultAction.error) {
          await dispatch(fetchCapabilities());
          resetForm();
          dispatch(closePanel({ formKey: `itemCapabilityForm` }));
          if (tagNameRef.current) {
            tagNameRef.current.focus();
          }
        }
        break;
      default:
        break;
    }
    dispatch(resetTeamsState());
    dispatch(deactivateLoading());
    const { message, variant } = snackbarHandler(
      resultAction.meta.requestStatus,
      formAction.label
    );
    enqueueSnackbar(message, {
      action: (key) => <SnackbarDismiss key={key} />,
      variant: variant,
    });
    setSubmitting(false);
  };

  const handleLevelDropDown = () => {
    setIsLevelDropDown(true);
  };

  const handleLevelId = (val) => {
    const values = val.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.value === value.value)
    );

    setFormData((prevValue) => ({
      ...prevValue,
      levels: values.map((item) => ({
        level_id: item.value,
        level_display_name: item.text,
        level_color: item.color,
        level_description: item.description,
      })),
    }));
    if (values.length === 1) {
      const newLevel = values[0];
      setCheckedLevelState(newLevel?.value);
      setLevelObject({
        level_id: newLevel?.value,
        level_display_name: newLevel?.text,
        level_color: newLevel?.color,
        level_description: newLevel?.description,
      });
    }
    setIsLevelDropDown(false);
  };

  const removeLevel = (idx, setFieldValue) => {
    const capabilityLevel = { ...formData };
    let levelsData = [...capabilityLevel.levels];
    let deletedLevel = levelsData.splice(idx, 1);
    if (checkedLevelState === deletedLevel[0]?.level_id) {
      setCheckedLevelState(null);
      setLevelObject(null);
    }
    let updatedSkills = { ...capabilityLevel.skills };
    delete updatedSkills[deletedLevel[0]?.level_id];

    capabilityLevel.levels = levelsData;
    capabilityLevel.skills = updatedSkills;

    if (checkedLevelState === deletedLevel[0]?.level_id) {
      const newLevelId = capabilityLevel?.levels[0]?.level_id;
      setCheckedLevelState(newLevelId);
      setLevelObject(capabilityLevel?.levels[0]);
    }
    setFormData(capabilityLevel);
  };

  const handleSkillData = (val) => {
    const levelId = val.level_id;
    setLevelObject(val);
    setCheckedLevelState(levelId);
  };

  return !loaded ? (
    <Loader styleClassName={classes.loadingSvg} />
  ) : (
    <Box>
      <Grid container>
        <Grid item={true} xs={12} lg={6}>
          <Formik
            enableReinitialize={true}
            initialValues={{ ...initValues }}
            validationSchema={Yup.object({
              display_name: Yup.string().required('Required'),
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              handleSubmit(values, setSubmitting, resetForm);
            }}
          >
            {({ isSubmitting, errors, setFieldValue }) => (
              <Form className={classes.form}>
                <FormTextField
                  autoFocus
                  label={user?.label_capability}
                  name="display_name"
                  placeholder={user?.label_capability}
                  disabled={readOnly}
                  error={!!errors.display_name}
                  helperText={errors.display_name}
                />
                <FormTextField
                  multiline
                  rows={3}
                  disabled={readOnly}
                  label="Description"
                  name="description"
                  placeholder="Description"
                />
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '1rem 0',
                  }}
                >
                  <Box>
                    {formData?.levels?.length > 0 && (
                      <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue={checkedLevelState}
                        value={checkedLevelState}
                        name="radio-buttons-group"
                      >
                        <Each
                          of={formData?.levels}
                          render={(item, index) => (
                            <Box key={item?.level_id}>
                              <FormControlLabel
                                key={item.level_id}
                                value={item.level_id}
                                control={
                                  <Radio
                                    sx={{
                                      '& .MuiSvgIcon-root': {
                                        fill: validateAndFormatColor(
                                          item.level_color
                                        ),
                                      },
                                      '& + .MuiFormControlLabel-label': {
                                        color: validateAndFormatColor(
                                          item.level_color
                                        ),
                                      },
                                    }}
                                    required={true}
                                    onChange={() => handleSkillData(item)}
                                  />
                                }
                                label={item.level_display_name}
                              />
                              {!readOnly && (
                                <IconButton
                                  onClick={() =>
                                    removeLevel(index, setFieldValue)
                                  }
                                >
                                  <MdDoNotDisturbOn />
                                </IconButton>
                              )}
                            </Box>
                          )}
                        />
                      </RadioGroup>
                    )}
                  </Box>
                  {!readOnly && (
                    <IconButton
                      onClick={() => handleLevelDropDown()}
                      disableRipple
                      sx={{ marginLeft: 'auto' }}
                      size="small"
                    >
                      <MdAdd /> Add Level
                    </IconButton>
                  )}
                </Box>
                {isLevelDropDown && levelDropDown?.length > 0 && (
                  <Autocomplete
                    sx={{
                      minWidth: '180px',
                      maxWidth: '100%',
                    }}
                    limitTags={2}
                    multiple
                    disableCloseOnSelect
                    variant="outline"
                    id="level-id"
                    options={levelDropDown}
                    onChange={(event, newInputValue) => {
                      handleLevelId(newInputValue);
                    }}
                    value={formData.levels.map((item) => ({
                      value: item.level_id,
                      text: item.level_display_name,
                      color: item.level_color,
                      description: item.level_description,
                    }))}
                    getOptionLabel={(option) => String(option.text)}
                    renderOption={(props, option, { selected }) => (
                      <li {...props} key={props.id}>
                        {option.text}
                      </li>
                    )}
                    renderInput={(params) => (
                      <MuiTextField
                        {...params}
                        variant="outlined"
                        label="Level Id"
                        placeholder="Level Id"
                      />
                    )}
                  />
                )}
                {levelObject?.level_description && (
                  <Typography
                    sx={{
                      backgroundColor: '#dddddd',
                      padding: '1rem',
                      marginTop: '1rem',
                    }}
                  >
                    {levelObject.level_description}
                  </Typography>
                )}

                {getCapabilityLevelData &&
                  formAction.id !== FormActions.Create.id && (
                    <Box
                      sx={{
                        margin: '1rem 0',
                        border: '2px solid #dddddd',
                      }}
                    >
                      <Typography
                        sx={{
                          backgroundColor: '#dddddd',
                          padding: '1rem',
                        }}
                      >
                        {`${user?.label_capability} History`}
                      </Typography>
                      <Grid container sx={{ padding: '1rem' }}>
                        <Grid item={true} xs={12}>
                          <Typography>
                            Author:{' '}
                            {
                              getCapabilityLevelData?.created_by_employee_full_name
                            }
                          </Typography>
                          Creation:{' '}
                          {moment(getCapabilityLevelData?.created_date)
                            .local()
                            .format('YYYY-MM-DD')}
                          <Typography>
                            Last Modified:{' '}
                            {moment(getCapabilityLevelData?.modified_date)
                              .local()
                              .format('YYYY-MM-DD')}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                {!readOnly && (
                  <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    fullWidth
                    type="submit"
                    disabled={isSubmitting}
                    sx={{ marginTop: '0.5rem' }}
                  >
                    {formAction.buttonLabel}
                  </Button>
                )}
              </Form>
            )}
          </Formik>
        </Grid>
        <Grid item={true} xs={12} lg={6} sx={{ padding: '0 1rem' }}>
          {checkedLevelState ? (
            <ItemCapabilitySkillTable
              currentLevelId={checkedLevelState}
              setFormData={setFormData}
              formData={formData}
              levelObject={levelObject}
              user={user}
              readOnly={readOnly}
              itemTagId={itemTagId}
              formAction={formAction}
            />
          ) : (
            <Typography textAlign={'center'}>No Skill data found</Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export { ItemCapabilityForm };
