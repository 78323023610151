import React, { useState, useRef, useEffect, useCallback } from 'react';
import { AutoSizer } from 'react-virtualized';
import IconButton from '@mui/material/IconButton';
import { Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { MdAdd, MdRefresh } from 'react-icons/md';
import { mapValues } from 'lodash';

import SearchWithFilters from 'components/SearchWithFilters';
import {
  actionCell,
  checkCell,
  checkHeaderCell,
  clipLabelTooltipCell,
  labelCell,
  labelHeader,
} from 'shared/components/_Tables/TableCells';
import { makeButtonStyles } from 'components/styles';
import { VirtualTable } from 'shared/components/_Tables/VirtualTable';
import { EnhancedTableToolbar } from 'components/EnhancedTableToolbar';
import { tableStyles } from 'shared/components/_Tables/VirtualTable/styles';
import { Action } from 'helpers/action';
import { mdTheme } from 'theme';
import { PopupAction } from 'components/_Misc/PopupAction/components/PopupAction';
import {
  closePanel,
  openPanel,
  setEditObjectId,
} from 'features/common/formSlice';
import { NoDataMessage } from 'shared/components/_Tables/NoDataMessage';
import { LoadingStatus, NO_MATCHING_OFFICES, OFFICE_TABLE_KEYS } from 'helpers';
import { DropZoneImport } from 'shared/components/_DropZoneImport';
import { selectActiveUser } from 'features/account/accountSlice';
import { ItemOfficeFilter } from '../ItemOfficeFilter';
import {
  exportOfficeCsv,
  fetchOffice,
  getImportOfficeHelp,
  getOfficeImportSampleCsv,
  getOfficeInfoStatus,
  getOfficeStatus,
  resetOfficeInfoState,
  resetOfficeState,
  uploadOfficeImportCsv,
} from 'features/Office/officeSlice';
import { useDebounce } from 'hooks/useDebounce';
import { CustomDialog } from 'shared/components/_Modal';
import {
  fetchOfficeByCostCenterId,
  getOfficeByCostCenterIdDropDownStatus,
  selectOfficeByCostCenterIdDropDown,
} from 'features/AdminSettingsDropDown/dropDownSlice';

function ItemOfficeTable({
  offices,
  pageInfo,
  itemOfficePayload,
  setItemOfficePayload,
  sortDirection,
  sortBy,
  handleSort,
}) {
  const dispatch = useDispatch();
  let updatedPayload = mapValues(itemOfficePayload);
  const buttonStyles = makeButtonStyles(mdTheme);
  const tableClasses = tableStyles();
  const [selected, setSelected] = useState([]);
  const [selectedAll, setSelectedAll] = React.useState(false);
  const [openHelpDialog, setOpenHelpDialog] = useState(false);

  const getInfoStatus = useSelector(getOfficeInfoStatus);
  const officeStatus = useSelector(getOfficeStatus);
  const loadedRowsMap = useSelector((state) => state.offices.loadedOfficeMap);
  const user = useSelector(selectActiveUser);
  const hideOfficeIdColumn = user?.hide_system_ids;
  const officeColumnIds = user?.admin_field_ids;

  const headerHeight = 40;
  const rowHeight = 60;

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const prevSelectedAll = usePrevious(selectedAll);
  if (prevSelectedAll && selectedAll) {
    if (selected.length !== offices?.length) {
      setSelected(offices.map((row) => row.office_id));
      setSelectedAll(true);
    }
  }

  const onRefreshIconClick = async () => {
    await dispatch(fetchOffice({ ...updatedPayload }));
  };

  const handleClick = (event, office_id) => {
    event.stopPropagation();
    const selectedIndex = selected.indexOf(office_id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, office_id);
    } else {
      newSelected = selected.filter((id) => id !== office_id);
    }
    setSelected(newSelected);
    setSelectedAll(newSelected.length === offices?.length);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelectedIds = offices.map((tag) => tag.office_id);
      setSelected(newSelectedIds);
      setSelectedAll(true);
      return;
    }
    setSelected([]);
    setSelectedAll(false);
  };

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const handleRowClick = async ({ rowData }) => {
    await dispatch(closePanel({ formKey: 'itemOfficeForm' }));
    if (rowData?.office_id) {
      await dispatch(
        setEditObjectId({
          formKey: `itemOfficeForm`,
          object_id: rowData?.office_id,
        })
      );
      dispatch(openPanel({ formKey: `itemOfficeForm`, formAction: 'Edit' }));
    }
  };

  const init_columns = [
    {
      classes: tableClasses,
      minWidth: 50,
      maxWidth: 50,
      width: 50,
      label: '',
      dataKey: 'office_id',
      padding: 'checkbox',
      cellLevel: 'office',
      numSelected: selected.length,
      rowCount: offices?.length,
      handleClick: handleClick,
      handleSelectAllClick: handleSelectAllClick,
      isSelected: isSelected,
      component: checkCell,
      headerComponent: checkHeaderCell,
    },
    {
      classes: tableClasses,
      minWidth: 50,
      maxWidth: 50,
      width: 50,
      label: '',
      dataKey: 'office_id',
      padding: 'none',
      actionCellLevel: 'office',
      targetPopup: 'officeManagement',
      formKey: 'itemOfficeForm',
      dispatch: dispatch,
      component: actionCell,
      headerComponent: labelHeader,
    },
  ];

  const handleColumnVisibility = (key, column) => {
    if (hideOfficeIdColumn && key === 'office_id') {
      return undefined;
    }

    const isVisible = officeColumnIds?.includes(OFFICE_TABLE_KEYS?.[key]?.id);
    if (isVisible) {
      return column;
    }
  };

  const columns = [
    ...init_columns,
    handleColumnVisibility('office_id', {
      classes: tableClasses,
      width: 160,
      minWidth: 160,
      flexGrow: 1,
      label: 'office Id',
      dataKey: 'office_id',
      padding: 'normal',
      component: labelCell,
      headerComponent: labelHeader,
      sortDirection: sortDirection,
      sortBy: sortBy,
      handleSort: handleSort,
    }),
    handleColumnVisibility('cost_center', {
      classes: tableClasses,
      width: 260,
      minWidth: 260,
      flexGrow: 1,
      label: 'Cost Center',
      dataKey: 'cost_center',
      padding: 'normal',
      component: labelCell,
      headerComponent: labelHeader,
      sortDirection: sortDirection,
      sortBy: sortBy,
      handleSort: handleSort,
    }),
    handleColumnVisibility('directorate', {
      classes: tableClasses,
      flexGrow: 1,
      width: 120,
      minWidth: 120,
      label: 'Directorate',
      dataKey: 'directorate',
      padding: 'normal',
      component: labelCell,
      headerComponent: labelHeader,
      sortDirection: sortDirection,
      sortBy: sortBy,
      handleSort: handleSort,
    }),
    handleColumnVisibility('symbol', {
      classes: tableClasses,
      flexGrow: 1,
      width: 120,
      minWidth: 120,
      label: 'Office Symbol',
      dataKey: 'symbol',
      padding: 'normal',
      component: labelCell,
      headerComponent: labelHeader,
      sortDirection: sortDirection,
      sortBy: sortBy,
      handleSort: handleSort,
    }),
    handleColumnVisibility('description', {
      classes: tableClasses,
      width: 250,
      minWidth: 250,
      label: 'Description',
      dataKey: 'description',
      padding: 'normal',
      component: clipLabelTooltipCell,
      headerComponent: labelHeader,
    }),
  ].filter(Boolean);

  // eslint-disable-next-line no-unused-vars
  async function loadMoreRows({ startIndex, stopIndex }) {
    if (officeStatus !== LoadingStatus.Loading) {
      await dispatch(
        fetchOffice({
          ...updatedPayload,
          reset: false,
          startIndex: startIndex,
          stopIndex: stopIndex,
        })
      );
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    useDebounce((searchString) => {
      setItemOfficePayload({
        ...itemOfficePayload,
        searchString: searchString,
      });
    }, 1000),
    [itemOfficePayload]
  );

  const handleCreateNew = () => {
    dispatch(openPanel({ formKey: 'itemOfficeForm', formAction: 'Create' }));
  };

  return (
    <div className={tableClasses.root}>
      <ItemOfficeFilter
        setItemOfficePayload={setItemOfficePayload}
        itemOfficePayload={itemOfficePayload}
      />
      {selected.length > 0 && (
        <EnhancedTableToolbar
          numSelected={selected.length}
          toolTipTitle={'Delete'}
          totalCount={pageInfo?.TotalCount}
        >
          <PopupAction
            action={Action.DeleteArray}
            object={{
              office_id: selected,
              setOfficeIds: setSelected,
            }}
            level={'office'}
            showLabel={false}
          />
        </EnhancedTableToolbar>
      )}
      <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
        <Tooltip title="Add New Office">
          <IconButton
            onClick={handleCreateNew}
            size="large"
            className={buttonStyles.contentAddButton}
          >
            <MdAdd />
          </IconButton>
        </Tooltip>
        <div style={{ flexGrow: 1 }}></div>
        <DropZoneImport
          key="office-dzi"
          getEntityImportSample={getOfficeImportSampleCsv}
          uploadEntityState={uploadOfficeImportCsv}
          resetEntitySelector={resetOfficeState}
          mainTitle="Office"
          exportEntityState={exportOfficeCsv}
          exportFilterData={updatedPayload}
          isExport
          setOpenHelpDialog={setOpenHelpDialog}
          isInfoIcon
        />
        <CustomDialog
          getEntityHelp={getImportOfficeHelp}
          fieldName="office_id"
          selectDropdownEntity={selectOfficeByCostCenterIdDropDown}
          getDropdownStatusEntity={getOfficeByCostCenterIdDropDownStatus}
          fetchDropDownEntityPage={fetchOfficeByCostCenterId}
          openHelpDialog={openHelpDialog}
          setOpenHelpDialog={setOpenHelpDialog}
          getEntityHelpStatus={getInfoStatus}
          selectLabel="Select Office"
          resetHelpEntity={resetOfficeInfoState}
        />
        <IconButton
          onClick={onRefreshIconClick}
          aria-label="close"
          sx={{
            color: '#707070',
          }}
          size="large"
        >
          <MdRefresh style={{ fontSize: '18px', color: 'black' }} />
        </IconButton>
        <SearchWithFilters
          filter={false}
          onChange={(evt) => debouncedSearch(evt.target.value)}
          value={itemOfficePayload?.searchString}
        />
      </div>
      {offices.length > 0 ? (
        <div
          style={{
            height: 'calc(100vh - 295px)',
            minWidth: '500px',
            paddingTop: '2rem',
          }}
        >
          <AutoSizer>
            {({ width, height }) => (
              <VirtualTable
                initialRows={offices}
                allRows={offices}
                totalRowCount={pageInfo?.TotalCount}
                loadedRowsMap={loadedRowsMap}
                loadMoreRows={loadMoreRows}
                columnDefinitions={columns}
                cellClassName={tableClasses.flexContainer}
                className={tableClasses.table}
                rowClassName={tableClasses.flexContainer}
                headerHeight={headerHeight}
                width={width}
                height={height}
                rowHeight={rowHeight}
                onRowClick={handleRowClick}
              />
            )}
          </AutoSizer>
        </div>
      ) : (
        <NoDataMessage message={NO_MATCHING_OFFICES} />
      )}
    </div>
  );
}

export { ItemOfficeTable };
