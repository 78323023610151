import makeStyles from '@mui/styles/makeStyles';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  title: {
    flex: '1 1 100%',
  },
}));

const makeButtonStyles = makeStyles((theme) => ({
  contentAddButton: {
    padding: '10px !important',
    borderRadius: '25px !important',
    background: (props) => `${props.palette.background.drawer} !important`,
    color: (props) => `${props.palette.common.white} !important`,
    width: '48px !important',
    height: '48px !important',
  },
}));

const makeGridFormStyles = makeStyles((theme) => ({
  formControlField: {
    flex: '0 0 100%',
    maxWidth: '100%',
    [theme.breakpoints.up('md')]: {
      flex: '0 0 50%',
      maxWidth: 'calc(50% - 0.8rem)',
    },
  },
  fiftyWidthCalc: {
    flex: '0 0 100%',
    maxWidth: '100%',
    [theme.breakpoints.up('md')]: {
      flex: '0 0 50%',
      maxWidth: 'calc(50% - 0.8rem)',
    },
  },
}));

const panelStyles = makeStyles(() => ({
  popupTitle: {
    display: 'flex',
    alignItems: 'center',
    color: '#7E8C8D',
    padding: '10px',
    fontSize: '16px',
    fontWeight: 'bold',
  },
  popupContainer: {
    display: 'flex',
    alignItems: 'center',
    color: '#7E8C8D',
    padding: '10px',
    fontSize: '14px',
  },
  drawerPaper: {
    '& .MuiDrawer-paper': {
      overflowY: 'auto !important',
    },
  },
  paper: {
    background: 'transparent',
  },
  paperOverflowVisible: {
    overflowY: 'visible',
  },
  paperOverflowHidden: {
    overflowY: 'hidden',
  },
  list: {
    width: 576,
  },
  selectBox: {
    marginTop: '10px',
    border: '1px solid #c1c1c1',
    borderRadius: '5px',
    padding: '2px 10px',
    color: '#838383',
    '&:before': {
      borderBottom: 'unset !important',
    },
    '&:after': {
      borderBottom: 'unset !important',
    },
    '& .MuiNativeSelect-select:focus': {
      backgroundColor: 'inherit',
    },
  },
  dropzoneContainer: {
    marginTop: '15px',
    flexGrow: 2,
  },
  dropzoneArea: {
    minHeight: '160px !important',
    '& svg': {
      width: '1em',
      height: '1em',
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid',
    borderRadius: '5px',
  },
  dropZoneMinHeight50: {
    minHeight: '50px !important',
  },
  dropzoneAreaCompressed: {
    minHeight: '80px !important',
    '& svg': {
      width: '1em',
      height: '1em',
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dropzoneText: {
    fontSize: '1em !important',
    margin: '10px !important',
    backgroundColor: '#0009',
    color: 'white',
    cursor: 'default',
    borderRadius: '5px',
    padding: '5px',
    width: 'max-content',
  },
  previewUploadFileName: {
    backgroundColor: '#18BC9B',
    color: 'white',
    width: '100%',
  },
  dropzonePreviewContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  originalAsset: {
    display: 'flex',
    height: '150px',
    marginTop: '15px',
    '& img': {
      objectFit: 'cover',
      width: '100px',
    },
  },
  hidden: {
    display: 'none',
  },
  unsaveData: {
    backgroundColor: 'white',
    zIndex: 2,
    pointerEvents: 'auto',
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    position: 'relative',
    overflow: 'auto',
  },
}));

export { useToolbarStyles, makeButtonStyles, makeGridFormStyles, panelStyles };
