import { useDispatch, useSelector } from 'react-redux';
import Modal from '@mui/material/Modal';
import { Typography, Button } from '@mui/material';
import clsx from 'clsx';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import {
  deletePayPlan,
  deletePayPlanArray,
} from 'features/payPlan/payPlansSlice';
import {
  Action,
  CAPABILITY_CACHE,
  COST_CENTER_CACHE,
  EMPLOYEE_CACHE,
  JOB_ROLE_CACHE,
  LoadingStatus,
  OFFICE_CACHE,
  SKILL_CACHE,
  snackbarHandler,
} from 'helpers';
import { SnackbarDismiss } from 'shared/components/SnackbarDismiss';
import {
  activateLoading,
  deactivateLoading,
  getLoadingState,
} from 'features/global/loadingProgressSlice';
import { closeModal } from 'features/common/actionModalSlice';
import getActionIcon from 'helpers/getActionIcon';
import { modalStyles } from '../Modals.styles';
import {
  deletePayGrade,
  deletePayGradeArray,
} from 'features/payGrade/payGradeSlice';
import { deleteJob, deleteJobsArray } from 'features/job/jobSlice';
import {
  deleteCapabilitiesArray,
  deleteCapability,
} from 'features/capability/capabilitySlice';
import { deleteSkill, deleteSkillArray } from 'features/skill/skillSlice';
import {
  deleteJobRole,
  deleteJobRolesArray,
} from 'features/jobRole/jobRoleSlice';
import { deleteLevel, deleteLevelArray } from 'features/Levels/levelSlice';
import {
  deleteEmployee,
  deleteEmployeeArray,
} from 'features/employee/employeeSlice';
import {
  deleteCostCenter,
  deleteCostCenterArray,
  setCostCenterStatus,
} from 'features/CostCenter/costCenterSlice';
import {
  deleteApiClient,
  deleteApiClientsArray,
} from 'features/ApiClientSlice/ApiClientSlice';
import { deleteOffice, deleteOfficeArray } from 'features/Office/officeSlice';
import { selectSearchCache, updateSearchCache } from 'features/Teams/teamSlice';
import {
  selectAdminSettingCache,
  updateAdminSettingCache,
} from 'features/global/visibilitySlice';
import _ from 'lodash';

function ActionModal({ isOpen, object, action, level }) {
  const classes = modalStyles();
  const dispatch = useDispatch();
  const loadingState = useSelector(getLoadingState);
  const { enqueueSnackbar } = useSnackbar();
  const getCacheData = useSelector(selectSearchCache);
  const adminCacheData = useSelector(selectAdminSettingCache);

  useEffect(() => {
    if (isOpen) {
      window.addEventListener('keyup', handleEnterKey);
    } else {
      window.removeEventListener('keyup', handleEnterKey);
    }
  }, [isOpen]);

  const onClose = () => dispatch(closeModal());

  let resultAction = null;

  const confirmAction = async () => {
    var altLabel = action.label;
    var altMessage = action.message;
    if (loadingState === false) {
      await dispatch(activateLoading());
      try {
        switch (level) {
          case 'payPlan':
            switch (action.label) {
              case 'delete':
                resultAction = await dispatch(
                  deletePayPlan(object?.pay_plan_id)
                );
                if (!resultAction?.error) {
                  if (
                    adminCacheData?.[EMPLOYEE_CACHE]?.pay_plan_id?.value ===
                    object.pay_plan_id
                  ) {
                    dispatch(
                      updateAdminSettingCache({
                        key: EMPLOYEE_CACHE,
                        payload: { pay_plan_id: null },
                      })
                    );
                  }
                }
                break;
              case 'deleteArray':
                altLabel = '';
                resultAction = await dispatch(
                  deletePayPlanArray(object.payPlanIds)
                );
                if (!resultAction?.error) {
                  if (
                    object.payPlanIds.includes(
                      adminCacheData?.[EMPLOYEE_CACHE]?.pay_plan_id?.value
                    )
                  ) {
                    dispatch(
                      updateAdminSettingCache({
                        key: EMPLOYEE_CACHE,
                        payload: { pay_plan_id: null },
                      })
                    );
                  }
                }
                object.setPayPlanIds([]);
                altMessage = resultAction.payload?.message;
                break;
              default:
                break;
            }
            break;
          case 'skill':
            switch (action.label) {
              case 'delete':
                resultAction = await dispatch(deleteSkill(object?.skill_id));
                if (!resultAction?.error) {
                  if (
                    adminCacheData?.[CAPABILITY_CACHE]?.skill_ids?.length > 0
                  ) {
                    let skillCapabilities = [
                      ...adminCacheData?.[CAPABILITY_CACHE]?.skill_ids,
                    ];
                    skillCapabilities.map((val, index) => {
                      if (val?.value === object?.skill_id) {
                        skillCapabilities[index] = null;
                      }
                    });
                    if (
                      !_.isEqual(
                        skillCapabilities,
                        adminCacheData?.[CAPABILITY_CACHE]?.skill_ids
                      )
                    ) {
                      dispatch(
                        updateAdminSettingCache({
                          key: CAPABILITY_CACHE,
                          payload: {
                            skill_ids: skillCapabilities,
                          },
                        })
                      );
                    }
                  }
                }
                break;
              case 'deleteArray':
                altLabel = '';
                resultAction = await dispatch(
                  deleteSkillArray(object.skillIds)
                );
                if (!resultAction?.error) {
                  if (
                    adminCacheData?.[CAPABILITY_CACHE]?.skill_ids?.length > 0
                  ) {
                    let skillCapabilities = [
                      ...adminCacheData?.[CAPABILITY_CACHE]?.skill_ids,
                    ];
                    skillCapabilities.map((val, index) => {
                      if (object?.skillIds.includes(val?.value)) {
                        skillCapabilities[index] = null;
                      }
                    });
                    if (
                      !_.isEqual(
                        skillCapabilities,
                        adminCacheData?.[CAPABILITY_CACHE]?.skill_ids
                      )
                    ) {
                      dispatch(
                        updateAdminSettingCache({
                          key: CAPABILITY_CACHE,
                          payload: {
                            skill_ids: skillCapabilities,
                          },
                        })
                      );
                    }
                  }
                }
                object.setSkillIds([]);
                altMessage = resultAction.payload?.message;
                break;
              default:
                break;
            }
            break;
          case 'capability':
            switch (action.label) {
              case 'delete':
                resultAction = await dispatch(
                  deleteCapability(object?.capability_id)
                );
                if (!resultAction.error) {
                  if (
                    adminCacheData?.[SKILL_CACHE]?.capability_id?.value ===
                    object?.capability_id
                  ) {
                    dispatch(
                      updateAdminSettingCache({
                        key: SKILL_CACHE,
                        payload: {
                          capability_id: null,
                        },
                      })
                    );
                  }
                  if (
                    adminCacheData?.[JOB_ROLE_CACHE]?.capability_ids?.length > 0
                  ) {
                    let jobRoleCapabilities = [
                      ...adminCacheData?.[JOB_ROLE_CACHE]?.capability_ids,
                    ];
                    jobRoleCapabilities.map((val, index) => {
                      if (val?.value === object?.capability_id) {
                        jobRoleCapabilities[index] = null;
                      }
                    });
                    if (
                      !_.isEqual(
                        jobRoleCapabilities,
                        adminCacheData?.[JOB_ROLE_CACHE]?.capability_ids
                      )
                    ) {
                      dispatch(
                        updateAdminSettingCache({
                          key: JOB_ROLE_CACHE,
                          payload: {
                            capability_ids: jobRoleCapabilities,
                          },
                        })
                      );
                    }
                  }
                }
                break;
              case 'deleteArray':
                altLabel = '';
                resultAction = await dispatch(
                  deleteCapabilitiesArray(object.capabilityIds)
                );
                if (!resultAction.error) {
                  if (
                    adminCacheData?.[JOB_ROLE_CACHE]?.capability_ids?.length > 0
                  ) {
                    let jobRoleCapabilities = [
                      ...adminCacheData?.[JOB_ROLE_CACHE]?.capability_ids,
                    ];
                    jobRoleCapabilities.map((val, index) => {
                      if (object?.capabilityIds.includes(val?.value)) {
                        jobRoleCapabilities[index] = null;
                      }
                    });
                    if (
                      !_.isEqual(
                        jobRoleCapabilities,
                        adminCacheData?.[JOB_ROLE_CACHE]?.capability_ids
                      )
                    ) {
                      dispatch(
                        updateAdminSettingCache({
                          key: JOB_ROLE_CACHE,
                          payload: {
                            capability_ids: jobRoleCapabilities,
                          },
                        })
                      );
                    }
                  }
                  if (
                    object.capabilityIds.includes(
                      adminCacheData?.[SKILL_CACHE]?.capability_id?.value
                    )
                  ) {
                    dispatch(
                      updateAdminSettingCache({
                        key: SKILL_CACHE,
                        payload: {
                          capability_id: null,
                        },
                      })
                    );
                  }
                }
                object.setCapabilityIds([]);
                altMessage = resultAction.payload?.message;
                break;
              default:
                break;
            }
            break;
          case 'payGrades':
            switch (action.label) {
              case 'delete':
                resultAction = await dispatch(
                  deletePayGrade(object?.pay_grade_id)
                );
                if (!resultAction?.error) {
                  if (
                    adminCacheData?.[EMPLOYEE_CACHE]?.pay_grade_id?.value ===
                    object.pay_grade_id
                  ) {
                    dispatch(
                      updateAdminSettingCache({
                        key: EMPLOYEE_CACHE,
                        payload: { pay_grade_id: null },
                      })
                    );
                  }
                }
                break;
              case 'deleteArray':
                altLabel = '';
                resultAction = await dispatch(
                  deletePayGradeArray(object.payGradeIds)
                );
                if (!resultAction?.error) {
                  if (
                    object.payGradeIds.includes(
                      adminCacheData?.[EMPLOYEE_CACHE]?.pay_grade_id?.value
                    )
                  ) {
                    dispatch(
                      updateAdminSettingCache({
                        key: EMPLOYEE_CACHE,
                        payload: { pay_grade_id: null },
                      })
                    );
                  }
                }
                object.setPayGradeIds([]);
                altMessage = resultAction.payload?.message;
                break;
              default:
                break;
            }
            break;
          case 'job':
            switch (action.label) {
              case 'delete':
                resultAction = await dispatch(deleteJob(object?.job_id));
                if (!resultAction.error) {
                  if (getCacheData?.job_id?.value === object.job_id) {
                    dispatch(
                      updateSearchCache({
                        ...getCacheData,
                        job_id: null,
                      })
                    );
                  }
                  if (
                    adminCacheData?.[JOB_ROLE_CACHE]?.job_id?.value ===
                    object.job_id
                  ) {
                    dispatch(
                      updateAdminSettingCache({
                        key: JOB_ROLE_CACHE,
                        payload: { job_id: null },
                      })
                    );
                  }
                  if (
                    adminCacheData?.[CAPABILITY_CACHE?.job_id]?.value ===
                    object.job_id
                  ) {
                    dispatch(
                      updateAdminSettingCache({
                        key: CAPABILITY_CACHE,
                        payload: { job_id: null },
                      })
                    );
                  }
                  if (
                    adminCacheData?.[EMPLOYEE_CACHE]?.job_id?.value ===
                    object.job_id
                  ) {
                    dispatch(
                      updateAdminSettingCache({
                        key: EMPLOYEE_CACHE,
                        payload: { job_id: null },
                      })
                    );
                  }
                }
                break;
              case 'deleteArray':
                altLabel = '';
                resultAction = await dispatch(deleteJobsArray(object.jobIds));
                if (!resultAction.error) {
                  if (object.jobIds.includes(getCacheData?.job_id?.value)) {
                    dispatch(
                      updateSearchCache({
                        ...getCacheData,
                        job_id: null,
                      })
                    );
                  }
                  if (
                    object.jobIds.includes(
                      adminCacheData?.[JOB_ROLE_CACHE]?.job_id?.value
                    )
                  ) {
                    dispatch(
                      updateAdminSettingCache({
                        key: JOB_ROLE_CACHE,
                        payload: { job_id: null },
                      })
                    );
                  }
                  if (
                    object.jobIds.includes(
                      adminCacheData?.[CAPABILITY_CACHE]?.job_id?.value
                    )
                  ) {
                    dispatch(
                      updateAdminSettingCache({
                        key: CAPABILITY_CACHE,
                        payload: { job_id: null },
                      })
                    );
                  }
                  if (
                    object.jobIds.includes(
                      adminCacheData?.[EMPLOYEE_CACHE]?.job_id?.value
                    )
                  ) {
                    dispatch(
                      updateAdminSettingCache({
                        key: EMPLOYEE_CACHE,
                        payload: { job_id: null },
                      })
                    );
                  }
                }
                object.setJobIds([]);
                altMessage = resultAction.payload?.message;
                break;
              default:
                break;
            }
            break;
          case 'jobRole':
            switch (action.label) {
              case 'delete':
                resultAction = await dispatch(
                  deleteJobRole(object.job_role_id)
                );
                if (!resultAction.error) {
                  if (getCacheData?.job_role_id?.value === object.job_role_id) {
                    dispatch(
                      updateSearchCache({
                        ...getCacheData,
                        job_role_id: null,
                      })
                    );
                  }
                  if (
                    adminCacheData?.[CAPABILITY_CACHE]?.job_role_id?.value ===
                    object.job_role_id
                  ) {
                    dispatch(
                      updateAdminSettingCache({
                        key: CAPABILITY_CACHE,
                        payload: { job_role_id: null },
                      })
                    );
                  }
                  if (
                    adminCacheData?.[EMPLOYEE_CACHE]?.job_role_id?.value ===
                    object.job_role_id
                  ) {
                    dispatch(
                      updateAdminSettingCache({
                        key: EMPLOYEE_CACHE,
                        payload: { job_role_id: null },
                      })
                    );
                  }
                }
                altMessage = resultAction.payload?.message;
                break;
              case 'deleteArray':
                altLabel = '';
                resultAction = await dispatch(
                  deleteJobRolesArray(object.jobRoleIds)
                );
                if (!resultAction.error) {
                  if (
                    object.jobRoleIds.includes(getCacheData?.job_role_id?.value)
                  ) {
                    dispatch(
                      updateSearchCache({
                        ...getCacheData,
                        job_role_id: null,
                      })
                    );
                  }
                  if (
                    object.jobRoleIds.includes(
                      adminCacheData?.[CAPABILITY_CACHE]?.job_role_id?.value
                    )
                  ) {
                    dispatch(
                      updateAdminSettingCache({
                        key: CAPABILITY_CACHE,
                        payload: { job_role_id: null },
                      })
                    );
                  }
                  if (
                    object.jobRoleIds.includes(
                      adminCacheData?.[EMPLOYEE_CACHE]?.job_role_id?.value
                    )
                  ) {
                    dispatch(
                      updateAdminSettingCache({
                        key: EMPLOYEE_CACHE,
                        payload: { job_role_id: null },
                      })
                    );
                  }
                }
                object.setJobRoleIds([]);
                altMessage = resultAction.payload?.message;
                break;
              default:
                break;
            }
            break;
          case 'adminLevel':
            switch (action.label) {
              case 'delete':
                resultAction = await dispatch(deleteLevel(object?.level_id));
                break;
              case 'deleteArray':
                altLabel = '';
                resultAction = await dispatch(deleteLevelArray(object.levelId));
                object.setLevelIds([]);
                altMessage = resultAction.payload?.message;
                break;
              default:
                break;
            }
            break;
          case 'adminEmployee':
            let diffKey = [];
            switch (action.label) {
              case 'delete':
                resultAction = await dispatch(
                  deleteEmployee(object?.employee_id)
                );
                if (!resultAction.error) {
                  if (
                    getCacheData?.employee_id?.value === object?.employee_id
                  ) {
                    diffKey.push('employee_id');
                  }
                  if (
                    getCacheData?.supervisor_employee_id?.value ===
                    object?.employee_id
                  ) {
                    diffKey.push('supervisor_employee_id');
                  }
                  if (diffKey?.length > 0) {
                    diffKey.forEach((key) => {
                      dispatch(
                        updateSearchCache({
                          ...getCacheData,
                          [key]: null,
                        })
                      );
                    });
                  }
                  if (
                    adminCacheData?.[EMPLOYEE_CACHE]?.supervisor_employee_id
                      ?.value === object?.employee_id
                  ) {
                    dispatch(
                      updateAdminSettingCache({
                        key: EMPLOYEE_CACHE,
                        payload: {
                          supervisor_employee_id: null,
                        },
                      })
                    );
                  }
                }
                break;
              case 'deleteArray':
                altLabel = 'Employee';
                resultAction = await dispatch(
                  deleteEmployeeArray(object.employee_id)
                );
                if (!resultAction.error) {
                  if (
                    object.employee_id.includes(
                      getCacheData?.employee_id?.value
                    )
                  ) {
                    diffKey.push('employee_id');
                  }
                  if (
                    object.employee_id.includes(
                      getCacheData?.supervisor_employee_id?.value
                    )
                  ) {
                    diffKey.push('supervisor_employee_id');
                  }
                  if (diffKey?.length > 0) {
                    diffKey.forEach((key) => {
                      dispatch(
                        updateSearchCache({
                          ...getCacheData,
                          [key]: null,
                        })
                      );
                    });
                  }
                  if (
                    object.employee_id.includes(
                      adminCacheData?.[EMPLOYEE_CACHE]?.supervisor_employee_id
                        ?.value
                    )
                  ) {
                    dispatch(
                      updateAdminSettingCache({
                        key: EMPLOYEE_CACHE,
                        payload: {
                          supervisor_employee_id: null,
                        },
                      })
                    );
                  }
                }
                object.setEmployeeIds([]);
                altMessage = resultAction.payload?.message;
                break;
              default:
                break;
            }
            break;
          case 'costCenter':
            switch (action.label) {
              case 'delete':
                resultAction = await dispatch(
                  deleteCostCenter(object?.cost_center_id)
                );
                if (!resultAction?.error) {
                  if (
                    adminCacheData?.[EMPLOYEE_CACHE]?.cost_center_id?.value ===
                    object.cost_center_id
                  ) {
                    dispatch(
                      updateAdminSettingCache({
                        key: EMPLOYEE_CACHE,
                        payload: {
                          cost_center_id: null,
                        },
                      })
                    );
                  }
                  if (
                    adminCacheData?.[OFFICE_CACHE]?.cost_center_id?.value ===
                    object.cost_center_id
                  ) {
                    dispatch(
                      updateAdminSettingCache({
                        key: OFFICE_CACHE,
                        payload: {
                          cost_center_id: null,
                        },
                      })
                    );
                  }
                  if (
                    adminCacheData?.[COST_CENTER_CACHE]?.cost_center_id
                      ?.value === object.cost_center_id
                  ) {
                    dispatch(
                      updateAdminSettingCache({
                        key: COST_CENTER_CACHE,
                        payload: {},
                      })
                    );
                    dispatch(setCostCenterStatus(LoadingStatus.Idle));
                  }
                }
                break;
              case 'deleteArray':
                altLabel = 'Cost Center';
                resultAction = await dispatch(
                  deleteCostCenterArray(object.cost_center_id)
                );
                if (!resultAction?.error) {
                  if (
                    object.cost_center_id.includes(
                      adminCacheData?.[EMPLOYEE_CACHE]?.cost_center_id?.value
                    )
                  ) {
                    dispatch(
                      updateAdminSettingCache({
                        key: EMPLOYEE_CACHE,
                        payload: {
                          cost_center_id: null,
                        },
                      })
                    );
                  }
                  if (
                    object.cost_center_id.includes(
                      adminCacheData?.[OFFICE_CACHE]?.cost_center_id?.value
                    )
                  ) {
                    dispatch(
                      updateAdminSettingCache({
                        key: OFFICE_CACHE,
                        payload: {
                          cost_center_id: null,
                        },
                      })
                    );
                  }
                  if (
                    object.cost_center_id.includes(
                      adminCacheData?.[COST_CENTER_CACHE]?.cost_center_id?.value
                    )
                  ) {
                    dispatch(
                      updateAdminSettingCache({
                        key: COST_CENTER_CACHE,
                        payload: {
                          cost_center_id: null,
                        },
                      })
                    );
                    dispatch(setCostCenterStatus(LoadingStatus.Idle));
                  }
                }
                object.setCostCenterIds([]);
                altMessage = resultAction.payload?.message;
                break;
              default:
                break;
            }
            break;
          case 'externalApi':
            switch (action.label) {
              case 'delete':
                resultAction = await dispatch(
                  deleteApiClient(object?.api_client_id)
                );
                break;
              case 'deleteArray':
                altLabel = 'External API';
                resultAction = await dispatch(
                  deleteApiClientsArray(object.api_client_id)
                );
                object.setCostCenterIds([]);
                altMessage = resultAction.payload?.message;
                break;
              default:
                break;
            }
            break;
          case 'office':
            switch (action.label) {
              case 'delete':
                resultAction = await dispatch(deleteOffice(object?.office_id));
                if (!resultAction?.error) {
                  if (
                    adminCacheData?.[EMPLOYEE_CACHE]?.office_id?.value ===
                    object.office_id
                  ) {
                    dispatch(
                      updateAdminSettingCache({
                        key: EMPLOYEE_CACHE,
                        payload: { office_id: null },
                      })
                    );
                  }
                  if (
                    adminCacheData?.[COST_CENTER_CACHE]?.office_id?.value ===
                    object.office_id
                  ) {
                    dispatch(
                      updateAdminSettingCache({
                        key: COST_CENTER_CACHE,
                        payload: { office_id: null },
                      })
                    );
                  }
                }
                break;
              case 'deleteArray':
                altLabel = 'Offices';
                resultAction = await dispatch(
                  deleteOfficeArray(object.office_id)
                );
                if (!resultAction?.error) {
                  if (
                    object.office_id.includes(
                      adminCacheData?.[EMPLOYEE_CACHE]?.office_id?.value
                    )
                  ) {
                    dispatch(
                      updateAdminSettingCache({
                        key: EMPLOYEE_CACHE,
                        payload: { office_id: null },
                      })
                    );
                  }
                  if (
                    object.office_id.includes(
                      adminCacheData?.[COST_CENTER_CACHE]?.office_id?.value
                    )
                  ) {
                    dispatch(
                      updateAdminSettingCache({
                        key: COST_CENTER_CACHE,
                        payload: { office_id: null },
                      })
                    );
                  }
                }
                object.setOfficeIds([]);
                altMessage = resultAction.payload?.message;
                break;
              default:
                break;
            }
            break;
          default:
            break;
        }
        unwrapResult(resultAction);
        const { message, variant } = snackbarHandler(
          resultAction.meta.requestStatus,
          altLabel,
          altMessage
        );
        enqueueSnackbar(message, {
          action: (key) => <SnackbarDismiss snackItem={key} />,
          variant: variant,
        });
        onClose();
        dispatch(deactivateLoading());
      } catch (err) {
        onClose();
        dispatch(deactivateLoading());
        enqueueSnackbar(err?.message ? err.message : `${action.label} failed`, {
          action: (key) => <SnackbarDismiss snackItem={key} />,
          variant: 'error',
        });
      }
    }
  };

  const maybePluralize = (count, noun, suffix = 's') =>
    `${count} ${noun}${count !== 1 ? suffix : ''}`;

  if (level === 'group' && object.asset_count > 0) {
    return (
      <Modal onClose={() => false} open={isOpen}>
        <div className={classes.modalContainer}>
          <div className={classes.actionIcon}>
            {getActionIcon(action.iconId)}
          </div>
          <Typography
            className={classes.actionMessage}
          >{`Unable to delete group because it contains ${maybePluralize(
            object.asset_count,
            'asset'
          )}.`}</Typography>
          <div>
            <Button
              onClick={onClose}
              className={classes.buttonSpacing}
              variant="outlined"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    );
  }

  function modalMessage(messageType) {
    var messageObjectTarget =
      level === 'navLink'
        ? object.target_zone
        : level === 'costCenter'
        ? object?.psntl
        : level === 'tag'
        ? object?.name
        : level === 'office'
        ? object?.symbol
        : object.display_name ?? `${object.first_name} ${object.last_name}`;
    switch (messageType) {
      case Action.PublishArray.label:
        return (
          <Typography
            className={classes.actionMessage}
          >{`Are you sure you want to publish all of the selected?`}</Typography>
        );
      case Action.DeleteArray.label:
        return (
          <Typography
            className={classes.actionMessage}
          >{`Are you sure you want to delete all of the selected ${level}s?`}</Typography>
        );
      case Action.DeleteCurrentZone.label:
        return (
          <Typography
            className={classes.actionMessage}
          >{`Are you sure you want to delete ${object?.name}?`}</Typography>
        );
      default:
        return (
          <Typography
            className={classes.actionMessage}
          >{`Are you sure you want to ${action.label} "${messageObjectTarget}"?`}</Typography>
        );
    }
  }

  const handleEnterKey = (event) => {
    event.key === 'Enter' && confirmAction();
    window.removeEventListener('keyup', handleEnterKey);
  };

  return (
    <Modal onClose={() => false} open={isOpen}>
      <div className={classes.modalContainer}>
        <div className={classes.actionIcon}>{getActionIcon(action.iconId)}</div>
        <Typography variant="h6" component="p">
          {modalMessage(action.label)}
        </Typography>

        <div className={classes.actionWrapper}>
          <Button
            onClick={onClose}
            className={classes.buttonSpacing}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              window.removeEventListener('keyup', handleEnterKey);
              confirmAction();
            }}
            className={clsx(classes.buttonSpacing, classes.actionButton)}
            variant="contained"
          >
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export { ActionModal };
