import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import pluralize from 'pluralize';
import { useDispatch, useSelector } from 'react-redux';
import { MdExpandMore } from 'react-icons/md';
import { useParams } from 'react-router-dom';

import { ReactComponent as TaskIcon } from '../../../assets/critical_task_icon.svg';
import { ItemEmployeeTable } from '../ItemEmployeeTable';
import {
  fetchEmployeeCapability,
  getEmployeeCapabilityStatus,
  selectEmployeeCapability,
} from 'features/Teams/teamSlice';
import { Each } from 'helpers/Each';
import { mdTheme } from 'theme';
import { NoDataMessage } from 'shared/components/_Tables/NoDataMessage';
import { LoadingStatus, NO_MATCHING_CAPABILITIES } from 'helpers';
import {
  activateLoading,
  deactivateLoading,
} from 'features/global/loadingProgressSlice';

const EmployeeCapabilitiesTable = ({
  classes,
  itemEmployeePageInfo,
  setItemEmployeePayload,
  itemEmployeePayload,
  isUserRole,
}) => {
  //#region Constants
  const dispatch = useDispatch();
  const theme = mdTheme;
  let params = useParams();
  let paramsId = params.id;
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  const [sortBy, setSortBy] = useState('');
  const [sortDirection, setSortDirection] = useState('');
  //#endregion State

  //#region Selectors
  const employeeCapabilities = useSelector(selectEmployeeCapability);
  const itemEmpCapabilityStatus = useSelector(getEmployeeCapabilityStatus);
  //#endregion Selectors

  //#region Refs
  //#endregion Refs

  //#region Effects
  useEffect(() => {
    const getEmpCapabilities = async (id) => {
      await dispatch(activateLoading());
      await dispatch(fetchEmployeeCapability(id));
      await dispatch(deactivateLoading());
    };

    if (paramsId && itemEmpCapabilityStatus === LoadingStatus.Idle) {
      getEmpCapabilities(paramsId);
    }
  }, [dispatch, itemEmpCapabilityStatus, paramsId]);

  //#endregion Effects

  //#region Methods
  const setSorting = (_sortBy, _sortDirection) => {
    setSortBy(_sortBy);
    setSortDirection(_sortDirection);
    setItemEmployeePayload({
      ...itemEmployeePayload,
      sort: `${_sortDirection === 'ASC' ? '' : '-'}${_sortBy}`,
    });
  };
  //#endregion Methods

  //#region Render time calcs
  //#endregion Render time calcs

  //#region Render

  return (
    <>
      {employeeCapabilities?.length > 0 ? (
        <Each
          of={employeeCapabilities}
          render={(item, index) => (
            <Accordion
              key={index}
              defaultExpanded={index === 0}
              className={classes.cardWrapper}
            >
              <AccordionSummary
                expandIcon={<MdExpandMore className={classes.expandIcon} />}
                aria-controls={`panel-${index}-content`}
                id={`panel-${index}-header`}
                sx={{
                  backgroundColor: item?.header_color
                    ? item?.header_color
                    : theme.palette.secondary.main,
                  color: theme.colors.textWhite,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <TaskIcon className={classes.cardIcon} />
                <Typography
                  sx={{
                    margin: 0,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {pluralize(item?.header.trim(), item?.length)}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: 0 }}>
                <Grid container sx={{ padding: '0', overflow: 'auto' }}>
                  <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    <ItemEmployeeTable
                      employee={item?.capabilities}
                      levelData={item?.levels}
                      pageInfo={itemEmployeePageInfo}
                      empId={paramsId}
                      itemEmployeePayload={itemEmployeePayload}
                      setItemEmployeePayload={setItemEmployeePayload}
                      sortDirection={sortDirection}
                      sortBy={sortBy}
                      handleSort={(_sortBy, _sortDirection) =>
                        setSorting(_sortBy, _sortDirection)
                      }
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          )}
        />
      ) : (
        <NoDataMessage message={NO_MATCHING_CAPABILITIES} />
      )}
    </>
  );
  //#endregion Render
};
export { EmployeeCapabilitiesTable };
