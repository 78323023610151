import { Button, Card } from '@mui/material';
import { setIsLevelDropdownOpen } from 'features/AdminSettingsDropDown/dropDownSlice';
import { useDispatch } from 'react-redux';

const MultiSelectDoneButton = ({ children }) => {
  const dispatch = useDispatch();

  return (
    <Card>
      {children}
      <Button
        fullWidth
        variant="contained"
        size="medium"
        onClick={() => {
          dispatch(setIsLevelDropdownOpen(false));
        }}
      >
        Done
      </Button>
    </Card>
  );
};

export { MultiSelectDoneButton };
