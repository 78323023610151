import React from 'react';
import { AdminTabPanel } from 'components/_Layout/AdminTabPanel';

function AdminSettings() {
  return (
    <div>
      <AdminTabPanel />
    </div>
  );
}

export { AdminSettings };
