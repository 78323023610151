import React from 'react';
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { MdModeEdit } from 'react-icons/md';

import LocationIcon from 'assets/location_icon.png';
import { jobDepotLabels } from 'helpers';
import { useStyles } from './style';
import { Each } from 'helpers/Each';

export default function DepotTable({ theme, jobDepotData }) {
  const classes = useStyles(theme);

  return (
    <Box sx={{ paddingTop: '20px' }}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead className={classes.tableHead}>
            <TableRow className={classes.tableRow}>
              <TableCell colSpan={1}></TableCell>
              <Each
                of={jobDepotLabels}
                render={(item, idx) => (
                  <TableCell align="right" key={idx}>
                    <Typography variant="h5" component="p">
                      {item}
                    </Typography>
                  </TableCell>
                )}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            <Each
              of={jobDepotData}
              render={(data) => (
                <>
                  <TableRow className={classes.tableRowHeader}>
                    <TableCell colSpan={5} component="td" scope="row">
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <img alt="Location Icon" src={LocationIcon} />
                        <Typography variant="h6" component="p">
                          {data?.display_name}
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                  <Each
                    of={data?.jobs}
                    render={(item) => (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Box
                              sx={{
                                paddingLeft: '36px',
                                paddingRight: '20px',
                              }}
                            >
                              <Typography variant="body2" component="p">
                                {item?.display_name}
                              </Typography>

                              <Typography variant="body2" component="p">
                                {item?.series}
                              </Typography>
                            </Box>
                            <IconButton>
                              <MdModeEdit />
                            </IconButton>
                          </Box>
                        </TableCell>
                        <TableCell align="right" component="th" scope="row">
                          {(item?.not_trained_percentage).toFixed(2)}% (
                          {item?.not_trained})
                        </TableCell>
                        <TableCell align="right" component="th" scope="row">
                          {(item?.trained_percentage).toFixed(2)}% (
                          {item?.trained})
                        </TableCell>
                        <TableCell align="right" component="th" scope="row">
                          {(item?.professional_percentage).toFixed(2)}% (
                          {item?.professional})
                        </TableCell>
                        <TableCell align="right" component="th" scope="row">
                          {(item?.journeyman_percentage).toFixed(2)}% (
                          {item?.journeyman})
                        </TableCell>
                      </TableRow>
                    )}
                  />
                </>
              )}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
