import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  activateLoading,
  deactivateLoading,
} from 'features/global/loadingProgressSlice';
import { LoadingStatus, SKILL_CACHE } from 'helpers';
import {
  fetchSkill,
  getSkillPageInfo,
  getSkillStatus,
  resetSkillState,
  selectSkill,
} from 'features/skill/skillSlice';
import { ItemSkillTable } from './ItemSkillTable';
import { closePanel } from 'features/common/formSlice';
import {
  selectAdminSettingCache,
  updateAdminSettingCache,
} from 'features/global/visibilitySlice';
import { mapValues } from 'helpers/mapValues';
import { resetDropDownState } from 'features/AdminSettingsDropDown/dropDownSlice';

const SkillManagementTab = () => {
  //#region Constants
  const dispatch = useDispatch();
  const getCacheData = useSelector(selectAdminSettingCache);
  const getSkillData = async (payload) => {
    dispatch(activateLoading());
    let updatedPayload = mapValues(payload);
    await dispatch(fetchSkill({ ...updatedPayload }));
    dispatch(updateAdminSettingCache({ key: SKILL_CACHE, payload }));
    dispatch(closePanel({ formKey: `itemSkillForm` }));
    dispatch(deactivateLoading());
  };
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  const [itemSkillPayload, setItemSkillPayload] = useState({
    ...getCacheData?.[SKILL_CACHE],
    reset: true,
    startIndex: 0,
    stopIndex: 19,
  });
  const [sortBy, setSortBy] = useState(
    getCacheData?.[SKILL_CACHE]?.sort?.slice(0, 1) === '-'
      ? getCacheData?.[SKILL_CACHE]?.sort.slice(1)
      : getCacheData?.[SKILL_CACHE]?.sort || 'display_name'
  );

  const [sortDirection, setSortDirection] = useState(
    getCacheData?.[SKILL_CACHE]?.sort?.[0] !== '-' ? 'ASC' : 'DESC'
  );
  //#endregion State

  //#region Selectors
  const skills = useSelector(selectSkill);
  const itemSkillStatus = useSelector(getSkillStatus);
  const itemSkillPageInfo = useSelector(getSkillPageInfo);
  //#endregion Selectors

  //#region Refs
  const ref = useRef(false);
  //#endregion Refs

  //#region Effects
  useEffect(() => {
    if (ref.current === true) {
      getSkillData({ ...itemSkillPayload });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemSkillPayload]);

  useEffect(() => {
    if (itemSkillStatus === LoadingStatus.Idle) {
      getSkillData({ ...itemSkillPayload, startIndex: 0, stopIndex: 19 });
    }
    ref.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemSkillStatus, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetSkillState());
      dispatch(resetDropDownState());
    };
  }, []);

  //#endregion Effects

  //#region Methods
  const setSorting = (_sortBy, _sortDirection) => {
    setSortBy(_sortBy);
    setSortDirection(_sortDirection);
    setItemSkillPayload({
      ...itemSkillPayload,
      sort: `${_sortDirection === 'ASC' ? '' : '-'}${_sortBy}`,
    });
  };
  //#endregion Methods

  //#region Render time calcs
  //#endregion Render time calcs

  //#region Render

  return (
    <>
      <ItemSkillTable
        skills={skills}
        pageInfo={itemSkillPageInfo}
        itemSkillPayload={itemSkillPayload}
        setItemSkillPayload={setItemSkillPayload}
        sortDirection={sortDirection}
        sortBy={sortBy}
        handleSort={(_sortBy, _sortDirection) =>
          setSorting(_sortBy, _sortDirection)
        }
      />
    </>
  );
  //#endregion Render
};
export { SkillManagementTab };
