import React from 'react';

const FieldValidationError = ({ validationError, IsRelative = false }) => {
  return (
    <div
      style={{
        color: '#d32f2f',
        margin: '0',
        fontSize: '0.7rem',
        textAlign: 'left',
        position: IsRelative ? 'relative' : 'absolute',
      }}
    >
      {validationError}
    </div>
  );
};

export default FieldValidationError;
