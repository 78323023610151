import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { reportService } from 'features/services/reports.service';
import { LoadingStatus } from 'helpers';

const initialState = {
  status: LoadingStatus.Idle,
  error: null,
  reports: [],
  reportsStatus: LoadingStatus.Idle,
  reportsPageInfo: {},
  displayReports: {},
  reportsUsage: [],
  loadedReportsMap: {},
};

export const fetchReports = createAsyncThunk(
  'reports/fetchReports',
  async (params = {}) =>
    await reportService.getReports(
      params.cost_center,
      params.supervisor_employee_id,
      params.capability_id,
      params.job_id,
      params.job_role_id,
      params.report_expiry,
      params.custom_expiry_date,
      params.office_id,
      params.command_id,
      params.depot_id,
      params.start_index,
      params.stop_index
    )
);

export const getReportSampleCsv = createAsyncThunk(
  'reports/getReportSampleCsv',
  async (params) =>
    await reportService.getReportsCsv(
      params.cost_center,
      params.supervisor_employee_id,
      params.capability_id,
      params.job_id,
      params.job_role_id,
      params.office_id,
      params.report_expiry,
      params.custom_expiry_date,
      params.command_id,
      params.depot_id
    )
);

const reportSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    resetReportState: (state) => initialState,
  },
  extraReducers: {
    [fetchReports.pending]: (state, action) => {
      state.reportsStatus = LoadingStatus.Loading;
    },
    [fetchReports.fulfilled]: (state, action) => {
      state.reportsPageInfo = action.payload.pageInfo;
      state.reportsStatus = LoadingStatus.Loaded;

      if (action.meta.arg.reset) {
        state.loadedReportsMap = {};
      }

      const startIndex = action.meta.arg.startIndex;
      const stopIndex = startIndex + action.payload.reports.length - 1;

      for (var i = startIndex; i <= stopIndex; i++) {
        state.loadedReportsMap[i] = LoadingStatus.Loaded;
      }

      // In case we didn't load as many as requested
      for (
        let j = stopIndex + 1;
        j <= action.payload?.pageInfo?.TotalCount;
        j++
      ) {
        delete state.loadedReportsMap[j];
      }

      if (action.meta.arg.reset) {
        state.reports = action.payload.reports;
      } else {
        state.reports = state.reports.concat(action.payload.reports);
      }
    },
    [fetchReports.rejected]: (state, action) => {
      state.reportsStatus = LoadingStatus.Failed;
    },
    [getReportSampleCsv.fulfilled]: (state, action) => {
      if (action?.payload) {
        const url = window.URL.createObjectURL(new Blob([action.payload]));
        const link = document.createElement('a');
        link.href = url;
        let downloadName = action.payload.headers?.filename ?? 'Reports.csv';
        link.setAttribute('download', downloadName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    },
  },
});

export const selectReports = (state) => state.reports.reports;
export const getReportStatus = (state) => state.reports.reportsStatus;
export const getReportPageInfo = (state) => state.reports.reportsPageInfo;
export const getReportUsage = (state) => state.reports.reportsUsage;
export const getReportLoadedMap = (state) => state.reports.loadedReportsMap;

export const { resetReportState } = reportSlice.actions;

export default reportSlice.reducer;
