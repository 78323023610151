import { useRef, useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { makeFormStyles } from 'forms/style';
import { map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import {
    fetchCommandDropDown,
    fetchCostCenterDropDown,
    fetchDepotDropDown,
    fetchJobRolesByJobId,
    fetchJobsDropDown,
    fetchOfficeByCostCenterId,
    fetchPercentageDropDown,
    fetchSkillsDropDown,
    getCommandDropDownStatus,
    getCostCenterDropDownStatus,
    getDepotDropDownStatus,
    getJobDropDownStatus,
    getJobRolesByJobIdDropDownStatus,
    getOfficeByCostCenterIdDropDownStatus,
    getReportPercentageDropDownStatus,
    getSkillsDropDownStatus,
    resetDepotDropDownState,
    resetJobRoleDropdown,
    resetOfficeIdDropDown,
    selectCommondDropDown,
    selectCostCenterDropDown,
    selectDepotDropDown,
    selectJobDropDown,
    selectJobRoleByJobIdDropDown,
    selectOfficeByCostCenterIdDropDown,
    selectReportPercentageDropDown,
    selectSkillsDropDown,
} from 'features/AdminSettingsDropDown/dropDownSlice';
import { SelectionAutoComplete } from 'shared/components/_Autocomplete/SelectionAutocomplete';
import { selectActiveUser } from 'features/account/accountSlice';
import { FilterAccordion } from 'shared/components/_Accordion';
import {
    activateLoading,
    deactivateLoading,
} from 'features/global/loadingProgressSlice';
import { clearFilter } from 'helpers/clearFilter';
import { SystemRoleEnums } from 'helpers';

const BestFitFilter = ({ itemBestFitPayload, setItemBestFitPayload }) => {
    //#region Constants
    const classes = makeFormStyles();
    const dispatch = useDispatch();
    const { role } = useSelector(selectActiveUser);
    //#endregion Constants

    //#region Hooks
    //#endregion Hooks

    //#region State
    const initValues = {
        job_id: null,
        job_role_id: null,
        supervisor_employee_id: null,
        office_id: null,
        cost_center_id: null,
        skill_ids: null,
        percentage_match: null,
        command_id: null,
        depot_id: null,
    };
    const [accordionTitle, setAccordionTitle] = useState([]);
    //#endregion State

    //#region Selectors
    const user = useSelector(selectActiveUser);
    //#endregion Selectors

    //#region Refs
    const formRef = useRef();
    //#endregion Refs

    //#region Effects
    //#endregion Effects

    //#region Methods
    const setBestFitAccordionTitle = (
        jobId,
        jobRoleId,
        costCenterId,
        officeId,
        skillIds,
        percentageMatch,
        commandId,
        depotId
    ) => {
        setAccordionTitle({
            ...accordionTitle,
            job_id: { text: jobId?.text },
            job_role_id: { text: jobRoleId?.text },
            cost_center_id: { text: costCenterId?.text },
            skill_ids:
                skillIds?.length > 0
                    ? { text: map(skillIds, 'text') }
                    : { text: skillIds?.text },
            office_id: { text: officeId?.text },
            percentage_match: { text: percentageMatch?.text },
            command_id: { text: commandId?.text },
            depot_id: { text: depotId?.text },
        });
    };
    const removeBestFitAccordionTitle = (
        jobId,
        jobRoleId,
        costCenterId,
        officeId,
        skillIds,
        percentageMatch,
        commandId,
        depotId
    ) => {
        setAccordionTitle({
            ...accordionTitle,
            job_id: { text: jobId?.text },
            job_role_id: { text: jobRoleId?.text },
            cost_center_id: { text: costCenterId?.text },
            skill_ids:
                skillIds?.length > 0
                    ? { text: map(skillIds, 'text') }
                    : { text: skillIds?.text },
            office_id: { text: officeId?.text },
            percentage_match: { text: percentageMatch?.text },
            command_id: { text: commandId?.text },
            depot_id: { text: depotId?.text },
        });
    };

    const setDependantIds = async (value, setFieldValue, key) => {
        if (key === 'job_id' && value) {
            setFieldValue('job_id', value);
            setFieldValue('job_role_id', null);
        }
        if (key === 'job_role_id' && value) {
            setFieldValue('job_role_id', value);
        }
        if (key === 'cost_center_id' && value) {
            setFieldValue('cost_center_id', value);
            setFieldValue('office_id', null);
        }
        if (key === 'office_id' && value) {
            setFieldValue('office_id', value);
        }
        if (key === 'percentage_match' && value) {
            setFieldValue('percentage_match', value);
        }
        if (key === 'command_id' && value) {
            setFieldValue('command_id', value);
        }
        if (key === 'depot_id' && value) {
            setFieldValue('depot_id', value);
        }
    };

    const removeDependantIds = async (setFieldValue, key) => {
        dispatch(activateLoading());
        if (key === 'job_id') {
            setFieldValue('job_id', null);
        }
        if (key === 'job_role_id') {
            setFieldValue('job_role_id', null);
        }
        if (key === 'cost_center_id') {
            setFieldValue('cost_center_id', null);
        }
        if (key === 'office_id') {
            setFieldValue('office_id', null);
        }
        if (key === 'percentage_match') {
            setFieldValue('percentage_match', null);
        }
        if (key === 'command_id') {
            setFieldValue('command_id', null);
        }
        if (key === 'depot_id') {
            setFieldValue('depot_id', null);
        }
        dispatch(deactivateLoading());
    };

    const handleSubmit = async (values, setSubmitting, resetForm) => {
        setItemBestFitPayload({
            ...itemBestFitPayload,
            job_role_id: values?.job_role_id?.value,
            job_id: values?.job_id?.value,
            cost_center: values?.cost_center_id?.value,
            skillIds: values?.skill_ids?.map((skill) => skill.value),
            office_id: values?.office_id?.value,
            percentage_match: values?.percentage_match?.value,
            command_id: values?.command_id?.value,
            depot_id: values?.depot_id?.value,
            reset: true,
            start_index: 0,
            stop_index: 10,
            other_start_index: 0,
            other_stop_index: 10,
        });
        setSubmitting(false);
    };

    const handleResetFilters = () => {
        const isFilterData = clearFilter(formRef);
        if (isFilterData) {
            formRef?.current?.resetForm();
            setItemBestFitPayload({
                ...initValues,
            });
            setAccordionTitle({});
            dispatch(fetchJobRolesByJobId());
            dispatch(fetchOfficeByCostCenterId());
        }
    };
    //
    //#endregion Methods

    //#region Render time calcs
    //#endregion Render time calcs

    //#region Render

    return (
        <FilterAccordion
            filteredTitles={accordionTitle}
            expandedId="capability-filter"
            styles={{ marginBottom: '1.5rem' }}
            defaultExpanded={true}
            onResetFilters={handleResetFilters}
        >
            <Formik
                enableReinitialize={true}
                initialValues={{ ...initValues }}
                validationSchema={Yup.object({
                    job_role_id: Yup.object().required('Job Role is required'),
                })}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    handleSubmit(values, setSubmitting, resetForm);
                }}
                innerRef={formRef}
            >
                {({ isSubmitting, errors, values, setFieldValue }) => (
                    <Form className={classes.form} style={{ padding: '0' }}>
                        <Grid container spacing={'1rem'}>
                            {role === SystemRoleEnums.AMC && (
                                <>
                                    <Grid
                                        item={true}
                                        xs={12}
                                        md={6}
                                        lg={3}
                                        className={classes.customSelect}
                                    >
                                        <Field
                                            className={classes.input}
                                            name="command_id"
                                        >
                                            {({
                                                field: { value },
                                                form: { setFieldValue },
                                            }) => (
                                                <SelectionAutoComplete
                                                    title="Select Command"
                                                    keyProperty="value"
                                                    nameProperty="text"
                                                    entityIds={value?.value}
                                                    setEntityIds={setFieldValue}
                                                    onCustomChange={(value) => {
                                                        setDependantIds(
                                                            value,
                                                            setFieldValue,
                                                            'command_id'
                                                        );
                                                        if (
                                                            role ===
                                                            SystemRoleEnums.AMC
                                                        ) {
                                                            setFieldValue(
                                                                'depot_id',
                                                                null
                                                            );
                                                            dispatch(
                                                                resetDepotDropDownState()
                                                            );
                                                        }

                                                        setBestFitAccordionTitle(
                                                            value,
                                                            null,
                                                            values.job_id,
                                                            values.job_role_id,
                                                            values?.cost_center_id,
                                                            values?.office_id,
                                                            values?.skill_ids,
                                                            values?.percentage_match
                                                        );
                                                    }}
                                                    entitySelector={
                                                        selectCommondDropDown
                                                    }
                                                    entityStatusSelector={
                                                        getCommandDropDownStatus
                                                    }
                                                    fetchEntityPage={
                                                        fetchCommandDropDown
                                                    }
                                                    multiSelection={false}
                                                    showCloseItemEdit={true}
                                                    hideCheckIcon={true}
                                                    cancelItemTagEdit={() => {
                                                        removeDependantIds(
                                                            setFieldValue,
                                                            'command_id'
                                                        );
                                                        removeBestFitAccordionTitle(
                                                            null,
                                                            null,
                                                            values.job_id,
                                                            values.job_role_id,
                                                            values?.cost_center_id,
                                                            values?.office_id,
                                                            values?.skill_ids,
                                                            values?.percentage_match
                                                        );
                                                        if (
                                                            role ===
                                                            SystemRoleEnums.AMC
                                                        ) {
                                                            setFieldValue(
                                                                'depot_id',
                                                                null
                                                            );
                                                            dispatch(
                                                                resetDepotDropDownState()
                                                            );
                                                        }
                                                    }}
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid
                                        item={true}
                                        xs={12}
                                        md={6}
                                        lg={3}
                                        className={classes.customSelect}
                                    >
                                        <Field
                                            className={classes.input}
                                            name="depot_id"
                                        >
                                            {({
                                                field: { value },
                                                form: { setFieldValue },
                                            }) => (
                                                <SelectionAutoComplete
                                                    title="Select Depot"
                                                    keyProperty="value"
                                                    nameProperty="text"
                                                    entityIds={value?.value}
                                                    setEntityIds={setFieldValue}
                                                    onCustomChange={(value) => {
                                                        setDependantIds(
                                                            value,
                                                            setFieldValue,
                                                            'depot_id'
                                                        );
                                                        setBestFitAccordionTitle(
                                                            values?.command_id,
                                                            value,
                                                            values.job_id,
                                                            values.job_role_id,
                                                            values?.cost_center_id,
                                                            values?.office_id,
                                                            values?.skill_ids,
                                                            values?.percentage_match
                                                        );
                                                    }}
                                                    entitySelector={
                                                        selectDepotDropDown
                                                    }
                                                    entityStatusSelector={
                                                        getDepotDropDownStatus
                                                    }
                                                    fetchEntityPage={
                                                        fetchDepotDropDown
                                                    }
                                                    formField={'depot_id'}
                                                    multiSelection={false}
                                                    showCloseItemEdit={true}
                                                    hideCheckIcon={true}
                                                    cancelItemTagEdit={() => {
                                                        removeDependantIds(
                                                            setFieldValue,
                                                            'depot_id'
                                                        );
                                                        removeBestFitAccordionTitle(
                                                            values?.command_id,
                                                            null,
                                                            values.job_id,
                                                            values.job_role_id,
                                                            values?.cost_center_id,
                                                            values?.office_id,
                                                            values?.skill_ids,
                                                            values?.percentage_match
                                                        );
                                                    }}
                                                    fetchParams={{
                                                        command_id:
                                                            values?.command_id
                                                                ?.value,
                                                    }}
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                </>
                            )}
                            <Grid
                                item={true}
                                xs={12}
                                md={6}
                                lg={3}
                                className={classes.customSelect}
                            >
                                <Field name="job_id">
                                    {({
                                        field: { value },
                                        form: { setFieldValue },
                                    }) => (
                                        <SelectionAutoComplete
                                            title={`Select ${user.label_job}`}
                                            keyProperty="value"
                                            nameProperty="text"
                                            entityIds={value?.value}
                                            setEntityIds={setFieldValue}
                                            entitySelector={selectJobDropDown}
                                            entityStatusSelector={
                                                getJobDropDownStatus
                                            }
                                            onCustomChange={(value) => {
                                                setDependantIds(
                                                    value,
                                                    setFieldValue,
                                                    'job_id'
                                                );
                                                setFieldValue(
                                                    'job_role_id',
                                                    null
                                                );
                                                setBestFitAccordionTitle(
                                                    values?.command_id,
                                                    values?.depot_id,
                                                    value,
                                                    null,
                                                    values?.cost_center_id,
                                                    values?.skill_ids,
                                                    values?.percentage_match
                                                );
                                                dispatch(
                                                    resetJobRoleDropdown()
                                                );
                                            }}
                                            fetchEntityPage={fetchJobsDropDown}
                                            cancelItemTagEdit={() => {
                                                removeDependantIds(
                                                    setFieldValue,
                                                    'job_id'
                                                );
                                                setFieldValue(
                                                    'job_role_id',
                                                    null
                                                );
                                                removeBestFitAccordionTitle(
                                                    values?.command_id,
                                                    values?.depot_id,
                                                    null,
                                                    null,
                                                    values?.cost_center_id,
                                                    values?.skill_ids,
                                                    values?.percentage_match
                                                );
                                                dispatch(
                                                    resetJobRoleDropdown()
                                                );
                                            }}
                                            showCloseItemEdit={true}
                                            formField="job_id"
                                            multiSelection={false}
                                            hideCheckIcon={true}
                                        />
                                    )}
                                </Field>
                            </Grid>
                            <Grid
                                item={true}
                                xs={12}
                                md={6}
                                lg={3}
                                className={classes.customSelect}
                            >
                                <Field name="job_role_id">
                                    {({
                                        field: { value },
                                        form: { setFieldValue },
                                    }) => (
                                        <SelectionAutoComplete
                                            title={`Select ${user.label_job_role}`}
                                            keyProperty="value"
                                            nameProperty="text"
                                            entityIds={value?.value}
                                            setEntityIds={setFieldValue}
                                            entitySelector={
                                                selectJobRoleByJobIdDropDown
                                            }
                                            entityStatusSelector={
                                                getJobRolesByJobIdDropDownStatus
                                            }
                                            onCustomChange={(value) => {
                                                setDependantIds(
                                                    value,
                                                    setFieldValue,
                                                    'job_role_id'
                                                );
                                                setBestFitAccordionTitle(
                                                    values?.command_id,
                                                    values?.depot_id,
                                                    values.job_id,
                                                    value,
                                                    values?.cost_center_id,
                                                    values?.skill_ids,
                                                    values?.percentage_match
                                                );
                                            }}
                                            fetchEntityPage={
                                                fetchJobRolesByJobId
                                            }
                                            fetchParams={{
                                                job_id: values?.job_id?.value,
                                            }}
                                            cancelItemTagEdit={() => {
                                                removeDependantIds(
                                                    setFieldValue,
                                                    'job_role_id'
                                                );
                                                removeBestFitAccordionTitle(
                                                    values?.command_id,
                                                    values?.depot_id,
                                                    values.job_id,
                                                    null,
                                                    values?.cost_center_id,
                                                    values?.skill_ids,
                                                    values?.percentage_match
                                                );
                                            }}
                                            formField="job_role_id"
                                            showCloseItemEdit={true}
                                            multiSelection={false}
                                            hideCheckIcon={true}
                                        />
                                    )}
                                </Field>
                                {errors?.job_role_id && (
                                    <Typography
                                        variant="subtitle2"
                                        className={classes.inputErr}
                                    >
                                        {errors.job_role_id}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid
                                item={true}
                                xs={12}
                                md={6}
                                lg={3}
                                className={classes.customSelect}
                            >
                                <Field
                                    className={classes.input}
                                    name="cost_center_id"
                                >
                                    {({
                                        field: { value },
                                        form: { setFieldValue },
                                    }) => (
                                        <SelectionAutoComplete
                                            title="Select Cost Center"
                                            keyProperty="value"
                                            nameProperty="text"
                                            entityIds={value?.value}
                                            setEntityIds={setFieldValue}
                                            onCustomChange={(value) => {
                                                setBestFitAccordionTitle(
                                                    values?.command_id,
                                                    values?.depot_id,
                                                    values.job_id,
                                                    values?.job_role_id,
                                                    value,
                                                    values?.skill_ids,
                                                    values?.percentage_match
                                                );
                                                setDependantIds(
                                                    value,
                                                    setFieldValue,
                                                    'cost_center_id'
                                                );
                                                dispatch(
                                                    resetOfficeIdDropDown()
                                                );
                                            }}
                                            entitySelector={
                                                selectCostCenterDropDown
                                            }
                                            entityStatusSelector={
                                                getCostCenterDropDownStatus
                                            }
                                            fetchEntityPage={
                                                fetchCostCenterDropDown
                                            }
                                            formField={'cost_center_id'}
                                            multiSelection={false}
                                            showCloseItemEdit={true}
                                            hideCheckIcon={true}
                                            cancelItemTagEdit={() => {
                                                removeBestFitAccordionTitle(
                                                    values?.command_id,
                                                    values?.depot_id,
                                                    values.job_id,
                                                    values?.job_role_id,
                                                    null,
                                                    values?.skill_ids,
                                                    values?.percentage_match
                                                );
                                                removeDependantIds(
                                                    setFieldValue,
                                                    'cost_center_id'
                                                );
                                                dispatch(
                                                    resetOfficeIdDropDown()
                                                );
                                            }}
                                        />
                                    )}
                                </Field>
                            </Grid>
                            <Grid
                                item={true}
                                xs={12}
                                md={6}
                                lg={3}
                                className={classes.customSelect}
                            >
                                <Field name="office_id">
                                    {({
                                        field: { value },
                                        form: { setFieldValue },
                                    }) => (
                                        <SelectionAutoComplete
                                            title={`Select Office`}
                                            keyProperty="value"
                                            nameProperty="text"
                                            entityIds={value?.value}
                                            setEntityIds={setFieldValue}
                                            entitySelector={
                                                selectOfficeByCostCenterIdDropDown
                                            }
                                            entityStatusSelector={
                                                getOfficeByCostCenterIdDropDownStatus
                                            }
                                            onCustomChange={(value) => {
                                                setDependantIds(
                                                    value,
                                                    setFieldValue,
                                                    'office_id'
                                                );
                                                setBestFitAccordionTitle(
                                                    values?.command_id,
                                                    values?.depot_id,
                                                    values.job_id,
                                                    values.job_role_id,
                                                    values?.cost_center_id,
                                                    value,
                                                    values?.skill_ids,
                                                    values?.percentage_match
                                                );
                                            }}
                                            fetchEntityPage={
                                                fetchOfficeByCostCenterId
                                            }
                                            cancelItemTagEdit={() => {
                                                removeDependantIds(
                                                    setFieldValue,
                                                    'office_id'
                                                );
                                                removeBestFitAccordionTitle(
                                                    values?.command_id,
                                                    values?.depot_id,
                                                    values.job_id,
                                                    values.job_role_id,
                                                    values?.cost_center_id,
                                                    null,
                                                    values?.skill_ids,
                                                    values?.percentage_match
                                                );
                                            }}
                                            fetchParams={{
                                                cost_center_id:
                                                    values?.cost_center_id
                                                        ?.value,
                                            }}
                                            formField="office_id"
                                            showCloseItemEdit={true}
                                            multiSelection={false}
                                            hideCheckIcon={true}
                                        />
                                    )}
                                </Field>
                            </Grid>
                            <Grid
                                item={true}
                                xs={12}
                                md={6}
                                lg={3}
                                className={classes.customSelect}
                            >
                                <Field name="skill_ids">
                                    {({
                                        field: { value },
                                        form: { setFieldValue },
                                    }) => (
                                        <SelectionAutoComplete
                                            title={`Must Have ${user.label_skill}`}
                                            keyProperty="value"
                                            nameProperty="text"
                                            entityIds={map(value, 'value')}
                                            setEntityIds={setFieldValue}
                                            entitySelector={
                                                selectSkillsDropDown
                                            }
                                            entityStatusSelector={
                                                getSkillsDropDownStatus
                                            }
                                            onCustomChange={(value) => {
                                                setFieldValue(
                                                    'skill_ids',
                                                    value
                                                );
                                                setBestFitAccordionTitle(
                                                    values?.command_id,
                                                    values?.depot_id,
                                                    values.job_id,
                                                    values?.job_role_id,
                                                    values?.cost_center_id,
                                                    values?.office_id,
                                                    value,
                                                    values?.percentage_match
                                                );
                                            }}
                                            fetchEntityPage={
                                                fetchSkillsDropDown
                                            }
                                            formField="skill_ids"
                                            multiSelection={true}
                                            showCloseItemEdit={true}
                                            hideCheckIcon={true}
                                            cancelItemTagEdit={() => {
                                                setFieldValue(
                                                    'skill_ids',
                                                    null
                                                );
                                                removeBestFitAccordionTitle(
                                                    values?.command_id,
                                                    values?.depot_id,
                                                    values.job_id,
                                                    values?.job_role_id,
                                                    values?.cost_center_id,
                                                    values?.office_id,
                                                    null,
                                                    values?.percentage_match
                                                );
                                            }}
                                        />
                                    )}
                                </Field>
                            </Grid>
                            <Grid
                                item={true}
                                xs={12}
                                md={6}
                                lg={3}
                                className={classes.customSelect}
                            >
                                <Field name="percentage_match">
                                    {({
                                        field: { value },
                                        form: { setFieldValue },
                                    }) => (
                                        <SelectionAutoComplete
                                            title="Select Match Percentage"
                                            keyProperty="value"
                                            nameProperty="text"
                                            entityIds={value?.value}
                                            setEntityIds={setFieldValue}
                                            entitySelector={
                                                selectReportPercentageDropDown
                                            }
                                            entityStatusSelector={
                                                getReportPercentageDropDownStatus
                                            }
                                            onCustomChange={(value) => {
                                                setDependantIds(
                                                    value,
                                                    setFieldValue,
                                                    'percentage_match'
                                                );
                                                setBestFitAccordionTitle(
                                                    values?.command_id,
                                                    values?.depot_id,
                                                    values.job_id,
                                                    values.job_role_id,
                                                    values?.cost_center_id,
                                                    values?.office_id,
                                                    values?.skill_ids,
                                                    value
                                                );
                                            }}
                                            fetchEntityPage={
                                                fetchPercentageDropDown
                                            }
                                            cancelItemTagEdit={() => {
                                                removeDependantIds(
                                                    setFieldValue,
                                                    'percentage_match'
                                                );
                                                removeBestFitAccordionTitle(
                                                    values?.command_id,
                                                    values?.depot_id,
                                                    values.job_id,
                                                    values.job_role_id,
                                                    values?.cost_center_id,
                                                    values?.office_id,
                                                    values?.skill_ids,
                                                    null
                                                );
                                            }}
                                            showCloseItemEdit={true}
                                            formField="percentage_match"
                                            multiSelection={false}
                                            hideCheckIcon={true}
                                        />
                                    )}
                                </Field>
                            </Grid>
                            <Grid item={true} xs={12}>
                                <Button
                                    size="large"
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    Search
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </FilterAccordion>
    );
    //#endregion Render
};
export { BestFitFilter };
