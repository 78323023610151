import { useEffect, useRef, useState } from 'react';
import { CostCenterTreeMap } from 'components/CostCenterTreeMap';
import { useDispatch, useSelector } from 'react-redux';
import {
  activateLoading,
  deactivateLoading,
} from 'features/global/loadingProgressSlice';
import {
  fetchCostCenterReport,
  getCostCenterReportStatus,
  resetCostCenterReportState,
} from 'features/CostCenterReports/costCenterReportSlice';
import { resetDropDownState } from 'features/AdminSettingsDropDown/dropDownSlice';
import { LoadingStatus } from 'helpers';
import { useParams } from 'react-router-dom';

const CostCenterReports = () => {
  //#region Constants
  const dispatch = useDispatch();
  const { id } = useParams();
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  const [itemCostCenterReportPayload, setItemCostCenterReportPayload] =
    useState({
      depot_id: id ?? null,
      cost_center_id: null,
      office_id: null,
      job_id: null,
      job_role_id: null,
      custom_expiry_date: null,
      supervisor_employee_id: null,
      capability_id: null,
      report_expiry: null,
    });

  const getReportData = async (payload) => {
    dispatch(activateLoading());
    await dispatch(fetchCostCenterReport({ ...payload }));
    dispatch(deactivateLoading());
  };
  //#endregion State

  //#region Selectors
  const itemCostCenterReportStatus = useSelector(getCostCenterReportStatus);
  //#endregion Selectors

  //#region Refs
  const ref = useRef(false);
  //#endregion Refs

  //#region Effects
  useEffect(() => {
    if (ref.current === true) {
      getReportData({ ...itemCostCenterReportPayload });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemCostCenterReportPayload]);

  useEffect(() => {
    return async () => {
      await dispatch(resetCostCenterReportState());
      await dispatch(resetDropDownState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (itemCostCenterReportStatus === LoadingStatus.Idle) {
      getReportData({
        ...itemCostCenterReportPayload,
      });
    }
    ref.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemCostCenterReportStatus, dispatch]);
  //#endregion Effects

  //#region Methods
  //#endregion Methods

  //#region Render time calcs
  //#endregion Render time calcs

  //#region Render

  return (
    <CostCenterTreeMap
      itemCostCenterReportPayload={itemCostCenterReportPayload}
      setItemCostCenterReportPayload={setItemCostCenterReportPayload}
    />
  );
  //#endregion Render
};
export { CostCenterReports };
