import React, { useState } from 'react';
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableBody,
  IconButton,
  Box,
  Typography,
} from '@mui/material';

import { makeFormStyles } from 'forms/style';
import { EmployeeJobTable } from './EmployeeJobTable';
import { NoDataMessage } from 'shared/components/_Tables/NoDataMessage';
import { MdAdd } from 'react-icons/md';
import { fetchJobRolesByJobId } from 'features/AdminSettingsDropDown/dropDownSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveUser } from 'features/account/accountSlice';
import { NO_JOB_HISTORY_FOUND } from 'helpers';
import { Each } from 'helpers/Each';
import { formTableStyle } from 'forms/ItemEmployeeForm/CapabilityForm/style';

const EmployeeJobForm = ({
  formAction,
  formInfo,
  setInitValues,
  initValues,
  values,
  errors,
  validateForm,
  setFieldValue,
  isReadOnly,
}) => {
  //#region Constants
  const dispatch = useDispatch();
  const classes = makeFormStyles();
  const formTableClass = formTableStyle();
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  const [warnings, setWarnings] = useState({
    missingCurrentJobRole: false,
    nonCurrentJobWithoutEndDate: false,
    noJobRoleFoundForEmployee: false,
  });
  const [filteredJobRole, setFilteredJobRole] = useState({});

  //#endregion State

  //#region Selectors
  const activeUser = useSelector(selectActiveUser);
  //#endregion Selectors

  //#region Refs
  //#endregion Refs

  //#region Effects
  //#endregion Effects

  //#region Methods
  const addNewRow = async () => {
    const newJobHistoryRow = {
      is_employee_current_role: false,
      series_id: null,
      role_name: '',
      start_date: null,
      end_date: null,
      is_active: true,
      job_id: null,
      job_role_id: null,
      newAdded: true,
    };
    const checkValueData = Object.values(values?.job_history);
    let resultAction = await dispatch(fetchJobRolesByJobId());
    let jobRole = resultAction?.payload?.dropDowns.map((item) => {
      return { value: +item.value, text: item.text };
    });
    setFilteredJobRole((prev) => ({
      ...prev,
      [checkValueData.length]: jobRole,
    }));
    setInitValues({
      ...values,
      job_history: [...checkValueData, newJobHistoryRow],
    });
  };
  //#endregion Methods
  //#region Render
  return (
    <Box
      sx={{
        padding: '1rem 0',
      }}
    >
      <Box
        className={formTableClass.cardWrapper}
        sx={{
          overflow: 'auto',
          maxHeight: '645px',
        }}
      >
        <Box className={formTableClass.cardHeader}>
          <Typography>{activeUser?.label_job_role ?? 'JobRole'}</Typography>
        </Box>
        <TableContainer sx={{ boxShadow: 'none' }} component={Paper}>
          <Table>
            <TableHead
              sx={{
                position: 'sticky',
                top: '0',
                zIndex: '1',
              }}
            >
              <TableRow>
                <TableCell width={40}>Current?</TableCell>
                <TableCell width={80}>Series</TableCell>
                <TableCell width={80}>{`${
                  activeUser?.label_job_role ?? 'Role'
                }`}</TableCell>
                <TableCell width={380} colSpan={2}>
                  Dates
                </TableCell>
                <TableCell width={60}>
                  {!isReadOnly && (
                    <IconButton onClick={addNewRow}>
                      <MdAdd />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.values(values.job_history).length > 0 ? (
                <Each
                  of={Object.values(values.job_history)}
                  render={(item, index) => (
                    <EmployeeJobTable
                      index={index}
                      setFieldValue={setFieldValue}
                      classes={classes}
                      values={values}
                      errors={errors}
                      rowClass={classes}
                      checkValidate={validateForm}
                      setWarnings={setWarnings}
                      warnings={warnings}
                      initValues={initValues}
                      setInitValues={setInitValues}
                      filteredJobRole={filteredJobRole}
                      setFilteredJobRole={setFilteredJobRole}
                      isReadOnly={isReadOnly}
                    />
                  )}
                />
              ) : (
                <TableRow>
                  <TableCell colSpan="5">
                    <NoDataMessage
                      message={NO_JOB_HISTORY_FOUND}
                      setHeight="300px"
                    />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
  //#endregion Render
};

export { EmployeeJobForm };
