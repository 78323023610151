import { createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const navStyles = makeStyles((theme) =>
  createStyles({
    navIcon: {
      color: theme.palette.secondary.main,
      fontSize: '40px',
      width: '40px',
    },
  })
);

export { navStyles };
