import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { costCenterService } from 'features/services/costCenter.service';
import { LoadingStatus, SavingStatus } from 'helpers';

const initialState = {
  status: LoadingStatus.Idle,
  error: null,
  costCenters: [],
  costCenterStatus: LoadingStatus.Idle,
  costCenterPageInfo: {},
  displayCostCenter: {},
  costCenterUsage: [],
  loadedCostCenterMap: {},
  costCenterImportInfo: [],
  costCenterImportInfoStatus: LoadingStatus.Idle,
};

export const fetchCostCenter = createAsyncThunk(
  'costCenters/fetchCostCenter',
  async (params) =>
    await costCenterService.getCostCenters(
      params.startIndex,
      params.stopIndex,
      params.searchString,
      params.sort,
      params.cc_id,
      params.psntl,
      params.cost_center_id,
      params.office_id
    )
);

export const fetchCostCenterById = createAsyncThunk(
  'costCenters/fetchCostCenterById',
  async (costCenterId) => {
    const response = await costCenterService.getById(costCenterId);
    response.id = costCenterId;
    return response;
  }
);

export const fetchCostCenterUsageById = createAsyncThunk(
  'costCenter/fetchCostCenterUsageById',
  async (costCenterId) =>
    await costCenterService.getCostCenterUsageById(costCenterId)
);

export const createCostCenter = createAsyncThunk(
  'costCenters/createCostCenter',
  async (params) => await costCenterService.create(params)
);

export const updateCostCenter = createAsyncThunk(
  'costCenters/updateCostCenter',
  async (params) => await costCenterService.update(params)
);

export const deleteCostCenter = createAsyncThunk(
  'costCenters/deleteCostCenter',
  async (cost_center_id) => {
    const response = await costCenterService.delete(cost_center_id);
    return response;
  }
);

export const deleteCostCenterArray = createAsyncThunk(
  'costCenters/deleteCostCenterArray',
  async (costCenterId) => {
    const response = await costCenterService.deleteArray(costCenterId);
    return response;
  }
);

export const getCostCenterImportSampleCsv = createAsyncThunk(
  'costCenters/getCostCenterImportSampleCsv',
  async () => await costCenterService.getCostCenterImportCsv()
);

export const uploadCostCenterImportCsv = createAsyncThunk(
  'costCenters/uploadCostCenterImportCsv',
  async (params) => await costCenterService.uploadCostCenterImportCsv(params)
);

export const exportCostCenterCsv = createAsyncThunk(
  'costCenters/exportCostCenterCsv',
  async (params) =>
    await costCenterService.exportCostCenterCsv(params.cc_id, params.psntl)
);

export const getImportCostCenterHelp = createAsyncThunk(
  'costCenters/getImportCostCenterHelp',
  async (params) => await costCenterService.getCostCenterImportHelp(params)
);

const costCenterSlice = createSlice({
  name: 'costCenters',
  initialState,
  reducers: {
    resetCostCenterState: (state) => initialState,
    setCostCenterStatus: (state, action) => {
      state.costCenterStatus = action.payload;
    },
    resetCostCenterInfoState: (state) => {
      state.costCenterImportInfoStatus = LoadingStatus.Idle;
    },
  },
  extraReducers: {
    [fetchCostCenter.pending]: (state, action) => {
      state.costCenterStatus = LoadingStatus.Loading;
    },
    [fetchCostCenter.fulfilled]: (state, action) => {
      state.costCenterPageInfo = action.payload.pageInfo;
      state.costCenterStatus = LoadingStatus.Loaded;

      if (action.meta.arg.reset) {
        state.loadedCostCenterMap = {};
      }

      const startIndex = action.meta.arg.startIndex;
      const stopIndex = startIndex + action.payload?.costCenters.length - 1;

      for (var i = startIndex; i <= stopIndex; i++) {
        state.loadedCostCenterMap[i] = LoadingStatus.Loaded;
      }

      // In case we didn't load as many as requested
      for (
        let j = stopIndex + 1;
        j <= action.payload?.pageInfo?.TotalCount;
        j++
      ) {
        delete state.loadedCostCenterMap[j];
      }

      if (action.meta.arg.reset) {
        state.costCenters = action.payload.costCenters;
      } else {
        state.costCenters = state.costCenters.concat(
          action.payload.costCenters
        );
      }
    },
    [fetchCostCenter.rejected]: (state, action) => {
      state.costCenterStatus = LoadingStatus.Failed;
    },
    [createCostCenter.fulfilled]: (state, action) => {
      state.costCenterStatus = LoadingStatus.Idle;
    },
    [updateCostCenter.fulfilled]: (state, action) => {
      state.costCenterStatus = LoadingStatus.Idle;
    },
    [deleteCostCenter.fulfilled]: (state, action) => {
      state.costCenterStatus = LoadingStatus.Loaded;
    },
    [deleteCostCenterArray.fulfilled]: (state, action) => {
      state.costCenterStatus = LoadingStatus.Loaded;
    },
    [deleteCostCenterArray.rejected]: (state, action) => {
      state.status = SavingStatus.Failed;
      state.error = action.error.message;
    },
    [fetchCostCenterById.fulfilled]: (state, action) => {
      state.displayCostCenter = action.payload;
    },
    [fetchCostCenterUsageById.fulfilled]: (state, action) => {
      state.costCenterUsage = action.payload;
    },
    [getCostCenterImportSampleCsv.fulfilled]: (state, action) => {
      if (action?.payload) {
        const url = window.URL.createObjectURL(new Blob([action.payload]));
        const link = document.createElement('a');
        link.href = url;
        let downloadName =
          action.payload.headers?.filename ?? 'cost_center_import.csv';
        link.setAttribute('download', downloadName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    },
    [getCostCenterImportSampleCsv.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    [exportCostCenterCsv.fulfilled]: (state, action) => {
      if (action?.payload) {
        const url = window.URL.createObjectURL(new Blob([action.payload]));
        const link = document.createElement('a');
        link.href = url;
        let downloadName =
          action.payload.headers?.filename ?? 'cost_center_export.csv';
        link.setAttribute('download', downloadName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    },
    [exportCostCenterCsv.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    [getImportCostCenterHelp.pending]: (state, action) => {
      state.costCenterImportInfoStatus = LoadingStatus.Loading;
    },
    [getImportCostCenterHelp.fulfilled]: (state, action) => {
      state.costCenterImportInfo = action.payload;
      state.costCenterImportInfoStatus = LoadingStatus.Loaded;
    },
    [getImportCostCenterHelp.rejected]: (state, action) => {
      state.costCenterImportInfoStatus = LoadingStatus.Failed;
    },
  },
});

export const selectCostCenter = (state) => state.costCenters.costCenters;
export const getCostCenterStatus = (state) =>
  state.costCenters.costCenterStatus;
export const getCostCenterPageInfo = (state) =>
  state.costCenters.costCenterPageInfo;
export const getCostCenterUsage = (state) => state.costCenters.costCenterUsage;
export const getCostCenterInfoStatus = (state) =>
  state.costCenters.costCenterImportInfoStatus;

export const {
  resetCostCenterState,
  setCostCenterStatus,
  resetCostCenterInfoState,
} = costCenterSlice.actions;

export default costCenterSlice.reducer;
