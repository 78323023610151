import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { jobRoleService } from 'features/services/JobRole.service';
import { LoadingStatus, SavingStatus } from 'helpers';

const initialState = {
  status: LoadingStatus.Idle,
  error: null,
  jobRoles: [],
  jobRoleStatus: LoadingStatus.Idle,
  jobRolePageInfo: {},
  displayJobRoles: {},
  jobRoleUsage: [],
  loadedJobRolesMap: {},
  jobRoleImportInfo: [],
  jobRoleImportInfoStatus: LoadingStatus.Idle,
  jobRoleCapSkillImportInfo: [],
  jobRoleCapSkillImportInfoStatus: LoadingStatus.Idle,
  associatedCapabilities: [],
  associatedCapabilitiesStatus: LoadingStatus.Idle,
};

export const fetchJobRoles = createAsyncThunk(
  'jobRoles/fetchJobRoles',
  async (params = {}) => {
    return await jobRoleService.getJobRoles(
      params.startIndex,
      params.stopIndex,
      params.searchString,
      params.sort,
      params.display_name,
      params.job_id,
      params.job_series_id,
      params.capability_ids
    );
  }
);

export const fetchJobRolesById = createAsyncThunk(
  'jobRoles/fetchJobRolesById',
  async (jobRoleId) => {
    const response = await jobRoleService.getById(jobRoleId);
    response.id = jobRoleId;
    return response;
  }
);

export const fetchJobRolesUsageById = createAsyncThunk(
  'groups/fetchJobRolesUsageById',
  async (jobRoleId) => await jobRoleService.getJobRoleUsageById(jobRoleId)
);

export const createJobRole = createAsyncThunk(
  'jobRoles/createJobRole',
  async (params) => await jobRoleService.create(params)
);

export const updateJobRole = createAsyncThunk(
  'jobRoles/updateJobRole',
  async (params) => await jobRoleService.update(params)
);

export const deleteJobRole = createAsyncThunk(
  'jobRoles/deleteJobRole',
  async (jobRoleId) => {
    const response = await jobRoleService.delete(jobRoleId);
    return response;
  }
);

export const deleteJobRolesArray = createAsyncThunk(
  'assets/deleteJobRolesArray',
  async (jobRoleIds) => {
    const response = await jobRoleService.deleteJobRolesArray(jobRoleIds);
    return response;
  }
);

export const getJobRoleImportSampleCsv = createAsyncThunk(
  'jobRoles/getJobRoleImportSampleCsv',
  async () => await jobRoleService.getJobRoleImportCsv()
);

export const uploadJobRoleImportCsv = createAsyncThunk(
  'jobRoles/uploadJobRoleImportCsv',
  async (params) => await jobRoleService.uploadJobRoleImportCsv(params)
);

export const exportJobRoleCsv = createAsyncThunk(
  'jobRoles/exportJobRoleCsv',
  async (params) =>
    await jobRoleService.exportJobRolesCsv(
      params.display_name,
      params.job_id,
      params.job_series_id,
      params.capability_ids
    )
);

export const getImportJobRoleHelp = createAsyncThunk(
  'jobs/getImportJobRoleHelp',
  async (params) => await jobRoleService.getJobRoleImportHelp(params)
);

export const getImportCapSkillHelp = createAsyncThunk(
  'jobs/getImportCapSkillHelp',
  async (params) => await jobRoleService.getJobRoleCapSkillImportHelp(params)
);

export const fetchAssociateCapability = createAsyncThunk(
  'JobRole/fetchAssociateCapability',
  async (capability_id = null) =>
    await jobRoleService.getAssociateCapability(capability_id)
);

export const updateAssociateCapability = createAsyncThunk(
  'JobRole/updateAssociateCapability',
  async (params) => await jobRoleService.updateJobRoleCapability(params)
);

const jobRolesSlice = createSlice({
  name: 'jobRoles',
  initialState,
  reducers: {
    resetJobRoleState: (state) => initialState,
    resetJobRoleInfoState: (state) => {
      state.jobRoleImportInfoStatus = LoadingStatus.Idle;
    },
    resetJobRoleCapSkillInfoState: (state) => {
      state.jobRoleCapSkillImportInfoStatus = LoadingStatus.Idle;
    },
    resetAssociateCapability: (state) => {
      state.associatedCapabilitiesStatus = LoadingStatus.Idle;
    },
  },
  extraReducers: {
    [fetchJobRoles.pending]: (state, action) => {
      state.jobRoleStatus = LoadingStatus.Loading;
    },
    [fetchJobRoles.fulfilled]: (state, action) => {
      state.jobRolePageInfo = action.payload.pageInfo;
      state.jobRoleStatus = LoadingStatus.Loaded;

      if (action.meta.arg.reset) {
        state.loadedJobRolesMap = {};
      }

      const startIndex = action.meta.arg.startIndex;
      const stopIndex = startIndex + action.payload.jobRoles.length - 1;

      for (var i = startIndex; i <= stopIndex; i++) {
        state.loadedJobRolesMap[i] = LoadingStatus.Loaded;
      }

      // In case we didn't load as many as requested
      for (
        let j = stopIndex + 1;
        j <= action.payload?.pageInfo?.TotalCount;
        j++
      ) {
        delete state.loadedJobRolesMap[j];
      }

      if (action.meta.arg.reset) {
        state.jobRoles = action.payload.jobRoles;
      } else {
        state.jobRoles = state.jobRoles.concat(action.payload.jobRoles);
      }
    },
    [fetchJobRoles.rejected]: (state, action) => {
      state.jobRoleStatus = LoadingStatus.Failed;
    },
    [createJobRole.fulfilled]: (state, action) => {
      state.jobRoleStatus = LoadingStatus.Idle;
    },
    [updateJobRole.fulfilled]: (state, action) => {
      state.jobRoleStatus = LoadingStatus.Idle;
    },
    [deleteJobRole.fulfilled]: (state, action) => {
      state.jobRoleStatus = LoadingStatus.Idle;
    },
    [deleteJobRolesArray.fulfilled]: (state, action) => {
      state.jobRoleStatus = LoadingStatus.Idle;
    },
    [deleteJobRolesArray.rejected]: (state, action) => {
      state.status = SavingStatus.Failed;
      state.error = action.error.message;
    },
    [fetchJobRolesById.fulfilled]: (state, action) => {
      state.displayJobRoles = action.payload;
    },
    [fetchJobRolesUsageById.fulfilled]: (state, action) => {
      state.jobRoleUsage = action.payload;
    },
    [getJobRoleImportSampleCsv.fulfilled]: (state, action) => {
      if (action?.payload) {
        const url = window.URL.createObjectURL(new Blob([action.payload]));
        const link = document.createElement('a');
        link.href = url;
        let downloadName =
          action.payload.headers?.filename ?? 'job_role_import.csv';
        link.setAttribute('download', downloadName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    },
    [getJobRoleImportSampleCsv.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    [exportJobRoleCsv.fulfilled]: (state, action) => {
      if (action?.payload) {
        const url = window.URL.createObjectURL(new Blob([action.payload]));
        const link = document.createElement('a');
        link.href = url;
        let downloadName =
          action.payload.headers?.filename ?? 'job_role_export.csv';
        link.setAttribute('download', downloadName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    },
    [exportJobRoleCsv.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    [getImportJobRoleHelp.pending]: (state, action) => {
      state.jobRoleImportInfoStatus = LoadingStatus.Loading;
    },
    [getImportJobRoleHelp.fulfilled]: (state, action) => {
      state.jobRoleImportInfo = action.payload;
      state.jobRoleImportInfoStatus = LoadingStatus.Loaded;
    },
    [getImportJobRoleHelp.rejected]: (state, action) => {
      state.jobRoleImportInfoStatus = LoadingStatus.Failed;
    },
    [getImportCapSkillHelp.pending]: (state, action) => {
      state.jobRoleCapSkillImportInfo = LoadingStatus.Loading;
    },
    [getImportCapSkillHelp.fulfilled]: (state, action) => {
      state.jobRoleCapSkillImportInfo = action.payload;
      state.jobRoleCapSkillImportInfo = LoadingStatus.Loaded;
    },
    [getImportCapSkillHelp.rejected]: (state, action) => {
      state.jobRoleCapSkillImportInfo = LoadingStatus.Failed;
    },
    [fetchAssociateCapability.pending]: (state, action) => {
      state.associatedCapabilitiesStatus = LoadingStatus.Loading;
    },
    [fetchAssociateCapability.fulfilled]: (state, action) => {
      state.associatedCapabilitiesStatus = LoadingStatus.Loaded;
      state.associatedCapabilities = action.payload;
    },
    [fetchAssociateCapability.rejected]: (state, action) => {
      state.associatedCapabilitiesStatus = LoadingStatus.Failed;
    },
    [updateAssociateCapability.fulfilled]: (state, action) => {
      state.associatedCapabilitiesStatus = LoadingStatus.Idle;
    },
  },
});

export const selectJobRole = (state) => state.jobRoles.jobRoles;
export const getJobRoleStatus = (state) => state.jobRoles.jobRoleStatus;
export const getJobRolePageInfo = (state) => state.jobRoles.jobRolePageInfo;
export const getJobRoleUsage = (state) => state.jobRoles.jobRoleUsage;
export const getJobRoleById = (state) => state.jobRoles.displayJobRoles;
export const getJobRoleInfoStatus = (state) =>
  state.jobRoles.jobRoleImportInfoStatus;
export const getJobRoleCapSkillInfoStatus = (state) =>
  state.jobRoles.jobRoleCapSkillImportInfoStatus;

export const selectAssociateCapability = (state) =>
  state.jobRoles.associatedCapabilities;
export const getAssociateCapabilityStatus = (state) =>
  state.jobRoles.associatedCapabilitiesStatus;

export const {
  resetJobRoleState,
  resetJobRoleInfoState,
  resetJobRoleCapSkillInfoState,
  resetAssociateCapability,
} = jobRolesSlice.actions;

export default jobRolesSlice.reducer;
