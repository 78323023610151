import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from '@mui/material';
import { MdExpandMore } from 'react-icons/md';
import { useDispatch } from 'react-redux';

import { exportHierarchy } from 'features/exportSlice/exportSlice';
import { generateDepotReports } from 'features/adminDepot/adminDepotSlice';
import {
  activateLoading,
  deactivateLoading,
} from 'features/global/loadingProgressSlice';

function AdvancedFeaturesAccordion({ onClick }) {
  const dispatch = useDispatch();
  const handleExport = async () => {
    await dispatch(activateLoading());
    await dispatch(exportHierarchy());
    dispatch(deactivateLoading());
  };

  const handleReportGeneration = async () => {
    await dispatch(activateLoading());
    await dispatch(generateDepotReports());
    dispatch(deactivateLoading());
  };
  return (
    <Accordion>
      <AccordionSummary expandIcon={<MdExpandMore />}>
        Advanced Features
      </AccordionSummary>
      <AccordionDetails>
        <Button
          onClick={handleExport}
          variant="contained"
          style={{ marginRight: '15px' }}
        >
          Export Hierarchy
        </Button>
        <Button onClick={handleReportGeneration} variant="contained">
          Aggregate Employee Reports
        </Button>
      </AccordionDetails>
    </Accordion>
  );
}

export default AdvancedFeaturesAccordion;
