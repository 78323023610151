import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  activateLoading,
  deactivateLoading,
} from 'features/global/loadingProgressSlice';
import { EXTERNAL_API_CACHE, LoadingStatus } from 'helpers';
import { closePanel } from 'features/common/formSlice';

import { ItemExternalApiTable } from './ItemExternalApiTable';
import {
  fetchApiClient,
  getApiClientPageInfo,
  getApiClientStatus,
  selectApiClient,
} from 'features/ApiClientSlice/ApiClientSlice';
import {
  selectAdminSettingCache,
  updateAdminSettingCache,
} from 'features/global/visibilitySlice';

const ExternalApiManagementTab = () => {
  //#region Constants
  const dispatch = useDispatch();
  const getCacheData = useSelector(selectAdminSettingCache);
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  const [itemApiClientsPayload, setItemApiClientsPayload] = useState({
    ...getCacheData?.[EXTERNAL_API_CACHE],
    reset: true,
    startIndex: 0,
    stopIndex: 19,
  });
  const [sortBy, setSortBy] = useState(
    getCacheData?.[EXTERNAL_API_CACHE]?.sort?.slice(0, 1) === '-'
      ? getCacheData?.[EXTERNAL_API_CACHE]?.sort.slice(1)
      : getCacheData?.[EXTERNAL_API_CACHE]?.sort || 'display_name'
  );

  const [sortDirection, setSortDirection] = useState(
    getCacheData?.[EXTERNAL_API_CACHE]?.sort?.[0] !== '-' ? 'ASC' : 'DESC'
  );
  //#endregion State

  //#region Selectors
  const apiClients = useSelector(selectApiClient);
  const itemApiClientStatus = useSelector(getApiClientStatus);
  const itemApiClientPageInfo = useSelector(getApiClientPageInfo);
  //#endregion Selectors

  //#region Refs
  const ref = useRef(false);
  //#endregion Refs

  //#region Effects
  useEffect(() => {
    if (ref.current === true) {
      getApiClientsData({ ...itemApiClientsPayload });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemApiClientsPayload]);

  useEffect(() => {
    if (itemApiClientStatus === LoadingStatus.Idle) {
      getApiClientsData({
        ...itemApiClientsPayload,
        startIndex: 0,
        stopIndex: 19,
      });
    }
    ref.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemApiClientStatus, dispatch]);
  //#endregion Effects

  //#region Methods
  const getApiClientsData = async (payload) => {
    dispatch(activateLoading());
    await dispatch(fetchApiClient({ ...payload }));
    delete payload.searchString;
    dispatch(updateAdminSettingCache({ key: EXTERNAL_API_CACHE, payload }));
    dispatch(closePanel({ formKey: `itemExternalApiForm` }));
    dispatch(deactivateLoading());
  };

  const setSorting = (_sortBy, _sortDirection) => {
    setSortBy(_sortBy);
    setSortDirection(_sortDirection);
    setItemApiClientsPayload({
      ...itemApiClientsPayload,
      sort: `${_sortDirection === 'ASC' ? '' : '-'}${_sortBy}`,
    });
  };
  //#endregion Methods

  //#region Render time calcs
  //#endregion Render time calcs

  //#region Render

  return (
    <>
      <ItemExternalApiTable
        apiClients={apiClients}
        pageInfo={itemApiClientPageInfo}
        itemApiClientsPayload={itemApiClientsPayload}
        setItemApiClientsPayload={setItemApiClientsPayload}
        sortDirection={sortDirection}
        sortBy={sortBy}
        handleSort={(_sortBy, _sortDirection) =>
          setSorting(_sortBy, _sortDirection)
        }
      />
    </>
  );
  //#endregion Render
};
export { ExternalApiManagementTab };
