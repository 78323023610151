import { tableCellClasses } from '@mui/material/TableCell';
import { collapsedTableStyle } from './style';
import { mdTheme } from 'theme';
import { NoDataMessage } from 'shared/components/_Tables/NoDataMessage';
import { useSelector } from 'react-redux';
import { selectActiveUser } from 'features/account/accountSlice';
import DynamicTable from 'shared/components/_Tables/DynamicTable';
import { Badge, Box } from '@mui/material';
import clsx from 'clsx';
import moment from 'moment';
import { MdCheckBoxOutlineBlank } from 'react-icons/md';
import { NO_MATCHING_REPORTS } from 'helpers';
import { useState } from 'react';
import { fetchReports, getReportPageInfo } from 'features/Reports/reportSlice';

const ReportsTable = ({ reportsData, reportTableData, setReportTableData }) => {
  //#region Constants
  const classes = collapsedTableStyle(mdTheme);
  const collapseClass = classes;
  const theme = mdTheme;
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  const [loadingMore, setLoadingMore] = useState(false);
  //#endregion State

  //#region Selectors
  const activeUser = useSelector(selectActiveUser);
  //#endregion Selectors

  //#region Refs
  //#endregion Refs

  //#region Effects
  //#endregion Effects

  //#region Methods

  const columns = [
    {
      id: 'full_name',
      label: 'Name',
      width: '300px',
      minWidth: '200px',
    },
    {
      id: 'job',
      label: activeUser?.label_job,
      width: '300px',
      minWidth: '200px',
      align: 'center',
    },
    {
      id: 'job_role',
      label: activeUser?.label_job_role,
      width: '300px',
      minWidth: '200px',
      align: 'center',
    },
    {
      id: 'supervisor',
      label: 'Supervisor',
      width: '300px',
      minWidth: '200px',
      align: 'center',
    },
    {
      id: 'cost_center',
      label: 'Cost Center',
      width: '300px',
      minWidth: '200px',
      align: 'center',
    },
    {
      id: 'expired_count',
      label: 'Expiration',
      width: '300px',
      minWidth: '200px',
      align: 'center',
      customRender: (item) => {
        return (
          <Box sx={{ margin: '0 auto' }}>
            {item?.expired_count ? (
              <Badge
                className={clsx(
                  collapseClass.badgeWrapper,
                  collapseClass.error
                )}
                badgeContent={item?.expired_count}
              />
            ) : (
              ''
            )}
            {item?.warning_count ? (
              <Badge
                className={clsx(
                  collapseClass.badgeWrapper,
                  collapseClass.warning
                )}
                badgeContent={item?.warning_count}
              />
            ) : (
              ''
            )}
            {item?.missing_count ? (
              <Badge
                className={clsx(
                  collapseClass.badgeWrapper,
                  collapseClass.default
                )}
                badgeContent={item?.missing_count}
              />
            ) : (
              ''
            )}
          </Box>
        );
      },
      accordion: true,
    },
  ];

  const nestedColumns = [
    {
      id: 'skill',
      label: activeUser?.label_skill,
      width: '225px',
      minWidth: '225px',
      customRender: (item) => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {item?.is_expired && (
              <MdCheckBoxOutlineBlank
                style={{
                  fill: theme.colors.error,
                  backgroundColor: theme.colors.error,
                  marginRight: '0.5rem',
                  height: '0.8rem',
                }}
              />
            )}
            {item?.is_warning && (
              <MdCheckBoxOutlineBlank
                style={{
                  fill: theme.colors.warning,
                  backgroundColor: theme.colors.warning,
                  marginRight: '0.5rem',
                  height: '0.8rem',
                }}
              />
            )}{' '}
            {item?.is_missing ? (
              <MdCheckBoxOutlineBlank
                style={{
                  fill: '#5a5a5a',
                  backgroundColor: '#5a5a5a',
                  marginRight: '0.5rem',
                  height: '0.8rem',
                }}
              />
            ) : (
              ''
            )}
            {item.skill}
          </Box>
        );
      },
    },
    { id: 'capability', label: activeUser?.label_capability },
    {
      id: 'level',
      label: 'Level',
      width: '300px',
      minWidth: '200px',
    },
    {
      id: 'expiration',
      label: 'Expiry',
      width: '300px',
      minWidth: '200px',
      customRender: (item) => {
        return item?.is_missing
          ? 'Untrained'
          : item?.expiration
          ? moment(item?.expiration).format('YYYY-MM-DD')
          : 'Never';
      },
    },
  ];

  //#region Render time calcs
  //#endregion Render time calcs

  //#region Render

  return (
    <>
      {reportsData?.length > 0 ? (
        <Box sx={{ marginTop: '2rem' }}>
          <DynamicTable
            tableId={'generate-report-table'}
            data={reportsData}
            columns={columns}
            nestedKey={'skill_expiry'}
            nestedColumns={nestedColumns}
            tableContainerClass={classes.tableContainer}
            bodyCellClass={tableCellClasses.body}
            loadingMore={loadingMore}
            setLoadingMore={setLoadingMore}
            fetchPayLoad={fetchReports}
            pageInfo={getReportPageInfo}
            tablePayload={reportTableData}
            setTablePayload={setReportTableData}
          />
        </Box>
      ) : (
        <NoDataMessage message={NO_MATCHING_REPORTS} />
      )}
    </>
  );
  //#endregion Render
};
export { ReportsTable };
