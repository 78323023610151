import { Field, Form, Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { MdClose } from 'react-icons/md';
import { styled } from '@mui/styles';

import { SelectionAutoComplete } from '../_Autocomplete/SelectionAutocomplete';
import { LoadingStatus } from 'helpers';
import {
  activateLoading,
  deactivateLoading,
} from 'features/global/loadingProgressSlice';
import { Each } from 'helpers/Each';
import { NoDataMessage } from '../_Tables/NoDataMessage';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    fontSize: '1rem',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '1rem',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
    fontSize: '1rem',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const CustomDialog = ({
  getEntityHelp,
  getEntityHelpStatus,
  selectDropdownEntity,
  getDropdownStatusEntity,
  fetchDropDownEntityPage,
  fieldName,
  openHelpDialog,
  setOpenHelpDialog,
  selectLabel,
  resetHelpEntity,
}) => {
  //#region Constants
  const dispatch = useDispatch();
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  const [initValues, setInitValues] = useState({
    fieldName: null,
  });
  const [helpData, setHelpData] = useState(null);
  //#endregion State

  //#region Selectors
  //#endregion Selectors

  //#region Refs
  const formRef = useRef();
  //#endregion Refs

  //#region Effects
  useEffect(() => {
    if (openHelpDialog && getEntityHelpStatus === LoadingStatus.Idle) {
      fetchData();
    }
  }, [getEntityHelpStatus, openHelpDialog]);

  //#endregion Effects

  //#region Methods
  const fetchData = async () => {
    try {
      await dispatch(activateLoading());
      const data = await dispatch(getEntityHelp());
      setHelpData(data?.payload);
      await dispatch(deactivateLoading());
    } catch (error) {
      console.error('Error fetching help content:', error);
    }
  };

  const handleSelectChange = async (fieldName, value, setFieldValue) => {
    setFieldValue(fieldName, value);
    if (value?.value) {
      await dispatch(activateLoading());
      const data = await dispatch(getEntityHelp(value?.value));
      await dispatch(deactivateLoading());
      setHelpData(data?.payload);
    }
  };
  const handleCloseDialog = () => {
    setOpenHelpDialog(false);
    dispatch(resetHelpEntity());
  };

  const columns = [
    {
      id: 'header_column',
      label: 'Header Column',
      minWidth: '180px',
    },
    {
      id: 'is_required',
      label: 'Optional/Required',
      minWidth: '100px',
    },
    {
      id: 'value_type',
      label: 'Cell Value',
      minWidth: '180px',
    },
    {
      id: 'validation',
      label: 'Validation',
      minWidth: '170px',
    },
    {
      id: 'description',
      label: 'Description',
      minWidth: '270px',
    },
    {
      id: 'example',
      label: 'Example Value',
      minWidth: '180px',
    },
  ];
  //#endregion Methods

  //#region Render time calcs
  //#endregion Render time calcs

  //#region Render

  return (
    <Dialog
      open={openHelpDialog}
      onClose={handleCloseDialog}
      maxWidth={'lg'}
      fullWidth
    >
      <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
        {helpData?.title}
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 6,
            color: '#000',
            fontSize: '1.4rem',
          }}
          size="large"
        >
          <MdClose />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ mb: '2rem' }}>{helpData?.description}</Typography>
        <Formik
          enableReinitialize={true}
          initialValues={{ ...initValues }}
          innerRef={formRef}
        >
          {() => (
            <Form>
              <Field name={fieldName}>
                {({ field: { value }, form: { setFieldValue } }) => (
                  <SelectionAutoComplete
                    title={selectLabel}
                    keyProperty="value"
                    nameProperty="text"
                    entityIds={value?.value}
                    setEntityIds={setFieldValue}
                    entitySelector={selectDropdownEntity}
                    entityStatusSelector={getDropdownStatusEntity}
                    onCustomChange={(value) => {
                      handleSelectChange(fieldName, value, setFieldValue);
                    }}
                    fetchEntityPage={fetchDropDownEntityPage}
                    cancelItemTagEdit={() => {
                      setFieldValue(fieldName, null);
                      dispatch(resetHelpEntity());
                    }}
                    formField={fieldName}
                    showCloseItemEdit={true}
                    multiSelection={false}
                    hideCheckIcon={true}
                  />
                )}
              </Field>
            </Form>
          )}
        </Formik>
        {helpData ? (
          <TableContainer sx={{ mt: '2rem' }} component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <StyledTableRow>
                  {columns && (
                    <Each
                      of={columns}
                      render={(column, index) => (
                        <StyledTableCell
                          sx={{
                            minWidth: column?.minWidth,
                          }}
                        >
                          <Typography fontWeight={'bold'}>
                            {column.label}
                          </Typography>
                        </StyledTableCell>
                      )}
                    />
                  )}
                </StyledTableRow>
              </TableHead>
              <TableBody>
                <Each
                  of={helpData?.properties}
                  render={(row, rowIndex) => (
                    <StyledTableRow>
                      {columns?.length > 0 && (
                        <Each
                          of={columns}
                          render={(column, columnIndex) => (
                            <StyledTableCell>
                              {column.id === 'is_required'
                                ? row[column.id]
                                  ? 'Required'
                                  : 'Optional'
                                : row[column.id]}
                            </StyledTableCell>
                          )}
                        />
                      )}
                    </StyledTableRow>
                  )}
                />
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <NoDataMessage
            message="No Info found for PayPlan"
            setHeight={'100px'}
          />
        )}
      </DialogContent>
    </Dialog>
  );
  //#endregion Render
};
export { CustomDialog };
