import { Grid } from '@mui/material';
import { appStyles } from 'app/styles';
import logo from 'assets/ectl_login_HVTC.png';

function AnonymousTemplate({ theme, children }) {
  const classes = appStyles(theme);
  return (
    <div className={classes.loginBackgroundContainer}>
      <Grid
        container
        className={classes.loginContainer}
        item
        xs={12}
        sm={6}
        lg={4}
        xl={3}
      >
        <Grid item className={classes.loginGridItem}>
          <h1 className={classes.loginLogoTitle}>SMARTS</h1>
          <img src={logo} className={classes.logo} alt="hvtc logo"></img>
          <div className={classes.loginContent}>{children}</div>
        </Grid>
      </Grid>
    </div>
  );
}

export { AnonymousTemplate };
