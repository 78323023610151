const mapValues = (objectData) => {
  const values = Object.entries(objectData).reduce((acc, [key, value]) => {
    if (value && typeof value === 'object') {
      if (Array.isArray(value)) {
        let arrayData = value.map((val) => val?.value);
        return { ...acc, [key]: arrayData };
      } else {
        return { ...acc, [key]: value?.value };
      }
    } else if (
      value !== '' &&
      value !== true &&
      value !== false &&
      !isNaN(Number(value))
    ) {
      return { ...acc, [key]: Number(value) };
    } else {
      return { ...acc, [key]: value };
    }
  }, {});
  return values;
};

export { mapValues };
