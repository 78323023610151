import React from 'react';
import { SnackbarProvider } from 'notistack';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { mdTheme } from 'theme';
import { config } from 'ectl-config';

const container = document.getElementById('root');
const root = createRoot(container);
console.log(config);

root.render(
  //<React.StrictMode>
  <Provider store={store}>
    <BrowserRouter basename={config.routerBaseName}>
      <ThemeProvider theme={mdTheme}>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          classes={{
            variantSuccess: mdTheme.success,
            variantError: mdTheme.error,
            variantWarning: mdTheme.warning,
            variantInfo: mdTheme.info,
          }}
        >
          <App theme={mdTheme} />
        </SnackbarProvider>
      </ThemeProvider>
    </BrowserRouter>
  </Provider>
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
