import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Box, Button, Grid, Typography } from '@mui/material';
import { CheckboxWithLabel } from 'formik-mui';
import { Field, Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { mdTheme } from 'theme';
import { makeFormStyles } from 'forms/style';
import {
  activateLoading,
  deactivateLoading,
} from 'features/global/loadingProgressSlice';
import {
  updateAdminDepot,
  fetchAllAdminDepots,
  fetchCapabilityFieldIds,
  fetchCostCenterFieldIds,
  fetchEmployeeFieldIds,
  fetchExternalApiFieldIds,
  fetchJobFieldIds,
  fetchJobRoleFieldIds,
  fetchLevelFieldIds,
  fetchOfficeFieldIds,
  fetchPayGradeFieldIds,
  fetchPayPlanFieldIds,
  fetchSkillFieldIds,
  getAdminDepotStatus,
  getAdminFieldIdsStatus,
  getAllAdminDepot,
  getPayPlanFieldStatus,
  selectAdminFieldIds,
  selectCapabilityFieldIds,
  selectCostCenterFieldIds,
  selectEmployeeFieldIds,
  selectExternalApiFieldIds,
  selectJobFieldIds,
  selectJobRoleFieldIds,
  selectLevelFieldIds,
  selectOfficeFieldIds,
  selectPayGradeFieldIds,
  selectPayPlanFieldIds,
  selectSkillFieldIds,
} from 'features/adminDepot/adminDepotSlice';
import { SnackbarDismiss } from 'shared/components/SnackbarDismiss';
import { LoadingStatus, SystemRoleEnums, snackbarHandler } from 'helpers';
import {
  selectActiveUser,
  setAdminFieldIds,
  setHideSystemIds,
} from 'features/account/accountSlice';
import { FormTextField } from 'shared/components/FormFields/TextField';
import { FormFieldWithAutoComplete } from 'shared/components/FormFields/AutoCompleteField';
import { mapValues } from 'helpers/mapValues';
import { Each } from 'helpers/Each';
import { SelectionAutoComplete } from 'shared/components/_Autocomplete/SelectionAutocomplete';
import {
  fetchCommandDropDown,
  getCommandDropDownStatus,
  selectCommondDropDown,
} from 'features/AdminSettingsDropDown/dropDownSlice';
import AdvancedFeaturesAccordion from './AdvancedFeaturesAccordion';

const DepotManagementTab = () => {
  //#region Constants
  const dispatch = useDispatch();
  const commonFormStyles = makeFormStyles(mdTheme);
  const { enqueueSnackbar } = useSnackbar();
  const activeUser = useSelector(selectActiveUser);
  const adminDepots = useSelector(getAllAdminDepot);
  const adminDepotStatus = useSelector(getAdminDepotStatus);
  const itemDepotFormProps = [
    {
      label: 'Short Name',
      name: 'short_name',
      placeholder: 'Short Name',
    },
    {
      label: 'Display Name',
      name: 'display_name',
      placeholder: 'Display Name',
    },
  ];
  const itemDepotLabelFormProps = [
    {
      label: 'Job',
      name: 'jobs_name',
      placeholder: 'Job',
    },
    {
      label: 'Job Role',
      name: 'job_roles_name',
      placeholder: 'Job Role',
    },
    {
      label: 'Capability',
      name: 'capabilities_name',
      placeholder: 'Capability',
    },
    {
      label: 'Skill',
      name: 'skills_name',
      placeholder: 'Skill',
    },
  ];
  const columnsErrorMessage = 'At least one value must be selected';
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  const [initValues, setInitValues] = useState({
    short_name: '',
    display_name: '',
    command_id: null,
    jobs_name: '',
    job_roles_name: '',
    capabilities_name: '',
    skills_name: '',
    hide_system_ids: false,
    pay_plan_field_ids: [],
    pay_grade_field_ids: [],
    job_field_ids: [],
    job_role_field_ids: [],
    capability_field_ids: [],
    skill_field_ids: [],
    level_field_ids: [],
    employee_field_ids: [],
    cost_center_field_ids: [],
    office_field_ids: [],
    external_api_field_ids: [],
  });
  //#endregion State

  //#region Selectors
  const adminFieldIds = useSelector(selectAdminFieldIds);
  //#endregion Selectors

  //#region Refs
  //#endregion Refs

  //#region Effects
  const fetchData = (fetchedObject, adminFieldIds) => {
    let fieldValues = {
      short_name: fetchedObject.short_name,
      display_name: fetchedObject.display_name,
      command_id: fetchedObject.command_id,
      jobs_name: fetchedObject.jobs_name ?? '',
      job_roles_name: fetchedObject.job_roles_name ?? '',
      capabilities_name: fetchedObject.capabilities_name ?? '',
      skills_name: fetchedObject.skills_name ?? '',
      hide_system_ids: fetchedObject.hide_system_ids,
    };
    if (fetchedObject) {
      if (adminFieldIds?.payPlanField?.status === LoadingStatus.Loaded) {
        fieldValues = {
          ...fieldValues,
          pay_plan_field_ids: adminFieldIds?.payPlanField?.data?.filter((opt) =>
            fetchedObject?.pay_plan_field_ids.includes(opt?.value)
          ),
        };
      }
      if (adminFieldIds?.payGradeField?.status === LoadingStatus.Loaded) {
        fieldValues = {
          ...fieldValues,
          pay_grade_field_ids: adminFieldIds?.payGradeField?.data?.filter(
            (opt) => fetchedObject?.pay_grade_field_ids.includes(opt?.value)
          ),
        };
      }
      if (adminFieldIds?.jobField?.status === LoadingStatus.Loaded) {
        fieldValues = {
          ...fieldValues,
          job_field_ids: adminFieldIds?.jobField?.data?.filter((opt) =>
            fetchedObject?.job_field_ids.includes(opt?.value)
          ),
        };
      }
      if (adminFieldIds?.jobRoleField?.status === LoadingStatus.Loaded) {
        fieldValues = {
          ...fieldValues,
          job_role_field_ids: adminFieldIds?.jobRoleField?.data?.filter((opt) =>
            fetchedObject?.job_role_field_ids.includes(opt?.value)
          ),
        };
      }
      if (adminFieldIds?.capabilityField?.status === LoadingStatus.Loaded) {
        fieldValues = {
          ...fieldValues,
          capability_field_ids: adminFieldIds?.capabilityField?.data?.filter(
            (opt) => fetchedObject?.capability_field_ids.includes(opt?.value)
          ),
        };
      }
      if (adminFieldIds?.skillField?.status === LoadingStatus.Loaded) {
        fieldValues = {
          ...fieldValues,
          skill_field_ids: adminFieldIds?.skillField?.data?.filter((opt) =>
            fetchedObject?.skill_field_ids.includes(opt?.value)
          ),
        };
      }
      if (adminFieldIds?.levelField?.status === LoadingStatus.Loaded) {
        fieldValues = {
          ...fieldValues,
          level_field_ids: adminFieldIds?.levelField?.data?.filter((opt) =>
            fetchedObject?.level_field_ids.includes(opt?.value)
          ),
        };
      }
      if (adminFieldIds?.employeeField?.status === LoadingStatus.Loaded) {
        fieldValues = {
          ...fieldValues,
          employee_field_ids: adminFieldIds?.employeeField?.data?.filter(
            (opt) => fetchedObject?.employee_field_ids.includes(opt?.value)
          ),
        };
      }
      if (adminFieldIds?.costCenterField?.status === LoadingStatus.Loaded) {
        fieldValues = {
          ...fieldValues,
          cost_center_field_ids: adminFieldIds?.costCenterField?.data?.filter(
            (opt) => fetchedObject?.cost_center_field_ids.includes(opt?.value)
          ),
        };
      }
      if (adminFieldIds?.officeField?.status === LoadingStatus.Loaded) {
        fieldValues = {
          ...fieldValues,
          office_field_ids: adminFieldIds?.officeField?.data?.filter((opt) =>
            fetchedObject?.office_field_ids.includes(opt?.value)
          ),
        };
      }
      if (adminFieldIds?.externalApiField?.status === LoadingStatus.Loaded) {
        fieldValues = {
          ...fieldValues,
          external_api_field_ids: adminFieldIds?.externalApiField?.data?.filter(
            (opt) => fetchedObject?.external_api_field_ids.includes(opt?.value)
          ),
        };
      }
    }
    setInitValues(fieldValues);
    dispatch(deactivateLoading());
  };

  useEffect(() => {
    const getDepots = async () => {
      dispatch(activateLoading());
      await dispatch(fetchAllAdminDepots());
      dispatch(deactivateLoading());
    };

    if (adminDepotStatus === LoadingStatus.Idle) {
      getDepots();
    }
  }, [adminDepotStatus, dispatch]);

  useEffect(() => {
    const fetchAdminDepot = async () => {
      fetchData(adminDepots, adminFieldIds);
    };
    if (adminDepots && adminFieldIds) {
      fetchAdminDepot();
    }
  }, [adminDepots]); // eslint-disable-line react-hooks/exhaustive-deps
  //#endregion Effects

  //#region Methods

  const handleSubmit = async (values, setSubmitting, resetForm) => {
    var resultAction = null;
    dispatch(activateLoading({ showProgress: true }));
    dispatch(setHideSystemIds(values?.hide_system_ids));
    resultAction = await dispatch(
      updateAdminDepot({
        ...mapValues(values),
      })
    );

    if (!resultAction.error) {
      const filteredAdminFieldIds = [
        'pay_plan_field_ids',
        'pay_grade_field_ids',
        'job_field_ids',
        'job_role_field_ids',
        'capability_field_ids',
        'skill_field_ids',
        'level_field_ids',
        'employee_field_ids',
        'cost_center_field_ids',
        'office_field_ids',
        'external_api_field_ids',
      ].flatMap((key) => resultAction?.payload?.[key]);
      dispatch(setAdminFieldIds(filteredAdminFieldIds));
      fetchData(resultAction?.payload, adminFieldIds);
    }

    resetForm();
    setSubmitting(false);
    dispatch(deactivateLoading());

    const { message, variant } = snackbarHandler(
      resultAction.meta.requestStatus,
      'Admin Depot Data Created'
    );
    enqueueSnackbar(message, {
      action: (key) => <SnackbarDismiss key={key} />,
      variant: variant,
    });
  };

  //#endregion Methods

  //#region Render time calcs
  //#endregion Render time calcs

  //#region Render
  return (
    <>
      <div>
        <Typography variant="h4" marginTop={'0.5rem'} marginBottom={'0.8rem'}>
          Depot
        </Typography>
        <Formik
          enableReinitialize={true}
          initialValues={{ ...initValues }}
          validationSchema={Yup.object({
            short_name: Yup.string().required('Required'),
            display_name: Yup.string().required('Required'),
            pay_plan_field_ids: Yup.array()
              .test('length', columnsErrorMessage, (arr) => {
                return arr?.length > 0;
              })
              .required(columnsErrorMessage),
            pay_grade_field_ids: Yup.array()
              .test('length', columnsErrorMessage, (arr) => {
                return arr?.length > 0;
              })
              .required(columnsErrorMessage),
            job_field_ids: Yup.array()
              .test('length', columnsErrorMessage, (arr) => {
                return arr?.length > 0;
              })
              .required(columnsErrorMessage),
            job_role_field_ids: Yup.array()
              .test('length', columnsErrorMessage, (arr) => {
                return arr?.length > 0;
              })
              .required(columnsErrorMessage),
            capability_field_ids: Yup.array()
              .test('length', columnsErrorMessage, (arr) => {
                return arr?.length > 0;
              })
              .required(columnsErrorMessage),
            skill_field_ids: Yup.array()
              .test('length', columnsErrorMessage, (arr) => {
                return arr?.length > 0;
              })
              .required(columnsErrorMessage),
            level_field_ids: Yup.array()
              .test('length', columnsErrorMessage, (arr) => {
                return arr?.length > 0;
              })
              .required(columnsErrorMessage),
            employee_field_ids: Yup.array()
              .test('length', columnsErrorMessage, (arr) => {
                return arr?.length > 0;
              })
              .required(columnsErrorMessage),
            cost_center_field_ids: Yup.array()
              .test('length', columnsErrorMessage, (arr) => {
                return arr?.length > 0;
              })
              .required(columnsErrorMessage),
            office_field_ids: Yup.array()
              .test('length', columnsErrorMessage, (arr) => {
                return arr?.length > 0;
              })
              .required(columnsErrorMessage),
            external_api_field_ids: Yup.array()
              .test('length', columnsErrorMessage, (arr) => {
                return arr?.length > 0;
              })
              .required(columnsErrorMessage),
          })}
          validateOnBlur
          onSubmit={(values, { setSubmitting, resetForm }) => {
            handleSubmit(values, setSubmitting, resetForm);
          }}
        >
          {({ isSubmitting, setFieldValue, values, errors }) => (
            <Form>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  gap: '0.8rem',
                }}
              >
                <Grid container spacing={2}>
                  <Each
                    of={itemDepotFormProps}
                    render={(field) => (
                      <Grid item xs={12} md={6}>
                        <FormTextField fullWidth {...field} />
                      </Grid>
                    )}
                  />
                  <Grid item xs={12} md={6}>
                    <Field className={commonFormStyles.input} name="command_id">
                      {({ field: { value }, form: { setFieldValue } }) => (
                        <SelectionAutoComplete
                          title="Select Command"
                          keyProperty="value"
                          nameProperty="text"
                          entityIds={value}
                          setEntityIds={setFieldValue}
                          onCustomChange={(value) => {
                            setFieldValue('command_id', value?.value);
                          }}
                          entitySelector={selectCommondDropDown}
                          entityStatusSelector={getCommandDropDownStatus}
                          fetchEntityPage={fetchCommandDropDown}
                          multiSelection={false}
                          showCloseItemEdit={true}
                          hideCheckIcon={true}
                          cancelItemTagEdit={() => {
                            setFieldValue('command_id', null);
                          }}
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>
                <Typography variant="h4" width="100%" padding={'2rem 0.5rem 0'}>
                  Depot Labels
                </Typography>
                <Grid container spacing={3}>
                  <Each
                    of={itemDepotLabelFormProps}
                    render={(field) => (
                      <Grid item xs={12} md={6}>
                        <FormTextField fullWidth {...field} />
                      </Grid>
                    )}
                  />
                  <Grid item xs={12} md={6} sx={{ position: 'relative' }}>
                    <FormFieldWithAutoComplete
                      title={`PayPlan Columns`}
                      entityIds={map(values?.pay_plan_field_ids, 'value')}
                      setEntityIds={(value) =>
                        setFieldValue('pay_plan_field_ids', value)
                      }
                      entitySelector={selectPayPlanFieldIds}
                      entityStatusSelector={getPayPlanFieldStatus}
                      onCustomChange={(value) =>
                        setFieldValue('pay_plan_field_ids', value)
                      }
                      fetchEntityPage={fetchPayPlanFieldIds}
                      formField="pay_plan_field_ids"
                      cancelItemTagEdit={() =>
                        setFieldValue('pay_plan_field_ids', null)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sx={{ position: 'relative' }}>
                    <FormFieldWithAutoComplete
                      title={`PayGrade Columns`}
                      entityIds={map(values.pay_grade_field_ids, 'value')}
                      setEntityIds={(value) =>
                        setFieldValue('pay_grade_field_ids', value)
                      }
                      entitySelector={selectPayGradeFieldIds}
                      entityStatusSelector={getAdminFieldIdsStatus}
                      onCustomChange={(value) =>
                        setFieldValue('pay_grade_field_ids', value)
                      }
                      fetchEntityPage={fetchPayGradeFieldIds}
                      formField="pay_grade_field_ids"
                      cancelItemTagEdit={() =>
                        setFieldValue('pay_grade_field_ids', null)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sx={{ position: 'relative' }}>
                    <FormFieldWithAutoComplete
                      title={`${activeUser?.label_job} Columns`}
                      entityIds={map(values.job_field_ids, 'value')}
                      setEntityIds={(value) =>
                        setFieldValue('job_field_ids', value)
                      }
                      entitySelector={selectJobFieldIds}
                      entityStatusSelector={getAdminFieldIdsStatus}
                      onCustomChange={(value) =>
                        setFieldValue('job_field_ids', value)
                      }
                      fetchEntityPage={fetchJobFieldIds}
                      formField="job_field_ids"
                      cancelItemTagEdit={() =>
                        setFieldValue('job_field_ids', null)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sx={{ position: 'relative' }}>
                    <FormFieldWithAutoComplete
                      title={` ${activeUser?.label_job_role} Columns`}
                      entityIds={map(values.job_role_field_ids, 'value')}
                      setEntityIds={(value) =>
                        setFieldValue('job_role_field_ids', value)
                      }
                      entitySelector={selectJobRoleFieldIds}
                      entityStatusSelector={getAdminFieldIdsStatus}
                      onCustomChange={(value) =>
                        setFieldValue('job_role_field_ids', value)
                      }
                      fetchEntityPage={fetchJobRoleFieldIds}
                      formField="job_role_field_ids"
                      cancelItemTagEdit={() =>
                        setFieldValue('job_role_field_ids', null)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sx={{ position: 'relative' }}>
                    <FormFieldWithAutoComplete
                      title={` ${activeUser?.label_capability} Columns`}
                      entityIds={map(values.capability_field_ids, 'value')}
                      setEntityIds={(value) =>
                        setFieldValue('capability_field_ids', value)
                      }
                      entitySelector={selectCapabilityFieldIds}
                      entityStatusSelector={getAdminFieldIdsStatus}
                      onCustomChange={(value) =>
                        setFieldValue('capability_field_ids', value)
                      }
                      fetchEntityPage={fetchCapabilityFieldIds}
                      formField="capability_field_ids"
                      cancelItemTagEdit={() =>
                        setFieldValue('capability_field_ids', null)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sx={{ position: 'relative' }}>
                    <FormFieldWithAutoComplete
                      title={` ${activeUser?.label_skill} Columns`}
                      entityIds={map(values.skill_field_ids, 'value')}
                      setEntityIds={(value) =>
                        setFieldValue('skill_field_ids', value)
                      }
                      entitySelector={selectSkillFieldIds}
                      entityStatusSelector={getAdminFieldIdsStatus}
                      onCustomChange={(value) =>
                        setFieldValue('skill_field_ids', value)
                      }
                      fetchEntityPage={fetchSkillFieldIds}
                      formField="skill_field_ids"
                      cancelItemTagEdit={() =>
                        setFieldValue('skill_field_ids', null)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sx={{ position: 'relative' }}>
                    <FormFieldWithAutoComplete
                      title={`Level Columns`}
                      entityIds={map(values.level_field_ids, 'value')}
                      setEntityIds={(value) =>
                        setFieldValue('level_field_ids', value)
                      }
                      entitySelector={selectLevelFieldIds}
                      entityStatusSelector={getAdminFieldIdsStatus}
                      onCustomChange={(value) =>
                        setFieldValue('level_field_ids', value)
                      }
                      fetchEntityPage={fetchLevelFieldIds}
                      formField="level_field_ids"
                      cancelItemTagEdit={() =>
                        setFieldValue('level_field_ids', null)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sx={{ position: 'relative' }}>
                    <FormFieldWithAutoComplete
                      title={`Employee Columns`}
                      entityIds={map(values.employee_field_ids, 'value')}
                      setEntityIds={(value) =>
                        setFieldValue('employee_field_ids', value)
                      }
                      entitySelector={selectEmployeeFieldIds}
                      entityStatusSelector={getAdminFieldIdsStatus}
                      onCustomChange={(value) =>
                        setFieldValue('employee_field_ids', value)
                      }
                      fetchEntityPage={fetchEmployeeFieldIds}
                      formField="employee_field_ids"
                      cancelItemTagEdit={() =>
                        setFieldValue('employee_field_ids', null)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sx={{ position: 'relative' }}>
                    <FormFieldWithAutoComplete
                      title={`CostCenter Columns`}
                      entityIds={map(values.cost_center_field_ids, 'value')}
                      setEntityIds={(value) =>
                        setFieldValue('cost_center_field_ids', value)
                      }
                      entitySelector={selectCostCenterFieldIds}
                      entityStatusSelector={getAdminFieldIdsStatus}
                      onCustomChange={(value) =>
                        setFieldValue('cost_center_field_ids', value)
                      }
                      fetchEntityPage={fetchCostCenterFieldIds}
                      formField="cost_center_field_ids"
                      cancelItemTagEdit={() =>
                        setFieldValue('cost_center_field_ids', null)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sx={{ position: 'relative' }}>
                    <FormFieldWithAutoComplete
                      title={`Office Columns`}
                      entityIds={map(values.office_field_ids, 'value')}
                      setEntityIds={(value) =>
                        setFieldValue('office_field_ids', value)
                      }
                      entitySelector={selectOfficeFieldIds}
                      entityStatusSelector={getAdminFieldIdsStatus}
                      onCustomChange={(value) =>
                        setFieldValue('office_field_ids', value)
                      }
                      fetchEntityPage={fetchOfficeFieldIds}
                      formField="office_field_ids"
                      cancelItemTagEdit={() =>
                        setFieldValue('office_field_ids', null)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sx={{ position: 'relative' }}>
                    <FormFieldWithAutoComplete
                      title={`ExternalApi Columns`}
                      entityIds={map(values.external_api_field_ids, 'value')}
                      setEntityIds={(value) =>
                        setFieldValue('external_api_field_ids', value)
                      }
                      entitySelector={selectExternalApiFieldIds}
                      entityStatusSelector={getAdminFieldIdsStatus}
                      onCustomChange={(value) =>
                        setFieldValue('external_api_field_ids', value)
                      }
                      fetchEntityPage={fetchExternalApiFieldIds}
                      formField="external_api_field_ids"
                      cancelItemTagEdit={() =>
                        setFieldValue('external_api_field_ids', null)
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <FormTextField
                      component={CheckboxWithLabel}
                      margin="dense"
                      Label={{ label: 'System IDs' }}
                      label="Display Steps"
                      className={commonFormStyles.materialInput}
                      type="checkbox"
                      name="hide_system_ids"
                      placeholder="Display Steps"
                      onChange={(e) =>
                        setFieldValue('hide_system_ids', e.target.checked)
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      fullWidth
                      variant="contained"
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          )}
        </Formik>
        {[
          SystemRoleEnums.ProgramManager,
          SystemRoleEnums.BlueGrassAdmin,
        ].includes(activeUser?.role) && (
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ mt: '2rem' }}>
              <AdvancedFeaturesAccordion />
            </Grid>
          </Grid>
        )}
      </div>
    </>
  );
  //#endregion Render
};
export { DepotManagementTab };
