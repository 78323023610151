import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { dropDownService } from 'features/services/dropDown.service';
import { LoadingStatus } from 'helpers';

const initialState = {
  error: null,
  dropDowns: {
    levels: [],
    skills: [],
    payPlans: [],
    payGrades: [],
    series: [],
    jobs: [],
    jobRoles: [],
    directReports: [],
    skillExpiry: [],
    capabilities: [],
    jobRolesById: [],
    employees: [],
    supervisors: [],
    taskManagers: [],
    systemRoles: [],
    systemRolesForEmployee: [],
    reports: [],
    costCenter: [],
    reportExpiration: [],
    employeeStatus: [],
    clientApiRestriction: [],
    officeByCostCenterId: [],
    allEmployees: [],
    command: [],
    depots: [],
    reportPercentage: [],
  },
  filterDropdowns: {},
  multiSelectDropDown: [],
  dropDownStatus: LoadingStatus.Idle,
  jobDropDownStatus: LoadingStatus.Idle,
  levelDropDownStatus: LoadingStatus.Idle,
  seriesDropDownStatus: LoadingStatus.Idle,
  jobRolesDropDownStatus: LoadingStatus.Idle,
  directReportsDropDownStatus: LoadingStatus.Idle,
  skillExpiryDropDownStatus: LoadingStatus.Idle,
  skillDropDownStatus: LoadingStatus.Idle,
  capabilityDropDownStatus: LoadingStatus.Idle,
  jobRolesByJobIdDropDownStatus: LoadingStatus.Idle,
  superVisorDropDownStatus: LoadingStatus.Idle,
  taskManagerDropDownStatus: LoadingStatus.Idle,
  payPlanDropDownStatus: LoadingStatus.Idle,
  payGradeDropDownStatus: LoadingStatus.Idle,
  systemRolesDropDownStatus: LoadingStatus.Idle,
  systemRolesForEmployeeDropDownStatus: LoadingStatus.Idle,
  reportsDropDownStatus: LoadingStatus.Idle,
  costCenterDropDownStatus: LoadingStatus.Idle,
  reportExpirationDropDownStatus: LoadingStatus.Idle,
  employeeStatusDropDownStatus: LoadingStatus.Idle,
  apiRestrictionDropDownStatus: LoadingStatus.Idle,
  officeByCostCenterIdDropDownStatus: LoadingStatus.Idle,
  employeeDropDownStatus: LoadingStatus.Idle,
  allEmployeesDropdownStatus: LoadingStatus.Idle,
  commandDropdownStatus: LoadingStatus.Idle,
  depotDrowpdownStatus: LoadingStatus.Idle,
  reportPercentageDropdownStatus: LoadingStatus.Idle,
  dropDownInfo: {},
  displayDropDowns: {},
  dropDownUsage: [],
  loadedDropDownsMap: {},
  isLevelDropdownOpen: false,
};

export const fetchJobsDropDown = createAsyncThunk(
  'dropDown/fetchJobsDropDown',
  async () => await dropDownService.fetchJobDropDown()
);

export const fetchJobRolesDropDown = createAsyncThunk(
  'dropDown/fetchJobRolesDropDown',
  async (params = {}) =>
    await dropDownService.fetchJobRoleDropDown(
      params.display_name,
      params.job_id,
      params.job_display_name,
      params.job_series_id,
      params.job_family_series_id,
      params.level_id,
      params.level_display_name
    )
);

export const fetchDirectReportsDropDown = createAsyncThunk(
  'dropDown/fetchDirectReportsDropDown',
  async (params = {}) =>
    await dropDownService.fetchDirectReportsDropDown(
      params.employee_id,
      params.first_name,
      params.last_name,
      params.full_name,
      params.pay_plan_id,
      params.pay_plan_display_name,
      params.pay_grade_id,
      params.pay_grade_display_name,
      params.job_id,
      params.job_display_name,
      params.job_series_id,
      params.job_family_series_id,
      params.job_family_display_name,
      params.job_role_id,
      params.job_role_display_name,
      params.supervisor_employee_id,
      params.supervisor_first_name,
      params.supervisor_last_name,
      params.supervisor_full_name,
      params.sort
    )
);

export const fetchLevelsDropDown = createAsyncThunk(
  'dropDown/fetchLevelsDropDown',
  async (params = {}) =>
    await dropDownService.fetchLevelDropDown(
      params.level_id,
      params.display_name,
      params.start_index ?? 0,
      params.stop_index ?? 0
    )
);

export const fetchSkillExpiryDropDown = createAsyncThunk(
  'dropDown/fetchSkillExpiryDropDown',
  async () => await dropDownService.fetchSkillExpiry()
);

export const fetchSkillsDropDown = createAsyncThunk(
  'dropDown/fetchSkillsDropDown',
  async (params = {}) =>
    await dropDownService.fetchSkillsDropDown(
      params.skill_id,
      params.display_name,
      params.start_index ?? 0,
      params.stop_index ?? 0
    )
);

export const fetchCapabilityDropDown = createAsyncThunk(
  'dropDown/fetchCapabilityDropDown',
  async (params = {}) =>
    await dropDownService.fetchCapabilitiesDropDown(
      params.capability_id,
      params.display_name,
      params.job_id,
      params.job_role_id
    )
);

export const fetchJobRolesByJobId = createAsyncThunk(
  'dropDown/fetchJobRolesByJobId',
  async (params = {}) =>
    await dropDownService.fetchJobRoleDropDown(
      params.display_name,
      params.job_id,
      params.job_display_name,
      params.job_series_id,
      params.job_family_series_id,
      params.level_id,
      params.level_display_name
    )
);

export const fetchSuperVisorDropDown = createAsyncThunk(
  'dropDown/fetchSuperVisorDropDown',
  async (params = {}) =>
    await dropDownService.fetchSuperVisorDropDown(
      params.include_self,
      params.start_index,
      params.stop_index
    )
);

export const fetchTaskManagerDropDown = createAsyncThunk(
  'dropDown/fetchTaskManagerDropDown',
  async (params = {}) =>
    await dropDownService.fetchTaskManagerDropDown(params.include_self)
);

export const fetchEmployeeDropDown = createAsyncThunk(
  'dropDown/fetchEmployeeDropDown',
  async (params = {}) =>
    await dropDownService.fetchEmployeeDropDown(params.include_self)
);

export const fetchPayPlansDropDown = createAsyncThunk(
  'dropDown/fetchPayPlansDropDown',
  async (params = {}) =>
    await dropDownService.fetchPayPlansDropDown(
      params.pay_plan_id,
      params.display_name,
      params.start_index,
      params.stop_index
    )
);

export const fetchPayGradesDropDown = createAsyncThunk(
  'dropDown/fetchPayGradesDropDown',
  async (params = {}) =>
    await dropDownService.fetchPayGradesDropDown(
      params.pay_grade_id,
      params.display_name,
      params.start_index,
      params.stop_index
    )
);

export const fetchSystemRolesDropDown = createAsyncThunk(
  'dropDown/fetchSystemRolesDropDown',
  async () => await dropDownService.fetchSystemRoles()
);

export const fetchSystemRolesForEmployeeDropDown = createAsyncThunk(
  'dropDown/fetchSystemRolesForEmployeeDropDown',
  async (params = {}) =>
    await dropDownService.fetchSystemRoles(params.employee_id)
);

export const fetchReportDropDown = createAsyncThunk(
  'dropDown/fetchReportDropDown',
  async (params = {}) =>
    await dropDownService.fetchReportsDropDown(
      params.employee_id,
      params.first_name,
      params.last_name,
      params.full_name,
      params.pay_plan_id,
      params.pay_plan_display_name,
      params.pay_grade_id,
      params.pay_grade_display_name,
      params.job_id,
      params.job_display_name,
      params.job_series_id,
      params.job_family_series_id,
      params.job_family_display_name,
      params.job_role_id,
      params.job_role_display_name,
      params.supervisor_employee_id,
      params.supervisor_first_name,
      params.supervisor_last_name,
      params.supervisor_full_name,
      params.start_index ?? 0,
      params.stop_index ?? 0
    )
);

export const fetchCostCenterDropDown = createAsyncThunk(
  'dropDown/fetchCostCenterDropDown',
  async () => await dropDownService.fetchCostCenter()
);

export const fetchReportExpirationDropDown = createAsyncThunk(
  'dropDown/fetchReportExpirationDropDown',
  async () => await dropDownService.fetchReportExpiration()
);

export const fetchEmployeeStatusDropDown = createAsyncThunk(
  'dropDown/fetchEmployeeStatusDropDown',
  async () => await dropDownService.fetchEmployeeStatus()
);

export const fetchApiRestrictionDropDown = createAsyncThunk(
  'dropDown/fetchApiRestrictionDropDown',
  async () => await dropDownService.fetchExternalApi()
);

export const fetchOfficeByCostCenterId = createAsyncThunk(
  'dropDown/fetchOfficeByCostCenterId',
  async (params = {}) =>
    await dropDownService.fetchOfficeDropDown(params.cost_center_id)
);

export const fetchAllEmployeesDropDown = createAsyncThunk(
  'dropDown/fetchAllEmployeesDropDown',
  async (params = {}) =>
    await dropDownService.fetchAllEmployees(params.include_self)
);

export const fetchCommandDropDown = createAsyncThunk(
  'dropDown/fetchCommandDropDown',
  async () => await dropDownService.fetchCommandDropDown()
);

export const fetchDepotDropDown = createAsyncThunk(
  'dropDown/fetchDepotDropDown',
  async (params = {}) =>
    await dropDownService.fetchDepotDropDown(params.command_id)
);

export const fetchPercentageDropDown = createAsyncThunk(
  'dropDown/fetchPercentageDropDown',
  async () => await dropDownService.fetchRPDropDown()
);

const dropDownSlice = createSlice({
  name: 'dropDowns',
  initialState,
  reducers: {
    resetDropDownState: (state) => initialState,
    setSelectedDropDown: (state, action) => {
      state.filterDropdowns = action.payload;
    },
    setMultiDropDownState: (state, action) => {
      state.multiSelectDropDown = action.payload;
    },
    resetDropDown: (state) => {
      state.dropDowns = initialState.dropDowns;
      state.jobDropDownStatus = LoadingStatus.Idle;
      state.capabilityDropDownStatus = LoadingStatus.Idle;
      state.jobRolesByJobIdDropDownStatus = LoadingStatus.Idle;
      state.jobRolesDropDownStatus = LoadingStatus.Idle;
      state.superVisorDropDownStatus = LoadingStatus.Idle;
      state.taskManagerDropDownStatus = LoadingStatus.Idle;
      state.directReportsDropDownStatus = LoadingStatus.Idle;
      state.levelDropDownStatus = LoadingStatus.Idle;
      state.skillDropDownStatus = LoadingStatus.Idle;
      state.reportsDropDownStatus = LoadingStatus.Idle;
      state.payPlanDropDownStatus = LoadingStatus.Idle;
      state.payGradeDropDownStatus = LoadingStatus.Idle;
      state.systemRolesDropDownStatus = LoadingStatus.Idle;
      state.systemRolesForEmployeeDropDownStatus = LoadingStatus.Idle;
      state.costCenterDropDownStatus = LoadingStatus.Idle;
      state.reportExpirationDropDownStatus = LoadingStatus.Idle;
      state.skillExpiryDropDownStatus = LoadingStatus.Idle;
      state.employeeStatusDropDownStatus = LoadingStatus.Idle;
      state.apiRestrictionDropDownStatus = LoadingStatus.Idle;
      state.officeByCostCenterIdDropDownStatus = LoadingStatus.Idle;
    },
    resetNewJobDropDown: (state) => {
      state.jobRolesDropDownStatus = LoadingStatus.Idle;
      state.jobDropDownStatus = LoadingStatus.Idle;
    },
    resetOfficeIdDropDown: (state) => {
      state.officeByCostCenterIdDropDownStatus = LoadingStatus.Idle;
    },
    resetJobRoleDropdown: (state) => {
      state.jobRolesByJobIdDropDownStatus = LoadingStatus.Idle;
    },
    resetDepotDropDownState: (state) => {
      state.depotDrowpdownStatus = LoadingStatus.Idle;
    },
    resetCommandDropDownState: (state) => {
      state.commandDropdownStatus = LoadingStatus.Idle;
    },
    resetLevelExpiryState: (state) => {
      state.levelDropDownStatus = LoadingStatus.Idle;
      state.skillExpiryDropDownStatus = LoadingStatus.Idle;
    },
    setIsLevelDropdownOpen: (state, action) => {
      state.isLevelDropdownOpen = action.payload;
    },
  },
  extraReducers: {
    [fetchJobsDropDown.pending]: (state, action) => {
      state.jobDropDownStatus = LoadingStatus.Loading;
    },
    [fetchJobsDropDown.fulfilled]: (state, action) => {
      state.dropDownInfo = action.payload.pageInfo;
      state.dropDowns.jobs = action?.payload?.dropDowns.map((item) => {
        return { value: +item.value, text: item.text };
      });
      state.jobDropDownStatus = LoadingStatus.Loaded;
    },
    [fetchJobsDropDown.rejected]: (state, action) => {
      state.jobDropDownStatus = LoadingStatus.Failed;
    },
    [fetchLevelsDropDown.pending]: (state, action) => {
      state.levelDropDownStatus = LoadingStatus.Loading;
    },
    [fetchLevelsDropDown.fulfilled]: (state, action) => {
      state.dropDownInfo = action.payload.pageInfo;
      state.dropDowns.levels = action.payload.dropDowns.map((item) => {
        return {
          value: +item.value,
          text: item.text,
          color: item.color,
          description: item.description,
        };
      });
      state.levelDropDownStatus = LoadingStatus.Loaded;
    },
    [fetchLevelsDropDown.rejected]: (state, action) => {
      state.levelDropDownStatus = LoadingStatus.Failed;
    },
    [fetchJobRolesDropDown.pending]: (state, action) => {
      state.jobRolesDropDownStatus = LoadingStatus.Loading;
    },
    [fetchJobRolesDropDown.fulfilled]: (state, action) => {
      state.dropDownInfo = action.payload.pageInfo;
      state.dropDowns.jobRoles = action?.payload?.dropDowns.map((item) => {
        return { value: +item.value, text: item.text };
      });
      state.jobRolesDropDownStatus = LoadingStatus.Loaded;
    },
    [fetchJobRolesDropDown.rejected]: (state, action) => {
      state.jobRolesDropDownStatus = LoadingStatus.Failed;
    },
    [fetchDirectReportsDropDown.pending]: (state, action) => {
      state.directReportsDropDownStatus = LoadingStatus.Loading;
    },
    [fetchDirectReportsDropDown.fulfilled]: (state, action) => {
      state.dropDownInfo = action.payload.pageInfo;
      state.dropDowns.directReports = action?.payload?.dropDowns.map((item) => {
        return { value: +item.value, text: item.text };
      });
      state.directReportsDropDownStatus = LoadingStatus.Loaded;
    },
    [fetchDirectReportsDropDown.rejected]: (state, action) => {
      state.directReportsDropDownStatus = LoadingStatus.Failed;
    },
    [fetchSkillExpiryDropDown.pending]: (state, action) => {
      state.skillExpiryDropDownStatus = LoadingStatus.Loading;
    },
    [fetchSkillExpiryDropDown.fulfilled]: (state, action) => {
      state.dropDownInfo = action.payload.pageInfo;
      state.dropDowns.skillExpiry = action.payload.dropDowns;
      state.skillExpiryDropDownStatus = LoadingStatus.Loaded;
    },
    [fetchSkillExpiryDropDown.rejected]: (state, action) => {
      state.skillExpiryDropDownStatus = LoadingStatus.Failed;
    },
    [fetchSkillsDropDown.pending]: (state, action) => {
      state.skillDropDownStatus = LoadingStatus.Loading;
    },
    [fetchSkillsDropDown.fulfilled]: (state, action) => {
      state.dropDownInfo = action.payload.pageInfo;
      state.dropDowns.skills = action.payload.dropDowns.map((item) => {
        return {
          value: +item.value,
          text: item.text,
          description: item.description,
        };
      });
      state.skillDropDownStatus = LoadingStatus.Loaded;
    },
    [fetchSkillsDropDown.rejected]: (state, action) => {
      state.skillDropDownStatus = LoadingStatus.Failed;
    },
    [fetchCapabilityDropDown.pending]: (state, action) => {
      state.capabilityDropDownStatus = LoadingStatus.Loading;
    },
    [fetchCapabilityDropDown.fulfilled]: (state, action) => {
      state.dropDownInfo = action.payload.pageInfo;
      state.dropDowns.capabilities = action.payload.dropDowns.map((item) => {
        return {
          value: +item.value,
          text: item.text,
          description: item.description,
        };
      });
      state.capabilityDropDownStatus = LoadingStatus.Loaded;
    },
    [fetchCapabilityDropDown.rejected]: (state, action) => {
      state.capabilityDropDownStatus = LoadingStatus.Failed;
    },
    [fetchJobRolesByJobId.pending]: (state, action) => {
      state.jobRolesByJobIdDropDownStatus = LoadingStatus.Loading;
    },
    [fetchJobRolesByJobId.fulfilled]: (state, action) => {
      state.dropDownInfo = action.payload.pageInfo;
      state.dropDowns.jobRolesById = action?.payload?.dropDowns.map((item) => {
        return { value: +item.value, text: item.text };
      });
      state.jobRolesByJobIdDropDownStatus = LoadingStatus.Loaded;
    },
    [fetchJobRolesByJobId.rejected]: (state, action) => {
      state.jobRolesByJobIdDropDownStatus = LoadingStatus.Failed;
    },

    [fetchSuperVisorDropDown.pending]: (state, action) => {
      state.superVisorDropDownStatus = LoadingStatus.Loading;
    },
    [fetchSuperVisorDropDown.fulfilled]: (state, action) => {
      state.dropDownInfo = action.payload.pageInfo;
      state.dropDowns.supervisors = action?.payload?.dropDowns.map((item) => {
        return { value: +item.value, text: item.text };
      });
      state.superVisorDropDownStatus = LoadingStatus.Loaded;
    },
    [fetchSuperVisorDropDown.rejected]: (state, action) => {
      state.superVisorDropDownStatus = LoadingStatus.Failed;
    },
    [fetchEmployeeDropDown.pending]: (state, action) => {
      state.employeeDropDownStatus = LoadingStatus.Loading;
    },
    [fetchEmployeeDropDown.fulfilled]: (state, action) => {
      state.dropDownInfo = action.payload.pageInfo;
      state.dropDowns.employees = action?.payload?.dropDowns.map((item) => {
        return { value: +item.value, text: item.text };
      });
      state.employeeDropDownStatus = LoadingStatus.Loaded;
    },
    [fetchEmployeeDropDown.rejected]: (state, action) => {
      state.employeeDropDownStatus = LoadingStatus.Failed;
    },
    [fetchTaskManagerDropDown.pending]: (state, action) => {
      state.taskManagerDropDownStatus = LoadingStatus.Loading;
    },
    [fetchTaskManagerDropDown.fulfilled]: (state, action) => {
      state.dropDownInfo = action.payload.pageInfo;
      state.dropDowns.taskManagers = action?.payload?.dropDowns.map((item) => {
        return { value: +item.value, text: item.text };
      });
      state.taskManagerDropDownStatus = LoadingStatus.Loaded;
    },
    [fetchTaskManagerDropDown.rejected]: (state, action) => {
      state.taskManagerDropDownStatus = LoadingStatus.Failed;
    },
    [fetchPayPlansDropDown.pending]: (state, action) => {
      state.payPlanDropDownStatus = LoadingStatus.Loading;
    },
    [fetchPayPlansDropDown.fulfilled]: (state, action) => {
      state.dropDownInfo = action.payload.pageInfo;
      state.dropDowns.payPlans = action?.payload?.dropDowns.map((item) => {
        return { value: +item.value, text: item.text };
      });
      state.payPlanDropDownStatus = LoadingStatus.Loaded;
    },
    [fetchPayPlansDropDown.rejected]: (state, action) => {
      state.payPlanDropDownStatus = LoadingStatus.Failed;
    },
    [fetchPayGradesDropDown.pending]: (state, action) => {
      state.payGradeDropDownStatus = LoadingStatus.Loading;
    },
    [fetchPayGradesDropDown.fulfilled]: (state, action) => {
      state.dropDownInfo = action.payload.pageInfo;
      state.dropDowns.payGrades = action?.payload?.dropDowns.map((item) => {
        return { value: +item.value, text: item.text };
      });
      state.payGradeDropDownStatus = LoadingStatus.Loaded;
    },
    [fetchPayGradesDropDown.rejected]: (state, action) => {
      state.payGradeDropDownStatus = LoadingStatus.Failed;
    },
    [fetchSystemRolesDropDown.pending]: (state, action) => {
      state.systemRolesDropDownStatus = LoadingStatus.Loading;
    },
    [fetchSystemRolesDropDown.fulfilled]: (state, action) => {
      state.dropDownInfo = action.payload.pageInfo;
      state.dropDowns.systemRoles = action?.payload?.dropDowns.map((item) => {
        return { value: +item.value, text: item.text };
      });
      state.systemRolesDropDownStatus = LoadingStatus.Loaded;
    },
    [fetchSystemRolesDropDown.rejected]: (state, action) => {
      state.systemRolesDropDownStatus = LoadingStatus.Failed;
    },
    [fetchSystemRolesForEmployeeDropDown.pending]: (state, action) => {
      state.systemRolesForEmployeeDropDownStatus = LoadingStatus.Loading;
    },
    [fetchSystemRolesForEmployeeDropDown.fulfilled]: (state, action) => {
      state.dropDownInfo = action.payload.pageInfo;
      state.dropDowns.systemRolesForEmployee = action?.payload?.dropDowns.map(
        (item) => {
          return { value: +item.value, text: item.text };
        }
      );
      state.systemRolesForEmployeeDropDownStatus = LoadingStatus.Loaded;
    },
    [fetchSystemRolesForEmployeeDropDown.rejected]: (state, action) => {
      state.systemRolesForEmployeeDropDownStatus = LoadingStatus.Failed;
    },
    [fetchReportDropDown.pending]: (state, action) => {
      state.reportsDropDownStatus = LoadingStatus.Loading;
    },
    [fetchReportDropDown.fulfilled]: (state, action) => {
      state.dropDownInfo = action.payload.pageInfo;
      state.dropDowns.reports = action?.payload?.dropDowns.map((item) => {
        return { value: +item.value, text: item.text };
      });
      state.reportsDropDownStatus = LoadingStatus.Loaded;
    },
    [fetchReportDropDown.rejected]: (state, action) => {
      state.reportsDropDownStatus = LoadingStatus.Failed;
    },
    [fetchCostCenterDropDown.pending]: (state, action) => {
      state.costCenterDropDownStatus = LoadingStatus.Loading;
    },
    [fetchCostCenterDropDown.fulfilled]: (state, action) => {
      state.dropDownInfo = action.payload.pageInfo;
      state.dropDowns.costCenter = action?.payload?.dropDowns.map((item) => {
        return { value: +item.value, text: item.text };
      });
      state.costCenterDropDownStatus = LoadingStatus.Loaded;
    },
    [fetchCostCenterDropDown.rejected]: (state, action) => {
      state.costCenterDropDownStatus = LoadingStatus.Failed;
    },
    [fetchReportExpirationDropDown.pending]: (state, action) => {
      state.reportExpirationDropDownStatus = LoadingStatus.Loading;
    },
    [fetchReportExpirationDropDown.fulfilled]: (state, action) => {
      state.dropDownInfo = action.payload.pageInfo;
      state.dropDowns.reportExpiration = action?.payload?.dropDowns.map(
        (item) => {
          return { value: +item.value, text: item.text };
        }
      );
      state.reportExpirationDropDownStatus = LoadingStatus.Loaded;
    },
    [fetchReportExpirationDropDown.rejected]: (state, action) => {
      state.reportExpirationDropDownStatus = LoadingStatus.Failed;
    },
    [fetchEmployeeStatusDropDown.pending]: (state, action) => {
      state.employeeStatusDropDownStatus = LoadingStatus.Loading;
    },
    [fetchEmployeeStatusDropDown.fulfilled]: (state, action) => {
      state.dropDownInfo = action.payload.pageInfo;
      state.dropDowns.employeeStatus = action?.payload?.dropDowns.map(
        (item) => {
          return { value: +item.value, text: item.text };
        }
      );
      state.employeeStatusDropDownStatus = LoadingStatus.Loaded;
    },
    [fetchEmployeeStatusDropDown.rejected]: (state, action) => {
      state.employeeStatusDropDownStatus = LoadingStatus.Failed;
    },
    [fetchApiRestrictionDropDown.pending]: (state, action) => {
      state.apiRestrictionDropDownStatus = LoadingStatus.Loading;
    },
    [fetchApiRestrictionDropDown.fulfilled]: (state, action) => {
      state.dropDownInfo = action.payload.pageInfo;
      state.dropDowns.clientApiRestriction = action?.payload?.dropDowns.map(
        (item) => {
          return { value: +item.value, text: item.text };
        }
      );
      state.apiRestrictionDropDownStatus = LoadingStatus.Loaded;
    },
    [fetchApiRestrictionDropDown.rejected]: (state, action) => {
      state.apiRestrictionDropDownStatus = LoadingStatus.Failed;
    },
    [fetchOfficeByCostCenterId.pending]: (state, action) => {
      state.officeByCostCenterIdDropDownStatus = LoadingStatus.Loading;
    },
    [fetchOfficeByCostCenterId.fulfilled]: (state, action) => {
      state.dropDownInfo = action.payload.pageInfo;
      state.dropDowns.officeByCostCenterId = action?.payload?.dropDowns.map(
        (item) => {
          return { value: +item.value, text: item.text };
        }
      );
      state.officeByCostCenterIdDropDownStatus = LoadingStatus.Loaded;
    },
    [fetchOfficeByCostCenterId.rejected]: (state, action) => {
      state.officeByCostCenterIdDropDownStatus = LoadingStatus.Failed;
    },
    [fetchAllEmployeesDropDown.pending]: (state, action) => {
      state.allEmployeesDropdownStatus = LoadingStatus.Loading;
    },
    [fetchAllEmployeesDropDown.fulfilled]: (state, action) => {
      state.dropDownInfo = action.payload.pageInfo;
      state.dropDowns.allEmployees = action?.payload?.dropDowns.map((item) => {
        return { value: +item.value, text: item.text };
      });
      state.allEmployeesDropdownStatus = LoadingStatus.Loaded;
    },
    [fetchAllEmployeesDropDown.rejected]: (state, action) => {
      state.allEmployeesDropdownStatus = LoadingStatus.Failed;
    },
    [fetchCommandDropDown.pending]: (state, action) => {
      state.commandDropdownStatus = LoadingStatus.Loading;
    },
    [fetchCommandDropDown.fulfilled]: (state, action) => {
      state.dropDowns.command = action?.payload?.dropDowns.map((item) => {
        return { value: +item.value, text: item.text };
      });
      state.commandDropdownStatus = LoadingStatus.Loaded;
    },
    [fetchCommandDropDown.rejected]: (state, action) => {
      state.commandDropdownStatus = LoadingStatus.Failed;
    },

    [fetchDepotDropDown.pending]: (state, action) => {
      state.depotDrowpdownStatus = LoadingStatus.Loading;
    },
    [fetchDepotDropDown.fulfilled]: (state, action) => {
      state.dropDowns.depots = action?.payload?.dropDowns.map((item) => {
        return { value: +item.value, text: item.text };
      });
      state.depotDrowpdownStatus = LoadingStatus.Loaded;
    },
    [fetchDepotDropDown.rejected]: (state, action) => {
      state.depotDrowpdownStatus = LoadingStatus.Failed;
    },
    [fetchPercentageDropDown.pending]: (state, action) => {
      state.reportPercentageDropdownStatus = LoadingStatus.Loading;
    },
    [fetchPercentageDropDown.fulfilled]: (state, action) => {
      state.dropDowns.reportPercentage = action?.payload?.dropDowns.map(
        (item) => {
          return { value: +item.value, text: item.text };
        }
      );
      state.reportPercentageDropdownStatus = LoadingStatus.Loaded;
    },
    [fetchPercentageDropDown.rejected]: (state, action) => {
      state.reportPercentageDropdownStatus = LoadingStatus.Failed;
    },
  },
});

export const selectJobDropDown = (state) => state.dropDowns.dropDowns.jobs;
export const selectLevelDropDown = (state) => state.dropDowns.dropDowns.levels;
export const selectSeriesDropDown = (state) => state.dropDowns.dropDowns.series;
export const selectJobRoleDropDown = (state) =>
  state.dropDowns.dropDowns.jobRoles;
export const selectDirectReportsDropDown = (state) =>
  state.dropDowns.dropDowns.directReports;
export const selectSkillExpiryDropDown = (state) =>
  state.dropDowns.dropDowns.skillExpiry;
export const selectSkillsDropDown = (state) => state.dropDowns.dropDowns.skills;
export const selectCapabilitiesDropDown = (state) =>
  state.dropDowns.dropDowns.capabilities;
export const selectJobRoleByJobIdDropDown = (state) =>
  state.dropDowns.dropDowns.jobRolesById;
export const selectSuperVisorDropDown = (state) =>
  state.dropDowns.dropDowns.supervisors;
export const selectTaskManagerDropDown = (state) =>
  state.dropDowns.dropDowns.taskManagers;
export const selectPayPlansDropdown = (state) =>
  state.dropDowns.dropDowns.payPlans;
export const selectPayGradesDropdown = (state) =>
  state.dropDowns.dropDowns.payGrades;
export const selectSystemRolesDropdown = (state) =>
  state.dropDowns.dropDowns.systemRoles;
export const selectEmployeeDropDown = (state) =>
  state.dropDowns.dropDowns.employees;
export const selectSystemRolesForEmployeeDropdown = (state) =>
  state.dropDowns.dropDowns.systemRolesForEmployee;
export const selectReportsDropDown = (state) =>
  state.dropDowns.dropDowns.reports;
export const selectCostCenterDropDown = (state) =>
  state.dropDowns.dropDowns.costCenter;
export const selectReportExpiryDropDown = (state) =>
  state.dropDowns.dropDowns.reportExpiration;
export const selectEmployeeStatusDropDown = (state) =>
  state.dropDowns.dropDowns.employeeStatus;
export const selectApiRestrictionDropDown = (state) =>
  state.dropDowns.dropDowns.clientApiRestriction;
export const selectOfficeByCostCenterIdDropDown = (state) =>
  state.dropDowns.dropDowns.officeByCostCenterId;
export const selectAllEmployeeDropDown = (state) =>
  state.dropDowns.dropDowns.allEmployees;
export const selectCommondDropDown = (state) =>
  state.dropDowns.dropDowns.command;
export const selectDepotDropDown = (state) => state.dropDowns.dropDowns.depots;
export const selectReportPercentageDropDown = (state) =>
  state.dropDowns.dropDowns.reportPercentage;

export const getDropDownStatus = (state) => state.dropDowns.dropDownStatus;
export const getJobDropDownStatus = (state) =>
  state.dropDowns.jobDropDownStatus;
export const getLevelDropDownStatus = (state) =>
  state.dropDowns.levelDropDownStatus;
export const getSeriesDropDownStatus = (state) =>
  state.dropDowns.seriesDropDownStatus;
export const getJobRolesDropDownStatus = (state) =>
  state.dropDowns.jobRolesDropDownStatus;
export const getDirectReportsDropDownStatus = (state) =>
  state.dropDowns.directReportsDropDownStatus;
export const getSkillExpiryDropDownStatus = (state) =>
  state.dropDowns.skillExpiryDropDownStatus;
export const getSkillsDropDownStatus = (state) =>
  state.dropDowns.skillDropDownStatus;
export const getCapabilityDropDownStatus = (state) =>
  state.dropDowns.capabilityDropDownStatus;
export const getJobRolesByJobIdDropDownStatus = (state) =>
  state.dropDowns.jobRolesByJobIdDropDownStatus;
export const getSuperVisorDropDownStatus = (state) =>
  state.dropDowns.superVisorDropDownStatus;
export const getTaskManagerDropDownStatus = (state) =>
  state.dropDowns.taskManagerDropDownStatus;
export const getPayPlansDropDownStatus = (state) =>
  state.dropDowns.payPlanDropDownStatus;
export const getPayGradesDropDownStatus = (state) =>
  state.dropDowns.payGradeDropDownStatus;
export const getSystemRolesDropDownStatus = (state) =>
  state.dropDowns.systemRolesDropDownStatus;
export const getEmployeeDropDownStatus = (state) =>
  state.dropDowns.employeeDropDownStatus;
export const getSystemRolesForEmployeeDropDownStatus = (state) =>
  state.dropDowns.systemRolesForEmployeeDropDownStatus;
export const getReportsDropDownStatus = (state) =>
  state.dropDowns.reportsDropDownStatus;
export const getCostCenterDropDownStatus = (state) =>
  state.dropDowns.costCenterDropDownStatus;
export const getReportExpiryDropDownStatus = (state) =>
  state.dropDowns.reportExpirationDropDownStatus;
export const getEmployeeStatusDropDownStatus = (state) =>
  state.dropDowns.employeeStatusDropDownStatus;
export const getApiRestrictionDropDownStatus = (state) =>
  state.dropDowns.apiRestrictionDropDownStatus;
export const getOfficeByCostCenterIdDropDownStatus = (state) =>
  state.dropDowns.officeByCostCenterIdDropDownStatus;
export const getAllEmployeeDropDownStatus = (state) =>
  state.dropDowns.allEmployeesDropdownStatus;
export const getCommandDropDownStatus = (state) =>
  state.dropDowns.commandDropdownStatus;
export const getDepotDropDownStatus = (state) =>
  state.dropDowns.depotDrowpdownStatus;
export const getReportPercentageDropDownStatus = (state) =>
  state.dropDowns.reportPercentageDropdownStatus;

export const getDropDownPageInfo = (state) => state.dropDowns.dropDownInfo;
export const getJobRoleUsage = (state) => state.jobRoles.jobRoleUsage;

export const getSelectedDropDowns = (state) => state.dropDowns.filterDropdowns;
export const getMultiSelectedDropDown = (state) =>
  state.dropDowns.multiSelectDropDown;
export const getLevelDropdownOpen = (state) =>
  state.dropDowns.isLevelDropdownOpen;

export const {
  resetDropDownState,
  setSelectedDropDown,
  setMultiDropDownState,
  resetDropDown,
  resetNewJobDropDown,
  resetOfficeIdDropDown,
  resetJobRoleDropdown,
  resetDepotDropDownState,
  resetCommandDropDownState,
  resetLevelExpiryState,
  setIsLevelDropdownOpen,
} = dropDownSlice.actions;

export default dropDownSlice.reducer;
