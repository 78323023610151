import { config } from 'ectl-config';
import { FormDataUtils, getNetworkService } from 'helpers';
import { createProgressParams } from 'helpers/progressUtils';
import { buildFilters } from 'hooks/buildFilter';

const accountService = { config: config };
const networkService = getNetworkService({
  config: config,
  checkAuth: accountService.checkAuth,
});
const baseUrl = `${config.apiUrl}/Admin/Skill`;
const capUrl = `${config.apiUrl}/Admin/CapabilityJoin/help`;

const getSkills = async (
  startIndex = 0,
  stopIndex = 10,
  searchString = '',
  sort = 'display_name',
  displayName = '',
  capabilityId = null,
  job_id = null,
  job_role_id = null
) => {
  const filters = buildFilters(
    ['quick_search', searchString],
    ['display_name', displayName],
    ['capability_id', capabilityId],
    ['job_id', job_id],
    ['job_role_id', job_role_id]
  );

  const params = {
    filters: filters,
    sorts: sort || '',
    start_index: startIndex,
    stop_index: stopIndex,
  };
  const query = new URLSearchParams(params);
  const response = await networkService.get(`${baseUrl}/?${query}`);
  const pageInfo = response.headers['x-pagination'];

  return {
    pageInfo: pageInfo,
    skills: response?.data,
  };
};

const create = async (params) => {
  const response = await networkService.post(baseUrl, params);

  if (response.error) {
    return Promise.reject(response.error);
  }

  return response;
};

const update = async (params) => {
  const response = await networkService.put(
    `${baseUrl}/${params.skill_id}`,
    params
  );

  if (response.error) {
    return Promise.reject(response.error);
  }

  return response.data;
};

const _delete = async (skill_id) => {
  try {
    const response = await networkService.delete(`${baseUrl}/${skill_id}`);
    return response;
  } catch (e) {
    return Promise.reject(e.error);
  }
};

const getById = async (id) => {
  const response = await networkService.get(`${baseUrl}/${id}`);
  return response.data;
};

const deleteSkillArray = async (ids) => {
  try {
    const deleteObject = {
      ids: ids,
    };
    const response = await networkService.deleteWithBody(baseUrl, deleteObject);
    return response.data;
  } catch (e) {
    return Promise.reject(e.error);
  }
};

const getSkillUsageById = async (tagId) => {
  const response = await networkService.get(`${baseUrl}/${tagId}`);
  return response.data;
};

const getSkillImportCsv = async () => {
  try {
    const response = await networkService.get(`${baseUrl}/import`);
    return response?.data;
  } catch (err) {
    return Promise.reject(err.error);
  }
};

const uploadSkillImportCsv = async (params) => {
  try {
    let formData = new FormData();
    FormDataUtils.safeAppend(formData, [['file', params.file]]);
    let progressParams = createProgressParams(params);
    const response = await networkService.postMultiFormData(
      `${baseUrl}/import`,
      formData,
      progressParams
    );
    return response.data;
  } catch (err) {
    return err;
  }
};

const exportSkillCsv = async (displayName = '', capabilityId = null) => {
  try {
    const filters = [];

    if (displayName) {
      filters.push(`display_name@=*${displayName}`);
    }
    if (capabilityId) {
      filters.push(`capability_id==${capabilityId}`);
    }

    const params = {
      filters: filters.join(','),
    };

    const query = new URLSearchParams(params);
    const response = await networkService.get(`${baseUrl}/export?${query}`);
    return response?.data;
  } catch (err) {
    return Promise.reject(err.error);
  }
};

const getSkillImportHelp = async (skill_id = null) => {
  try {
    const filters = [];
    if (skill_id) {
      filters.push(`skill_id==${skill_id}`);
    }
    const params = {
      filters: filters.join(','),
    };
    const query = new URLSearchParams(params);
    if (skill_id) {
      const response = await networkService.get(`${baseUrl}/help/?${query}`);
      return response?.data;
    }
    const response = await networkService.get(`${baseUrl}/help`);
    return response?.data;
  } catch (err) {
    return Promise.reject(err.error);
  }
};

const getSkillCapImportHelp = async (capability_id = null) => {
  try {
    const filters = [];
    if (capability_id) {
      filters.push(`capability_id==${capability_id}`);
    }
    const params = {
      filters: filters.join(','),
    };
    const query = new URLSearchParams(params);
    if (capability_id) {
      const response = await networkService.get(`${capUrl}/?${query}`);
      return response?.data;
    }
    const response = await networkService.get(`${capUrl}`);
    return response?.data;
  } catch (err) {
    return Promise.reject(err.error);
  }
};

const getAssociateSkills = async (skill_id = null) => {
  try {
    if (skill_id) {
      const response = await networkService.get(
        `${baseUrl}/Capability/${skill_id}`
      );
      return response?.data;
    }
  } catch (err) {
    return Promise.reject(err.error);
  }
};

const updateCapabilitySkills = async (params) => {
  try {
    const response = await networkService.put(
      `${baseUrl}/Capability/${params.skill_id}`,
      params?.skillData
    );

    return response.data;
  } catch (err) {
    return Promise.reject(err.error);
  }
};

export const skillService = {
  getSkills,
  create,
  update,
  delete: _delete,
  deleteSkillArray,
  getById,
  getSkillUsageById,
  getSkillImportCsv,
  uploadSkillImportCsv,
  exportSkillCsv,
  getSkillImportHelp,
  getSkillCapImportHelp,
  getAssociateSkills,
  updateCapabilitySkills,
};
