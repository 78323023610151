import React, { useState, useRef, useEffect, useCallback } from 'react';
import { AutoSizer } from 'react-virtualized';
import IconButton from '@mui/material/IconButton';
import { Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import pluralize from 'pluralize';
import { MdAdd, MdRefresh } from 'react-icons/md';

import SearchWithFilters from 'components/SearchWithFilters';
import {
  actionCell,
  checkCell,
  checkHeaderCell,
  clipLabelTooltipCell,
  labelCell,
  labelHeader,
  tagTypeCellWithToolTip,
} from 'shared/components/_Tables/TableCells';
import { makeButtonStyles } from 'components/styles';
import { VirtualTable } from 'shared/components/_Tables/VirtualTable';
import { EnhancedTableToolbar } from 'components/EnhancedTableToolbar';
import { tableStyles } from 'shared/components/_Tables/VirtualTable/styles';
import { Action } from 'helpers/action';
import { mdTheme } from 'theme';
import { PopupAction } from 'components/_Misc/PopupAction/components/PopupAction';
import {
  closePanel,
  openPanel,
  setEditObjectId,
} from 'features/common/formSlice';
import {
  exportJobCsv,
  fetchJobs,
  getImportJobHelp,
  getJobInfoStatus,
  getJobSliceImportSampleCsv,
  getJobStatus,
  resetJobInfoState,
  resetJobState,
  uploadJobSliceImportCsv,
} from 'features/job/jobSlice';
import { NoDataMessage } from 'shared/components/_Tables/NoDataMessage';
import { JOB_TABLE_KEYS, LoadingStatus, NO_MATCHING_JOBS } from 'helpers';
import { ItemJobFilter } from '../itemJobFilter';
import { selectActiveUser } from 'features/account/accountSlice';
import { DropZoneImport } from 'shared/components/_DropZoneImport';
import { padNumberWithZeros } from 'hooks/PadNumberWithZeros';
import { mapValues } from 'helpers/mapValues';
import { useDebounce } from 'hooks/useDebounce';
import { CustomDialog } from 'shared/components/_Modal';
import {
  fetchJobsDropDown,
  getJobDropDownStatus,
  selectJobDropDown,
} from 'features/AdminSettingsDropDown/dropDownSlice';

function ItemJobTable({
  jobs,
  pageInfo,
  itemJobPayload,
  setItemJobPayload,
  sortDirection,
  sortBy,
  handleSort,
}) {
  const dispatch = useDispatch();
  const buttonStyles = makeButtonStyles(mdTheme);
  const tableClasses = tableStyles();
  let updatedPayload = mapValues(itemJobPayload);

  const [selected, setSelected] = useState([]);
  const [selectedAll, setSelectedAll] = React.useState(false);
  const [openHelpDialog, setOpenHelpDialog] = useState(false);
  const getInfoStatus = useSelector(getJobInfoStatus);
  const loadedRowsMap = useSelector((state) => state.jobs.loadedJobsMap);
  const jobStatus = useSelector(getJobStatus);
  const activeUser = useSelector(selectActiveUser);
  const hideJobIdColumn = activeUser?.hide_system_ids;
  const jobColumnIds = activeUser?.admin_field_ids;

  const headerHeight = 60;
  const rowHeight = 60;

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const prevSelectedAll = usePrevious(selectedAll);
  if (prevSelectedAll && selectedAll) {
    if (selected.length !== jobs?.length) {
      setSelected(jobs.map((row) => row.job_id));
      setSelectedAll(true);
    }
  }

  const onRefreshIconClick = async () => {
    await dispatch(fetchJobs({ ...updatedPayload }));
  };

  const handleClick = (event, job_id) => {
    event.stopPropagation();
    const selectedIndex = selected.indexOf(job_id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, job_id);
    } else {
      newSelected = selected.filter((id) => id !== job_id);
    }
    setSelected(newSelected);
    setSelectedAll(newSelected.length === jobs?.length);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelectedIds = jobs.map((tag) => tag.job_id);
      setSelected(newSelectedIds);
      setSelectedAll(true);
      return;
    }
    setSelected([]);
    setSelectedAll(false);
  };

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const handleRowClick = async ({ rowData }) => {
    await dispatch(closePanel({ formKey: 'itemJobForm' }));
    if (rowData?.job_id) {
      await dispatch(
        setEditObjectId({
          formKey: `itemJobForm`,
          object_id: rowData?.job_id,
        })
      );
      dispatch(openPanel({ formKey: `itemJobForm`, formAction: 'Edit' }));
    }
  };
  const init_columns = [
    {
      classes: tableClasses,
      minWidth: 50,
      maxWidth: 50,
      width: 50,
      label: '',
      dataKey: 'job_id',
      padding: 'checkbox',
      cellLevel: 'job',
      numSelected: selected?.length,
      rowCount: jobs?.length,
      handleClick: handleClick,
      handleSelectAllClick: handleSelectAllClick,
      isSelected: isSelected,
      component: checkCell,
      headerComponent: checkHeaderCell,
    },
    {
      classes: tableClasses,
      minWidth: 50,
      maxWidth: 50,
      width: 50,
      label: '',
      dataKey: 'job_id',
      padding: 'none',
      actionCellLevel: 'job',
      targetPopup: 'jobManagement',
      formKey: 'itemJobForm',
      dispatch: dispatch,
      component: actionCell,
      headerComponent: labelHeader,
    },
  ];

  const handleColumnVisibility = (key, column) => {
    if (hideJobIdColumn && key === 'job_id') {
      return undefined;
    }

    const isVisible = jobColumnIds?.includes(JOB_TABLE_KEYS?.[key]?.id);
    if (isVisible) {
      return column;
    }
  };

  const columns = [
    ...init_columns,
    handleColumnVisibility('job_id', {
      classes: tableClasses,
      width: 120,
      minWidth: 120,
      flexGrow: 1,
      label: `${activeUser?.label_job} Id`,
      dataKey: 'job_id',
      padding: 'normal',
      component: clipLabelTooltipCell,
      headerComponent: labelHeader,
      sortDirection: sortDirection,
      sortBy: sortBy,
      handleSort: handleSort,
    }),
    handleColumnVisibility('display_name', {
      classes: tableClasses,
      flexGrow: 1,
      width: 180,
      minWidth: 180,
      label: 'Name',
      dataKey: 'display_name',
      padding: 'normal',
      component: clipLabelTooltipCell,
      headerComponent: labelHeader,
      sortDirection: sortDirection,
      sortBy: sortBy,
      handleSort: handleSort,
    }),
    handleColumnVisibility('series_id', {
      classes: tableClasses,
      flexGrow: 1,
      width: 140,
      minWidth: 140,
      maxWidth: 140,
      label: 'Series Id',
      dataKey: 'series_id',
      padding: 'normal',
      component: labelCell,
      headerComponent: labelHeader,
      transform: (data) => padNumberWithZeros(data),
      sortDirection: sortDirection,
      sortBy: sortBy,
      handleSort: handleSort,
    }),
    handleColumnVisibility('family_display_name', {
      classes: tableClasses,
      flexGrow: 1,
      width: 180,
      minWidth: 180,
      label: 'Family Name',
      dataKey: 'family_display_name',
      padding: 'normal',
      component: clipLabelTooltipCell,
      headerComponent: labelHeader,
      sortDirection: sortDirection,
      sortBy: sortBy,
      handleSort: handleSort,
    }),
    handleColumnVisibility('description', {
      classes: tableClasses,
      width: 250,
      minWidth: 250,
      maxWidth: 250,
      label: 'Description',
      dataKey: 'description',
      padding: 'normal',
      component: clipLabelTooltipCell,
      headerComponent: labelHeader,
    }),
    handleColumnVisibility('family_series_id', {
      classes: tableClasses,
      flexGrow: 1,
      width: 120,
      minWidth: 180,
      label: 'Family Series Id',
      dataKey: 'family_series_id',
      padding: 'normal',
      component: labelCell,
      headerComponent: labelHeader,
      transform: (data) => padNumberWithZeros(data),
      sortDirection: sortDirection,
      sortBy: sortBy,
      handleSort: handleSort,
    }),
    handleColumnVisibility('job_role_count', {
      classes: tableClasses,
      flexGrow: 1,
      width: 200,
      minWidth: 200,
      label: `${pluralize(activeUser?.label_job_role, jobs?.length)}`,
      dataKey: 'job_role_count',
      displayDataKey: 'job_role_count',
      toolTipKey: 'job_role_names',
      padding: 'none',
      component: tagTypeCellWithToolTip,
      headerComponent: labelHeader,
      sortDirection: sortDirection,
      sortBy: sortBy,
      handleSort: handleSort,
    }),
  ].filter(Boolean);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    useDebounce((searchString) => {
      setItemJobPayload({
        ...itemJobPayload,
        searchString: searchString,
      });
    }, 1000),
    [itemJobPayload]
  );

  const handleCreateNew = () => {
    dispatch(openPanel({ formKey: 'itemJobForm', formAction: 'Create' }));
  };

  return (
    <div className={tableClasses.root}>
      <ItemJobFilter
        setItemJobPayload={setItemJobPayload}
        itemJobPayload={itemJobPayload}
      />
      {selected.length > 0 && (
        <EnhancedTableToolbar
          numSelected={selected.length}
          toolTipTitle={'Delete'}
          totalCount={pageInfo?.TotalCount}
        >
          <PopupAction
            action={Action.DeleteArray}
            object={{ jobIds: selected, setJobIds: setSelected }}
            level={'job'}
            showLabel={false}
          />
        </EnhancedTableToolbar>
      )}
      <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
        <Tooltip title="Add New Job">
          <IconButton
            onClick={handleCreateNew}
            size="large"
            className={buttonStyles.contentAddButton}
          >
            <MdAdd />
          </IconButton>
        </Tooltip>
        <div style={{ flexGrow: 1 }}></div>
        <DropZoneImport
          key="job-dzi"
          getEntityImportSample={getJobSliceImportSampleCsv}
          uploadEntityState={uploadJobSliceImportCsv}
          resetEntitySelector={resetJobState}
          mainTitle={activeUser?.label_job}
          exportEntityState={exportJobCsv}
          exportFilterData={updatedPayload}
          setOpenHelpDialog={setOpenHelpDialog}
          isExport
          isInfoIcon
        />
        <CustomDialog
          getEntityHelp={getImportJobHelp}
          fieldName="job_id"
          selectDropdownEntity={selectJobDropDown}
          getDropdownStatusEntity={getJobDropDownStatus}
          fetchDropDownEntityPage={fetchJobsDropDown}
          openHelpDialog={openHelpDialog}
          setOpenHelpDialog={setOpenHelpDialog}
          getEntityHelpStatus={getInfoStatus}
          selectLabel="Select Job"
          resetHelpEntity={resetJobInfoState}
        />
        <IconButton
          onClick={onRefreshIconClick}
          aria-label="close"
          sx={{
            color: '#707070',
          }}
          size="large"
        >
          <MdRefresh style={{ fontSize: '18px', color: 'black' }} />
        </IconButton>
        <SearchWithFilters
          filter={false}
          onChange={(evt) => debouncedSearch(evt.target.value)}
          value={itemJobPayload?.searchString}
        />
      </div>
      {jobs.length > 0 ? (
        <div
          style={{
            height: 'calc(100vh - 295px)',
            minWidth: '500px',
            paddingTop: '2rem',
          }}
        >
          <AutoSizer>
            {({ width, height }) => (
              <VirtualTable
                initialRows={jobs}
                allRows={jobs}
                totalRowCount={pageInfo?.TotalCount}
                loadedRowsMap={loadedRowsMap}
                loadMoreRows={loadMoreRows}
                columnDefinitions={columns}
                cellClassName={tableClasses.flexContainer}
                className={tableClasses.table}
                rowClassName={tableClasses.flexContainer}
                headerHeight={headerHeight}
                width={width}
                height={height}
                rowHeight={rowHeight}
                onRowClick={handleRowClick}
              />
            )}
          </AutoSizer>
        </div>
      ) : (
        <NoDataMessage message={NO_MATCHING_JOBS} />
      )}
    </div>
  );

  async function loadMoreRows({ startIndex, stopIndex }) {
    if (jobStatus !== LoadingStatus.Loading) {
      await dispatch(
        fetchJobs({
          ...updatedPayload,
          reset: false,
          startIndex: startIndex,
          stopIndex: stopIndex,
        })
      );
    }
  }
}

export { ItemJobTable };
