import React, { useState, useRef, useEffect, useCallback } from 'react';
import { AutoSizer } from 'react-virtualized';
import IconButton from '@mui/material/IconButton';
import { Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { MdAdd, MdRefresh } from 'react-icons/md';
import pluralize from 'pluralize';

import SearchWithFilters from 'components/SearchWithFilters';
import {
  actionCell,
  checkCell,
  checkHeaderCell,
  clipLabelTooltipCell,
  labelCell,
  labelHeader,
  tagTypeCellWithToolTip,
} from 'shared/components/_Tables/TableCells';
import { makeButtonStyles } from 'components/styles';
import { VirtualTable } from 'shared/components/_Tables/VirtualTable';
import { EnhancedTableToolbar } from 'components/EnhancedTableToolbar';
import { tableStyles } from 'shared/components/_Tables/VirtualTable/styles';
import { Action } from 'helpers/action';
import { mdTheme } from 'theme';
import { PopupAction } from 'components/_Misc/PopupAction/components/PopupAction';
import {
  closePanel,
  openPanel,
  setEditObjectId,
} from 'features/common/formSlice';
import {
  exportJobRoleCsv,
  fetchJobRoles,
  getImportCapSkillHelp,
  getImportJobRoleHelp,
  getJobRoleCapSkillInfoStatus,
  getJobRoleImportSampleCsv,
  getJobRoleInfoStatus,
  getJobRoleStatus,
  resetJobRoleCapSkillInfoState,
  resetJobRoleInfoState,
  resetJobRoleState,
  uploadJobRoleImportCsv,
} from 'features/jobRole/jobRoleSlice';
import { NoDataMessage } from 'shared/components/_Tables/NoDataMessage';
import {
  JOB_ROLE_TABLE_KEYS,
  LoadingStatus,
  NO_MATCHING_JOB_ROLES,
} from 'helpers';
import { ItemJobRoleFilter } from '../ItemJobRoleFilter';
import { DropZoneImport } from 'shared/components/_DropZoneImport';
import { padNumberWithZeros } from 'hooks/PadNumberWithZeros';
import { mapValues } from 'helpers/mapValues';
import { useDebounce } from 'hooks/useDebounce';
import {
  capabilitySkillsCsvImport,
  getCapabilitySkillsSampleCsv,
  resetCapabilityState,
} from 'features/capability/capabilitySlice';
import { CustomDialog } from 'shared/components/_Modal';
import {
  fetchCapabilityDropDown,
  fetchJobRolesDropDown,
  getCapabilityDropDownStatus,
  getJobRolesDropDownStatus,
  selectCapabilitiesDropDown,
  selectJobRoleDropDown,
} from 'features/AdminSettingsDropDown/dropDownSlice';

function ItemJobRoleTable({
  jobRoles,
  pageInfo,
  itemJobRolePayload,
  setItemJobRolePayload,
  sortDirection,
  sortBy,
  handleSort,
  user,
}) {
  const dispatch = useDispatch();
  const buttonStyles = makeButtonStyles(mdTheme);
  const tableClasses = tableStyles();
  const [selected, setSelected] = useState([]);
  const [selectedAll, setSelectedAll] = React.useState(false);
  const [openHelpDialog, setOpenHelpDialog] = useState(false);
  const [dropZoneModalKey, setDropZoneModalKey] = useState(null);
  const getInfoStatus = useSelector(getJobRoleInfoStatus);
  const getCapSkillInfoStatus = useSelector(getJobRoleCapSkillInfoStatus);
  let updatedPayload = mapValues(itemJobRolePayload);
  const jobRoleStatus = useSelector(getJobRoleStatus);

  const loadedRowsMap = useSelector(
    (state) => state.jobRoles.loadedJobRolesMap
  );
  const hideJobRoleIdColumn = user?.hide_system_ids;
  const jobRoleColumnIds = user?.admin_field_ids;

  const headerHeight = 60;
  const rowHeight = 60;

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const prevSelectedAll = usePrevious(selectedAll);
  if (prevSelectedAll && selectedAll) {
    if (selected.length !== jobRoles?.length) {
      setSelected(jobRoles.map((row) => row.job_role_id));
      setSelectedAll(true);
    }
  }

  const onRefreshIconClick = async () => {
    await dispatch(fetchJobRoles({ ...itemJobRolePayload }));
  };

  const handleClick = (event, job_role_id) => {
    event.stopPropagation();
    const selectedIndex = selected.indexOf(job_role_id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, job_role_id);
    } else {
      newSelected = selected.filter((id) => id !== job_role_id);
    }
    setSelected(newSelected);
    setSelectedAll(newSelected.length === jobRoles?.length);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelectedIds = jobRoles.map((tag) => tag.job_role_id);
      setSelected(newSelectedIds);
      setSelectedAll(true);
      return;
    }
    setSelected([]);
    setSelectedAll(false);
  };

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const handleRowClick = async ({ rowData }) => {
    await dispatch(closePanel({ formKey: 'itemJobRoleForm' }));
    if (rowData?.job_role_id) {
      await dispatch(
        setEditObjectId({
          formKey: `itemJobRoleForm`,
          object_id: rowData?.job_role_id,
        })
      );
      dispatch(openPanel({ formKey: `itemJobRoleForm`, formAction: 'Edit' }));
    }
  };

  const init_columns = [
    {
      classes: tableClasses,
      minWidth: 50,
      maxWidth: 50,
      width: 50,
      label: '',
      dataKey: 'job_role_id',
      padding: 'checkbox',
      cellLevel: 'job_role',
      numSelected: selected?.length,
      rowCount: jobRoles?.length,
      handleClick: handleClick,
      handleSelectAllClick: handleSelectAllClick,
      isSelected: isSelected,
      component: checkCell,
      headerComponent: checkHeaderCell,
    },
    {
      classes: tableClasses,
      minWidth: 50,
      maxWidth: 50,
      width: 50,
      label: '',
      dataKey: 'job_role_id',
      padding: 'none',
      actionCellLevel: 'jobRole',
      targetPopup: 'jobRoleManagement',
      formKey: 'itemJobRoleForm',
      dispatch: dispatch,
      component: actionCell,
      headerComponent: labelHeader,
    },
  ];

  const handleColumnVisibility = (key, column) => {
    if (hideJobRoleIdColumn && key === 'job_role_id') {
      return undefined;
    }

    const isVisible = jobRoleColumnIds?.includes(
      JOB_ROLE_TABLE_KEYS?.[key]?.id
    );
    if (isVisible) {
      return column;
    }
  };

  const columns = [
    ...init_columns,
    handleColumnVisibility('job_role_id', {
      classes: tableClasses,
      width: 140,
      minWidth: 140,
      flexGrow: 1,
      label: `${user?.label_job_role} Id`,
      dataKey: 'job_role_id',
      padding: 'normal',
      component: labelCell,
      headerComponent: labelHeader,
      sortDirection: sortDirection,
      sortBy: sortBy,
      handleSort: handleSort,
    }),
    handleColumnVisibility('display_name', {
      classes: tableClasses,
      flexGrow: 1,
      width: 180,
      minWidth: 180,
      label: 'Name',
      dataKey: 'display_name',
      padding: 'normal',
      component: clipLabelTooltipCell,
      headerComponent: labelHeader,
      sortDirection: sortDirection,
      sortBy: sortBy,
      handleSort: handleSort,
    }),
    handleColumnVisibility('job_display_name', {
      classes: tableClasses,
      flexGrow: 1,
      width: 180,
      minWidth: 180,
      label: `${user?.label_job} Name`,
      dataKey: 'job_display_name',
      padding: 'normal',
      component: clipLabelTooltipCell,
      headerComponent: labelHeader,
      sortDirection: sortDirection,
      sortBy: sortBy,
      handleSort: handleSort,
    }),
    handleColumnVisibility('job_series_id', {
      classes: tableClasses,
      flexGrow: 1,
      width: 120,
      minWidth: 120,
      label: `${user?.label_job} Series Id`,
      dataKey: 'job_series_id',
      padding: 'normal',
      component: labelCell,
      headerComponent: labelHeader,
      transform: (data) => padNumberWithZeros(data),
      sortDirection: sortDirection,
      sortBy: sortBy,
      handleSort: handleSort,
    }),
    handleColumnVisibility('description', {
      classes: tableClasses,
      width: 250,
      minWidth: 250,
      maxWidth: 250,
      label: 'Description',
      dataKey: 'description',
      padding: 'normal',
      component: clipLabelTooltipCell,
      headerComponent: labelHeader,
    }),
    handleColumnVisibility('capability_count', {
      classes: tableClasses,
      flexGrow: 1,
      width: 200,
      minWidth: 200,
      label: `${pluralize(user?.label_capability, jobRoles?.length)}`,
      dataKey: 'capability_count',
      displayDataKey: 'capability_count',
      toolTipKey: 'capability_names',
      padding: 'none',
      dispatch: dispatch,
      formKey: 'associateJobRoleFormCapability',
      formId: 'job_role_id',
      component: tagTypeCellWithToolTip,
      headerComponent: labelHeader,
      sortDirection: sortDirection,
      sortBy: sortBy,
      handleSort: handleSort,
    }),
  ].filter(Boolean);

  // eslint-disable-next-line no-unused-vars

  async function loadMoreRows({ startIndex, stopIndex }) {
    if (jobRoleStatus !== LoadingStatus.Loading) {
      await dispatch(
        fetchJobRoles({
          ...updatedPayload,
          reset: false,
          startIndex: startIndex,
          stopIndex: stopIndex,
        })
      );
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    useDebounce((searchString) => {
      setItemJobRolePayload({
        ...itemJobRolePayload,
        searchString: searchString,
      });
    }, 1000),
    [itemJobRolePayload]
  );

  const handleCreateNew = () => {
    dispatch(openPanel({ formKey: 'itemJobRoleForm', formAction: 'Create' }));
  };

  const importInfoModalProps = useCallback(() => {
    if (dropZoneModalKey === user?.label_job_role) {
      return {
        getEntityHelp: getImportJobRoleHelp,
        fieldName: 'job_role_id',
        selectDropdownEntity: selectJobRoleDropDown,
        getDropdownStatusEntity: getJobRolesDropDownStatus,
        fetchDropDownEntityPage: fetchJobRolesDropDown,
        openHelpDialog: openHelpDialog,
        setOpenHelpDialog: setOpenHelpDialog,
        getEntityHelpStatus: getInfoStatus,
        selectLabel: 'Select JobRole',
        resetHelpEntity: resetJobRoleInfoState,
      };
    }
    if (
      dropZoneModalKey ===
      `Connect ${pluralize(user?.label_capability, 2)}/${pluralize(
        user?.label_skill,
        2
      )}`
    ) {
      return {
        getEntityHelp: getImportCapSkillHelp,
        fieldName: 'employee_id',
        selectDropdownEntity: selectCapabilitiesDropDown,
        getDropdownStatusEntity: getCapabilityDropDownStatus,
        fetchDropDownEntityPage: fetchCapabilityDropDown,
        openHelpDialog: openHelpDialog,
        setOpenHelpDialog: setOpenHelpDialog,
        getEntityHelpStatus: getCapSkillInfoStatus,
        selectLabel: 'Select Capability',
        resetHelpEntity: resetJobRoleCapSkillInfoState,
      };
    }
  }, [
    dropZoneModalKey,
    getCapSkillInfoStatus,
    getInfoStatus,
    openHelpDialog,
    user?.label_capability,
    user?.label_job_role,
    user?.label_skill,
  ]);

  return (
    <div className={tableClasses.root}>
      <ItemJobRoleFilter
        setItemJobRolePayload={setItemJobRolePayload}
        itemJobRolePayload={itemJobRolePayload}
      />
      {selected.length > 0 && (
        <EnhancedTableToolbar
          numSelected={selected.length}
          toolTipTitle={'Delete'}
          totalCount={pageInfo?.TotalCount}
        >
          <PopupAction
            action={Action.DeleteArray}
            object={{
              jobRoleIds: selected,
              setJobRoleIds: setSelected,
            }}
            level={'jobRole'}
            showLabel={false}
          />
        </EnhancedTableToolbar>
      )}
      <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
        <Tooltip title="Add New Job Role">
          <IconButton
            onClick={handleCreateNew}
            size="large"
            className={buttonStyles.contentAddButton}
          >
            <MdAdd />
          </IconButton>
        </Tooltip>
        <div style={{ flexGrow: 1 }}></div>
        <DropZoneImport
          key="capability-skill-dzi"
          getEntityImportSample={getCapabilitySkillsSampleCsv}
          uploadEntityState={capabilitySkillsCsvImport}
          resetEntitySelector={resetCapabilityState}
          mainTitle={`Connect ${pluralize(
            user?.label_capability,
            2
          )}/${pluralize(user?.label_skill, 2)}`}
          buttonText={`Connect ${pluralize(
            user?.label_capability,
            2
          )}/${pluralize(user?.label_skill, 2)}`}
          isInfoIcon
          setOpenHelpDialog={setOpenHelpDialog}
          setDropZoneModalKey={setDropZoneModalKey}
        />
        <DropZoneImport
          key="jobrole-dzi"
          getEntityImportSample={getJobRoleImportSampleCsv}
          uploadEntityState={uploadJobRoleImportCsv}
          resetEntitySelector={resetJobRoleState}
          mainTitle={user?.label_job_role}
          exportEntityState={exportJobRoleCsv}
          exportFilterData={updatedPayload}
          isExport
          setOpenHelpDialog={setOpenHelpDialog}
          isInfoIcon
          setDropZoneModalKey={setDropZoneModalKey}
        />
        <CustomDialog {...importInfoModalProps()} />
        <IconButton
          onClick={onRefreshIconClick}
          aria-label="close"
          sx={{
            color: '#707070',
          }}
          size="large"
        >
          <MdRefresh style={{ fontSize: '18px', color: 'black' }} />
        </IconButton>
        <SearchWithFilters
          filter={false}
          onChange={(evt) => debouncedSearch(evt.target.value)}
          value={itemJobRolePayload?.searchString}
        />
      </div>
      {jobRoles?.length > 0 ? (
        <div
          style={{
            height: 'calc(100vh - 295px)',
            minWidth: '500px',
            paddingTop: '2rem',
          }}
        >
          <AutoSizer>
            {({ width, height }) => (
              <VirtualTable
                initialRows={jobRoles}
                allRows={jobRoles}
                totalRowCount={pageInfo?.TotalCount}
                loadedRowsMap={loadedRowsMap}
                loadMoreRows={loadMoreRows}
                columnDefinitions={columns}
                cellClassName={tableClasses.flexContainer}
                className={tableClasses.table}
                rowClassName={tableClasses.flexContainer}
                headerHeight={headerHeight}
                width={width}
                height={height}
                rowHeight={rowHeight}
                onRowClick={handleRowClick}
              />
            )}
          </AutoSizer>
        </div>
      ) : (
        <NoDataMessage message={NO_MATCHING_JOB_ROLES} />
      )}
    </div>
  );
}

export { ItemJobRoleTable };
