import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { costCenterReportService } from 'features/services/costCenterReport.service';
import { LoadingStatus } from 'helpers';

const initialState = {
  status: LoadingStatus.Idle,
  error: null,
  costCenterReports: [],
  costCenterReportStatus: LoadingStatus.Idle,
  costCenterReportPageInfo: {},
  displayCostCenterReport: {},
  costCenterReportUsage: [],
  loadedCostCenterReportMap: {},
};

export const fetchCostCenterReport = createAsyncThunk(
  'costCenterReports/fetchCostCenterReport',
  async (params) =>
    await costCenterReportService.getCostCenterReports(
      params.depot_id,
      params.supervisor_employee_id,
      params.cost_center_id,
      params.job_id,
      params.job_role_id,
      params.report_expiry,
      params.custom_expiry_date,
      params.capability_id,
      params.office_id
    )
);

const costCenterReportSlice = createSlice({
  name: 'costCenterReports',
  initialState,
  reducers: {
    resetCostCenterReportState: (state) => initialState,
  },
  extraReducers: {
    [fetchCostCenterReport.pending]: (state, action) => {
      state.costCenterReportStatus = LoadingStatus.Loading;
    },
    [fetchCostCenterReport.fulfilled]: (state, action) => {
      state.costCenterReportStatus = LoadingStatus.Loaded;
      state.costCenterReports = action.payload;
    },
    [fetchCostCenterReport.rejected]: (state, action) => {
      state.costCenterReportStatus = LoadingStatus.Failed;
    },
  },
});

export const selectCostCenterReport = (state) =>
  state.costCenterReports.costCenterReports;
export const getCostCenterReportStatus = (state) =>
  state.costCenterReports.costCenterReportStatus;
export const getCostCenterPageInfo = (state) =>
  state.costCenterReports.costCenterPageInfo;

export const { resetCostCenterReportState } = costCenterReportSlice.actions;

export default costCenterReportSlice.reducer;
