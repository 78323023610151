import { Box, IconButton } from '@mui/material';
import Popover from '@mui/material/Popover';
import { MdMoreVert } from 'react-icons/md';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectPanelInfo } from 'features/common/editPanelSlice';
import { selectActionModalInfo } from 'features/common/actionModalSlice';
import { getContentVersionPanelVis } from 'features/global/visibilitySlice';
import { PartsManagementPopup } from './components/PartsManagementPopup';
import { SkillManagementPopup } from './components/SkillManagementPopup';
import { CapabilityManagementPopup } from './components/CapabilityManagementPopup';
import { JobRoleManagementPopup } from './components/JobRoleManagementPopup';

function PopupActions({ object, level, targetPopup, size = 'large' }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const editPanelInfo = useSelector(selectPanelInfo);
  const actionModalInfo = useSelector(selectActionModalInfo);
  const contentVersionVis = useSelector(getContentVersionPanelVis);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.preventDefault();
  };

  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  useEffect(() => {
    if (
      (editPanelInfo !== undefined && editPanelInfo.viewPanel) ||
      (actionModalInfo !== undefined && actionModalInfo.isOpen) ||
      contentVersionVis === true
    ) {
      setAnchorEl(null);
    }
  }, [editPanelInfo, actionModalInfo, contentVersionVis]);

  const handleClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div onClick={(event) => stopPropagation(event)}>
      <IconButton onClick={handleClick} size={size} sx={{ padding: '0.5rem' }}>
        <MdMoreVert />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Box p={2}>
          {targetPopup === 'payPlanManagement' && (
            <PartsManagementPopup
              object={object}
              level={level}
              onClose={handleClose}
            />
          )}
          {targetPopup === 'payGradeManagement' && (
            <PartsManagementPopup
              object={object}
              level={level}
              onClose={handleClose}
            />
          )}
          {targetPopup === 'skillManagement' && (
            <SkillManagementPopup
              object={object}
              level={level}
              onClose={handleClose}
            />
          )}
          {targetPopup === 'capabilityManagement' && (
            <CapabilityManagementPopup
              object={object}
              level={level}
              onClose={handleClose}
            />
          )}
          {targetPopup === 'jobManagement' && (
            <PartsManagementPopup
              object={object}
              level={level}
              onClose={handleClose}
            />
          )}
          {targetPopup === 'jobRoleManagement' && (
            <JobRoleManagementPopup
              object={object}
              level={level}
              onClose={handleClose}
            />
          )}
          {targetPopup === 'adminLevelManagement' && (
            <PartsManagementPopup
              object={object}
              level={level}
              onClose={handleClose}
            />
          )}
          {targetPopup === 'adminEmployeeManagement' && (
            <PartsManagementPopup
              object={object}
              level={level}
              onClose={handleClose}
            />
          )}
          {targetPopup === 'costCenterManagement' && (
            <PartsManagementPopup
              object={object}
              level={level}
              onClose={handleClose}
            />
          )}
          {targetPopup === 'externalApiManagement' && (
            <PartsManagementPopup
              object={object}
              level={level}
              onClose={handleClose}
            />
          )}
          {targetPopup === 'officeManagement' && (
            <PartsManagementPopup
              object={object}
              level={level}
              onClose={handleClose}
            />
          )}
        </Box>
      </Popover>
    </div>
  );
}

export { PopupActions };
