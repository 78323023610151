import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { skillService } from 'features/services/skill.service';
import { LoadingStatus, SavingStatus } from 'helpers';

const initialState = {
  status: LoadingStatus.Idle,
  error: null,
  skills: [],
  skillStatus: LoadingStatus.Idle,
  skillPageInfo: {},
  displaySkill: {},
  skillUsage: [],
  loadedSkillMap: {},
  skillImportInfo: [],
  skillImportInfoStatus: LoadingStatus.Idle,
  skillCapImportInfo: [],
  skillCapImportInfoStatus: LoadingStatus.Idle,
  associatedSkills: [],
  associatedSkillsStatus: LoadingStatus.Idle,
};

export const fetchSkill = createAsyncThunk(
  'skills/fetchSkill',
  async (params = {}) =>
    await skillService.getSkills(
      params.startIndex,
      params.stopIndex,
      params.searchString,
      params.sort,
      params.display_name,
      params.capability_id,
      params.job_id,
      params.job_role_id
    )
);

export const fetchSkillById = createAsyncThunk(
  'skills/fetchSkillById',
  async (skillId) => {
    const response = await skillService.getById(skillId);
    response.id = skillId;
    return response;
  }
);

export const fetchSkillUsageById = createAsyncThunk(
  'groups/fetchSkillUsageById',
  async (skillId) => await skillService.getSkillUsageById(skillId)
);

export const createSkill = createAsyncThunk(
  'skills/createSkill',
  async (params) => await skillService.create(params)
);

export const updateSkill = createAsyncThunk(
  'skills/updateSkill',
  async (params) => await skillService.update(params)
);

export const deleteSkill = createAsyncThunk(
  'skills/deleteSkill',
  async (skill_id) => {
    const response = await skillService.delete(skill_id);
    return response;
  }
);

export const deleteSkillArray = createAsyncThunk(
  'skills/deleteSkillArray',
  async (skillIds) => {
    const response = await skillService.deleteSkillArray(skillIds);
    return response;
  }
);

export const getSkillImportSampleCsv = createAsyncThunk(
  'skills/getSkillImportSampleCsv',
  async () => await skillService.getSkillImportCsv()
);

export const uploadSkillImportCsv = createAsyncThunk(
  'skills/uploadSkillImportCsv',
  async (params) => await skillService.uploadSkillImportCsv(params)
);

export const exportSkillsCsv = createAsyncThunk(
  'skills/exportSkillsCsv',
  async (params) =>
    await skillService.exportSkillCsv(params.display_name, params.capability_id)
);

export const getImportSkillHelp = createAsyncThunk(
  'skills/getImportSkillHelp',
  async (params) => await skillService.getSkillImportHelp(params)
);

export const getImportSkillCapHelp = createAsyncThunk(
  'skillsCapability/getImportSkillCapHelp',
  async (params) => await skillService.getSkillCapImportHelp(params)
);

export const fetchAssociateSkills = createAsyncThunk(
  'capability/fetchAssociateSkills',
  async (skill_id = null) => await skillService.getAssociateSkills(skill_id)
);

export const updateAssociatedSkills = createAsyncThunk(
  'capabilities/updateAssociateSkills',
  async (params) => await skillService.updateCapabilitySkills(params)
);

const skillSlice = createSlice({
  name: 'skills',
  initialState,
  reducers: {
    resetSkillState: (state) => initialState,
    resetSkillInfoState: (state) => {
      state.skillImportInfoStatus = LoadingStatus.Idle;
    },
    resetSkillCapInfoState: (state) => {
      state.skillCapImportInfoStatus = LoadingStatus.Idle;
    },
    resetAssociateSkills: (state) => {
      state.associatedSkillsStatus = LoadingStatus.Idle;
    },
  },
  extraReducers: {
    [fetchSkill.pending]: (state, action) => {
      state.skillStatus = LoadingStatus.Loading;
    },
    [fetchSkill.fulfilled]: (state, action) => {
      state.skillPageInfo = action.payload.pageInfo;
      state.skillStatus = LoadingStatus.Loaded;

      if (action.meta.arg.reset) {
        state.loadedSkillMap = {};
      }

      const startIndex = action.meta.arg.startIndex;
      const stopIndex = startIndex + action.payload.skills.length - 1;

      for (var i = startIndex; i <= stopIndex; i++) {
        state.loadedSkillMap[i] = LoadingStatus.Loaded;
      }

      // In case we didn't load as many as requested
      for (
        let j = stopIndex + 1;
        j <= action.payload?.pageInfo?.TotalCount;
        j++
      ) {
        delete state.loadedSkillMap[j];
      }

      if (action.meta.arg.reset) {
        state.skills = action.payload.skills;
      } else {
        state.skills = state.skills.concat(action.payload.skills);
      }
    },
    [fetchSkill.rejected]: (state, action) => {
      state.skillStatus = LoadingStatus.Failed;
    },
    [createSkill.fulfilled]: (state, action) => {
      state.skillStatus = LoadingStatus.Idle;
    },
    [updateSkill.fulfilled]: (state, action) => {
      state.skillStatus = LoadingStatus.Idle;
    },
    [deleteSkill.fulfilled]: (state, action) => {
      state.skillStatus = LoadingStatus.Idle;
    },
    [deleteSkillArray.fulfilled]: (state, action) => {
      state.skillStatus = LoadingStatus.Idle;
    },
    [deleteSkillArray.rejected]: (state, action) => {
      state.status = SavingStatus.Failed;
      state.error = action.error.message;
    },
    [fetchSkillById.fulfilled]: (state, action) => {
      state.displaySkill = action.payload;
    },
    [fetchSkillUsageById.fulfilled]: (state, action) => {
      state.skillUsage = action.payload;
    },
    [getSkillImportSampleCsv.fulfilled]: (state, action) => {
      if (action?.payload) {
        const url = window.URL.createObjectURL(new Blob([action.payload]));
        const link = document.createElement('a');
        link.href = url;
        let downloadName =
          action.payload.headers?.filename ?? 'skills_import.csv';
        link.setAttribute('download', downloadName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    },
    [getSkillImportSampleCsv.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    [exportSkillsCsv.fulfilled]: (state, action) => {
      if (action?.payload) {
        const url = window.URL.createObjectURL(new Blob([action.payload]));
        const link = document.createElement('a');
        link.href = url;
        let downloadName =
          action.payload.headers?.filename ?? 'skills_export.csv';
        link.setAttribute('download', downloadName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    },
    [exportSkillsCsv.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    [getImportSkillHelp.pending]: (state, action) => {
      state.skillImportInfoStatus = LoadingStatus.Loading;
    },
    [getImportSkillHelp.fulfilled]: (state, action) => {
      state.skillImportInfo = action.payload;
      state.skillImportInfoStatus = LoadingStatus.Loaded;
    },
    [getImportSkillHelp.rejected]: (state, action) => {
      state.skillImportInfoStatus = LoadingStatus.Failed;
    },
    [getImportSkillCapHelp.pending]: (state, action) => {
      state.skillCapImportInfoStatus = LoadingStatus.Loading;
    },
    [getImportSkillCapHelp.fulfilled]: (state, action) => {
      state.skillCapImportInfo = action.payload;
      state.skillCapImportInfoStatus = LoadingStatus.Loaded;
    },
    [getImportSkillCapHelp.rejected]: (state, action) => {
      state.skillCapImportInfoStatus = LoadingStatus.Failed;
    },
    [fetchAssociateSkills.pending]: (state, action) => {
      state.associatedSkillsStatus = LoadingStatus.Loading;
    },
    [fetchAssociateSkills.fulfilled]: (state, action) => {
      state.associatedSkillsStatus = LoadingStatus.Loaded;
      state.associatedSkills = action.payload;
    },
    [fetchAssociateSkills.rejected]: (state, action) => {
      state.associatedSkillsStatus = LoadingStatus.Failed;
    },
    [updateAssociatedSkills.fulfilled]: (state, action) => {
      state.associatedSkillsStatus = LoadingStatus.Idle;
    },
  },
});

export const selectSkill = (state) => state.skills.skills;
export const getSkillStatus = (state) => state.skills.skillStatus;
export const getSkillPageInfo = (state) => state.skills.skillPageInfo;
export const getSkillUsage = (state) => state.skills.skillUsage;
export const getSkillInfoStatus = (state) => state.skills.skillImportInfoStatus;
export const getSkillCapInfoStatus = (state) =>
  state.skills.skillCapImportInfoStatus;

export const selectAssociateSkills = (state) => state.skills.associatedSkills;
export const getAssociateSkillsStatus = (state) =>
  state.skills.associatedSkillsStatus;

export const {
  resetSkillState,
  resetSkillInfoState,
  resetSkillCapInfoState,
  resetAssociateSkills,
} = skillSlice.actions;

export default skillSlice.reducer;
