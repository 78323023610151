import React, { useState, useRef, useEffect, useCallback } from 'react';
import { AutoSizer } from 'react-virtualized';
import IconButton from '@mui/material/IconButton';
import { Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import pluralize from 'pluralize';
import { useDebounce } from 'hooks/useDebounce';
import { MdAdd, MdRefresh } from 'react-icons/md';

import SearchWithFilters from 'components/SearchWithFilters';
import {
  actionCell,
  checkCell,
  checkHeaderCell,
  clipLabelTooltipCell,
  labelCell,
  labelHeader,
  tagTypeCellWithToolTip,
} from 'shared/components/_Tables/TableCells';
import { makeButtonStyles } from 'components/styles';
import { VirtualTable } from 'shared/components/_Tables/VirtualTable';
import { EnhancedTableToolbar } from 'components/EnhancedTableToolbar';
import { tableStyles } from 'shared/components/_Tables/VirtualTable/styles';
import { Action } from 'helpers/action';
import { mdTheme } from 'theme';
import { PopupAction } from 'components/_Misc/PopupAction/components/PopupAction';
import {
  closePanel,
  openPanel,
  setEditObjectId,
} from 'features/common/formSlice';
import {
  exportSkillsCsv,
  fetchSkill,
  getImportSkillCapHelp,
  getImportSkillHelp,
  getSkillCapInfoStatus,
  getSkillImportSampleCsv,
  getSkillInfoStatus,
  getSkillStatus,
  resetSkillCapInfoState,
  resetSkillInfoState,
  resetSkillState,
  uploadSkillImportCsv,
} from 'features/skill/skillSlice';
import { NoDataMessage } from 'shared/components/_Tables/NoDataMessage';
import { LoadingStatus, NO_MATCHING_SKILLS, SKILL_TABLE_KEYS } from 'helpers';
import { ItemSkillFilter } from '../ItemSkillFilter';
import { selectActiveUser } from 'features/account/accountSlice';
import { DropZoneImport } from 'shared/components/_DropZoneImport';
import {
  capabilitySkillsCsvImport,
  getCapabilitySkillsSampleCsv,
} from 'features/capability/capabilitySlice';
import { mapValues } from 'helpers/mapValues';
import { CustomDialog } from 'shared/components/_Modal';
import {
  fetchCapabilityDropDown,
  fetchSkillsDropDown,
  getCapabilityDropDownStatus,
  getSkillsDropDownStatus,
  selectCapabilitiesDropDown,
  selectSkillsDropDown,
} from 'features/AdminSettingsDropDown/dropDownSlice';

function ItemSkillTable({
  skills,
  pageInfo,
  itemSkillPayload,
  setItemSkillPayload,
  sortDirection,
  sortBy,
  handleSort,
}) {
  const dispatch = useDispatch();
  const buttonStyles = makeButtonStyles(mdTheme);
  const tableClasses = tableStyles();
  let updatedPayload = mapValues(itemSkillPayload);
  const [selected, setSelected] = useState([]);
  const [selectedAll, setSelectedAll] = React.useState(false);
  const [openHelpDialog, setOpenHelpDialog] = useState(false);
  const [dropZoneModalKey, setDropZoneModalKey] = useState(null);

  const getInfoStatus = useSelector(getSkillInfoStatus);
  const getCapInfoStatus = useSelector(getSkillCapInfoStatus);
  const skillStatus = useSelector(getSkillStatus);
  const loadedRowsMap = useSelector((state) => state.skills.loadedSkillMap);
  const activeUser = useSelector(selectActiveUser);
  const hideSkillIdColumn = activeUser?.hide_system_ids;
  const skillColumnIds = activeUser?.admin_field_ids;

  const headerHeight = 40;
  const rowHeight = 60;

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const prevSelectedAll = usePrevious(selectedAll);
  if (prevSelectedAll && selectedAll) {
    if (selected.length !== skills?.length) {
      setSelected(skills.map((row) => row.skill_id));
      setSelectedAll(true);
    }
  }

  const onRefreshIconClick = async () => {
    await dispatch(fetchSkill({ ...updatedPayload }));
  };

  const handleClick = (event, skill_id) => {
    event.stopPropagation();
    const selectedIndex = selected.indexOf(skill_id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, skill_id);
    } else {
      newSelected = selected.filter((id) => id !== skill_id);
    }
    setSelected(newSelected);
    setSelectedAll(newSelected.length === skills.length);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelectedIds = skills.map((tag) => tag.skill_id);
      setSelected(newSelectedIds);
      setSelectedAll(true);
      return;
    }
    setSelected([]);
    setSelectedAll(false);
  };

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const handleRowClick = async ({ rowData, event }) => {
    if (event.target.closest('.MuiTooltip-tooltip')) {
      return;
    }
    await dispatch(closePanel({ formKey: 'itemSkillForm' }));
    if (rowData?.skill_id) {
      await dispatch(
        setEditObjectId({
          formKey: `itemSkillForm`,
          object_id: rowData?.skill_id,
        })
      );
      dispatch(openPanel({ formKey: `itemSkillForm`, formAction: 'Edit' }));
    }
  };

  const init_columns = [
    {
      classes: tableClasses,
      minWidth: 50,
      maxWidth: 50,
      width: 50,
      label: '',
      dataKey: 'skill_id',
      padding: 'checkbox',
      cellLevel: 'skill',
      numSelected: selected?.length,
      rowCount: skills?.length,
      handleClick: handleClick,
      handleSelectAllClick: handleSelectAllClick,
      isSelected: isSelected,
      component: checkCell,
      headerComponent: checkHeaderCell,
    },
    {
      classes: tableClasses,
      minWidth: 50,
      maxWidth: 50,
      width: 50,
      label: '',
      dataKey: 'skill_id',
      padding: 'none',
      actionCellLevel: 'skill',
      targetPopup: 'skillManagement',
      formKey: 'itemSkillForm',
      dispatch: dispatch,
      component: actionCell,
      headerComponent: labelHeader,
    },
  ];

  const handleColumnVisibility = (key, column) => {
    if (hideSkillIdColumn && key === 'skill_id') {
      return undefined;
    }

    const isVisible = skillColumnIds?.includes(SKILL_TABLE_KEYS?.[key]?.id);
    if (isVisible) {
      return column;
    }
  };

  const columns = [
    ...init_columns,
    handleColumnVisibility('skill_id', {
      classes: tableClasses,
      width: 140,
      minWidth: 140,
      flexGrow: 1,
      label: `${activeUser?.label_skill} Id`,
      dataKey: 'skill_id',
      padding: 'normal',
      component: labelCell,
      headerComponent: labelHeader,
      sortDirection: sortDirection,
      sortBy: sortBy,
      handleSort: handleSort,
    }),
    handleColumnVisibility('display_name', {
      classes: tableClasses,
      flexGrow: 1,
      width: 120,
      minWidth: 120,
      label: 'Name',
      dataKey: 'display_name',
      padding: 'normal',
      component: labelCell,
      headerComponent: labelHeader,
      sortDirection: sortDirection,
      sortBy: sortBy,
      handleSort: handleSort,
    }),
    handleColumnVisibility('description', {
      classes: tableClasses,
      width: 250,
      minWidth: 250,
      maxWidth: 250,
      label: 'Description',
      dataKey: 'description',
      padding: 'normal',
      component: clipLabelTooltipCell,
      headerComponent: labelHeader,
    }),
    handleColumnVisibility('capability_count', {
      classes: tableClasses,
      flexGrow: 1,
      width: 80,
      minWidth: 80,
      label: `${pluralize(activeUser?.label_capability, skills?.length)}`,
      dataKey: 'capability_count',
      displayDataKey: 'capability_count',
      padding: 'none',
      toolTipKey: 'capability_names',
      dispatch: dispatch,
      formKey: 'itemAssociateSkillForm',
      formId: 'skill_id',
      component: tagTypeCellWithToolTip,
      headerComponent: labelHeader,
      sortDirection: sortDirection,
      sortBy: sortBy,
      handleSort: handleSort,
    }),
  ].filter(Boolean);

  // eslint-disable-next-line no-unused-vars
  async function loadMoreRows({ startIndex, stopIndex }) {
    if (skillStatus !== LoadingStatus.Loading) {
      await dispatch(
        fetchSkill({
          ...updatedPayload,
          reset: false,
          startIndex: startIndex,
          stopIndex: stopIndex,
        })
      );
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    useDebounce((searchString) => {
      setItemSkillPayload({
        ...itemSkillPayload,
        searchString: searchString,
      });
    }, 1000),
    [itemSkillPayload]
  );

  const handleCreateNew = () => {
    dispatch(openPanel({ formKey: 'itemSkillForm', formAction: 'Create' }));
  };

  const importInfoModalProps = useCallback(() => {
    if (dropZoneModalKey === activeUser?.label_skill) {
      return {
        getEntityHelp: getImportSkillHelp,
        fieldName: 'skill_id',
        selectDropdownEntity: selectSkillsDropDown,
        getDropdownStatusEntity: getSkillsDropDownStatus,
        fetchDropDownEntityPage: fetchSkillsDropDown,
        openHelpDialog: openHelpDialog,
        setOpenHelpDialog: setOpenHelpDialog,
        getEntityHelpStatus: getInfoStatus,
        selectLabel: 'Select Skill',
        resetHelpEntity: resetSkillInfoState,
      };
    }
    if (
      dropZoneModalKey ===
      `Connect ${pluralize(activeUser?.label_job_role, 2)}/${pluralize(
        activeUser?.label_capability,
        2
      )}`
    ) {
      return {
        getEntityHelp: getImportSkillCapHelp,
        fieldName: 'employee_id',
        selectDropdownEntity: selectCapabilitiesDropDown,
        getDropdownStatusEntity: getCapabilityDropDownStatus,
        fetchDropDownEntityPage: fetchCapabilityDropDown,
        openHelpDialog: openHelpDialog,
        setOpenHelpDialog: setOpenHelpDialog,
        getEntityHelpStatus: getCapInfoStatus,
        selectLabel: 'Select Capability',
        resetHelpEntity: resetSkillCapInfoState,
      };
    }
  }, [
    activeUser?.label_capability,
    activeUser?.label_job_role,
    activeUser?.label_skill,
    dropZoneModalKey,
    getCapInfoStatus,
    getInfoStatus,
    openHelpDialog,
  ]);

  return (
    <div className={tableClasses.root}>
      <ItemSkillFilter
        setItemSkillPayload={setItemSkillPayload}
        itemSkillPayload={itemSkillPayload}
      />

      {selected.length > 0 && (
        <EnhancedTableToolbar
          numSelected={selected.length}
          toolTipTitle={'Delete'}
          totalCount={pageInfo?.TotalCount}
        >
          <PopupAction
            action={Action.DeleteArray}
            object={{
              skillIds: selected,
              setSkillIds: setSelected,
            }}
            level={'skill'}
            showLabel={false}
          />
        </EnhancedTableToolbar>
      )}
      <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
        <Tooltip title="Add New Skill">
          <IconButton
            onClick={handleCreateNew}
            size="large"
            className={buttonStyles.contentAddButton}
          >
            <MdAdd />
          </IconButton>
        </Tooltip>
        <div style={{ flexGrow: 1 }}></div>
        <DropZoneImport
          key="capability-skill-dzi"
          getEntityImportSample={getCapabilitySkillsSampleCsv}
          uploadEntityState={capabilitySkillsCsvImport}
          resetEntitySelector={resetSkillState}
          mainTitle={`Connect ${pluralize(
            activeUser?.label_job_role,
            2
          )}/${pluralize(activeUser?.label_capability, 2)}`}
          buttonText={`Connect ${pluralize(
            activeUser?.label_job_role,
            2
          )}/${pluralize(activeUser?.label_capability, 2)}`}
          isInfoIcon
          setOpenHelpDialog={setOpenHelpDialog}
          setDropZoneModalKey={setDropZoneModalKey}
        />
        <DropZoneImport
          key="skill-dzi"
          getEntityImportSample={getSkillImportSampleCsv}
          uploadEntityState={uploadSkillImportCsv}
          resetEntitySelector={resetSkillState}
          mainTitle={activeUser?.label_skill}
          exportEntityState={exportSkillsCsv}
          exportFilterData={updatedPayload}
          isExport
          isInfoIcon
          setOpenHelpDialog={setOpenHelpDialog}
          setDropZoneModalKey={setDropZoneModalKey}
        />
        <CustomDialog {...importInfoModalProps()} />
        <IconButton
          onClick={onRefreshIconClick}
          aria-label="close"
          sx={{
            color: '#707070',
          }}
          size="large"
        >
          <MdRefresh style={{ fontSize: '18px', color: 'black' }} />
        </IconButton>
        <SearchWithFilters
          filter={false}
          onChange={(evt) => debouncedSearch(evt.target.value)}
          value={itemSkillPayload?.searchString}
        />
      </div>
      {skills.length > 0 ? (
        <div
          style={{
            height: 'calc(100vh - 295px)',
            minWidth: '500px',
            paddingTop: '2rem',
          }}
        >
          <AutoSizer>
            {({ width, height }) => (
              <VirtualTable
                initialRows={skills}
                allRows={skills}
                totalRowCount={pageInfo?.TotalCount}
                loadedRowsMap={loadedRowsMap}
                loadMoreRows={loadMoreRows}
                columnDefinitions={columns}
                cellClassName={tableClasses.flexContainer}
                className={tableClasses.table}
                rowClassName={tableClasses.flexContainer}
                headerHeight={headerHeight}
                width={width}
                height={height}
                rowHeight={rowHeight}
                onRowClick={handleRowClick}
              />
            )}
          </AutoSizer>
        </div>
      ) : (
        <NoDataMessage message={NO_MATCHING_SKILLS} />
      )}
    </div>
  );
}

export { ItemSkillTable };
