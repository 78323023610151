import { Autocomplete, Box, MenuItem, TextField } from '@mui/material';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import SearchWithFilters from 'components/SearchWithFilters';
import {
  selectActiveUser,
  setAppIsLoading,
} from 'features/account/accountSlice';

import { LoadingStatus, SystemRoleEnums } from 'helpers';
import {
  fetchAllJobDepots,
  getAllJobDepot,
  getJobDepotStatus,
} from 'features/JobDepot/jobDepotSlice';
import DepotTable from 'components/_Layout/DepotTable';

function Home({ theme }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = useRef(false);
  const [jobDepotPayload, setJobDepotPayload] = useState({});
  const jobDepotStatus = useSelector(getJobDepotStatus);
  const jobDepotData = useSelector(getAllJobDepot);
  const activeUser = useSelector(selectActiveUser);
  const getAllJob = async (payload) => {
    dispatch(setAppIsLoading());
    await dispatch(fetchAllJobDepots(payload));
    dispatch(setAppIsLoading());
  };

  useEffect(() => {
    if (ref?.current === true) {
      getAllJob({ ...jobDepotPayload });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobDepotPayload]);

  useEffect(() => {
    if (SystemRoleEnums.User === activeUser?.role) {
      navigate(`/employee/${activeUser?.employee_id}`);
    } else if (SystemRoleEnums.AMC === activeUser?.role) {
      navigate('/depot-report');
    } else {
      navigate('/team');
    }

    if (jobDepotStatus === LoadingStatus.Idle) {
      getAllJob({ ...jobDepotPayload });
    }
    ref.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobDepotStatus]);

  return (
    <Box>
      <SearchWithFilters>
        <MenuItem disableRipple>
          <Autocomplete
            limitTags={2}
            variant="outline"
            id="checkboxes-tags-demo"
            options={[]}
            value={'test'}
            style={{ width: 500 }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Display Type"
                placeholder="Display Type"
              />
            )}
          />
        </MenuItem>
      </SearchWithFilters>
      <DepotTable jobDepotData={jobDepotData} theme={theme} />
    </Box>
  );
}

export { Home };
