import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  activateLoading,
  deactivateLoading,
} from 'features/global/loadingProgressSlice';
import { ADMIN_JOB_CACHE, LoadingStatus } from 'helpers';
import { ItemJobTable } from './ItemJobTable';
import {
  fetchJobs,
  getJobPageInfo,
  getJobStatus,
  selectJob,
} from 'features/job/jobSlice';
import { closePanel } from 'features/common/formSlice';
import {
  selectAdminSettingCache,
  updateAdminSettingCache,
} from 'features/global/visibilitySlice';
import { mapValues } from 'helpers/mapValues';

const JobManagementTab = () => {
  //#region Constants
  const dispatch = useDispatch();
  const getCacheData = useSelector(selectAdminSettingCache);
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  const [itemJobPayload, setItemJobPayload] = useState({
    ...getCacheData?.[ADMIN_JOB_CACHE],
    reset: true,
    startIndex: 0,
    stopIndex: 19,
  });
  const [sortBy, setSortBy] = useState(
    getCacheData?.[ADMIN_JOB_CACHE]?.sort?.slice(0, 1) === '-'
      ? getCacheData?.[ADMIN_JOB_CACHE]?.sort.slice(1)
      : getCacheData?.[ADMIN_JOB_CACHE]?.sort || 'display_name'
  );

  const [sortDirection, setSortDirection] = useState(
    getCacheData?.[ADMIN_JOB_CACHE]?.sort?.[0] !== '-' ? 'ASC' : 'DESC'
  );
  //#endregion State

  //#region Selectors
  const jobs = useSelector(selectJob);
  const itemJobStatus = useSelector(getJobStatus);
  const itemJobPageInfo = useSelector(getJobPageInfo);
  //#endregion Selectors

  //#region Refs
  const ref = useRef(false);
  //#endregion Refs

  //#region Effects
  useEffect(() => {
    if (ref.current === true) {
      getJobData({ ...itemJobPayload });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemJobPayload]);

  useEffect(() => {
    if (itemJobStatus === LoadingStatus.Idle) {
      getJobData({ ...itemJobPayload, startIndex: 0, stopIndex: 19 });
    }
    ref.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemJobStatus, dispatch]);
  //#endregion Effects

  //#region Methods
  const getJobData = async (payload) => {
    dispatch(activateLoading());
    let updatedPayload = mapValues(payload);
    await dispatch(fetchJobs({ ...updatedPayload }));
    dispatch(updateAdminSettingCache({ key: ADMIN_JOB_CACHE, payload }));
    dispatch(closePanel({ formKey: `itemJobForm` }));
    dispatch(deactivateLoading());
  };

  const setSorting = (_sortBy, _sortDirection) => {
    setSortBy(_sortBy);
    setSortDirection(_sortDirection);
    setItemJobPayload({
      ...itemJobPayload,
      sort: `${_sortDirection === 'ASC' ? '' : '-'}${_sortBy}`,
    });
  };
  //#endregion Methods

  //#region Render time calcs
  //#endregion Render time calcs

  //#region Render

  return (
    <>
      <ItemJobTable
        jobs={jobs}
        pageInfo={itemJobPageInfo}
        itemJobPayload={itemJobPayload}
        setItemJobPayload={setItemJobPayload}
        sortDirection={sortDirection}
        sortBy={sortBy}
        handleSort={(_sortBy, _sortDirection) =>
          setSorting(_sortBy, _sortDirection)
        }
      />
    </>
  );
  //#endregion Render
};
export { JobManagementTab };
