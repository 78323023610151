import {
  Box,
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';

import SearchWithFilters from 'components/SearchWithFilters';
import {
  selectLevelDropDown,
  selectSkillExpiryDropDown,
} from 'features/AdminSettingsDropDown/dropDownSlice';
import {
  fetchAssociateJobRole,
  getAssociateJobRoleStatus,
  resetAssociateJobRole,
  resetCapabilityState,
  selectAssociateJobRole,
  updateAssociateCapabilityJobRole,
} from 'features/capability/capabilitySlice';
import { closePanel } from 'features/common/formSlice';
import {
  activateLoading,
  deactivateLoading,
} from 'features/global/loadingProgressSlice';
import { makeFormStyles } from 'forms/style';
import { LoadingStatus, snackbarHandler } from 'helpers';
import { Each } from 'helpers/Each';
import { useDebounce } from 'hooks/useDebounce';
import { useDispatch, useSelector } from 'react-redux';
import { SnackbarDismiss } from 'shared/components/SnackbarDismiss';
import { NoDataMessage } from 'shared/components/_Tables/NoDataMessage';

const AssociateJobRole = ({ formAction, itemTagId }) => {
  //#region Constants
  const classes = makeFormStyles();
  const dispatch = useDispatch();
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  const [selected, setSelected] = useState([]);
  const [filteredFormData, setFilteredFormData] = useState([]);
  const [initValues, setInitValues] = useState({});
  const isSelected = (id) => selected.indexOf(id) !== -1;
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  //#endregion State

  //#region Selectors
  const levelDropDown = useSelector(selectLevelDropDown);
  const skillExpiryDropDown = useSelector(selectSkillExpiryDropDown);
  const associationStatus = useSelector(getAssociateJobRoleStatus);
  const associateData = useSelector(selectAssociateJobRole);
  //#endregion Selectors

  //#region Refs
  //#endregion Refs

  //#region Effects
  useEffect(() => {
    if (associateData?.length > 0) {
      const updatedData = {};
      associateData.forEach((data) => {
        updatedData[data?.job_role_id] = data;
      });
      setInitValues(() => ({
        ...updatedData,
      }));
    }
  }, [levelDropDown, skillExpiryDropDown, associateData]);

  useEffect(() => {
    const getAssociationData = async () => {
      await dispatch(activateLoading());
      await dispatch(fetchAssociateJobRole(itemTagId));
      await dispatch(deactivateLoading());
    };
    if (itemTagId && associationStatus === LoadingStatus.Idle) {
      getAssociationData();
    }
  }, [associationStatus, dispatch, itemTagId]);

  useEffect(() => {
    if (associateData?.length > 0) {
      setSelected(
        associateData
          ?.filter((item) => item?.is_checked)
          .map((item) => item.job_role_id)
      );
    }
  }, [associateData]);

  useEffect(() => {
    if (associateData?.length > 0) {
      setFilteredFormData([...associateData]);
    }
  }, [associateData]);

  useEffect(() => {
    return () => {
      dispatch(resetAssociateJobRole());
    };
  }, []);
  //#endregion Effects

  //#region Methods
  const handleClickSelect = (job_role_id) => {
    const selectedIndex = selected.indexOf(job_role_id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, job_role_id);
    } else {
      newSelected = selected.filter((id) => id !== job_role_id);
    }

    setSelected(newSelected);
  };

  const handleSelectAllClick = (event, setFieldValue) => {
    if (event.target.checked) {
      const newSelectedIds = associateData?.map(
        (audience) => audience?.job_role_id
      );
      setSelected(newSelectedIds);
      associateData.forEach((item) => {
        setFieldValue(`[${item.job_role_id}].is_checked`, true);
      });
      return;
    }
    associateData.forEach((item) => {
      setFieldValue(`[${item.job_role_id}].is_checked`, false);
    });
    setSelected([]);
  };

  const debouncedSearch = useCallback(
    useDebounce(async (searchString) => {
      const filteredFormData = associateData?.filter((item) =>
        item?.display_name?.toLowerCase().includes(searchString.toLowerCase())
      );
      setFilteredFormData(filteredFormData);
    }, 1000),
    [associateData]
  );

  function convertData(data) {
    const result = [];
    Object.values(data).forEach((formData) => {
      if (data.is_checked.includes(formData?.job_role_id)) {
        result.push({
          job_role_id: formData.job_role_id,
          is_checked: data.is_checked.includes(formData.job_role_id),
        });
      }
    });
    return result;
  }

  const submitData = useCallback(async () => {
    var resultAction = null;
    const associateData = convertData({
      ...initValues,
      is_checked: selected,
    });
    if (associateData?.length > 0) {
      dispatch(activateLoading());
      setIsFormSubmitting(true);
      resultAction = await dispatch(
        updateAssociateCapabilityJobRole({
          jobRoleData: associateData,
          capability_id: itemTagId,
        })
      );
      if (!resultAction.error) {
        dispatch(closePanel({ formKey: `associateCapabilityJobRoleForm` }));
        dispatch(resetCapabilityState());
      }
      const { message, variant } = snackbarHandler(
        resultAction.meta.requestStatus,
        'Capability JobRole Updated'
      );
      enqueueSnackbar(message, {
        action: (key) => <SnackbarDismiss key={key} />,
        variant: variant,
      });
      dispatch(deactivateLoading());
    } else {
      setIsFormSubmitting(false);
    }
  }, [dispatch, initValues, itemTagId, selected]);
  //#endregion Methods

  //#region Render time calcs
  //#endregion Render time calcs

  //#region Render
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{ ...initValues }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          submitData(values, setSubmitting, resetForm);
        }}
      >
        {({ setFieldValue, dirty }) => (
          <Form className={classes.form} style={{ padding: '0' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                pb: '1rem',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: '1rem',
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isFormSubmitting || !dirty}
                >
                  Update
                </Button>
                <SearchWithFilters
                  filter={false}
                  onChange={(evt) => debouncedSearch(evt.target.value)}
                />
              </Box>
            </Box>
            {selected?.length > 0 && (
              <Typography
                className={classes.title}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                {selected.length} of {associateData?.length} selected
              </Typography>
            )}
            <TableContainer
              sx={{
                height: 'calc(100vh - 295px)',
                minWidth: '500px',
                overflow: 'auto',
              }}
            >
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Checkbox
                        indeterminate={
                          selected?.length > 0 &&
                          selected?.length < associateData?.length
                        }
                        checked={
                          associateData?.length > 0 &&
                          selected?.length === associateData?.length
                        }
                        onChange={(event) => {
                          handleSelectAllClick(event, setFieldValue);
                          setFieldValue('select_all', event.target.checked);
                        }}
                      />
                    </TableCell>
                    <TableCell width={280}>
                      <Typography variant="body1" fontWeight={'bold'}>
                        Name
                      </Typography>
                    </TableCell>
                    <TableCell minWidth={400}>
                      <Typography variant="body1" fontWeight={'bold'}>
                        Description
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredFormData?.length > 0 ? (
                    <Each
                      of={filteredFormData}
                      render={(item, idx) => (
                        <TableRow className={classes.tableRow} key={idx}>
                          <TableCell>
                            <Checkbox
                              checked={isSelected(item.job_role_id)}
                              onClick={(event) => {
                                event.stopPropagation();
                                handleClickSelect(item.job_role_id);
                                setFieldValue(
                                  `[${item?.job_role_id}].is_checked`,
                                  event.target.checked
                                );
                              }}
                            />
                          </TableCell>
                          <TableCell>{item?.display_name}</TableCell>
                          <TableCell>
                            <Typography
                              sx={{
                                overflow: 'hidden !important',
                                textOverflow: 'ellipsis',
                                margin: '0',
                                WebkitLineClamp: '3',
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                              }}
                            >
                              {item?.description}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    />
                  ) : (
                    <TableRow className={classes.tableRow}>
                      <TableCell colSpan={6}>
                        <NoDataMessage
                          message={'No Data Found'}
                          setHeight="450px"
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Form>
        )}
      </Formik>
    </>
  );
  //#endregion Render
};

export default AssociateJobRole;
