import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { capabilityService } from 'features/services/capability.service';
import { LoadingStatus, SavingStatus } from 'helpers';

const initialState = {
  status: LoadingStatus.Idle,
  error: null,
  capabilities: [],
  capabilityStatus: LoadingStatus.Idle,
  capabilityPageInfo: {},
  displayCapabilities: {
    capability_id: null,
    created_by_employee_id: null,
    created_by_employee_full_name: '',
    created_date: '',
    modified_date: '',
    levels: [],
    skills: {},
    display_name: '',
    description: '',
  },
  capabilityUsage: [],
  loadedCapabilitiesMap: {},
  capabilityImportInfo: [],
  capabilityImportInfoStatus: LoadingStatus.Idle,
  associatedCapability: [],
  associatedCapabilityStatus: LoadingStatus.Idle,
  associatedUpdatedStatus: LoadingStatus.Idle,
  associatedJobRole: [],
  associatedJobRoleStatus: LoadingStatus.Idle,
};

export const fetchCapabilities = createAsyncThunk(
  'capabilities/fetchCapabilities',
  async (params = {}) =>
    await capabilityService.getCapabilities(
      params.startIndex,
      params.stopIndex,
      params.searchString,
      params.sort,
      params.display_name,
      params.job_role_id,
      params.job_id,
      params.job_series_id,
      params.skill_ids
    )
);

export const fetchCapabilitiesById = createAsyncThunk(
  'capabilities/fetchCapabilitiesById',
  async (payPlanId) => {
    const response = await capabilityService.getById(payPlanId);
    response.id = payPlanId;
    return response;
  }
);

export const fetchCapabilitiesUsageById = createAsyncThunk(
  'groups/fetchCapabilitiesUsageById',
  async (payPlanId) => await capabilityService.getCapabilityUsageById(payPlanId)
);

export const createCapability = createAsyncThunk(
  'capabilities/createCapability',
  async (params) => await capabilityService.create(params)
);

export const updateCapability = createAsyncThunk(
  'capabilities/updateCapability',
  async (params) => await capabilityService.update(params)
);

export const deleteCapability = createAsyncThunk(
  'capabilities/deleteCapability',
  async (capability_id) => {
    const response = await capabilityService.delete(capability_id);
    return response;
  }
);

export const deleteCapabilitiesArray = createAsyncThunk(
  'assets/deleteCapabilitiesArray',
  async (capabilityIds) => {
    const response = await capabilityService.deleteCapabilitiesArray(
      capabilityIds
    );
    return response;
  }
);

export const getCapabilityImportSampleCsv = createAsyncThunk(
  'capabilities/getCapabilityImportSampleCsv',
  async () => await capabilityService.getCapabilityImportCsv()
);

export const uploadCapabilityImportCsv = createAsyncThunk(
  'capabilities/uploadCapabilityImportCsv',
  async (params) => await capabilityService.uploadCapabilityImportCsv(params)
);

export const exportCapabilityCsv = createAsyncThunk(
  'capabilities/exportCapabilityCsv',
  async (params) =>
    await capabilityService.exportCapabilityCsv(
      params.display_name,
      params.job_role_id,
      params.job_id,
      params.job_series_id,
      params.skill_ids
    )
);

export const getCapabilitySkillsSampleCsv = createAsyncThunk(
  'capabilitiesSkill/getCapabilitySkillsSampleCsv',
  async () => await capabilityService.getCapabilitySkillImportCsv()
);

export const capabilitySkillsCsvImport = createAsyncThunk(
  'capabilitySkills/capabilitySkillsCsvImport',
  async (params) => await capabilityService.capabilitySkillImport(params)
);

export const getImportCapabilityHelp = createAsyncThunk(
  'capability/getImportCapabilityHelp',
  async (params) => await capabilityService.getCapabilityImportHelp(params)
);

export const fetchAssociateCapability = createAsyncThunk(
  'capability/fetchAssociateCapability',
  async (capability_id = null) =>
    await capabilityService.getAssociateCapability(capability_id)
);

export const updateAssociateCapabilitySkill = createAsyncThunk(
  'capabilities/updateAssociateCapability',
  async (params) => await capabilityService.updateCapabilitySkill(params)
);

export const fetchAssociateJobRole = createAsyncThunk(
  'capability/fetchAssociateJobRole',
  async (capability_id = null) =>
    await capabilityService.getAssociateJobRole(capability_id)
);

export const updateAssociateCapabilityJobRole = createAsyncThunk(
  'capabilities/updateAssociateJobRole',
  async (params) => await capabilityService.updateCapabilityJobRole(params)
);

const capabilitySlice = createSlice({
  name: 'capabilities',
  initialState,
  reducers: {
    resetCapabilityState: (state) => initialState,
    resetDisplayCapabilities: (state) => {
      state.displayCapabilities = {
        capability_id: null,
        created_by_employee_id: null,
        created_by_employee_full_name: '',
        created_date: '',
        modified_date: '',
        levels: [],
        skills: {},
        display_name: '',
        description: '',
      };
    },
    resetCapabilityInfoState: (state) => {
      state.capabilityImportInfoStatus = LoadingStatus.Idle;
    },
    resetAssociateCapability: (state) => {
      state.associatedCapabilityStatus = LoadingStatus.Idle;
    },
    resetAssociateJobRole: (state) => {
      state.associatedJobRoleStatus = LoadingStatus.Idle;
    },
  },
  extraReducers: {
    [fetchCapabilities.pending]: (state, action) => {
      state.capabilityStatus = LoadingStatus.Loading;
    },
    [fetchCapabilities.fulfilled]: (state, action) => {
      state.capabilityPageInfo = action.payload.pageInfo;
      state.capabilityStatus = LoadingStatus.Loaded;

      if (action.meta.arg.reset) {
        state.loadedCapabilitiesMap = {};
      }

      const startIndex = action.meta.arg.startIndex;
      const stopIndex = startIndex + action.payload.capabilities.length - 1;

      for (var i = startIndex; i <= stopIndex; i++) {
        state.loadedCapabilitiesMap[i] = LoadingStatus.Loaded;
      }

      // In case we didn't load as many as requested
      for (
        let j = stopIndex + 1;
        j <= action.payload?.pageInfo?.TotalCount;
        j++
      ) {
        delete state.loadedCapabilitiesMap[j];
      }

      if (action.meta.arg.reset) {
        state.capabilities = action.payload.capabilities;
      } else {
        state.capabilities = state.capabilities.concat(
          action.payload.capabilities
        );
      }
    },
    [fetchCapabilities.rejected]: (state, action) => {
      state.capabilityStatus = LoadingStatus.Failed;
    },
    [createCapability.fulfilled]: (state, action) => {
      state.capabilityStatus = LoadingStatus.Idle;
    },
    [updateCapability.fulfilled]: (state, action) => {
      state.capabilityStatus = LoadingStatus.Idle;
    },
    [deleteCapability.fulfilled]: (state, action) => {
      state.capabilityStatus = LoadingStatus.Idle;
    },
    [deleteCapabilitiesArray.fulfilled]: (state, action) => {
      state.capabilityStatus = LoadingStatus.Idle;
    },
    [deleteCapabilitiesArray.rejected]: (state, action) => {
      state.status = SavingStatus.Failed;
      state.error = action.error.message;
    },
    [fetchCapabilitiesById.fulfilled]: (state, action) => {
      state.displayCapabilities = action.payload;
    },
    [fetchCapabilitiesUsageById.fulfilled]: (state, action) => {
      state.capabilityUsage = action.payload;
    },
    [getCapabilityImportSampleCsv.fulfilled]: (state, action) => {
      if (action?.payload) {
        const url = window.URL.createObjectURL(new Blob([action.payload]));
        const link = document.createElement('a');
        link.href = url;
        let downloadName =
          action.payload.headers?.filename ?? 'capabilities_import.csv';
        link.setAttribute('download', downloadName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    },
    [getCapabilityImportSampleCsv.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    [exportCapabilityCsv.fulfilled]: (state, action) => {
      if (action?.payload) {
        const url = window.URL.createObjectURL(new Blob([action.payload]));
        const link = document.createElement('a');
        link.href = url;
        let downloadName =
          action.payload.headers?.filename ?? 'capabilities_export.csv';
        link.setAttribute('download', downloadName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    },
    [exportCapabilityCsv.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    [getCapabilitySkillsSampleCsv.fulfilled]: (state, action) => {
      if (action?.payload) {
        const url = window.URL.createObjectURL(new Blob([action.payload]));
        const link = document.createElement('a');
        link.href = url;
        let downloadName =
          action.payload.headers?.filename ?? 'capabilities_skills_import.csv';
        link.setAttribute('download', downloadName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    },
    [getCapabilitySkillsSampleCsv.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    [getImportCapabilityHelp.pending]: (state, action) => {
      state.capabilityImportInfoStatus = LoadingStatus.Loading;
    },
    [getImportCapabilityHelp.fulfilled]: (state, action) => {
      state.capabilityImportInfo = action.payload;
      state.capabilityImportInfoStatus = LoadingStatus.Loaded;
    },
    [getImportCapabilityHelp.rejected]: (state, action) => {
      state.capabilityImportInfoStatus = LoadingStatus.Failed;
    },
    [fetchAssociateCapability.pending]: (state, action) => {
      state.associatedCapabilityStatus = LoadingStatus.Loading;
    },
    [fetchAssociateCapability.fulfilled]: (state, action) => {
      state.associatedCapabilityStatus = LoadingStatus.Loaded;
      state.associatedCapability = action.payload;
    },
    [fetchAssociateCapability.rejected]: (state, action) => {
      state.associatedCapabilityStatus = LoadingStatus.Failed;
    },
    [updateAssociateCapabilitySkill.fulfilled]: (state, action) => {
      state.associatedUpdatedStatus = LoadingStatus.Idle;
    },
    [fetchAssociateJobRole.pending]: (state, action) => {
      state.associatedJobRoleStatus = LoadingStatus.Loading;
    },
    [fetchAssociateJobRole.fulfilled]: (state, action) => {
      state.associatedJobRoleStatus = LoadingStatus.Loaded;
      state.associatedJobRole = action.payload;
    },
    [fetchAssociateJobRole.rejected]: (state, action) => {
      state.associatedJobRoleStatus = LoadingStatus.Failed;
    },
    [updateAssociateCapabilityJobRole.fulfilled]: (state, action) => {
      state.associatedJobRoleStatus = LoadingStatus.Idle;
    },
  },
});

export const selectCapability = (state) => state.capabilities.capabilities;
export const getCapabilityStatus = (state) =>
  state.capabilities.capabilityStatus;
export const getCapabilityPageInfo = (state) =>
  state.capabilities.capabilityPageInfo;
export const getCapabilityUsage = (state) => state.capabilities.capabilityUsage;
export const getCapabilityById = (state) =>
  state.capabilities.displayCapabilities;
export const getCapabilityInfoStatus = (state) =>
  state.capabilities.capabilityImportInfoStatus;

export const selectAssociateCapability = (state) =>
  state.capabilities.associatedCapability;
export const getAssociateCapabilityStatus = (state) =>
  state.capabilities.associatedCapabilityStatus;

export const selectAssociateJobRole = (state) =>
  state.capabilities.associatedJobRole;
export const getAssociateJobRoleStatus = (state) =>
  state.capabilities.associatedJobRoleStatus;

export const {
  resetDisplayCapabilities,
  resetCapabilityState,
  resetCapabilityInfoState,
  resetAssociateCapability,
  resetAssociateJobRole,
} = capabilitySlice.actions;

export default capabilitySlice.reducer;
