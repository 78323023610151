import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { levelService } from 'features/services/level.service';
import { LoadingStatus, SavingStatus } from 'helpers';

const initialState = {
  status: LoadingStatus.Idle,
  error: null,
  levels: [],
  levelStatus: LoadingStatus.Idle,
  levelPageInfo: {},
  displayLevel: {},
  levelUsage: [],
  loadedLevelsMap: {},
  levelImportInfo: [],
  levelImportInfoStatus: LoadingStatus.Idle,
};

export const fetchLevels = createAsyncThunk(
  'levels/fetchLevels',
  async (params = {}) =>
    await levelService.getLevels(
      params.level_id,
      params.display_name,
      params.description,
      params.color,
      params.order,
      params.previous_level_id,
      params.previous_level_display_name,
      params.startIndex,
      params.stopIndex,
      params.searchString,
      params.sort
    )
);

export const fetchLevelById = createAsyncThunk(
  'levels/fetchLevelById',
  async (levelId) => {
    const response = await levelService.getById(levelId);
    response.id = levelId;
    return response;
  }
);

export const fetchSkillUsageById = createAsyncThunk(
  'groups/fetchSkillUsageById',
  async (levelId) => await levelService.getLevelUsageById(levelId)
);

export const createLevel = createAsyncThunk(
  'levels/createLevel',
  async (params) => await levelService.create(params)
);

export const updateLevel = createAsyncThunk(
  'levels/updateLevel',
  async (params) => await levelService.update(params)
);

export const deleteLevel = createAsyncThunk(
  'levels/deleteLevel',
  async (level_id) => {
    const response = await levelService.delete(level_id);
    return response;
  }
);

export const deleteLevelArray = createAsyncThunk(
  'levels/deleteLevelArray',
  async (levelIds) => {
    const response = await levelService.deleteLevelArray(levelIds);
    return response;
  }
);

export const getLevelImportSampleCsv = createAsyncThunk(
  'levels/getLevelImportSampleCsv',
  async () => await levelService.getLevelImportCsv()
);

export const uploadLevelImportCsv = createAsyncThunk(
  'levels/uploadLevelImportCsv',
  async (params) => await levelService.uploadLevelImportCsv(params)
);

export const exportLevelCsv = createAsyncThunk(
  'levels/exportLevelCsv',
  async (params = {}) =>
    await levelService.exportLevelCsv(
      params.level_id,
      params.display_name,
      params.description,
      params.color,
      params.order,
      params.previous_level_id,
      params.previous_level_display_name
    )
);

export const getImportLevelHelp = createAsyncThunk(
  'levels/getImportLevelHelp',
  async (params) => await levelService.getLevelImportHelp(params)
);

const levelSlice = createSlice({
  name: 'skills',
  initialState,
  reducers: {
    resetLevelState: (state) => initialState,
    resetLevelInfoState: (state) => {
      state.levelImportInfoStatus = LoadingStatus.Idle;
    },
  },
  extraReducers: {
    [fetchLevels.pending]: (state, action) => {
      state.levelStatus = LoadingStatus.Loading;
    },
    [fetchLevels.fulfilled]: (state, action) => {
      state.levelPageInfo = action.payload.pageInfo;
      state.levelStatus = LoadingStatus.Loaded;

      if (action.meta.arg.reset) {
        state.loadedLevelsMap = {};
      }
      const startIndex = action.meta.arg.startIndex;
      const stopIndex = startIndex + action.payload.levels.length - 1;

      for (var i = startIndex; i <= stopIndex; i++) {
        state.loadedLevelsMap[i] = LoadingStatus.Loaded;
      }

      // In case we didn't load as many as requested
      for (
        let j = stopIndex + 1;
        j <= action.payload?.pageInfo?.TotalCount;
        j++
      ) {
        delete state.loadedLevelsMap[j];
      }

      if (action.meta.arg.reset) {
        state.levels = action.payload.levels;
      } else {
        state.levels = state.levels.concat(action.payload.levels);
      }
    },
    [fetchLevels.rejected]: (state, action) => {
      state.levelStatus = LoadingStatus.Failed;
    },
    [createLevel.fulfilled]: (state, action) => {
      state.levelStatus = LoadingStatus.Idle;
    },
    [updateLevel.fulfilled]: (state, action) => {
      state.levelStatus = LoadingStatus.Idle;
    },
    [deleteLevel.fulfilled]: (state, action) => {
      state.levelStatus = LoadingStatus.Idle;
    },
    [deleteLevelArray.fulfilled]: (state, action) => {
      state.levelStatus = LoadingStatus.Idle;
    },
    [deleteLevelArray.rejected]: (state, action) => {
      state.status = SavingStatus.Failed;
      state.error = action.error.message;
    },
    [fetchLevelById.fulfilled]: (state, action) => {
      state.displayLevel = action.payload;
    },
    [fetchSkillUsageById.fulfilled]: (state, action) => {
      state.levelUsage = action.payload;
    },
    [getLevelImportSampleCsv.fulfilled]: (state, action) => {
      if (action?.payload) {
        const url = window.URL.createObjectURL(new Blob([action.payload]));
        const link = document.createElement('a');
        link.href = url;
        let downloadName =
          action.payload.headers?.filename ?? 'Level_import.csv';
        link.setAttribute('download', downloadName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    },
    [getLevelImportSampleCsv.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    [exportLevelCsv.fulfilled]: (state, action) => {
      if (action?.payload) {
        const url = window.URL.createObjectURL(new Blob([action.payload]));
        const link = document.createElement('a');
        link.href = url;
        let downloadName =
          action.payload.headers?.filename ?? 'Level_export.csv';
        link.setAttribute('download', downloadName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    },
    [exportLevelCsv.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    [getImportLevelHelp.pending]: (state, action) => {
      state.levelImportInfoStatus = LoadingStatus.Loading;
    },
    [getImportLevelHelp.fulfilled]: (state, action) => {
      state.levelImportInfo = action.payload;
      state.levelImportInfoStatus = LoadingStatus.Loaded;
    },
    [getImportLevelHelp.rejected]: (state, action) => {
      state.levelImportInfoStatus = LoadingStatus.Failed;
    },
  },
});

export const selectLevel = (state) => state.levels.levels;
export const getLevelStatus = (state) => state.levels.levelStatus;
export const getLevelPageInfo = (state) => state.levels.levelPageInfo;
export const getLevelUsage = (state) => state.levels.levelUsage;
export const getLevelInfoStatus = (state) => state.levels.levelImportInfoStatus;

export const { resetLevelState, resetLevelInfoState } = levelSlice.actions;

export default levelSlice.reducer;
