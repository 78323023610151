import { useMemo, useState } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { map, omit } from 'lodash';
import { accordionStyle } from './style';
import { mdTheme } from 'theme';
import { MdClose, MdExpandMore } from 'react-icons/md';

const FilterAccordion = ({
  children,
  expandedId,
  filteredTitles,
  styles,
  defaultExpanded = false,
  onResetFilters,
}) => {
  //#region Constants
  const classes = accordionStyle(mdTheme);
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  const [expanded, setExpanded] = useState(defaultExpanded);
  //#endregion State

  //#region Selectors
  //#endregion Selectors

  //#region Refs
  //#endregion Refs

  //#region Effects
  const filters = useMemo(() => {
    const removeSortedKeys = omit(filteredTitles, [
      'startIndex',
      'stopIndex',
      'reset',
      'sort',
      'searchString',
      'start_index',
      'stop_index',
      'other_start_index',
      'other_stop_index',
      'custom_expiry_date',
      'createdDate',
    ]);

    const consolidateFilters = Object.entries(removeSortedKeys).reduce(
      (acc, [key, value]) => {
        if (value && typeof value === 'object') {
          if (Array.isArray(value)) {
            let arrayData = value
              .filter((val) => val !== null && val !== undefined)
              .map((val) => {
                return val?.text === ' ' ? '-' : val?.text;
              });
            return { ...acc, [key]: { text: arrayData } };
          } else {
            return {
              ...acc,
              [key]: {
                text: value?.text === ' ' ? '-' : value?.text,
              },
            };
          }
        }
        return { ...acc, [key]: { text: value === ' ' ? '-' : value } };
      },
      {}
    );
    const hasFilters = map(consolidateFilters, 'text').filter((value) => {
      return (
        value !== null &&
        value !== undefined &&
        !(Array.isArray(value) && value.length === 0)
      );
    });
    return !!hasFilters.length > 0
      ? hasFilters.filter(Boolean).join(' | ')
      : 'Filters';
  }, [filteredTitles]);

  //#endregion Effects

  //#region Methods
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleClearFilter = (e) => {
    e.stopPropagation();
    onResetFilters();
  };
  //#endregion Methods

  //#region Render time calcs
  //#endregion Render time calcs

  //#region Render
  return (
    <>
      <Accordion
        expanded={expanded === expandedId || expanded}
        onChange={handleChange(expandedId)}
        sx={styles}
      >
        <AccordionSummary
          expandIcon={<MdExpandMore />}
          aria-controls="generate-report"
          id="generate-report"
          className={classes.accordionSummary}
        >
          <Typography sx={{ flexShrink: 0 }}>
            {!expanded ? (!filters ? 'Filters' : filters) : 'Filters'}
          </Typography>
          <Tooltip title="Clear All Filter">
            <IconButton
              aria-label="close"
              onClick={(e) => handleClearFilter(e)}
              className="clearFilter"
              size="small"
            >
              <MdClose className="react-icon" />
            </IconButton>
          </Tooltip>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          {children}
        </AccordionDetails>
      </Accordion>
    </>
  );
  //#endregion Render
};
export { FilterAccordion };
