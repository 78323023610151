import { makeStyles } from '@mui/styles';

const makeFormStyles = makeStyles((theme) => ({
  '@keyframes rotate': {
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' },
  },
  paper: {
    background: 'transparent',
  },
  loadingSvg: {
    height: '100px',
    width: '100px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    spacing: '10px',
  },
  linkForm: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
  },
  formHeader: {
    fontWeight: 'bold',
    fontSize: '20px',
    color: '#2D3D50',
    marginBottom: '10px',
  },
  input: {
    border: '1px solid #BDC3C7',
    fontSize: '18px',
    marginTop: '10px',
    padding: '10px',
    borderRadius: '5px',
    color: '#757575',
    '& option': {
      color: '#2D3D50',
    },
  },
  materialInput: {
    fontSize: '18px',
    color: '#2D3D50',
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  infoIcon: {
    width: '20px',
    height: '20px',
    marginRight: '5px',
  },
  submit: {
    backgroundColor: `${theme.colors.background.drawer} !important`,
    color: `${theme.palette.common.white} !important`,
    border: '0px !important',
    padding: '10px !important',
    borderRadius: '5px !important',
    fontSize: '18px !important',
    textTransform: 'uppercase !important',
    marginTop: '10px !important',
    cursor: 'pointer !important',
    '&:disabled': {
      pointerEvents: 'none',
      cursor: 'default !important',
      color: 'rgba(0, 0, 0, 0.26) !important',
      boxShadow: 'none',
      backgroundColor: 'rgba(0, 0, 0, 0.12)  !important',
      '&:hover': {
        pointerEvents: 'none',
        cursor: 'default !important',
        color: 'rgba(0, 0, 0, 0.26) !important',
        boxShadow: 'none',
        backgroundColor: 'rgba(0, 0, 0, 0.12)  !important',
      },
    },
    '&:hover': {
      backgroundColor: `${theme.palette.secondary.main} !important`,
      color: `${theme.palette.common.white} !important`,
      filter: 'brightness(100%)',
    },
  },
  cancel: {
    backgroundColor: theme.colors.background.drawer,
    color: theme.palette.background.default,
    padding: '10px',
    borderRadius: '5px',
    fontSize: '18px',
    textTransform: 'uppercase',
    marginTop: '10px',
    cursor: 'pointer',
    border: '0px',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.background.default,
      filter: 'brightness(90%)',
    },
  },
  inputErr: {
    color: '#E84C3C',
    margin: '4px 0',
    fontSize: '0.8rem',
    textAlign: 'left',
    fontWeight: 400,
    lineHeight: 1.66,
  },
  formErrorDiv: {
    color: '#f44336',
    marginLeft: '14px',
    marginRight: '14px',
    marginTop: '4px',
    fontSize: '0.75rem',
    fontFamily: 'Open Sans',
    fontWeight: 400,
    lineHeight: 1.66,
    marginBottom: '10px',
  },
  formErrorField: {
    borderColor: '#f44336',
    color: '#f44336',
    '& option': {
      color: '#2D3D50',
    },
    '&::placeholder': {
      color: '#f44336',
    },
  },
  selectWrap: {
    transform: 'translate(14px, 12px) scale(1)',
    '.MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(1)',
    },
  },
  fieldWrapper: {
    width: '100%',
  },
  smeLabel: {
    lineHeight: '27px',
  },
  customSelect: {
    '& > div': {
      marginTop: '0',
    },
  },
  setOpacity: {
    opacity: '0.5',
  },
  revertOpacity: {
    opacity: '1',
  },
  customClearIcon: {
    '& .MuiAutocomplete-hasPopupIcon.css-abx3jw-MuiAutocomplete-root .MuiOutlinedInput-root':
      {
        paddingRight: '50px',
      },
  },
  cardHeader: {
    backgroundColor: theme.palette.common.grey,
    display: 'flex',
    alignItems: 'center',
    padding: '1rem',
  },
  cardIcon: {
    width: '1.5rem',
    height: '1.5rem',
    marginRight: '0.5rem',
    flex: '0 0 auto',
  },
  cardWrapper: {
    margin: '1rem 0 0',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: theme.palette.common.grey,
    '& .MuiCollapse-hidden': {
      visibility: 'visible',
    },
  },
}));

export { makeFormStyles };
