import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClientService } from 'features/services/apiClients.service';
import { LoadingStatus, SavingStatus } from 'helpers';

const initialState = {
  status: LoadingStatus.Idle,
  error: null,
  apiClients: [],
  apiClientsStatus: LoadingStatus.Idle,
  apiClientPageInfo: {},
  displayApiClient: {},
  apiClientsUsage: [],
  loadedApiClientsMap: {},
};

export const fetchApiClient = createAsyncThunk(
  'apiClients/fetchApiClient',
  async (params) =>
    await apiClientService.getApiClient(
      params.startIndex,
      params.stopIndex,
      params.searchString,
      params.sort
    )
);

export const fetchApiClientsById = createAsyncThunk(
  'apiClients/fetchApiClientsById',
  async (apiClientId) => {
    const response = await apiClientService.getById(apiClientId);
    response.id = apiClientId;
    return response;
  }
);

export const fetchApiClientsUsageById = createAsyncThunk(
  'costCenter/fetchApiClientsUsageById',
  async (apiClientId) =>
    await apiClientService.getApiClientsUsageById(apiClientId)
);

export const createApiClient = createAsyncThunk(
  'apiClients/createApiClient',
  async (params) => await apiClientService.create(params)
);

export const updateApiClient = createAsyncThunk(
  'apiClients/updateApiClient',
  async (params) => await apiClientService.update(params)
);

export const deleteApiClient = createAsyncThunk(
  'apiClients/deleteApiClient',
  async (api_client_id) => {
    const response = await apiClientService.delete(api_client_id);
    return response;
  }
);

export const deleteApiClientsArray = createAsyncThunk(
  'apiClients/deleteApiClientsArray',
  async (apiClientId) => {
    const response = await apiClientService.deleteArray(apiClientId);
    return response;
  }
);

const apiClientSlice = createSlice({
  name: 'apiClients',
  initialState,
  reducers: {
    resetApiClientState: (state) => initialState,
  },
  extraReducers: {
    [fetchApiClient.pending]: (state, action) => {
      state.apiClientsStatus = LoadingStatus.Loading;
    },
    [fetchApiClient.fulfilled]: (state, action) => {
      state.apiClientPageInfo = action.payload.pageInfo;
      state.apiClientsStatus = LoadingStatus.Loaded;

      if (action.meta.arg.reset) {
        state.loadedApiClientsMap = {};
      }

      const startIndex = action.meta.arg.startIndex;
      const stopIndex = startIndex + action.payload?.apiClients.length - 1;

      for (var i = startIndex; i <= stopIndex; i++) {
        state.loadedApiClientsMap[i] = LoadingStatus.Loaded;
      }

      // In case we didn't load as many as requested
      for (
        let j = stopIndex + 1;
        j <= action.payload?.pageInfo?.TotalCount;
        j++
      ) {
        delete state.loadedApiClientsMap[j];
      }

      if (action.meta.arg.reset) {
        state.apiClients = action.payload.apiClients;
      } else {
        state.apiClients = state.apiClients.concat(action.payload.apiClients);
      }
    },
    [fetchApiClient.rejected]: (state, action) => {
      state.apiClientsStatus = LoadingStatus.Failed;
    },
    [createApiClient.fulfilled]: (state, action) => {
      state.apiClientsStatus = LoadingStatus.Idle;
    },
    [updateApiClient.fulfilled]: (state, action) => {
      state.apiClientsStatus = LoadingStatus.Idle;
    },
    [deleteApiClient.fulfilled]: (state, action) => {
      state.apiClientsStatus = LoadingStatus.Idle;
    },
    [deleteApiClientsArray.fulfilled]: (state, action) => {
      state.apiClientsStatus = LoadingStatus.Idle;
    },
    [deleteApiClientsArray.rejected]: (state, action) => {
      state.status = SavingStatus.Failed;
      state.error = action.error.message;
    },
    [fetchApiClientsById.fulfilled]: (state, action) => {
      state.displayApiClient = action.payload;
    },
    [fetchApiClientsUsageById.fulfilled]: (state, action) => {
      state.apiClientsUsage = action.payload;
    },
  },
});

export const selectApiClient = (state) => state.apiClients.apiClients;
export const getApiClientStatus = (state) => state.apiClients.apiClientsStatus;
export const getApiClientPageInfo = (state) =>
  state.apiClients.apiClientPageInfo;
export const getApiClientsUsage = (state) => state.apiClients.apiClientsUsage;
export const getAPiClientsById = (state) => state.apiClients.displayApiClient;
export const { resetApiClientState } = apiClientSlice.actions;

export default apiClientSlice.reducer;
