import {
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useEffect } from 'react';
import pluralize from 'pluralize';
import moment from 'moment/moment';
import { FiEye } from 'react-icons/fi';
import { MdEdit } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ReactComponent as SkillIcon } from '../../../assets/skills_icon.svg';
import { NoDataMessage } from 'shared/components/_Tables/NoDataMessage';
import { Each } from 'helpers/Each';
import { LoadingStatus, employeeRoleEnums } from 'helpers';
import {
  fetchEmployeeSkill,
  getEmployeeSkillStatus,
  selectEmployeeSkill,
} from 'features/Teams/teamSlice';

const AchievedSkillsTable = ({
  activeUser,
  handleSkillCertificationForm,
  employees,
  classes,
  setDataFormate,
}) => {
  //#region Constants
  const dispatch = useDispatch();
  const canEditSkills = employees?.permissions?.includes(
    employeeRoleEnums?.EditSkills
  );
  let params = useParams();
  let paramsId = params.id;
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  //#endregion State

  //#region Selectors
  const employeeSkills = useSelector(selectEmployeeSkill);
  const itemEmpSkillStatus = useSelector(getEmployeeSkillStatus);
  //#endregion Selectors

  //#region Refs
  //#endregion Refs

  //#region Effects
  useEffect(() => {
    const getEmpSkill = async (id) => {
      await dispatch(fetchEmployeeSkill(id));
    };
    if (paramsId && itemEmpSkillStatus === LoadingStatus.Idle) {
      getEmpSkill(paramsId);
    }
  }, [dispatch, itemEmpSkillStatus, paramsId]);
  //#endregion Effects

  //#region Methods
  //#endregion Methods

  //#region Render time calcs
  //#endregion Render time calcs

  //#region Render

  return (
    <Box className={classes.cardWrapper}>
      {employeeSkills && employeeSkills?.length > 0 ? (
        <>
          <Typography className={classes.cardHeader}>
            <SkillIcon className={classes.cardIcon} />
            Achieved {pluralize(activeUser?.label_skill, 2)}
          </Typography>
          <Grid
            container
            sx={{
              padding: '1rem',
              maxHeight: employees?.reports?.length > 0 ? '220px' : '420px',
              overflow: 'auto',
            }}
          >
            <Grid item={true} xs={12}>
              <TableContainer>
                <Table>
                  <TableHead
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    <TableCell>
                      <Typography fontWeight={'bold'} marginBottom="0.5rem">
                        {pluralize(
                          activeUser?.label_skill.trim(),
                          employeeSkills?.length
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontWeight={'bold'} marginBottom="0.5rem">
                        Expires
                      </Typography>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableHead>
                  <TableBody>
                    <Each
                      of={employeeSkills}
                      render={(item) => (
                        <TableRow>
                          <TableCell
                            style={{
                              width: '45%',
                              padding: '5px 16px',
                            }}
                          >
                            <Typography
                              variant="body1"
                              sx={{
                                lineHeight: '1.5rem',
                              }}
                            >
                              {item.skill_display_name}
                            </Typography>
                          </TableCell>
                          <TableCell
                            style={{
                              width: '45%',
                              padding: '5px 16px',
                            }}
                          >
                            <Typography variant="body1">
                              {item.expires === 'Never'
                                ? 'Never'
                                : moment(
                                    setDataFormate(new Date())
                                  ).isSameOrBefore(setDataFormate(item.expires))
                                ? setDataFormate(item.expires)
                                : 'Lapsed'}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <IconButton
                              onClick={() =>
                                handleSkillCertificationForm(
                                  item?.skill_id,
                                  employees?.employee_id,
                                  canEditSkills,
                                  item?.skill_display_name
                                )
                              }
                            >
                              {canEditSkills && (
                                <MdEdit
                                  style={{
                                    fontSize: '1.3rem',
                                  }}
                                />
                              )}
                              {!canEditSkills && (
                                <FiEye
                                  style={{
                                    fontSize: '1.3rem',
                                  }}
                                />
                              )}
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      )}
                    />
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </>
      ) : (
        <NoDataMessage
          message={'Employee Skills Not Found'}
          setHeight="150px"
        />
      )}
    </Box>
  );
  //#endregion Render
};
export { AchievedSkillsTable };
