import { Typography } from '@mui/material';

const NoDataMessage = ({ message, setHeight }) => {
  //#region Constants
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  //#endregion State

  //#region Selectors
  //#endregion Selectors

  //#region Refs
  //#endregion Refs

  //#region Effects
  //#endregion Effects

  //#region Methods
  //#endregion Methods

  //#region Render time calcs
  //#endregion Render time calcs

  //#region Render

  return (
    <>
      <div
        style={{
          height: setHeight ? setHeight : 'calc(100vh - 295px)',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h6" align="center" paragraph={true}>
          {message}
        </Typography>
      </div>
    </>
  );
  //#endregion Render
};
export { NoDataMessage };
