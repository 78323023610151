import React from 'react';
import { Box, Typography } from '@mui/material';
import { mdTheme } from 'theme';
import { makeAuditInfoStyles } from './style';
import { FormActions } from 'helpers/form-action';
import moment from 'moment/moment';

const AuditInfoCard = ({ auditInfo, formAction, auditInfoHeader }) => {
  const classes = makeAuditInfoStyles(mdTheme);
  const setDataFormate = (dateVal) => {
    return dateVal ? moment(dateVal).local().format('YYYY-MM-DD') : 'N/A';
  };

  return (
    <>
      {auditInfo && formAction.id === FormActions.Edit.id && (
        <Box className={classes.cardWrapper}>
          <>
            <Typography className={classes.cardHeader}>
              {auditInfoHeader}
            </Typography>
            <Box
              sx={{
                padding: '1rem',
                overflow: 'auto',
              }}
            >
              <Typography variant="body2">
                <strong>Author: </strong> {auditInfo?.author}
              </Typography>
              <Typography variant="body2">
                <strong>Creation: </strong>{' '}
                {setDataFormate(auditInfo?.creation)}
              </Typography>
              <Typography variant="body2">
                <strong>Last Modified: </strong>{' '}
                {setDataFormate(auditInfo?.modified)}
              </Typography>
            </Box>
          </>
        </Box>
      )}
    </>
  );
};

export default AuditInfoCard;
