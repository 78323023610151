import { useEffect } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import { MdClose, MdInfo } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { resetEmployeeCls } from 'features/Teams/teamSlice';
import { FaCheckSquare } from 'react-icons/fa';
import { MdCheckBoxOutlineBlank } from 'react-icons/md';

import { modalStyles } from 'components/Modals/Modals.styles';
import { Each } from 'helpers/Each';

// import { useStyles } from './styles';

const ItemCapabilitySkillModal = ({ isOpen, handleClose, employeeClsData }) => {
  //#region Constants
  const classes = modalStyles();
  const dispatch = useDispatch();
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  //#endregion State

  //#region Selectors
  //#endregion Selectors

  //#region Refs
  //#endregion Refs

  //#region Effects
  useEffect(() => {
    return () => {
      dispatch(resetEmployeeCls);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //#endregion Effects

  //#region Methods
  if (!isOpen || !employeeClsData) {
    return null;
  }
  //#endregion Methods

  //#region Render time calcs
  //#endregion Render time calcs

  //#region Render

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '100%', maxHeight: '70vh' } }}
      open={isOpen}
      onClose={() => handleClose(false)}
    >
      <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
        {`${employeeClsData?.capability} - ${employeeClsData?.level}`}
        <IconButton
          onClick={() => handleClose(false)}
          sx={{ marginLeft: 'auto' }}
        >
          <MdClose />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography
                    variant="body2"
                    fontWeight={'bold'}
                    sx={{ minWidth: '80px' }}
                  >
                    Has Skill
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" fontWeight={'bold'}>
                    Skill
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" fontWeight={'bold'}>
                    Description
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="body2"
                    fontWeight={'bold'}
                    sx={{ minWidth: '120px' }}
                  >
                    Expiration Date
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {employeeClsData?.skills?.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    No Data found.
                  </TableCell>
                </TableRow>
              ) : (
                <Each
                  of={employeeClsData?.skills}
                  render={(skill) => (
                    <TableRow key={skill.skill_id}>
                      <TableCell>
                        {skill.has_skill ? (
                          <FaCheckSquare className={classes.checkBoxIcons} />
                        ) : (
                          <MdCheckBoxOutlineBlank
                            className={classes.checkBoxIcons}
                          />
                        )}
                      </TableCell>
                      <TableCell>{skill.skill_display_name}</TableCell>
                      <TableCell>
                        {skill?.skill_description ? (
                          <Tooltip title={skill?.skill_description}>
                            <IconButton>
                              <MdInfo />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          ' '
                        )}
                      </TableCell>
                      <TableCell>{skill.expiry_date || '-'}</TableCell>
                    </TableRow>
                  )}
                />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
  //#endregion Render
};
export { ItemCapabilitySkillModal };
