import { makeStyles } from '@mui/styles';

export const employeeStyle = makeStyles((theme) => ({
  cardHeader: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.colors.textWhite,
    display: 'flex',
    alignItems: 'center',
    padding: '1rem',
  },
  cardIcon: {
    width: '1.5rem',
    height: '1.5rem',
    marginRight: '0.5rem',
    flex: '0 0 auto',
  },
  cardWrapper: {
    margin: '1rem 0',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: theme.palette.common.grey,
    '& .MuiCollapse-hidden': {
      visibility: 'visible',
    },
  },
  expandIcon: {
    color: theme.colors.textWhite,
    fontSize: '1.5rem',
  },
}));
