import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { jobService } from 'features/services/adminJob.service';
import { LoadingStatus, SavingStatus } from 'helpers';

const initialState = {
  status: LoadingStatus.Idle,
  error: null,
  jobs: [],
  jobStatus: LoadingStatus.Idle,
  jobPageInfo: {},
  displayJobs: {},
  jobUsage: [],
  loadedJobsMap: {},
  jobImportInfo: [],
  jobImportInfoStatus: LoadingStatus.Idle,
};

export const fetchJobs = createAsyncThunk(
  'jobs/fetchJobs',
  async (params = {}) =>
    await jobService.getJobs(
      params.startIndex,
      params.stopIndex,
      params.searchString,
      params.sort,
      params.display_name,
      params.series_id,
      params.family_display_name,
      params.family_series_id
    )
);

export const fetchJobsById = createAsyncThunk(
  'jobs/fetchJobsById',
  async (jobId) => {
    const response = await jobService.getById(jobId);
    response.id = jobId;
    return response;
  }
);

export const fetchJobsUsageById = createAsyncThunk(
  'groups/fetchJobsUsageById',
  async (jobId) => await jobService.getjobUsageById(jobId)
);

export const createJob = createAsyncThunk(
  'jobs/createJob',
  async (params) => await jobService.create(params)
);

export const updateJob = createAsyncThunk(
  'jobs/updateJob',
  async (params) => await jobService.update(params)
);

export const deleteJob = createAsyncThunk('jobs/deleteJob', async (job_id) => {
  const response = await jobService.delete(job_id);
  return response;
});

export const deleteJobsArray = createAsyncThunk(
  'assets/deleteJobsArray',
  async (jobIds) => {
    const response = await jobService.deleteJobsArray(jobIds);
    return response;
  }
);

export const getJobSliceImportSampleCsv = createAsyncThunk(
  'jobs/getJobSliceImportSampleCsv',
  async () => await jobService.getJobImportCsv()
);

export const uploadJobSliceImportCsv = createAsyncThunk(
  'jobs/uploadJobSliceImportCsv',
  async (params) => await jobService.uploadJobImportCsv(params)
);

export const exportJobCsv = createAsyncThunk(
  'jobs/exportJobCsv',
  async (params) =>
    await jobService.exportJobCsv(
      params.display_name,
      params.series_id,
      params.family_display_name,
      params.family_series_id
    )
);

export const getImportJobHelp = createAsyncThunk(
  'jobs/getImportJobHelp',
  async (params) => await jobService.getJobImportHelp(params)
);

const jobSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    resetJobState: (state) => initialState,
    resetJobInfoState: (state) => {
      state.jobImportInfoStatus = LoadingStatus.Idle;
    },
  },
  extraReducers: {
    [fetchJobs.pending]: (state, action) => {
      state.jobStatus = LoadingStatus.Loading;
    },
    [fetchJobs.fulfilled]: (state, action) => {
      state.jobPageInfo = action.payload.pageInfo;
      state.jobStatus = LoadingStatus.Loaded;

      if (action.meta.arg.reset) {
        state.loadedJobsMap = {};
      }

      const startIndex = action.meta.arg.startIndex;
      const stopIndex = startIndex + action.payload.jobs.length - 1;

      for (var i = startIndex; i <= stopIndex; i++) {
        state.loadedJobsMap[i] = LoadingStatus.Loaded;
      }

      // In case we didn't load as many as requested
      for (
        let j = stopIndex + 1;
        j <= action.payload?.pageInfo?.TotalCount;
        j++
      ) {
        delete state.loadedJobsMap[j];
      }

      if (action.meta.arg.reset) {
        state.jobs = action.payload.jobs;
      } else {
        state.jobs = state.jobs.concat(action.payload.jobs);
      }
    },
    [fetchJobs.rejected]: (state, action) => {
      state.jobStatus = LoadingStatus.Failed;
    },
    [createJob.fulfilled]: (state, action) => {
      state.jobStatus = LoadingStatus.Idle;
    },
    [updateJob.fulfilled]: (state, action) => {
      state.jobStatus = LoadingStatus.Idle;
    },
    [deleteJob.fulfilled]: (state, action) => {
      state.jobStatus = LoadingStatus.Idle;
    },
    [deleteJobsArray.fulfilled]: (state, action) => {
      state.jobStatus = LoadingStatus.Idle;
    },
    [deleteJobsArray.rejected]: (state, action) => {
      state.jobStatus = SavingStatus.Failed;
      state.error = action.error.message;
    },
    [fetchJobsById.fulfilled]: (state, action) => {
      state.displayJobs = action.payload;
    },
    [fetchJobsUsageById.fulfilled]: (state, action) => {
      state.jobUsage = action.payload;
    },
    [getJobSliceImportSampleCsv.fulfilled]: (state, action) => {
      if (action?.payload) {
        const url = window.URL.createObjectURL(new Blob([action.payload]));
        const link = document.createElement('a');
        link.href = url;
        let downloadName = action.payload.headers?.filename ?? 'job_import.csv';
        link.setAttribute('download', downloadName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    },
    [getJobSliceImportSampleCsv.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    [exportJobCsv.fulfilled]: (state, action) => {
      if (action?.payload) {
        const url = window.URL.createObjectURL(new Blob([action.payload]));
        const link = document.createElement('a');
        link.href = url;
        let downloadName = action.payload.headers?.filename ?? 'job_export.csv';
        link.setAttribute('download', downloadName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    },
    [exportJobCsv.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    [getImportJobHelp.pending]: (state, action) => {
      state.jobImportInfoStatus = LoadingStatus.Loading;
    },
    [getImportJobHelp.fulfilled]: (state, action) => {
      state.jobImportInfo = action.payload;
      state.jobImportInfoStatus = LoadingStatus.Loaded;
    },
    [getImportJobHelp.rejected]: (state, action) => {
      state.jobImportInfoStatus = LoadingStatus.Failed;
    },
  },
});

export const selectJob = (state) => state.jobs.jobs;
export const getJobStatus = (state) => state.jobs.jobStatus;
export const getJobPageInfo = (state) => state.jobs.jobPageInfo;
export const getJobUsage = (state) => state.jobs.jobUsage;
export const getJobInfoStatus = (state) => state.jobs.jobImportInfoStatus;

export const { resetJobState, resetJobInfoState } = jobSlice.actions;

export default jobSlice.reducer;
