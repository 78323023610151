import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    popupActionContainer: {
      display: 'flex',
      alignItems: 'center',
      color: '#7E8C8D',
      padding: '10px',
      fontSize: '14px',
      '&:hover': {
        cursor: 'pointer',
        transform: 'scale(1.05)',
      },
    },
    popupActionIcon: {
      display: 'flex',
      justifyContent: 'center',
      '& svg': {
        height: '20px',
        width: '20px',
      },
    },
    popupActionLabel: {
      marginLeft: '10px',
      textTransform: 'capitalize',
    },
  })
);

export { useStyles };
