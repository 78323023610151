import { config } from 'ectl-config';
import { getNetworkService } from 'helpers';
import { buildFilters } from 'hooks/buildFilter';

const accountService = { config: config };
const networkService = getNetworkService({
  config: config,
  checkAuth: accountService.checkAuth,
});
const baseUrl = `${config.apiUrl}/Report`;

const getReports = async (
  cost_center = null,
  supervisor_employee_id = null,
  capability_id = null,
  job_id = null,
  job_role_id = null,
  report_expiry = null,
  custom_expiry_date = null,
  office_id = null,
  command_id = null,
  depot_id = null,
  startIndex = 0,
  stopIndex = 10
) => {
  const filters = buildFilters(
    ['cost_center_id', cost_center],
    ['supervisor_employee_id', supervisor_employee_id],
    ['capability_id', capability_id],
    ['job_id', job_id],
    ['job_role_id', job_role_id],
    ['report_expiry', report_expiry],
    ['custom_expiry_date', custom_expiry_date],
    ['office_id', office_id],
    ['command_id', command_id],
    ['depot_id', depot_id]
  );

  const params = {
    filters: filters,
    start_index: startIndex,
    stop_index: stopIndex,
  };
  const query = new URLSearchParams(params).toString();

  const response = await networkService.get(`${baseUrl}?${query}`, 30000);
  const pageInfo = response.headers['x-pagination'];
  return { pageInfo: pageInfo, reports: response.data };
};

const getReportsCsv = async (
  cost_center = null,
  supervisor_employee_id = null,
  capability_id = null,
  job_id = null,
  job_role_id = null,
  office_id = null,
  report_expiry = null,
  custom_expiry_date = null,
  command_id = null,
  depot_id = null
) => {
  const filters = buildFilters(
    ['cost_center_id', cost_center],
    ['supervisor_employee_id', supervisor_employee_id],
    ['capability_id', capability_id],
    ['job_id', job_id],
    ['job_role_id', job_role_id],
    ['office_id', office_id],
    ['report_expiry', report_expiry],
    ['custom_expiry_date', custom_expiry_date],
    ['command_id', command_id],
    ['depot_id', depot_id]
  );

  const params = {
    filters: filters,
  };
  const query = new URLSearchParams(params).toString();

  const response = await networkService.get(
    `${baseUrl}/download?${query}`,
    300000
  );

  return response?.data;
};

export const reportService = {
  getReports,
  getReportsCsv,
};
