import { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { useDispatch, useSelector } from 'react-redux';

import { resetDropDownState } from 'features/AdminSettingsDropDown/dropDownSlice';
import { selectDepotReport } from 'features/depotReports/depotReportSlice';
import { useNavigate } from 'react-router-dom';
import { DepotReportFilter } from './DepotReportFilter';

const DepotTreeMap = ({
  itemDepotReportPayload,
  setItemDepotReportPayload,
}) => {
  //#region Constants
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initValues = {
    cost_center_id: null,
    office_id: null,
    job_id: null,
    job_role_id: null,
    custom_expiry_date: null,
    supervisor_employee_id: null,
    capability_id: null,
    report_expiry: null,
  };
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  const [depotReportData, setDepotReportData] = useState({
    ...initValues,
  });
  const [treeMapPayload, setTreeMapPayload] = useState(null);
  //#endregion State

  //#region Selectors
  const depotReports = useSelector(selectDepotReport);
  //#endregion Selectors

  //#region Refs
  const updateTreeMapData = (treeMap) => {
    return treeMap?.map((item) => {
      const childItem = {
        name: item?.display_name,
        label: item?.command_id,
        children: item?.children.map((val) => {
          const node = {
            value: val.size,
            name: val.display_name,
            id: val.depot_id,
            colorSaturation: [0.35, 0.5],
            itemStyle: {
              color: getColorBasedOnPercentage(val?.percentage),
            },
          };

          if (val.children && val.children.length > 0) {
            node.children = updateTreeMapData(val.children);
          }

          return node;
        }),
      };
      return childItem;
    });
  };

  function getColorBasedOnPercentage(percentage) {
    let color;

    if (percentage <= 0.8) {
      color = '#D51D1F';
    } else if (percentage <= 0.85) {
      color = '#D7890B';
    } else if (percentage <= 0.9) {
      color = '#DCD00A';
    } else if (percentage <= 0.95) {
      color = '#9FDE09';
    } else {
      color = '#49DE10';
    }

    // top green
    return color;
  }
  //#endregion Refs

  //#region Effects
  useEffect(() => {
    const levelOption = () => {
      return [
        {
          itemStyle: {
            borderColor: '#000',
            borderWidth: 0,
            gapWidth: 1,
          },
          upperLabel: {
            show: false,
          },
        },
        {
          itemStyle: {
            borderColor: '#555',
            borderWidth: 3,
            gapWidth: 1,
          },
          emphasis: {
            itemStyle: {
              borderColor: '#ddd',
            },
          },
        },
        {
          colorSaturation: [0.35, 0.5],
          itemStyle: {
            borderWidth: 3,
            gapWidth: 1,
            borderColorSaturation: 0.6,
          },
        },
      ];
    };
    const treeMapData = {
      tooltip: {
        formatter: function (params) {
          const name = params.name;
          const value = params.value;
          return `${name}<br>Employee Count: ${value}`;
        },
      },
      color: 'transparent',
      series: {
        name: 'Cost Center',
        type: 'treemap',
        roam: 'scale',
        levels: levelOption(),
        data: updateTreeMapData(depotReports),
        label: {
          show: true,
          fontSize: 16,
          color: '#fff',
        },
        emphasis: {
          upperLabel: {
            color: '#000',
          },
        },
        upperLabel: {
          show: true,
          height: 20,
          color: 'white',
          fontSize: 14,
        },
      },
    };

    setTreeMapPayload(treeMapData);
  }, [depotReports]);

  useEffect(() => {
    return () => {
      dispatch(resetDropDownState());
    };
  }, []);

  //#endregion Effects

  //#region Methods
  const handleDepotSelect = (depotId) => {
    navigate(`/costcenter-report/${depotId}`);
  };

  const chartEvents = {
    click: (params) => {
      const depotId = params?.data?.id;
      if (depotId) {
        handleDepotSelect(depotId);
      }
    },
  };
  //#endregion Methods

  //#region Render time calcs
  //#endregion Render time calcs

  //#region Render

  return (
    <>
      <DepotReportFilter
        costCenterReportData={depotReportData}
        setCostCenterReportData={setDepotReportData}
        itemDepotReportPayload={itemDepotReportPayload}
        setItemDepotReportPayload={setItemDepotReportPayload}
      />
      {treeMapPayload && Object.values(treeMapPayload).length > 0 && (
        <>
          <ReactECharts
            option={treeMapPayload}
            lazyUpdate={false}
            onEvents={chartEvents}
            style={{ height: '700px', width: '100%' }} // Customize the chart's dimensions
          />
        </>
      )}
    </>
  );
  //#endregion Render
};
export { DepotTreeMap };
