import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loadingState: false,
  showProgress: false,
  progress: { progress: 0, label: '' },
  noAnimation: false,
};

const loadingProgressSlice = createSlice({
  name: 'loadingProgress',
  initialState,
  reducers: {
    setProgress: (state, action) => {
      state.progress = action.payload;
    },
    activateLoading: (state, action) => {
      state.loadingState = true;
      state.showProgress = action.payload
        ? action.payload.showProgress ?? false
        : false;
    },
    deactivateLoading: (state) => {
      state.loadingState = false;
      state.showProgress = false;
    },
    resetLoadingState: () => initialState,
  },
  extraReducers: {},
});

export const getLoadingState = (state) => state.loadingProgress.loadingState;
export const getProgress = (state) => state.loadingProgress.progress;
export const getShowProgress = (state) => state.loadingProgress.showProgress;

export const {
  setProgress,
  activateLoading,
  deactivateLoading,
  resetLoadingState,
} = loadingProgressSlice.actions;

export default loadingProgressSlice.reducer;
