import { config } from 'ectl-config';
import { getNetworkService } from 'helpers';

const accountService = { config: config };
const networkService = getNetworkService({
  config: config,
  checkAuth: accountService.checkAuth,
});
const baseUrl = `${config.apiUrl}/Admin`;

const exportCsv = async () => {
  try {
    const response = await networkService.get(`${baseUrl}/Hierarchy/Export`);
    return response?.data;
  } catch (err) {
    return Promise.reject(err.error);
  }
};

export const exportFileService = {
  exportCsv,
};
