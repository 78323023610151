import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MdClose } from 'react-icons/md';

import { sidePanelHeaderStyles } from './styles';
import UnsavedFormDataDialog from 'forms/UnsavedFormDataDialog';
import {
  closePanel,
  getDirtyFlag,
  setIsDirty,
} from 'features/common/formSlice';

const SidePanelHeader = ({ icon, header, subheader, formKey, data }) => {
  const dispatch = useDispatch();

  const classes = sidePanelHeaderStyles();

  const getDirty = useSelector(getDirtyFlag);
  const [openDialog, setOpenDialog] = useState(false);

  const handleClose = () => {
    dispatch(closePanel({ formKey: formKey }));
    dispatch(setIsDirty(false));
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.headerBottomRow}>
          {icon &&
            React.cloneElement(icon, {
              className: classes.headerIcon,
            })}
          <div className={classes.info}>
            {subheader && <div className={classes.subheader}>{subheader}</div>}
            <div className={classes.header}>{header}</div>
          </div>
        </div>
      </div>
      <div className={classes.headerCloseButton}>
        <MdClose
          className={classes.close}
          onClick={() => {
            if (getDirty) {
              setOpenDialog(true);
            } else {
              handleClose();
            }
          }}
        />
      </div>
      <UnsavedFormDataDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        handleClose={handleClose}
      />
    </div>
  );
};

export { SidePanelHeader };
