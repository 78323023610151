import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    search: {
      position: 'relative',
      flex: '1 0 auto',
      minWidth: '130px',
    },
    searchIcon: {
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#2f393b',
      zIndex: '1',
      left: '5px',
    },
    inputRoot: {
      color: 'inherit',
      height: '45px',
      width: '100%',
      background: '#f4f6f6',
      padding: '10px 30px',
    },
  })
);

export { useStyles };
