import { IconButton, TableCell, TableRow } from '@mui/material';
import { Field } from 'formik';
import { MdDelete } from 'react-icons/md';

import {
  fetchApiRestrictionDropDown,
  getApiRestrictionDropDownStatus,
  selectApiRestrictionDropDown,
} from 'features/AdminSettingsDropDown/dropDownSlice';
import FieldValidationError from 'shared/components/FormFields/FieldValidationError';
import { SelectionAutoComplete } from 'shared/components/_Autocomplete/SelectionAutocomplete';
import { FormTextField } from 'shared/components/FormFields/TextField';
const ItemApiRestrictionsTable = ({
  index,
  setFieldValue,
  classes,
  values,
  errors,
  updateArrayField,
}) => {
  //#region Constants
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  //#endregion State

  //#region Selectors
  //#endregion Selectors

  //#region Refs
  //#endregion Refs

  //#region Effects
  //#endregion Effects

  //#region Methods
  const handleDeleteRow = async (index) => {
    const updatedValue = [...values.restrictions];
    updatedValue.splice(index, 1);
    updateArrayField(updatedValue);
  };
  //#endregion Methods

  //#region Render time calcs
  //#endregion Render time calcs

  //#region Render
  return (
    <TableRow>
      <TableCell sx={{ minWidth: '190px' }}>
        <FormTextField
          label="Display Name"
          name={`[restrictions.${index}].display_name`}
          placeholder="Display Name"
          fullWidth
          error={!!errors?.restrictions?.[index]?.display_name}
          helperText={errors?.restrictions?.[index]?.display_name}
        />
      </TableCell>
      <TableCell sx={{ minWidth: '250px' }}>
        <Field
          className={classes.input}
          name={`restrictions.[${index}].restriction_type`}
        >
          {({ field: { value }, form: { setFieldValue } }) => (
            <SelectionAutoComplete
              title="Select Restriction Type"
              keyProperty="value"
              nameProperty="text"
              entityIds={value}
              setEntityIds={setFieldValue}
              entitySelector={selectApiRestrictionDropDown}
              entityStatusSelector={getApiRestrictionDropDownStatus}
              onCustomChange={(value) => {
                setFieldValue(
                  `restrictions.[${index}].restriction_type`,
                  value?.value
                );
              }}
              fetchEntityPage={fetchApiRestrictionDropDown}
              formField={`restrictions.[${index}].restriction_type`}
              multiSelection={false}
              showCloseItemEdit={true}
              hideCheckIcon={true}
              cancelItemTagEdit={() => {
                setFieldValue(`restrictions.[${index}].restriction_type`, null);
              }}
              fieldValidation={
                !!errors?.restrictions?.[index]?.restriction_type
              }
            />
          )}
        </Field>
        {errors?.restrictions?.[index]?.restriction_type && (
          <FieldValidationError
            IsRelative={true}
            validationError={errors.restrictions?.[index]?.restriction_type}
          />
        )}
      </TableCell>
      <TableCell sx={{ minWidth: '190px' }}>
        <FormTextField
          name={`restrictions.[${index}].restriction_value`}
          label="Restriction value"
          placeholder="Restriction value"
          fullWidth
          error={!!errors?.restrictions?.[index]?.restriction_value}
          helperText={errors?.restrictions?.[index]?.restriction_value}
        />
      </TableCell>
      <TableCell>
        <IconButton onClick={() => handleDeleteRow(index)}>
          <MdDelete />
        </IconButton>
      </TableCell>
    </TableRow>

    // </Box>
  );
  //#endregion Render
};
export { ItemApiRestrictionsTable };
