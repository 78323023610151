import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { MdOutlineSquare } from 'react-icons/md';
import { Box } from '@mui/system';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { NoDataMessage } from 'shared/components/_Tables/NoDataMessage';
import { mdTheme } from 'theme';
import { teamTableStyle } from './style';
import {
  activateLoading,
  deactivateLoading,
} from 'features/global/loadingProgressSlice';
import { openPanel, setEditObjectId } from 'features/common/formSlice';
import { NO_MATCHING_TEAMS_DATA } from '../../helpers/enums';
import { Each } from 'helpers/Each';
import { ExpiryCapabilityData } from './components/ExpiryCapabilityData';
import { EmployeeData } from './components/EmployeeData';

const ItemTeamsTable = ({
  columnData,
  teamsTableData,
  setCurrentPage,
  currentPage,
  mediaWidth,
  expiryTotal,
  benchTotal,
}) => {
  //#region Constants
  const dispatch = useDispatch();
  const teamClasses = teamTableStyle(mdTheme);
  const navigate = useNavigate();
  const theme = mdTheme;
  const [searchParams] = useSearchParams();
  const isHideMenu = searchParams ? searchParams.get('hide-menu') : null;
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  const [nextDisable, setNextDisable] = useState({
    expiryNext: false,
    benchNext: false,
  });
  const [prevDisable, setPrevDisable] = useState({
    expiryPrev: true,
    benchPrev: true,
  });
  //#endregion State

  //#region Selectors
  //#endregion Selectors

  //#region Refs
  //#endregion Refs
  //#region Effects
  useEffect(() => {
    (async () => {
      dispatch(activateLoading());
      if (expiryTotal?.TotalCount) {
        currentPage.stop_index + 1 < expiryTotal?.TotalCount
          ? setNextDisable(false)
          : setNextDisable(true);
      }
      if (benchTotal?.TotalCount) {
        currentPage.stop_index + 1 < benchTotal?.TotalCount
          ? setNextDisable(false)
          : setNextDisable(true);
      }
      currentPage.start_index - 1 > 0
        ? setPrevDisable(false)
        : setPrevDisable(true);

      dispatch(deactivateLoading());
    })();
  }, [
    currentPage,
    columnData.total_employees,
    dispatch,
    expiryTotal?.TotalCount,
    benchTotal?.TotalCount,
  ]);
  //#endregion Effects
  //#region Methods
  const handleEmployeeData = (e, id) => {
    e.stopPropagation();
    const filters = [];
    const jobId = teamsTableData?.job_id?.value;
    const jobRoleId = teamsTableData?.job_role_id?.value;
    if (jobId) {
      filters.push(`job_id=${jobId}`);
    }
    if (jobRoleId) {
      filters.push(`job_role_id=${jobRoleId}`);
    }
    if (id) {
      filters.push(`capability_id=${id}`);
    }
    const params = filters.join('&');
    navigate(`/training?${params}`);
  };

  const handlePagination = (key) => {
    const benchTotalCount = benchTotal?.TotalCount;
    const expiryTotalCount = expiryTotal?.TotalCount;
    if (
      benchTotalCount &&
      key === 'Next' &&
      currentPage.start_index + mediaWidth < benchTotalCount
    ) {
      setCurrentPage((prev) => ({
        start_index: prev.start_index + mediaWidth,
        stop_index:
          prev.stop_index + mediaWidth < benchTotalCount
            ? prev.stop_index + mediaWidth
            : benchTotalCount,
      }));
    }
    if (
      expiryTotalCount &&
      key === 'Next' &&
      currentPage.start_index + mediaWidth < expiryTotalCount
    ) {
      setCurrentPage((prev) => ({
        start_index: prev.start_index + mediaWidth,
        stop_index:
          prev.stop_index + mediaWidth < expiryTotalCount
            ? prev.stop_index + mediaWidth
            : expiryTotalCount,
      }));
    }
    if (key === 'Prev' && currentPage.start_index > 1) {
      setCurrentPage((prev) => ({
        start_index: Math.max(0, currentPage?.start_index - mediaWidth),
        stop_index: prev.start_index - 1,
      }));
    }
  };

  const handleCapabilityForm = async (capabilityId) => {
    await dispatch(
      setEditObjectId({
        formKey: `itemCapabilityForm`,
        object_id: capabilityId,
      })
    );
    dispatch(openPanel({ formKey: `itemCapabilityForm`, formAction: 'Edit' }));
  };

  //#endregion Methods

  //#region Render time calcs
  //#endregion Render time calcs

  //#region Render

  return (
    <>
      <Box className={teamClasses.levelList}>
        {columnData?.colors?.length > 0 && (
          <Each
            of={columnData.colors}
            render={(item, index) => (
              <Box key={item.color_id} className={teamClasses.verticallyCenter}>
                <MdOutlineSquare
                  style={{
                    fill: item.color,
                    background: item.color,
                    overflow: 'hidden',
                    marginRight: '1rem',
                  }}
                />
                <Typography sx={{ whiteSpace: 'nowrap' }}>
                  {item.display_name}
                </Typography>
              </Box>
            )}
          />
        )}
      </Box>
      {(columnData && columnData?.capabilities?.length > 0) ||
      columnData?.employees?.length > 0 ? (
        <>
          <Box
            sx={{
              maxWidth: '100%',
              width: '1785px',
              overflow: 'hidden',
            }}
          >
            <TableContainer
              sx={{
                borderRadius: '0',
                overflow: 'auto',
                height: 'calc(100vh - 340px)',
              }}
              component={Paper}
            >
              <Table
                aria-label="simple table"
                sx={{
                  minWidth: 800,
                }}
                className={teamClasses.mainTable}
              >
                <TableHead className={teamClasses.stickyHeader}>
                  <TableRow>
                    <TableCell
                      sx={{
                        background: theme.colors.secondarySectionBackground,
                        minWidth: '230px',
                      }}
                    ></TableCell>
                    <TableCell
                      align="center"
                      colSpan={columnData?.employees?.length}
                      sx={{
                        background: theme.colors.secondarySectionBackground,
                      }}
                    >
                      <Typography
                        sx={{
                          color: theme.colors.primarySectionForeground,
                        }}
                        variant="h6"
                      >
                        Employees
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        background: theme.colors.secondarySectionBackground,
                      }}
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <EmployeeData
                    columnData={columnData}
                    isHideMenu={isHideMenu}
                    handlePagination={handlePagination}
                    prevDisable={prevDisable}
                    nextDisable={nextDisable}
                  />
                  <ExpiryCapabilityData
                    columnData={columnData}
                    handleEmployeeData={handleEmployeeData}
                    handleCapabilityForm={handleCapabilityForm}
                  />
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      ) : (
        <NoDataMessage message={NO_MATCHING_TEAMS_DATA} />
      )}
    </>
  );
  //#endregion Render
};
export { ItemTeamsTable };
