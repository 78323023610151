import { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Grid, IconButton, Typography, Tooltip } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { MdAdd, MdEdit, MdSummarize } from 'react-icons/md';
import { enqueueSnackbar } from 'notistack';
import { FiEye } from 'react-icons/fi';

import { SnackbarDismiss } from 'shared/components/SnackbarDismiss';
import { employeeRoleEnums, snackbarHandler } from 'helpers';
import {
  fetchEmployeeDropDown,
  getEmployeeDropDownStatus,
  getSelectedDropDowns,
  selectEmployeeDropDown,
  setSelectedDropDown,
} from 'features/AdminSettingsDropDown/dropDownSlice';
import {
  activateLoading,
  deactivateLoading,
} from 'features/global/loadingProgressSlice';
import {
  fetchEmployee,
  getEmployeePageInfo,
  getEmployeeStatus,
  fetchEmployeeReport,
  resetTeamEmployeeState,
  selectEmployee,
  resetEmployeeSkill,
  resetEmployeeCapabilities,
  getEmployeeSkillStatus,
} from 'features/Teams/teamSlice';
import { LoadingStatus, SystemRoleEnums } from 'helpers';
import { mdTheme } from 'theme';
import { employeeStyle } from './style';
import { SelectionAutoComplete } from 'shared/components/_Autocomplete/SelectionAutocomplete';
import { selectActiveUser } from 'features/account/accountSlice';
import {
  closePanel,
  openPanel,
  setEditObjectId,
} from 'features/common/formSlice';
import { NoDataMessage } from 'shared/components/_Tables/NoDataMessage';
import { AchievedSkillsTable } from './AchievedSkillsTable';
import { EmployeeCapabilitiesTable } from './EmployeeCapabilitiesTable';

const Employees = () => {
  //#region Constants
  const classes = employeeStyle(mdTheme);
  const dispatch = useDispatch();
  let params = useParams();
  let id = params.id;
  const initValues = {
    direct_report_id: id ? id : null,
  };
  const navigate = useNavigate();
  const statusOfLoading = [LoadingStatus.Loaded];
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  const [itemEmployeePayload, setItemEmployeePayload] = useState();
  const [searchParams] = useSearchParams();
  const isHideMenu = searchParams ? searchParams.get('hide-menu') : null;
  //#endregion State

  //#region Selectors
  const employees = useSelector(selectEmployee);
  const itemEmployeeStatus = useSelector(getEmployeeStatus);
  const itemEmployeePageInfo = useSelector(getEmployeePageInfo);
  const getSelectedOption = useSelector(getSelectedDropDowns);
  const activeUser = useSelector(selectActiveUser);
  const employeeSkillStatus = useSelector(getEmployeeSkillStatus);
  const capabilitySkillStatus = useSelector(getEmployeeSkillStatus);

  const isEmpEditRole = employees?.permissions?.includes(
    employeeRoleEnums?.EditUser
  );
  const canViewReport = employees?.permissions?.includes(
    employeeRoleEnums?.ViewReport
  );
  //#endregion Selectors

  //#region Refs
  const ref = useRef(false);
  const isUserRole = SystemRoleEnums.User === activeUser?.role;
  const canEditSkills = [
    SystemRoleEnums.BlueGrassAdmin,
    SystemRoleEnums.Certifier,
    SystemRoleEnums.Supervisor,
  ].includes(activeUser?.role);
  //#endregion Refs

  //#region Effects
  useEffect(() => {
    if (ref.current === true) {
      getEmployeeData({ ...itemEmployeePayload });
    }
  }, [itemEmployeePayload]);

  useEffect(() => {
    return async () => {
      await dispatch(resetTeamEmployeeState());
      await dispatch(resetEmployeeSkill());
      await dispatch(resetEmployeeCapabilities());
    };
  }, []);

  useEffect(() => {
    if (itemEmployeeStatus === LoadingStatus.Idle) {
      getEmployeeData();
    }
    ref.current = true;
  }, [itemEmployeeStatus, id, dispatch]);
  //#endregion Effects

  //#region Methods

  const handleSeriesId = useCallback(
    async (val) => {
      if (val) {
        isHideMenu === 'true'
          ? navigate(`/employee/${val?.value}?hide-menu=${isHideMenu}`)
          : navigate(`/employee/${val?.value}`);
        await dispatch(fetchEmployee(val.value));
      } else {
        await dispatch(fetchEmployee(id));
      }
      dispatch(
        setSelectedDropDown({
          ...getSelectedOption,
          directReport_id: val,
        })
      );
    },
    [dispatch, getSelectedOption, id, isHideMenu, navigate]
  );

  const getEmployeeData = useCallback(
    async (payload) => {
      if (isUserRole) {
        isHideMenu === 'true'
          ? navigate(
              `employee/${activeUser?.employee_id}?hide-menu=${isHideMenu}`
            )
          : navigate(`/employee/${activeUser?.employee_id}`);
        await handleSeriesId({ value: activeUser?.employee_id });
      } else {
        handleSeriesId({ value: id });
      }
    },
    [
      activeUser?.employee_id,
      handleSeriesId,
      id,
      isHideMenu,
      isUserRole,
      navigate,
    ]
  );

  const setDataFormate = (dateVal) => {
    return moment(dateVal).local().format('YYYY-MM-DD');
  };

  const resetDirectReport = async (setFieldValue) => {
    dispatch(activateLoading());
    if (id) {
      setFieldValue('direct_report_id', null);
    }
    dispatch(deactivateLoading());
  };

  const handleEditClick = async (empId) => {
    await dispatch(closePanel({ formKey: 'itemEmployeeForm' }));
    if (empId) {
      await dispatch(
        setEditObjectId({
          formKey: `itemEmployeeForm`,
          object_id: empId,
        })
      );
      dispatch(
        openPanel({
          formKey: `itemEmployeeForm`,
          formAction: 'Edit',
          isUpdatable: true,
          isSameForm: true,
          isReadOnly: !isEmpEditRole,
        })
      );
    }
  };

  const handleReportClick = async (empId) => {
    dispatch(activateLoading());
    const response = await dispatch(fetchEmployeeReport(empId));
    if (response?.payload) {
      const { message, variant } = snackbarHandler(
        response?.meta?.requestStatus,
        `${response.payload.headers?.filename ?? 'CSV'} Downloaded`
      );
      enqueueSnackbar(message, {
        action: (key) => <SnackbarDismiss key={key} />,
        variant: variant,
      });
    }
    dispatch(deactivateLoading());
    if (response?.error) {
      const { message, variant } = snackbarHandler(
        response?.error?.message,
        'Download CSV'
      );
      enqueueSnackbar(message, {
        action: (key) => <SnackbarDismiss key={key} />,
        variant: variant,
      });
    }
  };

  const handleCreateNew = () => {
    dispatch(
      openPanel({
        formKey: 'itemEmployeeForm',
        formAction: 'Create',
        isUpdatable: true,
      })
    );
  };

  const handleSkillCertificationForm = async (
    skillId,
    empId,
    canEdit,
    displayName
  ) => {
    await dispatch(
      setEditObjectId({
        formKey: `skillsCertificationForm`,
        object_id: skillId,
        assetTypeID: empId,
        editObject: displayName,
      })
    );
    if (canEdit) {
      dispatch(
        openPanel({
          formKey: `skillsCertificationForm`,
          formAction: 'Edit',
          isUpdatable: true,
        })
      );
    } else {
      dispatch(
        openPanel({
          formKey: `skillsCertificationForm`,
          formAction: 'Edit',
          isUpdatable: false,
        })
      );
    }
  };

  //#endregion Methods

  //#region Render time calcs
  //#endregion Render time calcs

  //#region Render

  return (
    <Box sx={{ flexGrow: 1, padding: '2rem 0' }}>
      <Grid container>
        <Grid item={true} xs={12} lg={6} sx={{ padding: '0 1rem' }}>
          {activeUser?.role !== SystemRoleEnums.User && (
            <Box>
              <Formik
                enableReinitialize={true}
                initialValues={{ ...initValues }}
              >
                {() => (
                  <Form style={{ width: '100%' }} className={classes.form}>
                    <Field className={classes.input} name="direct_report_id">
                      {({ field: { value }, form: { setFieldValue } }) => (
                        <SelectionAutoComplete
                          title="Select Employee"
                          keyProperty="value"
                          nameProperty="text"
                          entityIds={value}
                          onCustomChange={(newInputValue) => {
                            handleSeriesId(newInputValue);
                            if (statusOfLoading.includes(employeeSkillStatus)) {
                              dispatch(resetEmployeeSkill());
                            }
                            if (
                              statusOfLoading.includes(capabilitySkillStatus)
                            ) {
                              dispatch(resetEmployeeCapabilities());
                            }
                          }}
                          setEntityIds={setFieldValue}
                          entitySelector={selectEmployeeDropDown}
                          entityStatusSelector={getEmployeeDropDownStatus}
                          fetchEntityPage={fetchEmployeeDropDown}
                          fetchQueryParams={{
                            include_self: true,
                          }}
                          formField="direct_report_id"
                          showCloseItemEdit={true}
                          hideCheckIcon={true}
                          multiSelection={false}
                          cancelItemTagEdit={() => {
                            resetDirectReport(setFieldValue);
                          }}
                          readOnly={SystemRoleEnums.User === activeUser?.role}
                        />
                      )}
                    </Field>
                  </Form>
                )}
              </Formik>
            </Box>
          )}
          <Box className={classes.cardWrapper}>
            {employees ? (
              <Grid container sx={{ padding: '1rem' }}>
                <Grid
                  item={true}
                  xs={12}
                  sx={{
                    position: 'relative',
                    paddingRight: '2.2rem',
                  }}
                >
                  {employees?.employee_full_name && isEmpEditRole ? (
                    <>
                      <IconButton
                        onClick={() => handleEditClick(employees?.employee_id)}
                        sx={{
                          width: '2rem',
                          height: '2rem',
                          position: 'absolute',
                          top: '0',
                          right: '0',
                        }}
                      >
                        <Tooltip
                          title={`Edit ${employees?.employee_full_name}`}
                        >
                          <MdEdit />
                        </Tooltip>
                      </IconButton>
                    </>
                  ) : (
                    <>
                      <IconButton
                        onClick={() => handleEditClick(employees?.employee_id)}
                        sx={{
                          width: '2rem',
                          height: '2rem',
                          position: 'absolute',
                          top: '0',
                          right: '0',
                        }}
                      >
                        <Tooltip
                          title={`View ${employees?.employee_full_name}`}
                        >
                          <FiEye
                            style={{
                              fontSize: '1.3rem',
                            }}
                          />
                        </Tooltip>
                      </IconButton>
                    </>
                  )}
                  {canViewReport && (
                    <IconButton
                      onClick={() => handleReportClick(employees?.employee_id)}
                      sx={{
                        width: '2rem',
                        height: '2rem',
                        position: 'absolute',
                        top: '2rem',
                        right: '0',
                      }}
                    >
                      <Tooltip
                        title={`${employees.employee_full_name}'s Report`}
                      >
                        <MdSummarize />
                      </Tooltip>
                    </IconButton>
                  )}
                  <Typography>{employees?.employee_full_name}</Typography>
                  {employees?.employee_supervisor_full_name && (
                    <Typography sx={{ wordBreak: 'break-word' }}>
                      Supervisor: {employees?.employee_supervisor_full_name}
                    </Typography>
                  )}
                  {employees?.employee_start_date}
                  <Typography>{employees?.employee_job_role}</Typography>
                  <Typography>{employees?.employee_pay}</Typography>
                  <Typography>{employees?.system_role}</Typography>
                </Grid>
              </Grid>
            ) : (
              <NoDataMessage message={'Employee Not Found'} setHeight="150px" />
            )}
          </Box>
          <AchievedSkillsTable
            activeUser={activeUser}
            canEditSkills={canEditSkills}
            handleSkillCertificationForm={handleSkillCertificationForm}
            employees={employees}
            classes={classes}
            setDataFormate={setDataFormate}
          />
        </Grid>
        <Grid item={true} xs={12} lg={6} sx={{ padding: '0 1rem' }}>
          {[
            SystemRoleEnums.BlueGrassAdmin,
            SystemRoleEnums.ProgramManager,
            SystemRoleEnums.Admin,
          ].includes(activeUser?.role) && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton
                onClick={() => handleCreateNew()}
                size={'small'}
                disableRipple
              >
                <MdAdd /> Add Employee
              </IconButton>
            </Box>
          )}
          <EmployeeCapabilitiesTable
            canEditSkills={canEditSkills}
            classes={classes}
            itemEmployeePageInfo={itemEmployeePageInfo}
            itemEmployeePayload={itemEmployeePayload}
            setItemEmployeePayload={setItemEmployeePayload}
            isUserRole={isUserRole}
          />
        </Grid>
      </Grid>
    </Box>
  );
  //#endregion Render
};
export { Employees };
