import React from 'react';
import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';

function SnackbarDismiss({ snackItem }) {
  const { closeSnackbar } = useSnackbar();

  return (
    <Button onClick={() => closeSnackbar(snackItem)}>
      <div style={{ color: '#fff', textTransform: 'uppercase' }}>Dismiss</div>
    </Button>
  );
}

export { SnackbarDismiss };
