import { useCallback, useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { CostCenterReportFilter } from './CostCenterReportFilter';
import { selectCostCenterReport } from 'features/CostCenterReports/costCenterReportSlice';
import { useDispatch, useSelector } from 'react-redux';
import { resetDropDownState } from 'features/AdminSettingsDropDown/dropDownSlice';
import { useNavigate } from 'react-router-dom';

const CostCenterTreeMap = ({
  itemCostCenterReportPayload,
  setItemCostCenterReportPayload,
}) => {
  //#region Constants
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const initValues = {
    cost_center_id: null,
    office_id: null,
    job_id: null,
    job_role_id: null,
    custom_expiry_date: null,
    supervisor_employee_id: null,
    capability_id: null,
    report_expiry: null,
  };
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  const [costCenterReportData, setCostCenterReportData] = useState({
    ...initValues,
  });
  const [treeMapPayload, setTreeMapPayload] = useState(null);
  //#endregion State

  //#region Selectors
  const costCenterReports = useSelector(selectCostCenterReport);
  //#endregion Selectors

  //#region Refs
  const updateTreeMapData = (treeMap) => {
    return treeMap?.map((item) => {
      const childItem = {
        name: item?.psntl,
        label: item?.psntl,
        children: item?.children.map((val) => {
          const node = {
            value: val.employee_count,
            name: val.job,
            jobId: val.job_Id,
            costCenterId: item?.cost_center_id,
            colorSaturation: [0.35, 0.5],
            itemStyle: {
              color: getColorBasedOnPercentage(val?.compliance_percentage),
            },
          };

          if (val.children && val.children.length > 0) {
            node.children = updateTreeMapData(val.children);
          }

          return node;
        }),
      };
      return childItem;
    });
  };

  function getColorBasedOnPercentage(percentage) {
    let color;

    if (percentage <= 0.8) {
      color = '#D51D1F';
    } else if (percentage <= 0.85) {
      color = '#D7890B';
    } else if (percentage <= 0.9) {
      color = '#DCD00A';
    } else if (percentage <= 0.95) {
      color = '#9FDE09';
    } else {
      color = '#49DE10';
    }

    // top green
    return color;
  }
  //#endregion Refs

  //#region Effects
  useEffect(() => {
    const levelOption = () => {
      return [
        {
          itemStyle: {
            borderColor: '#000',
            borderWidth: 0,
            gapWidth: 1,
          },
          upperLabel: {
            show: false,
          },
        },
        {
          itemStyle: {
            borderColor: '#555',
            borderWidth: 3,
            gapWidth: 1,
          },
          emphasis: {
            itemStyle: {
              borderColor: '#ddd',
            },
          },
        },
        {
          colorSaturation: [0.35, 0.5],
          itemStyle: {
            borderWidth: 3,
            gapWidth: 1,
            borderColorSaturation: 0.6,
          },
        },
      ];
    };
    const treeMapData = {
      tooltip: {
        formatter: function (params) {
          const name = params.name;
          const value = params.value;
          return `${name}<br>Employee Count: ${value}`;
        },
      },
      color: 'transparent',
      series: {
        name: 'Cost Center',
        type: 'treemap',
        roam: 'scale',
        levels: levelOption(),
        data: updateTreeMapData(costCenterReports),
        label: {
          show: true,
          fontSize: 16,
          color: '#fff',
        },
        emphasis: {
          upperLabel: {
            color: '#000',
          },
        },
        upperLabel: {
          show: true,
          height: 20,
          color: 'white',
          fontSize: 14,
        },
      },
    };

    setTreeMapPayload(treeMapData);
  }, [costCenterReports]);

  useEffect(() => {
    dispatch(resetDropDownState());
  }, []);
  //#endregion Effects

  //#region Methods
  const handleCostCenterSelect = (params) => {
    navigate(`/reports?${params}`);
  };

  const costCenterEvent = {
    click: useCallback(
      (params) => {
        if (params?.value && params?.data) {
          const reportsParams = {
            ...itemCostCenterReportPayload,
            treeMapJobId: params?.data?.jobId,
            treeMapCostCenterId: params?.data?.costCenterId,
          };
          delete reportsParams?.display_date;
          const filteredData = Object.keys(reportsParams).reduce((acc, val) => {
            if (reportsParams[val]) {
              acc[val] = reportsParams[val];
            }
            return acc;
          }, {});
          const queryString = new URLSearchParams(filteredData).toString();
          if (queryString) {
            handleCostCenterSelect(queryString);
          }
        }
      },
      [handleCostCenterSelect, itemCostCenterReportPayload]
    ),
  };
  //#endregion Methods

  //#region Render time calcs
  //#endregion Render time calcs

  //#region Render

  return (
    <>
      <CostCenterReportFilter
        costCenterReportData={costCenterReportData}
        setCostCenterReportData={setCostCenterReportData}
        itemCostCenterReportPayload={itemCostCenterReportPayload}
        setItemCostCenterReportPayload={setItemCostCenterReportPayload}
      />
      {treeMapPayload && Object.values(treeMapPayload).length > 0 && (
        <>
          <ReactECharts
            option={treeMapPayload}
            lazyUpdate={false}
            onEvents={costCenterEvent}
            style={{ height: '700px', width: '100%' }} // Customize the chart's dimensions
          />
        </>
      )}
    </>
  );
  //#endregion Render
};
export { CostCenterTreeMap };
