import { useState, useEffect, useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

import { FormActions } from 'helpers/form-action';
import { makeFormStyles } from 'forms/style';
import {
  activateLoading,
  deactivateLoading,
} from 'features/global/loadingProgressSlice';
import { COST_CENTER_CACHE, EMPLOYEE_CACHE, snackbarHandler } from 'helpers';
import { SnackbarDismiss } from 'shared/components/SnackbarDismiss';
import Loader from 'shared/components/Loader';
import { closePanel } from 'features/common/formSlice';
import { Button, Typography } from '@mui/material';
import {
  createOffice,
  fetchOffice,
  fetchOfficeById,
  updateOffice,
} from 'features/Office/officeSlice';
import { SelectionAutoComplete } from 'shared/components/_Autocomplete/SelectionAutocomplete';
import {
  fetchCostCenterDropDown,
  getCostCenterDropDownStatus,
  selectCostCenterDropDown,
} from 'features/AdminSettingsDropDown/dropDownSlice';
import {
  selectAdminSettingCache,
  updateAdminSettingCache,
} from 'features/global/visibilitySlice';
import { FormTextField } from 'shared/components/FormFields/TextField';
import AuditInfoCard from 'shared/components/_AuditInfoCard';

const ItemOfficeForm = ({ formAction, itemTagId, auditHeader }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const adminCacheData = useSelector(selectAdminSettingCache);
  // eslint-disable-next-line no-unused-vars
  const [loaded, setLoaded] = useState(
    formAction.id === FormActions.Create.id ? true : false
  );
  const classes = makeFormStyles();
  // eslint-disable-next-line no-unused-vars
  const [initValues, setInitValues] = useState({
    cost_center_id: null,
    directorate: '',
    symbol: '',
    description: '',
  });
  const tagNameRef = useRef(null);
  const [auditInfo, setAuditInfo] = useState(null);

  useEffect(() => {
    if (formAction.id === FormActions.Edit.id) {
      const fetchData = async () => {
        var objectData = await dispatch(fetchOfficeById(itemTagId));
        var fetchedObject = objectData.payload;
        setInitValues({
          cost_center_id: fetchedObject.cost_center_id,
          directorate: fetchedObject.directorate,
          symbol: fetchedObject.symbol,
          description: fetchedObject.description,
        });
        const { created_by, created_time, modified_time } = fetchedObject;
        setAuditInfo({
          author: created_by,
          creation: created_time,
          modified: modified_time,
        });
        setLoaded(true);
      };
      fetchData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = async (values, setSubmitting, resetForm) => {
    dispatch(activateLoading({ showProgress: true }));
    var resultAction = null;
    switch (formAction.id) {
      case FormActions.Edit.id:
        resultAction = await dispatch(
          updateOffice({
            office_id: itemTagId,
            ...values,
          })
        );
        if (!resultAction.error) {
          setInitValues({
            ...initValues,
            cost_center_id: resultAction?.payload?.cost_center_id,
            directorate: resultAction?.payload?.directorate,
            symbol: resultAction?.payload?.symbol,
            description: resultAction?.payload?.description,
          });
          if (
            adminCacheData?.[EMPLOYEE_CACHE]?.office_id?.value === itemTagId &&
            adminCacheData?.[EMPLOYEE_CACHE]?.office_id?.text !==
              resultAction?.payload.symbol
          ) {
            dispatch(
              updateAdminSettingCache({
                key: EMPLOYEE_CACHE,
                payload: {
                  office_id: {
                    value: itemTagId,
                    text: resultAction?.payload?.symbol,
                  },
                },
              })
            );
          }
          if (
            adminCacheData?.[COST_CENTER_CACHE]?.office_id?.value ===
              itemTagId &&
            adminCacheData?.[COST_CENTER_CACHE]?.office_id?.text !==
              resultAction?.payload.symbol
          ) {
            dispatch(
              updateAdminSettingCache({
                key: COST_CENTER_CACHE,
                payload: {
                  office_id: {
                    value: itemTagId,
                    text: resultAction?.payload?.symbol,
                  },
                },
              })
            );
          }
          dispatch(closePanel({ formKey: `itemOfficeForm` }));
        }
        break;
      case FormActions.Create.id:
        resultAction = await dispatch(createOffice({ ...values }));
        if (!resultAction.error) {
          await dispatch(fetchOffice());
          dispatch(closePanel({ formKey: `itemOfficeForm` }));
          resetForm();
          if (tagNameRef.current) {
            tagNameRef.current.focus();
          }
        }
        break;
      default:
        break;
    }

    dispatch(deactivateLoading());
    const { message, variant } = snackbarHandler(
      resultAction.meta.requestStatus,
      formAction.label
    );
    enqueueSnackbar(message, {
      action: (key) => <SnackbarDismiss key={key} />,
      variant: variant,
    });
    setSubmitting(false);
  };

  return !loaded ? (
    <Loader styleClassName={classes.loadingSvg} />
  ) : (
    <Formik
      enableReinitialize={true}
      initialValues={{ ...initValues }}
      validationSchema={Yup.object({
        directorate: Yup.string().required('Required'),
        symbol: Yup.string().required('Required'),
      })}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        handleSubmit(values, setSubmitting, resetForm);
      }}
    >
      {({ isSubmitting, errors, setFieldValue }) => (
        <Form className={classes.form}>
          <Field className={classes.input} name="cost_center_id">
            {({ field: { value }, form: { setFieldValue } }) => (
              <SelectionAutoComplete
                title="Select Cost Center"
                keyProperty="value"
                nameProperty="text"
                entityIds={value || null}
                setEntityIds={setFieldValue}
                entitySelector={selectCostCenterDropDown}
                entityStatusSelector={getCostCenterDropDownStatus}
                fetchEntityPage={fetchCostCenterDropDown}
                formField={'cost_center_id'}
                multiSelection={false}
                showCloseItemEdit={true}
                hideCheckIcon={true}
                cancelItemTagEdit={() => {
                  setFieldValue('cost_center_id', null);
                }}
              />
            )}
          </Field>
          {errors?.cost_center_id && (
            <Typography variant="subtitle2" className={classes.inputErr}>
              {errors.cost_center_id}
            </Typography>
          )}
          <FormTextField
            name="directorate"
            label="Directorate"
            placeholder="Directorate"
          />
          <FormTextField
            label="Office Symbol"
            name="symbol"
            placeholder="Office Symbol"
          />
          <FormTextField
            multiline="true"
            rows="3"
            label="Description"
            name="description"
            placeholder="Description"
          />
          <AuditInfoCard
            auditInfo={auditInfo}
            auditInfoHeader={auditHeader}
            formAction={formAction}
          />
          <Button
            size="large"
            variant="contained"
            color="primary"
            fullWidth
            type="submit"
            disabled={isSubmitting}
            sx={{ marginTop: '0.5rem' }}
          >
            {formAction.buttonLabel}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export { ItemOfficeForm };
