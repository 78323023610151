import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { jobService } from 'features/services/job.service';
import { LoadingStatus } from 'helpers/enums';

const initialState = {
  jobsDepot: {},
  jobDepotStatus: LoadingStatus.Idle,
  loadedDepotMap: {},
  appIsLoading: false,
};

export const fetchAllJobDepots = createAsyncThunk(
  'job/fetchDepotData',
  async () => {
    const response = await jobService.fetchJobsDepot();
    return response;
  }
);

const jobsDepotSlice = createSlice({
  name: 'jobDepot',
  initialState,
  reducers: {
    resetJobsState: (state) => initialState,
  },
  extraReducers: {
    [fetchAllJobDepots.pending]: (state, action) => {
      state.jobDepotStatus = LoadingStatus.Loading;
      state.appIsLoading = true;
    },
    [fetchAllJobDepots.fulfilled]: (state, action) => {
      state.jobDepotStatus = LoadingStatus.Loaded;
      state.jobDepot = action.payload;
      state.appIsLoading = false;
    },
    [fetchAllJobDepots.rejected]: (state, action) => {
      state.jobDepotStatus = LoadingStatus.Failed;
      state.appIsLoading = false;
    },
  },
});

export const getAllJobDepot = (state) => state.jobDepot.jobDepot;
export const getJobDepotStatus = (state) => state.jobDepot.jobDepotStatus;
export const depotIsLoading = (state) => state.account.appIsLoading;
// export const getJobDataInfo = (state) => state.jobs.jobDepotDataInfo;

export const { resetJobsState } = jobsDepotSlice.actions;

export default jobsDepotSlice.reducer;
