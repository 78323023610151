import React, { useState, useEffect, useRef } from 'react';
import { Formik, Form } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
} from '@mui/material';
import { MdAdd } from 'react-icons/md';

import { FormActions } from 'helpers/form-action';
import { makeFormStyles } from 'forms/style';
import {
  activateLoading,
  deactivateLoading,
} from 'features/global/loadingProgressSlice';
import { snackbarHandler } from 'helpers';
import { SnackbarDismiss } from 'shared/components/SnackbarDismiss';
import Loader from 'shared/components/Loader';
import { closePanel } from 'features/common/formSlice';
import {
  createApiClient,
  fetchApiClient,
  fetchApiClientsById,
  updateApiClient,
} from 'features/ApiClientSlice/ApiClientSlice';
import { ItemApiRestrictionsTable } from './ItemApiRestrictionsTable';
import { useStyles } from 'forms/ItemCapabilityForm/ItemCapabilitySkillTable/styles';
import { NoDataMessage } from 'shared/components/_Tables/NoDataMessage';
import { FormTextField } from 'shared/components/FormFields/TextField';
import { Each } from 'helpers/Each';

const ItemExternalApiForm = ({ formAction, itemTagId }) => {
  //#region Constants
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const classes = makeFormStyles();
  const skillClasses = useStyles();
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  const [loaded, setLoaded] = useState(
    formAction.id === FormActions.Create.id ? true : false
  );
  const [initValues, setInitValues] = useState({
    display_name: '',
    api_key: null,
    restrictions: [],
    description: '',
  });
  //#endregion State

  //#region Selectors
  //#endregion Selectors

  //#region Refs
  const tagNameRef = useRef(null);
  //#endregion Refs

  //#region Effects
  //#endregion Effects

  //#region Methods
  const handleAddRestriction = async (values, setFieldValue) => {
    setFieldValue('restrictions', [
      ...values.restrictions,
      {
        display_name: '',
        restriction_type: null,
        restriction_value: '',
      },
    ]);
  };
  //#endregion Methods

  //#region Render time calcs
  //#endregion Render time calcs

  useEffect(() => {
    if (formAction.id === FormActions.Edit.id) {
      const fetchData = async () => {
        var objectData = await dispatch(fetchApiClientsById(itemTagId));
        var fetchedObject = objectData.payload;
        setInitValues({
          display_name: fetchedObject.display_name,
          api_key: fetchedObject.api_key,
          restrictions: fetchedObject?.restrictions,
          description: fetchedObject?.description,
        });
        setLoaded(true);
      };
      fetchData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const validationSchema = Yup.object().shape({
    display_name: Yup.string().required('Required'),
    api_key: Yup.string().required('Required'),
    restrictions: Yup.array().of(
      Yup.object().shape({
        display_name: Yup.string().required('Required'),
        restriction_type: Yup.string().required('Required'),
        restriction_value: Yup.string().required('Required'),
      })
    ),
  });
  const handleSubmit = async (values, setSubmitting, resetForm) => {
    dispatch(activateLoading({ showProgress: true }));
    var resultAction = null;
    switch (formAction.id) {
      case FormActions.Edit.id:
        resultAction = await dispatch(
          updateApiClient({
            api_client_id: itemTagId,
            ...values,
          })
        );
        if (!resultAction.error) {
          setInitValues({
            ...initValues,
            display_name: resultAction?.payload.display_name,
            api_key: resultAction?.payload.api_key,
            restrictions: resultAction?.payload?.restrictions,
            description: resultAction?.payload?.description,
          });
          dispatch(closePanel({ formKey: `itemExternalApiForm` }));
        }
        break;
      case FormActions.Create.id:
        resultAction = await dispatch(createApiClient({ ...values }));
        if (!resultAction.error) {
          await dispatch(fetchApiClient());
          dispatch(closePanel({ formKey: `itemExternalApiForm` }));
          resetForm();
          if (tagNameRef.current) {
            tagNameRef.current.focus();
          }
        }
        break;
      default:
        break;
    }

    dispatch(deactivateLoading());
    const { message, variant } = snackbarHandler(
      resultAction.meta.requestStatus,
      formAction.label
    );
    enqueueSnackbar(message, {
      action: (key) => <SnackbarDismiss key={key} />,
      variant: variant,
    });
    setSubmitting(false);
  };

  return !loaded ? (
    <Loader styleClassName={classes.loadingSvg} />
  ) : (
    <Formik
      enableReinitialize={true}
      initialValues={{ ...initValues }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        handleSubmit(values, setSubmitting, resetForm);
      }}
    >
      {({ isSubmitting, setFieldValue, values, errors }) => (
        <Form className={classes.form} style={{ padding: '20px 0' }}>
          <Grid container>
            <Grid
              item={true}
              xs={12}
              lg={5}
              sx={{ padding: '0 1rem' }}
              spacing={1}
            >
              <FormTextField
                inputRef={tagNameRef}
                label="Display Name"
                name="display_name"
                placeholder="Display Name"
                fullWidth
                error={!!errors?.display_name}
                helperText={errors?.display_name}
              />
              <FormTextField
                label="API Key"
                name="api_key"
                placeholder="API Key"
                fullWidth
                error={!!errors?.api_key}
                helperText={errors?.api_key}
                inputRef={tagNameRef}
              />
              <FormTextField
                multiline="true"
                rows="3"
                fullWidth
                label="Description"
                name="description"
                placeholder="Description"
              />
            </Grid>
            <Grid item={true} xs={12} lg={7} sx={{ padding: '0 1rem' }}>
              <Box className={skillClasses.skillTableWrapper}>
                <Box
                  className={skillClasses.skillHeader}
                  sx={{ justifyContent: 'flex-end' }}
                >
                  <IconButton
                    onClick={() => handleAddRestriction(values, setFieldValue)}
                    disableRipple
                    sx={{
                      marginLeft: 'auto',
                      paddingLeft: '1rem',
                    }}
                    size="small"
                  >
                    <MdAdd /> Add Restrictions
                  </IconButton>
                </Box>
                <TableContainer sx={{ boxShadow: 'none' }} component={Paper}>
                  <Table>
                    <TableBody>
                      {values?.restrictions.length > 0 ? (
                        <Each
                          of={values?.restrictions}
                          render={(item, index) => (
                            <ItemApiRestrictionsTable
                              index={index}
                              setFieldValue={setFieldValue}
                              classes={classes}
                              values={values}
                              errors={errors}
                              updateArrayField={(updatedArray) => {
                                setFieldValue('restrictions', updatedArray);
                              }}
                            />
                          )}
                        />
                      ) : (
                        <NoDataMessage
                          message={'No Restriction Data Found.'}
                          setHeight={'100px'}
                        />
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid xs={12} lg={5} sx={{ padding: '0 1rem' }}>
              <Button
                size="large"
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
                disabled={isSubmitting}
                sx={{ marginTop: '0.5rem' }}
              >
                {formAction.buttonLabel}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export { ItemExternalApiForm };
