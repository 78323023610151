import { createSlice } from '@reduxjs/toolkit';
import { FormActions } from 'helpers/form-action';
import { last, remove } from 'lodash';

const initialState = {
  openForm: null,
  isDirty: false,
  isUpdatable: false,
  formStack: [],
  currentForm: [],
  itemPayPlanForm: {
    formKey: 'itemPayPlanForm',
    isOpen: false,
    formAction: null,
    editObjectId: null,
    editObjectInfo: null,
    assetTypeID: null,
  },
  itemPayGradeForm: {
    formKey: 'itemPayGradeForm',
    isOpen: false,
    formAction: null,
    editObjectId: null,
    editObjectInfo: null,
    assetTypeID: null,
  },
  itemSkillForm: {
    formKey: 'itemSkillForm',
    isOpen: false,
    formAction: null,
    editObjectId: null,
    editObjectInfo: null,
    assetTypeID: null,
  },
  itemCapabilityForm: {
    formKey: 'itemCapabilityForm',
    isOpen: false,
    formAction: null,
    editObjectId: null,
    editObjectInfo: null,
    assetTypeID: null,
  },
  itemJobForm: {
    formKey: 'itemJobForm',
    isOpen: false,
    formAction: null,
    editObjectId: null,
    editObjectInfo: null,
    assetTypeID: null,
  },
  itemJobRoleForm: {
    formKey: 'itemJobRoleForm',
    isOpen: false,
    formAction: null,
    editObjectId: null,
    editObjectInfo: null,
    assetTypeID: null,
  },
  itemLevelForm: {
    formKey: 'itemLevelForm',
    isOpen: false,
    formAction: null,
    editObjectId: null,
    editObjectInfo: null,
    assetTypeID: null,
  },
  itemEmployeeForm: {
    formKey: 'itemEmployeeForm',
    isOpen: false,
    formAction: null,
    editObjectId: null,
    editObjectInfo: null,
    assetTypeID: null,
    isSameForm: false,
  },
  itemCostCenterForm: {
    formKey: 'itemCostCenterForm',
    isOpen: false,
    formAction: null,
    editObjectId: null,
    editObjectInfo: null,
    assetTypeID: null,
  },
  skillsCertificationForm: {
    formKey: 'skillsCertificationForm',
    isOpen: false,
    formAction: null,
    editObjectId: null,
    editObjectInfo: null,
    assetTypeID: null,
  },
  itemExternalApiForm: {
    formKey: 'itemExternalApiForm',
    isOpen: false,
    formAction: null,
    editObjectId: null,
    editObjectInfo: null,
    assetTypeID: null,
  },
  itemOfficeForm: {
    formKey: 'itemOfficeForm',
    isOpen: false,
    formAction: null,
    editObjectId: null,
    editObjectInfo: null,
    assetTypeID: null,
  },
  itemAssociateSkillForm: {
    formKey: 'itemAssociateSkillForm',
    isOpen: false,
    formAction: null,
    editObjectId: null,
    editObjectInfo: null,
    assetTypeID: null,
  },
  itemAssociateCapabilityForm: {
    formKey: 'itemAssociateCapabilityForm',
    isOpen: false,
    formAction: null,
    editObjectId: null,
    editObjectInfo: null,
    assetTypeID: null,
  },
  associateCapabilityJobRoleForm: {
    formKey: 'associateCapabilityJobRoleForm',
    isOpen: false,
    formAction: null,
    editObjectId: null,
    editObjectInfo: null,
    assetTypeID: null,
  },
  associateJobRoleFormCapability: {
    formKey: 'associateJobRoleFormCapability',
    isOpen: false,
    formAction: null,
    editObjectId: null,
    editObjectInfo: null,
    assetTypeID: null,
  },
};

const formPanelSlice = createSlice({
  name: 'formPanel',
  initialState,
  reducers: {
    openPanel: (state, action) => {
      if (
        state[action.payload.formKey].isOpen !== true ||
        state[action.payload.formKey].formAction.id !==
          FormActions[action.payload.formAction].id
      ) {
        state[action.payload.formKey].isOpen = true;
        state[action.payload.formKey].formAction =
          FormActions[action.payload.formAction];
        state[action.payload.formKey].isSameForm =
          action.payload.formAction.isSameForm ?? false;
        // state[action.payload.formKey].assetTypeID =
        //     action.payload.assetTypeID;
        state[action.payload.formKey].isContentPanelOpen =
          action.payload.isContentPanelOpen ?? false;
        state.openForm = state[action.payload.formKey];
        state.formStack.push(state[action.payload.formKey]);
        state.currentForm.push(action.payload.formKey);
        state.isDirty = false;
        state[action.payload.formKey].isUpdatable =
          action.payload.isUpdatable ?? false;
        state[action.payload.formKey].isReadOnly =
          action.payload.isReadOnly ?? false;
      }
    },
    closePanel: (state, action) => {
      state[action.payload.formKey].isOpen = false;
      state[action.payload.formKey].formAction = null;
      state[action.payload.formKey].editObjectId = null;
      state[action.payload.formKey].editObjectInfo = null;
      state[action.payload.formKey].isContentPanelOpen =
        action.payload.isContentPanelOpen ?? false;
      state.openForm = null;
      remove(state.formStack, state[action.payload.formKey]);
      state.openForm = state.formStack.lengh > 0 ? last(state.formStack) : null;
      state.currentForm = state.currentForm.filter(
        (formName) => formName !== action.payload.formKey
      );
      state.isDirty = false;
    },
    setEditObjectId: (state, action) => {
      state[action.payload.formKey].editObjectId = action.payload.object_id;
      state[action.payload.formKey].assetTypeID = action.payload.assetTypeID;
      if (action.payload.editObject) {
        state[action.payload.formKey].editObjectInfo =
          action.payload.editObject;
      }
    },
    setEditObject: (state, action) => {
      state[action.payload.formKey].editObjectInfo.editObject =
        action.payload.editObject;
    },
    closeAllPanel: (state, action) => {
      return initialState;
    },
    setIsDirty: (state, action) => {
      state.isDirty = action.payload;
    },
  },
  extraReducers: {},
});

// Select all form info for rendering opened forms
export const selectFormInfo = (state) => state.forms;
export const currentFormInfo = (state) =>
  state.forms.currentForm[state.forms.currentForm.length - 1];
export const isFormOpen = (state) =>
  state.forms.itemPayPlanForm.isOpen ||
  state.forms.itemPayGradeForm.isOpen ||
  state.forms.itemSkillForm.isOpen ||
  state.forms.itemCapabilityForm.isOpen ||
  state.forms.itemJobForm.isOpen ||
  state.forms.itemJobRoleForm.isOpen ||
  state.forms.itemLevelForm.isOpen ||
  state.forms.itemEmployeeForm.isOpen ||
  state.forms.itemCostCenterForm.isOpen ||
  state.forms.skillsCertificationForm.isOpen ||
  state.forms.itemExternalApiForm.isOpen ||
  state.forms.itemOfficeForm.isOpen ||
  state.forms.itemAssociateSkillForm.isOpen ||
  state.forms.itemAssociateCapabilityForm.isOpen ||
  state.forms.associateCapabilityJobRoleForm.isOpen ||
  state.forms.associateJobRoleFormCapability.isOpen;

export const getDirtyFlag = (state) => state.forms.isDirty;
// Get the object id of the editable object
// export const selectEditObjectId = (state, formKey) => state.forms[formKey].editObjectId;

export const {
  openPanel,
  closePanel,
  setEditObjectId,
  setEditObject,
  closeAllPanel,
  setIsDirty,
} = formPanelSlice.actions;

export default formPanelSlice.reducer;
