import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { headerStyles } from './Header.styles';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser, selectActiveUser } from 'features/account/accountSlice';
import { Box, Button, Popover } from '@mui/material';
import { useState } from 'react';
import { MdMenu, MdPerson } from 'react-icons/md';

import {
  activateLoading,
  deactivateLoading,
} from 'features/global/loadingProgressSlice';
import { useNavigate } from 'react-router-dom';
import {
  resetEmployeeCapabilities,
  resetEmployeeSkill,
  resetTeamEmployeeState,
} from 'features/Teams/teamSlice';
import navBarLogo from '../../../../assets/hvtc_header_smarts_logo.png';

function Header({ theme, open, toggleDrawer, isHideMenu }) {
  //#region Constants
  const classes = headerStyles(theme);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  const [anchorEl, setAnchorEl] = useState(null);
  const openPopOver = Boolean(anchorEl);
  const id = openPopOver ? 'simple-popover' : undefined;
  //#endregion State

  //#region Selectors
  const activeUser = useSelector(selectActiveUser);
  //#endregion Selectors

  //#region Refs
  //#endregion Refs

  //#region Render time calcs
  //#endregion Render time calcs

  //#region Effects
  //#endregion Effects

  //#region Methods
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const submitLogout = async (event) => {
    event.preventDefault();
    dispatch(activateLoading());
    await dispatch(logoutUser());
    dispatch({ type: 'LOGOUT' });
    dispatch(deactivateLoading());
  };

  const handleUserProfile = async () => {
    if (activeUser) {
      isHideMenu === 'true'
        ? navigate(
            `/employee/${activeUser?.employee_id}?hide-menu=${isHideMenu}`
          )
        : navigate(`/employee/${activeUser?.employee_id}`);
      await dispatch(resetTeamEmployeeState());
      await dispatch(resetEmployeeSkill());
      await dispatch(resetEmployeeCapabilities());
      handleClose();
    }
  };
  //#endregion Methods

  return (
    <Toolbar
      sx={{
        pr: '24px', // keep right padding when drawer closed
        backgroundColor: `${theme.colors.common.headerMain}`,
      }}
    >
      {!isHideMenu && (
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          sx={{
            marginRight: '36px',
            ...(open && { display: 'none' }),
          }}
        >
          <MdMenu />
        </IconButton>
      )}
      <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
        <img
          src={navBarLogo}
          width={'250px'}
          className={classes.responsiveImg}
          alt="HVTC"
        />
      </Box>
      <IconButton
        aria-describedby={id}
        color="inherit"
        onClick={(event) => handleClick(event)}
      >
        <MdPerson />
      </IconButton>
      <Popover
        id={id}
        open={openPopOver}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box className={classes.logoutContainer} sx={{ margin: '0.5rem 0' }}>
          <Button
            size="medium"
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => handleUserProfile()}
          >
            Profile
          </Button>
        </Box>
        <Box className={classes.logoutContainer} sx={{ margin: '0.5rem 0' }}>
          <Button
            size="medium"
            variant="contained"
            color="primary"
            disableElevation
            onClick={(e) => submitLogout(e)}
          >
            Logout
          </Button>
        </Box>
      </Popover>
      <Typography>
        {activeUser.first_name} {activeUser.last_name}
      </Typography>
    </Toolbar>
  );
}

export { Header };
