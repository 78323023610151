import { useDispatch, useSelector } from 'react-redux';
import {
  Autocomplete,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import pluralize from 'pluralize';
import { MdAccountTree, MdAdd, MdDelete, MdInfo } from 'react-icons/md';

import { LoadingStatus } from 'helpers';
import {
  fetchCapabilityDropDown,
  getCapabilityDropDownStatus,
  selectCapabilitiesDropDown,
} from 'features/AdminSettingsDropDown/dropDownSlice';
import { useStyles } from './styles';
import { openPanel, setEditObjectId } from 'features/common/formSlice';
import { FormActions } from 'helpers/form-action';

const ItemJobRoleCapabilityTable = ({
  setFormData,
  formData,
  user,
  itemTagId,
  formAction,
}) => {
  //#region Constants
  const dispatch = useDispatch();
  const classes = useStyles();
  //#endregion Constants
  //#region Hooks
  //#endregion Hooks

  //#region State
  const [isCapabilityDropDown, setIsCapabilityDropDown] = useState(false);

  //#endregion State

  //#region Selectors
  const capabilityDropDownStatus = useSelector(getCapabilityDropDownStatus);
  const capabilityDropDown = useSelector(selectCapabilitiesDropDown);
  //#endregion Selectors

  //#region Refs
  //#endregion Refs

  //#region Effects
  useEffect(() => {
    if (capabilityDropDownStatus === LoadingStatus.Idle) {
      dispatch(fetchCapabilityDropDown());
    }
  }, [capabilityDropDownStatus]);

  let getFormData = { ...formData };

  const handleDeleteRow = (idx) => {
    let capabilityData = [...getFormData.capabilities];
    capabilityData.splice(idx, 1);
    getFormData.capabilities = capabilityData;
    setFormData(getFormData);
  };

  const handleIsCapabilityDropDown = (event) => {
    event.preventDefault();
    setIsCapabilityDropDown(true);
  };

  const handleCapability = (val) => {
    const isExist = formData?.capabilities.find(
      (item) => item.capability_id === val.value
    );
    if (!isExist) {
      setFormData((prevValue) => ({
        capabilities: [
          ...prevValue.capabilities,
          {
            display_name: val.text,
            capability_id: val.value,
            description: val?.description,
          },
        ],
      }));
    }

    setIsCapabilityDropDown(false);
  };

  const handleQuickAddCapability = (e) => {
    e.preventDefault();
    dispatch(
      setEditObjectId({
        formKey: `associateJobRoleFormCapability`,
        object_id: itemTagId,
      })
    );
    dispatch(
      openPanel({
        formKey: 'associateJobRoleFormCapability',
        formAction: 'Edit',
      })
    );
  };
  //#endregion Methods

  //#region Render time calcs
  //#endregion Render time calcs

  //#region Render

  return (
    <>
      <Box className={classes.skillTableWrapper}>
        <Box className={classes.skillHeader}>
          <Typography>
            {' '}
            {pluralize(
              user?.label_capability,
              formData?.capabilities?.length
            )}{' '}
            List
          </Typography>
          <Box className={classes.verticallyCenter}>
            {isCapabilityDropDown && capabilityDropDown.length > 0 && (
              <Autocomplete
                sx={{
                  minWidth: '340px',
                  maxWidth: '100%',
                }}
                limitTags={2}
                disableCloseOnSelect
                variant="outline"
                id="level-id"
                options={[...capabilityDropDown]}
                onChange={(event, newInputValue) => {
                  handleCapability(newInputValue);
                }}
                value={null}
                getOptionLabel={(option) => String(option.text)}
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={props.id}>
                    {option.text}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    label={user?.label_capability}
                    placeholder={user?.label_capability}
                  />
                )}
              />
            )}
            {!isCapabilityDropDown && (
              <IconButton
                onClick={(event) => handleIsCapabilityDropDown(event)}
                disableRipple
                sx={{
                  marginLeft: 'auto',
                  paddingLeft: '1rem',
                }}
                size="small"
              >
                <MdAdd /> Add {user?.label_capability}
              </IconButton>
            )}
            {formAction.id === FormActions.Edit.id && (
              <IconButton
                onClick={(event) => handleQuickAddCapability(event)}
                disableRipple
                sx={{
                  marginLeft: 'auto',
                  paddingLeft: '1rem',
                }}
                size="small"
              >
                <MdAccountTree />
              </IconButton>
            )}
          </Box>
        </Box>
        <TableContainer
          component={Paper}
          className={classes.tableMainContainer}
        >
          <Table
            sx={{ minWidth: 350 }}
            aria-label="simple table"
            className={classes.tableWrapper}
          >
            <TableHead>
              <TableRow>
                <TableCell colSpan="2"></TableCell>
                <TableCell width="450">
                  <Typography variant="body1" fontWeight="bold">
                    {user?.label_capability}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {formData?.capabilities?.map((row, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>
                      {row?.description ? (
                        <Tooltip title={row?.description}>
                          <IconButton>
                            <MdInfo />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <IconButton>
                          <MdInfo />
                        </IconButton>
                      )}
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleDeleteRow(index)}>
                        <MdDelete />
                      </IconButton>
                    </TableCell>
                    <TableCell width="200" className={classes.borderX}>
                      {row.display_name}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
  //#endregion Render
};
export { ItemJobRoleCapabilityTable };
