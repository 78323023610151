import { useState, useCallback } from 'react';
import { validate } from 'email-validator';
import { useSelector, useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { clsx } from 'clsx';
import { appStyles } from 'app/styles';
import {
  Button,
  TextField,
  Typography,
  Grid,
  Alert,
  AlertTitle,
} from '@mui/material';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import {
  loginUser,
  setAppIsLoading,
  selectActiveUser,
  selectLoginError,
  selectAppIsLoading,
  setLoginError,
} from 'features/account/accountSlice';
import { localStyles } from './LoginForm.styles';
import Loader from 'shared/components/Loader';
import { config } from 'ectl-config';
import { showDodModal } from 'features/common/actionModalSlice';

function LoginForm({ theme, loginCompleted }) {
  const activeUser = useSelector(selectActiveUser);
  var loginEmail = sessionStorage.getItem('loginEmail') ?? '';
  sessionStorage.removeItem('loginEmail');
  const [enteredEmail, setEnteredEmail] = useState(loginEmail);
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [emailError, setEmailError] = useState('');
  const isLoading = useSelector(selectAppIsLoading);
  const getErrorMessage = useSelector(selectLoginError);

  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const appClasses = appStyles(theme);
  const classes = localStyles(theme);

  const handleLogin = useCallback(
    async (user = null) => {
      var email = user?.email;

      const resultAction = await dispatch(
        loginUser({
          email: email || enteredEmail,
        })
      );
      if (!resultAction.error) {
        if (loginCompleted) {
          await loginCompleted();
        }
        unwrapResult(resultAction);
        const { from } = location.state || {
          from: { pathname: '/' },
        };
        navigate({ ...from, ...{ to: '/' } }, { replace: true });
        dispatch(showDodModal(true));
      }
    },
    [dispatch, enteredEmail, location.state, loginCompleted, navigate]
  );

  const onSubmit = async () => {
    try {
      sessionStorage.setItem('loginEmail', enteredEmail);
      clearError();
      if (validation()) {
        handleLogin();
      }
    } catch (err) {
      console.error(``, err);
    } finally {
      dispatch(setAppIsLoading(false));
    }
  };

  function validation() {
    if (!validate(enteredEmail)) {
      setEmailError('Please enter a valid email.');
      setIsInvalidEmail(true);
      return false;
    }

    return true;
  }

  function clearError() {
    dispatch(setLoginError(false));
    setEmailError('');
    setIsInvalidEmail(false);
  }

  function keyPress(e) {
    if (e.nativeEvent.key === 'Enter') {
      e.preventDefault();
      onSubmit();
    }
  }

  if (activeUser) {
    dispatch(showDodModal(true));
  }

  return activeUser ? (
    <Navigate to={location.state?.from || '/'} />
  ) : !isLoading ? (
    <>
      <Grid item className={classes.loginForm}>
        <Typography className={clsx(appClasses.spacing, appClasses.formHeader)}>
          System for Mapping/Reporting/Tracking Skills
        </Typography>
        {config.allowEmailLogin ? (
          <>
            <form className={clsx(appClasses.arrangement, appClasses.spacing)}>
              <TextField
                onChange={(e) => setEnteredEmail(e.target.value)}
                onKeyDown={(e) => keyPress(e)}
                error={isInvalidEmail}
                helperText={emailError}
                size="small"
                label="Email"
                name="email"
                value={enteredEmail}
                variant="outlined"
                required
                className={appClasses.spacing}
                autoComplete="email"
              />
              <Button
                size="medium"
                variant="contained"
                color="secondary"
                disableElevation
                onClick={onSubmit}
              >
                Login
              </Button>
            </form>
            {getErrorMessage && (
              <Alert severity="error" className={classes.loginError}>
                <AlertTitle>
                  <strong>Something is wrong!</strong>
                </AlertTitle>
                {getErrorMessage.code === 'ECONNABORTED' ? (
                  <div>
                    Login timed out. Please check your internet connection.
                  </div>
                ) : (
                  <div>
                    The username or password could be wrong, please review that
                    or start the Forgot username/password process. If the
                    problem persists, please contact your administrator
                  </div>
                )}
              </Alert>
            )}
          </>
        ) : (
          <Typography>
            Your DoD Id is not in the database, please contact an administrator
            to have your DoD Id added.
          </Typography>
        )}
      </Grid>
    </>
  ) : (
    <Loader
      styles={{
        background: '#ffffff',
        position: 'fixed',
        height: '100vh',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: '1',
        top: '0',
        left: '0',
      }}
    />
  );
}

export { LoginForm };
