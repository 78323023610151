export const validateAndFormatColor = (inputColor) => {
  if (!inputColor) {
    return '#000000';
  }

  const trimmedColor = inputColor.trim();
  const hexColorRegex = /^#?([0-9A-Fa-f]{6})$/;

  if (hexColorRegex.test(trimmedColor)) {
    return `#${trimmedColor.replace('#', '')}`;
  } else {
    return '#000000';
  }
};

export const sanitizeEnotationInput = (input) => {
  let sanitizedInput = input.toString().replace(/[^0-9.]/g, '');
  return sanitizedInput ?? '';
};
