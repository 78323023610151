import { omit } from 'lodash';

export const clearFilter = (formRef) => {
  let isFilterData = false;
  if (Object.keys(formRef?.current?.values).length > 0) {
    const removeSortedKeys = omit(formRef?.current?.values, [
      'startIndex',
      'stopIndex',
      'reset',
      'sort',
    ]);
    Object.keys(removeSortedKeys).forEach((key) => {
      if (!!removeSortedKeys[key]) {
        isFilterData = true;
      }
    });
  }
  return isFilterData;
};
