import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MdClose } from 'react-icons/md';

import SearchWithFilters from 'components/SearchWithFilters';
import {
  fetchLevelsDropDown,
  fetchSkillExpiryDropDown,
  getLevelDropDownStatus,
  getLevelDropdownOpen,
  getSkillExpiryDropDownStatus,
  resetLevelExpiryState,
  selectLevelDropDown,
  selectSkillExpiryDropDown,
  setIsLevelDropdownOpen,
} from 'features/AdminSettingsDropDown/dropDownSlice';
import {
  fetchAssociateCapability,
  getAssociateCapabilityStatus,
  resetAssociateCapability,
  resetCapabilityState,
  selectAssociateCapability,
  updateAssociateCapabilitySkill,
} from 'features/capability/capabilitySlice';
import {
  closePanel,
  openPanel,
  setEditObjectId,
} from 'features/common/formSlice';
import {
  activateLoading,
  deactivateLoading,
} from 'features/global/loadingProgressSlice';
import { makeFormStyles } from 'forms/style';
import { snackbarHandler } from 'helpers';
import { Each } from 'helpers/Each';
import { useDebounce } from 'hooks/useDebounce';
import { useDispatch, useSelector } from 'react-redux';
import { MultiSelectDoneButton } from 'shared/components/MultiSelectDoneButton';
import { SnackbarDismiss } from 'shared/components/SnackbarDismiss';
import { NoDataMessage } from 'shared/components/_Tables/NoDataMessage';
import { FormActions } from 'helpers/form-action';

const CustomChip = (props) => {
  return (
    <Chip
      {...props}
      sx={{
        backgroundColor:
          `#${props?.option?.color}10` ?? 'rgba(255,255,255,0.3)',
        color: `#${props?.option?.color}` ?? '#000000',
        borderRadius: '0',
        '& svg': {
          fill: `#${props?.option?.color}` ?? 'white',
          fontSize: '1rem !important',
        },
      }}
      deleteIcon={<MdClose />}
    />
  );
};

const renderOption = (props, option, { selected }) => (
  <li {...props} key={option.value}>
    <Checkbox style={{ marginRight: 8 }} checked={selected} />
    {option.text}
  </li>
);

const renderInput = (params) => (
  <TextField
    {...params}
    variant="outlined"
    label="Level IDs"
    placeholder="Level IDs"
  />
);

const renderTags = (tagValue, getTagProps) => {
  return tagValue.map((option, index) => (
    <CustomChip
      {...getTagProps({ index })}
      label={option.text}
      option={option}
    />
  ));
};

const AssociatCapabilitySkill = ({ formAction, itemTagId }) => {
  //#region Constants
  const classes = makeFormStyles();
  const dispatch = useDispatch();
  const paperRef = useRef(null);
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  const [selected, setSelected] = useState([]);
  const [filteredFormData, setFilteredFormData] = useState([]);
  const isSelected = (id) => selected.indexOf(id) !== -1;
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [selectedSkillRow, setSelectedSkillRow] = useState(null);
  const [selectedSkillExpiryRow, setSelectedSkillExpiryRow] = useState(null);
  //#endregion State

  //#region Selectors
  const levelDropDown = useSelector(selectLevelDropDown);
  const levelDropDownStatus = useSelector(getLevelDropDownStatus);
  const skillExpiryDropDown = useSelector(selectSkillExpiryDropDown);
  const skillExpiryStatus = useSelector(getSkillExpiryDropDownStatus);
  const associationStatus = useSelector(getAssociateCapabilityStatus);
  const associateData = useSelector(selectAssociateCapability);
  const isLevelDropdownOpen = useSelector(getLevelDropdownOpen);
  //#endregion Selectors

  //#region Refs
  //#endregion Refs

  //#region Effects
  useEffect(() => {
    (async () => {
      dispatch(activateLoading());
      await Promise.all([
        dispatch(fetchLevelsDropDown()),
        dispatch(fetchSkillExpiryDropDown()),
        dispatch(fetchAssociateCapability(itemTagId)),
      ]);
      dispatch(deactivateLoading());
    })();

    return () => {
      dispatch(resetLevelExpiryState());
      dispatch(resetAssociateCapability());
    };
  }, []);

  useEffect(() => {
    if (associateData?.length > 0) {
      setSelected(
        associateData
          ?.filter((item) => item?.is_checked)
          .map((item) => item.skill_id)
      );
    }
  }, [associateData]);

  const initValues = useMemo(() => {
    if (associateData?.length > 0) {
      const updatedData = {};
      associateData.forEach((data) => {
        updatedData[data?.skill_id] = data;
      });
      return updatedData;
    }
  }, [associateData]);

  useEffect(() => {
    if (associateData?.length > 0) {
      setFilteredFormData([...associateData]);
    }
  }, [associateData]);
  //#endregion Effects

  //#region Methods
  const handleClickSelect = (skill_id) => {
    const selectedIndex = selected.indexOf(skill_id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, skill_id);
    } else {
      newSelected = selected.filter((id) => id !== skill_id);
    }

    setSelected(newSelected);
  };

  const handleSelectAllClick = (event, setFieldValue) => {
    if (event.target.checked) {
      const newSelectedIds = associateData?.map(
        (audience) => audience?.skill_id
      );
      setSelected(newSelectedIds);
      associateData.forEach((item) => {
        setFieldValue(`[${item.skill_id}].is_checked`, true);
      });
      return;
    }
    associateData.forEach((item) => {
      setFieldValue(`[${item.skill_id}].is_checked`, false);
    });
    setSelected([]);
  };

  const debouncedSearch = useCallback(
    useDebounce(async (searchString) => {
      if (associateData) {
        const filteredData = await associateData.filter((item) =>
          item?.display_name?.toLowerCase().includes(searchString.toLowerCase())
        );
        setFilteredFormData(filteredData);
      }
    }, 1000),
    [associateData]
  );

  function convertData(data) {
    const result = [];
    Object.values(data).forEach((associateData) => {
      if (data.is_checked.includes(associateData?.skill_id)) {
        result.push({
          capability_id: data.capabiliyId,
          years_to_expiry:
            associateData?.years_to_expiry?.value === null
              ? null
              : +associateData.years_to_expiry?.value,
          can_group_train: associateData.can_group_train,
          is_checked: data.is_checked.includes(associateData.skill_id),
          skill_id: associateData.skill_id,
          level_ids: associateData?.level_ids?.map((levelId) => levelId.value),
        });
      }
    });
    return result;
  }

  const submitData = useCallback(
    async (values) => {
      var resultAction = null;
      const associateData = convertData({
        ...values,
        is_checked: selected,
      });
      if (associateData?.length > 0) {
        dispatch(activateLoading());
        setIsFormSubmitting(true);
        resultAction = await dispatch(
          updateAssociateCapabilitySkill({
            capabilityData: associateData,
            capability_id: itemTagId,
          })
        );
        if (!resultAction.error) {
          dispatch(closePanel({ formKey: `itemAssociateCapabilityForm` }));
          if (formAction.id === FormActions.Create.id) {
            await dispatch(resetCapabilityState());
          } else {
            await dispatch(
              setEditObjectId({
                formKey: `itemCapabilityForm`,
                object_id: itemTagId,
              })
            );
            await dispatch(
              openPanel({
                formKey: `itemCapabilityForm`,
                formAction: 'Edit',
              })
            );
          }
        }

        const { message, variant } = snackbarHandler(
          resultAction.meta.requestStatus,
          'Capability Skill Updated'
        );
        enqueueSnackbar(message, {
          action: (key) => <SnackbarDismiss key={key} />,
          variant: variant,
        });
        dispatch(deactivateLoading());
      } else {
        setIsFormSubmitting(false);
      }
    },
    [dispatch, itemTagId, selected]
  );

  const validate = useCallback(
    (values) => {
      const errors = {};
      selected.forEach((skillId) => {
        if (values[skillId]?.level_ids?.length === 0) {
          errors[`${skillId}.level_ids`] = 'Please select at least one level';
        }
      });
      return errors;
    },
    [selected]
  );
  //#endregion Methods

  //#region Render time calcs
  //#endregion Render time calcs

  //#region Render
  return (
    <>
      {levelDropDownStatus === 'Loaded' &&
        skillExpiryStatus === 'Loaded' &&
        associationStatus === 'Loaded' &&
        associateData.length > 0 && (
          <Formik
            enableReinitialize={true}
            initialValues={initValues}
            validate={validate}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              submitData(values, setSubmitting, resetForm);
            }}
          >
            {({ values, setFieldValue, errors, dirty }) => (
              <Form className={classes.form} style={{ padding: '0' }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    pb: '1rem',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      gap: '1rem',
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={isFormSubmitting || !dirty}
                    >
                      Update
                    </Button>
                    <SearchWithFilters
                      filter={false}
                      onChange={(evt) => debouncedSearch(evt.target.value)}
                    />
                  </Box>
                </Box>
                {selected?.length > 0 && (
                  <Typography
                    className={classes.title}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                  >
                    {selected.length} of {associateData?.length} selected
                  </Typography>
                )}
                <TableContainer
                  sx={{
                    height: 'calc(100vh - 295px)',
                    minWidth: '500px',
                    overflow: 'auto',
                  }}
                >
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Checkbox
                            indeterminate={
                              selected?.length > 0 &&
                              selected?.length < associateData?.length
                            }
                            checked={
                              associateData?.length > 0 &&
                              selected?.length === associateData?.length
                            }
                            onChange={(event) => {
                              handleSelectAllClick(event, setFieldValue);
                              setFieldValue('select_all', event.target.checked);
                            }}
                          />
                        </TableCell>
                        <TableCell width={280}>
                          <Typography variant="body1" fontWeight={'bold'}>
                            Name
                          </Typography>
                        </TableCell>
                        <TableCell minWidth={400}>
                          <Typography variant="body1" fontWeight={'bold'}>
                            Description
                          </Typography>
                        </TableCell>
                        <TableCell width={212}>
                          <Typography variant="body1" fontWeight={'bold'}>
                            Expires
                          </Typography>
                        </TableCell>
                        <TableCell width={170}>
                          <Typography variant="body1" fontWeight={'bold'}>
                            Group Training
                          </Typography>
                        </TableCell>
                        <TableCell width={300}>
                          <Typography variant="body1" fontWeight={'bold'}>
                            Levels
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredFormData?.length > 0 ? (
                        <Each
                          of={filteredFormData}
                          render={(item, idx) => (
                            <TableRow className={classes.tableRow} key={idx}>
                              <TableCell>
                                <Checkbox
                                  checked={isSelected(item.skill_id)}
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    handleClickSelect(item.skill_id);
                                    setFieldValue(
                                      `[${item?.skill_id}].is_checked`,
                                      event.target.checked
                                    );
                                  }}
                                />
                              </TableCell>
                              <TableCell>{item?.display_name}</TableCell>
                              <TableCell>
                                <Typography
                                  sx={{
                                    overflow: 'hidden !important',
                                    textOverflow: 'ellipsis',
                                    margin: '0',
                                    WebkitLineClamp: '3',
                                    display: '-webkit-box',
                                    WebkitBoxOrient: 'vertical',
                                  }}
                                >
                                  {item?.description}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                {selectedSkillExpiryRow !== item.skill_id &&
                                !values?.[item?.skill_id]?.is_checked ? (
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      gap: '0.5rem',
                                      border: '1px solid #c0c0c0',
                                      padding: '9px',
                                      width: '100%',
                                      borderRadius: '5px',
                                      height: '56px',
                                      overflow: 'hidden',
                                      alignItems: 'center',
                                      cursor: values?.[item?.skill_id]
                                        ?.is_checked
                                        ? 'pointer'
                                        : 'not-allowed',
                                      opacity: values?.[item?.skill_id]
                                        ?.is_checked
                                        ? '1'
                                        : '0.5',
                                    }}
                                    onClick={() => {
                                      if (
                                        values?.[item?.skill_id]?.is_checked
                                      ) {
                                        setSelectedSkillExpiryRow(
                                          item.skill_id
                                        );
                                      }
                                    }}
                                  >
                                    {values?.[item?.skill_id]
                                      ?.years_to_expiry ? (
                                      <Typography
                                        sx={{
                                          color: '#656363',
                                          fontSize: '1rem',
                                        }}
                                      >
                                        {
                                          values?.[item?.skill_id]
                                            ?.years_to_expiry?.text
                                        }
                                      </Typography>
                                    ) : (
                                      <Typography
                                        sx={{
                                          color: '#656363',
                                          fontSize: '1rem',
                                        }}
                                      >
                                        Year of Expiry
                                      </Typography>
                                    )}
                                  </Box>
                                ) : (
                                  <Autocomplete
                                    sx={{
                                      minWidth: '180px',
                                      maxWidth: '100%',
                                    }}
                                    variant="outline"
                                    id="expiry"
                                    name={`[${item?.skill_id}].years_to_expiry`}
                                    options={[...skillExpiryDropDown]}
                                    value={
                                      values?.[item?.skill_id]
                                        ?.years_to_expiry || null
                                    }
                                    isOptionEqualToValue={(option, value) =>
                                      option.value === value?.value
                                    }
                                    onChange={(_, newInputValue) => {
                                      setFieldValue(
                                        `[${item?.skill_id}].years_to_expiry`,
                                        newInputValue
                                      );
                                    }}
                                    onClose={() =>
                                      setSelectedSkillExpiryRow(null)
                                    }
                                    onBlur={() => {
                                      setSelectedSkillExpiryRow(null);
                                    }}
                                    getOptionLabel={(option) => option.text}
                                    renderOption={(props, option) => (
                                      <li {...props}>{option.text}</li>
                                    )}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Year of Expiry"
                                        placeholder="Year of Expiry"
                                      />
                                    )}
                                  />
                                )}
                              </TableCell>
                              <TableCell align="center">
                                <Checkbox
                                  checked={
                                    values?.[item?.skill_id]?.can_group_train ||
                                    false
                                  }
                                  onChange={(event) =>
                                    setFieldValue(
                                      `[${item?.skill_id}].can_group_train`,
                                      event.target.checked
                                    )
                                  }
                                  inputProps={item.can_group_train}
                                  disabled={
                                    !values?.[item?.skill_id]?.is_checked
                                  }
                                />
                              </TableCell>
                              <TableCell>
                                {isLevelDropdownOpen &&
                                selectedSkillRow === item.skill_id &&
                                values?.[item?.skill_id]?.is_checked ? (
                                  <Autocomplete
                                    fullWidth
                                    limitTags={2}
                                    multiple
                                    id="level-ids"
                                    options={levelDropDown}
                                    value={
                                      values?.[item?.skill_id]?.level_ids ?? []
                                    }
                                    isOptionEqualToValue={(option, value) =>
                                      option.value === value?.value
                                    }
                                    onChange={(_, newInputValue) => {
                                      setFieldValue(
                                        `[${item?.skill_id}].level_ids`,
                                        newInputValue
                                      );
                                    }}
                                    open={isLevelDropdownOpen}
                                    onBlur={() => {
                                      setSelectedSkillRow(null);
                                      dispatch(setIsLevelDropdownOpen(false));
                                    }}
                                    name={`[${item?.skill_id}].level_ids`}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option.text}
                                    getOptionValue={(option) => option.value}
                                    renderOption={renderOption}
                                    renderInput={renderInput}
                                    onClose={() => {
                                      setSelectedSkillRow(null);
                                      dispatch(setIsLevelDropdownOpen(false));
                                    }}
                                    onMount={() => {
                                      paperRef.current.scrollIntoView({
                                        behavior: 'auto',
                                      });
                                    }}
                                    PaperComponent={MultiSelectDoneButton}
                                    validate={(value) => {
                                      if (
                                        selected.length > 0 &&
                                        value.length === 0
                                      ) {
                                        return 'Please select at least one level';
                                      }
                                      return undefined;
                                    }}
                                    renderTags={renderTags}
                                  />
                                ) : (
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      gap: '0.5rem',
                                      border: '1px solid #c0c0c0',
                                      padding: '9px',
                                      width: '100%',
                                      borderRadius: '5px',
                                      height: '56px',
                                      overflow: 'hidden',
                                      alignItems: 'center',
                                      cursor: values?.[item?.skill_id]
                                        ?.is_checked
                                        ? 'pointer'
                                        : 'not-allowed',
                                      opacity: values?.[item?.skill_id]
                                        ?.is_checked
                                        ? '1'
                                        : '0.5',
                                    }}
                                    onClick={() => {
                                      if (
                                        values?.[item?.skill_id]?.is_checked
                                      ) {
                                        setSelectedSkillRow(item.skill_id);
                                        dispatch(setIsLevelDropdownOpen(true));
                                      }
                                    }}
                                  >
                                    {values?.[item?.skill_id]?.level_ids &&
                                    values?.[item?.skill_id]?.level_ids
                                      ?.length > 0 ? (
                                      values?.[item?.skill_id]?.level_ids
                                        ?.length > 2 ? (
                                        <>
                                          {values?.[item?.skill_id]?.level_ids
                                            ?.slice(0, 2)
                                            .map((data) => {
                                              return (
                                                <CustomChip
                                                  label={data.text}
                                                  option={data}
                                                />
                                              );
                                            })}
                                          <Typography variant="body2">
                                            +{' '}
                                            {values?.[item?.skill_id]?.level_ids
                                              ?.length - 2}
                                          </Typography>
                                        </>
                                      ) : (
                                        values?.[
                                          item?.skill_id
                                        ]?.level_ids?.map((data) => {
                                          return (
                                            <CustomChip
                                              label={data.text}
                                              option={data}
                                            />
                                          );
                                        })
                                      )
                                    ) : (
                                      <Typography
                                        sx={{
                                          color: '#656363',
                                          fontSize: '1rem',
                                        }}
                                      >
                                        Level IDs
                                      </Typography>
                                    )}
                                  </Box>
                                )}
                                {errors[`${item?.skill_id}.level_ids`] &&
                                  values?.[item?.skill_id]?.is_checked && (
                                    <div
                                      style={{
                                        color: 'red',
                                      }}
                                    >
                                      {errors[`${item?.skill_id}.level_ids`]}
                                    </div>
                                  )}
                              </TableCell>
                            </TableRow>
                          )}
                        />
                      ) : (
                        <TableRow className={classes.tableRow}>
                          <TableCell colSpan={6}>
                            <NoDataMessage
                              message={'No Data Found'}
                              setHeight="450px"
                            />
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Form>
            )}
          </Formik>
        )}
    </>
  );
  //#endregion Render
};

export default AssociatCapabilitySkill;
