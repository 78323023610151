import { useCallback, useEffect, useRef, useState } from 'react';
import { Grid } from '@mui/material';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { FilterAccordion } from 'shared/components/_Accordion';
import { clearFilter } from 'helpers/clearFilter';
import {
  selectAdminSettingCache,
  updateAdminSettingCache,
} from 'features/global/visibilitySlice';
import { useDebounce } from 'hooks/useDebounce';
import { ADMIN_JOB_CACHE } from 'helpers';
import { FormTextField } from 'shared/components/FormFields/TextField';
import { sanitizeEnotationInput } from 'hooks/validateAndFormateColor';

const ItemJobFilter = ({ setItemJobPayload, itemJobPayload }) => {
  //#region Constants
  const dispatch = useDispatch();
  const getCacheData = useSelector(selectAdminSettingCache);

  const jobCacheData = getCacheData?.[ADMIN_JOB_CACHE];
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  const [initValues, setInitValues] = useState({
    display_name: '',
    series_id: '',
    family_display_name: '',
    family_series_id: '',
  });
  //#endregion State

  //#region Selectors
  //#endregion Selectors

  //#region Refs
  const formRef = useRef();
  //#endregion Refs

  //#region Effects
  useEffect(() => {
    setInitValues({ ...initValues, ...jobCacheData });
  }, [getCacheData, itemJobPayload]);
  //#endregion Effects

  //#region Methods
  const handleSearch = useCallback(
    useDebounce((displayName, seriesId, familyDisplayName, familySeriesId) => {
      setItemJobPayload({
        ...itemJobPayload,
        display_name: displayName,
        series_id: seriesId,
        family_display_name: familyDisplayName,
        family_series_id: familySeriesId,
      });
    }, 1000),
    [itemJobPayload]
  );

  const handleResetFilters = () => {
    const isFilterData = clearFilter(formRef);
    if (isFilterData) {
      formRef?.current?.resetForm();
      setItemJobPayload({
        ...itemJobPayload,
        display_name: '',
        series_id: '',
        family_display_name: '',
        family_series_id: '',
      });
      dispatch(updateAdminSettingCache({ key: ADMIN_JOB_CACHE, ...{} }));
    }
  };
  //#endregion Methods

  //#region Render time calcs
  //#endregion Render time calcs

  //#region Render
  return (
    <FilterAccordion
      filteredTitles={itemJobPayload}
      expandedId="job-filter"
      styles={{ marginBottom: '1.5rem' }}
      onResetFilters={handleResetFilters}
    >
      <Formik
        enableReinitialize={true}
        initialValues={{ ...initValues }}
        innerRef={formRef}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Grid container columnSpacing={'1rem'}>
              <Grid item xs={12} md={6} lg={3}>
                <FormTextField
                  name="display_name"
                  label="Display Name"
                  placeholder="Display Name"
                  fullWidth
                  onChange={(e) => {
                    const searchTerm = e.target.value;
                    setFieldValue('display_name', searchTerm);
                    handleSearch(
                      searchTerm,
                      values?.series_id,
                      values?.family_display_name,
                      values?.family_series_id
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <FormTextField
                  name="series_id"
                  label="Series Id"
                  placeholder="Series Id"
                  type="text"
                  fullWidth
                  onChange={(e) => {
                    const searchTerm = e.target.value;
                    const filterValue = sanitizeEnotationInput(searchTerm);
                    setFieldValue('series_id', filterValue);
                    handleSearch(
                      values?.display_name,
                      filterValue,
                      values?.family_display_name,
                      values?.family_series_id
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <FormTextField
                  name="family_display_name"
                  label="Family Name"
                  placeholder="Family Name"
                  fullWidth
                  onChange={(e) => {
                    const searchTerm = e.target.value;
                    setFieldValue('family_display_name', searchTerm);
                    handleSearch(
                      values?.display_name,
                      values?.series_id,
                      searchTerm,
                      values?.family_series_id
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <FormTextField
                  name="family_series_id"
                  label="Family Series ID"
                  placeholder="Family Series ID"
                  fullWidth
                  onChange={(e) => {
                    const searchTerm = e.target.value;
                    const filterValue = sanitizeEnotationInput(searchTerm);
                    setFieldValue('family_series_id', filterValue);
                    handleSearch(
                      values?.display_name,
                      values?.series_id,
                      values?.family_display_name,
                      filterValue
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </FilterAccordion>
  );
  //#endregion Render
};
export { ItemJobFilter };
