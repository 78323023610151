import {
  getGenericModalContent,
  getGenericModalVis,
  toggleGenericModal,
} from 'features/global/visibilitySlice';
import { Modal } from '@mui/material';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { modalStyles } from '../Modals.styles';
import { useEffect } from 'react';

function DisplayModal() {
  const classes = modalStyles();
  const dispatch = useDispatch();
  const modalVis = useSelector(getGenericModalVis);
  const modalContent = useSelector(getGenericModalContent);

  useEffect(() => {}, [modalContent, dispatch]);

  const handleClose = async () => {
    dispatch(toggleGenericModal());
  };

  return (
    <Modal open={modalVis} onClose={handleClose}>
      <div
        className={clsx(classes.modalContainer, classes.roundedCornerContainer)}
      >
        <div dangerouslySetInnerHTML={{ __html: modalContent }}></div>
      </div>
    </Modal>
  );
}

export default DisplayModal;
