import { createSlice } from '@reduxjs/toolkit';
import { EMPLOYEE_CACHE } from 'helpers';

const initialState = {
  genericModal: false,
  genericModalContent: '',
  adminTabIndex: 0,
  teamsTabIndex: 0,
  talentTabIndex: 0,
  contentVersionCreatePanel: false,
  adminSettingsCache: {
    [EMPLOYEE_CACHE]: {
      first_name: '',
      last_name: '',
      dod_id: '',
      supervisor_employee_id: null,
      pay_plan_id: null,
      pay_grade_id: null,
      system_role_id: null,
      job_id: null,
      job_role_id: null,
      employee_status: null,
      cost_center_id: null,
      office_id: null,
      reset: true,
      startIndex: 0,
      stopIndex: 19,
    },
  },
};

const visibilitySlice = createSlice({
  name: 'visibility',
  initialState,
  reducers: {
    toggleGenericModal: (state) => {
      return {
        ...state,
        genericModal: !state.genericModal,
      };
    },
    setGenericModalContent: (state, action) => {
      state.genericModalContent = action.payload;
    },
    setAdminTabIndex: (state, action) => {
      state.adminTabIndex = action.payload;
    },
    setTeamsTabIndex: (state, action) => {
      state.teamsTabIndex = action.payload;
    },
    setTalentTabIndex: (state, action) => {
      state.talentTabIndex = action.payload;
    },
    showContentVersionCreatePanel: (state, action) => {
      state.contentVersionCreatePanel = true;
      state.contentVersionContent = action.payload.content;
    },
    closeContentVersionCreatePanel: (state) => {
      state.contentVersionCreatePanel = false;
      state.contentVersionContent = null;
    },
    updateAdminSettingCache: (state, action) => {
      const { key, payload } = action.payload;
      const prevValue = state.adminSettingsCache[key];
      state.adminSettingsCache[key] = { ...prevValue, ...payload };
    },
  },
  extraReducers: {},
});

export const getGenericModalVis = (state) => state.visibility.genericModal;
export const getGenericModalContent = (state) =>
  state.visibility.genericModalContent;
export const getAdminTabIndex = (state) => state.visibility.adminTabIndex;
export const getContentVersionPanelVis = (state) =>
  state.visibility.contentVersionCreatePanel;

export const selectAdminSettingCache = (state) =>
  state.visibility.adminSettingsCache;

export const getTeamsTabIndex = (state) => state.visibility.teamsTabIndex;
export const getTalentTabIndex = (state) => state.visibility.talentTabIndex;

export const {
  toggleGenericModal,
  setGenericModalContent,
  setAdminTabIndex,
  updateAdminSettingCache,
  setTeamsTabIndex,
  setTalentTabIndex,
} = visibilitySlice.actions;
export default visibilitySlice.reducer;
