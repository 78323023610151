import { createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import login_image from 'assets/ectl_login_photo_opt1.jpg';

const appStyles = makeStyles((theme) =>
  createStyles({
    // The main flex container for the app's layout. Its min-height
    // is set to `100vh` so it always fill the height of the screen.
    root: {
      display: 'flex',
      minHeight: '100vh',
      zIndex: 1,
      position: 'relative',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.default,
    },
    // Styles for the root `div` element in the `Drawer` component.
    drawer: {
      width: theme.layout.drawerWidth,
    },
    // Styles for the `Paper` component rendered by `Drawer`.
    drawerPaper: {
      width: 'inherit',
      paddingTop: 64, // equal to AppBar height
    },
    logo: {
      height: '66px',
    },
    mainContentTitle: {
      backgroundColor: theme.colors.primarySectionForeground,
      height: theme.layout.appBarHeight,
      margin: 'auto',
      paddingLeft: '25px',
      color: theme.colors.text,
      display: 'flex',
      alignItems: 'center',
      border: '1px solid rgba(0,0,0,0)',
    },
    loginLogoTitle: {
      color: '#59A7AB',
      fontSize: 64,
      margin: 0,
    },
    loginContainer: {
      minHeight: '100vh',
      padding: '50px',
      backgroundColor: '#FFFFFF',
      boxShadow: '-4px 0 29px 0 rgba(45,61,80,0.52)',
      '& $logo': {
        display: 'block',
        clear: 'left',
        height: '30px',
      },
      '& $loginLogoTitle': {
        display: 'block',
      },
    },
    loginBackgroundContainer: {
      width: '100%',
      background: `no-repeat url(${login_image})`,
      backgroundSize: 'cover',
      height: '100%',
    },
    loginGridItem: {
      width: '100%',
    },
    loginContent: {
      marginTop: 75,
      '& div': {
        marginBottom: 15,
      },
      '& $spacing': {
        marginBottom: 15,
      },
    },
    arrangement: {
      display: 'flex',
      flexDirection: 'column',
    },
    spacing: {
      marginBottom: '20px',
    },
  })
);

export { appStyles };
