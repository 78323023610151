import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Button, Grid, TextField } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';

import { makeFormStyles } from 'forms/style';
import {
    fetchCapabilityDropDown,
    fetchCommandDropDown,
    fetchCostCenterDropDown,
    fetchDepotDropDown,
    fetchJobRolesByJobId,
    fetchJobsDropDown,
    fetchOfficeByCostCenterId,
    fetchReportExpirationDropDown,
    fetchSuperVisorDropDown,
    getCapabilityDropDownStatus,
    getCommandDropDownStatus,
    getCostCenterDropDownStatus,
    getDepotDropDownStatus,
    getJobDropDownStatus,
    getJobRolesByJobIdDropDownStatus,
    getOfficeByCostCenterIdDropDownStatus,
    getReportExpiryDropDownStatus,
    getSuperVisorDropDownStatus,
    resetDepotDropDownState,
    resetDropDownState,
    resetOfficeIdDropDown,
    selectCapabilitiesDropDown,
    selectCommondDropDown,
    selectCostCenterDropDown,
    selectDepotDropDown,
    selectJobDropDown,
    selectJobRoleByJobIdDropDown,
    selectOfficeByCostCenterIdDropDown,
    selectReportExpiryDropDown,
    selectSuperVisorDropDown,
} from 'features/AdminSettingsDropDown/dropDownSlice';
import { SelectionAutoComplete } from 'shared/components/_Autocomplete/SelectionAutocomplete';
import {
    CAPABILITY_ID,
    COST_CENTER_ID,
    SUPER_VISOR_ID,
    SystemRoleEnums,
} from 'helpers';
import { selectActiveUser } from 'features/account/accountSlice';
import { ReportsTable } from './ReportsTable';
import {
    fetchReports,
    getReportSampleCsv,
    resetReportState,
    selectReports,
} from 'features/Reports/reportSlice';
import {
    activateLoading,
    deactivateLoading,
} from 'features/global/loadingProgressSlice';
import { FilterAccordion } from 'shared/components/_Accordion';
import { clearFilter } from 'helpers/clearFilter';
import { useDebounce } from 'hooks/useDebounce';
import { useLocation } from 'react-router-dom';

function filterObjectsByPropertyValue(value, dataArray) {
    if (dataArray.length > 0) {
        return dataArray.find((obj) => obj?.value === value);
    }
}

const GenerateFilter = () => {
    //#region Constants
    const classes = makeFormStyles();
    const dispatch = useDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const superVisorDropDown = useSelector(selectSuperVisorDropDown);
    const capabilitiesDropDown = useSelector(selectCapabilitiesDropDown);
    const costCenterDropDown = useSelector(selectCostCenterDropDown);
    const jobDropDown = useSelector(selectJobDropDown);
    const jobRoleDropDown = useSelector(selectJobRoleByJobIdDropDown);
    const officeDropDown = useSelector(selectOfficeByCostCenterIdDropDown);
    const expiryDropDown = useSelector(selectReportExpiryDropDown);
    //#endregion Constants

    //#region Hooks
    //#endregion Hooks

    //#region State
    const initValues = {
        supervisor_employee_id: queryParams.get('supervisor_employee_id')
            ? +queryParams.get('supervisor_employee_id')
            : null,
        capability_id: queryParams.get('capability_id')
            ? +queryParams.get('capability_id')
            : null,
        cost_center: queryParams.get('treeMapCostCenterId')
            ? +queryParams.get('treeMapCostCenterId')
            : null,
        job_id: queryParams.get('treeMapJobId')
            ? +queryParams.get('treeMapJobId')
            : null,
        job_role_id: queryParams.get('job_role_id')
            ? +queryParams.get('job_role_id')
            : null,
        office_id: queryParams.get('office_id')
            ? +queryParams.get('office_id')
            : null,
        report_expiry: queryParams.get('report_expiry')
            ? { value: +queryParams.get('report_expiry'), text: 'custom' }
            : { value: 30, text: '30 Days' },
        createdDate: queryParams.get('custom_expiry_date')
            ? queryParams.get('custom_expiry_date')
            : null,
        command_id: null,
        depot_id: null,
    };

    const [reportTableData, setReportTableData] = useState({
        ...initValues,
        supervisor_employee_id: queryParams.get('supervisor_employee_id')
            ? filterObjectsByPropertyValue(
                  +queryParams.get('supervisor_employee_id'),
                  superVisorDropDown
              )
            : null,
        capability_id: queryParams.get('capability_id')
            ? filterObjectsByPropertyValue(
                  +queryParams.get('capability_id'),
                  capabilitiesDropDown
              )
            : null,
        cost_center: queryParams.get('treeMapCostCenterId')
            ? filterObjectsByPropertyValue(
                  +queryParams.get('treeMapCostCenterId'),
                  costCenterDropDown
              )
            : null,
        job_id: queryParams.get('treeMapJobId')
            ? filterObjectsByPropertyValue(
                  +queryParams.get('treeMapJobId'),
                  jobDropDown
              )
            : null,
        job_role_id: queryParams.get('job_role_id')
            ? filterObjectsByPropertyValue(
                  +queryParams.get('job_role_id'),
                  jobRoleDropDown
              )
            : null,
        office_id: queryParams.get('office_id')
            ? filterObjectsByPropertyValue(
                  +queryParams.get('office_id'),
                  officeDropDown
              )
            : null,
        report_expiry: queryParams.get('report_expiry')
            ? filterObjectsByPropertyValue(
                  +queryParams.get('report_expiry'),
                  expiryDropDown
              )
            : { value: 30, text: '30 Days' },
        start_index: 0,
        stop_index: 10,
    });

    const [createdDateFilter, setCreatedDateFilter] = useState(
        moment(queryParams.get('custom_expiry_date')) ?? null
    );

    //#endregion State

    //#region Selectors
    const user = useSelector(selectActiveUser);
    const reportsData = useSelector(selectReports);
    //#endregion Selectors

    //#region Refs
    const formRef = useRef();
    const ref = useRef(true);
    //#endregion Refs

    //#region Effects
    const fetchAll = async () => {
        dispatch(activateLoading());
        await dispatch(
            fetchReports({
                supervisor_employee_id:
                    reportTableData?.supervisor_employee_id?.value ?? null,
                cost_center: reportTableData?.cost_center?.value ?? null,
                capability_id: reportTableData?.capability_id?.value ?? null,
                job_id: reportTableData?.job_id?.value ?? null,
                job_role_id: reportTableData?.job_role_id?.value ?? null,
                report_expiry: reportTableData?.report_expiry?.value ?? null,
                custom_expiry_date: reportTableData?.createdDate ?? null,
                office_id: reportTableData?.office_id?.value ?? null,
                command_id: reportTableData?.command_id?.value ?? null,
                depot_id: reportTableData?.depot_id?.value ?? null,
                start_index: reportTableData.start_index,
                stop_index: reportTableData.stop_index,
                reset: reportTableData.reset ?? true,
            })
        );
        dispatch(deactivateLoading());
    };

    useEffect(() => {
        if (
            ref.current === true &&
            location?.search &&
            superVisorDropDown?.length > 0 &&
            capabilitiesDropDown?.length > 0 &&
            costCenterDropDown?.length > 0 &&
            jobDropDown?.length > 0 &&
            jobRoleDropDown?.length > 0 &&
            officeDropDown?.length > 0 &&
            expiryDropDown?.length > 0
        ) {
            const filterPayload = {
                start_index: 0,
                stop_index: 10,
            };
            if (queryParams.get('supervisor_employee_id')) {
                filterPayload['supervisor_employee_id'] =
                    filterObjectsByPropertyValue(
                        +queryParams.get('supervisor_employee_id'),
                        superVisorDropDown
                    );
            }
            if (queryParams.get('capability_id')) {
                filterPayload['capability_id'] = filterObjectsByPropertyValue(
                    +queryParams.get('capability_id'),
                    capabilitiesDropDown
                );
            }
            if (queryParams.get('treeMapCostCenterId')) {
                filterPayload['cost_center'] = filterObjectsByPropertyValue(
                    +queryParams.get('treeMapCostCenterId'),
                    costCenterDropDown
                );
            }
            if (queryParams.get('treeMapJobId')) {
                filterPayload['job_id'] = filterObjectsByPropertyValue(
                    +queryParams.get('treeMapJobId'),
                    jobDropDown
                );
            }
            if (queryParams.get('job_role_id')) {
                filterPayload['job_role_id'] = filterObjectsByPropertyValue(
                    +queryParams.get('job_role_id'),
                    jobRoleDropDown
                );
            }
            if (queryParams.get('office_id')) {
                filterPayload['office_id'] = filterObjectsByPropertyValue(
                    +queryParams.get('office_id'),
                    officeDropDown
                );
            }
            if (queryParams.get('report_expiry')) {
                filterPayload['report_expiry'] = filterObjectsByPropertyValue(
                    +queryParams.get('report_expiry'),
                    expiryDropDown
                );
                initValues.display_date = {
                    text: queryParams.get('custom_expiry_date'),
                };
            } else {
                filterPayload['report_expiry'] = { value: 30, text: '30 Days' };
            }
            if (queryParams.get('custom_expiry_date')) {
                filterPayload['createdDate'] =
                    queryParams.get('custom_expiry_date');
            }
            ref.current = false;
            setReportTableData({ ...filterPayload });
        }
    }, [
        location.search,
        superVisorDropDown,
        capabilitiesDropDown,
        costCenterDropDown,
        jobDropDown,
        jobRoleDropDown,
        officeDropDown,
        expiryDropDown,
    ]);

    useEffect(() => {
        async function fetchData() {
            dispatch(
                fetchJobRolesByJobId({
                    job_id: reportTableData?.job_id?.value,
                })
            );
        }
        if (reportTableData?.job_id) {
            fetchData();
        }
        fetchAll();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportTableData]);

    useEffect(() => {
        return () => {
            dispatch(resetDropDownState());
            dispatch(resetReportState());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //#endregion Effects

    //#region Methods
    const setTeamTableDataID = (value, key, setFieldValue) => {
        switch (key) {
            case SUPER_VISOR_ID:
                setFieldValue('supervisor_employee_id', value.value);
                setReportTableData((prevValue) => ({
                    ...prevValue,
                    supervisor_employee_id: value,
                    reset: true,
                    start_index: 0,
                    stop_index: 10,
                }));
                break;
            case CAPABILITY_ID:
                setFieldValue('capability_id', value.value);
                setReportTableData((prevValue) => ({
                    ...prevValue,
                    capability_id: value,
                    reset: true,
                    start_index: 0,
                    stop_index: 10,
                }));
                break;
            case COST_CENTER_ID:
                setFieldValue('cost_center', value?.value);
                setFieldValue('office_id', null);
                setReportTableData((prevValue) => ({
                    ...prevValue,
                    cost_center: value,
                    reset: true,
                    start_index: 0,
                    stop_index: 10,
                }));
                break;
            case 'job_id':
                setFieldValue('job_id', value.value);
                setFieldValue('job_role_id', null);
                setReportTableData((prevValue) => ({
                    ...prevValue,
                    job_id: value,
                    job_role_id: null,
                    reset: true,
                    start_index: 0,
                    stop_index: 10,
                }));
                break;
            case 'job_role_id':
                setFieldValue('job_role_id', value.value);
                setReportTableData((prevValue) => ({
                    ...prevValue,
                    job_role_id: value,
                    reset: true,
                    start_index: 0,
                    stop_index: 10,
                }));
                break;
            case 'report_expiry':
                if (value?.value !== 1) {
                    setCreatedDateFilter(null);
                    setFieldValue('report_expiry', value);
                    setReportTableData((prevValue) => ({
                        ...prevValue,
                        createdDate: null,
                        display_date: null,
                        report_expiry: value,
                        reset: true,
                        start_index: 0,
                        stop_index: 10,
                    }));
                } else {
                    setFieldValue('report_expiry', value);
                }
                break;
            case 'office_id':
                setFieldValue('office_id', value?.value);
                setReportTableData((prevValue) => ({
                    ...prevValue,
                    office_id: value,
                    reset: true,
                    start_index: 0,
                    stop_index: 10,
                }));
                break;
            case 'depot_id':
                setFieldValue('depot_id', value.value);
                setReportTableData((prevValue) => ({
                    ...prevValue,
                    depot_id: value,
                    reset: true,
                    start_index: 0,
                    stop_index: 10,
                }));
                break;
            case 'command_id':
                setFieldValue('command_id', value.value);
                setReportTableData((prevValue) => ({
                    ...prevValue,
                    command_id: value,
                    depot_id: null,
                    reset: true,
                    start_index: 0,
                    stop_index: 10,
                }));
                break;
            default:
                break;
        }
    };
    const removeTeamTableId = (setFieldValue, key) => {
        switch (key) {
            case SUPER_VISOR_ID:
                setFieldValue('supervisor_employee_id', null);
                setReportTableData((prevValue) => ({
                    ...prevValue,
                    supervisor_employee_id: null,
                    reset: true,
                    start_index: 0,
                    stop_index: 10,
                }));
                break;
            case CAPABILITY_ID:
                setFieldValue('capability_id', null);
                setReportTableData((prevValue) => ({
                    ...prevValue,
                    capability_id: null,
                    reset: true,
                    start_index: 0,
                    stop_index: 10,
                }));
                break;
            case COST_CENTER_ID:
                setFieldValue('cost_center', null);
                setReportTableData((prevValue) => ({
                    ...prevValue,
                    cost_center: null,
                    reset: true,
                    start_index: 0,
                    stop_index: 10,
                }));
                break;
            case 'job_id':
                setFieldValue('job_id', null);
                setReportTableData((prevValue) => ({
                    ...prevValue,
                    job_id: null,
                    reset: true,
                    start_index: 0,
                    stop_index: 10,
                }));
                dispatch(
                    fetchJobRolesByJobId({
                        job_id: null,
                        start_index: 0,
                        stop_index: 10,
                    })
                );
                break;
            case 'job_role_id':
                setFieldValue('job_role_id', null);
                setReportTableData((prevValue) => ({
                    ...prevValue,
                    job_role_id: null,
                    reset: true,
                    start_index: 0,
                    stop_index: 10,
                }));
                break;
            case 'report_expiry':
                setFieldValue('report_expiry', { value: 30, text: '30 Days' });
                setCreatedDateFilter(null);
                setReportTableData((prevValue) => ({
                    ...prevValue,
                    report_expiry: { value: 30, text: '30 Days' },
                    display_date: null,
                    custom_expiry_date: null,
                    createdDate: null,
                    reset: true,
                    start_index: 0,
                    stop_index: 10,
                }));
                break;
            case 'office_id':
                setFieldValue('office_id', null);
                setReportTableData((prevValue) => ({
                    ...prevValue,
                    office_id: null,
                    reset: true,
                    start_index: 0,
                    stop_index: 10,
                }));
                break;
            case 'depot_id':
                setFieldValue('depot_id', null);
                setReportTableData((prevValue) => ({
                    ...prevValue,
                    depot_id: null,
                    reset: true,
                    start_index: 0,
                    stop_index: 10,
                }));
                break;
            case 'command_id':
                setFieldValue('command_id', null);
                setReportTableData((prevValue) => ({
                    ...prevValue,
                    command_id: null,
                    depot_id: null,
                    reset: true,
                    start_index: 0,
                    stop_index: 10,
                }));
                break;
            default:
                break;
        }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSearch = useCallback(
        useDebounce((createdDate, setFieldValue, report_expiry) => {
            setFieldValue('report_expiry', report_expiry);
            if (createdDate) {
                const formateCreatedAt =
                    moment(createdDate).format('YYYY-MM-DD');
                setFieldValue('createdDate', formateCreatedAt);
                setReportTableData((prevValue) => ({
                    ...prevValue,
                    createdDate: createdDate && formateCreatedAt,
                    display_date: {
                        text: createdDate && formateCreatedAt,
                    },
                    report_expiry: report_expiry,
                }));
            }
        }, 1000),
        [reportTableData]
    );

    const handleDownloadCsv = async (val) => {
        dispatch(activateLoading());
        await dispatch(
            getReportSampleCsv({
                supervisor_employee_id: val?.supervisor_employee_id ?? null,
                cost_center: val?.cost_center ?? null,
                capability_id: val?.capability_id ?? null,
                office_id: val?.office_id ?? null,
                job_id: val?.job_id ?? null,
                job_role_id: val?.job_role_id ?? null,
                report_expiry: val?.report_expiry?.value ?? 30,
                custom_expiry_date:
                    val?.report_expiry?.value === 1 ? val?.createdDate : null,
                command_id: val?.command_id ?? null,
                depot_id: val?.depot_id ?? null,
            })
        );
        dispatch(deactivateLoading());
    };

    const handleResetFilters = () => {
        const isFilterData = clearFilter(formRef);
        if (isFilterData) {
            formRef?.current?.resetForm();
            setReportTableData({
                supervisor_employee_id: null,
                cost_center: null,
                capability_id: null,
                job_id: null,
                job_role_id: null,
                report_expiry: { value: 30, text: '30 Days' },
                custom_expiry_date: null,
                office_id: null,
                command_id: null,
                depot_id: null,
                start_index: reportTableData.start_index,
                stop_index: reportTableData.stop_index,
                reset: true,
            });
        }
    };
    //#endregion Methods

    //#region Render time calcs
    //#endregion Render time calcs

    //#region Render

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={{ ...initValues }}
                innerRef={formRef}
            >
                {({ values, setFieldValue }) => (
                    <Form>
                        <FilterAccordion
                            filteredTitles={reportTableData}
                            expandedId="generate-report"
                            onResetFilters={handleResetFilters}
                        >
                            <Grid container spacing={'1rem'}>
                                {user.role === SystemRoleEnums.AMC && (
                                    <>
                                        <Grid
                                            item={true}
                                            xs={12}
                                            md={4}
                                            lg={3}
                                            className={classes.customSelect}
                                        >
                                            <Field
                                                className={classes.input}
                                                name="command_id"
                                            >
                                                {({
                                                    field: { value },
                                                    form: { setFieldValue },
                                                }) => (
                                                    <SelectionAutoComplete
                                                        title="Select Command"
                                                        keyProperty="value"
                                                        nameProperty="text"
                                                        entityIds={value}
                                                        setEntityIds={
                                                            setFieldValue
                                                        }
                                                        onCustomChange={(
                                                            value
                                                        ) => {
                                                            setTeamTableDataID(
                                                                value,
                                                                'command_id',
                                                                setFieldValue
                                                            );
                                                            if (
                                                                user.role ===
                                                                SystemRoleEnums.AMC
                                                            ) {
                                                                setFieldValue(
                                                                    'depot_id',
                                                                    null
                                                                );
                                                                dispatch(
                                                                    resetDepotDropDownState()
                                                                );
                                                            }
                                                        }}
                                                        entitySelector={
                                                            selectCommondDropDown
                                                        }
                                                        entityStatusSelector={
                                                            getCommandDropDownStatus
                                                        }
                                                        fetchEntityPage={
                                                            fetchCommandDropDown
                                                        }
                                                        multiSelection={false}
                                                        showCloseItemEdit={true}
                                                        hideCheckIcon={true}
                                                        cancelItemTagEdit={() => {
                                                            removeTeamTableId(
                                                                setFieldValue,
                                                                'command_id'
                                                            );
                                                            if (
                                                                user.role ===
                                                                SystemRoleEnums.AMC
                                                            ) {
                                                                setFieldValue(
                                                                    'depot_id',
                                                                    null
                                                                );
                                                                dispatch(
                                                                    resetDepotDropDownState()
                                                                );
                                                            }
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                        </Grid>
                                        <Grid
                                            item={true}
                                            xs={12}
                                            md={4}
                                            lg={3}
                                            className={classes.customSelect}
                                        >
                                            <Field
                                                className={classes.input}
                                                name="depot_id"
                                            >
                                                {({
                                                    field: { value },
                                                    form: { setFieldValue },
                                                }) => (
                                                    <SelectionAutoComplete
                                                        title="Select Depot"
                                                        keyProperty="value"
                                                        nameProperty="text"
                                                        entityIds={value}
                                                        setEntityIds={
                                                            setFieldValue
                                                        }
                                                        onCustomChange={(
                                                            value
                                                        ) => {
                                                            setTeamTableDataID(
                                                                value,
                                                                'depot_id',
                                                                setFieldValue
                                                            );
                                                        }}
                                                        entitySelector={
                                                            selectDepotDropDown
                                                        }
                                                        entityStatusSelector={
                                                            getDepotDropDownStatus
                                                        }
                                                        fetchEntityPage={
                                                            fetchDepotDropDown
                                                        }
                                                        formField={'depot_id'}
                                                        multiSelection={false}
                                                        showCloseItemEdit={true}
                                                        hideCheckIcon={true}
                                                        cancelItemTagEdit={() => {
                                                            removeTeamTableId(
                                                                setFieldValue,
                                                                'depot_id'
                                                            );
                                                        }}
                                                        fetchParams={{
                                                            command_id:
                                                                values?.command_id,
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                        </Grid>
                                    </>
                                )}
                                <Grid
                                    item={true}
                                    xs={12}
                                    md={4}
                                    lg={3}
                                    className={classes.customSelect}
                                >
                                    <Field
                                        className={classes.input}
                                        name={SUPER_VISOR_ID}
                                    >
                                        {({
                                            field: { value },
                                            form: { setFieldValue },
                                        }) => (
                                            <SelectionAutoComplete
                                                title="Select Supervisor"
                                                keyProperty="value"
                                                nameProperty="text"
                                                entityIds={value}
                                                setEntityIds={setFieldValue}
                                                entitySelector={
                                                    selectSuperVisorDropDown
                                                }
                                                onCustomChange={(value) => {
                                                    setTeamTableDataID(
                                                        value,
                                                        SUPER_VISOR_ID,
                                                        setFieldValue
                                                    );
                                                }}
                                                entityStatusSelector={
                                                    getSuperVisorDropDownStatus
                                                }
                                                fetchEntityPage={
                                                    fetchSuperVisorDropDown
                                                }
                                                fetchQueryParams={{
                                                    include_self: true,
                                                }}
                                                formField={SUPER_VISOR_ID}
                                                multiSelection={false}
                                                showCloseItemEdit={true}
                                                hideCheckIcon={true}
                                                cancelItemTagEdit={() => {
                                                    removeTeamTableId(
                                                        setFieldValue,
                                                        SUPER_VISOR_ID
                                                    );
                                                }}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid
                                    item={true}
                                    xs={12}
                                    md={4}
                                    lg={3}
                                    className={classes.customSelect}
                                >
                                    <Field
                                        className={classes.input}
                                        name={COST_CENTER_ID}
                                    >
                                        {({
                                            field: { value },
                                            form: { setFieldValue },
                                        }) => (
                                            <SelectionAutoComplete
                                                title="Select Cost Center"
                                                keyProperty="value"
                                                nameProperty="text"
                                                entityIds={value}
                                                setEntityIds={setFieldValue}
                                                entitySelector={
                                                    selectCostCenterDropDown
                                                }
                                                onCustomChange={(value) => {
                                                    setTeamTableDataID(
                                                        value,
                                                        COST_CENTER_ID,
                                                        setFieldValue
                                                    );
                                                    dispatch(
                                                        resetOfficeIdDropDown()
                                                    );
                                                }}
                                                entityStatusSelector={
                                                    getCostCenterDropDownStatus
                                                }
                                                fetchEntityPage={
                                                    fetchCostCenterDropDown
                                                }
                                                formField={COST_CENTER_ID}
                                                multiSelection={false}
                                                showCloseItemEdit={true}
                                                hideCheckIcon={true}
                                                cancelItemTagEdit={() => {
                                                    removeTeamTableId(
                                                        setFieldValue,
                                                        COST_CENTER_ID
                                                    );
                                                    dispatch(
                                                        resetOfficeIdDropDown()
                                                    );
                                                }}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid
                                    item={true}
                                    xs={12}
                                    md={4}
                                    lg={3}
                                    className={classes.customSelect}
                                >
                                    <Field
                                        className={classes.input}
                                        name="office_id"
                                    >
                                        {({
                                            field: { value },
                                            form: { setFieldValue },
                                        }) => (
                                            <SelectionAutoComplete
                                                title="Select Office"
                                                keyProperty="value"
                                                nameProperty="text"
                                                entityIds={value}
                                                setEntityIds={setFieldValue}
                                                entitySelector={
                                                    selectOfficeByCostCenterIdDropDown
                                                }
                                                entityStatusSelector={
                                                    getOfficeByCostCenterIdDropDownStatus
                                                }
                                                fetchEntityPage={
                                                    fetchOfficeByCostCenterId
                                                }
                                                fetchParams={{
                                                    cost_center_id:
                                                        values?.cost_center,
                                                }}
                                                onCustomChange={(value) => {
                                                    setTeamTableDataID(
                                                        value,
                                                        'office_id',
                                                        setFieldValue
                                                    );
                                                }}
                                                formField={'office_id'}
                                                multiSelection={false}
                                                showCloseItemEdit={true}
                                                hideCheckIcon={true}
                                                cancelItemTagEdit={() => {
                                                    removeTeamTableId(
                                                        setFieldValue,
                                                        'office_id'
                                                    );
                                                }}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid
                                    item={true}
                                    xs={12}
                                    md={4}
                                    lg={3}
                                    className={classes.customSelect}
                                >
                                    <Field name="job_id">
                                        {({
                                            field: { value },
                                            form: { setFieldValue },
                                        }) => (
                                            <SelectionAutoComplete
                                                title={`Select ${user.label_job}`}
                                                keyProperty="value"
                                                nameProperty="text"
                                                entityIds={value}
                                                setEntityIds={setFieldValue}
                                                entitySelector={
                                                    selectJobDropDown
                                                }
                                                entityStatusSelector={
                                                    getJobDropDownStatus
                                                }
                                                onCustomChange={(value) => {
                                                    setTeamTableDataID(
                                                        value,
                                                        'job_id',
                                                        setFieldValue
                                                    );
                                                }}
                                                fetchEntityPage={
                                                    fetchJobsDropDown
                                                }
                                                cancelItemTagEdit={() => {
                                                    removeTeamTableId(
                                                        setFieldValue,
                                                        'job_id'
                                                    );
                                                }}
                                                showCloseItemEdit={true}
                                                formField="job_id"
                                                multiSelection={false}
                                                hideCheckIcon={true}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid
                                    item={true}
                                    xs={12}
                                    md={4}
                                    lg={3}
                                    className={classes.customSelect}
                                >
                                    <Field name="job_role_id">
                                        {({
                                            field: { value },
                                            form: { setFieldValue },
                                        }) => (
                                            <SelectionAutoComplete
                                                title={`Select ${user.label_job_role}`}
                                                keyProperty="value"
                                                nameProperty="text"
                                                entityIds={value}
                                                setEntityIds={setFieldValue}
                                                entitySelector={
                                                    selectJobRoleByJobIdDropDown
                                                }
                                                entityStatusSelector={
                                                    getJobRolesByJobIdDropDownStatus
                                                }
                                                onCustomChange={(value) => {
                                                    setTeamTableDataID(
                                                        value,
                                                        'job_role_id',
                                                        setFieldValue
                                                    );
                                                }}
                                                fetchEntityPage={
                                                    fetchJobRolesByJobId
                                                }
                                                cancelItemTagEdit={() => {
                                                    removeTeamTableId(
                                                        setFieldValue,
                                                        'job_role_id'
                                                    );
                                                }}
                                                formField="job_role_id"
                                                showCloseItemEdit={true}
                                                multiSelection={false}
                                                hideCheckIcon={true}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid
                                    item={true}
                                    xs={12}
                                    md={4}
                                    lg={3}
                                    className={classes.customSelect}
                                >
                                    <Field
                                        className={classes.input}
                                        name={CAPABILITY_ID}
                                    >
                                        {({
                                            field: { value },
                                            form: { setFieldValue },
                                        }) => (
                                            <SelectionAutoComplete
                                                title={`Select ${user.label_capability}`}
                                                keyProperty="value"
                                                nameProperty="text"
                                                entityIds={value}
                                                setEntityIds={setFieldValue}
                                                entitySelector={
                                                    selectCapabilitiesDropDown
                                                }
                                                entityStatusSelector={
                                                    getCapabilityDropDownStatus
                                                }
                                                onCustomChange={(value) => {
                                                    setTeamTableDataID(
                                                        value,
                                                        CAPABILITY_ID,
                                                        setFieldValue
                                                    );
                                                }}
                                                fetchEntityPage={
                                                    fetchCapabilityDropDown
                                                }
                                                formField={CAPABILITY_ID}
                                                multiSelection={false}
                                                showCloseItemEdit={true}
                                                hideCheckIcon={true}
                                                cancelItemTagEdit={() => {
                                                    removeTeamTableId(
                                                        setFieldValue,
                                                        CAPABILITY_ID
                                                    );
                                                }}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid
                                    item={true}
                                    xs={12}
                                    md={4}
                                    lg={3}
                                    className={classes.customSelect}
                                >
                                    <Field
                                        className={classes.input}
                                        name={'report_expiry'}
                                    >
                                        {({
                                            field: { value },
                                            form: { setFieldValue },
                                        }) => (
                                            <SelectionAutoComplete
                                                title="Select Report Expiration"
                                                keyProperty="value"
                                                nameProperty="text"
                                                entityIds={value?.value}
                                                setEntityIds={setFieldValue}
                                                entitySelector={
                                                    selectReportExpiryDropDown
                                                }
                                                entityStatusSelector={
                                                    getReportExpiryDropDownStatus
                                                }
                                                onCustomChange={(value) => {
                                                    setTeamTableDataID(
                                                        value,
                                                        'report_expiry',
                                                        setFieldValue
                                                    );
                                                }}
                                                fetchEntityPage={
                                                    fetchReportExpirationDropDown
                                                }
                                                formField={'report_expiry'}
                                                multiSelection={false}
                                                showCloseItemEdit={true}
                                                hideCheckIcon={true}
                                                cancelItemTagEdit={() => {
                                                    setCreatedDateFilter(null);
                                                    debouncedSearch(
                                                        null,
                                                        setFieldValue,
                                                        {
                                                            value: 30,
                                                            text: '30 Days',
                                                        }
                                                    );
                                                    removeTeamTableId(
                                                        setFieldValue,
                                                        'report_expiry'
                                                    );
                                                }}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                {values?.report_expiry?.value === 1 && (
                                    <Grid
                                        item={true}
                                        xs={12}
                                        md={4}
                                        lg={3}
                                        className={classes.customSelect}
                                    >
                                        <fieldset
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                padding: '0',
                                                border: '0',
                                            }}
                                        >
                                            <LocalizationProvider
                                                dateAdapter={AdapterMoment}
                                            >
                                                <DatePicker
                                                    sx={{
                                                        width: '100%',
                                                    }}
                                                    label="Select Expiry Date"
                                                    value={createdDateFilter}
                                                    format="YYYY-MM-DD"
                                                    onChange={(newValue) => {
                                                        if (
                                                            moment(newValue)
                                                                .utc()
                                                                .startOf('day')
                                                                .format(
                                                                    'YYYY-MM-DD'
                                                                ) !==
                                                            'Invalid date'
                                                        ) {
                                                            setCreatedDateFilter(
                                                                newValue
                                                            );
                                                            debouncedSearch(
                                                                newValue,
                                                                setFieldValue,
                                                                values?.report_expiry
                                                            );
                                                        }
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            variant="outlined"
                                                            {...params}
                                                            sx={{
                                                                minWidth:
                                                                    '150px',
                                                                width: '100%',
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        </fieldset>
                                    </Grid>
                                )}
                            </Grid>
                        </FilterAccordion>
                        {SystemRoleEnums.EnableAdminRoles.includes(
                            user?.role
                        ) && (
                            <Box
                                sx={{ margin: '1rem 0 0', textAlign: 'right' }}
                            >
                                <Button
                                    variant="contained"
                                    onClick={() => handleDownloadCsv(values)}
                                >
                                    Download CSV
                                </Button>
                            </Box>
                        )}
                    </Form>
                )}
            </Formik>
            <ReportsTable
                reportsData={reportsData}
                reportTableData={reportTableData}
                setReportTableData={setReportTableData}
            />
        </>
    );
    //#endregion Render
};
export { GenerateFilter };
