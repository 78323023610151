import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  activateLoading,
  deactivateLoading,
} from 'features/global/loadingProgressSlice';
import { LEVEL_CACHE, LoadingStatus } from 'helpers';

import { closePanel } from 'features/common/formSlice';
import { ItemLevelsTable } from './ItemLevelsTable';
import {
  fetchLevels,
  getLevelPageInfo,
  getLevelStatus,
  selectLevel,
} from 'features/Levels/levelSlice';
import {
  selectAdminSettingCache,
  updateAdminSettingCache,
} from 'features/global/visibilitySlice';

const LevelManagementTab = () => {
  //#region Constants
  const dispatch = useDispatch();
  const getCacheData = useSelector(selectAdminSettingCache);
  const getLevelsData = async (payload) => {
    dispatch(activateLoading());
    await dispatch(fetchLevels({ ...payload }));
    dispatch(updateAdminSettingCache({ key: LEVEL_CACHE, payload }));
    dispatch(closePanel({ formKey: `itemLevelForm` }));
    dispatch(deactivateLoading());
  };
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  const [itemLevelPayload, setItemLevelPayload] = useState({
    ...getCacheData?.[LEVEL_CACHE],
    reset: true,
    startIndex: 0,
    stopIndex: 19,
  });
  const [sortBy, setSortBy] = useState(
    getCacheData?.[LEVEL_CACHE]?.sort?.slice(0, 1) === '-'
      ? getCacheData?.[LEVEL_CACHE]?.sort.slice(1)
      : getCacheData?.[LEVEL_CACHE]?.sort || 'display_name'
  );

  const [sortDirection, setSortDirection] = useState(
    getCacheData?.[LEVEL_CACHE]?.sort?.[0] !== '-' ? 'ASC' : 'DESC'
  );
  //#endregion State

  //#region Selectors
  const levels = useSelector(selectLevel);
  const itemSkillStatus = useSelector(getLevelStatus);
  const itemSkillPageInfo = useSelector(getLevelPageInfo);
  //#endregion Selectors

  //#region Refs
  const ref = useRef(false);
  //#endregion Refs

  //#region Effects
  useEffect(() => {
    if (ref.current === true) {
      getLevelsData({ ...itemLevelPayload });
    }
    ref.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemLevelPayload]);

  useEffect(() => {
    if (itemSkillStatus === LoadingStatus.Idle) {
      getLevelsData({
        ...itemLevelPayload,
        reset: true,
        startIndex: 0,
        stopIndex: 19,
      });
    }
    ref.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemSkillStatus, dispatch]);
  //#endregion Effects

  //#region Methods
  const setSorting = (_sortBy, _sortDirection) => {
    setSortBy(_sortBy);
    setSortDirection(_sortDirection);
    setItemLevelPayload({
      ...itemLevelPayload,
      sort: `${_sortDirection === 'ASC' ? '' : '-'}${_sortBy}`,
    });
  };
  //#endregion Methods

  //#region Render time calcs
  //#endregion Render time calcs

  //#region Render

  return (
    <>
      <ItemLevelsTable
        levels={levels}
        pageInfo={itemSkillPageInfo}
        itemLevelPayload={itemLevelPayload}
        setItemLevelPayload={setItemLevelPayload}
        sortDirection={sortDirection}
        sortBy={sortBy}
        handleSort={(_sortBy, _sortDirection) =>
          setSorting(_sortBy, _sortDirection)
        }
      />
    </>
  );
  //#endregion Render
};
export { LevelManagementTab };
