import { createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const modalStyles = makeStyles(() =>
  createStyles({
    modalContainer: {
      position: 'absolute',
      backgroundColor: 'white',
      top: `50%`,
      left: `50%`,
      transform: `translate(-50%, -50%)`,
      '&:focus': {
        outline: 'none',
      },
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      borderRadius: '5px',
      boxShadow: '0 0 5px #444444',
      padding: '2rem',
    },
    roundedCornerContainer: {
      borderRadius: '20px',
      width: '50%',
      '& div': {
        padding: '50px',
      },
      '& strong': {
        fontWeight: 'bold',
      },
      lineHeight: '1.5em',
    },
    buttonSpacing: {
      '& + button': {
        marginLeft: '1rem',
      },
    },
    actionWrapper: {
      marginTop: '1rem',
    },
  })
);

export { modalStyles };
