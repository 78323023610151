import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  activateLoading,
  deactivateLoading,
} from 'features/global/loadingProgressSlice';
import { LoadingStatus, OFFICE_CACHE } from 'helpers';
import { closePanel } from 'features/common/formSlice';

import { ItemOfficeTable } from './ItemOfficeTable';
import {
  fetchOffice,
  getOfficePageInfo,
  getOfficeStatus,
  resetOfficeState,
  selectOffice,
} from 'features/Office/officeSlice';
import {
  selectAdminSettingCache,
  updateAdminSettingCache,
} from 'features/global/visibilitySlice';
import { mapValues } from 'helpers/mapValues';
import { resetDropDownState } from 'features/AdminSettingsDropDown/dropDownSlice';

const OfficeManagementTab = () => {
  //#region Constants
  const dispatch = useDispatch();
  const getCacheData = useSelector(selectAdminSettingCache);
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  const [itemOfficePayload, setItemOfficePayload] = useState({
    ...getCacheData?.[OFFICE_CACHE],
    reset: true,
    startIndex: 0,
    stopIndex: 19,
  });
  const [sortBy, setSortBy] = useState(
    getCacheData?.[OFFICE_CACHE]?.sort?.slice(0, 1) === '-'
      ? getCacheData?.[OFFICE_CACHE]?.sort.slice(1)
      : getCacheData?.[OFFICE_CACHE]?.sort || 'directorate'
  );
  const [sortDirection, setSortDirection] = useState(
    getCacheData?.[OFFICE_CACHE]?.sort?.[0] !== '-' ? 'ASC' : 'DESC'
  );
  //#endregion State

  //#region Selectors
  const offices = useSelector(selectOffice);
  const itemOfficeStatus = useSelector(getOfficeStatus);
  const itemOfficePageInfo = useSelector(getOfficePageInfo);
  //#endregion Selectors

  //#region Refs
  const ref = useRef(false);
  //#endregion Refs

  //#region Effects
  useEffect(() => {
    if (ref.current === true) {
      getOfficesData({ ...itemOfficePayload });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemOfficePayload]);

  useEffect(() => {
    if (itemOfficeStatus === LoadingStatus.Idle) {
      getOfficesData({
        ...itemOfficePayload,
        startIndex: 0,
        stopIndex: 19,
      });
    }
    ref.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemOfficeStatus, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetOfficeState());
      dispatch(resetDropDownState());
    };
  }, []);

  //#endregion Effects

  //#region Methods
  const getOfficesData = async (payload) => {
    dispatch(activateLoading());
    let updatedPayload = mapValues(payload);
    await dispatch(fetchOffice({ ...updatedPayload }));
    dispatch(updateAdminSettingCache({ key: OFFICE_CACHE, payload }));
    dispatch(closePanel({ formKey: `itemOfficeForm` }));
    dispatch(deactivateLoading());
  };

  const setSorting = (_sortBy, _sortDirection) => {
    setSortBy(_sortBy);
    setSortDirection(_sortDirection);
    setItemOfficePayload({
      ...itemOfficePayload,
      sort: `${_sortDirection === 'ASC' ? '' : '-'}${_sortBy}`,
    });
  };
  //#endregion Methods

  //#region Render time calcs
  //#endregion Render time calcs

  //#region Render

  return (
    <>
      <ItemOfficeTable
        offices={offices}
        pageInfo={itemOfficePageInfo}
        itemOfficePayload={itemOfficePayload}
        setItemOfficePayload={setItemOfficePayload}
        sortDirection={sortDirection}
        sortBy={sortBy}
        handleSort={(_sortBy, _sortDirection) =>
          setSorting(_sortBy, _sortDirection)
        }
      />
    </>
  );
  //#endregion Render
};
export { OfficeManagementTab };
