import { combineReducers, configureStore } from '@reduxjs/toolkit';

// Globals
import visibilityReducer from 'features/global/visibilitySlice';

// Employee
import employeeReducer from 'features/employee/employeeSlice';

//Jobs
import jobDepotReducer from 'features/JobDepot/jobDepotSlice';

// Account
import accountReducer from 'features/account/accountSlice';

//Pay Plans
import payPlansReducer from 'features/payPlan/payPlansSlice';

//Pay Grades
import payGradesReducer from 'features/payGrade/payGradeSlice';

//Skill
import skillReducer from 'features/skill/skillSlice';

//Capability
import capabilityReducer from 'features/capability/capabilitySlice';

//Job
import jobReducer from 'features/job/jobSlice';

//Job Role
import jobRoleReducer from 'features/jobRole/jobRoleSlice';

//Form Panel
import formPanelReducer from 'features/common/formSlice';

// Action Modal
import actionModalReducer from 'features/common/actionModalSlice';

//Admin Depot
import adminDepotReducer from 'features/adminDepot/adminDepotSlice';

//Edit panel
import editPanelReducer from 'features/common/editPanelSlice';

//Loading Progress slice
import loadingProgressReducer from 'features/global/loadingProgressSlice';

//DropDowns
import dropDownReducer from 'features/AdminSettingsDropDown/dropDownSlice';

//Team
import teamReducer from 'features/Teams/teamSlice';

//Level
import levelReducer from 'features/Levels/levelSlice';

//Group Training
import groupTrainingReducer from 'features/groupTraining/groupTrainingSlice';

//Generate Reports
import reportReducer from 'features/Reports/reportSlice';

//Cost Center
import costCenterReducer from 'features/CostCenter/costCenterSlice';

//Cost Center Reports
import costCenterReportReducer from 'features/CostCenterReports/costCenterReportSlice';

//Offices
import officeReducer from 'features/Office/officeSlice';

//External Api
import apiClientsReducer from 'features/ApiClientSlice/ApiClientSlice';

//Best Fit
import bestFitReducer from 'features/BestFit/bestFitSlice';

//Depot Reports
import depotReportsReducer from 'features/depotReports/depotReportSlice';

//Export file
import exportFileReducer from 'features/exportSlice/exportSlice';

export const allReducers = combineReducers({
  visibility: visibilityReducer,
  employee: employeeReducer,
  account: accountReducer,
  jobDepot: jobDepotReducer,
  payPlans: payPlansReducer,
  payGrades: payGradesReducer,
  skills: skillReducer,
  capabilities: capabilityReducer,
  jobs: jobReducer,
  jobRoles: jobRoleReducer,
  forms: formPanelReducer,
  actionModal: actionModalReducer,
  editPanel: editPanelReducer,
  loadingProgress: loadingProgressReducer,
  adminDepot: adminDepotReducer,
  dropDowns: dropDownReducer,
  teams: teamReducer,
  levels: levelReducer,
  groupTraining: groupTrainingReducer,
  reports: reportReducer,
  costCenters: costCenterReducer,
  costCenterReports: costCenterReportReducer,
  apiClients: apiClientsReducer,
  offices: officeReducer,
  bestFits: bestFitReducer,
  depotReports: depotReportsReducer,
  exportFile: exportFileReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    state = undefined;
  }
  return allReducers(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
});
