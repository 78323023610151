import { version } from 'version.js';

const config = {
  apiUrl: process.env.REACT_APP_API_URL,
  routerBaseName: process.env.REACT_APP_ROUTER_BASENAME,
  allowEmailLogin: process.env.REACT_APP_ALLOW_EMAIL_LOGIN == 'true',
  appVersion: process.env.REACT_APP_BUILD_VERSION ?? version,
};

export { config };
