export const FormActions = {
  Create: {
    id: 1,
    label: 'create',
    buttonLabel: 'Create',
  },
  Edit: {
    id: 2,
    label: 'edit',
    buttonLabel: 'Update',
  },
};
