import React, { useState, useRef, useEffect, useCallback } from 'react';
import { AutoSizer } from 'react-virtualized';
import IconButton from '@mui/material/IconButton';
import { Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { MdAdd, MdRefresh } from 'react-icons/md';

import SearchWithFilters from 'components/SearchWithFilters';
import {
  actionCell,
  checkCell,
  checkHeaderCell,
  clipLabelTooltipCell,
  labelCell,
  labelColorBadge,
  labelHeader,
} from 'shared/components/_Tables/TableCells';
import { makeButtonStyles } from 'components/styles';
import { VirtualTable } from 'shared/components/_Tables/VirtualTable';
import { EnhancedTableToolbar } from 'components/EnhancedTableToolbar';
import { tableStyles } from 'shared/components/_Tables/VirtualTable/styles';
import { Action } from 'helpers/action';
import { mdTheme } from 'theme';
import { PopupAction } from 'components/_Misc/PopupAction/components/PopupAction';
import {
  closePanel,
  openPanel,
  setEditObjectId,
} from 'features/common/formSlice';

import { NoDataMessage } from 'shared/components/_Tables/NoDataMessage';
import { LEVEL_TABLE_KEYS, LoadingStatus, NO_MATCHING_LEVELS } from 'helpers';
import {
  exportLevelCsv,
  fetchLevels,
  getImportLevelHelp,
  getLevelImportSampleCsv,
  getLevelInfoStatus,
  getLevelStatus,
  resetLevelInfoState,
  resetLevelState,
  uploadLevelImportCsv,
} from 'features/Levels/levelSlice';
import { DropZoneImport } from 'shared/components/_DropZoneImport';
import { selectActiveUser } from 'features/account/accountSlice';
import { useDebounce } from 'hooks/useDebounce';
import { CustomDialog } from 'shared/components/_Modal';
import {
  fetchLevelsDropDown,
  getLevelDropDownStatus,
  selectLevelDropDown,
} from 'features/AdminSettingsDropDown/dropDownSlice';

function ItemLevelsTable({
  levels,
  pageInfo,
  itemLevelPayload,
  setItemLevelPayload,
  sortDirection,
  sortBy,
  handleSort,
}) {
  const dispatch = useDispatch();
  const buttonStyles = makeButtonStyles(mdTheme);
  const tableClasses = tableStyles();
  const [selected, setSelected] = useState([]);
  const [selectedAll, setSelectedAll] = React.useState(false);
  const [openHelpDialog, setOpenHelpDialog] = useState(false);

  const getInfoStatus = useSelector(getLevelInfoStatus);
  const levelStatus = useSelector(getLevelStatus);
  const loadedRowsMap = useSelector((state) => state.levels.loadedLevelsMap);
  const user = useSelector(selectActiveUser);
  const hideLevelIdColumn = user?.hide_system_ids;
  const levelColumnIds = user?.admin_field_ids;

  const headerHeight = 40;
  const rowHeight = 60;

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const prevSelectedAll = usePrevious(selectedAll);
  if (prevSelectedAll && selectedAll) {
    if (selected.length !== levels?.length) {
      setSelected(levels.map((row) => row.level_id));
      setSelectedAll(true);
    }
  }

  const onRefreshIconClick = async () => {
    await dispatch(fetchLevels({ ...itemLevelPayload }));
  };

  const handleClick = (event, level_id) => {
    event.stopPropagation();
    const selectedIndex = selected.indexOf(level_id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, level_id);
    } else {
      newSelected = selected.filter((id) => id !== level_id);
    }
    setSelected(newSelected);
    setSelectedAll(newSelected.length === levels.length);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelectedIds = levels.map((tag) => tag.level_id);
      setSelected(newSelectedIds);
      setSelectedAll(true);
    } else {
      setSelected([]);
      setSelectedAll(false);
    }
  };

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const handleRowClick = async ({ rowData }) => {
    await dispatch(closePanel({ formKey: 'itemLevelForm' }));
    if (rowData?.level_id) {
      await dispatch(
        setEditObjectId({
          formKey: `itemLevelForm`,
          object_id: rowData?.level_id,
        })
      );
      dispatch(openPanel({ formKey: `itemLevelForm`, formAction: 'Edit' }));
    }
  };

  const init_columns = [
    {
      classes: tableClasses,
      minWidth: 50,
      maxWidth: 50,
      width: 50,
      label: '',
      dataKey: 'level_id',
      padding: 'checkbox',
      cellLevel: 'level',
      numSelected: selected?.length,
      rowCount: levels?.length,
      handleClick: handleClick,
      handleSelectAllClick: handleSelectAllClick,
      isSelected: isSelected,
      component: checkCell,
      headerComponent: checkHeaderCell,
    },
    {
      classes: tableClasses,
      minWidth: 50,
      maxWidth: 50,
      width: 50,
      label: '',
      dataKey: 'level_id',
      padding: 'none',
      actionCellLevel: 'adminLevel',
      targetPopup: 'adminLevelManagement',
      formKey: 'itemLevelForm',
      dispatch: dispatch,
      component: actionCell,
      headerComponent: labelHeader,
    },
  ];

  const handleColumnVisibility = (key, column) => {
    if (hideLevelIdColumn && key === 'level_id') {
      return undefined;
    }

    const isVisible = levelColumnIds?.includes(LEVEL_TABLE_KEYS?.[key]?.id);
    if (isVisible) {
      return column;
    }
  };

  const columns = [
    ...init_columns,
    handleColumnVisibility('level_id', {
      classes: tableClasses,
      flexGrow: 1,
      width: 220,
      minWidth: 220,
      label: 'Level Id',
      dataKey: 'level_id',
      padding: 'normal',
      component: labelCell,
      headerComponent: labelHeader,
      sortDirection: sortDirection,
      sortBy: sortBy,
      handleSort: handleSort,
    }),
    handleColumnVisibility('display_name', {
      classes: tableClasses,
      flexGrow: 1,
      width: 220,
      minWidth: 220,
      label: 'Name',
      dataKey: 'display_name',
      padding: 'normal',
      component: labelCell,
      headerComponent: labelHeader,
      sortDirection: sortDirection,
      sortBy: sortBy,
      handleSort: handleSort,
    }),
    handleColumnVisibility('description', {
      classes: tableClasses,
      width: 250,
      minWidth: 250,
      maxWidth: 250,
      label: 'Description',
      dataKey: 'description',
      padding: 'normal',
      component: clipLabelTooltipCell,
      headerComponent: labelHeader,
    }),
    handleColumnVisibility('color', {
      classes: tableClasses,
      flexGrow: 1,
      width: 120,
      minWidth: 120,
      label: 'Color',
      dataKey: 'color',
      padding: 'normal',
      component: labelColorBadge,
      headerComponent: labelHeader,
    }),
    handleColumnVisibility('previous_level_display_name', {
      classes: tableClasses,
      flexGrow: 1,
      width: 310,
      minWidth: 310,
      label: 'Previous Level',
      dataKey: 'previous_level_display_name',
      padding: 'normal',
      component: labelCell,
      headerComponent: labelHeader,
      sortDirection: sortDirection,
      sortBy: sortBy,
      handleSort: handleSort,
    }),
  ].filter(Boolean);

  // eslint-disable-next-line no-unused-vars
  async function loadMoreRows({ startIndex, stopIndex }) {
    if (levelStatus !== LoadingStatus.Loading) {
      await dispatch(
        fetchLevels({
          ...itemLevelPayload,
          reset: false,
          startIndex: startIndex,
          stopIndex: stopIndex,
        })
      );
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    useDebounce((searchString) => {
      setItemLevelPayload({
        ...itemLevelPayload,
        searchString: searchString,
      });
    }, 1000),
    [itemLevelPayload]
  );

  const handleCreateNew = () => {
    dispatch(openPanel({ formKey: 'itemLevelForm', formAction: 'Create' }));
  };

  return (
    <div className={tableClasses.root}>
      {selected.length > 0 && (
        <EnhancedTableToolbar
          numSelected={selected.length}
          toolTipTitle={'Delete'}
          totalCount={pageInfo?.TotalCount}
        >
          <PopupAction
            action={Action.DeleteArray}
            object={{
              levelId: selected,
              setLevelIds: setSelected,
            }}
            level={'adminLevel'}
            showLabel={false}
          />
        </EnhancedTableToolbar>
      )}
      <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
        <Tooltip title="Add New Level">
          <IconButton
            onClick={handleCreateNew}
            size="large"
            className={buttonStyles.contentAddButton}
          >
            <MdAdd />
          </IconButton>
        </Tooltip>
        <div style={{ flexGrow: 1 }}></div>
        <DropZoneImport
          key="level-dzi"
          getEntityImportSample={getLevelImportSampleCsv}
          uploadEntityState={uploadLevelImportCsv}
          resetEntitySelector={resetLevelState}
          mainTitle="Level"
          exportEntityState={exportLevelCsv}
          exportFilterData={itemLevelPayload}
          isExport
          setOpenHelpDialog={setOpenHelpDialog}
          isInfoIcon
        />
        <CustomDialog
          getEntityHelp={getImportLevelHelp}
          fieldName="level_id"
          selectDropdownEntity={selectLevelDropDown}
          getDropdownStatusEntity={getLevelDropDownStatus}
          fetchDropDownEntityPage={fetchLevelsDropDown}
          openHelpDialog={openHelpDialog}
          setOpenHelpDialog={setOpenHelpDialog}
          getEntityHelpStatus={getInfoStatus}
          selectLabel="Select Level"
          resetHelpEntity={resetLevelInfoState}
        />
        <IconButton
          onClick={onRefreshIconClick}
          aria-label="close"
          sx={{
            color: '#707070',
          }}
          size="large"
        >
          <MdRefresh style={{ fontSize: '18px', color: 'black' }} />
        </IconButton>
        <SearchWithFilters
          filter={false}
          onChange={(evt) => debouncedSearch(evt.target.value)}
          value={itemLevelPayload?.searchString}
        />
      </div>
      {levels.length > 0 ? (
        <div
          style={{
            height: 'calc(100vh - 295px)',
            minWidth: '500px',
            paddingTop: '2rem',
          }}
        >
          <AutoSizer>
            {({ width, height }) => (
              <VirtualTable
                initialRows={levels}
                allRows={levels}
                totalRowCount={pageInfo?.TotalCount}
                loadedRowsMap={loadedRowsMap}
                loadMoreRows={loadMoreRows}
                columnDefinitions={columns}
                cellClassName={tableClasses.flexContainer}
                className={tableClasses.table}
                rowClassName={tableClasses.flexContainer}
                headerHeight={headerHeight}
                width={width}
                height={height}
                rowHeight={rowHeight}
                onRowClick={handleRowClick}
              />
            )}
          </AutoSizer>
        </div>
      ) : (
        <NoDataMessage message={NO_MATCHING_LEVELS} />
      )}
    </div>
  );
}

export { ItemLevelsTable };
