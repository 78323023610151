import {
  Box,
  Button,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import {
  MdEdit,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { selectActiveUser } from 'features/account/accountSlice';
import { SystemRoleEnums } from 'helpers';
import { Each } from 'helpers/Each';
import { padNumberWithZeros } from 'hooks/PadNumberWithZeros';
import { mdTheme } from 'theme';

const EmployeeData = ({
  columnData,
  isHideMenu,
  handlePagination,
  prevDisable,
  nextDisable,
}) => {
  //#region Constants
  const theme = mdTheme;
  const navigate = useNavigate();
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  //#endregion State

  //#region Selectors
  const user = useSelector(selectActiveUser);
  //#endregion Selectors

  //#region Refs
  //#endregion Refs

  //#region Effects
  //#endregion Effects

  //#region Methods
  //#endregion Methods

  //#region Render time calcs
  //#endregion Render time calcs

  //#region Render

  return (
    <>
      <TableRow
        sx={{
          background: theme.palette.common.darkGray,
        }}
      >
        <TableCell
          sx={{
            background: theme.palette.common.darkGray,
          }}
        ></TableCell>
        {columnData?.employees?.length > 0 ? (
          <Each
            of={columnData.employees}
            render={(row, index) => (
              <TableCell
                component="td"
                scope="columns"
                sx={{
                  background:
                    index % 2 === 0 ? '' : theme.palette.background.lightBlue,
                  textAlign: 'center',
                }}
              >
                {padNumberWithZeros(row?.series_id)}
              </TableCell>
            )}
          />
        ) : (
          <TableCell>N/A</TableCell>
        )}
        <TableCell
          sx={{
            background: theme.colors.primarySectionForeground,
          }}
        ></TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          sx={{
            background: theme.colors.primarySectionForeground,
          }}
          component="td"
          scope="columns"
        >
          <Typography variant="h6" paddingTop={'1rem'}>
            {user?.label_capability
              ? user.label_capability
              : 'Critical Capabilities'}
          </Typography>
        </TableCell>
        {columnData?.employees?.length > 0 ? (
          <Each
            of={columnData?.employees}
            render={(row, index) => (
              <TableCell
                component="td"
                scope="columns"
                valign="baseline"
                sx={{
                  background:
                    index % 2 === 0 ? '' : theme.palette.background.lightBlue,
                  maxWidth: '150px',
                  textAlign: 'center',
                }}
              >
                <Typography
                  variant="caption"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingBottom: '0.5rem',
                  }}
                >
                  {row?.full_name}
                </Typography>
                {SystemRoleEnums.EnableAdminRoles.includes(user?.role) && (
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'space-evenly'}
                  >
                    <IconButton
                      onClick={() =>
                        isHideMenu === 'true'
                          ? navigate(
                              `/employee/${row?.employee_id}?hide-menu=${isHideMenu}`
                            )
                          : navigate(`/employee/${row?.employee_id}`)
                      }
                      sx={{
                        width: '2.3rem',
                        height: '2.3rem',
                      }}
                    >
                      <MdEdit />
                    </IconButton>
                  </Box>
                )}
              </TableCell>
            )}
          />
        ) : (
          <TableCell>N/A</TableCell>
        )}
        <TableCell
          sx={{
            background: theme.colors.primarySectionForeground,
          }}
          width="120px"
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
            }}
          >
            <Button
              onClick={() => handlePagination('Prev')}
              variant="contained"
              disabled={prevDisable}
            >
              <MdKeyboardArrowLeft
                style={{
                  fontSize: '1.4rem',
                }}
              />
            </Button>
            <Button
              onClick={() => handlePagination('Next')}
              variant="contained"
              disabled={nextDisable}
            >
              <MdKeyboardArrowRight
                style={{
                  fontSize: '1.4rem',
                }}
              />
            </Button>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
  //#endregion Render
};
export { EmployeeData };
