import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import { mdTheme } from 'theme';
import { Each } from 'helpers/Each';

const DynamicNestedTable = ({
  data,
  nestedKey,
  nestedColumns,
  accordionOpen,
  colSpanLength,
}) => {
  const theme = mdTheme;

  return (
    <TableCell style={{ padding: 0 }} colSpan={colSpanLength}>
      <Collapse in={accordionOpen} timeout="auto" unmountOnExit>
        <Table size="medium" aria-label="purchases">
          <TableHead>
            <Each
              of={nestedColumns}
              render={(column, index) => (
                <TableCell
                  style={{
                    width: column?.width ? column.width : '200px',
                    minWidth: column?.minWidth ? column.minWidth : '200px',
                  }}
                  key={index}
                >
                  {column.label}
                </TableCell>
              )}
            />
          </TableHead>
          <TableBody>
            <Each
              of={data?.[nestedKey]}
              render={(item) => (
                <TableRow
                  sx={{
                    background: theme.palette.common.darkGray,
                  }}
                  key={item.skill_id}
                >
                  <Each
                    of={nestedColumns}
                    render={(column, index) => (
                      <TableCell key={index}>
                        {column.customRender
                          ? column.customRender(item)
                          : item[column.id]}
                      </TableCell>
                    )}
                  />
                </TableRow>
              )}
            />
          </TableBody>
        </Table>
      </Collapse>
    </TableCell>
  );
};

export default DynamicNestedTable;
