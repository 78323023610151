import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import { mdTheme } from 'theme';
import { tabStyles } from '../AdminTabPanel/style';
import {
  getTeamsTabIndex,
  setTeamsTabIndex,
} from 'features/global/visibilitySlice';
import { ItemTeamsTable } from 'components/ItemTeamsTable';

function TeamsTab(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-teams-tab-${index}`}
      style={{ height: '100%' }}
      {...other}
    >
      {value === index && (
        <Box
          p={3}
          style={{
            overflow: 'auto hidden',
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

TeamsTab.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-teams-tab-${index}`,
    'aria-controls': `scrollable-auto-teams-tabpanel-${index}`,
  };
}

function TeamsTabPanel({
  columnData,
  getTeamExpiryData,
  teamsTableData,
  setCurrentPage,
  currentPage,
  user,
  mediaWidth,
  expiryTotal,
  benchTotal,
}) {
  const dispatch = useDispatch();
  const classes = tabStyles(mdTheme);

  const displayTabIndex = useSelector((state) => getTeamsTabIndex(state));

  const handleChange = (event, newValue) => {
    dispatch(setTeamsTabIndex(newValue));
  };

  let tabBarIndex = 0;
  let tabPageIndex = 0;
  return (
    <div className={classes.tabRoot}>
      <AppBar position="static" color="default">
        <Tabs
          value={displayTabIndex}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs"
          className={classes.tabs}
        >
          <Tab label={'Bench'} {...a11yProps(tabBarIndex)} />
          <Tab label={'Expiry'} {...a11yProps(++tabBarIndex)} />
        </Tabs>
      </AppBar>
      <TeamsTab value={displayTabIndex} index={tabPageIndex}>
        <ItemTeamsTable
          columnData={columnData}
          teamsTableData={teamsTableData}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          mediaWidth={mediaWidth}
          benchTotal={benchTotal}
        />
      </TeamsTab>
      <TeamsTab value={displayTabIndex} index={++tabPageIndex}>
        <ItemTeamsTable
          columnData={getTeamExpiryData}
          teamsTableData={teamsTableData}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          mediaWidth={mediaWidth}
          expiryTotal={expiryTotal}
        />
      </TeamsTab>
    </div>
  );
}

export { TeamsTabPanel };
