import React from 'react';
import { Field } from 'formik';
import { TextField } from 'formik-mui';

import { makeFormStyles } from 'forms/style';
import { mdTheme } from 'theme';

export const FormTextField = ({ validationError, ...props }) => {
  const classes = makeFormStyles(mdTheme);
  return (
    <Field
      component={TextField}
      margin="dense"
      variant="outlined"
      className={classes.materialInput}
      InputProps={{
        error: validationError ? validationError : false,
      }}
      type="text"
      {...props}
    />
  );
};
