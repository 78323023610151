import { setIsDirty } from 'features/common/formSlice';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
const IsFormDirty = () => {
  //#region Constants
  const { dirty } = useFormikContext();
  const dispatch = useDispatch();
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  //#endregion State

  //#region Selectors
  //#endregion Selectors

  //#region Refs
  //#endregion Refs

  //#region Effects
  useEffect(() => {
    dispatch(setIsDirty(dirty));
  }, [dirty]);

  //#endregion Effects

  //#region Methods
  //#endregion Methods

  //#region Render time calcs
  //#endregion Render time calcs

  //#region Render

  return <></>;
  //#endregion Render
};
export { IsFormDirty };
