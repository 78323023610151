import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { exportFileService } from 'features/services/export.service';
import { LoadingStatus } from 'helpers';

const initialState = {
  status: LoadingStatus.Idle,
  error: null,
};

export const exportHierarchy = createAsyncThunk(
  'export/exportHierarchy',
  async (params) => await exportFileService.exportCsv()
);

const exportSlice = createSlice({
  name: 'export',
  initialState,
  reducers: {
    resetExportState: (state) => initialState,
  },
  extraReducers: {
    [exportHierarchy.fulfilled]: (state, action) => {
      if (action?.payload) {
        const url = window.URL.createObjectURL(new Blob([action.payload]));
        const link = document.createElement('a');
        link.href = url;
        let downloadName =
          action.payload.headers?.filename ?? 'Job-Role-Capability-Skill.csv';
        link.setAttribute('download', downloadName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    },
    [exportHierarchy.rejected]: (state, action) => {
      state.error = action.error.message;
    },
  },
});

export const { resetExportState } = exportSlice.actions;

export default exportSlice.reducer;
