import { useRef } from 'react';
import { useSelector } from 'react-redux';

// REDUX
import { currentFormInfo, selectFormInfo } from 'features/common/formSlice';

// PANELS
import { SidePanelHeader } from 'shared/components/SidePanel/SidePanelHeader';

// FORMS
import { ItemPayPlanForm } from 'forms/ItemPayPlanForm';

// ICONS
import { Dialog, DialogContent } from '@mui/material';
import { ItemPayGradeForm } from 'forms/ItemPayGradeForm';
import { ItemSkillForm } from 'forms/ItemSkillForm';
import { ItemCapabilityForm } from 'forms/ItemCapabilityForm';
import { ItemJobForm } from 'forms/ItemJobForm';
import { ItemJobRoleForm } from 'forms/ItemJobRoleForm';
import { ItemLevelForm } from 'forms/ItemLevelForm';
import { selectActiveUser } from 'features/account/accountSlice';
import { ItemEmployeeForm } from 'forms/ItemEmployeeForm';
import { ItemCostCenterForm } from 'forms/ItemCostCenterForm';
import { ItemSkillCertificationForm } from 'components/Employees/ItemSkillCertificationForm';
import { ItemExternalApiForm } from 'forms/ItemExternalApiForm';
import { ItemOfficeForm } from 'forms/ItemOfficeForm';
import { adminTabsTitles } from 'helpers';
import AssociatSkillCapability from 'components/SkillManagementTab/AssociationSkillCapability';
import AssociatCapabilitySkill from 'components/CapabilityManagementTab/AssociationCapabilitySkill';
import AssociateJobRole from 'components/CapabilityManagementTab/AssociateJobRole';
import AssociateCapability from 'components/JobRolesManagementTab/AssociateCapability';

const FormDisplayer = () => {
  const formInfo = useSelector(selectFormInfo);
  const currentForm = useSelector(currentFormInfo);
  const user = useSelector(selectActiveUser);
  const hideSystemIds = user?.hide_system_ids;
  // const asset = useSelector((state) => getDisplayAsset(state));
  // Need latest value of formInfo when scene changes
  const formInfoRef = useRef();
  formInfoRef.current = formInfo;

  return (
    <>
      {currentForm === 'itemPayPlanForm' && formInfo.itemPayPlanForm.isOpen && (
        <>
          <Dialog
            fullWidth
            fullScreen={false}
            open={formInfo?.itemPayPlanForm?.isOpen}
            maxWidth="sm"
            disableEnforceFocus={true}
          >
            <DialogContent>
              <SidePanelHeader
                header={`${adminTabsTitles.payPlan} ${
                  formInfo?.itemPayPlanForm?.editObjectId && !hideSystemIds
                    ? `(${formInfo.itemPayPlanForm.editObjectId})`
                    : ''
                }`}
                formKey={formInfo.itemPayPlanForm.formKey}
              />
              <ItemPayPlanForm
                auditHeader={`${adminTabsTitles.payPlan} History`}
                formAction={formInfo.itemPayPlanForm.formAction}
                itemTagId={formInfo.itemPayPlanForm.editObjectId}
              />
            </DialogContent>
          </Dialog>
        </>
      )}
      {currentForm === 'itemPayGradeForm' && formInfo.itemPayGradeForm.isOpen && (
        <>
          <Dialog
            fullWidth
            fullScreen={false}
            open={formInfo?.itemPayGradeForm?.isOpen}
            maxWidth="sm"
            disableEnforceFocus={true}
          >
            <DialogContent>
              <SidePanelHeader
                header={`${adminTabsTitles.payGrade} ${
                  formInfo?.itemPayGradeForm?.editObjectId && !hideSystemIds
                    ? `(${formInfo.itemPayGradeForm.editObjectId})`
                    : ''
                }`}
                formKey={formInfo.itemPayGradeForm.formKey}
              />
              <ItemPayGradeForm
                auditHeader={`${adminTabsTitles.payGrade} History`}
                formAction={formInfo.itemPayGradeForm.formAction}
                itemTagId={formInfo.itemPayGradeForm.editObjectId}
              />
            </DialogContent>
          </Dialog>
        </>
      )}
      {currentForm === 'itemSkillForm' && formInfo.itemSkillForm.isOpen && (
        <>
          <Dialog
            fullWidth
            fullScreen={false}
            open={formInfo?.itemSkillForm?.isOpen}
            maxWidth="sm"
            disableEnforceFocus={true}
          >
            <DialogContent>
              <SidePanelHeader
                header={`${user?.label_skill} ${
                  formInfo?.itemSkillForm?.editObjectId && !hideSystemIds
                    ? `(${formInfo.itemSkillForm.editObjectId})`
                    : ''
                }`}
                formKey={formInfo.itemSkillForm.formKey}
              />
              <ItemSkillForm
                auditHeader={`${user?.label_skill} History`}
                formAction={formInfo.itemSkillForm.formAction}
                itemTagId={formInfo.itemSkillForm.editObjectId}
              />
            </DialogContent>
          </Dialog>
        </>
      )}
      {currentForm === 'itemCapabilityForm' &&
        formInfo.itemCapabilityForm.isOpen && (
          <>
            <Dialog
              fullWidth
              fullScreen={false}
              open={formInfo?.itemCapabilityForm?.isOpen}
              maxWidth="xl"
              disableEnforceFocus={true}
            >
              <DialogContent>
                <SidePanelHeader
                  header={`${user?.label_capability} ${
                    formInfo?.itemCapabilityForm?.editObjectId && !hideSystemIds
                      ? `(${formInfo.itemCapabilityForm.editObjectId})`
                      : ''
                  }`}
                  formKey={formInfo.itemCapabilityForm.formKey}
                />
                <ItemCapabilityForm
                  user={user}
                  formAction={formInfo.itemCapabilityForm.formAction}
                  itemTagId={formInfo.itemCapabilityForm.editObjectId}
                />
              </DialogContent>
            </Dialog>
          </>
        )}
      {currentForm === 'itemJobForm' && formInfo.itemJobForm.isOpen && (
        <>
          <Dialog
            fullWidth
            fullScreen={false}
            open={formInfo?.itemJobForm?.isOpen}
            maxWidth="sm"
            disableEnforceFocus={true}
          >
            <DialogContent>
              <SidePanelHeader
                header={`${user?.label_job} ${
                  formInfo?.itemJobForm?.editObjectId && !hideSystemIds
                    ? `(${formInfo.itemJobForm.editObjectId})`
                    : ''
                }`}
                formKey={formInfo.itemJobForm.formKey}
              />
              <ItemJobForm
                auditHeader={`${user?.label_job} History`}
                formAction={formInfo.itemJobForm.formAction}
                itemTagId={formInfo.itemJobForm.editObjectId}
              />
            </DialogContent>
          </Dialog>
        </>
      )}
      {currentForm === 'itemJobRoleForm' && formInfo.itemJobRoleForm.isOpen && (
        <>
          <Dialog
            fullWidth
            fullScreen={false}
            open={formInfo?.itemJobRoleForm?.isOpen}
            maxWidth="xl"
            disableEnforceFocus={true}
          >
            <DialogContent>
              <SidePanelHeader
                header={`${user?.label_job_role} ${
                  formInfo?.itemJobRoleForm?.editObjectId && !hideSystemIds
                    ? `(${formInfo.itemJobRoleForm.editObjectId})`
                    : ''
                }`}
                formKey={formInfo.itemJobRoleForm.formKey}
              />
              <ItemJobRoleForm
                formAction={formInfo.itemJobRoleForm.formAction}
                itemTagId={formInfo.itemJobRoleForm.editObjectId}
              />
            </DialogContent>
          </Dialog>
        </>
      )}
      {currentForm === 'itemLevelForm' && formInfo.itemLevelForm.isOpen && (
        <>
          <Dialog
            fullWidth
            fullScreen={false}
            open={formInfo?.itemLevelForm?.isOpen}
            maxWidth="sm"
            disableEnforceFocus={true}
          >
            <DialogContent>
              <SidePanelHeader
                header={`${adminTabsTitles.level} ${
                  formInfo?.itemLevelForm?.editObjectId && !hideSystemIds
                    ? `(${formInfo.itemLevelForm.editObjectId})`
                    : ''
                }`}
                formKey={formInfo.itemLevelForm.formKey}
              />
              <ItemLevelForm
                auditHeader={`${adminTabsTitles.level} History`}
                formAction={formInfo.itemLevelForm.formAction}
                itemTagId={formInfo.itemLevelForm.editObjectId}
              />
            </DialogContent>
          </Dialog>
        </>
      )}
      {currentForm === 'itemEmployeeForm' && formInfo.itemEmployeeForm.isOpen && (
        <>
          <Dialog
            fullWidth
            fullScreen={false}
            open={formInfo?.itemEmployeeForm?.isOpen}
            maxWidth="xl"
            disableEnforceFocus={true}
          >
            <DialogContent>
              <SidePanelHeader
                header={`${adminTabsTitles.employee} ${
                  formInfo?.itemEmployeeForm?.editObjectId && !hideSystemIds
                    ? `(${formInfo.itemEmployeeForm.editObjectId})`
                    : ''
                }`}
                formKey={formInfo.itemEmployeeForm.formKey}
              />
              <ItemEmployeeForm
                formAction={formInfo.itemEmployeeForm.formAction}
                itemTagId={formInfo.itemEmployeeForm.editObjectId}
                isUpdatable={formInfo.itemEmployeeForm.isUpdatable}
                isSameForm={formInfo.itemEmployeeForm.isSameForm}
                formInfo={formInfo.itemEmployeeForm}
                isReadOnly={formInfo.itemEmployeeForm.isReadOnly}
                user={user}
              />
            </DialogContent>
          </Dialog>
        </>
      )}
      {currentForm === 'itemCostCenterForm' &&
        formInfo.itemCostCenterForm.isOpen && (
          <>
            <Dialog
              fullWidth
              fullScreen={false}
              open={formInfo?.itemCostCenterForm?.isOpen}
              maxWidth="sm"
              disableEnforceFocus={true}
            >
              <DialogContent>
                <SidePanelHeader
                  header={`${adminTabsTitles.costCenter} ${
                    formInfo?.itemCostCenterForm?.editObjectId && !hideSystemIds
                      ? `(${formInfo.itemCostCenterForm.editObjectId})`
                      : ''
                  }`}
                  formKey={formInfo.itemCostCenterForm.formKey}
                />
                <ItemCostCenterForm
                  auditHeader={`${adminTabsTitles.costCenter} History`}
                  formAction={formInfo.itemCostCenterForm.formAction}
                  itemTagId={formInfo.itemCostCenterForm.editObjectId}
                />
              </DialogContent>
            </Dialog>
          </>
        )}
      {currentForm === 'skillsCertificationForm' &&
        formInfo.skillsCertificationForm.isOpen && (
          <>
            <Dialog
              fullWidth
              fullScreen={false}
              open={formInfo?.skillsCertificationForm?.isOpen}
              maxWidth="md"
              disableEnforceFocus={true}
            >
              <DialogContent>
                <SidePanelHeader
                  header={`${formInfo.skillsCertificationForm.editObjectInfo} - ${user?.label_skill} Certifications`}
                  formKey={formInfo.skillsCertificationForm.formKey}
                />
                <ItemSkillCertificationForm
                  formAction={formInfo.skillsCertificationForm.formAction}
                  formInfo={formInfo.skillsCertificationForm}
                />
              </DialogContent>
            </Dialog>
          </>
        )}
      {currentForm === 'itemExternalApiForm' &&
        formInfo.itemExternalApiForm.isOpen && (
          <>
            <Dialog
              fullWidth
              fullScreen={false}
              open={formInfo?.itemExternalApiForm?.isOpen}
              maxWidth="xl"
              disableEnforceFocus={true}
            >
              <DialogContent>
                <SidePanelHeader
                  header={`${adminTabsTitles.externalApi} ${
                    formInfo?.itemExternalApiForm?.editObjectId &&
                    !hideSystemIds
                      ? `(${formInfo.itemExternalApiForm.editObjectId})`
                      : ''
                  }`}
                  formKey={formInfo.itemExternalApiForm.formKey}
                />
                <ItemExternalApiForm
                  formAction={formInfo.itemExternalApiForm.formAction}
                  itemTagId={formInfo.itemExternalApiForm.editObjectId}
                />
              </DialogContent>
            </Dialog>
          </>
        )}
      {currentForm === 'itemOfficeForm' && formInfo.itemOfficeForm.isOpen && (
        <>
          <Dialog
            fullWidth
            fullScreen={false}
            open={formInfo?.itemOfficeForm?.isOpen}
            maxWidth="sm"
            disableEnforceFocus={true}
          >
            <DialogContent>
              <SidePanelHeader
                header={`${adminTabsTitles.office} ${
                  formInfo?.itemOfficeForm?.editObjectId && !hideSystemIds
                    ? `(${formInfo.itemOfficeForm.editObjectId})`
                    : ''
                }`}
                formKey={formInfo.itemOfficeForm.formKey}
              />
              <ItemOfficeForm
                auditHeader={`${adminTabsTitles.office} History`}
                formAction={formInfo.itemOfficeForm.formAction}
                itemTagId={formInfo.itemOfficeForm.editObjectId}
              />
            </DialogContent>
          </Dialog>
        </>
      )}
      {currentForm === 'itemAssociateSkillForm' &&
        formInfo.itemAssociateSkillForm.isOpen && (
          <>
            <Dialog
              fullWidth
              fullScreen={false}
              open={formInfo?.itemAssociateSkillForm?.isOpen}
              maxWidth="xl"
              disableEnforceFocus={true}
            >
              <DialogContent sx={{ backgroundColor: '#efefef' }}>
                <SidePanelHeader
                  header={`${user?.label_skill} ${
                    formInfo?.itemAssociateSkillForm?.editObjectId &&
                    !hideSystemIds
                      ? `(${formInfo.itemAssociateSkillForm.editObjectId})`
                      : ''
                  }`}
                  formKey={formInfo.itemAssociateSkillForm.formKey}
                />
                <AssociatSkillCapability
                  formAction={formInfo.itemAssociateSkillForm.formAction}
                  itemTagId={formInfo.itemAssociateSkillForm.editObjectId}
                />
              </DialogContent>
            </Dialog>
          </>
        )}
      {currentForm === 'itemAssociateCapabilityForm' &&
        formInfo.itemAssociateCapabilityForm.isOpen && (
          <>
            <Dialog
              fullWidth
              fullScreen={false}
              open={formInfo?.itemAssociateCapabilityForm?.isOpen}
              maxWidth="xl"
              disableEnforceFocus={true}
            >
              <DialogContent sx={{ backgroundColor: '#efefef' }}>
                <SidePanelHeader
                  header={`${user?.label_capability} ${
                    formInfo?.itemAssociateCapabilityForm?.editObjectId &&
                    !hideSystemIds
                      ? `(${formInfo.itemAssociateCapabilityForm.editObjectId})`
                      : ''
                  }`}
                  formKey={formInfo.itemAssociateCapabilityForm.formKey}
                />
                <AssociatCapabilitySkill
                  formAction={formInfo.itemAssociateCapabilityForm.formAction}
                  itemTagId={formInfo.itemAssociateCapabilityForm.editObjectId}
                />
              </DialogContent>
            </Dialog>
          </>
        )}
      {currentForm === 'associateCapabilityJobRoleForm' &&
        formInfo.associateCapabilityJobRoleForm.isOpen && (
          <>
            <Dialog
              fullWidth
              fullScreen={false}
              open={formInfo?.associateCapabilityJobRoleForm?.isOpen}
              maxWidth="md"
              disableEnforceFocus={true}
            >
              <DialogContent sx={{ backgroundColor: '#efefef' }}>
                <SidePanelHeader
                  header={`${user?.label_capability} ${
                    formInfo?.associateCapabilityJobRoleForm?.editObjectId &&
                    !hideSystemIds
                      ? `(${formInfo.associateCapabilityJobRoleForm.editObjectId})`
                      : ''
                  }`}
                  formKey={formInfo.associateCapabilityJobRoleForm.formKey}
                />
                <AssociateJobRole
                  formAction={
                    formInfo.associateCapabilityJobRoleForm.formAction
                  }
                  itemTagId={
                    formInfo.associateCapabilityJobRoleForm.editObjectId
                  }
                />
              </DialogContent>
            </Dialog>
          </>
        )}
      {currentForm === 'associateJobRoleFormCapability' &&
        formInfo.associateJobRoleFormCapability.isOpen && (
          <>
            <Dialog
              fullWidth
              fullScreen={false}
              open={formInfo?.associateJobRoleFormCapability?.isOpen}
              maxWidth="md"
              disableEnforceFocus={true}
            >
              <DialogContent sx={{ backgroundColor: '#efefef' }}>
                <SidePanelHeader
                  header={`${user.label_job_role} ${
                    formInfo?.associateJobRoleFormCapability?.editObjectId &&
                    !hideSystemIds
                      ? `(${formInfo.associateJobRoleFormCapability.editObjectId})`
                      : ''
                  }`}
                  formKey={formInfo.associateJobRoleFormCapability.formKey}
                />
                <AssociateCapability
                  formAction={
                    formInfo.associateJobRoleFormCapability.formAction
                  }
                  itemTagId={
                    formInfo.associateJobRoleFormCapability.editObjectId
                  }
                />
              </DialogContent>
            </Dialog>
          </>
        )}
    </>
  );
};

export { FormDisplayer };
