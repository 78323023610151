import React, { useState, useRef, useEffect, useCallback } from 'react';
import { AutoSizer } from 'react-virtualized';
import IconButton from '@mui/material/IconButton';
import { Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import pluralize from 'pluralize';
import { MdAdd, MdRefresh } from 'react-icons/md';

import SearchWithFilters from 'components/SearchWithFilters';
import {
  actionCell,
  checkCell,
  checkHeaderCell,
  labelCell,
  labelHeader,
  tagTypeCell,
} from 'shared/components/_Tables/TableCells';
import { makeButtonStyles } from 'components/styles';
import { VirtualTable } from 'shared/components/_Tables/VirtualTable';
import { EnhancedTableToolbar } from 'components/EnhancedTableToolbar';
import { tableStyles } from 'shared/components/_Tables/VirtualTable/styles';
import { Action } from 'helpers/action';
import {
  exportPayPlanCsv,
  fetchPayPlans,
  getPayPlanHelp,
  getPayPlanImportSampleCsv,
  getPayPlanInfoStatus,
  getPayPlanStatus,
  resetPayPlanInfoState,
  resetPayPlanState,
  uploadPayPlanImportCsv,
} from 'features/payPlan/payPlansSlice';
import { mdTheme } from 'theme';
import { PopupAction } from 'components/_Misc/PopupAction/components/PopupAction';
import {
  closePanel,
  openPanel,
  setEditObjectId,
} from 'features/common/formSlice';
import { NoDataMessage } from 'shared/components/_Tables/NoDataMessage';
import {
  LoadingStatus,
  NO_MATCHING_PAY_PLANS,
  PAY_PLAN_TABLE_KEYS,
} from 'helpers';
import { ItemPayPlanFilter } from '../ItemPayPlanFilter';
import { DropZoneImport } from 'shared/components/_DropZoneImport';
import { selectActiveUser } from 'features/account/accountSlice';
import { useDebounce } from 'hooks/useDebounce';
import { CustomDialog } from 'shared/components/_Modal';
import {
  fetchPayPlansDropDown,
  getPayPlansDropDownStatus,
  selectPayPlansDropdown,
} from 'features/AdminSettingsDropDown/dropDownSlice';

function ItemPayPlanTable({
  payPlans,
  pageInfo,
  itemPayPlanPayload,
  setItemPayPlanPayload,
  sortDirection,
  sortBy,
  handleSort,
}) {
  const dispatch = useDispatch();
  const buttonStyles = makeButtonStyles(mdTheme);
  const tableClasses = tableStyles();
  const [selected, setSelected] = useState([]);
  const [selectedAll, setSelectedAll] = React.useState(false);
  const [openHelpDialog, setOpenHelpDialog] = useState(false);

  const payPlanStatus = useSelector(getPayPlanStatus);
  const loadedRowsMap = useSelector((state) => state.payPlans.loadedPayPlanMap);
  const getInfoStatus = useSelector(getPayPlanInfoStatus);

  const user = useSelector(selectActiveUser);
  const hidePayPlanIdColumn = user?.hide_system_ids;
  const payPlanColumnIds = user?.admin_field_ids;
  const headerHeight = 40;
  const rowHeight = 60;

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const prevSelectedAll = usePrevious(selectedAll);
  if (prevSelectedAll && selectedAll) {
    if (selected.length !== payPlans?.length) {
      setSelected(payPlans.map((row) => row.pay_plan_id));
      setSelectedAll(true);
    }
  }

  const onRefreshIconClick = async () => {
    await dispatch(fetchPayPlans({ ...itemPayPlanPayload }));
  };

  const handleClick = (event, pay_plan_id) => {
    event.stopPropagation();
    const selectedIndex = selected.indexOf(pay_plan_id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, pay_plan_id);
    } else {
      newSelected = selected.filter((id) => id !== pay_plan_id);
    }
    setSelected(newSelected);
    setSelectedAll(newSelected.length === payPlans?.length);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelectedIds = payPlans.map((tag) => tag.pay_plan_id);
      setSelected(newSelectedIds);
      setSelectedAll(true);
      return;
    }
    setSelected([]);
    setSelectedAll(false);
  };

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const handleRowClick = async ({ rowData }) => {
    await dispatch(closePanel({ formKey: 'itemPayPlanForm' }));
    if (rowData?.pay_plan_id) {
      await dispatch(
        setEditObjectId({
          formKey: `itemPayPlanForm`,
          object_id: rowData?.pay_plan_id,
        })
      );
      dispatch(openPanel({ formKey: `itemPayPlanForm`, formAction: 'Edit' }));
    }
  };

  const init_columns = [
    {
      classes: tableClasses,
      minWidth: 50,
      maxWidth: 50,
      width: 50,
      label: '',
      dataKey: 'pay_plan_id',
      padding: 'checkbox',
      cellLevel: 'pay_plan',
      numSelected: selected.length,
      rowCount: payPlans?.length,
      handleClick: handleClick,
      handleSelectAllClick: handleSelectAllClick,
      isSelected: isSelected,
      component: checkCell,
      headerComponent: checkHeaderCell,
    },
    {
      classes: tableClasses,
      minWidth: 50,
      maxWidth: 50,
      width: 50,
      label: '',
      dataKey: 'pay_plan_id',
      padding: 'none',
      actionCellLevel: 'payPlan',
      targetPopup: 'payPlanManagement',
      formKey: 'itemPayPlanForm',
      dispatch: dispatch,
      component: actionCell,
      headerComponent: labelHeader,
    },
  ];

  const handleColumnVisibility = (key, column) => {
    if (hidePayPlanIdColumn && key === 'pay_plan_id') {
      return undefined;
    }

    const isVisible = payPlanColumnIds?.includes(
      PAY_PLAN_TABLE_KEYS?.[key]?.id
    );
    if (isVisible) {
      return column;
    }
  };

  const columns = [
    ...init_columns,
    handleColumnVisibility('pay_plan_id', {
      classes: tableClasses,
      width: 140,
      minWidth: 140,
      flexGrow: 1,
      label: 'pay Plan Id',
      dataKey: 'pay_plan_id',
      padding: 'normal',
      component: labelCell,
      headerComponent: labelHeader,
      sortDirection: sortDirection,
      sortBy: sortBy,
      handleSort: handleSort,
    }),
    handleColumnVisibility('display_name', {
      classes: tableClasses,
      flexGrow: 1,
      width: 200,
      minWidth: 200,
      label: 'Name',
      dataKey: 'display_name',
      padding: 'normal',
      component: labelCell,
      headerComponent: labelHeader,
      sortDirection: sortDirection,
      sortBy: sortBy,
      handleSort: handleSort,
    }),
    handleColumnVisibility('user_count', {
      classes: tableClasses,
      flexGrow: 1,
      width: 200,
      minWidth: 200,
      label: `${pluralize('Employee', payPlans?.length)}`,
      dataKey: 'user_count',
      displayDataKey: 'user_count',
      padding: 'none',
      component: tagTypeCell,
      headerComponent: labelHeader,
      sortDirection: sortDirection,
      sortBy: sortBy,
      handleSort: handleSort,
    }),
  ].filter(Boolean);

  // eslint-disable-next-line no-unused-vars
  async function loadMoreRows({ startIndex, stopIndex }) {
    if (payPlanStatus !== LoadingStatus.Loading) {
      await dispatch(
        fetchPayPlans({
          ...itemPayPlanPayload,
          reset: false,
          startIndex: startIndex,
          stopIndex: stopIndex,
        })
      );
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    useDebounce((searchString) => {
      setItemPayPlanPayload({
        ...itemPayPlanPayload,
        searchString: searchString,
      });
    }, 1000),
    [itemPayPlanPayload]
  );

  const handleCreateNew = () => {
    dispatch(openPanel({ formKey: 'itemPayPlanForm', formAction: 'Create' }));
  };

  return (
    <div className={tableClasses.root}>
      <ItemPayPlanFilter
        setItemPayPlanPayload={setItemPayPlanPayload}
        itemPayPlanPayload={itemPayPlanPayload}
      />
      {selected.length > 0 && (
        <EnhancedTableToolbar
          numSelected={selected.length}
          toolTipTitle={'Delete'}
          totalCount={pageInfo?.TotalCount}
        >
          <PopupAction
            action={Action.DeleteArray}
            object={{
              payPlanIds: selected,
              setPayPlanIds: setSelected,
            }}
            level={'payPlan'}
            showLabel={false}
          />
        </EnhancedTableToolbar>
      )}
      <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
        <Tooltip title="Add New Pay Plans">
          <IconButton
            onClick={handleCreateNew}
            size="large"
            className={buttonStyles.contentAddButton}
          >
            <MdAdd />
          </IconButton>
        </Tooltip>
        <div style={{ flexGrow: 1 }}></div>
        <DropZoneImport
          key="payPlan-dzi"
          getEntityImportSample={getPayPlanImportSampleCsv}
          uploadEntityState={uploadPayPlanImportCsv}
          resetEntitySelector={resetPayPlanState}
          mainTitle="PayPlan"
          exportEntityState={exportPayPlanCsv}
          exportFilterData={itemPayPlanPayload}
          setOpenHelpDialog={setOpenHelpDialog}
          isExport
          isInfoIcon
        />
        <CustomDialog
          getEntityHelp={getPayPlanHelp}
          fieldName="pay_plan_id"
          selectDropdownEntity={selectPayPlansDropdown}
          getDropdownStatusEntity={getPayPlansDropDownStatus}
          fetchDropDownEntityPage={fetchPayPlansDropDown}
          openHelpDialog={openHelpDialog}
          setOpenHelpDialog={setOpenHelpDialog}
          getEntityHelpStatus={getInfoStatus}
          selectLabel="Select Pay Plan"
          resetHelpEntity={resetPayPlanInfoState}
        />
        <IconButton
          onClick={onRefreshIconClick}
          aria-label="close"
          sx={{
            color: '#707070',
          }}
          size="large"
        >
          <MdRefresh style={{ fontSize: '18px', color: 'black' }} />
        </IconButton>
        <SearchWithFilters
          filter={false}
          onChange={(evt) => debouncedSearch(evt.target.value)}
          value={itemPayPlanPayload?.searchString}
        />
      </div>
      {payPlans.length > 0 ? (
        <div
          style={{
            height: 'calc(100vh - 295px)',
            minWidth: '500px',
            paddingTop: '2rem',
          }}
        >
          <AutoSizer>
            {({ width, height }) => (
              <VirtualTable
                initialRows={payPlans}
                allRows={payPlans}
                totalRowCount={pageInfo?.TotalCount}
                loadedRowsMap={loadedRowsMap}
                loadMoreRows={loadMoreRows}
                columnDefinitions={columns}
                cellClassName={tableClasses.flexContainer}
                className={tableClasses.table}
                rowClassName={tableClasses.flexContainer}
                headerHeight={headerHeight}
                width={width}
                height={height}
                rowHeight={rowHeight}
                onRowClick={handleRowClick}
              />
            )}
          </AutoSizer>
        </div>
      ) : (
        <NoDataMessage message={NO_MATCHING_PAY_PLANS} />
      )}
    </div>
  );
}

export { ItemPayPlanTable };
