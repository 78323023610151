import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    tableHead: {
      backgroundColor: theme.palette.secondary.main,
      '& > tr > th > p': {
        color: '#ffffff',
        fontSize: '20px',
      },
    },
    tableRowHeader: {
      backgroundColor: theme.palette.common.headerBg,
      '& > td': {
        color: '#ffffff',
        fontSize: '20px',
      },
    },
  })
);

export { useStyles };
