import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { AutoSizer } from 'react-virtualized';

import {
  activateLoading,
  deactivateLoading,
} from 'features/global/loadingProgressSlice';
import { selectGroupTraining } from 'features/groupTraining/groupTrainingSlice';
import { NoDataMessage } from 'shared/components/_Tables/NoDataMessage';
import {
  checkHeaderCell,
  conditionalCheckCell,
  labelCell,
  labelHeader,
  styledLabelCell,
} from 'shared/components/_Tables/TableCells';
import { VirtualTable } from 'shared/components/_Tables/VirtualTable';
import { tableStyles } from 'shared/components/_Tables/VirtualTable/styles';
import { useFormikContext } from 'formik';
import { NO_MATCHING_SKILLS } from 'helpers';

const GroupTrainingTable = ({
  groupTrainingData,
  pageInfo,
  allFormData,
  employeeIds,
  setIsSkillModalOpen,
  setIsAttendTable,
  setGroupTrainingPayload,
  groupTrainingPayload,
  user,
}) => {
  //#region Constants
  const tableClasses = tableStyles();
  const dispatch = useDispatch();
  const headerHeight = 60;
  const rowHeight = 60;
  const isSelected = (id) => selected.indexOf(id) !== -1;
  const { resetForm } = useFormikContext();
  //#endregion Constants
  //#region Hooks
  //#endregion Hooks

  //#region State
  const [selected, setSelected] = useState([]);
  const [selectedAll, setSelectedAll] = React.useState(false);
  //#endregion State

  //#region Selectors
  const loadedRowsMap = useSelector(
    (state) => state.groupTraining.loadedGroupTrainingMap
  );
  const getTrainingData = useSelector(selectGroupTraining);
  const filterNullId = selected?.filter((ids) => ids !== null);
  //#endregion Selectors

  //#region Refs
  //#endregion Refs
  useEffect(() => {
    if (getTrainingData) {
      setGroupTrainingPayload({
        ...groupTrainingPayload,
        ...getTrainingData,
      });
    }
  }, [getTrainingData]);
  //#endregion Effects

  //#region Methods
  const handleClick = (event, capability_skill_id) => {
    event.stopPropagation();
    const selectedIndex = selected.indexOf(capability_skill_id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, capability_skill_id);
    } else {
      newSelected = selected.filter((id) => id !== capability_skill_id);
    }

    setSelected(newSelected);
    setSelectedAll(newSelected.length === groupTrainingData?.length);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelectedIds = groupTrainingData?.map(
        (audience) => audience.capability_skill_id
      );
      setSelected(newSelectedIds);
      setSelectedAll(true);
      return;
    }
    setSelected([]);
    setSelectedAll(false);
  };

  const submitData = async () => {
    const filterNullIds = selected.filter((ids) => ids !== null);
    const capabilityId = allFormData?.capability_id;
    const SubmitData = {
      employee_ids: employeeIds ? employeeIds : [],
      capability_id: capabilityId,
      capability_skill_ids: filterNullIds ? filterNullIds : [],
      job_id: null,
      job_role_id: null,
    };
    setGroupTrainingPayload({ ...groupTrainingPayload, ...SubmitData });
    dispatch(activateLoading());
    dispatch(deactivateLoading());
    setIsSkillModalOpen(false);
    setIsAttendTable(true);
    resetForm();
  };

  async function loadMoreRows({ startIndex, stopIndex }) {}

  const columns = [
    {
      classes: tableClasses,
      minWidth: 120,
      maxWidth: 120,
      width: 120,
      label: '',
      dataKey: 'capability_skill_id',
      padding: 'checkbox',
      cellLevel: 'capability_skill',
      numSelected: selected.length,
      rowCount: groupTrainingData?.length,
      handleClick: handleClick,
      handleSelectAllClick: handleSelectAllClick,
      isSelected: isSelected,
      component: conditionalCheckCell,
      headerComponent: checkHeaderCell,
    },
    {
      classes: tableClasses,
      minWidth: 150,
      maxWidth: 150,
      width: 150,
      label: user.label_skill,
      dataKey: 'skill_name',
      padding: 'none',
      cellLevel: 'skill_name',
      numSelected: selected.length,
      rowCount: groupTrainingData?.length,
      setId: 'capability_skill',
      textColor: 'red',
      component: styledLabelCell,
      headerComponent: labelHeader,
    },
    {
      classes: tableClasses,
      minWidth: 150,
      maxWidth: 150,
      width: 150,
      label: 'Level',
      dataKey: 'level_name',
      padding: 'none',
      cellLevel: 'level_name',
      numSelected: selected.length,
      rowCount: groupTrainingData?.length,
      setId: 'capability_skill',
      textColor: 'red',
      component: styledLabelCell,
      headerComponent: labelHeader,
    },
    {
      classes: tableClasses,
      minWidth: 150,
      maxWidth: 150,
      width: 150,
      label: 'To Expire',
      dataKey: 'expiry_date',
      padding: 'none',
      cellLevel: 'expiry_date',
      transform: (data) =>
        data ? moment(data).local().format('YYYY-MM-DD') : 'Never Expires',
      numSelected: selected.length,
      rowCount: groupTrainingData?.length,
      component: labelCell,
      headerComponent: labelHeader,
    },
  ];
  //#endregion Methods

  //#region Render time calcs
  //#endregion Render time calcs

  //#region Render

  return (
    <div className={tableClasses.root}>
      {groupTrainingData?.length > 0 ? (
        <>
          <div
            style={{
              height: 'calc(100vh - 295px)',
              minWidth: '500px',
              paddingTop: '2rem',
            }}
          >
            <AutoSizer style={{ width: '100%' }}>
              {({ width, height }) => (
                <VirtualTable
                  initialRows={groupTrainingData}
                  allRows={groupTrainingData}
                  totalRowCount={pageInfo?.TotalCount}
                  loadedRowsMap={loadedRowsMap}
                  loadMoreRows={loadMoreRows}
                  columnDefinitions={columns}
                  cellClassName={tableClasses.flexContainer}
                  className={tableClasses.table}
                  rowClassName={tableClasses.flexContainer}
                  headerHeight={headerHeight}
                  width={width}
                  height={height}
                  rowHeight={rowHeight}
                />
              )}
            </AutoSizer>
          </div>
          <Button
            size="large"
            variant="contained"
            color="primary"
            fullWidth
            disabled={filterNullId.length > 0 ? false : true}
            onClick={() => submitData()}
            sx={{ marginTop: '0.5rem' }}
          >
            Continue
          </Button>
        </>
      ) : (
        <NoDataMessage message={NO_MATCHING_SKILLS} />
      )}
    </div>
  );
  //#endregion Render
};
export { GroupTrainingTable };
