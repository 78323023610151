import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { payGradeService } from 'features/services/payGrade.service';
import { LoadingStatus, SavingStatus } from 'helpers';

const initialState = {
  status: LoadingStatus.Idle,
  error: null,
  payGrades: [],
  payGradeStatus: LoadingStatus.Idle,
  payGradePageInfo: {},
  displayPayGrade: {},
  payGradeUsage: [],
  loadedPayGradeMap: {},
  payGradeInfo: [],
  payGradeInfoStatus: LoadingStatus.Idle,
};

export const fetchPayGrade = createAsyncThunk(
  'payGrades/fetchPayGrade',
  async (params = {}) =>
    await payGradeService.getPayGrades(
      params.startIndex,
      params.stopIndex,
      params.searchString,
      params.sort,
      params.display_name
    )
);

export const fetchPayGradeById = createAsyncThunk(
  'payGrades/fetchPayGradeById',
  async (payPlanId) => {
    const response = await payGradeService.getById(payPlanId);
    response.id = payPlanId;
    return response;
  }
);

export const fetchPayGradeUsageById = createAsyncThunk(
  'groups/fetchPayGradeUsageById',
  async (payPlanId) => await payGradeService.getPayGradeUsageById(payPlanId)
);

export const createPayGrade = createAsyncThunk(
  'payGrades/createPayGrade',
  async (params) => await payGradeService.create(params)
);

export const updatePayGrade = createAsyncThunk(
  'payGrades/updatePayGrade',
  async (params) => await payGradeService.update(params)
);

export const deletePayGrade = createAsyncThunk(
  'payGrades/deletePayGrade',
  async (pay_grade_id) => {
    const response = await payGradeService.delete(pay_grade_id);
    return response;
  }
);

export const deletePayGradeArray = createAsyncThunk(
  'assets/deletePayGradeArray',
  async (payGradeId) => {
    const response = await payGradeService.deletePayGradeArray(payGradeId);
    return response;
  }
);

export const getPayGradeImportSampleCsv = createAsyncThunk(
  'PayGrade/getPayGradeImportSampleCsv',
  async () => await payGradeService.getPayGradeImportCsv()
);

export const uploadPayGradeImportCsv = createAsyncThunk(
  'PayGrade/uploadPayGradeImportCsv',
  async (params) => await payGradeService.uploadPayGradeImportCsv(params)
);

export const exportPayGradeCsv = createAsyncThunk(
  'PayGrade/exportPayGradeCsv',
  async (params) => await payGradeService.exportPayGradeCsv(params.display_name)
);

export const getPayGradeHelp = createAsyncThunk(
  'PayGrade/getPayGradeHelp',
  async (params) => await payGradeService.getPayGradeImportHelp(params)
);

const payGradesSlice = createSlice({
  name: 'payGrades',
  initialState,
  reducers: {
    resetPayGradeState: (state) => initialState,
    resetPayGradeInfoState: (state) => {
      state.payGradeInfoStatus = LoadingStatus.Idle;
    },
  },
  extraReducers: {
    [fetchPayGrade.pending]: (state, action) => {
      state.payGradeStatus = LoadingStatus.Loading;
    },
    [fetchPayGrade.fulfilled]: (state, action) => {
      state.payGradePageInfo = action.payload.pageInfo;
      state.payGradeStatus = LoadingStatus.Loaded;

      if (action.meta.arg.reset) {
        state.loadedPayGradeMap = {};
      }

      const startIndex = action.meta.arg.startIndex;
      const stopIndex = startIndex + action.payload.payGrades.length - 1;

      for (var i = startIndex; i <= stopIndex; i++) {
        state.loadedPayGradeMap[i] = LoadingStatus.Loaded;
      }

      // In case we didn't load as many as requested
      for (
        let j = stopIndex + 1;
        j <= action.payload?.pageInfo?.TotalCount;
        j++
      ) {
        delete state.loadedPayGradeMap[j];
      }

      if (action.meta.arg.reset) {
        state.payGrades = action.payload.payGrades;
      } else {
        state.payGrades = state.payGrades.concat(action.payload.payGrades);
      }
    },
    [fetchPayGrade.rejected]: (state, action) => {
      state.payGradeStatus = LoadingStatus.Failed;
    },
    [createPayGrade.fulfilled]: (state, action) => {
      state.payGradeStatus = LoadingStatus.Idle;
    },
    [updatePayGrade.fulfilled]: (state, action) => {
      state.payGradeStatus = LoadingStatus.Idle;
    },
    [deletePayGrade.fulfilled]: (state, action) => {
      state.payGradeStatus = LoadingStatus.Idle;
    },
    [deletePayGradeArray.fulfilled]: (state, action) => {
      state.payGradeStatus = LoadingStatus.Idle;
    },
    [deletePayGradeArray.rejected]: (state, action) => {
      state.status = SavingStatus.Failed;
      state.error = action.error.message;
    },
    [fetchPayGradeById.fulfilled]: (state, action) => {
      state.displayPayPlan = action.payload;
    },
    [fetchPayGradeUsageById.fulfilled]: (state, action) => {
      state.payGradeUsage = action.payload;
    },
    [getPayGradeImportSampleCsv.fulfilled]: (state, action) => {
      if (action?.payload) {
        const url = window.URL.createObjectURL(new Blob([action.payload]));
        const link = document.createElement('a');
        link.href = url;
        let downloadName =
          action.payload.headers?.filename ?? 'pay_grade_import.csv';
        link.setAttribute('download', downloadName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    },
    [getPayGradeImportSampleCsv.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    [exportPayGradeCsv.fulfilled]: (state, action) => {
      if (action?.payload) {
        const url = window.URL.createObjectURL(new Blob([action.payload]));
        const link = document.createElement('a');
        link.href = url;
        let downloadName =
          action.payload.headers?.filename ?? 'pay_grades_export.csv';
        link.setAttribute('download', downloadName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    },
    [exportPayGradeCsv.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    [getPayGradeHelp.pending]: (state, action) => {
      state.payGradeInfoStatus = LoadingStatus.Loading;
    },
    [getPayGradeHelp.fulfilled]: (state, action) => {
      state.payGradeInfo = action.payload;
      state.payGradeInfoStatus = LoadingStatus.Loaded;
    },
    [getPayGradeHelp.rejected]: (state, action) => {
      state.payGradeInfoStatus = LoadingStatus.Failed;
    },
  },
});

export const selectPayGrade = (state) => state.payGrades.payGrades;
export const getPayGradeStatus = (state) => state.payGrades.payGradeStatus;
export const getPayGradePageInfo = (state) => state.payGrades.payGradePageInfo;
export const getPayGradeUsage = (state) => state.payGrades.payGradeUsage;

export const getPayGradeInfoStatus = (state) =>
  state.payGrades.payGradeInfoStatus;

export const { resetPayGradeState, resetPayGradeInfoState } =
  payGradesSlice.actions;

export default payGradesSlice.reducer;
