import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { adminDepotService } from 'features/services/adminDepot.service';
import { LoadingStatus } from 'helpers/enums';

const initialState = {
  adminDepot: {},
  adminDepotStatus: LoadingStatus.Idle,
  loadedDepotMap: {},
  appIsLoading: false,
  adminFieldIds: {
    payPlanField: { data: [], status: LoadingStatus.Idle },
    payGradeField: { data: [], status: LoadingStatus.Idle },
    jobField: { data: [], status: LoadingStatus.Idle },
    jobRoleField: { data: [], status: LoadingStatus.Idle },
    capabilityField: { data: [], status: LoadingStatus.Idle },
    skillField: { data: [], status: LoadingStatus.Idle },
    levelField: { data: [], status: LoadingStatus.Idle },
    employeeField: { data: [], status: LoadingStatus.Idle },
    costCenterField: { data: [], status: LoadingStatus.Idle },
    officeField: { data: [], status: LoadingStatus.Idle },
    externalApiField: { data: [], status: LoadingStatus.Idle },
  },
  adminFieldStatus: LoadingStatus.Idle,
};

export const fetchAllAdminDepots = createAsyncThunk(
  'admin/fetchAdminDepotData',
  async () => {
    const response = await adminDepotService.fetchAdminDepot();
    return response;
  }
);

export const updateAdminDepot = createAsyncThunk(
  'admin/updateAdminDepotData',
  async (params) => {
    const response = await adminDepotService.updateAdminDepot(params);
    return response;
  }
);

export const fetchPayPlanFieldIds = createAsyncThunk(
  'adminFieldIds/fetchPayPlanFieldIds',
  async () => {
    const response = await adminDepotService.fetchPayPlanFields();
    return response;
  }
);

export const fetchPayGradeFieldIds = createAsyncThunk(
  'adminFieldIds/fetchPayGradeFieldIds',
  async () => {
    const response = await adminDepotService.fetchPayGradeFields();
    return response;
  }
);

export const fetchJobFieldIds = createAsyncThunk(
  'adminFieldIds/fetchJobFieldIds',
  async () => {
    const response = await adminDepotService.fetchJobFields();
    return response;
  }
);

export const fetchJobRoleFieldIds = createAsyncThunk(
  'adminFieldIds/fetchJobRoleFieldIds',
  async () => {
    const response = await adminDepotService.fetchJobRoleFields();
    return response;
  }
);

export const fetchCapabilityFieldIds = createAsyncThunk(
  'adminFieldIds/fetchCapabilityFieldIds',
  async () => {
    const response = await adminDepotService.fetchCapabilityFields();
    return response;
  }
);

export const fetchSkillFieldIds = createAsyncThunk(
  'adminFieldIds/fetchSkillFieldIds',
  async () => {
    const response = await adminDepotService.fetchSkillFields();
    return response;
  }
);

export const fetchLevelFieldIds = createAsyncThunk(
  'adminFieldIds/fetchLevelFieldIds',
  async () => {
    const response = await adminDepotService.fetchLevelFields();
    return response;
  }
);

export const fetchEmployeeFieldIds = createAsyncThunk(
  'adminFieldIds/fetchEmployeeFieldIds',
  async () => {
    const response = await adminDepotService.fetchEmployeeFields();
    return response;
  }
);

export const fetchCostCenterFieldIds = createAsyncThunk(
  'adminFieldIds/fetchCostCenterFieldIds',
  async () => {
    const response = await adminDepotService.fetchCostCenterFields();
    return response;
  }
);

export const fetchOfficeFieldIds = createAsyncThunk(
  'adminFieldIds/fetchOfficeFieldIds',
  async () => {
    const response = await adminDepotService.fetchOfficeFields();
    return response;
  }
);

export const fetchExternalApiFieldIds = createAsyncThunk(
  'adminFieldIds/fetchExternalApiFieldIds',
  async () => {
    const response = await adminDepotService.fetchExternalApiFields();
    return response;
  }
);

export const generateDepotReports = createAsyncThunk(
  'admin/generateDepotReports',
  async () => {
    const response = await adminDepotService.runDepotReportCrunch();
    return response;
  }
);

const adminDepotSlice = createSlice({
  name: 'adminDepot',
  initialState,
  reducers: {
    resetJobsState: (state) => initialState,
    resetFieldIdsState: (state) => {
      state.adminFieldStatus = LoadingStatus.Idle;
    },
  },
  extraReducers: {
    [fetchAllAdminDepots.pending]: (state, action) => {
      state.adminDepotStatus = LoadingStatus.Loading;
      state.appIsLoading = true;
    },
    [fetchAllAdminDepots.fulfilled]: (state, action) => {
      state.adminDepotStatus = LoadingStatus.Loaded;
      state.adminDepot = action.payload;
      state.appIsLoading = false;
    },
    [fetchAllAdminDepots.rejected]: (state, action) => {
      state.adminDepotStatus = LoadingStatus.Failed;
      state.appIsLoading = false;
    },
    [updateAdminDepot.pending]: (state, action) => {
      state.adminDepotStatus = LoadingStatus.Loading;
      state.appIsLoading = true;
    },
    [updateAdminDepot.fulfilled]: (state, action) => {
      state.adminDepotStatus = LoadingStatus.Loaded;
      state.adminDepot = action.payload;
      state.appIsLoading = false;
    },
    [updateAdminDepot.rejected]: (state, action) => {
      state.adminDepotStatus = LoadingStatus.Failed;
      state.appIsLoading = false;
    },
    [fetchPayPlanFieldIds.pending]: (state, action) => {
      state.adminFieldIds.payPlanField = {
        data: [],
        status: LoadingStatus.Loading,
      };
      state.appIsLoading = true;
    },
    [fetchPayPlanFieldIds.fulfilled]: (state, action) => {
      state.adminFieldIds.payPlanField = {
        data: action.payload.map((item) => {
          return {
            value: +item.value,
            text: item.text,
          };
        }),
        status: LoadingStatus.Loaded,
      };
    },
    [fetchPayPlanFieldIds.rejected]: (state, action) => {
      state.adminFieldIds.payPlanField = {
        status: LoadingStatus.Failed,
      };
      state.appIsLoading = false;
    },
    [fetchPayGradeFieldIds.pending]: (state, action) => {
      state.adminFieldIds.payGradeField = {
        data: [],
        status: LoadingStatus.Loading,
      };
      state.appIsLoading = true;
    },
    [fetchPayGradeFieldIds.fulfilled]: (state, action) => {
      state.adminFieldStatus = LoadingStatus.Loaded;
      state.adminFieldIds.payGradeField = {
        data: action.payload.map((item) => {
          return {
            value: +item.value,
            text: item.text,
          };
        }),
        status: LoadingStatus.Loaded,
      };
      state.appIsLoading = false;
    },
    [fetchPayGradeFieldIds.rejected]: (state, action) => {
      state.adminFieldIds.payGradeField = {
        status: LoadingStatus.Failed,
      };
      state.appIsLoading = false;
    },
    [fetchJobFieldIds.pending]: (state, action) => {
      state.adminFieldIds.jobField = {
        data: [],
        status: LoadingStatus.Loading,
      };
      state.appIsLoading = true;
    },
    [fetchJobFieldIds.fulfilled]: (state, action) => {
      state.adminFieldIds.jobField = {
        data: action.payload.map((item) => {
          return {
            value: +item.value,
            text: item.text,
          };
        }),
        status: LoadingStatus.Loaded,
      };
      state.appIsLoading = false;
    },
    [fetchJobFieldIds.rejected]: (state, action) => {
      state.adminFieldIds.jobField = {
        status: LoadingStatus.Failed,
      };
      state.appIsLoading = false;
    },
    [fetchJobRoleFieldIds.pending]: (state, action) => {
      state.adminFieldIds.jobRoleField = {
        data: [],
        status: LoadingStatus.Loading,
      };
      state.appIsLoading = true;
    },
    [fetchJobRoleFieldIds.fulfilled]: (state, action) => {
      state.adminFieldIds.jobRoleField = {
        data: action.payload.map((item) => {
          return {
            value: +item.value,
            text: item.text,
          };
        }),
        status: LoadingStatus.Loaded,
      };
      state.appIsLoading = false;
    },
    [fetchJobRoleFieldIds.rejected]: (state, action) => {
      state.adminFieldIds.jobRoleField = {
        status: LoadingStatus.Failed,
      };
      state.appIsLoading = false;
    },
    [fetchCapabilityFieldIds.pending]: (state, action) => {
      state.adminFieldIds.capabilityField = {
        data: [],
        status: LoadingStatus.Loading,
      };
      state.appIsLoading = true;
    },
    [fetchCapabilityFieldIds.fulfilled]: (state, action) => {
      state.adminFieldIds.capabilityField = {
        data: action.payload.map((item) => {
          return {
            value: +item.value,
            text: item.text,
          };
        }),
        status: LoadingStatus.Loaded,
      };
      state.appIsLoading = false;
    },
    [fetchCapabilityFieldIds.rejected]: (state, action) => {
      state.adminFieldIds.capabilityField = {
        status: LoadingStatus.Failed,
      };
      state.appIsLoading = false;
    },
    [fetchSkillFieldIds.pending]: (state, action) => {
      state.adminFieldIds.skillField = {
        data: [],
        status: LoadingStatus.Loading,
      };
      state.appIsLoading = true;
    },
    [fetchSkillFieldIds.fulfilled]: (state, action) => {
      state.adminFieldIds.skillField = {
        data: action.payload.map((item) => {
          return {
            value: +item.value,
            text: item.text,
          };
        }),
        status: LoadingStatus.Loaded,
      };
      state.appIsLoading = false;
    },
    [fetchSkillFieldIds.rejected]: (state, action) => {
      state.adminFieldIds.skillField = {
        status: LoadingStatus.Failed,
      };
      state.appIsLoading = false;
    },
    [fetchLevelFieldIds.pending]: (state, action) => {
      state.adminFieldIds.levelField = {
        data: [],
        status: LoadingStatus.Loading,
      };
      state.appIsLoading = true;
    },
    [fetchLevelFieldIds.fulfilled]: (state, action) => {
      state.adminFieldIds.levelField = {
        data: action.payload.map((item) => {
          return {
            value: +item.value,
            text: item.text,
          };
        }),
        status: LoadingStatus.Loaded,
      };
      state.appIsLoading = false;
    },
    [fetchLevelFieldIds.rejected]: (state, action) => {
      state.adminFieldIds.levelField = {
        status: LoadingStatus.Failed,
      };
      state.appIsLoading = false;
    },
    [fetchEmployeeFieldIds.pending]: (state, action) => {
      state.adminFieldIds.employeeField = {
        data: [],
        status: LoadingStatus.Loading,
      };
      state.appIsLoading = true;
    },
    [fetchEmployeeFieldIds.fulfilled]: (state, action) => {
      state.adminFieldIds.employeeField = {
        data: action.payload.map((item) => {
          return {
            value: +item.value,
            text: item.text,
          };
        }),
        status: LoadingStatus.Loaded,
      };
      state.appIsLoading = false;
    },
    [fetchEmployeeFieldIds.rejected]: (state, action) => {
      state.adminFieldIds.employeeField = {
        status: LoadingStatus.Failed,
      };
      state.appIsLoading = false;
    },
    [fetchCostCenterFieldIds.pending]: (state, action) => {
      state.adminFieldIds.costCenterField = {
        data: [],
        status: LoadingStatus.Loading,
      };
      state.appIsLoading = true;
    },
    [fetchCostCenterFieldIds.fulfilled]: (state, action) => {
      state.adminFieldIds.costCenterField = {
        data: action.payload.map((item) => {
          return {
            value: +item.value,
            text: item.text,
          };
        }),
        status: LoadingStatus.Loaded,
      };
      state.appIsLoading = false;
    },
    [fetchCostCenterFieldIds.rejected]: (state, action) => {
      state.adminFieldIds.costCenterField = {
        status: LoadingStatus.Failed,
      };
      state.appIsLoading = false;
    },
    [fetchOfficeFieldIds.pending]: (state, action) => {
      state.adminFieldIds.officeField = {
        data: [],
        status: LoadingStatus.Loading,
      };
      state.appIsLoading = true;
    },
    [fetchOfficeFieldIds.fulfilled]: (state, action) => {
      state.adminFieldIds.officeField = {
        data: action.payload.map((item) => {
          return {
            value: +item.value,
            text: item.text,
          };
        }),
        status: LoadingStatus.Loaded,
      };
      state.appIsLoading = false;
    },
    [fetchOfficeFieldIds.rejected]: (state, action) => {
      state.adminFieldIds.officeField = {
        status: LoadingStatus.Failed,
      };
      state.appIsLoading = false;
    },
    [fetchExternalApiFieldIds.pending]: (state, action) => {
      state.adminFieldIds.externalApiField = {
        data: [],
        status: LoadingStatus.Loading,
      };
      state.appIsLoading = true;
    },
    [fetchExternalApiFieldIds.fulfilled]: (state, action) => {
      state.adminFieldIds.externalApiField = {
        data: action.payload.map((item) => {
          return {
            value: +item.value,
            text: item.text,
          };
        }),
        status: LoadingStatus.Loaded,
      };
      state.appIsLoading = false;
    },
    [fetchExternalApiFieldIds.rejected]: (state, action) => {
      state.adminFieldIds.externalApiField = {
        status: LoadingStatus.Failed,
      };
      state.appIsLoading = false;
    },
    [generateDepotReports.rejected]: (state, action) => {
      console.log('MDR', 'generateDepotReports.rejected', state, action);
    },
  },
});

export const getAllAdminDepot = (state) => state.adminDepot.adminDepot;
export const getAdminDepotStatus = (state) => state.adminDepot.adminDepotStatus;
export const depotIsLoading = (state) => state.account.appIsLoading;
// export const getJobDataInfo = (state) => state.jobs.adminDepotDataInfo;
export const selectAdminFieldIds = (state) => state.adminDepot.adminFieldIds;
export const selectPayPlanFieldIds = (state) =>
  state.adminDepot.adminFieldIds.payPlanField.data;
export const getAdminFieldIdsStatus = (state) =>
  state.adminDepot.adminFieldStatus;
export const selectPayGradeFieldIds = (state) =>
  state.adminDepot.adminFieldIds.payGradeField.data;
export const selectJobFieldIds = (state) =>
  state.adminDepot.adminFieldIds.jobField.data;
export const selectJobRoleFieldIds = (state) =>
  state.adminDepot.adminFieldIds.jobRoleField.data;
export const selectCapabilityFieldIds = (state) =>
  state.adminDepot.adminFieldIds.capabilityField.data;
export const selectSkillFieldIds = (state) =>
  state.adminDepot.adminFieldIds.skillField.data;
export const selectLevelFieldIds = (state) =>
  state.adminDepot.adminFieldIds.levelField.data;
export const selectEmployeeFieldIds = (state) =>
  state.adminDepot.adminFieldIds.employeeField.data;
export const selectCostCenterFieldIds = (state) =>
  state.adminDepot.adminFieldIds.costCenterField.data;
export const selectOfficeFieldIds = (state) =>
  state.adminDepot.adminFieldIds.officeField.data;
export const selectExternalApiFieldIds = (state) =>
  state.adminDepot.adminFieldIds.externalApiField.data;

export const getPayPlanFieldStatus = (state) =>
  state.adminDepot.adminFieldIds.payPlanField.status;
export const getPayGradeFieldStatus = (state) =>
  state.adminDepot.adminFieldIds.payGradeField.status;
export const getJobFieldStatus = (state) =>
  state.adminDepot.adminFieldIds.jobField.status;
export const getJobRoleFieldStatus = (state) =>
  state.adminDepot.adminFieldIds.jobRoleField.status;
export const getCapabilityFieldStatus = (state) =>
  state.adminDepot.adminFieldIds.capabilityField.status;
export const getSkillFieldStatus = (state) =>
  state.adminDepot.adminFieldIds.skillField.status;
export const getLevelFieldStatus = (state) =>
  state.adminDepot.adminFieldIds.levelField.status;
export const getEmployeeFieldStatus = (state) =>
  state.adminDepot.adminFieldIds.employeeField.status;
export const getCostCenterFieldStatus = (state) =>
  state.adminDepot.adminFieldIds.costCenterField.status;
export const getOfficeFieldStatus = (state) =>
  state.adminDepot.adminFieldIds.officeField.status;
export const getExternalApiFieldStatus = (state) =>
  state.adminDepot.adminFieldIds.externalApiField.status;

export const { resetJobsState } = adminDepotSlice.actions;

export default adminDepotSlice.reducer;
