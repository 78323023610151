export const userHasAccess = ({ activeUser, isAnon, requiredRoles }) => {
  if (isAnon) {
    return true;
  }

  if (!requiredRoles || requiredRoles.length === 0) {
    return true;
  }

  if (
    activeUser &&
    requiredRoles.filter((x) => x === activeUser.role).length > 0
  ) {
    return true;
  }

  return false;
};
