// import DepotIcon from 'assets/depot_icon.png';
import TeamIcon from 'assets/team.svg';
import PersonIcon from 'assets/user_icon.png';
import ReportsIcon from 'assets/reports.svg';
import { ReactComponent as CostCenterTreeMapIcon } from 'assets/treemap.svg';
import GroupTrainingIcon from 'assets/group_train.svg';
import AdminIcon from 'assets/admin.svg';
import AppInfoIcon from 'assets/app_info.svg';
import { Home } from './scenes';
import { LoginForm } from './LoginForm/LoginForm';
import { SystemRoleEnums } from 'helpers';
import { AdminSettings } from './AdminSettings';
import { Teams } from './Teams';
import { Employees } from 'components/Employees';
import { GroupTraining } from './GroupTraining';
import { GenerateReports } from './GenerateReports';
import { CostCenterReports } from './CostCenterReports';
import {
    showAboutModal,
    isAboutModalOpen,
} from 'features/common/actionModalSlice';
import { useSelector } from 'react-redux';
import { BestFitReport } from './BestFitReport';
import BestFitIcon from 'assets/job_fit.svg';
import { DepotReports } from './DepotReports';

export const SceneDefinitions = ({ activeUser, theme }) => [
    {
        key: '01-home',
        menuArea: 'top',
        path: '/',
        // iconSrc: DepotIcon,
        // menuText: 'Depot',
        iconSrc: false,
        menuText: false,
        isAnon: false,
        requiredRoles: [],
        title: activeUser?.depot,
        contentElement: <Home theme={theme} />,
    },
    {
        key: '02-team',
        menuArea: 'top',
        path: '/team',
        iconSrc: TeamIcon,
        svgStyle: 'red',
        menuText: 'Team',
        isAnon: false,
        requiredRoles: [
            ...SystemRoleEnums.EnableAdminRoles,
            SystemRoleEnums.AMC,
        ],
        title: 'Your Team',
        contentElement: <Teams theme={theme} />,
    },
    {
        key: '03-employee-n',
        menuArea: 'top',
        path: '/employee/:id',
        iconSrc: false,
        menuText: false,
        isAnon: false,
        requiredRoles: [],
        title: 'Profile',
        isPagination: true,
        backRoute: '/team',
        contentElement: <Employees />,
    },
    {
        key: '04-employee',
        menuArea: 'top',
        iconSrc: PersonIcon,
        path: '/profile',
        menuText: 'Employee',
        isAnon: false,
        requiredRoles: [SystemRoleEnums.User],
        title: 'Profile',
        isPagination: false,
        contentElement: <Employees />,
    },
    {
        key: '05-expiry-report',
        menuArea: 'top',
        path: '/reports',
        iconSrc: ReportsIcon,
        menuText: 'Reports',
        isAnon: false,
        requiredRoles: [
            ...SystemRoleEnums.EnableAdminRoles,
            SystemRoleEnums.AMC,
        ],
        title: 'Generate Reports',
        contentElement: <GenerateReports />,
    },
    {
        key: '06-cc-tree-map',
        menuArea: 'top',
        path: '/costcenter-report',
        iconSrc: false,
        menuText: false,
        iconElement: CostCenterTreeMapIcon,
        menuText: 'Cost Center Tree Map',
        isAnon: false,
        requiredRoles: SystemRoleEnums.EnableAdminRoles,
        title: 'Cost Center Tree Map',
        contentElement: <CostCenterReports />,
    },
    {
        key: '06-cc-tree-map-n',
        menuArea: 'top',
        path: '/costcenter-report/:id',
        iconSrc: false,
        menuText: false,
        isAnon: false,
        requiredRoles: [SystemRoleEnums.AMC],
        title: 'Cost Center Tree Map',
        isPagination: true,
        backRoute: '/depot-report',
        contentElement: <CostCenterReports />,
    },
    {
        key: '06-depot-reports',
        menuArea: 'top',
        path: '/depot-report',
        iconSrc: false,
        menuText: false,
        iconElement: CostCenterTreeMapIcon,
        menuText: 'Depot Reports',
        isAnon: false,
        requiredRoles: [SystemRoleEnums.AMC],
        title: 'Command Dashboard',
        contentElement: <DepotReports />,
    },
    {
        key: '07-talent-match',
        menuArea: 'top',
        path: '/match',
        iconSrc: BestFitIcon,
        menuText: false,
        menuText: `${activeUser?.label_capability} Match Report`,
        isAnon: false,
        requiredRoles: [
            ...SystemRoleEnums.EnableAdminRoles,
            SystemRoleEnums.AMC,
        ],
        title: `${activeUser?.label_capability} Match`,
        contentElement: <BestFitReport />,
    },
    {
        key: '08-training',
        menuArea: 'top',
        path: '/training',
        iconSrc: GroupTrainingIcon,
        menuText: 'Group Training',
        isAnon: false,
        requiredRoles: SystemRoleEnums.NotUserAndAdmin,
        title: 'Group Training',
        contentElement: <GroupTraining />,
    },
    {
        key: '01-about',
        menuArea: 'bottom',
        clickDispatchEvent: showAboutModal,
        clickDispatchArgs: true,
        isActiveOverride: useSelector(isAboutModalOpen),
        iconSrc: AppInfoIcon,
        menuText: 'About',
        isAnon: false,
        requiredRoles: [],
        title: 'About',
    },
    {
        key: '02-admin',
        menuArea: 'bottom',
        path: '/admin-settings',
        iconSrc: AdminIcon,
        menuText: 'Admin Settings',
        isAnon: false,
        requiredRoles: [
            SystemRoleEnums.BlueGrassAdmin,
            SystemRoleEnums.ProgramManager,
        ],
        title: 'Admin Settings',
        contentElement: <AdminSettings />,
    },
    {
        key: '01-login',
        menuArea: 'none',
        path: '/login',
        isAnon: true,
        contentElement: <LoginForm theme={theme} />,
    },
];
