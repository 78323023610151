import { useCallback, useState } from 'react';
import {
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'notistack';
import { MdClose, MdInfo } from 'react-icons/md';

import {
  activateLoading,
  deactivateLoading,
} from 'features/global/loadingProgressSlice';
import { SnackbarDismiss } from 'shared/components/SnackbarDismiss';
import { panelStyles } from 'components/styles';
import Dropzone from 'react-dropzone';
import { mdTheme } from 'theme';
import { LinkTypes } from 'helpers/enumration';
import { BulkImportResultDisplay } from 'components/BulkImportResultDisplay';
import { snackbarHandler } from 'helpers';
import { resetDropDown } from 'features/AdminSettingsDropDown/dropDownSlice';

const DropZoneImport = ({
  key,
  getEntityImportSample,
  uploadEntityState,
  resetEntitySelector,
  mainTitle,
  buttonText = 'Bulk Import',
  exportFilterData,
  exportEntityState,
  isExport = false,
  getEntityHelp,
  setOpenHelpDialog,
  isInfoIcon = false,
  setDropZoneModalKey = () => {},
}) => {
  //#region Constants
  const dispatch = useDispatch();
  const panelClasses = panelStyles(mdTheme);
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  const [openImportDialog, setOpenImportDialog] = useState(false);
  const [importFile, setImportFile] = useState(null);
  const [importFileName, setImportFileName] = useState('');
  const [lastImportResult, setLastImportResult] = useState(null);
  //#endregion State

  //#region Selectors
  //#endregion Selectors

  //#region Refs
  //#endregion Refs

  //#region Effects
  //#endregion Effects

  //#region Methods
  const getToolsImportCSVSample = async () => {
    dispatch(activateLoading());
    const importResponse = await dispatch(getEntityImportSample());
    if (importResponse?.payload) {
      const { message, variant } = snackbarHandler(
        importResponse?.meta?.requestStatus,
        'CSV Downloaded'
      );
      enqueueSnackbar(message, {
        action: (key) => <SnackbarDismiss key={key} />,
        variant: variant,
      });
    }
    dispatch(deactivateLoading());
    if (importResponse?.error) {
      const { message, variant } = snackbarHandler(
        importResponse?.error?.message,
        'Download CSV'
      );
      enqueueSnackbar(message, {
        action: (key) => <SnackbarDismiss key={key} />,
        variant: variant,
      });
    }
  };

  const onDrop = (file) => {
    setImportFile(file?.[0]);
    setImportFileName(file?.[0]?.name);
  };

  const onDropError = (err) => {
    if (err?.[0]?.errors?.[0]?.message) {
      enqueueSnackbar(err[0].errors[0].message, {
        action: (key) => <SnackbarDismiss key={key} />,
        variant: 'error',
      });
    }
  };

  const handleImport = useCallback(async () => {
    dispatch(activateLoading());
    const response = await dispatch(uploadEntityState({ file: importFile }));
    dispatch(deactivateLoading());
    if (response?.payload) {
      setLastImportResult(response?.payload);
    }
    if (response?.payload?.error?.message) {
      setLastImportResult(null);
      enqueueSnackbar(response?.payload?.error?.message, {
        action: (key) => <SnackbarDismiss key={key} />,
        variant: 'error',
      });
      return;
    }
    if (response.payload?.imported) {
      enqueueSnackbar(
        `${response.payload.imported} ${mainTitle} Imported Successfully`,
        {
          action: (key) => <SnackbarDismiss key={key} />,
          variant: 'success',
        }
      );
      dispatch(resetDropDown());
      dispatch(resetEntitySelector());
    }
    if (
      !(
        response?.payload?.errors &&
        typeof response?.payload?.errors === 'object' &&
        !Array.isArray(response?.payload?.errors) &&
        Object.keys(response?.payload?.errors)?.length > 0
      )
    ) {
      setLastImportResult(null);
      setOpenImportDialog(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importFile]);

  const handleExport = async () => {
    dispatch(activateLoading());
    const exportResponse = await dispatch(
      exportEntityState({
        ...exportFilterData,
      })
    );
    if (exportResponse?.payload) {
      const { message, variant } = snackbarHandler(
        exportResponse?.meta?.requestStatus,
        'CSV Saved'
      );
      enqueueSnackbar(message, {
        action: (key) => <SnackbarDismiss key={key} />,
        variant: variant,
      });
    }
    dispatch(deactivateLoading());
    if (exportResponse?.error) {
      const { message, variant } = snackbarHandler(
        exportResponse?.error?.message,
        'Export CSV'
      );
      enqueueSnackbar(message, {
        action: (key) => <SnackbarDismiss key={key} />,
        variant: variant,
      });
    }
  };

  const handleImportHelp = async () => {
    setOpenHelpDialog(true);
  };
  //#endregion Methods

  //#region Render time calcs
  //#endregion Render time calcs

  //#region Render

  return (
    <>
      <Button
        key={key}
        onClick={() => {
          setImportFile(null);
          setImportFileName('');
          setLastImportResult(null);
          setOpenImportDialog(true);
        }}
        disableElevation
        size="medium"
        color="primary"
        variant="contained"
      >
        {buttonText}
      </Button>
      {openImportDialog === true && (
        <Dialog
          open={openImportDialog}
          onClose={() => {}}
          maxWidth={'md'}
          fullWidth
        >
          <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
            {mainTitle} Bulk Import
            {isInfoIcon && (
              <IconButton
                onClick={() => {
                  setDropZoneModalKey(mainTitle);
                  handleImportHelp();
                }}
              >
                <MdInfo />
              </IconButton>
            )}
            <IconButton
              aria-label="close"
              onClick={() => setOpenImportDialog(false)}
              sx={{
                position: 'absolute',
                right: 8,
                top: 6,
                color: '#000',
                fontSize: '1.4rem',
              }}
              size="large"
            >
              <MdClose />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText marginBottom={'5px'}>
              Upload CSV to Import {mainTitle} in Bulk
            </DialogContentText>
            <div className={panelClasses.dropzoneContainer}>
              <Dropzone
                accept={['text/csv']}
                maxFiles={1}
                maxSize={LinkTypes.Document.fileSizeLimit}
                multiple={false}
                onDropAccepted={onDrop}
                onDropRejected={onDropError}
              >
                {({ getRootProps, getInputProps }) => (
                  <div
                    {...getRootProps()}
                    className={`${panelClasses.dropzoneArea} ${panelClasses.dropZoneMinHeight50}`}
                    style={{
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'contain',
                      backgroundPosition: 'center center',
                    }}
                  >
                    <input {...getInputProps()} />
                    <div className={panelClasses.dropzoneText}>
                      Drop/Select Csv File
                    </div>
                  </div>
                )}
              </Dropzone>
              <div className={panelClasses.previewContainer}>
                {importFile && importFileName && (
                  <Chip
                    className={panelClasses.previewUploadFileName}
                    color="secondary"
                    size="small"
                    deleteIcon={<MdClose />}
                    onDelete={() => {
                      setImportFile(null);
                      setImportFileName('');
                      setLastImportResult(null);
                    }}
                    label={importFileName}
                  />
                )}
              </div>
            </div>
            {lastImportResult && (
              <BulkImportResultDisplay result={lastImportResult} />
            )}
            <Button
              onClick={getToolsImportCSVSample}
              fullWidth
              variant="contained"
              size="medium"
              sx={{ marginTop: '1rem' }}
            >
              DOWNLOAD SAMPLE CSV
            </Button>
            <Button
              onClick={handleImport}
              variant="contained"
              fullWidth
              disabled={Boolean(!importFile)}
              sx={{ marginTop: '0.5rem' }}
            >
              IMPORT
            </Button>
            {isExport && (
              <Button
                onClick={handleExport}
                variant="contained"
                fullWidth
                sx={{ marginTop: '0.5rem' }}
              >
                Export
              </Button>
            )}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
  //#endregion Render
};
export { DropZoneImport };
