import { useStyles } from './styles';
import { useDispatch } from 'react-redux';
import { showActionModal } from 'features/common/actionModalSlice';
import { openPanel, setEditObjectId } from 'features/common/formSlice';
import getActionIcon from 'helpers/getActionIcon';
import { useNavigate } from 'react-router-dom';

function PopupAction({
  action,
  object,
  level,
  showLabel,
  onActionHandled,
  activeUser = null,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleActionModal = () => {
    dispatch(
      showActionModal({
        isOpen: true,
        object: object,
        action: action,
        level: level,
      })
    );
  };

  const handleAction = () => {
    switch (action.label) {
      case 'createContentVersion':
        // dispatch(showContentVersionCreatePanel({content: object}))
        dispatch(
          setEditObjectId({
            formKey: `itemPayPlanForm`,
            object_id: object['tag_id'],
          })
        );
        dispatch(
          setEditObjectId({
            formKey: `itemPayGradeForm`,
            object_id: object['tag_id'],
          })
        );
        break;
      case 'delete':
        handleActionModal();
        break;
      case 'deactivate':
        handleActionModal();
        break;
      case 'edit':
        if (level === 'payPlan') {
          dispatch(
            setEditObjectId({
              formKey: `itemPayPlanForm`,
              object_id: object['pay_plan_id'],
            })
          );
          dispatch(
            openPanel({
              formKey: 'itemPayPlanForm',
              formAction: 'Edit',
            })
          );
        } else if (level === 'payGrades') {
          dispatch(
            setEditObjectId({
              formKey: `itemPayGradeForm`,
              object_id: object['pay_grade_id'],
            })
          );
          dispatch(
            openPanel({
              formKey: 'itemPayGradeForm',
              formAction: 'Edit',
            })
          );
        } else if (level === 'skill') {
          dispatch(
            setEditObjectId({
              formKey: `itemSkillForm`,
              object_id: object['skill_id'],
            })
          );
          dispatch(
            openPanel({
              formKey: 'itemSkillForm',
              formAction: 'Edit',
            })
          );
        } else if (level === 'capability') {
          dispatch(
            setEditObjectId({
              formKey: `itemCapabilityForm`,
              object_id: object['capability_id'],
            })
          );
          dispatch(
            openPanel({
              formKey: 'itemCapabilityForm',
              formAction: 'Edit',
            })
          );
        } else if (level === 'job') {
          dispatch(
            setEditObjectId({
              formKey: `itemJobForm`,
              object_id: object['job_id'],
            })
          );
          dispatch(
            openPanel({
              formKey: 'itemJobForm',
              formAction: 'Edit',
            })
          );
        } else if (level === 'jobRole') {
          dispatch(
            setEditObjectId({
              formKey: `itemJobRoleForm`,
              object_id: object['job_role_id'],
            })
          );
          dispatch(
            openPanel({
              formKey: 'itemJobRoleForm',
              formAction: 'Edit',
            })
          );
        } else if (level === 'adminLevel') {
          dispatch(
            setEditObjectId({
              formKey: `itemLevelForm`,
              object_id: object['level_id'],
            })
          );
          dispatch(
            openPanel({
              formKey: 'itemLevelForm',
              formAction: 'Edit',
            })
          );
        } else if (level === 'adminEmployee') {
          dispatch(
            setEditObjectId({
              formKey: `itemEmployeeForm`,
              object_id: object['employee_id'],
            })
          );
          dispatch(
            openPanel({
              formKey: 'itemEmployeeForm',
              formAction: 'Edit',
            })
          );
        } else if (level === 'costCenter') {
          dispatch(
            setEditObjectId({
              formKey: `itemCostCenterForm`,
              object_id: object['cost_center_id'],
            })
          );
          dispatch(
            openPanel({
              formKey: 'itemCostCenterForm',
              formAction: 'Edit',
            })
          );
        } else if (level === 'externalApi') {
          dispatch(
            setEditObjectId({
              formKey: `itemExternalApiForm`,
              object_id: object['api_client_id'],
            })
          );
          dispatch(
            openPanel({
              formKey: 'itemExternalApiForm',
              formAction: 'Edit',
            })
          );
        } else if (level === 'office') {
          dispatch(
            setEditObjectId({
              formKey: `itemOfficeForm`,
              object_id: object['office_id'],
            })
          );
          dispatch(
            openPanel({
              formKey: 'itemOfficeForm',
              formAction: 'Edit',
            })
          );
        }

        //}
        break;
      case 'view':
        navigate(`/employee/${object['employee_id']}`);
        break;
      case 'publish':
      case 'publishArray':
      case 'deleteArray':
      case 'reactivate':
      case 'duplicate':
        handleActionModal();
        break;
      case 'AddCapability':
        dispatch(
          setEditObjectId({
            formKey: `itemAssociateSkillForm`,
            object_id: object['skill_id'],
          })
        );
        dispatch(
          openPanel({
            formKey: 'itemAssociateSkillForm',
            formAction: 'Edit',
          })
        );
        break;
      case 'AddSkills':
        dispatch(
          setEditObjectId({
            formKey: `itemAssociateCapabilityForm`,
            object_id: object['capability_id'],
          })
        );
        dispatch(
          openPanel({
            formKey: 'itemAssociateCapabilityForm',
            formAction: 'Create',
          })
        );
        break;
      case 'AddJobRoles':
        dispatch(
          setEditObjectId({
            formKey: `associateCapabilityJobRoleForm`,
            object_id: object['capability_id'],
          })
        );
        dispatch(
          openPanel({
            formKey: 'associateCapabilityJobRoleForm',
            formAction: 'Edit',
          })
        );
        break;
      case 'AddJobRoleCapability':
        dispatch(
          setEditObjectId({
            formKey: `associateJobRoleFormCapability`,
            object_id: object['job_role_id'],
          })
        );
        dispatch(
          openPanel({
            formKey: 'associateJobRoleFormCapability',
            formAction: 'Create',
          })
        );
        break;
      default:
        break;
    }
    if (onActionHandled) {
      onActionHandled();
    }
  };

  return (
    <div className={classes.popupActionContainer} onClick={handleAction}>
      <div className={classes.popupActionIcon}>
        {getActionIcon(action.iconId)}
      </div>
      {showLabel && (
        <div className={classes.popupActionLabel}>{action.displayLabel}</div>
      )}
    </div>
  );
}

export { PopupAction };
