import { createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const headerStyles = makeStyles((theme) => {
  return createStyles({
    logoutContainer: {
      display: 'flex',
      padding: '0px 4px',
    },
    responsiveImg: {
      display: 'block',
      maxWidth: '100%',
      height: 'auto',
    },
  });
});

export { headerStyles };
