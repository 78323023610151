import React from 'react';
import { Action } from 'helpers';
import { PopupAction } from '../PopupAction';

function JobRoleManagementPopup({ object, level, onClose }) {
  return (
    <React.Fragment>
      <PopupAction
        action={Action.Edit}
        object={object}
        level={level}
        showLabel={true}
        onActionHandled={onClose}
      />
      <PopupAction
        action={Action.AddJobRoleCapability}
        object={object}
        level={level}
        showLabel={true}
        onActionHandled={onClose}
      />
      <PopupAction
        action={Action.Delete}
        object={object}
        level={level}
        showLabel={true}
        onActionHandled={onClose}
      />
    </React.Fragment>
  );
}

export { JobRoleManagementPopup };
