import { useState, useEffect, useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { Button, Typography } from '@mui/material';

import { FormActions } from 'helpers/form-action';
import { makeFormStyles } from 'forms/style';
import {
  activateLoading,
  deactivateLoading,
} from 'features/global/loadingProgressSlice';
import { snackbarHandler } from 'helpers';
import { SnackbarDismiss } from 'shared/components/SnackbarDismiss';
import Loader from 'shared/components/Loader';

import { closePanel } from 'features/common/formSlice';
import {
  createLevel,
  fetchLevelById,
  fetchLevels,
  updateLevel,
} from 'features/Levels/levelSlice';
import { SelectionAutoComplete } from 'shared/components/_Autocomplete/SelectionAutocomplete';
import {
  fetchLevelsDropDown,
  getLevelDropDownStatus,
  resetDropDownState,
  selectLevelDropDown,
} from 'features/AdminSettingsDropDown/dropDownSlice';
import { FormTextField } from 'shared/components/FormFields/TextField';
import AuditInfoCard from 'shared/components/_AuditInfoCard';

const ItemLevelForm = ({ formAction, itemTagId, auditHeader }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  // eslint-disable-next-line no-unused-vars
  const [loaded, setLoaded] = useState(
    formAction.id === FormActions.Create.id ? true : false
  );
  const [auditInfo, setAuditInfo] = useState(null);
  const classes = makeFormStyles();
  // eslint-disable-next-line no-unused-vars
  const [initValues, setInitValues] = useState({
    display_name: '',
    description: '',
    color: '#000000',
    previous_level_id: null,
  });
  const tagNameRef = useRef(null);

  const removePreviousLevelId = async (setFieldValue) => {
    setFieldValue('previous_level_id', null);
  };

  useEffect(() => {
    if (formAction.id === FormActions.Edit.id) {
      const fetchData = async () => {
        var objectData = await dispatch(fetchLevelById(itemTagId));
        var fetchedObject = objectData.payload;
        setInitValues({
          display_name: fetchedObject?.display_name,
          description: fetchedObject?.description,
          color: fetchedObject?.color,
          previous_level_id: fetchedObject?.previous_level_id,
        });
        const { created_by, created_time, modified_time } = fetchedObject;
        setAuditInfo({
          author: created_by,
          creation: created_time,
          modified: modified_time,
        });
        setLoaded(true);
      };
      fetchData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      dispatch(resetDropDownState());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = async (values, setSubmitting, resetForm) => {
    dispatch(activateLoading({ showProgress: true }));
    var resultAction = null;
    switch (formAction.id) {
      case FormActions.Edit.id:
        resultAction = await dispatch(
          updateLevel({
            level_id: itemTagId,
            ...values,
          })
        );
        if (!resultAction.error) {
          setInitValues({
            ...initValues,
            display_name: resultAction?.payload?.display_name,
            description: resultAction?.payload?.description,
            color: resultAction?.payload?.color,
            previous_level_id: resultAction?.payload?.previous_level_id,
          });
          dispatch(closePanel({ formKey: `itemLevelForm` }));
          await dispatch(fetchLevelsDropDown());
        }
        break;
      case FormActions.Create.id:
        resultAction = await dispatch(createLevel({ ...values }));
        if (!resultAction.error) {
          await dispatch(fetchLevels());
          await dispatch(fetchLevelsDropDown());
          resetForm();
          dispatch(closePanel({ formKey: `itemLevelForm` }));
          if (tagNameRef.current) {
            tagNameRef.current.focus();
          }
        }
        break;
      default:
        break;
    }

    dispatch(deactivateLoading());
    dispatch(resetDropDownState());
    const { message, variant } = snackbarHandler(
      resultAction.meta.requestStatus,
      formAction.label
    );
    enqueueSnackbar(message, {
      action: (key) => <SnackbarDismiss key={key} />,
      variant: variant,
    });
    setSubmitting(false);
  };

  return !loaded ? (
    <Loader styleClassName={classes.loadingSvg} />
  ) : (
    <Formik
      enableReinitialize={true}
      initialValues={{ ...initValues }}
      validationSchema={Yup.object({
        display_name: Yup.string().required('Required'),
      })}
      validateOnBlur={false}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        handleSubmit(values, setSubmitting, resetForm);
      }}
    >
      {({ isSubmitting, errors }) => (
        <Form className={classes.form}>
          <FormTextField
            autoFocus
            inputRef={tagNameRef}
            label="Display Name"
            name="display_name"
            placeholder="Display Name"
          />
          <FormTextField
            label="Description"
            name="description"
            placeholder="Description"
            multiline
            rows={3}
          />
          <FormTextField
            type="color"
            name="color"
            label="Select Color"
            placeholder="Select Color"
          />
          <Field className={classes.input} name="previous_level_id">
            {({ field: { value }, form: { setFieldValue } }) => (
              <SelectionAutoComplete
                title="Previous Level Id"
                keyProperty="value"
                nameProperty="text"
                entityIds={value}
                setEntityIds={setFieldValue}
                entitySelector={selectLevelDropDown}
                entityStatusSelector={getLevelDropDownStatus}
                fetchEntityPage={fetchLevelsDropDown}
                formField={'previous_level_id'}
                multiSelection={false}
                cancelItemTagEdit={() => {
                  removePreviousLevelId(setFieldValue);
                }}
                showCloseItemEdit={true}
                hideCheckIcon={true}
              />
            )}
          </Field>
          {errors?.previous_level_id && (
            <Typography variant="subtitle2" className={classes.inputErr}>
              {errors.previous_level_id}
            </Typography>
          )}
          <AuditInfoCard
            auditInfo={auditInfo}
            auditInfoHeader={auditHeader}
            formAction={formAction}
          />
          <Button
            size="large"
            variant="contained"
            color="primary"
            fullWidth
            type="submit"
            disabled={isSubmitting}
            sx={{ marginTop: '0.5rem' }}
          >
            {formAction.buttonLabel}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export { ItemLevelForm };
