import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Grid } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { FilterAccordion } from 'shared/components/_Accordion';
import { SelectionAutoComplete } from 'shared/components/_Autocomplete/SelectionAutocomplete';
import {
  fetchCostCenterDropDown,
  getCostCenterDropDownStatus,
  selectCostCenterDropDown,
} from 'features/AdminSettingsDropDown/dropDownSlice';
import { clearFilter } from 'helpers/clearFilter';
import {
  selectAdminSettingCache,
  updateAdminSettingCache,
} from 'features/global/visibilitySlice';
import { useDebounce } from 'hooks/useDebounce';
import { OFFICE_CACHE } from 'helpers';
import { FormTextField } from 'shared/components/FormFields/TextField';

const ItemOfficeFilter = ({ setItemOfficePayload, itemOfficePayload }) => {
  //#region Constants
  const getCacheData = useSelector(selectAdminSettingCache);
  const officeCacheData = getCacheData?.[OFFICE_CACHE];
  const dispatch = useDispatch();
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  const [initValues, setInitValues] = useState({
    cost_center_id: null,
    directorate: '',
    symbol: '',
  });
  //#endregion State

  //#region Selectors
  useEffect(() => {
    setInitValues({ ...initValues, ...officeCacheData });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCacheData, itemOfficePayload]);
  //#endregion Selectors

  //#region Refs
  const formRef = useRef();
  //#endregion Refs

  //#region Effects
  //#endregion Effects

  //#region Methods
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateFields = useCallback(
    useDebounce((costCenterId, directorate, symbol) => {
      setItemOfficePayload({
        ...itemOfficePayload,
        cost_center_id: costCenterId,
        directorate: directorate,
        symbol: symbol,
      });
    }, 1000),
    [itemOfficePayload]
  );

  const handleResetFilters = () => {
    const isFilterData = clearFilter(formRef);
    if (isFilterData) {
      formRef?.current?.resetForm();
      setItemOfficePayload({
        ...itemOfficePayload,
        cost_center_id: null,
        directorate: '',
        symbol: '',
      });
      dispatch(updateAdminSettingCache({ key: OFFICE_CACHE, ...{} }));
    }
  };
  //#endregion Methods

  //#region Render time calcs
  //#endregion Render time calcs

  //#region Render

  return (
    <FilterAccordion
      filteredTitles={itemOfficePayload}
      expandedId="pay-plan"
      styles={{ marginBottom: '1.5rem' }}
      onResetFilters={handleResetFilters}
    >
      <Formik
        enableReinitialize={true}
        initialValues={{ ...initValues }}
        innerRef={formRef}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <Grid container columnSpacing={'1rem'}>
              <Grid item={true} xs={12} md={6} lg={3}>
                <Field name="cost_center_id">
                  {({ field: { value }, form: { setFieldValue } }) => (
                    <SelectionAutoComplete
                      title="Select Cost Center"
                      keyProperty="value"
                      nameProperty="text"
                      entityIds={value?.value}
                      setEntityIds={setFieldValue}
                      onCustomChange={(value) => {
                        setFieldValue('cost_center_id', value);
                        updateFields(
                          value,
                          values?.directorate,
                          values?.symbol
                        );
                      }}
                      entitySelector={selectCostCenterDropDown}
                      entityStatusSelector={getCostCenterDropDownStatus}
                      fetchEntityPage={fetchCostCenterDropDown}
                      formField={'cost_center_id'}
                      multiSelection={false}
                      showCloseItemEdit={true}
                      hideCheckIcon={true}
                      cancelItemTagEdit={() => {
                        setFieldValue('cost_center_id', null);
                        updateFields(null, values?.directorate, values?.symbol);
                      }}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item={true} xs={12} md={6} lg={3}>
                <FormTextField
                  name="directorate"
                  label="Directorate"
                  placeholder="Directorate"
                  fullWidth
                  onChange={(e) => {
                    const searchTerm = e.target.value;
                    setFieldValue('directorate', searchTerm);
                    updateFields(
                      values?.cost_center_id,
                      searchTerm,
                      values?.symbol
                    );
                  }}
                />
              </Grid>
              <Grid item={true} xs={12} md={6} lg={3}>
                <FormTextField
                  name="symbol"
                  label="Office Symbol"
                  placeholder="Office Symbol"
                  fullWidth
                  onChange={(e) => {
                    const searchTerm = e.target.value;
                    setFieldValue('symbol', searchTerm);
                    updateFields(
                      values?.cost_center_id,
                      values?.directorate,
                      searchTerm
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </FilterAccordion>
  );
  //#endregion Render
};
export { ItemOfficeFilter };
