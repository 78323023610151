import { config } from 'ectl-config';
import { getNetworkService } from 'helpers';
import { buildFilters } from 'hooks/buildFilter';

const accountService = { config: config };
const networkService = getNetworkService({
  config: config,
  checkAuth: accountService.checkAuth,
});
const baseUrl = `${config.apiUrl}/GroupTraining`;

const ERROR_MESSAGES = {
  INVALID_PARAMETERS: 'Invalid parameters provided.',
  NETWORK_ERROR: 'Network request failed.',
};

const getGroupTraining = async (
  capabilityId = null,
  skill_ids = null,
  job_id = null,
  job_role_id = null,
  employee_name = '',
  employee_dod_id = null,
  series_id = null,
  job_role = '',
  supervisor_name = '',
  cost_center = null,
  directorate = '',
  include_employee_ids = [],
  sort = 'employee_name',
  startIndex = 0,
  stopIndex = 10
) => {
  try {
    const filters = [];
    if (skill_ids?.length > 0) {
      filters.push(`capability_skill_ids==${skill_ids.join('|')}`);
    }
    if (include_employee_ids?.length > 0) {
      filters.push(`include_employee_ids==${include_employee_ids.join('|')}`);
    }
    if (employee_name) {
      filters.push(`employee_name@=*${employee_name}`);
    }
    if (supervisor_name) {
      filters.push(`supervisor_name@=*${supervisor_name}`);
    }
    if (directorate) {
      filters.push(`directorate@=*${directorate}`);
    }
    if (job_role) {
      filters.push(`job_role@=*${job_role}`);
    }
    if (job_id) {
      filters.push(`job_id==${job_id}`);
    }
    if (job_role_id) {
      filters.push(`job_role_id==${job_role_id}`);
    }
    if (cost_center) {
      filters.push(`cost_center==${cost_center}`);
    }
    if (
      employee_dod_id !== null &&
      employee_dod_id !== undefined &&
      employee_dod_id !== ''
    ) {
      filters.push(`employee_dod_id==${employee_dod_id}`);
    }
    if (series_id !== null && series_id !== undefined && series_id !== '') {
      filters.push(`series_id==${series_id}`);
    }

    const params = {
      filters: filters,
      sorts: sort,
      start_index: startIndex,
      stop_index: stopIndex,
    };

    const query = new URLSearchParams(params);

    const response = await networkService.get(
      `${baseUrl}/${capabilityId}?${query}`
    );

    const pageInfo = response.headers['x-pagination'];
    return {
      pageInfo: pageInfo,
      groupTraining: response?.data,
    };
  } catch (error) {
    console.error('Error in getGroupTraining:', error);

    if (error.response) {
      console.error('Server error:', error.response.data);
    } else if (error.request) {
      console.error('No response received:', error.request);
    } else {
      console.error('Error during request setup:', error.message);
    }

    throw new Error(ERROR_MESSAGES.NETWORK_ERROR);
  }
};

const getGroupTrainingSkills = async (capabilityId, completion_date) => {
  try {
    let params = '';
    if (completion_date) {
      params = { completion_date: completion_date };
    }

    const query = new URLSearchParams(params);

    const response = await networkService.get(
      `${baseUrl}/skills/${capabilityId}?${query}`
    );
    return response.data;
  } catch (error) {
    console.error('Error in getGroupTraining Skill:', error);
  }
};

const update = async (params) => {
  const response = await networkService.post(baseUrl, params);

  if (response.error) {
    return Promise.reject(response.error);
  }

  return response.data;
};

export const groupTrainingService = {
  getGroupTraining,
  getGroupTrainingSkills,
  update,
};
