import { useCallback, useRef, useState } from 'react';
import { Grid, TextField } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { makeFormStyles } from 'forms/style';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import {
  fetchCapabilityDropDown,
  fetchCommandDropDown,
  fetchCostCenterDropDown,
  fetchDepotDropDown,
  fetchJobRolesByJobId,
  fetchJobsDropDown,
  fetchOfficeByCostCenterId,
  fetchReportExpirationDropDown,
  fetchSuperVisorDropDown,
  getCapabilityDropDownStatus,
  getCommandDropDownStatus,
  getCostCenterDropDownStatus,
  getDepotDropDownStatus,
  getJobDropDownStatus,
  getJobRolesByJobIdDropDownStatus,
  getOfficeByCostCenterIdDropDownStatus,
  getReportExpiryDropDownStatus,
  getSuperVisorDropDownStatus,
  resetCommandDropDownState,
  resetDepotDropDownState,
  resetJobRoleDropdown,
  resetOfficeIdDropDown,
  selectCapabilitiesDropDown,
  selectCommondDropDown,
  selectCostCenterDropDown,
  selectDepotDropDown,
  selectJobDropDown,
  selectJobRoleByJobIdDropDown,
  selectOfficeByCostCenterIdDropDown,
  selectReportExpiryDropDown,
  selectSuperVisorDropDown,
} from 'features/AdminSettingsDropDown/dropDownSlice';
import { SelectionAutoComplete } from 'shared/components/_Autocomplete/SelectionAutocomplete';
import { selectActiveUser } from 'features/account/accountSlice';
import { FilterAccordion } from 'shared/components/_Accordion';
import { clearFilter } from 'helpers/clearFilter';
import { useDebounce } from 'hooks/useDebounce';
import { SystemRoleEnums } from 'helpers';

const DepotReportFilter = ({
  costCenterReportData,
  setCostCenterReportData,
  itemDepotReportPayload,
  setItemDepotReportPayload,
}) => {
  //#region Constants
  const classes = makeFormStyles();
  const dispatch = useDispatch();
  const { role } = useSelector(selectActiveUser);
  //#endregion Constants

  //#region Hooks
  //#endregion Hooks

  //#region State
  const initValues = {
    command_id: null,
    depot_id: null,
    cost_center_id: null,
    office_id: null,
    job_id: null,
    job_role_id: null,
    report_expiry: null,
    custom_expiry_date: null,
    supervisor_employee_id: null,
    capability_id: null,
  };
  const [createdDateFilter, setCreatedDateFilter] = useState(null);
  //#endregion State

  //#region Selectors
  const user = useSelector(selectActiveUser);
  //#endregion Selectors

  //#region Refs
  const formRef = useRef();
  //#endregion Refs

  //#region Effects
  //#endregion Effects

  //#region Methods
  const handleSearch = useDebounce(
    (
      command_id,
      depot_id,
      costCenterId,
      officeId,
      jobId,
      jobRoleId,
      reportExpiry,
      supervisorEmployeeId,
      capabilityId
    ) => {
      setItemDepotReportPayload({
        ...itemDepotReportPayload,
        command_id: command_id?.value,
        depot_id: depot_id?.value,
        cost_center_id: costCenterId?.value,
        office_id: officeId?.value,
        job_id: jobId?.value,
        job_role_id: jobRoleId?.value,
        report_expiry: reportExpiry?.value,
        supervisor_employee_id: supervisorEmployeeId?.value,
        capability_id: capabilityId?.value,
      });
      setCostCenterReportData({
        ...costCenterReportData,
        command_id: {
          text: command_id?.text,
        },
        depot_id: {
          text: depot_id?.text,
        },
        cost_center_id: {
          text: costCenterId?.text,
        },
        office_id: {
          text: officeId?.text,
        },
        job_id: { text: jobId?.text },
        job_role_id: { text: jobRoleId?.text },
        report_expiry: { text: reportExpiry?.text },
        supervisor_employee_id: {
          text: supervisorEmployeeId?.text,
        },
        capability_id: {
          text: capabilityId?.text,
        },
      });
      if (reportExpiry?.value !== 1) {
        setCreatedDateFilter(null);
        setItemDepotReportPayload((prevValue) => ({
          ...prevValue,
          custom_expiry_date: null,
          display_date: null,
        }));
        setCostCenterReportData((prevValue) => ({
          ...prevValue,
          display_date: null,
        }));
      }
    },
    1000
  );

  const removeHandleSearch = useDebounce(
    (
      command_id,
      depot_id,
      costCenterId,
      officeId,
      jobId,
      jobRoleId,
      reportExpiry,
      supervisorEmployeeId,
      capabilityId
    ) => {
      setItemDepotReportPayload({
        ...itemDepotReportPayload,
        command_id: command_id?.value,
        depot_id: depot_id?.value,
        cost_center_id: costCenterId?.value,
        office_id: officeId?.value,
        job_id: jobId?.value,
        job_role_id: jobRoleId?.value,
        report_expiry: reportExpiry?.value,
        supervisor_employee_id: supervisorEmployeeId?.value,
        capability_id: capabilityId?.value,
      });
      setCostCenterReportData({
        ...costCenterReportData,
        command_id: {
          text: command_id?.text,
        },
        depot_id: {
          text: depot_id?.text,
        },
        cost_center_id: {
          text: costCenterId?.text,
        },
        office_id: {
          text: officeId?.text,
        },
        job_id: { text: jobId?.text },
        job_role_id: { text: jobRoleId?.text },
        report_expiry: { text: reportExpiry?.text },
        supervisor_employee_id: {
          text: supervisorEmployeeId?.text,
        },
        capability_id: {
          text: capabilityId?.text,
        },
      });
      if (reportExpiry?.value !== 1) {
        setCreatedDateFilter(null);
        setItemDepotReportPayload((prevValue) => ({
          ...prevValue,
          custom_expiry_date: null,
          display_date: null,
        }));
        setCostCenterReportData((prevValue) => ({
          ...prevValue,
          display_date: null,
        }));
      }

      if (!costCenterId?.value) {
        setItemDepotReportPayload((prevValue) => ({
          ...prevValue,
          office_id: null,
        }));
        setCostCenterReportData((prevValue) => ({
          ...prevValue,
          office_id: null,
        }));
      }
    },
    1000
  );

  const debouncedSearch = useCallback(
    useDebounce((createdDate, setFieldValue, report_expiry) => {
      setFieldValue('report_expiry', report_expiry);
      if (createdDate) {
        setItemDepotReportPayload((prevValue) => ({
          ...prevValue,
          custom_expiry_date:
            createdDate && moment(createdDate).format('YYYY-MM-DD'),
          display_date: {
            text: createdDate && moment(createdDate).format('YYYY-MM-DD'),
          },
          report_expiry: report_expiry?.value,
        }));
        setCostCenterReportData((prevValue) => ({
          ...prevValue,
          custom_expiry_date:
            createdDate && moment(createdDate).format('YYYY-MM-DD'),
          display_date: {
            text: createdDate && moment(createdDate).format('YYYY-MM-DD'),
          },
          report_expiry: { text: report_expiry?.text },
        }));
      }
    }, 1000),
    [itemDepotReportPayload]
  );

  const handleResetFilters = () => {
    const isFilterData = clearFilter(formRef);
    if (isFilterData) {
      formRef?.current?.resetForm();
      setItemDepotReportPayload({
        ...itemDepotReportPayload,
        command_id: null,
        depot_id: null,
        cost_center_id: null,
        office_id: null,
        job_id: null,
        job_role_id: null,
        report_expiry: null,
        custom_expiry_date: null,
        supervisor_employee_id: null,
        capability_id: null,
      });
      setCostCenterReportData({});
      dispatch(resetOfficeIdDropDown());
      dispatch(fetchJobRolesByJobId());
      dispatch(resetDepotDropDownState());
      dispatch(resetCommandDropDownState());
    }
  };
  //#endregion Methods

  //#region Render time calcs
  //#endregion Render time calcs

  //#region Render

  return (
    <FilterAccordion
      filteredTitles={costCenterReportData}
      expandedId="capability-filter"
      styles={{ marginBottom: '1.5rem' }}
      onResetFilters={handleResetFilters}
    >
      <Formik
        enableReinitialize={true}
        initialValues={{ ...initValues }}
        innerRef={formRef}
      >
        {({ values, setFieldValue }) => (
          <Form className={classes.form} style={{ padding: '0' }}>
            <Grid container spacing={'1rem'}>
              {role === SystemRoleEnums.AMC && (
                <>
                  <Grid
                    item={true}
                    xs={12}
                    md={4}
                    lg={3}
                    className={classes.customSelect}
                  >
                    <Field className={classes.input} name="command_id">
                      {({ field: { value }, form: { setFieldValue } }) => (
                        <SelectionAutoComplete
                          title="Select Command"
                          keyProperty="value"
                          nameProperty="text"
                          entityIds={value?.value}
                          setEntityIds={setFieldValue}
                          onCustomChange={(value) => {
                            setFieldValue('command_id', value);
                            setFieldValue('depot_id', null);
                            handleSearch(
                              value,
                              null,
                              values?.cost_center_id,
                              values?.job_id,
                              values?.job_role_id,
                              values?.report_expiry,
                              values?.supervisor_employee_id,
                              values?.capability_id
                            );
                            dispatch(resetDepotDropDownState());
                          }}
                          entitySelector={selectCommondDropDown}
                          entityStatusSelector={getCommandDropDownStatus}
                          fetchEntityPage={fetchCommandDropDown}
                          multiSelection={false}
                          showCloseItemEdit={true}
                          hideCheckIcon={true}
                          cancelItemTagEdit={() => {
                            setFieldValue('command_id', null);
                            setFieldValue('depot_id', null);
                            removeHandleSearch(
                              null,
                              null,
                              values?.cost_center_id,
                              values?.job_id,
                              values?.job_role_id,
                              values?.report_expiry,
                              values?.supervisor_employee_id,
                              values?.capability_id
                            );
                            dispatch(resetDepotDropDownState());
                          }}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid
                    item={true}
                    xs={12}
                    md={4}
                    lg={3}
                    className={classes.customSelect}
                  >
                    <Field className={classes.input} name="depot_id">
                      {({ field: { value }, form: { setFieldValue } }) => (
                        <SelectionAutoComplete
                          title="Select Depot"
                          keyProperty="value"
                          nameProperty="text"
                          entityIds={value?.value}
                          setEntityIds={setFieldValue}
                          onCustomChange={(value) => {
                            setFieldValue('depot_id', value);
                            handleSearch(
                              values?.command_id,
                              value,
                              values?.cost_center_id,
                              values?.job_id,
                              values?.job_role_id,
                              values?.report_expiry,
                              values?.supervisor_employee_id,
                              values?.capability_id
                            );
                          }}
                          entitySelector={selectDepotDropDown}
                          entityStatusSelector={getDepotDropDownStatus}
                          fetchEntityPage={fetchDepotDropDown}
                          formField={'depot_id'}
                          multiSelection={false}
                          showCloseItemEdit={true}
                          hideCheckIcon={true}
                          cancelItemTagEdit={() => {
                            setFieldValue('depot_id', null);
                            removeHandleSearch(
                              values?.command_id,
                              null,
                              values?.cost_center_id,
                              values?.job_id,
                              values?.job_role_id,
                              values?.report_expiry,
                              values?.supervisor_employee_id,
                              values?.capability_id
                            );
                          }}
                          fetchParams={{
                            command_id: values?.command_id?.value,
                          }}
                        />
                      )}
                    </Field>
                  </Grid>
                </>
              )}

              <Grid
                item={true}
                xs={12}
                md={4}
                lg={3}
                className={classes.customSelect}
              >
                <Field className={classes.input} name="cost_center_id">
                  {({ field: { value }, form: { setFieldValue } }) => (
                    <SelectionAutoComplete
                      title="Select Cost Center"
                      keyProperty="value"
                      nameProperty="text"
                      entityIds={value?.value}
                      setEntityIds={setFieldValue}
                      onCustomChange={(value) => {
                        setFieldValue('cost_center_id', value);
                        setFieldValue('office_id', null);
                        handleSearch(
                          values?.command_id,
                          values?.depot_id,
                          value,
                          null,
                          values?.job_id,
                          values?.job_role_id,
                          values?.report_expiry,
                          values?.supervisor_employee_id,
                          values?.capability_id
                        );
                        dispatch(resetOfficeIdDropDown());
                      }}
                      entitySelector={selectCostCenterDropDown}
                      entityStatusSelector={getCostCenterDropDownStatus}
                      fetchEntityPage={fetchCostCenterDropDown}
                      formField={'cost_center_id'}
                      multiSelection={false}
                      showCloseItemEdit={true}
                      hideCheckIcon={true}
                      cancelItemTagEdit={() => {
                        setFieldValue('cost_center_id', null);
                        setFieldValue('office_id', null);
                        removeHandleSearch(
                          values?.command_id,
                          values?.depot_id,
                          null,
                          null,
                          values?.job_id,
                          values?.job_role_id,
                          values?.report_expiry,
                          values?.supervisor_employee_id,
                          values?.capability_id
                        );
                        dispatch(resetOfficeIdDropDown());
                      }}
                    />
                  )}
                </Field>
              </Grid>
              <Grid
                item={true}
                xs={12}
                md={4}
                lg={3}
                className={classes.customSelect}
              >
                <Field name="office_id">
                  {({ field: { value }, form: { setFieldValue } }) => (
                    <SelectionAutoComplete
                      title={'Select Office'}
                      keyProperty="value"
                      nameProperty="text"
                      entityIds={value?.value}
                      setEntityIds={setFieldValue}
                      entitySelector={selectOfficeByCostCenterIdDropDown}
                      entityStatusSelector={
                        getOfficeByCostCenterIdDropDownStatus
                      }
                      onCustomChange={(value) => {
                        setFieldValue('office_id', value);
                        handleSearch(
                          values?.command_id,
                          values?.depot_id,
                          values?.cost_center_id,
                          value,
                          values?.job_id,
                          values?.report_expiry,
                          values?.supervisor_employee_id,
                          values?.capability_id
                        );
                      }}
                      fetchEntityPage={fetchOfficeByCostCenterId}
                      cancelItemTagEdit={() => {
                        setFieldValue('office_id', null);
                        removeHandleSearch(
                          values?.command_id,
                          values?.depot_id,
                          values?.cost_center_id,
                          null,
                          values?.job_id,
                          values?.report_expiry,
                          values?.supervisor_employee_id,
                          values?.capability_id
                        );
                      }}
                      fetchParams={{
                        cost_center_id: values?.cost_center_id?.value,
                      }}
                      formField="office_id"
                      showCloseItemEdit={true}
                      multiSelection={false}
                      hideCheckIcon={true}
                    />
                  )}
                </Field>
              </Grid>
              <Grid
                item={true}
                xs={12}
                md={4}
                lg={3}
                className={classes.customSelect}
              >
                <Field name="job_id">
                  {({ field: { value }, form: { setFieldValue } }) => (
                    <SelectionAutoComplete
                      title={`Select ${user.label_job}`}
                      keyProperty="value"
                      nameProperty="text"
                      entityIds={value?.value}
                      setEntityIds={setFieldValue}
                      entitySelector={selectJobDropDown}
                      entityStatusSelector={getJobDropDownStatus}
                      onCustomChange={(value) => {
                        setFieldValue('job_id', value);
                        setFieldValue('job_role_id', null);
                        handleSearch(
                          values?.command_id,
                          values?.depot_id,
                          values?.cost_center_id,
                          values?.office_id,
                          value,
                          null,
                          values?.report_expiry,
                          values?.supervisor_employee_id,
                          values?.capability_id
                        );
                        dispatch(resetJobRoleDropdown());
                      }}
                      fetchEntityPage={fetchJobsDropDown}
                      cancelItemTagEdit={() => {
                        setFieldValue('job_id', null);
                        setFieldValue('job_role_id', null);
                        removeHandleSearch(
                          values?.command_id,
                          values?.depot_id,
                          values?.cost_center_id,
                          values?.office_id,
                          null,
                          null,
                          values?.report_expiry,
                          values?.supervisor_employee_id,
                          values?.capability_id
                        );
                        dispatch(resetJobRoleDropdown());
                      }}
                      showCloseItemEdit={true}
                      formField="job_id"
                      multiSelection={false}
                      hideCheckIcon={true}
                    />
                  )}
                </Field>
              </Grid>
              <Grid
                item={true}
                xs={12}
                md={4}
                lg={3}
                className={classes.customSelect}
              >
                <Field name="job_role_id">
                  {({ field: { value }, form: { setFieldValue } }) => (
                    <SelectionAutoComplete
                      title={`Select ${user.label_job_role}`}
                      keyProperty="value"
                      nameProperty="text"
                      entityIds={value?.value}
                      setEntityIds={setFieldValue}
                      entitySelector={selectJobRoleByJobIdDropDown}
                      entityStatusSelector={getJobRolesByJobIdDropDownStatus}
                      onCustomChange={(value) => {
                        setFieldValue('job_role_id', value);
                        handleSearch(
                          values?.command_id,
                          values?.depot_id,
                          values?.cost_center_id,
                          values?.office_id,
                          values?.job_id,
                          value,
                          values?.report_expiry,
                          values?.supervisor_employee_id,
                          values?.capability_id
                        );
                      }}
                      fetchEntityPage={fetchJobRolesByJobId}
                      cancelItemTagEdit={() => {
                        setFieldValue('job_role_id', null);
                        removeHandleSearch(
                          values?.command_id,
                          values?.depot_id,
                          values?.cost_center_id,
                          values?.office_id,
                          values?.job_id,
                          null,
                          values?.report_expiry,
                          values?.supervisor_employee_id,
                          values?.capability_id
                        );
                      }}
                      fetchParams={{
                        job_id: values?.job_id?.value,
                      }}
                      formField="job_role_id"
                      showCloseItemEdit={true}
                      multiSelection={false}
                      hideCheckIcon={true}
                    />
                  )}
                </Field>
              </Grid>
              <Grid
                item={true}
                xs={12}
                md={4}
                lg={3}
                className={classes.customSelect}
              >
                <Field className={classes.input} name={'report_expiry'}>
                  {({ field: { value }, form: { setFieldValue } }) => (
                    <SelectionAutoComplete
                      title="Select Report Expiration"
                      keyProperty="value"
                      nameProperty="text"
                      entityIds={value?.value}
                      setEntityIds={setFieldValue}
                      entitySelector={selectReportExpiryDropDown}
                      entityStatusSelector={getReportExpiryDropDownStatus}
                      onCustomChange={(value) => {
                        if (value?.value === 1) {
                          setFieldValue('report_expiry', value);
                        } else {
                          setFieldValue('report_expiry', value);
                          handleSearch(
                            values?.command_id,
                            values?.depot_id,
                            values?.cost_center_id,
                            values?.office_id,
                            values?.job_id,
                            values?.job_role_id,
                            value,
                            values?.supervisor_employee_id,
                            values?.capability_id
                          );
                        }
                      }}
                      fetchEntityPage={fetchReportExpirationDropDown}
                      formField={'report_expiry'}
                      multiSelection={false}
                      showCloseItemEdit={true}
                      hideCheckIcon={true}
                      cancelItemTagEdit={() => {
                        setFieldValue('report_expiry', null);
                        removeHandleSearch(
                          values?.command_id,
                          values?.depot_id,
                          values?.cost_center_id,
                          values?.office_id,
                          values?.job_id,
                          values?.job_role_id,
                          null,
                          values?.supervisor_employee_id,
                          values?.capability_id
                        );
                      }}
                    />
                  )}
                </Field>
              </Grid>
              {values?.report_expiry?.value === 1 && (
                <Grid
                  item={true}
                  xs={12}
                  md={4}
                  lg={3}
                  className={classes.customSelect}
                >
                  <fieldset
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '0',
                      border: '0',
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        sx={{
                          width: '100%',
                        }}
                        label="Select Expiry Date"
                        value={createdDateFilter}
                        format="YYYY-MM-DD"
                        onChange={(newValue) => {
                          if (
                            moment(newValue)
                              .utc()
                              .startOf('day')
                              .format('YYYY-MM-DD') !== 'Invalid date'
                          ) {
                            setCreatedDateFilter(newValue);
                            debouncedSearch(
                              newValue,
                              setFieldValue,
                              values?.report_expiry
                            );
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="outlined"
                            {...params}
                            sx={{
                              minWidth: '150px',
                              width: '100%',
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </fieldset>
                </Grid>
              )}
              <Grid
                item={true}
                xs={12}
                md={4}
                lg={3}
                className={classes.customSelect}
              >
                <Field name="supervisor_employee_id">
                  {({ field: { value }, form: { setFieldValue } }) => (
                    <SelectionAutoComplete
                      title={`Select Supervisor`}
                      keyProperty="value"
                      nameProperty="text"
                      entityIds={value?.value}
                      setEntityIds={setFieldValue}
                      entitySelector={selectSuperVisorDropDown}
                      entityStatusSelector={getSuperVisorDropDownStatus}
                      onCustomChange={(value) => {
                        setFieldValue('supervisor_employee_id', value);
                        handleSearch(
                          values?.command_id,
                          values?.depot_id,
                          values?.cost_center_id,
                          values?.office_id,
                          values?.job_id,
                          values?.job_role_id,
                          values?.report_expiry,
                          value,
                          values?.capability_id
                        );
                      }}
                      fetchEntityPage={fetchSuperVisorDropDown}
                      cancelItemTagEdit={() => {
                        setFieldValue('supervisor_employee_id', null);
                        removeHandleSearch(
                          values?.command_id,
                          values?.depot_id,
                          values?.cost_center_id,
                          values?.office_id,
                          values?.job_id,
                          values?.job_role_id,
                          values?.report_expiry,
                          null,
                          values?.capability_id
                        );
                      }}
                      fetchQueryParams={{
                        include_self: true,
                      }}
                      formField="supervisor_employee_id"
                      showCloseItemEdit={true}
                      multiSelection={false}
                      hideCheckIcon={true}
                    />
                  )}
                </Field>
              </Grid>

              <Grid
                item={true}
                xs={12}
                md={4}
                lg={3}
                className={classes.customSelect}
              >
                <Field name="capability_id">
                  {({ field: { value }, form: { setFieldValue } }) => (
                    <SelectionAutoComplete
                      title={`Select ${user.label_capability}`}
                      keyProperty="value"
                      nameProperty="text"
                      entityIds={value?.value}
                      setEntityIds={setFieldValue}
                      entitySelector={selectCapabilitiesDropDown}
                      entityStatusSelector={getCapabilityDropDownStatus}
                      onCustomChange={(value) => {
                        setFieldValue('capability_id', value);
                        handleSearch(
                          values?.command_id,
                          values?.depot_id,
                          values?.cost_center_id,
                          values?.office_id,
                          values?.job_id,
                          values?.job_role_id,
                          values?.report_expiry,
                          values?.supervisor_employee_id,
                          value
                        );
                      }}
                      fetchEntityPage={fetchCapabilityDropDown}
                      cancelItemTagEdit={() => {
                        setFieldValue('capability_id', null);
                        removeHandleSearch(
                          values?.command_id,
                          values?.depot_id,
                          values?.cost_center_id,
                          values?.office_id,
                          values?.job_id,
                          values?.job_role_id,
                          values?.report_expiry,
                          values?.supervisor_employee_id,
                          null
                        );
                      }}
                      formField="capability_id"
                      showCloseItemEdit={true}
                      multiSelection={false}
                      hideCheckIcon={true}
                    />
                  )}
                </Field>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </FilterAccordion>
  );
  //#endregion Render
};
export { DepotReportFilter };
